import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

let initialState = {
    isShowRuleModel:false,
    NumberOfRows:0,
    formSchema:[],
    selectedForm:null
};





// export const openRuleModel = createAsyncThunk(
//     "ruleForm/openRuleModel",
//     async (obj) => {
        
//        let endPoint = `${apiConfig.adminApi}/form/get-form-schema/${obj.form.id}`;

//         const response = await axios.get(endPoint);


//         let endPointForm = `${apiConfig.adminApi}/form/get-form-details/${obj.form.id}`;

//         const responseForm = await axios.get(endPointForm);

//         if(response.data){
//             return {data:response.data, formData:responseForm.data} ;
//         }else{
//             return false;
//         }
       
       
//     }
// );

export const openRuleModel = createAsyncThunk(
    "ruleForm/openRuleModel",
    async (obj) => {
       let endPoint = `${apiConfig.adminApi}/form/get-form-schema/${obj.form.id}`;
       let endPointForm = `${apiConfig.adminApi}/form/get-form-details/${obj.form.id}`;

       try {
           const [response, responseForm] = await Promise.all([
               axios.get(endPoint),
               axios.get(endPointForm)
           ]);

           if(response.data){
                return {data: response.data, formData: responseForm.data};
           } else {
                return false;
           }
       } catch (error) {
           console.error(error);
           return false;
       }
    }
);



export const closeRuleModel = createAsyncThunk(
    "ruleForm/closeRuleModel",
    async () => {
       
            return true;
       
    }
);




// export const addNewRuleSet = createAsyncThunk(
//     "ruleForm/addNewRuleSet",
//     async (data) => {
       
//         let endPoint = `${apiConfig.adminApi}/form/add-new-rule-set`;

//         const response = await axios.post(endPoint,data);
//         if(response.data){
//             return response.data
//         }else{
//             return false;
//         }
           
       
//     }
// );


export const addNewRuleSet = createAsyncThunk(
    "ruleForm/addNewRuleSet",
    async (data) => {
       
        let endPoint = `${apiConfig.adminApi}/form/add-new-rule-set`;

        try {
          const response = await axios.post(endPoint, data);
          if (response?.data) {
            return response.data;
          }
        } catch (err) {
          console.error(err);
          return false;
        }
    }
);




export const getFormDetails = createAsyncThunk(
    "ruleForm/getFormDetails",
    async (data) => {
       
        let endPointForm = `${apiConfig.adminApi}/form/get-form-details/${data}`;

        const responseForm = await axios.get(endPointForm);
        if(responseForm.data){
            return responseForm.data
        }else{
            return false;
        }
           
       
    }
);








export const formRuleSlice = createSlice({
    name: "ruleForm",
    initialState,
    extraReducers: {

        [openRuleModel.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                isShowRuleModel: true,
                formSchema:action?.payload?.data?action.payload.data:[],
                selectedForm:action?.payload?.formData?action.payload.formData:null,
            }
        },
        [closeRuleModel.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                isShowRuleModel: false,
            }
        },
        [getFormDetails.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                selectedForm: action.payload?action.payload:null,
            }
        },
        
        

    },
});

export default formRuleSlice.reducer;


