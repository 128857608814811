

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';
import $ from 'jquery';
import { Popover, Steps } from 'antd';
import { Stepper } from 'react-form-stepper';
import {
    closeClockOutMenu, shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";

import {
    ShareLocation
} from "../../../../attandence/components/ShareLocation";
const { Option } = Select;
const { Step } = Stepper;

const DynamicStep = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [lable, setLable] = useState(null)

    const [stages, setStages] = useState(null)


    const currentStage = useSelector((state) => {


        let error = 0
        if (state?.element?.selectedGroup) {
            const sGroup = state.element.selectedGroup
            
            const relStage = props?.schema?.schemaElement?.relatedStage ? props.schema.schemaElement.relatedStage : []
           
            const ind = relStage.findIndex((e) => e.groupId == sGroup)
            for (let i = 1; i < ind+1; i++) {
                let lstDi=i+2;
                let fttDi=lstDi*2;

               
              }
            if (ind >= 0) {
                error = ind
                
            }


        }
        return error

    })

    const init = (data) => {


        if (props?.schema?.schemaElement?.relatedStage) {
            let allSt = []
            for (let st of props.schema.schemaElement.relatedStage) {
                let lab=t(st.groupLable)
                allSt.push({
                    label: lab
                })
            }
            setStages(allSt)



        } else {
            setStages(null)
        }
        if (props?.schema?.schemaElement?.lableTxt) {
            setLable(props.schema.schemaElement.lableTxt)
        } else {
            setLable(null)
        }
    }

    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    );

    const getGeo = async () => {



    };

    const description = 'can hover';

    useEffect(() => {


        init(props)

    }, [props]);
    return (
        <div >

            {
                stages ? <>
                    <Stepper
                        steps={stages}
                        connectorStateColors={true}
                        activeStep={currentStage}
                    />

                </> : null
            }



        </div>



    );
}



export default withJsonFormsControlProps(DynamicStep);
