import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import {QuestionCircleOutlined, EditOutlined ,EyeOutlined,DeleteOutlined,UnorderedListOutlined ,CopyOutlined   } from "@ant-design/icons";
import { manageMoreOptionDrawer } from "../../redux/slices/answer/answerSetting";
import { Radio, Tabs } from 'antd';
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox , Form, Drawer, Typography,  DatePicker
} from "antd";
import { Divider, List } from 'antd';
import {FormViewInterface} from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import DialogContent from "@mui/material/DialogContent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function MoreOption(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();
    const showDrawer = useSelector((state) => {
        let data = false
        const pageState=state.answer?state.answer:null
        if(pageState?.openMoreOptionDrawer){
            data=pageState.openMoreOptionDrawer
        }
         
        return data
    })


    const selectedtData = useSelector((state) => {
        let data = false
        const pageState=state.answer?state.answer:null
        if(pageState?.selectedtData){
            data=pageState.selectedtData
        }
         
        return data
    })





    const closeDrawer = () =>{
        dispatch(manageMoreOptionDrawer(false))
    }

    const listItemClick = (e) =>{

        let ob={
                key:e,
                item:{
                    props:{
                        items:{
                            id:selectedtData
                        }
                    }
                }
        }
        props.handleMenuClick(ob)
        dispatch(manageMoreOptionDrawer(false))
       // dispatch(manageViewAnswerDrawer(false))
       //   
          
       // alert()
    }

    useEffect(() => {

        // init();

    }, [props]);

let options = [
    {
        value:'2',
        lable:'VIEW'
    }, {
        value:'4',
        lable:'TASK ANSWER'
    }, {
        value:'5',
        lable:'DUPLICATE ANSWER'
    }
]
    if (props?.permission?.update) {
        options.push({
            value:'1',
            lable:'EDIT'
        })
    }
    if (props?.permission?.delete) {
        options.push({
            value:'3',
            lable:'DELETE'
        })
    }
    return (
<div className='more-option-div'>
    <Drawer
    //    title="Basic Drawer"
       placement={"bottom"}
       closable={false}
       onClose={closeDrawer}
       open={showDrawer}
       key={"placement"}
    >
        <div >


            <List
                size="small"
                bordered
                dataSource={options.sort((firstItem, secondItem) => Number(firstItem.value) - Number(secondItem.value))}
                renderItem={(item) => <List.Item>
                    <div className='drawer-contain' style={{width:'100%'}} onClick={(e)=>{listItemClick(item.value)}}>
                        {
                            item.value=='1'?<span><EditOutlined  /></span>:
                                item.value=='2'?<span><EyeOutlined  /></span>:
                                    item.value=='3'?<span><DeleteOutlined  /></span>:
                                        item.value=='4'?<span><UnorderedListOutlined  /></span>:
                                            item.value=='5'?<span><CopyOutlined  /></span>:null
                        }
                        <span>{item.lable}</span>
                </div></List.Item>}
            />


        </div>


    </Drawer>
</div>


    );
}