import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import jwt from "jsonwebtoken";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
} from "@mui/material";
import {
  ExportOutlined,
  SearchOutlined,
  ImportOutlined,
  FileAddOutlined,
  SettingTwoTone,
  DeleteOutlined,
  EditOutlined,
  ForwardOutlined,
  CopyOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";

import {
  Row,
  Col,
  Radio,
  Table,
  Button,
  Select as AntSelect,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Space,
  Tabs,
  Collapse,
  Modal,
  Input,
  Form,
} from "antd";

import {
  getDynamicPageInitial,
  getIframeUrl,
} from "../redux/slices/dynamicPage";

import * as XLSX from "xlsx";
import { parse } from "date-fns";
import { type } from "jquery";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const DynamicIfram = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  //refactor and move to reducers
const setupIframe =  (urlFromDb) => {
    if (!urlFromDb) {
      return "no data";
    }

    const urlLength = urlFromDb.length;
    console.log(`typeof ${urlLength}: ${urlLength}`);

    let url;

    if (urlLength > 5) { // check if length is more than 5, if yes (it will be a full URL not a Metabase ID)
      url = urlFromDb;
    } else if (urlLength < 5) {
        
      const METABASE_SITE_URL = "https://mb.frontend.gw.t.int.suparagroup.com";
      const METABASE_SECRET_KEY =
        "1dccc6177f08d5bf27b1ce935aafdec8f78e2c8562ae6f79faeec8356e483a71";

      const payload = {
        resource: { dashboard: parseInt(urlFromDb) }, // dashboard have to be an INTEGER
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
      
      console.log(payload);

      const token = jwt.sign(payload, METABASE_SECRET_KEY);
      
      url = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
    }

    return url;
  
};

  const containerData = useSelector((state) => state.page);
  console.log(containerData);

  const init = (id) => {
    dispatch(getDynamicPageInitial(id));
  };

  useEffect(() => {
    init(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {/* <div className="page-continer "> */}
          {containerData?.contain?.redirect ? (
            <>
              <iframe
                className="ifram-cutom"
                src={setupIframe(containerData?.contain?.redirect)}
                title="description"
              ></iframe>
            </>
          ) : null}
          {/* </div> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DynamicIfram;
