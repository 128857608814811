import React, { useState, useEffect } from "react";

import { useDispatch, connect, useSelector } from "react-redux";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { ElementValue } from "./components/ElementValue"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';

import { Col, Row } from 'antd';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Button, Modal } from 'antd';
import { Form, Input, Space, Checkbox ,Select, Card, Collapse } from 'antd';
import { closeRuleModel, addNewRuleElementSet, getFormDetails, ChangeElementRuleBehaviors, saveCommonFieldReducer } from "../../../../redux/slices/ruleElement"
const { Option } = Select;
const { Panel } = Collapse;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};




export function RuleElement(props) {

    const [openAddRule, setOpenAddRule] = useState(false)


    const [targetField, setTargetField] = useState(null)

    const [Addobject, setAddObject] = useState(null)

    const [commonField, setCommonField] = useState([])

    const dispatch = useDispatch();
    const isOpenModel = useSelector((state) => {
        return state?.ruleFormElement?.isShowRuleModel ? state.ruleFormElement.isShowRuleModel : null
    })
    const selectedSchema = useSelector((state) => {
         
        return state?.ruleFormElement?.formSchema ? state.ruleFormElement.formSchema : []
    })

    const selectedForm = useSelector((state) => {
         
        return state?.ruleFormElement?.selectedForm ? state.ruleFormElement.selectedForm : null
    })
    const ruleCloseFunction = () => {

        dispatch(closeRuleModel())
    }

    const clossAddRuleModel = () => {

        setOpenAddRule(false)


    }
    const openAddRuleModel = () => {

        setOpenAddRule(true)


    }

    const onChangeField = (value) => {

         ;


    }


    const onFinish = async (values) => {

        let obj = {
            ...values,
            selectedForm: selectedForm.id,
            visibleSet: []
        }
        await dispatch(addNewRuleElementSet(obj))

        await dispatch(getFormDetails(selectedForm.id))
    };


    const addToElementRule = async (values, e, sc) => {

        const tagertValue = values
        var element = e
        let echama = sc

        let obj = {
            tagertValue,
            element,
            echama
        }
        await dispatch(ChangeElementRuleBehaviors(obj))
        await dispatch(getFormDetails(selectedForm.id))

    };

    const setCommonFieldFunction = async (e) => {

        let obj = {
            selectedForm: selectedForm.id,
            key: e
        }
        await dispatch(saveCommonFieldReducer(obj))
        await dispatch(getFormDetails(selectedForm.id))

    };



    useEffect(() => { }, [props]);

    return (
        <>
            <Modal
                title="Rule management"
                visible={isOpenModel}
                footer={[
                    <Button danger key="back" onClick={ruleCloseFunction}>
                        Cancel
                    </Button>,
                    <Button danger key="submit" type="primary" onClick={ruleCloseFunction}>
                        Submit
                    </Button>
                ]}
            >


                <div>

                    <Row style={{ width: '100%', marginBottom: '5px' }}>

                        <Col span={24}>
                            {
                                selectedSchema &&  selectedForm?.formCommonField?
                                    <>
                                        {

                                            selectedSchema.map((element) => (
                                                <>
                                                <Checkbox checked={selectedForm.formCommonField.find((e)=>e.key===element.keyTxt?true:false)} onChange={(e) => setCommonFieldFunction(element.keyTxt)}>{element.lableTxt}</Checkbox>
                                                  


                                                </>
                                            ))
                                        }
                                    </>
                                    : null
                            }

                        </Col>

                    </Row>


                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                        <Form.Item
                            name="element"
                            rules={[{ required: true, message: 'Missing Field' }]}
                        >
                            <Select
                                placeholder="Select Field"
                                allowClear
                            >
                                {
                                    selectedSchema.map((element) => (
                                        <Option value={element.keyTxt}>{element.lableTxt}</Option>
                                    ))
                                }


                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="answer"
                            rules={[{ required: true, message: 'Missing Answer' }]}
                        >
                            <Input placeholder="Answer" />
                        </Form.Item>
                       
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                    {
                        selectedForm?.ruleSet ? <>
                            <Collapse>


                                {
                                    selectedForm.formRuleSetElement.map((element) => (
                                        <>
                                            <Panel header={`${element.element}-${element.answer}`} key={element._id}>
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <th>Field</th>
                                                            <th>Show/Hide</th>
                                                        </tr>

                                                        {
                                                            selectedSchema.map((sc) => (
                                                                <tr>
                                                                    <td>{sc.lableTxt ? sc.lableTxt : '-'}</td>
                                                                    <td><input type="checkbox" checked={element.visibleSet.find((e) => e == sc.keyTxt ? true : false)} onChange={(e) => addToElementRule(e.target.checked, element, sc)} /></td>
                                                                </tr>

                                                            ))
                                                        }
                                                    </table>
                                                </div>
                                            </Panel>
                                        </>
                                    ))
                                }
                            </Collapse>
                        </> : null

                    }
                </div>


            </Modal>



            {/*  */}
        </>
    );
}