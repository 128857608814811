import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../../config";

let initialState = {
    show:false,
    elementGroup:[],
    formInformation:null,
    jsonSchema:[],
    row:null,
    itemLoading:true,
    formRemark:{},
    dataRemark:{},
    type:null,
    openMoreOptionDrawer:false,
    selectedtData:null
};






export const setFormReducer = createAsyncThunk(
    "answer/setFormReducer",
    async (data) => {
         


        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);


export const manageMoreOptionDrawer = createAsyncThunk(
    "answer/manageMoreOptionDrawer",
    async (data) => {
         

            return data;

    }
);
export const openMoreOptionDrawer = createAsyncThunk(
    "answer/openMoreOptionDrawer",
    async (data) => {
         

        return data;

    }
);


export const changeRemarkReducer = createAsyncThunk(
    "answer/changeRemarkReducer",
    async (data) => {
         


        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);

export const changeRemarkValueReducer = createAsyncThunk(
    "answer/changeRemarkValueReducer",
    async (data) => {
         
     
        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);



export const changeCheckReducer = createAsyncThunk(
    "answer/changeCheckReducer",
    async (data) => {
         


        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);



export const answerSlice = createSlice({
    name: "answer",
    initialState,
    extraReducers: {

        [setFormReducer.pending]: (state, action) => {
            const data=action.payload
             
            return {
                ...state,
               itemLoading:true
            }
        },
        [manageMoreOptionDrawer.fulfilled]: (state, action) => {
            const data=action.payload
             
            return {
                ...state,
                openMoreOptionDrawer:data
            }
        },
        [openMoreOptionDrawer.fulfilled]: (state, action) => {
            const data=action.payload
             
            return {
                ...state,
                openMoreOptionDrawer:true,
                selectedtData:data
            }
        },

        [changeRemarkReducer.fulfilled]: (state, action) => {
            const data=action.payload
             
            if(data.answerType=='sub'){
                return {
                    ...state,
                    dataRemark:{
                        ...state.dataRemark,
                        [data.key.keyTxt]:{
                            ...state.dataRemark[data.key.keyTxt],
                            remark:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }else{
                return {
                    ...state,
                    formRemark:{
                        ...state.formRemark,
                        [data.key.keyTxt]:{
                            ...state.formRemark[data.key.keyTxt],
                            remark:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }

        },
        [changeRemarkValueReducer.fulfilled]: (state, action) => {
            const data=action.payload
             
            if(data.answerType=='sub'){
                return {
                    ...state,
                    dataRemark:{
                        ...state.dataRemark,
                        [data.key.keyTxt]:{
                            ...state.dataRemark[data.key.keyTxt],
                            value:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }else{
                return {
                    ...state,
                    formRemark:{
                        ...state.formRemark,
                        [data.key.keyTxt]:{
                            ...state.formRemark[data.key.keyTxt],
                            value:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }

        },
        
        [changeCheckReducer.fulfilled]: (state, action) => {
            const data=action.payload
             
            if(data.answerType=='sub'){
                return {
                    ...state,
                    dataRemark:{
                        ...state.dataRemark,
                        [data.key.keyTxt]:{
                            ...state.dataRemark[data.key.keyTxt],
                            checked:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }else{
                return {
                    ...state,
                    formRemark:{
                        ...state.formRemark,
                        [data.key.keyTxt]:{
                            ...state.formRemark[data.key.keyTxt],
                            checked:data.value?data.value:null
                        }
                    }
                    //itemLoading:true
                }
            }

        },
        [setFormReducer.fulfilled]: (state, action) => {
            const data=action.payload
            
            return {
                ...state,
                elementGroup:data.elementGroup?data.elementGroup:[],
                formInformation:data.formInformation?data.formInformation:null,
                jsonSchema:data.jsonSchema?data.jsonSchema:[],
                row:data.row?data.row:null,
                itemLoading:false,
                type:data.type?data.type:null,
                workFlow: data.workFlow
            }
        },

    },
});

export default answerSlice.reducer;


