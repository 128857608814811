/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu,Skeleton  } from "antd";

import {NavLink, useHistory, useLocation} from "react-router-dom";
import { useDispatch, connect, useSelector } from "react-redux";
// import logo from "../../assets/images/logo.png";
import Icon, { FastBackwardOutlined, HeartTwoTone, VerifiedOutlined, FolderOpenOutlined, ReconciliationOutlined } from '@ant-design/icons';
import {
  openSideNev,closeSideNev, setActiveMenu, setActiveSubMenu
} from "../../redux/slices/sideNevSetting"

import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { getSiteBarHeader } from "../../redux/slices/configSetting";
const { SubMenu } = Menu;
function Sidenav(props) {
  const color = props.color
  const store = useSelector((state) => state)
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const page = pathname.replace("/manage-form", "manage-form");
  const [dataArray, setDataArray] = useState([])
  const tempHeaderItems = useSelector(state => state.setting.sideMenuItems)
  const isLoading = useSelector(state => state.setting.isLoading)
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];





  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];


  const manageForm = [
    <ReconciliationOutlined />,
  ];

  const componentManagement = [
    <FolderOpenOutlined />,
  ];


  const languageManage = [
    <VerifiedOutlined />,
  ];



  const history = useHistory();




  const { t } = useTranslation();

  const createContainer = () => {
    let tempArray = [
      {
        title: 'Main',
        key: 'main',
        items: [
          {
            title: 'Dashboard',
            key: 'dashboard',
            icon: 'https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-dashboard-line-black-icon-png-image_691037.jpg',
            to: '/dashboard'
          }
        ]
      },
      {
        title: 'EMPLOYEE',
        key: 'employee',
        items: [
          {
            title: 'Users',
            key: 'users',
            icon: 'https://flyclipart.com/thumb2/profile-icon-png-black-196391.png',
            to: '/users'
          }
        ]
      },
      {
        title: 'SETTINGS',
        key: 'settings',
        items: [
          {
            title: 'Form',
            key: 'form',
            icon: 'https://png.pngtree.com/png-vector/20190909/ourlarge/pngtree-form-icon-isolated-png-image_1727824.jpg',
            to: '/manage-form'
          },
          {
            title: 'Component',
            key: 'component',
            icon: 'https://w7.pngwing.com/pngs/465/905/png-transparent-computer-icons-module-icon-design-modular-programming-web-module-miscellaneous-angle-logo.png',
            to: '/manage-component'
          },
          {
            title: 'Language',
            key: 'lanaguage',
            icon: 'https://img.favpng.com/24/3/12/computer-icons-translation-icon-design-png-favpng-BYi4Y7LQCEPbaFANZcJxiBkKH.jpg',
            to: '/language'
          },
          {
            title: 'Configuration',
            key: 'configuration',
            icon: 'https://flyclipart.com/thumb2/phone-settings-setting-icon-292044.png',
            to: '/app-config'
          },
          {
            title: 'SideBar',
            key: 'sidebase',
            icon: 'https://flyclipart.com/thumb2/phone-settings-setting-icon-292044.png',
            to: '/side-bar-config'
          }
        ]
      },
      {
        title: 'ACCOUNT PAGES',
        key: 'account_page',
        items: [
          {
            title: 'Profile',
            key: 'profile',
            icon: 'https://flyclipart.com/thumb2/profile-icon-png-black-196391.png',
            to: '/profile'
          }
        ]
      }
    ]
    setDataArray(tempArray)


  }

  const getInit = () => {
    dispatch(getSiteBarHeader())
  }

  const moveToFormSetting = (e,url) => {
     
     
     history.push(url)
    dispatch(closeSideNev())
   // props.
    // if (props?.formInformation?.id) {
    //   // history.push()
    //   window.open("/manage-form/scheema?id=" + props.formInformation.id, "_blank");
    // }

  }
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    getInit()
  }, []);

  return (
    <>
    {
       
    }
      <div className="brand brand-logo">
        <div>
          <img src="https://cdn.shopify.com/s/files/1/0659/0133/0652/files/supara-09.svg?v=1672900442" alt="" />
        </div>
      </div>
      <hr />
      {
        isLoading?<><Skeleton active /></>:
<Menu defaultSelectedKeys={['1']} openKeys={store.sideNev.activeMenu}
        defaultOpenKeys={store.sideNev.activeMenu} theme="light" mode="inline" onOpenChange={(e) => {
          dispatch(setActiveMenu([`${e[e.length - 1]}`]))
        }}
        onClick={(e) => {
          dispatch(setActiveSubMenu(e.key))
        }}
        >
        {
          tempHeaderItems.filter((e)=>e.hasAccess).map((row) => (
            <Fragment key={row.key}>

              <SubMenu
                style={{ padding: 0 }}
                key={row.key}
                title={
                  <span>
                    <Icon type="mail" />
                    <span> {t(row.title)}</span>
                  </span>
                }
              >
                {/* <Menu.Item className="menu-item-header" key={row.key}>
                    {t(row.title)}
                  </Menu.Item> */}
                {
                  row.items ?
                    <>
                      {
                        row.items.filter((e)=>e.hasAccess).map((item) => (
                          <Menu.Item key={item.key} 
                          className={`${item.key === store.sideNev.activeSubMenu ? 'active' : ''}`} 
                          onClick={() => {
                            dispatch(setActiveSubMenu(item.key))
                          }}
                          >
                          { 
                            item.type=='external_page'?
                            <a href={item.redirect?item.redirect:'#'} target="_blank">
                            <span
                              className="icon"
                              style={{
                                color: page === item.to ? 'white' : "black",
                              }}
                            >
                              {
                                item.icon != '' ? <><i className={item.icon}></i></> : <i className='far fa-file'></i>
                              }


                            </span>
                            <span className="label">{t(item.title)}</span>
                          </a>:
                          <div className={`ui-item-div`}
                              onClick={()=>{
                                moveToFormSetting(false,item.to)

                              }}
                          >
                            {/*to={item.to}*/}
                            <span
                              className="icon"
                              style={{
                                color: page === item.to ? 'white' : "black",
                              }}
                            >
                              {
                                item.icon != '' ? <><i className={item.icon}></i></> : <i className='far fa-file'></i>
                              }


                            </span>
                            <span  className="label">{t(item.title)}</span>
                          </div>
                          }
                             
                           
                          </Menu.Item>
                        ))
                      }
                    </> : null

                }
              </SubMenu>
              </Fragment>



          ))
        }

      </Menu>

      }
      
    </>
  );
}

export default Sidenav;
