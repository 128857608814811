import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

const initialState = {
  value: 0,
  status: "idle",
  userDataLoading:false,
  loginUser:null,
  usersFetchLoading: false,
  usersFetchData: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getFetchUsers = createAsyncThunk(
  "user/getFetchUsers",
  async (data) => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/bitrixApi/getUsersByPagin?`;
    if (data.nameFilter) {
      endPoint += `&NAME=${data.nameFilter}`;
    }
    if (data.lastNameFilter) {
      endPoint += `&LAST_NAME=${data.lastNameFilter}`;
    }
    if (data.emailFilter) {
      endPoint += `&EMAIL=${data.emailFilter}`;
    }
    if (data.cityFilter) {
      endPoint += `&PERSONAL_CITY=${data.cityFilter}`;
    }
    if (data.statusFilter) {
      endPoint += `&ACTIVE=${data.statusFilter}`;
    }
    if (data?.page) {
      let offset = (data.page -1) * (data.dataPerPage || 50);
      endPoint += `&start=${offset}`;
    }
    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);


// axios request to get paginated request with dynamic schema search filtered Users data
export const getPaginatedUser = createAsyncThunk(
  "user/getFetchUsers",
  async (data) => {
    let { page, dataPerPage, ...filters } = data || {};
    let endPoint = `${apiConfig.adminApi}/bitrixApi/getUsersByPagin?`;
    if (page) {
      let offset = (data.page - 1) * (dataPerPage || 50);
      endPoint += `&start=${offset}`;
    }
    // add all filters into query params
    if (filters) {
      endPoint += Object.keys(filters).reduce((acc, cur) => acc + `&${cur}=${filters[cur]}`, '');
    }
    const response = await axios.get(endPoint);
    if (response?.data) {
      return response.data;
    } else {
      throw Error;
    }
  }
);

export const getFetchUsersWithoutPaginate = createAsyncThunk(
  "user/getFetchUsersWithoutPaginate",
  async () => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/bitrixApi/getAllUsers`;

    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);


export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (fields) => {
    try {
      // Axios request to get users data
      let endPoint = `${apiConfig.adminApi}/bitrixApi/getUsers`;

      // const endPoint = `http://localhost:4020/bitrixApi/getUsers`;
      const config = { params: { fields } };
      const response = await axios.get(endPoint, config);

      // Reponse status code validation
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.log("Error fetching users data: ", error.message);
      throw error;
    }
  }
);



export const completeProfileReducer = createAsyncThunk(
  "user/completeProfileReducer",
  async (formData) => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/user/compelte-profile`;
    const token = localStorage.getItem('token')
    let config = {
      headers: {
        authorization: 'Bearer ' + token,
      }
    }
    try {
      const response = await axios.post(endPoint, formData, config);
      if (response?.data) {
        // The value we return becomes the `fulfilled` action payload
        return response.data;
      }

    } catch (err) {
      if (err.response.status == 401) {

        localStorage.removeItem('token')
        localStorage.removeItem('emCode');

      }
      return err.response.status;
    }
  }
);






export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (id) => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/bitrixApi/bitrixFields/${id}`;

    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);


export const getUserDataById = createAsyncThunk(
  "user/getUserById",
  async (id) => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/user/get-user-data/${id}`;

    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);


export const getLogedInUserData = createAsyncThunk(
  "user/getLogedInUserData",
  async () => {
    const token = localStorage.getItem('token')
    let config = {
      headers: {
        authorization: 'Bearer ' + token,
      }
    }


    let endPoint = `${apiConfig.adminApi}/user/get-loged-user`;
    try {
      const response = await axios.get(endPoint, config);
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      if (err.response.status == 401) {

        localStorage.removeItem('token')
        localStorage.removeItem('emCode');

      }
      return err.response.status;
    }
  }
);



// export const initUserData = createAsyncThunk(
//   "user/initUserData",
//   async () => {
//     const token = localStorage.getItem('token')
//     let config = {
//       headers: {
//         authorization: 'Bearer ' + token,
//       }
//     }


//     let endPoint = `${apiConfig.adminApi}/user/get-loged-user`;
//     try {
//       const response = await axios.get(endPoint, config);
//       if (response?.data) {
//         return response.data;
//       }
//     } catch (err) {
//       if (err.response.status == 401) {

//         localStorage.removeItem('token')
//       }
//       return err.response.status;
//     }
//   }
// );

export const initUserData = createAsyncThunk(
  "user/initUserData",
  async () => {
    const token = localStorage.getItem('token');
    const headers = { authorization: `Bearer ${token}`,"requestid":"requestid" };
    const config = { headers };

    const endPoint = `${apiConfig.adminApi}/user/get-loged-user`;
    try {
      const response = await axios.get(endPoint, config);
      if (response?.data) {
        console.log('response.data', response.data);
        return response.data;
      }
    } catch (err) {
      const { status } = err.response;
      if (status === 401) {
        console.log("401 - token removed");
        localStorage.removeItem("token");
        console.log("401 - token removed");
       
        alert(`401 - token removed, Please login again \n ปิดแท็บเบราว์เซอร์นี้ และกรุณาเข้าสู่ระบบอีกครั้ง`);
      }
      console.log('status',status)
      return status;
    }
  }
);




// export const authenticatedWithUrl = createAsyncThunk(
//   "user/authenticatedWithUrl",
//   async (id) => {
//     //axios request to get Users data
//     let endPoint = `${apiConfig.adminApi}/user/login-url/${id}`;

//     try {
//       const response = await axios.get(endPoint);
//       if (response?.data) {
//         return response.data;
//       }
//     } catch (err) {
//       if (err.response.status == 401) {

//         localStorage.removeItem('token')
//       }
//       return err.response.status;
//     }


//   }
// );

// This code seems to have the following issues:

// The error handling code is not very descriptive, it only returns the status code of the error response. It is not clear what the status codes mean, and there is no feedback to the user.

// The code does not check if the token exists before removing it in the case of a 401 error. This can result in an error if the token is already removed.

// The code does not throw an error if the response data is not present.

// A more robust implementation of this code would look something like this:



export const authenticatedWithUrl = createAsyncThunk(
  "user/authenticatedWithUrl",
  async (id) => {
    //axios request to get Users data
    localStorage.setItem('emCode', id);

    let endPoint = `${apiConfig.adminApi}/user/login-url/${id}`;

    try {
      const response = await axios.get(endPoint);
      if (!response) {
        throw new Error("No response from server");
      }
      if (!response.data) {
        throw new Error("No data in response");
      }
      return response.data;
    } catch (err) {
      if (err.response && err.response.status == 401) {
        const token = localStorage.getItem('token');
        if (token) {
          localStorage.removeItem('token');
          localStorage.removeItem('emCode');

        }
        throw new Error("Unauthorized");
      }
      throw new Error("Request failed");
    }
  }
);



export const getBitrixAllDbFields = createAsyncThunk(
  "user/getBitrixAllDbFields",
  async () => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/bitrixApi/getBitrixAllDbFields`;

    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);




export const updateUserService = createAsyncThunk(
  "user/updateUserService",
  async (employeeData) => {
    //axios request to get Users data

    let endPoint = `${apiConfig.adminApi}/bitrixApi/updateEmployee`;

    const response = await axios.post(endPoint, employeeData);
    if (response?.data) {
      return response.data;
    } else {
      throw Error;
    }
  }
);

export const updateBulkUserService = createAsyncThunk(
  "user/updateUserService",
  async (employeesData) => {
    //axios request to get Users data


    let endPoint = `${apiConfig.adminApi}/bitrixApi/bundleUpdate`;

    const response = await axios.post(endPoint, employeesData);
    if (response?.data) {
      return response.data;
    } else {
      throw Error;
    }
  }
);

export const signInWithCredintials = createAsyncThunk(
  "user/signInWithCredintials",
  async (email) => {
    //axios request to get Users data



    let endPoint = `${apiConfig.adminApi}/user/login-cred`;

    try {
      const response = await axios.post(endPoint, email);


      // if (response?.data) {
      //   if (response.data.login) {

      //     localStorage.setItem('token', response.data.token)
      //     return response.data;
      //   } else {
      //     return false
      //   }

      // }

      if (response?.data?.login) {
        localStorage.setItem('token', response.data.token);
        return response.data;
      } else {
        return false;
      }
      
      
    } catch (err) {
      if (err.response.status == 401) {

        localStorage.removeItem('token')
        localStorage.removeItem('emCode');

      }
      return false
    }


  }
);




export const getFormSchema = createAsyncThunk(
  "user/getFormSchema",
  async () => {
    //axios request to get Users data

    const url = 'https://a0784e1f-d36c-4fb5-a1a8-d9b192bc0931.mock.pstmn.io/formdata/get-all'

    let endPoint = url;

    const response = await axios.get(endPoint);
    if (response?.data) {
      return response.data;
    } else {
      throw Error;
    }
  }
);



export const getUserAttribute = createAsyncThunk(
  "user/getUserAttribute",
  async (id) => {
    //axios request to get Users data
    let endPoint = `${apiConfig.adminApi}/user/signup/${id.userKey}/${id.attribute}`

    const response = await axios.get(endPoint);
    if (response?.data) {
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } else {
      throw Error;
    }
  }
);




export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFetchUsers.pending, (state) => {
        state.usersFetchLoading = true;
      })


      .addCase(getFetchUsers.fulfilled, (state, action) => {
        state.usersFetchLoading = false;
        if (action.payload?.result?.length > 0) {
          state.usersFetchData = action.payload;
        } else {
          state.usersFetchData = [];
        }
      })

      .addCase(initUserData.pending, (state, action) => {
      
        return {
          ...state,
          userDataLoading:true

      }
      })


      .addCase(initUserData.fulfilled, (state, action) => {
        const data =action.payload
        console.log('initUserData',data)

        return {
          ...state,
          userDataLoading:false,
          loginUser:data

      }
      })
      
      .addCase(getFetchUsers.rejected, (state) => {
        state.usersFetchLoading = false;
        state.usersFetchData = [];
      })

  },
});

export default userSlice.reducer;
