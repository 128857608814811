


import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useDispatch, connect,useSelector } from "react-redux";



import { getAllLanguage, addLanguageBulk } from "../../redux/slices/language";

import { updateSetting, getAppSetting } from "../../redux/slices/configSetting";

import {
  getFormSchema,
  getFormId,
  getAllGroupRaducer,
} from "../../redux/slices/formSettings";

function Footer(props) {
  const { Footer: AntFooter } = Layout;
  const dispatch = useDispatch();
  const [footerLinks, setFooterLinks] = useState([])


  const version = useSelector((state) => {

    let version=null
    if(state?.setting?.version){
      version=state.setting.version
    }

 
    return version

})

  const getFormData = async () => {

    const formData = await dispatch(getFormId("app_config"));
    if (formData) {
      if (formData.payload) {
        if (formData.payload.length != 0) {
          const formSchema = await dispatch(
            getFormSchema(formData.payload[0].id)
          );

          const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));
          const getAnswer = await dispatch(getAppSetting())

          let arrayOfFooter = []
          let footerAnswer = {}
          if (getAnswer?.payload?.data?.footer) {
            footerAnswer = getAnswer.payload.data.footer

          } else {
            return
          }


          if (gg.payload) {
          }

          if (formSchema.payload) {
            const footerLink = formSchema.payload
            for (const lin of footerLink) {
              const answer = footerAnswer[lin.keyTxt] ? footerAnswer[lin.keyTxt] : null
              if (answer) {
                arrayOfFooter.push({
                  lable: lin.lableTxt,
                  url: answer
                })
              }
            }
            setFooterLinks(arrayOfFooter)
          }
        }
      }
    }

  };


  useEffect(() => {
    getFormData()
  }, [])
  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">

          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>

              {footerLinks.map((row, index) => (
                <li key={index} className="nav-item">
                  <a
                    href={row.url}
                    className="nav-link text-muted"
                    target="_blank"
                  >
                    {row.lable}
                  </a>
                </li>

              ))}

              <li key={111} className="nav-item">
                <a
                  href='#'
                  className="nav-link text-muted"
                  target="_blank"
                >
                  
                 v{version}
                 </a>
              </li>

            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
