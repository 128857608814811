import React, { useState, useEffect } from "react";

import {
    useDispatch, useSelector
} from "react-redux";
import { Alert, Button, Col, Image, Result, Row, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { RoundedStatusGreen } from "../../../../components/elements/RoundedStatus";
import {
    changeDataEditAction, editFormAnswer,
    getFormAnswerList, removeStateReducer
} from "../../../../redux/slices/attandance/markAttandanceSetting";
import moment from 'moment'
import { CheckCircleTwoTone,WarningOutlined,HeartTwoTone, SmileTwoTone } from '@ant-design/icons'
import { FormInterfaceDynamicEdit } from "../../../settings/JsonForm/intefaces/FormInterfaceDynamicEdit";

import $ from 'jquery';

export function CheckoutForms(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [time, setTime] = useState(null)
    const formGroupTask = useSelector((state) => {
        let data = []
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formGroupTask) {
            data = attandanceState.formGroupTask
        }
        return data
    })


    const formInformation = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formInformation) {
            data = attandanceState.formInformation
        }
        return data
    })

    const formdata = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formInformation) {
            data = attandanceState.formInformation
        }
        return data
    })

    const formSchemaTask = useSelector((state) => {
        let data = []
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formSchemaTask) {
            data = attandanceState.formSchemaTask
        }
        return data
    })
    const selectedAnswer = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.selectedAnswer) {
            data = attandanceState.selectedAnswer
        }
        return data
    })


    const formDataSetEdit = useSelector((state) => {
        let data = {}
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formDataSetEdit) {
            data = attandanceState.formDataSetEdit
        }
        return data
    })

    const isEnableEdit = useSelector((state) => {
        let data = {}
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.isEnableEdit) {
            data = attandanceState.isEnableEdit
        }
        return data
    })



    const getFormAnswer = async (id) => {

        const data = {
            formData: id,
            sorting: false,
            order: false,
            search: null,
            viewType: "user_only"
        };
        const res = await dispatch(getFormAnswerList(data));


    }

    const [isOpenResultPopup, setResultPopup] = useState(false)

    const formSubmitClocked = async (e) => {

        if (e.clockOut) {
            console.log(e.clockOut, 'clockOut 109' )
            let tim = e.clockOut.image.dateTime ? moment(e.clockOut.image.dateTime).format("HH:mm") : '-'
            setTime(tim)
        }
        setResultPopup(true);

        
    }

    const [loading, setLoading] = useState(false)

    const clossPopup = async () => {
        setLoading(true)
        await formSubmitEdit()
        setLoading(false)
        setResultPopup(false)
    }

    const formSubmitEdit = async () => {

        if (!formDataSetEdit?.database?.main?.clocked?.clockOut) {
            alert('please take clockeout photo')
            return
        }
        let updatedFormdata = formDataSetEdit


        const tempData = {
            answerId: selectedAnswer.id,
            data: updatedFormdata,
            form: formdata.id,
            rejected: formDataSetEdit?.workFlow?.rejected ? true : false
        }



        const res = await dispatch(editFormAnswer(tempData))
         await dispatch(removeStateReducer(tempData))

        // getFormAnswer(formdata.id)
    }

    const changeDataTriggerEdit = async (data) => {

        dispatch(changeDataEditAction(data))

    }





    useEffect(() => {
        $(".ant-modal-wrap").eq(0).removeAttr("tabindex");

    }, [props]);



    return (
        <>


            {/* <Row>
                <Col span={4} offset={10}>
                    <RoundedStatusGreen lable={'Current status : Working'}></RoundedStatusGreen>

                </Col>
            </Row> */}
            <FormInterfaceDynamicEdit

                jsonSchema={formSchemaTask}
                elementGroup={formGroupTask}
                data={selectedAnswer}
                formInformation={formInformation}
                formDetails={formdata.id}
                changeDataTrigger={(data) => changeDataTriggerEdit(data)}
                formSubmit={formSubmitClocked}
            />


            

            <Modal
                className="clocking-form-copntainer"
                open={isOpenResultPopup}
                onOk={clossPopup}
                onCancel={clossPopup}
                centered
                footer={[
                    <Button key="back" onClick={clossPopup} loading={loading}>
                        {t('!!OK, Got it')}
                    </Button>,

                ]}
            >
                <div className="msg-container">
                    <div className="lable-div">
                        <span className="lable-icon">
                            {/* <CheckCircleTwoTone twoToneColor="#52c41a" /> */}
                            <WarningOutlined twoToneColor="#e49c55"/>

                        </span>
                    </div>
                    <div className="lable-div">
                        <span className="title-div">
                            {t('Clock out is completed')}
                        </span>
                    </div>
                    <div className="lable-div">
                        <div className="lable-div-time-lable">
                            {t('Your clock Out Time')}
                        </div>
                        <div className="lable-div-time">
                            {time ? time : '-'}
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <Button disabled={!isEnableEdit} style={{ width: '100%' }} type="primary" danger onClick={() => formSubmitEdit()}>
                {t('Clock Out')}
            </Button> */}
        </>

    );
}