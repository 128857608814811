

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, InputNumber } from 'antd';
import Icon, { ReloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { init } from 'i18next';
const { Option } = Select;

const Calculation = (props) => {
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const { t, i18n } = useTranslation();
    const [drowDown, setDrowDown] = useState(0)
    const [Used, setUsed] = useState(0)
    const [Urenderfunsed, setrenderfyn] = useState(false)

    const [amount, setAmount] = useState(0)
    const init = async () => {

       
        setAmount(props?.data ? props.data : 0)



    }
    const updateData = async (e) => {

        const propData = props
        let path=propData.path
        var array = path.split(".");
       
        let index=array[array.length-2]

      let  group=array[array.length-3]


        const formData = wholeDataObject

        let operations = propData?.schema?.info?.operation ? propData.schema.info.operation : []
    

        let value = ''
        for (const operation of operations) {
            if(!formData?.database?.main[group]){
                continue
            }
            if(!formData?.database?.main[group][parseInt(index)]){
                continue
            }
            const valueOfCell=formData?.database?.main[group][parseInt(index)][operation.field]?formData.database.main[group][parseInt(index)][operation.field]:null
            if(!valueOfCell){
                continue
            }
            value += valueOfCell

         
            if (operation.operation) {
                value += operation.operation
            }
        }

        props.handleChange(props.path, eval(value))
    };
    //

    useEffect(() => {
        init()

        //
    }, [props]);
    return (
        <>
            <div>
                <div>
                    {props?.label ? props.label : ' '}
                    <Button danger type="text" onClick={updateData} shape="circle" icon={<ReloadOutlined />} />

                </div>
                <div>
                    {amount}
                </div>
            </div>



        </>



    );
}



export default withJsonFormsControlProps(Calculation);
