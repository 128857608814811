import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { ValueDisplay } from "../elements/ValueDisplay";


import {
    MinusCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    RedoOutlined,
} from "@ant-design/icons";


import { Radio, Tabs } from "antd";

import { MoreOutlined } from "@ant-design/icons";
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space,
    Checkbox,
    Form,
    Drawer,
    Typography,
    DatePicker,
} from "antd";
import DialogContent from "@mui/material/DialogContent";
import { ValueDisplay } from "../../../components/elements/ValueDisplay";
import { openMoreOptionDrawer } from "../../../redux/slices/answer/answerSetting";
import { workFlowDrawerOpen } from "../../../redux/slices/workflow";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: "8px 0",
    marginBottom: "10px",
};

export function GridCard(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();

    // const [data, setData] = useState(null);
    const [workFlow, setWorkFlow] = useState(null);
    const [display, setDisplay] = useState([]);
    // const [activeTask, setActiveTask] = useState(null);





    //   useEffect(() => {
    //     if (props?.dataRow?.data) {
    //       setData(props.dataRow.data);
    //     }

    //     if (props?.dataRow?.data?.workFlow) {
    //       setWorkFlow(props.dataRow.data.workFlow);
    //     }

    //     // if (props?.dataRow?.activeTask) {
    //     //   setActiveTask(props?.dataRow?.activeTask[0]);
    //     // }

    //     if (props?.dataRow?.display) {
    //       setDisplay(props.dataRow.display);
    //     }

    //     console.log("props", props);
    //   }, [props]);

    const { data, showModal } = props;

    return (
        <div className="grid-row">
            <div className="grid-header">
                <div className="grid-header-1">
                </div>
                <div className="grid-header-2" style={{ width: "50%" }}>
                    <div>
                        {data.action}
                        {/* <Button icon={<MoreOutlined />} onClick={() => { }} /> */}
                    </div>
                </div>
            </div>
            <div className="grid-Body">
                {/* {display.map((val) => (
          <Fragment key={val.key}>{<ValueDisplay value={val} />}</Fragment>
        ))} */}
                <Fragment key={data.EMPStatus}>{<ValueDisplay value={{
                    col: {
                        title: "Status"
                    },
                    value: data.EMPStatus
                }} />}</Fragment>
                <Fragment key={data.EmployeeCode}>{<ValueDisplay value={{
                    col: {
                        title: "Employee Code"
                    },
                    value: data.EmployeeCode
                }} />}</Fragment>
                <Fragment key={data.EmployeeName}>{<ValueDisplay value={{
                    col: {
                        title: "Employee Name"
                    },
                    value: data.EmployeeName
                }} />}</Fragment>
                <Fragment key={data.StoreID}>{<ValueDisplay value={{
                    col: {
                        title: "Store ID"
                    },
                    value: data.StoreID
                }} />}</Fragment>
                <Fragment key={data.StoreName}>{<ValueDisplay value={{
                    col: {
                        title: "Store Name"
                    },
                    value: data.StoreName
                }} />}</Fragment>
                <Fragment key={data.Brand}>{<ValueDisplay value={{
                    col: {
                        title: "Brand"
                    },
                    value: data.Brand
                }} />}</Fragment>
                
                
            </div>
            <div className="grid-footer">
                <div className="grid-footer-created-by font-footer">
                    <span>

                    </span>
                </div>
                <div className="grid-footer-created-at font-footer">
                    <span>

                    </span>
                </div>
            </div>
        </div>
    );
}
