import React, { useState, useEffect } from "react";

import {
    useDispatch, useSelector
} from "react-redux";

import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';



import { ElementValue } from "./components/ElementValue"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { Cascader, Collapse, InputNumber, Select, Space } from 'antd';


import { changeRemarkReducer, changeCheckReducer, changeRemarkValueReducer } from "../../../../redux/slices/answer/answerSetting";
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Input } from 'antd';
import { Checkbox } from "antd";
const { TextArea } = Input;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};


export function FormViewInterface(props) {

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const [user, setUser] = useState({})

    const isLoading = useSelector((state) => {
        let data = false
        const pageState = state.answer ? state.answer : null
        if (pageState?.itemLoading) {
            data = pageState?.itemLoading ? pageState.itemLoading : []
        }

        return data
    })

    const isFormView = useSelector((state) => {

        let data = false
        const pageState = state.page ? state.page : null
        if (pageState.isFormView) {
            data = pageState.isFormView ? pageState.isFormView : false

        }

        return data
    })





    const jsonScema = useSelector((state) => {
        let data = []

        const pageState = state.answer ? state.answer : null
        if (pageState?.jsonSchema) {

            data = pageState?.jsonSchema ? pageState.jsonSchema : []
            console.log('data', data)
        }

        return data
    })


    const groupList = useSelector((state) => {
        let data = []
        const pageState = state.answer ? state.answer : null
        if (pageState?.elementGroup) {
            data = pageState?.elementGroup ? pageState.elementGroup : []
        }

        return data
    })

    const workFlow = useSelector((state) => {
        return state?.answer?.workFlow
    })

    const userDB = useSelector((state) => {
        let data = {}
        const pageState = state.answer ? state.answer : null

        if (pageState?.row) {
            data = pageState?.row?.data?.database?.main ? pageState.row.data.database.main : null
        }

        return data
    })


    const answerType = useSelector((state) => {
        let data = null
        const pageState = state.answer ? state.answer : null
        if (pageState?.type) {
            data = pageState?.type ? pageState.type : null
        }
        return data
    })

    const remarkChange = (key, value) => {


        dispatch(changeRemarkReducer({
            key: key,
            value: value,
            answerType: answerType
        }))
    }

    const remarkValueChange = (key, value) => {


        dispatch(changeRemarkValueReducer({
            key: key,
            value: value,
            answerType: answerType
        }))
    }

    const remarkCheck = (key, value) => {


        dispatch(changeCheckReducer({
            key: key,
            value: value,
            answerType: answerType
        }))
    }









    useEffect(() => {




    }, [props]);

    useEffect(() => {
        console.log("groupList", groupList, jsonScema, workFlow)
    }, [groupList, jsonScema, workFlow])



    return (
        <>
            {
                isLoading || !userDB ? (null) : (
                    <>
                        <Box sx={{ width: '100%' }}>
                            <div>
                                {groupList ? (
                                    <>
                                        <div>
                                            {
                                                groupList.map((row) => (
                                                    <>
                                                        {
                                                            row.groupType === 'array' ? <>
                                                                {
                                                                    <>
                                                                        {
                                                                            !userDB[row.groupId] ? null :

                                                                                <>
                                                                                    {
                                                                                        jsonScema.filter((e) => e.group === row.groupId).length === 0 ? null :
                                                                                            <>
                                                                                                <Grid item sm={12} md={12}>
                                                                                                    <div className='roup-el-container'>
                                                                                                        {/*<div className='group-el-header'>*/}
                                                                                                        {/*    {t(row.groupLable)}*/}

                                                                                                        {/*</div>*/}
                                                                                                        <TableContainer component={Paper}>
                                                                                                            <Table aria-label="simple table">
                                                                                                                <TableHead>
                                                                                                                    <TableRow>
                                                                                                                        {

                                                                                                                            jsonScema.filter((e) => e.group === row.groupId && e.type != 'total').map((element) => (
                                                                                                                                <TableCell>{t(element.lableTxt)}</TableCell>
                                                                                                                            ))
                                                                                                                        }

                                                                                                                    </TableRow>
                                                                                                                </TableHead>
                                                                                                                <TableBody>

                                                                                                                    {
                                                                                                                        userDB[row.groupId].map((elementGroup, index) => (
                                                                                                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                                                                {
                                                                                                                                    jsonScema.filter((e) => e.group === row.groupId && e.type != 'total').map((element) => (
                                                                                                                                        <TableCell align="center">{
                                                                                                                                            isLoading ? (null) : (
                                                                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                                                                                    <ElementValue type={element.type} value={userDB[row.groupId][index][element.keyTxt] ? userDB[row.groupId][index][element.keyTxt] : '-'} />
                                                                                                                                                    {element.checkInApproval && !isFormView ?
                                                                                                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                                                                            <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between",marginBottom:5 }}>
                                                                                                                                                                <Checkbox onChange={(es) => { remarkCheck(element, es.target.checked) }} />
                                                                                                                                                                <InputNumber style={{width:"95%",marginLeft:5}} onChange={(es) => remarkValueChange(element, es)} />
                                                                                                                                                            </div>

                                                                                                                                                            <TextArea rows={2} placeholder="Re-mark" onChange={(es) => remarkChange(element, es.target.value)} />

                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        null
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                        }</TableCell>


                                                                                                                                    )
                                                                                                                                    )

                                                                                                                                }

                                                                                                                            </TableRow>
                                                                                                                        )
                                                                                                                        )
                                                                                                                    }

                                                                                                                </TableBody>
                                                                                                            </Table>
                                                                                                        </TableContainer>

                                                                                                        {

                                                                                                            jsonScema.filter((e) => e.group === row.groupId && e.type == 'total').map((element) => (


                                                                                                                isLoading ? (null) : (
                                                                                                                    <h3 className="net-total">
                                                                                                                        {t(element.lableTxt)} :  <ElementValue type={element.type} value={userDB[element.keyTxt] ? userDB[element.keyTxt] : '-'} />
                                                                                                                    </h3>

                                                                                                                )



                                                                                                            )
                                                                                                            )

                                                                                                        }
                                                                                                        {

                                                                                                            jsonScema.filter((e) => e.group === row.groupId && e.type == 'clearing').map((element) => (


                                                                                                                isLoading ? (null) : (
                                                                                                                    <>
                                                                                                                        <h3 className="net-total">
                                                                                                                            Drowdown :  <ElementValue type='total' value={userDB[element.keyTxt].drowDown ? userDB[element.keyTxt].drowDown : '-'} />
                                                                                                                        </h3>
                                                                                                                        <h3 className="net-total">
                                                                                                                            Used :  <ElementValue type='total' value={userDB[element.keyTxt]?.used ? userDB[element.keyTxt].used : '-'} />
                                                                                                                        </h3>
                                                                                                                        <h3 className="net-total">
                                                                                                                            Refund :  <ElementValue type='total' value={userDB[element.keyTxt]?.pay ? userDB[element.keyTxt].pay : '-'} />
                                                                                                                        </h3>
                                                                                                                    </>

                                                                                                                )



                                                                                                            )
                                                                                                            )

                                                                                                        }

                                                                                                    </div>
                                                                                                </Grid>
                                                                                            </>
                                                                                    }
                                                                                </>



                                                                        }

                                                                    </>
                                                                }
                                                            </> : row.groupType === 'category' ?
                                                                <>
                                                                    <Collapse defaultActiveKey={['0']}>

                                                                        {
                                                                            groupList.filter((e) => e.selectedCategory === row.groupId && e.groupType === 'stage').map((stageGroup, index) => (
                                                                                <Collapse.Panel header={t(stageGroup.groupLable)} key={index}>
                                                                                    <div className='group-el-body' container>
                                                                                        {

                                                                                            jsonScema.filter((e) => e.group === stageGroup.groupId && e.type != 'lable_draft').map((element) => (

                                                                                                <div className='input-field'>


                                                                                                    <div className={element.fieldPlace === 'database' ? "element-value-database element-value-container" : 'element-value-bitrix element-value-container'} container rowSpacing={1}>

                                                                                                        <div item xs={4} md={4}>
                                                                                                            <div className='element-value'>
                                                                                                                <Stack direction="row" spacing={1}>

                                                                                                                    {t(element.lableTxt)}
                                                                                                                    {/*<Chip style={{marginLeft:4}} label={element.fieldPlace === 'bitrix'?'Bitrix':'Database'} color={element.fieldPlace === 'bitrix'?'warning':'error'} size="small" variant="outlined" />*/}
                                                                                                                </Stack>


                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div item xs={8} md={8} style={{ width: "100%" }}>
                                                                                                            <div className='element-lable'>
                                                                                                                {
                                                                                                                    element.fieldPlace === 'bitrix' ? (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                isLoading ? (null) : (
                                                                                                                                    <ElementValue type={element.type} value={user[element.keyTxt] ? user[element.keyTxt] : '-'} />
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    ) : null

                                                                                                                }
                                                                                                                {
                                                                                                                    element.fieldPlace === 'database' ? (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                isLoading ? (null) : (
                                                                                                                                    <>
                                                                                                                                        <ElementValue element={element} type={element.type} allData={userDB ? userDB : null} value={userDB[element.keyTxt] ? userDB[element.keyTxt] : '-'} />

                                                                                                                                    </>

                                                                                                                                )
                                                                                                                            }


                                                                                                                        </>
                                                                                                                    ) : null

                                                                                                                }



                                                                                                            </div>

                                                                                                        </div>


                                                                                                    </div>
                                                                                                    {
                                                                                                        element.checkInApproval && !isFormView ? <div className='input-field-checkbox'>

                                                                                                            <Checkbox onChange={(es) => { remarkCheck(element, es.target.checked) }} />
                                                                                                            <TextArea rows={4} placeholder="Re-mark" onChange={(es) => remarkChange(element, es.target.value)} />
                                                                                                            <div className='mark-div'> <InputNumber className='mark-input' onChange={(es) => remarkValueChange(element, es)} /></div>
                                                                                                        </div> : null
                                                                                                    }

                                                                                                </div>



                                                                                            )
                                                                                            )

                                                                                        }
                                                                                    </div>
                                                                                </Collapse.Panel>
                                                                                // <>
                                                                                //     <div className='roup-el-container'>
                                                                                //         <div>
                                                                                //             <h3>{t(stageGroup.groupLable)}</h3>
                                                                                //         </div>


                                                                                //     </div>
                                                                                // </>
                                                                            ))

                                                                        }
                                                                    </Collapse>


                                                                </>
                                                                : row.groupType === 'object' ?
                                                                    <>

                                                                        {

                                                                            jsonScema.filter((e) => e.group === row.groupId && e.type != 'lable_draft').length === 0 ? null :
                                                                                <>
                                                                                    <div>
                                                                                        <div className='group-el-container'>
                                                                                            <div>

                                                                                                <h3>{t(row.groupLable)}</h3>
                                                                                            </div>

                                                                                            <div className='group-el-body' container>

                                                                                                {

                                                                                                    jsonScema.filter((e) => e.group === row.groupId && e.type != 'lable_draft').map((element) => (

                                                                                                        <div className='input-field'>
                                                                                                            <div className={element.fieldPlace === 'database' ? "element-value-database element-value-container" : 'element-value-bitrix element-value-container'} container rowSpacing={1}>

                                                                                                                <div>
                                                                                                                    <div className='element-value'>
                                                                                                                        <Stack direction="row" spacing={1}>
                                                                                                                            {
                                                                                                                                element.type == 'lable_draft' && element.defaultLableValue ? <></> : <>  {element.lableTxt}</>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                isLoading ? (null) : (
                                                                                                                                    <ElementValue element={element} type={element.type} value={user[element.keyTxt] ? user[element.keyTxt] : '-'} />
                                                                                                                                )
                                                                                                                            }

                                                                                                                            {/*<Chip style={{marginLeft:4}} label={element.fieldPlace === 'bitrix'?'Bitrix':'Database'} color={element.fieldPlace === 'bitrix'?'warning':'error'} size="small" variant="outlined" />*/}
                                                                                                                        </Stack>


                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <div className='element-lable'>
                                                                                                                        {
                                                                                                                            element.fieldPlace === 'bitrix' ? (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        isLoading ? (null) : (
                                                                                                                                            <ElementValue type={element.type} value={user[element.keyTxt] ? user[element.keyTxt] : '-'} />
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            ) : null

                                                                                                                        }
                                                                                                                        {
                                                                                                                            element.fieldPlace === 'database' ? (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        isLoading ? (null) : (
                                                                                                                                            <>

                                                                                                                                                {
                                                                                                                                                    element.type == 'lable_draft' && element.defaultLableValue ? <> </> : <>  <ElementValue element={element} type={element.type} value={userDB[element.keyTxt] ? userDB[element.keyTxt] : '-'} /> </>
                                                                                                                                                }

                                                                                                                                            </>

                                                                                                                                        )
                                                                                                                                    }


                                                                                                                                </>
                                                                                                                            ) : null

                                                                                                                        }



                                                                                                                    </div>

                                                                                                                </div>


                                                                                                            </div>
                                                                                                            {
                                                                                                                element.checkInApproval && !isFormView ? <div className='input-field-checkbox'>
                                                                                                                    <Checkbox />
                                                                                                                </div> : null
                                                                                                            }


                                                                                                        </div>



                                                                                                    )
                                                                                                    )

                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </>
                                                                    : null
                                                        }


                                                    </>
                                                ))
                                            }
                                        </div>
                                    </>





                                ) : (null)
                                }



                            </div>
                        </Box>
                    </>
                )
            }





        </>
    );
}