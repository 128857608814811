import React, { useState, useRef, useEffect } from "react";
import Measure from "react-measure";
import { useUserMedia } from "./hooks/use-user-media";
import { useCardRatio } from "./hooks/use-card-ratio";
import { useOffsets } from "./hooks/use-offsets";

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
  Button,
} from "./styles";


const CAPTIOR_USER = "user";
const CAPTIOR_ENVIOREMENT = "environment";
const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: CAPTIOR_ENVIOREMENT },
};

const CAPTURE_OPTIONS_USER = {
  audio: false,
  video: { facingMode: CAPTIOR_USER },
};

export function Camera(props) {
  const canvasRef = useRef();
  const videoRef = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(true);
  const [images, setImages] = useState([]);
  const [isUser, setFontFace] = useState(true)
  const [mediaStream1, setMediaStream] = useState(null);

  let mediaStream = useUserMedia(isUser ? CAPTURE_OPTIONS : CAPTURE_OPTIONS_USER)
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );


  function rotateCamera() {
    setFontFace(!isUser)
    setIsVideoPlaying(false);

    if (isUser) {
      enableVideoStream(CAPTURE_OPTIONS_USER)
        .then((stream) => {

          mediaStream = stream
          if (stream) {
            videoRef.current.srcObject = stream;

          }
          //handleCanPlay()

        });
    } else {
      enableVideoStream(CAPTURE_OPTIONS)
        .then((stream) => {

          mediaStream = stream


          if (stream) {
            //&& videoRef.current && !videoRef.current.srcObject
            videoRef.current.srcObject = stream;

          }
          //handleCanPlay()

        });
    }

  }

  async function enableVideoStream(face) {

    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(
        face
      );

      setMediaStream(stream);

      // return function cleanup() {
      //   mediaStream.getTracks().forEach(track => {
      //     track.stop();
      //   });
      // };
    } catch (err) {

      // Handle the error
    }
    return stream;

  }







  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio),
    });
  }

  function handleCanPlay() {

    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();

  }

  function handleCapture() {
    const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );
    let tempImages = [...images];
    if (props.multiple) {
      canvasRef.current.toBlob((blob) => tempImages.push(blob), "image/jpeg", 1);
      if (images.length > 4) {
        images.shift();
      }
      setImages(tempImages);
      // props.onCapture(tempImages)
      setIsCanvasEmpty(false);
    } else {
      canvasRef.current.toBlob((blob) => props.onCapture(blob), "image/jpeg", 1);
      setIsCanvasEmpty(false);
      setIsFlashing(true);
    }
  }

  function handleClose() {

    props.onCapture(images)
  }

  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    props.onClear();
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            ref={measureRef}
            maxHeight={videoRef.current && videoRef.current.videoHeight}
            maxWidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              height: `100vh`,
            }}
          >
            <Video
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                // top: `-${offsets.y}px`,
                left: `-${offsets.x}px`,
              }}
            />

            <Overlay hidden={!isVideoPlaying} />

            <Canvas
              ref={canvasRef}
              width={container.width}
              height={container.height}
            />

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
          </Container>

          {isVideoPlaying && props.loading != true ? (
            <>
              <div style={({ display: 'flex', flexDirection: 'row' })}>
                {images.map((blobFile) => {
                  return (<img style={{ marginRight: 10 }} width='70' src={URL.createObjectURL(blobFile)} />)
                })}
              </div>
              <div className="row">
                <div className="cam-action-row">
                  <div style={{ width: container.width }} className="action-btn-container">
                    <div className="left-btn">
                      <IconButton className="capture-btn" style={{ color: 'white' }} onClick={() => { rotateCamera() }} aria-label="upload picture" component="label">

                        <RotateLeftIcon />
                      </IconButton>
                    </div>
                    <div className="center-btn">
                      <IconButton className="capture-btn" style={{ color: 'white' }} onClick={isCanvasEmpty ? handleCapture : handleClear} aria-label="upload picture" component="label">

                        <PhotoCamera />
                      </IconButton>

                    </div>
                    <div className="rightr-btn">
                      <IconButton className="capture-btn" style={{ color: 'white' }} onClick={props.clossCamera} aria-label="upload picture" component="label">

                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                    <div>

                    </div>

                  </div>
                  {/* <Button1 className="camera-icon-stagetree-cam" type="ghost" icon={<RetweetOutlined  />} onClick={() => { rotateCamera() }} /> */}



                </div>


                {props.multiple && images.length > 0 && (
                  <button className="btn punch-btn" onClick={() => { handleClose() }}>Close and Save</button>
                )}
              </div>
            </>
          ) : (
            null
          )}
        </Wrapper>
      )}
    </Measure>
  );
}
