import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

let initialState = {
    sideMenuItems: [
        {
            title: 'Main',
            key: 'main',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Dashboard',
                    key: 'dashboard',
                    icon: 'fas fa-chart-line',
                    to: '/dashboard',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'WorkFlow',
                    key: 'workflow',
                    icon: 'fas fa-chart-line',
                    to: '/workflow',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'EMPLOYEE',
            key: 'employee',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Users',
                    key: 'users',
                    icon: 'fas fa-users',
                    to: '/users',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Attendance',
                    key: 'Attendance',
                    icon: 'fas fa-users',
                    to: '/attendance',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Attandance List',
                    key: 'AttendanceList',
                    icon: 'fas fa-users',
                    to: '/attendance-list',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'SETTINGS',
            key: 'settings',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Form',
                    key: 'form',
                    icon: 'fa fa-list',
                    to: '/manage-form',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Component',
                    key: 'component',
                    icon: 'far fa-folder',
                    to: '/manage-component',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Language',
                    key: 'lanaguage',
                    icon: 'fas fa-globe',
                    to: '/language',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Configuration',
                    key: 'configuration',
                    icon: 'fa fa-cogs',
                    to: '/app-config',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'SideBar',
                    key: 'sidebase',
                    icon: 'fa fa-list',
                    to: '/side-bar-config',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Permission',
                    key: 'permission',
                    icon: 'fa fa-list',
                    to: '/role',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'ACCOUNT PAGES',
            key: 'account_page',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Profile',
                    key: 'profile',
                    icon: 'far fa-address-card',
                    to: '/profile',
                    editable: false,
                    hide: false,
                }
            ]
        }
    ]
};





export const getSiteBarPermisionByRole = createAsyncThunk(
    "permision/getSiteBarPermisionByRole",
    async () => {
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/setting/get-site-bar-header-by-user-role/`;

        const response = await axios.get(endPoint);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);


export const changeHeaderVisibilityReducer = createAsyncThunk(
    "permision/changeHeaderVisibilityReducer",
    async (data) => {
         
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/role/manage-header-visibility/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, data, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);

export const changeSiderListVisibilityReducer = createAsyncThunk(
    "permision/changeSiderListVisibilityReducer",
    async (data) => {
         
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/role/manage-list-visibility/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, data, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);



export const changeListActionReducer = createAsyncThunk(
    "permision/changeListActionReducer",
    async (data) => {
         
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/role/manage-list-visibility/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, data, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);



export const getAllHeaderByRole = createAsyncThunk(
    "permision/getAllHeaderByRole",
    async (data) => {
        //  
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/role/get-all-header-by-role/${data}`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);











export const permisionSlice = createSlice({
    name: "permision",
    initialState,
    extraReducers: {

        [getSiteBarPermisionByRole.fulfilled]: (state, action) => {


        },
        //manage header visibilty
        [changeHeaderVisibilityReducer.fulfilled]: (state, action) => {

             
        },
        [getAllHeaderByRole.fulfilled]: (state, action) => {

            const dynHeader = action.payload.data

            let initSt = initialState.sideMenuItems.concat(dynHeader)
            let chileds = action.payload.list
            const headerPermisionList = action.payload.permision.lostVisibilityArrat


            const headerPermision = action.payload.permision.headerPermision



             

            for (let index = 0; index < initSt.length; ++index) {
                const header = initSt[index];


                const filteredChild = chileds.filter((e) => e.parentkey === header.key)


                initSt[index] = {
                    ...initSt[index],
                    hasAccess: headerPermision.find((e) => e.value && e.key == header.key) ? true : false
                }

                if (header.items) {
                    let tempArray = initSt[index].items


                     
                    for (const fill of filteredChild) {

                        let fillobj = {
                            ...fill,
                        }
                        initSt[index] = {
                            ...initSt[index],
                            items: [...initSt[index].items, fillobj]
                        }



                    }



                } else {




                    initSt[index].items = [
                        ...filteredChild
                    ]


                }




            }


            const datapackage = {
                ...state,
                sideMenuItems: initSt.map((data) => {

                    return {
                        ...data,
                        items:data.items.map((itemData)=>{
                            const addAccess = headerPermisionList.find((obj) => obj.key === itemData.key)
                            const deleteAccess = headerPermisionList.find((obj) => obj.key === itemData.key)
                            const updateAccess = headerPermisionList.find((obj) => obj.key === itemData.key)
                            const importAccess = headerPermisionList.find((obj) => obj.key === itemData.key)
                            const exportAccess = headerPermisionList.find((obj) => obj.key === itemData.key)
                            return {
                                ...itemData,
                                Add: addAccess?.ADD ? true : false,
                                Delete : deleteAccess?.DELETE ? true : false,
                                Update : updateAccess?.UPDATE ? true : false,
                                Import: importAccess?.IMPORT ? true : false,
                                Export: exportAccess?.EXPORT ? true :false,
                                hasAccess:headerPermisionList.find((e) => e.value && e.key == itemData.key) ? true : false
                            }
                        })
                    };

                }
                )
            }

            return datapackage
        },



    },
});

export default permisionSlice.reducer;


