import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import moment from 'moment'
import {Col} from "antd";
import {ElementValue} from "../../pages/settings/JsonForm/viewInteface/components/ElementValue";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function ValueDisplay(props) {

    const dispatch = useDispatch();

    const [value, setValue] = useState(null)
    const [key, setKey] = useState(null)
    const [col, setCol] = useState(null)

    const init = () =>{
         
        setValue(props?.value?.value?props.value.value:null)
        setKey(props?.value?.key?props.value.key:null)
        setCol(props?.value?.col?props.value.col:null)


    }
    useEffect(() => {

        init();

    }, [props]);



    return (

        <div className="data-div-att data-div-value-display" style={style}>
            <div className='lable-txt'>
                {
                    col?.title?<>{col.title} : </>:null
                }
            </div>
            <div>
                {
                    value && col?.title? <ElementValue type={col.type} value={value} />:null

                }

            </div>
        </div>

    );
}