import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import i18n from "i18next";

import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@mui/material";
import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    ForwardOutlined,
    CopyOutlined,
    UnorderedListOutlined,
    SettingOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";





import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";

import {
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select as AntSelect,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form, Spin
} from "antd";

import { getDynamicPageInitial, FormLoading } from "../redux/slices/dynamicPage";
import { changeDataReducer } from "../redux/slices/ruleElement";
import {
    getFormSchema, getAllGroupRaducer
} from "../redux/slices/formSettings";

import { DynamicAnswer } from './settings/JsonForm/compoment/DynamicAnswer'

import * as XLSX from "xlsx";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const Dynamic = (props) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [answerListSelectedId, setListSelectedId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [containerData,setContainerData] = useState(null);
    const [currentLanguage,setCurrentLanguage] = useState(i18n.language) 
    const containerDatas = useSelector((state) => state.page)

    const setContainersData = () => {
        console.log("language changed",containerDatas)
         
        if (!containerDatas?.contain) {
            return false
        }

        let container = containerDatas.contain
        if (!container?.form?.id) {
            return false
        }
        if (!container?.form?.schema || !container?.form?.group) {
            return false
        }

        const formSchema = container.form.schema
        const gg = container.form.group

        //create coloumn
        let colArray = []
        const widthcol = (90 / formSchema.length)
        colArray.push(

            {
                title: t("Action"),
                key: "action",
                dataIndex: "action",
                width: '5%'
            },
        )
        for (const scemaEl of formSchema) {

            if (scemaEl.showInHeader || scemaEl.type=='index') {
                colArray.push({
                    title: t(scemaEl.lableTxt),
                    dataIndex: scemaEl.keyTxt,
                    key: scemaEl.keyTxt,
                    width: widthcol + '%',
                    sorter: (a, b) => a.keyTxt - b.keyTxt,
                    enum:scemaEl.enum?scemaEl.enum:[],
                    type:scemaEl.type,
                    fullObject:scemaEl.fullObject?scemaEl.fullObject:[]
                })
            }



        }
        
        colArray.push(
            {
                title: t("Attendance Date"),
                key: "createdAt",
                dataIndex: "createdAt",
                width: '5%',
            },
        )

       
        //create coloumn end

        // add data


        //add data end

        container = {
            ...container,
            schema: formSchema,
            groupe: gg,
            column: colArray
        }
        setContainerData((prevState)=>container) 
        return container
    }
    const dataHandle = async (id) => {
        //dispatch(changeDataReducer(id))
        
    }


    const init = async (id) => {
        setLoading(true)
        await dispatch(getDynamicPageInitial(id))
        setListSelectedId(id)
        setLoading(false)
    }

    useEffect(()=>{
        if(containerDatas){
            setContainersData()
        }
    },[containerDatas])
    const handleLanguageChange = (lang) => {
        setCurrentLanguage(lang)
        console.log("lang changed",lang,containerData)
      };
  
    useEffect(()=>{
        i18n.on("languageChanged",handleLanguageChange)
        return () => {
            i18n.off("languageChanged", handleLanguageChange);
            };
    },[])

    useEffect(()=>{
        setLoading(true)
        console.log("currentLanguage changed",setContainersData())
        setLoading(false)
    },[currentLanguage])

    useEffect(() => {
        init(props.match.params.id)

    }, [props.match.params.id]);
    return (

        <React.Fragment>

            <Grid container spacing={6}>
                <Grid item xs={12}>

                    <div className="page-continer ">
                        {
                            loading ? <div className="example"> <Spin /></div> :
                                <>
                                    {
                                       containerData && containerData?.schema ? <>
                                            <DynamicAnswer dataHandle={dataHandle} formId={answerListSelectedId} containerData={containerData} form={containerData.form} schema={containerData.schema} groupList={containerData.groupe} columns={containerData.column} />
                                        </> : null
                                    }

                                </>

                        }

                    </div>
                </Grid>
            </Grid>

        </React.Fragment>

    );
};

export default Dynamic;
