import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';

import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";

import { init } from 'i18next';

import Select from 'react-select';

const MultiSelectTree = (props) => {
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState([])
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedSubGroup, setSelectedSubGroup] = useState(null)
    const [enumList, setEnumList] = useState([])
    const [allList, setAllList] = useState([])
    const [groupList, setGroupList] = useState([])
    const [subgroupList, setSubGroupList] = useState([])
    const [lableName, setLableName] = useState('')
    const [isLoading, loading] = useState(true)
    const [isLoadingEnumValue, setLoadngEnumValue] = useState(false)
    const [fullObj, setfullObj] = useState([])
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const [allEnum,setAllEnum]=useState([])

    const errorList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.condition) {
                if (props?.schema?.schemaElement?.ConditionBase) {
                    const conitionBase = props?.schema?.schemaElement?.ConditionBase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })

    const filterList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.filter) {
                if (props?.schema?.schemaElement?.filterbase) {
                    const conitionBase = props?.schema?.schemaElement?.filterbase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })

    const getConditionalData = async () => {
        
        if (props?.schema?.schemaElement?.ConditionBase)
        {
            let obj = {
                schama: props?.schema?.schemaElement ?props.schema.schemaElement: null,
            value: errorList
            }
            setLoadngEnumValue(true)
            if(errorList){
                let Sub_Grouping_By = wholeDataObject?.database?.main?.Sub_Grouping_By

                if(wholeDataObject?.database?.main?.Grouping_By){
                    obj.schama = { ...obj.schama, "grouped" : wholeDataObject?.database?.main?.Grouping_By }
                }

                if(wholeDataObject?.database?.main?.sortBy ){
                    if(wholeDataObject?.database?.main?.sortBy.length > 0 ){
                        obj.schama = { ...obj.schama, "sortBy" : wholeDataObject?.database?.main?.sortBy }
                    }
                }

                const p= await dispatch(getCOnditionalEnum(obj))
                let unum=p?.payload?.data?.data?.enum?p.payload.data.data.enum:[]
                const groupdKey = obj.schama.grouped ? obj.schama.grouped : null
                const allList =p?.payload?.data?.data?.fullObject?p.payload.data.data.fullObject:[] 
               
               
                let opt=[]
                let subOpt=[]


                let rand=[]
                let enRand=[]
                if (props?.schema?.schemaElement?.filterbase) { 
                  
                    if (props?.schema?.schemaElement?.filterField && filterList) {
                        const filField = props.schema.schemaElement.filterField

                        
                        for(const tempProd of allList){
                            if(tempProd.data[filField]){
                                const filterbleData=tempProd.data[filField]?tempProd.data[filField]:[]
                                if(Array.isArray(filterbleData)){
                                  
                                    if(  filterbleData.find((e)=>e==filterList)){
                                        rand.push(tempProd)
                                    }
                                   
                                }
                               //if()
                            }
                        }
                        for(const tempProd of unum){
                            if(tempProd.schema[filField]){
                                const filterbleData=tempProd.schema[filField]?tempProd.schema[filField]:[]
                                if(Array.isArray(filterbleData)){
                                  
                                    if(  filterbleData.find((e)=>e==filterList)){
                                        enRand.push(tempProd)
                                    }
                                   
                                }
                                //if()
                            }
                        }
                  
        
                    }else{
                        rand=allList
                        enRand=unum
                    }
        
        
        
        
                }else{
                    rand=allList
                    enRand=unum
                }
                
                let groupSet = await uniqByKeepFirst(rand, it => it.data[groupdKey])
                
                
                for(const els of groupSet){
                    let lable=`${els.data.brand_menu?els.data.brand_menu:'-'} - ${els.data.PRODUCT_SHORT_DESCRIPTION?els.data.PRODUCT_SHORT_DESCRIPTION:''} - ${els.data[obj.schama.grouped]}`

                    if(els.data[obj.schama.grouped]){
                        opt.push({
                            value:els.data[obj.schama.grouped],
                            label:lable,
                        })
                    } 

                }

                if(Sub_Grouping_By){
                    let subgroupSet = await uniqByKeepFirst(rand, it => `${it.data[Sub_Grouping_By]}`)
                    for(const elsub of subgroupSet){
                        let lable=`${elsub.data.brand_menu?elsub.data.brand_menu:'-'} - ${elsub.data[Sub_Grouping_By]?elsub.data[Sub_Grouping_By]:''}`
    
                        subOpt.push({
                            value:elsub.data[Sub_Grouping_By],
                            label:lable,
                        })
                            
                        setSubGroupList(subOpt);
                    }
                }
                

                setfullObj(opt)
                setGroupList(opt)
                

                if (enRand) {
    
                    let en=enRand
                    setAllEnum(en)
                }

                if (props.data) {
                    if (props.data.selectedgroup) {
                       let firs= opt.find((e)=>e.value==props.data.selectedgroup)
                       if(firs){
                      //  let lablea=`${firs.data.productDescription?firs.data.productDescription:firs.data[props.schema.schemaElement.grouped]} - ${firs.data.productName?firs.data.productName:''}`
                        let oj={
                            value:firs.value,
                            label:firs.label
                        }
                        setSelectedGroup(oj)   
                        
                    }

                    if (props.data.selectedsubgroup) {
                        let sec= subOpt.find((e)=>e.value==props.data.selectedsubgroup)
                        if(sec){
                            //  let lablea=`${firs.data.productDescription?firs.data.productDescription:firs.data[props.schema.schemaElement.grouped]} - ${firs.data.productName?firs.data.productName:''}`
                            let oj = {
                                value:sec.value,
                                label:sec.label
                            }
                            setSelectedSubGroup(oj)   

                        }
                    }
                       
        
        
                        if (enRand) {
        
                            let groupedEnumList=enRand.filter((e)=>e.grouped===props.data.selectedgroup)
                           
                            let opt=[]
                            if (groupedEnumList) {
                                
                                for(const els of groupedEnumList){
                                    opt.push({
                                        value:els.const,
                                        label:els.title,
                                    })
                                }
                
                            //     
                                setEnumList(opt)
                                
                                // setEnumList(props.schema.enumList)
                                if(props?.data?.value){
                                    let ans=[]
                                    for(const els of props.data.value){
                          
                                     
                                         let lable=opt.find((e)=>e.value==els)
                    
                                        ans.push({
                                            value:els,
                                            label:lable?.label?lable.label:'-'
                                        })
                                    }
                                    setValueData(ans)
                                }
        
                               
                
                            }
                        }
        
                    }
                }
                // setAllEnum
                //  
                // setEnumList(unum)
               setLoadngEnumValue(false)
              
            }
           
        }
           
        
    }

   

    useEffect(() => {
        getConditionalData()

    }, [errorList,filterList]);

    const updateData = async (e) => {

        // setValueData(props.data)
       
        
        let obj = {
            selectedgroup: e.value,
            value: valueData ? valueData : []
        }
   
        props.handleChange(props.path, obj)


    };

    const updateDataValue = async (e) => {
 
        // setValueData(props.data)
   
        let arr=[]
        for(const els of e){
            arr.push(els.value)  
           
        }

        let obj = {
            selectedgroup: selectedGroup.value,
            value:arr
        }
        props.handleChange(props.path, obj)


    };

    


    const getFilterData = async () => {
        if (props?.schema?.schemaElement?.filterbase) {
           if (props?.schema?.schemaElement?.filterField) {
                const filField = props.schema.schemaElement.filterField
                const datasetFilter=fullObj.filter((e)=>e.data.Format===filterList)
                
                let opt = []
                if(props?.schema?.schemaElement?.enumLable){
                    for (const els of datasetFilter) {

                        opt.push({
                            value: els.const,
                            label: els.data[props.schema.schemaElement.enumLable]?els.data[props.schema.schemaElement.enumLable]:'-',
                        })
                    }
                }
                
                setEnumList(opt)
              
            }




        }


    }


    const uniqByKeepFirst = (a, key) => {
        let seen = new Set();
        return a.filter(item => {
            let k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }
    const init = async (e) => {
        loading(true)
        const allList = props.schema.schemaElement.fullObject ? props.schema.schemaElement.fullObject : []

        const groupdKey = props.schema.schemaElement.grouped ? props.schema.schemaElement.grouped : null

    
        const groupSet = await uniqByKeepFirst(allList, it => it.data[groupdKey])
      
        if (props?.schema?.schemaElement?.condition) {
            if (props.data) {
                if (props.data.selectedgroup) {
                   let firs= groupList.find((e)=>e.value==props.data.selectedgroup)
                   if(firs){
                  //  let lablea=`${firs.data.productDescription?firs.data.productDescription:firs.data[props.schema.schemaElement.grouped]} - ${firs.data.productName?firs.data.productName:''}`
                    let oj={
                        value:firs.value,
                        label:firs.label
                    }
                    setSelectedGroup(oj)   
                    //console.log(props.data,'props.data.props.data.props.data2')
                }
                   
    
    
                    if (allEnum) {
    
                        let groupedEnumList=allEnum.filter((e)=>e.grouped===props.data.selectedgroup)
                        let opt=[]
                        if (groupedEnumList) {
                    
                            for(const els of groupedEnumList){
                                
                                opt.push({
                                    value:els.const,
                                    label:els.title,
                                })
                            }
            
                            setEnumList(opt)
                            
                            // setEnumList(props.schema.enumList)
                            if(props?.data?.value){
                                let ans=[]
                                for(const els of props.data.value){
                                 
                                    //console.log(els,'elselsels2')
                                     let lable=opt.find((e)=>e.value==els)
                                    if(lable?.label){
                                        ans.push({
                                            value:els,
                                            label:lable?.label ? lable.label:'-'
                                        })
                                    }
                                }
                                setValueData(ans)
                            }
    
                           
            
                        }
                    }
    
                }
            }
           
        } else {
            let groupdEle = props?.schema?.schemaElement?.grouped
            let opt=[]
            
            if(wholeDataObject?.database?.main?.Grouping_By){
                groupdEle = wholeDataObject?.database?.main?.Grouping_By
            }

            if(groupdEle){
               
                for(const els of groupSet){
                    
                    let lable=`${els.data.productDescription?els.data.productDescription:els.data[groupdEle]} - ${els.data.productName?els.data.productName:''}`

                        opt.push({
                            value:els.data[groupdEle],
                            label:lable,
                        })
                    
                }
            }
            
    
            setGroupList(opt)
    
            if (props.data) {
                if (props.data.selectedgroup) {
                   let firs= opt.find((e)=>e.value==props.data.selectedgroup)
                   if(firs){
                  //  let lablea=`${firs.data.productDescription?firs.data.productDescription:firs.data[groupdEle]} - ${firs.data.productName?firs.data.productName:''}`
                    let oj={
                        value:firs.value,
                        label:firs.label
                    }
                    setSelectedGroup(oj)   
                    //console.log(props.data,'props.data.props.data.props.data3')
                }
                   
    
    
                    if (props?.schema?.enumList) {
    
                        let groupedEnumList=props.schema.enumList.filter((e)=>e.grouped===props.data.selectedgroup)
                        let opt=[]
                        if (groupedEnumList) {
                    
                            for(const els of groupedEnumList){
                                
                                opt.push({
                                    value:els.const,
                                    label:els.title,
                                })
                            }
            
                            setEnumList(opt)
                            
                            //setEnumList(props.schema.enumList)
                            if(props?.data?.value){
                                let ans=[]
                                for(const els of props.data.value){
                               
                                     let lable=opt.find((e)=>e.value==els)
                
                                    ans.push({
                                        value:els,
                                        label:lable?.label?lable.label:'-'
                                    })
                                }
                                setValueData(ans)
                            }
    
                           
            
                        }
                    }
    
                }
            }
       
        }

        
        
       // setValueData(props.data ? props.data : null)
        setLableName(props.schema.schemaElement.lableTxt ? props.schema.schemaElement.lableTxt : '')






        loading(false)

    };

    //

    useEffect(() => {

        init()

        //
    }, [props]);
    return (
        <>
            {
                !isLoading ? <>

                    <div style={{
                        width: '100%',
                        marginTop: '15px',
                    }}>{t(lableName)}{
                            props?.required ? <span className='required-field'>*</span> : null
                        }</div>

                    <Select  isSearchable={false} value={selectedGroup} onChange={(e)=>{updateData(e)}} options={groupList} />
                    
                    {wholeDataObject?.database?.main?.Sub_Grouping_By && <>Product Subgroup
                    <Select  isSearchable={false} value={selectedSubGroup} onChange={(e)=>{updateData(e)}} options={subgroupList} />
                    </>}
                    {/* <Select
                     getPopupContainer={trigger => trigger.parentNode}
                     listHeight={150}
                        allowClear
                        style={{
                            width: '100%',
                            marginBottom: '15px'
                        }}
                        placeholder={t('Product SKU')}
                        value={selectedGroup}
                        onChange={(e) => updateData(e)}
                    >


                        {groupList.map((row) => (
                            <Option key={row.data[props.schema.schemaElement.grouped]}>{row.data.productDescription?row.data.productDescription:row.data[props.schema.schemaElement.grouped]} - {row.data.productName?row.data.productName:''}</Option>
                        ))}



                    </Select> */}


                            PRODUCT LIST
                            <Select isMulti isSearchable={false}  value={valueData} onChange={(e)=>{updateDataValue(e)}} options={enumList} />
                    {/* <Select
                     getPopupContainer={trigger => trigger.parentNode}
                     listHeight={150}
                     mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                            marginBottom: '15px'
                        }}
                        placeholder={t('Product BARCODE')}
                        value={valueData}
                        onChange={(e) => updateDataValue(e)}
                    >


                        {enumList.map((row) => (
                             <Option key={row.const}>{row.title}</Option>
                        ))}



                    </Select> */}

                </> : null
            }


        </>



    );
}



export default withJsonFormsControlProps(MultiSelectTree);
