
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Unautherize from "./pages/Unautherize";
import keycloak from "./utils/keycloak";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Auth from "./pages/Auth";

import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Dynamic from "./pages/Dynamic";
import DynamicIfram from "./pages/DynamicIfram";



import PublicForm from "./pages/form-interfaces/PublicForm";


//form
import ManageForm from "./pages/settings/JsonForm/ManageForm";
import UserSetting from "./pages/settings/JsonForm/UserSetting";
import WorkFlowMgt from "./pages/settings/JsonForm/workflowManagement/WorkFlowMgt";
import ExportProfile from "./pages/settings/JsonForm/ExportManagement/ExportProfile";

import ViewAnswers from "./pages/settings/JsonForm/ViewAnswers";
import ManageComponentSettings from "./pages/settings/JsonForm/ManageComponentSettings";
import TaskProcess from "./pages/settings/JsonForm/intefaces/TaskProcess";
//

//

import WorkFlow from "./pages/workflow/WorkFlow";
//


//form
import Language from "./pages/settings/Language/Language";
import Permission from "./pages/settings/Permission/Permission";
import PermisionEdit from "./pages/settings/Permission/Manage/PermisionEdit";
//

//config
import AddConfig from "./pages/settings/Config/AppConfig/AddConfig";
import SideBarConfig from "./pages/settings/Config/AppConfig/SideBarConfig";


//


//user

import Users from "./pages/Users/Users";
import UserAttandance from "./pages/attandence/UserAttandance";
import Attendance from "./pages/attandence/Attendance";
//
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./i18n";

import { ProtectedRoute } from './protected.route'

function App() {
  console.log('welcome to the intranet')
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <Provider store={store}>

          <Switch>
          
          <Route path="/auth" exact component={Auth} />
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/sign-in" exact component={SignIn} />
            <Route exact path="/public-form/submit-form" component={PublicForm} />
            <Main>
              <ProtectedRoute exact path="/" component={Home} />
              <ProtectedRoute exact path="/dashboard" component={Home} />
              <ProtectedRoute exact path="/workflow" component={WorkFlow} />

              <ProtectedRoute exact path="/tables" component={Tables} />
              <ProtectedRoute exact path="/billing" component={Billing} />
              <ProtectedRoute exact path="/rtl" component={Rtl} />
              <ProtectedRoute exact path="/profile" component={Profile} />



              <ProtectedRoute exact path="/dynamic-frame/:id" component={DynamicIfram} />



              <ProtectedRoute exact path="/users" component={Users} />
              <ProtectedRoute exact path="/attendance-list" component={UserAttandance} />

              <ProtectedRoute exact path="/attendance" component={Attendance} />
              <ProtectedRoute exact path="/manage-component" component={ManageComponentSettings} />

              <ProtectedRoute exact path="/manage-form" component={ManageForm} />
              <ProtectedRoute exact path="/manage-form/work-flow" component={WorkFlowMgt} />
              <ProtectedRoute exact path="/manage-form/export-profile" component={ExportProfile} />


              <ProtectedRoute exact path="/manage-form/scheema" component={UserSetting} />

              <ProtectedRoute exact path="/internal-form/answer" component={ViewAnswers} />

              <ProtectedRoute exact path="/language" component={Language} />
              <ProtectedRoute exact path="/unautherize" component={Unautherize} />

              <ProtectedRoute exact path="/role" component={Permission} />
              <ProtectedRoute exact path="/permission/:role" component={PermisionEdit} />

              <ProtectedRoute exact path="/dynamic-page/:id" component={Dynamic} />

              <ProtectedRoute exact path="/app-config" component={AddConfig} />
              <ProtectedRoute exact path="/side-bar-config" component={SideBarConfig} />
              <ProtectedRoute exact path="/task-process/:id" component={TaskProcess} />


            </Main>
          </Switch>
        </Provider>
      </ReactKeycloakProvider>


      {/* <ReactKeycloakProvider authClient={keycloak}>
        <Switch>
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route exact path="/public-form/submit-form" component={PublicForm} />
          <Main>
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute exact path="/dashboard" component={Home} />
            <ProtectedRoute exact path="/workflow" component={WorkFlow} />

            <ProtectedRoute exact path="/tables" component={Tables} />
            <ProtectedRoute exact path="/billing" component={Billing} />
            <ProtectedRoute exact path="/rtl" component={Rtl} />
            <ProtectedRoute exact path="/profile" component={Profile} />



            <ProtectedRoute exact path="/dynamic-frame/:id" component={DynamicIfram} />



            <ProtectedRoute exact path="/users" component={Users} />
            <ProtectedRoute exact path="/attendance-list" component={UserAttandance} />

            <ProtectedRoute exact path="/attendance" component={Attendance} />
            <ProtectedRoute exact path="/manage-component" component={ManageComponentSettings} />

            <ProtectedRoute exact path="/manage-form" component={ManageForm} />
            <ProtectedRoute exact path="/manage-form/work-flow" component={WorkFlowMgt} />
            <ProtectedRoute exact path="/manage-form/export-profile" component={ExportProfile} />


            <ProtectedRoute exact path="/manage-form/scheema" component={UserSetting} />

            <ProtectedRoute exact path="/internal-form/answer" component={ViewAnswers} />

            <ProtectedRoute exact path="/language" component={Language} />
            <ProtectedRoute exact path="/unautherize" component={Unautherize} />

            <ProtectedRoute exact path="/role" component={Permission} />
            <ProtectedRoute exact path="/permission/:role" component={PermisionEdit} />

            <ProtectedRoute exact path="/dynamic-page/:id" component={Dynamic} />

            <ProtectedRoute exact path="/app-config" component={AddConfig} />
            <ProtectedRoute exact path="/side-bar-config" component={SideBarConfig} />
            <ProtectedRoute exact path="/task-process/:id" component={TaskProcess} />


          </Main>
        </Switch>

      </ReactKeycloakProvider> */}

    </div>
  );
}

export default App;
