import React, { useState, useEffect } from "react";

import {
    closeAddExportReducer, addNewProfileReducer, updateProfileReducer
} from "../../../../redux/slices/internalExportSetting";
import {
    useDispatch, useSelector
} from "react-redux";
import '../style.css'
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox as AntCheckBox, Form
} from "antd";
import moment from 'moment'

export function Add(props) {

    const profileData = props.id ? props.id : null;

    const dispatch = useDispatch();
    const [profileName, setProfilename] = useState(null)
    const [selectedElement, setSelectedElement] = useState([])
    const isOpen = useSelector((state) => {


        let res = state?.exportInternalState?.isOpenAdd ? state.exportInternalState.isOpenAdd : false


        return res

    })

    const elements = useSelector((state) => {


        let res = state?.exportInternalState?.schamaList ? state.exportInternalState.schamaList : []


        let options = [
            {
                value: 'workFlow',
                lable: 'workFlow',
            },
            {
                value: 'checkList',
                lable: 'Check List',
            },
        ]
        for (let key of res) {
            let option = {
                value: key.keyTxt,
                label: key.lableTxt,
            }
            options.push(option)
        }
        return options

    })


    const changeProfileName = (e) => {


        setProfilename(e.target.value)

    }
    const changeSelectedElement = (e) => {
        setSelectedElement(e)


    }

    const closeAddExport = () => {

        dispatch(closeAddExportReducer())

    }


    const addNewProfile = () => {

        if (!profileName) {
            alert('please enter profile name')
            return
        }

        if (selectedElement.length === 0) {
            alert('please add one or more element')
            return
        }
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("id");
        const obj = {
            profileName: profileName,
            elements: selectedElement,
            form: myParam
        }
        if (profileData) {
            dispatch(updateProfileReducer({ ...obj, _id: profileData?._id }))
        } else {
            dispatch(addNewProfileReducer(obj))
        }

    }



    useEffect(() => {

        //elementCreate();

    }, [props]);


    const editUserSubmit = () => {

    }

    return (
        <>
            <Modal
                visible={isOpen}
                title="Approval Flow"

                onOk={closeAddExport}
                onCancel={closeAddExport}
                footer={[
                    <Button danger key="back" onClick={closeAddExport}>
                        Return
                    </Button>,
                    <Button danger key="submit" type="primary" onClick={addNewProfile}>
                        Save
                    </Button>
                ]}
            >
                <div>
                    Profile name :
                    <Input placeholder="Profile Name" defaultValue={profileData?.profileName} onChange={changeProfileName} />

                    Exporting Fields :
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                        }}
                    >
                        <Select
                            mode="multiple"
                            defaultValue={profileData?.elements ? profileData?.elements : []}
                            allowClear
                            onChange={(e) => { changeSelectedElement(e) }}
                            style={{
                                width: '100%',
                            }}
                            maxTagCount='responsive'
                            options={elements}
                        />
                    </Space>


                </div>

            </Modal>
        </>
    );
}