import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getFetchUsers } from "../../../../redux/slices/users";
import { getAllFormsReducer } from "../../../../redux/slices/formSettings";
import {
  addFormElementToServer,
  getFormSchema,
  editFormElementToServer,
  getAllGroupRaducer,
  changeOrderReducer,
  changeOrderElementReducer,
} from "../../../../redux/slices/formSettings";
import { InputNumber } from 'antd';
import { useDispatch } from "react-redux";
import "../style.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Grid,
  Divider as MuiDivider,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import { spacing } from "@mui/system";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

import {
  DeleteOutlined
} from "@ant-design/icons";

import {
  Row,
  Col,
  Button as AntButton,
  Select as AntSelect,
  Input,
  Modal,
  Checkbox as AntCheckBox
} from "antd";
import FormElement from "../FormElements";
const { Option } = AntSelect;

const SchemaElementAddEdit = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const acceptedFileType = [
    {
      const:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      lable: "EXCEL",
    },
    {
      const: "image/*",
      lable: "image",
    },
    {
      const: "video/*",
      lable: "video",
    },
    {
      const: "audio/*",
      lable: "audio",
    },
    {
      const: ".pdf",
      lable: "PDF",
    },
  ];

  const { t } = useTranslation();

  const Divider = styled(MuiDivider)(spacing);

  const [elementOption, setElementOption] = React.useState({
    lableTxt: null,
    keyTxt: null,
  });

  const [selectedTextType, changeSelectedType] = React.useState(null);

  const [dropDownValue, setDropDownVal] = React.useState([]);
  const [selectedFormId, setSelectedFormId] = React.useState(null);
  const [orderingValue, setOrderingValue] = React.useState([]);
  const [sortRefreshed, setSortRefreshed] = React.useState(true);

  const [orderLabel, setOrderLable] = useState('')
  const [valueDropDown, addValueDropDown] = React.useState(null);
  const [valueDropDownConst, addValueDropDownConst] = React.useState(null);
  const [schemaComponent, setScheemaComponent] = React.useState([]);
  const [defaultLableValue, changeLableDefaula] = useState(null)

  const [elementKeyList, manageElementKeyList] = React.useState([]);

  const [fileStoreType, changeFileStoreType] = React.useState("s3");
  const [s3Bucket, changeS3Bucket] = React.useState("");
  const [s3Dir, changeS3Dir] = React.useState("");

  const page = 0;
  const nameFilter = "";
  const emailFilter = "";
  const cityFilter = "";
  const lastNameFilter = "";

  const [isOpenAdvance, setAdvalceOpen] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [elementByGroup, setElementByGroup] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("main");

  const [savingType, setSavingType] = useState("database");

  const [selectedFileType, setSelectedFileType] = React.useState([]);

  const [fileSource, setFileSource] = React.useState("local");


  const [isLoadingElement, loadingElement] = useState(true);

  const [requier, setRequred] = useState(false);
  const [reference, setReference] = useState(false);

  const [selectedGroupConst, setSelectedGroupConst] = useState("main");

  const [enumLoadType, setEnumLoadType] = useState('custom');


  const [referenceKey, setReferenceKey] = useState(null);


  const [enumConst, setEnumCost] = useState(null);

  const [enumLable, setEnumLable] = useState(null);


  const [grouped, setGrouped] = useState(null);
  const [isLoadForm, setIsLoadForm] = useState(false)

  //advance
  const [showInHeader, setShowInHeader] = useState(false);
  const [targetValue, setTargetValue] = useState(null)
  const [searchableKey, setSearchableKey] = useState(false);
  const [sortable, setSortable] = useState(false);
  const [calucaluationArray, setCalculationArray] = useState(null)
  const [calucaluationArrayObj, setCalculationArrayObj] = useState([])
  const [dateInputType, setDateInputType] = useState(null)
  const [gridPayloadFrom, setGridPayloadFrom] = useState(null)
  const [gridPayloadGroup, setGridPayloadGroup] = useState(null)

  //form

  const [activeFormList, setActiveFormList] = useState([])
  const [selectedFormForPayload, setSelectedFormForPayload] = useState(null)
  const [selectFormEnumSet, setSelectedFormEnumSet] = useState([])

  const [selectedParentFormSchemaId, setselectedParentFormSchemaId] = useState(null)

  const [isHTML, setIsHTML] = useState(true)
  const [isBold, setIsBold] = useState(false)
  const [headerList, setheaderList] = useState(null)
  const [fontSize, setFontSize] = useState(14)

  const [selectType, setSelectType] = useState('single')
  const [lableShowingElement, setShowingElement] = useState([])

  const modifyFileType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFileType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  const requeredChecked = () => {
    setRequred(!requier);
  };

  const referenceChacked = () => {
    setReference(!reference);
  };





  const getAllGroup = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const res = await dispatch(getAllGroupRaducer(myParam));

    if (res.payload) {
      setGroupList(res.payload);
    }
  };


  const dispatch = useDispatch();

  const getFormSchimaToDom = async (e) => {
    loadingElement(true);
    const res = await dispatch(getFormSchema(e));

    setScheemaComponent(res.payload);
    changeGroup(selectedGroupId ? selectedGroupId : "main", res.payload);

    loadingElement(false);
  };

  const getAllKeyElement = async (e) => {
    let tempFilters = {
        nameFilter,
        lastNameFilter,
        emailFilter,
        cityFilter,
        page,
      };
  
      const res = await dispatch(getFetchUsers(tempFilters));
  
      let keyArray = res.payload.result[0];
      const propertyNames = Object.keys(keyArray);
  
      let tempObj = [];
  
      for (const el of propertyNames) {
        tempObj.push({ keyName: el });
      }
      
      manageElementKeyList(tempObj);
  };
  const changeDisplayValue = (e) => {
    if(props.isOpenEditSchema){
      setElementOption((prevState) => {
        return {
          ...prevState,
          lableTxt: e.target.value,
        };
      });
    } else {
      setElementOption((prevState) => {
        return {
          ...prevState,
          lableTxt: e.target.value,
          keyTxt: e.target.value.split(' ').join('_'),
        };
      });
    }
  };

  const [selectedParentFormList, setselectedParentFormList] = useState([])
  const [selectedParentFormListId, setselectedParentFormListId] = useState(null)
  const [selectedParentGroupeList, setSelectedParentGrouplist] = useState([])
  const ChangeSelectedParentForm = (id) => {

    const formTemp = parentFormList.find((e) => e.id === id)

    setselectedParentFormListId(id)
    setSelectedParentGrouplist(formTemp?.groupe)
    setselectedParentFormList(formTemp?.schema)

  }

  const changeWarning = (e) => {
    setElementOption((prevState) => {
      return {
        ...prevState,
        warning: e.target.value,
      };
    });
  };



  const [isDepent, setIsDepend] = useState(false)

  const [dependDataSet, setDependDataSet] = useState(null)
  const [dependKey, setDependKey] = useState(null)
  const [dependDataElement, setDependDataElement] = useState([])
  const [dependTargetValue, setDependTargetValue] = useState(null)
  const [parentFormList, setParentFormList] = useState([])
  const getSelectedFormForDepend = async (e) => {
    const res = await dispatch(getFormSchema(e));
    setDependDataSet(e)
    if (res.payload) {

      setDependDataElement(res.payload)
    }

  }

  const [indexRuleType, setIndexRuleType] = useState(null)

  const [randomNumberLength, setRandomNumberLength] = useState(0)
  const [dateType, setDateType] = useState(null)
  const [staticChar, setStatiChar] = useState(null)
  const [ruleArray, setRuleArray] = useState([])

  const [formSelectType, setFormSelectType] = useState(null)
  const [cloningForm, setCloaningForm] = useState(null)
  const [timmerMin, setTimmerMin] = useState(null)

  const changeIndexRuleType = (e) => {
    setIndexRuleType(e)
  }

  const addRuleFunction = () => {
    let obj = {
      type: '',
      value: ''
    }
    if (!indexRuleType) {
      alert('Type is Required')
      return
    }

    if (indexRuleType === 'date') {
      if (!dateType) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: dateType
      }
    }

    if (indexRuleType === 'static') {
      if (!staticChar) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: staticChar
      }
    }

    if (indexRuleType === 'random') {
      if (!randomNumberLength) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: randomNumberLength
      }
    }

    setRuleArray(oldArray => [...oldArray, obj]);

  }

  const [extraDisplayArray, setExtraDisplayArray] = useState([])
  const [searchKeyListArray, setSearchKeyListArray] = useState([])
  const [extrabasedOn, setExtraBasedOn] = useState([])

  const [imageTakenCount, setImageTakenCount] = useState(null)
  const [isLocationEnable, setLocationEnable] = useState(null)
  const [isDateEnable, setDateEEnable] = useState(null)
  const [isResumeEnable, setResumeEnable] = useState(null)
  const [parentFocusValue, setParentFocue] = useState(null)


  const changeTimeLineData = (e, lable) => {
    if (lable === 'Date') {
      setDateEEnable(e.target.checked)
    }
    if (lable === 'Location') {
      setLocationEnable(e.target.checked)
    }
    if (lable === 'resume') {
      setResumeEnable(e.target.checked)
    }

  }

  const addElementToSchema = async () => {
    let object = elementOption;

    if (schemaComponent.find((e) => e.keyTxt === object.keyTxt)) {
      alert("Cant use same element key for the multiple elements!");
      return;
    }
    if(elementOption.minChar && elementOption.maxChar && Number(elementOption.minChar) >= Number(elementOption.maxChar)) {
      alert("Min length should be less then Max length");
      return;
    }
    object.type = selectedTextType;
    object.fieldPlace = savingType ? savingType : "bitrix";
    object.group = selectedGroupConst === 'main' ? '' : selectedGroupConst;
    object.reference = reference ? reference : false;
    object.referenceKey = reference ? referenceKey : null;
    object.minChar = elementOption.minChar
    object.maxChar = elementOption.maxChar
    if (showInHeader) {
      object.showInHeader = showInHeader ? showInHeader : false;
      object.searchableKey = searchableKey ? searchableKey : false;
      object.sortable = sortable ? sortable : false;
    }

    if (reference === true && !referenceKey) {
      alert("Please select reference Key!");
      return;
    }

    if (!object.type) {
      alert("Please select element type!");
      return;
    }

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0 && enumLoadType === 'custom') {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      if (enumLoadType !== 'custom') {
        if (!enumConst) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!enumLable) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!selectedFormForPayload) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }

        object.enumLoadType = enumLoadType;
        object.enumConst = enumConst;
        object.enumLable = enumLable;
        object.grouped = grouped
        object.selectedFormForPayload = selectedFormForPayload; 
        object.extraDisplayArray = extraDisplayArray;
        object.basedOn = extrabasedOn;
        object.sortBy = orderingValue;
        object.searchKeyList = searchKeyListArray;
      } else {
        object.enumLoadType = enumLoadType;
        object.enum = dropDownValue;
      }
      if (isDepent) {
        if (!dependDataSet) {
          alert('please select depend form')

          return
        }
        if (!dependKey) {
          alert('please select depend form')

          return
        }
        if (!dependTargetValue) {
          alert('please select depend form')

          return
        }
        object.dependTargetValue = dependTargetValue;
        object.isDepent = true;
        object.dependDataSet = dependDataSet;
        object.dependKey = dependKey;
      }
      else {
        object.isDepent = false;
      }
      object.selectType = selectType
    }
    if (selectedTextType === "date") {
      object.format = "date";
      object.inputType = dateInputType;
    }

    if (selectedTextType === "location") {
      object.getType = "onClick";
    }

    if (selectedTextType === "timeLine") {
      object.isLocationEnable = isLocationEnable;
      object.isDateEnable = isDateEnable;
      object.isResumeEnable = isResumeEnable;

    }

    if (selectedTextType === "File" || 'html_camera' || selectedTextType === "cloacked") {
      object.fileStoreType = fileStoreType;
      object.s3Bucket = s3Bucket;
      object.s3Dir = s3Dir;
      object.selectedFileType = selectedFileType;
      object.captureType = fileSource;
      object.imageTakenCount = imageTakenCount
    }

    if (selectedTextType === "answer_view") {
      object.showingElements = lableShowingElement
    }

    if (selectedTextType === "formTimeOut") {
      object.showingElements = timmerMin
    }


    if (selectedTextType === "autofill") {
      object.parentFocusValue = parentFocusValue
    }


    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert("Selected element should have at least one Enum values!");

        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === 'total' || selectedTextType === 'clearing') {

      if (!targetValue) {
        alert()
        return
      }

      const gId = object.group
      const groupobj = groupList.find((e) => e.groupId == gId)
      if (groupobj.groupType != 'array') {
        alert('cant procees this action with this gorup')
        return
      }
      object.targetValue = targetValue;

    }

    if (selectedTextType === 'index') {

      if (ruleArray.length == 0) {
        alert('rules empty')
        return
      }

      object = {
        ...object,
        group: null,
        ruleArray: ruleArray
      }

    }

    if (selectedTextType === 'calculation') {
      object.operation = calucaluationArrayObj;
    }

    if (selectedTextType === 'data_grid' || selectedTextType === 'lable_draft') {

        object.gridPayloadFrom = gridPayloadFrom
        object.gridPayloadGroup = gridPayloadGroup
        object.defaultLableValue = defaultLableValue
        if(selectedTextType === 'lable_draft'){
          object.isHTML = isHTML
            if(!isHTML){
              object.isBold = isBold
              object.headerList = headerList
              object.fontSize = fontSize
            }
        }

    }
    if (selectedTextType === 'Form') {
      object = {
        ...object,
        formSelectType: formSelectType,
        cloningForm: cloningForm,
      }
    }

    object.formId = selectedFormId;

    await dispatch(addFormElementToServer(object));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

    setElementOption({
      lableTxt: null,
      keyTxt: null,
    });
    setIsHTML(true)
    setSavingType("database");
    setShowingElement([])
    props.closeAddSchema()

  };

  const updateElementToSchema = async () => {
    let object = elementOption;

    if( object.minChar && object.maxChar && Number(object?.minChar) >= Number(object?.maxChar)) {
      alert("Min length should be less then Max length");
      return;
    }

    object = {
      ...object,
      group: selectedGroupConst === 'main' ? '' : selectedGroupConst,
      isRequired: requier,
      reference: reference ? reference : false,
      referenceKey: reference ? referenceKey : null
    };

    object.type = selectedTextType;
    object.fieldPlace = savingType ? savingType : "bitrix";

    if (!object.type) {
      alert(`Please select element type!`);

      return;
    }

    if (showInHeader) {
      object.showInHeader = showInHeader ? showInHeader : false;
      object.searchableKey = searchableKey ? searchableKey : false;
      object.sortable = sortable ? sortable : false;
    }

    if (reference === true && !referenceKey) {
      alert(`Please select reference Key!`);

      return;
    }
    if (selectedTextType === 'calculation') {
        object.operation = calucaluationArrayObj;
      }
    if (selectedTextType === 'data_grid' || selectedTextType === 'lable_draft') {
        object.gridPayloadFrom = gridPayloadFrom
        object.gridPayloadGroup = gridPayloadGroup
        object.defaultLableValue = defaultLableValue
        if(selectedTextType === 'lable_draft'){
          object.isHTML = isHTML
          if(!isHTML){
            object.isBold = isBold
            object.headerList = headerList
            object.fontSize = fontSize
          }
        }
    }
    if (selectedTextType === 'Form') {
      object = {
        ...object,
        formSelectType: formSelectType,
        cloningForm: cloningForm,
      }
    }
    if (selectedTextType === "autofill") {
      object.parentFocusValue = parentFocusValue
    }

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0 && enumLoadType === 'custom') {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      if (enumLoadType !== 'custom') {
        if (!enumConst) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!enumLable) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!selectedFormForPayload) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }

        object.enumLoadType = enumLoadType;
        object.enumConst = enumConst;
        object.enumLable = enumLable;
        object.grouped = grouped
        object.selectedFormForPayload = selectedFormForPayload;
        object.extraDisplayArray = extraDisplayArray;
        object.basedOn = extrabasedOn;
        object.sortBy = orderingValue;
        object.searchKeyList = searchKeyListArray;
      } else {
        object.enumLoadType = enumLoadType;
        object.enum = dropDownValue;
      }

      if (isDepent === 'true' || isDepent === true) {
        if (!dependDataSet) {
          alert('please select depend form')

          return
        }
        if (!dependKey) {
          alert('please select depend form')

          return
        }
        if (!dependTargetValue) {
          alert('please select depend form')

          return
        }
        object.dependTargetValue = dependTargetValue;
        object.isDepent = true;
        object.dependDataSet = dependDataSet;
        object.dependKey = dependKey;

      }else {
        object.isDepent = false;
      }
      object.selectType = selectType
    }

    ////////
    if (selectedTextType === "date") {
      object.format = "date";
      object.inputType = dateInputType;
    }

    if (selectedTextType === "location") {
      object.getType = "onClick";
    }

    if (selectedTextType === "timeLine") {
        object.isLocationEnable = isLocationEnable;
        object.isDateEnable = isDateEnable;
        object.isResumeEnable = isResumeEnable;
      }
      if (selectedTextType === "formTimeOut") {
        object.showingElements = timmerMin
      }
    if (selectedTextType === "File" || 'html_camera' || "cloacked") {
      object.fileStoreType = fileStoreType;
      object.s3Bucket = s3Bucket;
      object.s3Dir = s3Dir;
      object.selectedFileType = selectedFileType;
      object.captureType = fileSource;
      object.imageTakenCount = imageTakenCount
    }
    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === "answer_view") {
      object.showingElements = lableShowingElement
    }

    if (selectedTextType === 'total' || selectedTextType === 'clearing') {

        if (!targetValue) {
          alert()
          return
        }
  
        const gId = object.group
        const groupobj = groupList.find((e) => e.groupId == gId)
        if (groupobj.groupType != 'array') {
          alert('cant procees this action with this gorup')
          return
        }
        object.targetValue = targetValue;
  
      }
    if (selectedTextType === 'index') {

      if (ruleArray.length == 0) {
        alert('rules empty')
        return
      }

      object = {
        ...object,
        group: null,
        ruleArray: ruleArray
      }
    }

    object.formId = selectedFormId;

    await dispatch(editFormElementToServer(object));

    props.editFormSuccess();
  };

  const changeOrderFunction = async (id, direction) => {
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };
    if (direction === "up") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex - 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex + 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderReducer(payloadOb));
    if (result) {
      getAllGroup();
    }
  };

  const changeOrderFunctionElement = async (id, direction) => {
    loadingElement(true);
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };

    if (direction === "up") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex - 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex + 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderElementReducer(payloadOb));

    // setSelectedFormId(myParam)
    getFormSchimaToDom(myParam);

    loadingElement(false);
  };

  const changeGroup = (id, data = false) => {
    let tempSchemaComponent = [];

    if (data) {
      if (id === "main") {
        tempSchemaComponent = data.filter((e) => e.group === null);
        const a = tempSchemaComponent;
        setElementByGroup(data.filter((e) => e.group === null));
      } else {
        tempSchemaComponent = data.filter((e) => e.group === id);
        setElementByGroup(tempSchemaComponent);
      }
    } else {
      if (id === "main") {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === null);
        setElementByGroup(tempSchemaComponent);
      } else {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === id);
        setElementByGroup(tempSchemaComponent);
      }
    }

    setSelectedGroupId(id);
  };

  const pushValueToDropDown = () => {
    let rempArray = dropDownValue;
    let obj = {
      const: "",
      title: "",
    };

    if (rempArray.find((e) => e.const === valueDropDownConst)) {
      alert(`Cant use same key for the multiple Enums!`);

      return;
    }

    obj.const = valueDropDownConst;
    obj.title = valueDropDown;

    addValueDropDown("");
    addValueDropDownConst("");

    setDropDownVal([...dropDownValue, obj]);
  };

  const removeDropDownValus = (item) => {
    if (dropDownValue) {
      setDropDownVal(dropDownValue.filter((post) => post.const != item));
    }
  };
  const pushValueToOrdering = () => {
    if (!orderLabel) {
      alert('Please select sort By')
      return false;
    }
    let obj = { "sort_key": orderLabel, "sort_type": 1, "sort_by": 'ALPHANUM' };
    setOrderLable('');
    setOrderingValue([...orderingValue, obj]);
  };

  const removeOrderingValus = (item) => {
    if (orderingValue) {
      setSortRefreshed(false);
      let selectedSorting = orderingValue
      selectedSorting.splice(item, 1)
      setOrderLable(item);

      setOrderingValue(selectedSorting);
      setTimeout(() => { setSortRefreshed(true); }, 100)
      setOrderLable('');
    }
  };

  const changeViewElement = (e) => {
    setShowingElement(e)
  }

  const onChangeTimmer = (e) => {
    setTimmerMin(e)
  }
  const gridPayloadParentForm = [
  { type: 'date' },
  { type: 'File' },
  { type: 'check' },
  { type: 'autofill' },
  { type: 'html_camera' },
  { type: 'number' },
  { type: 'drop' },
  { type: 'Form' },
  { type: 'location' },
  { type: 'clearing' },
  { type: 'total' },
  { type: 'dropTree' },
  { type: 'formTimeOut' },
  { type: 'calculation' },
  { type: 'cloacked' },
  { type: 'timeLine' },
  { type: 'shops' },
  { type: 'sortBy' },
  { type: 'returnAll' },
  { type: 'date_diff' },
  { type: 'answer_view' },
  { type: 'lable_draft' },
  { type: 'string' },
  { type: 'data_grid' },
  { type: 'index' },
  { type: 'move_to_next_stage' },
  { type: 'dynamicContent' },
  { type: 'locationShare' },
  { type: 'repeat' }]

  const initialPropBind = async (props) => {
  console.log('my props',props)
 setElementOption({
      enum: props?.init?.enum ? props.init.enum : null,
      fieldPlace: props?.init?.fieldPlace ? props.init.fieldPlace : null,
      formId: props?.init?.formId ? props.init.formId : null,
      group: props?.init?.group ? props.init.group : null,
      isMulitSelect: props?.init?.isMulitSelect ? props.init.isMulitSelect : null,
      keyTxt: props?.init?.keyTxt ? props.init.keyTxt : null,
      lableTxt: props?.init?.lableTxt ? props.init.lableTxt : null,
      orderNumber: props?.init?.orderNumber ? props.init.orderNumber : null,
      type: props?.init?.type ? props.init.type : null,
      minChar: props?.init?.absaulteData?.minChar ? props?.init?.absaulteData?.minChar : null,
      maxChar: props?.init?.absaulteData?.maxChar ? props?.init?.absaulteData?.maxChar : null,
      warning: props?.init?.warning ? props?.init?.warning : props?.init?.absaulteData?.warning,
      multiLine: props?.init?.multiLine ? props?.init?.multiLine : props?.init?.absaulteData?.multiLine
    });
    setCalculationArrayObj(props?.init?.operation ? props?.init?.operation : props?.init?.absaulteData?.operation)
    setShowInHeader(props?.init?.showInHeader? props?.init?.showInHeader : props?.init?.absaulteData?.showInHeader)
    setSearchableKey(props?.init?.searchableKey ? props.init.searchableKey : props?.init?.absaulteData?.searchableKey)
    setSortable(props?.init?.sortable ? props.init.sortable : props?.init?.absaulteData?.sortable)
    changeSelectedType(props?.init?.type ? props?.init?.type : props?.init?.absaulteData?.type);
    setRequred(props?.init?.isRequired ? true : false)
    if (props?.init) {
      if (props?.init?.type == 'index') {
        setRuleArray(props?.init?.ruleArray)
      }
    }
    if (props?.init?.type === "drop" || props?.init?.type === "radio" || props?.init?.type === "dropTree" || props?.init?.type === "dynamicContent") {
      setSelectType(props?.init?.selectType ? props.init.selectType :props?.init?.absaulteData?.selectType)
      setEnumLoadType(props?.init?.enumLoadType ? props.init.enumLoadType :props?.init?.absaulteData?.enumLoadType)
      const LoadType = props?.init?.enumLoadType ? props.init.enumLoadType :props?.init?.absaulteData?.enumLoadType
      if(LoadType === 'payload'){
        setExtraBasedOn(props?.init?.basedOn ? props.init.basedOn : props?.init?.absaulteData?.basedOn)
        getSelectedForm(props?.init?.selectedFormForPayload ? props.init.selectedFormForPayload :props?.init?.absaulteData?.selectedFormForPayload)
        setEnumCost(props?.init?.enumConst ? props.init.enumConst : props?.init?.absaulteData?.enumConst)
        setEnumLable(props?.init?.enumLable ? props.init.enumLable : props?.init?.absaulteData?.enumLable)
        setGrouped(props?.init?.grouped ? props.init.grouped : props?.init?.absaulteData?.grouped)
        setExtraDisplayArray(props?.init?.extraDisplayArray ? props.init.extraDisplayArray : props?.init?.absaulteData?.extraDisplayArray)
        setIsDepend(props?.init?.isDepent ? props.init.isDepent : props?.init?.absaulteData?.isDepent)
        getSelectedFormForDepend(props?.init?.dependDataSet ? props.init.dependDataSet : props?.init?.absaulteData?.dependDataSet)
        setDependKey(props?.init?.dependKey ? props.init.dependKey : props?.init?.absaulteData?.dependKey)
        setDependTargetValue(props?.init?.dependTargetValue ? props.init.dependTargetValue : props?.init?.absaulteData?.dependTargetValue)
        setOrderingValue(props?.init?.sortBy ? props.init.sortBy : props?.init?.absaulteData?.sortBy)
        if(props?.init?.searchableKey || props?.init?.absaulteData?.searchableKey){
          setSearchKeyListArray(props?.init?.searchKeyList ? props.init.searchKeyList : props?.init?.absaulteData?.searchKeyList)
        }
      }
    }
    if (props?.init?.type === "autofill") {
      setParentFocue(props?.init?.parentFocusValue ? props.init.parentFocusValue : props?.init?.absaulteData?.parentFocusValue)
    }
    if (props?.init?.type === 'data_grid' || props?.init?.type === 'lable_draft') {

      changeLableDefaula(props?.init?.defaultLableValue)
      setGridPayloadFrom(props?.init?.gridPayloadFrom ? props.init.gridPayloadFrom : props?.init?.absaulteData?.gridPayloadFrom)
      setGridPayloadGroup(props?.init?.gridPayloadGroup ? props.init.gridPayloadGroup : props?.init?.absaulteData?.gridPayloadGroup)
      if(props?.init?.type === 'lable_draft'){

        const isHtml = props?.init?.isHTML ?? props?.init?.absaulteData?.isHTML ?? true;
        const isBold = props?.init?.isBold ?? props?.init?.absaulteData?.isBold ?? false;
        const headerList = props?.init?.headerList ?? props?.init?.absaulteData?.headerList ?? false;
        const fontSize = props?.init?.fontSize ?? props?.init?.absaulteData?.fontSize ?? 12;


        // const isBold = Object.keys(props?.init).includes('isBold') ? props?.init?.isBold : Object.keys(props?.init?.absaulteData).includes('isBold') ? props?.init?.absaulteData?.isBold : false
        
        // const headerList = Object.keys(props?.init).includes('headerList') ? props?.init?.headerList : Object.keys(props?.init?.absaulteData).includes('headerList') ? props?.init?.absaulteData?.headerList : null
        // const fontSize = Object.keys(props?.init).includes('fontSize') ? props?.init?.fontSize : Object.keys(props?.init?.absaulteData).includes('fontSize') ? props?.init?.absaulteData?.fontSize : 14
        setIsHTML(isHtml)
        setIsBold(isBold)
        setheaderList(headerList)
        setFontSize(fontSize)
      }
    }
    if (props?.init?.type === "date") {
      setDateInputType(props?.init?.inputType ? props.init.inputType : props?.init?.absaulteData?.inputType)
    }

    if (props?.init?.type === "formTimeOut") {
      setTimmerMin(props?.init?.showingElements ? props.init.showingElements : props?.init?.absaulteData?.showingElements)
    }
    if (props?.init?.enumLoadType && props?.init?.enumLoadType === "payload") {
      setEnumLoadType("payload")
      if (props?.init?.selectedFormForPayload) {
        setSelectedFormForPayload(props?.init?.selectedFormForPayload)
        const res = await dispatch(getFormSchema(props?.init?.selectedFormForPayload));
        setScheemaComponent(res?.payload);
        setSelectedFormEnumSet(res?.payload)
        if (props?.init?.enumConst) {
          setEnumCost(props.init.enumConst)
        }
        if (props?.init?.enumLable) {
          setEnumLable(props?.init?.enumLable)
        }
      }

    }
    if (props?.init?.type == 'File' || props?.init?.type == 'html_camera' || props?.init?.type === "cloacked") {
      changeFileStoreType(props?.init?.fileStoreType ? props.init.fileStoreType : 's3')
      changeS3Bucket(props?.init?.s3Bucket ? props.init.s3Bucket : null)

      changeS3Dir(props?.init?.s3Dir ? props.init.s3Dir : null)
      setSelectedFileType(props?.init?.selectedFileType ? props.init.selectedFileType : null)
      setFileSource(props?.init?.captureType ? props.init.captureType : null)

      setImageTakenCount(props?.init?.imageTakenCount ? props.init.imageTakenCount : props?.init?.absaulteData?.imageTakenCount)
    }
    if(props?.init?.type === 'formTimeOut'){
      setTimmerMin(props?.init?.showingElements ? props.init.showingElements : props?.init?.absaulteData?.showingElements)
    }
    if(props?.init?.type === 'Form'){
        setFormSelectType(props?.init?.formSelectType ? props?.init?.formSelectType : props?.init?.absaulteData?.formSelectType)
        setCloaningForm(props?.init?.cloningForm ? props?.init?.cloningForm : props?.init?.absaulteData?.cloningForm)
    }
    if (props?.init?.type === "answer_view") {
      setShowingElement(props?.init?.showingElements ? props?.init?.showingElements : null)
    }

    const res = await dispatch(getFormSchema(props.init.formId));
    setScheemaComponent(res.payload);
    if (props?.init?.showInHeader) {

      setShowInHeader(true);

      if (props?.init?.sortable) {
        setSortable(true);
      }
      if (props?.init?.searchableKey) {
        setSearchableKey(true);
      }
    }

    setDropDownVal(props?.init?.enum ? props.init.enum : []);
    setSavingType(props?.init?.fieldPlace);
    setSelectedGroupConst(props?.init?.group ? props.init.group : 'main');
    if (props?.init?.reference) {
      setReference(true);
      setReferenceKey(props?.init?.reference);
    }
  };

  const getAllForms = async () => {
    const array = await dispatch(getAllFormsReducer(true))
    setActiveFormList(array.payload.respons.data)
    //
  }


  const getSelectedForm = async (e) => {
    const res = await dispatch(getFormSchema(e));
    setSelectedFormForPayload(e)
    if (res.payload) {
      setSelectedFormEnumSet(res.payload)
    }

  }

const handleSubmit = () => {
  if(props.isOpenAddSchema){
    addElementToSchema()
  }
  if(props.isOpenEditSchema){
    updateElementToSchema()
  }
}

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    getAllKeyElement(myParam);
    setSelectedFormId(myParam);
    getFormSchimaToDom(myParam);
    getAllGroup();
    if(props.isOpenEditSchema){
        initialPropBind(props);
    }
    getAllForms()
  }, [props]);

  useEffect(() => {
    if (isLoadForm) {
      setIsLoadForm(false)
    }
  }, [isLoadForm])

  return (
    <>
      <Modal
        title={props.isOpenAddSchema ? 'Add Element' : props.isOpenEditSchema && 'Edit Element'}
        visible={props.isOpenAddSchema ? props.isOpenAddSchema : props.isOpenEditSchema && props.isOpenEditSchema}
        onCancel={props.isOpenAddSchema ? props.closeAddSchema : props.isOpenEditSchema && props.closeEditSchema}
        width={'90vw'}
        footer={[
          <AntButton danger key="back" onClick={props.isOpenAddSchema ? props.closeAddSchema : props.isOpenEditSchema && props.closeEditSchema}>
            {t('Cancel')}
          </AntButton>,
          <AntButton
            danger
            key="submit"
            type="primary"
            onClick={() => handleSubmit()}
          >
            {t('Submit')}
          </AntButton>,
        ]}
      >
        {
          isLoadingElement ? null :
            <>
              <Grid
                className="page-action-header"
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <FormElement changeSelectedType={changeSelectedType} setIsLoadForm={setIsLoadForm} selectedTextType={selectedTextType} />
                </Grid>
              </Grid>
              <Grid className="box-setting-display" container spacing={2}>
                <Grid container spacing={2}>
                <Grid className="field-container" item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Saving Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={savingType}
                    disabled={props.isOpenEditSchema}
                    label="group"
                    onChange={(e) => {
                      setSavingType(e.target.value);
                      setElementOption((prevState) => {
                        return {
                          ...prevState,
                          keyTxt: "",
                        };
                      });
                    }}
                  >
                    <MenuItem value="database">Database</MenuItem>
                    <MenuItem value="bitrix">Bitrix</MenuItem>
                    <MenuItem value="root">Root</MenuItem>


                  </Select>
                </FormControl>
              </Grid>
                  <Grid className="field-container" item xs={12} md={6}>
                    <TextField
                      value={elementOption.lableTxt}
                      onChange={changeDisplayValue}
                      className="full-width"
                      size="small"
                      label="Field lable"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid className="field-container" item xs={12} md={6}>
                {savingType === "bitrix" ? (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Key
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={props.isOpenEditSchema}
                      value={elementOption.keyTxt}
                      label="Age"
                      onChange={(e) => {
                        setElementOption((prevState) => {
                          return {
                            ...prevState,
                            keyTxt: e.target.value,
                          };
                        });
                      }}
                    >
                      {elementKeyList.map((row) => (
                        <MenuItem value={row.keyName}>
                          {row.keyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    value={elementOption.keyTxt}
                    disabled={props.isOpenEditSchema}
                    onChange={(e) => {
                      setElementOption((prevState) => {
                        return {
                          ...prevState,
                          keyTxt: e.target.value,
                        };
                      });
                    }}
                    className="full-width"
                    size="small"
                    label="Field Key"
                    variant="outlined"
                  />
                )}
              </Grid>

                  <Grid className="field-container" item xs={12} md={6}>
                    <TextField
                      value={elementOption.warning}
                      onChange={changeWarning}
                      className="full-width"
                      size="small"
                      label="Warning"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid className="field-container" item xs={12} md={6}>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">group</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedGroupConst}
                        size="small"
                        label="group"
                        onChange={(e) => {
                          setSelectedGroupConst(e.target.value);
                        }}
                      >
                        <MenuItem value="main">Main</MenuItem>
                        {groupList.map((row1) => (
                          <MenuItem value={row1.groupId}>{row1.groupName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className="field-container" item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={requier} onChange={requeredChecked} />}
                        label="Required"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {(() => {
                  if (selectedTextType === "string") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                        <Grid className="field-container" item xs={6} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Line
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            value={elementOption.multiLine}
                            label="Age"
                            onChange={(e) => {
                              setElementOption((prevState) => {
                                return {
                                  ...prevState,
                                  multiLine: e.target.value,
                                };
                              });
                            }}
                          >
                            <MenuItem value="M">Multi Line</MenuItem>
                            <MenuItem value="S">Single Line</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Max length"
                              size="small"
                              variant="outlined"
                              type="number"
                              value={elementOption?.maxChar}
                              onChange={(e) => {
                                if (/^[0-9]{0,6}$/.test(e.target.value)) {
                                  setElementOption({
                                    ...elementOption,
                                    maxChar: e.target.value
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Min length"
                              size="small"
                              variant="outlined"
                              type="number"
                              value={elementOption?.minChar}
                              onChange={(e) => {
                                if (/^[0-9]{0,6}$/.test(e.target.value)) {
                                  setElementOption({
                                    ...elementOption,
                                    minChar: e.target.value
                                  })
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "number") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Max"
                              size="small"
                              variant="outlined"
                              type="number"
                            />
                          </Grid>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Min"
                              variant="outlined"
                              size="small"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );

                  } else if (selectedTextType === "total" || selectedTextType === "clearing") {
                    return (
                      <div style={{ width: '100%' }} className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6} md={4}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Target Field
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={targetValue}
                                size="small"
                                label="group"
                                onChange={(e) => {
                                  setTargetValue(e.target.value);
                                }}
                              >
                                {elementByGroup.filter((e) => e.type === 'number').map((row) => (
                                  <MenuItem value={row.keyTxt}>
                                    {row.lableTxt}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "Form") {
                    return (

                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6}>
                            <AntSelect
                              style={{ width: '200px' }}
                              onChange={(e) => { setFormSelectType(e) }}
                              value={formSelectType}
                            >
                              <Option value="manual">select form</Option>
                              <Option value="auto">Auto Create</Option>
                            </AntSelect>
                          </Grid>
                          <Grid className="field-container" item xs={6}>
                            <AntSelect
                              style={{ width: '200px' }}
                              onChange={(e) => { setCloaningForm(e) }}
                              value={cloningForm}
                            >

                              {activeFormList.map((row) => (
                                <Option value={row.id}>{row.name}</Option>

                              ))}
                            </AntSelect>
                          </Grid>
                        </Grid>
                      </div>

                    );

                  } else if (selectedTextType === "shops") {
                    return (
                      <div className="setting-container">

                      </div>
                    );

                  } else if (selectedTextType === "calculation") {
                    return (
                      <div className="setting-container">
    
                        Target element
                        <AntSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Schema"
                          optionFilterProp="children"
                          defaultValue={props?.init?.operation[0]?.field}
                          onChange={(e) => {
                            setCalculationArray(e);
                          }}
                        >
                          {elementByGroup.map((row, index) => (
                            <Option value={row.keyTxt}> {row.keyTxt}</Option>
                          ))}
    
    
                        </AntSelect>
                        <button onClick={() => {
    
                          setCalculationArrayObj(oldArray => [...oldArray, { field: calucaluationArray, operation: null }]);
                        }}>Add</button>
    
                        <div className="operation-list">
    
                          {calucaluationArrayObj.map((row, index) => (
                            <>{row.field}
                              <AntSelect
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="operation"
                                optionFilterProp="children"
                                defaultValue={row?.operation}
                                onChange={(e) => {
                                  const nextShapes = calucaluationArrayObj.map(shape => {
                                    if (shape.field === row.field) {
                                      // No change
    
                                      return {
                                        ...shape,
                                        operation: e
                                      };
                                    } else {
                                      return shape
                                    }
                                  });
                                  setCalculationArrayObj(nextShapes);
    
                                }}
                              >
                                <Option value='-'> -</Option>
                                <Option value='+'> +</Option>
                                <Option value='*'> *</Option>
                              </AntSelect>
                            </>
    
                          ))}
                        </div>
    
                      </div>
                    );
                  } else if (selectedTextType === "autofill") {
                    return (
                      <div className="setting-container">
    
                        <Grid className="field-container" item xs={12} md={6}>
                          parent
                          <input value={parentFocusValue} onChange={(e) => { setParentFocue(e.target.value) }} />
    
                        </Grid>
    
                      </div>
                    );
    
                  }
                  else if (selectedTextType === "index") {
                    return (
                      <div className="field-container-index">
                        <Row>
                          <Col className="index-action" span={8}>
                            <AntSelect
                              style={{ width: '100%' }}
                              onChange={changeIndexRuleType}
                            >
                              <Option value="date">date</Option>
                              <Option value="static">static number</Option>
                              <Option value="random">random number</Option>
                            </AntSelect>
                          </Col>
                          <Col className="index-action" span={8}>
                            {

                              indexRuleType == "date" ?
                                <AntSelect
                                  style={{ width: '100%' }}
                                  onChange={(e) => { setDateType(e) }}
                                >
                                  <Option value="MM">Month</Option>
                                  <Option value="DD">Date</Option>
                                  <Option value="YYYY">Year</Option>
                                  <Option value="MMDD">Month & Date</Option>
                                  <Option value="YYYYMMDD">Year, Month & Date</Option>
                                </AntSelect> : indexRuleType == 'static' ?
                                  <Input onChange={(e) => { setStatiChar(e.target.value) }} style={{ width: '100%' }} placeholder='static number' /> : indexRuleType == 'random' ?
                                    <Input onChange={(e) => { setRandomNumberLength(e.target.value) }} style={{ width: '100%' }} placeholder='number length' />
                                    : null

                            }

                          </Col>
                          <Col className="index-action" span={8}>
                            <AntButton onClick={addRuleFunction} className="index-action" type="primary" danger style={{ width: '100%' }}>Add Rule</AntButton>
                          </Col>
                        </Row>
                        <table>
                          <tr>
                            <th>Rule Type</th>
                            <th>Rule</th>
                            <th>Action</th>
                          </tr>
                          {ruleArray.map((rule) => (
                            <tr>
                              <td>{rule.type}</td>
                              <td>{rule.value}</td>
                              <td><button> x </button></td>
                            </tr>
                          ))}


                        </table>


                      </div>

                    );
                  } else if (selectedTextType === "drop" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
                    return (
                      <div style={{ width: '100%' }} className="setting-container">
                        <Grid container spacing={2}>

                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={selectType}
                                label="group"
                                onChange={(e) => {
                                  setSelectType(e.target.value);
                                }}
                              >
                                <MenuItem value="single">single</MenuItem>

                                <MenuItem value="multi">mulit</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Enum DataLoad
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={enumLoadType}
                                label="group"
                                onChange={(e) => {
                                  setEnumLoadType(e.target.value);
                                }}
                              >
                                <MenuItem value="custom">Custom</MenuItem>

                                <MenuItem value="payload">Payload</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>



                          {
                            enumLoadType === 'custom' ? <>
                              <Grid className="field-container" item xs={12} md={12}>
                                <span>
                                  <TextField
                                    className="array-value-add-container"
                                    size="small"
                                    value={valueDropDownConst}
                                    onChange={(e) => {
                                      addValueDropDownConst(e.target.value);
                                    }}
                                    label="Key"
                                    variant="outlined"
                                  />
                                </span>
                                <span>
                                  <TextField
                                    className="array-value-add-container"
                                    size="small"
                                    value={valueDropDown}
                                    onChange={(e) => {
                                      addValueDropDown(e.target.value);
                                    }}
                                    label="Item"
                                    variant="outlined"
                                  />
                                </span>
                                <IconButton
                                  onClick={pushValueToDropDown}
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SendIcon />
                                </IconButton>
                              </Grid>
                              <Grid className="field-container" item xs={12} md={12}>
                                <div className="table-scrol-mid">
                                  <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">key</TableCell>
                                        <TableCell align="left">lable</TableCell>
                                        <TableCell align="left">action</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {dropDownValue.map((row) => (
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th": {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell>{row.const}</TableCell>
                                          <TableCell>{row.title}</TableCell>
                                          <TableCell>
                                            <Button
                                              onClick={() => {
                                                removeDropDownValus(row.const);
                                              }}
                                            >
                                              {" "}
                                              x
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </div>
                              </Grid>
                            </> : <>
                              <Grid className="field-container" item xs={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    Select Form
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={selectedFormForPayload}
                                    label="group"
                                    onChange={(e) => {
                                      getSelectedForm(e.target.value)

                                    }}
                                  >

                                    {activeFormList.map((row) => (
                                      <MenuItem value={row.id}>{row.name}</MenuItem>

                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {
                                selectedFormForPayload ?
                                  <>
                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Enum Const
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={enumConst}
                                          onChange={(e) => {
                                            setEnumCost(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Enum Lable
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={enumLable}
                                          onChange={(e) => {
                                            setEnumLable(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>


                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Grouped
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={grouped}
                                          onChange={(e) => {
                                            setGrouped(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid className="field-container" item xs={12}>
                                      <h4>{t('Order Settings')}</h4>
                                    </Grid>
                                    <Grid container spacing={2} style={{ marginBottom: "30px" }}>
                                      <Grid className="field-container" item xs={6} md={6}>
                                        <FormControl fullWidth>
                                          <InputLabel>
                                            {t('Order By')}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-ordering"
                                            size="small"
                                            label="ordering"
                                            value={orderLabel}
                                            onChange={(e) => {
                                              setOrderLable(e.target.value)
                                            }}
                                          >
                                            {selectFormEnumSet.map((row) => (
                                              <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Button onClick={pushValueToOrdering}>{t('Add Order By')}</Button>
                                    </Grid>
                                    {sortRefreshed && <>
                                      {orderingValue?.map((row, index) => (<Grid container spacing={2}>
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <TextField
                                              className="array-value-add-container"
                                              size="small"
                                              value={row.sort_key}
                                              Readonly
                                              label="Key"
                                              variant="outlined"
                                              inputProps={
                                                { readOnly: true, }
                                              }
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>

                                              {t('Order Type')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-ordering"
                                              size="small"
                                              label="ordering"
                                              defaultValue={row.sort_by}
                                              onChange={(e) => {
                                                setOrderLable(e.target.value)
                                                row.sort_by = e.target.value
                                              }}
                                            >
                                              <MenuItem value={`ALPHANUM`}>Alphanumeric</MenuItem>
                                              <MenuItem value={`CUSOR`}>Custom Order</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {row.sort_by == 'CUSOR' && <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>
                                              {t('Select Form')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              size="small"
                                              defaultValue={row.sort_form}
                                              label="group"
                                              onChange={(e) => {
                                                row.sort_form = e.target.value
                                              }}
                                            >

                                              {activeFormList.map((row) => (
                                                <MenuItem value={row.id}>{row.name}</MenuItem>

                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>}
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>

                                              {t('Data Order')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-ordering"
                                              size="small"
                                              label="ordering"
                                              defaultValue={row.sort_type}
                                              onChange={(e) => {
                                                //setEnumLable(e.target.value)
                                                row.sort_type = e.target.value
                                              }}
                                            >
                                              <MenuItem value={1}>ASC</MenuItem>
                                              <MenuItem value={-1}>DESC</MenuItem>
                                            </Select>
                                          </FormControl>
                                          <Grid item xs={2} md={2}><Button onClick={() => removeOrderingValus(index)}><DeleteOutlined /></Button></Grid>
                                        </Grid>
                                      </Grid>))}
                                    </>}
                                    <hr />
                              <Grid className="field-container" item xs={12} md={12}>
                                    <div style={{
                                      width: '100%',
                                      marginTop: '15px',
                                    }}>Based on</div>
                                    <AntSelect
                                      mode="multiple"
                                      allowClear
                                      style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                      }}
                                      value={extrabasedOn}
                                      placeholder='Extra Values'
                                      onChange={(e) => setExtraBasedOn(e)}
                                    >
                                      {schemaComponent.map((row) => (
                                        <Option key={row.keyTxt}>{row.lableTxt}</Option>
                                      ))}

                                    </AntSelect>
                                    <div style={{
                                      width: '100%',
                                      marginTop: '15px',
                                    }}>Extra Values</div>
                                    <AntSelect
                                      mode="multiple"
                                      allowClear
                                      style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                      }}
                                      value={extraDisplayArray}
                                      placeholder='Extra Values'
                                      onChange={(e) => setExtraDisplayArray(e)}
                                    >
                                      {selectFormEnumSet.map((row) => (
                                        <Option key={row.keyTxt}>{row.lableTxt}</Option>
                                      ))}
                                    </AntSelect>
                                    </Grid>

                                  </>

                                  : null
                              }



                              {/* const [isDepent,setIsDepend]=useState(false)

const [dependDataSet,setDependDataSet]=useState(null)
const [dependDataElement,setDependDataElement]=useState([])
const [dependKey,setDependKey]=useState(null) */}

                              <Grid className="field-container" item xs={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    IS depend
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={isDepent}
                                    label="group"
                                    onChange={(e) => {
                                      setIsDepend(e.target.value)

                                    }}
                                  >
                                    <MenuItem value='false'>Indipend</MenuItem>
                                    <MenuItem value='true'>Depend</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {
                                isDepent === 'true' || isDepent === true ? <>
                                  <Grid className="field-container" item xs={12} md={12}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Select Form
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        value={dependDataSet}
                                        label="group"
                                        onChange={(e) => {
                                          getSelectedFormForDepend(e.target.value)

                                        }}
                                      >

                                        {activeFormList.map((row) => (
                                          <MenuItem value={row.id}>{row.name}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid className="field-container" item xs={6} md={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Depend key
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="group"
                                        value={dependKey}
                                        onChange={(e) => {
                                          setDependKey(e.target.value)

                                        }}
                                      >

                                        {dependDataElement.map((row) => (
                                          <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid className="field-container" item xs={6} md={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Depend target value
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="group"
                                        value={dependTargetValue}
                                        onChange={(e) => {
                                          setDependTargetValue(e.target.value)

                                        }}
                                      >

                                        {schemaComponent.map((row) => (
                                          <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </> : null
                              }
                              {/* <Grid className="field-container" item xs={12} md={12}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Select Form
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={enumLoadType}
                                  label="group"
                                  onChange={(e) => {
                                    setEnumLoadType(e.target.value);
                                  }}
                                >

                                  {activeFormList.map((row) => (
                                      <MenuItem value={row.id}>{row.name}</MenuItem>
                                    
                                  ))}
                                </Select>
                              </FormControl>
                              </Grid> */}
                            </>
                          }

                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "radio") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDownConst}
                                onChange={(e) => {
                                  addValueDropDownConst(e.target.value);
                                }}
                                label="Key"
                                variant="outlined"
                              />
                            </span>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDown}
                                onChange={(e) => {
                                  addValueDropDown(e.target.value);
                                }}
                                label="Item"
                                variant="outlined"
                              />
                            </span>{" "}
                            <IconButton
                              onClick={pushValueToDropDown}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SendIcon />
                            </IconButton>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <Stack direction="row" spacing={1}>
                              {dropDownValue.map((row) => (
                                <Chip
                                  label={row.title}
                                  onDelete={() => {
                                    removeDropDownValus(row.const);
                                  }}
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "date") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          Date type
                          <Col className="index-action" span={8}>
                            <AntSelect
                              value={dateInputType}
                              style={{ width: '100%' }}
                              onChange={(e) => setDateInputType(e)}
                            >
                              <Option value="user_input">User Input</Option>
                              <Option value="current">Current</Option>
                            </AntSelect>
                          </Col>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "date_diff") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}></Grid>
                      </div>
                    );
                  } else if (selectedTextType === "answer_view") {
                    return (
                      <div className="setting-container">
    
                        <Grid className="field-container" item xs={12} md={6}>
                          select showing element
                          <AntSelect
                            mode="multiple"
                            style={{ width: '450px' }}
                            onChange={changeViewElement}
    
                          >
                            {
                              schemaComponent.map((sc) => (
                                <Option value={sc.keyTxt ? sc.keyTxt : ' '}>{sc.lableTxt ? sc.lableTxt : ' '}</Option>
                              ))
                            }
    
                          </AntSelect>
    
                        </Grid>
    
                      </div>
                    );
    
                  } else if (selectedTextType === "formTimeOut") {
                    return (
                      <div className="setting-container">
    
                        <Grid className="field-container" item xs={12} md={6}>
                          Time Out After (MIN)
                          <InputNumber value={timmerMin} onChange={onChangeTimmer} />
    
                        </Grid>
    
                      </div>
                    );
    
                  } else if (selectedTextType === "File" || selectedTextType === "html_camera" || selectedTextType === "cloacked") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Store Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={fileStoreType}
                                label="group"
                                onChange={(e) => {
                                  changeFileStoreType(e.target.value);
                                }}
                              >
                                <MenuItem value="s3">Amazon S3</MenuItem>

                                <MenuItem value="local">Local</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {fileStoreType === "s3" ? (
                            <>
                              <Grid className="field-container" item xs={12} md={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    bucket
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={s3Bucket}
                                    size="small"
                                    label="group"
                                    onChange={(e) => {
                                      changeS3Bucket(e.target.value);
                                    }}
                                  >
                                    <MenuItem value="mertracker">Mertracker</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid className="field-container" item xs={12} md={6}>
                                <TextField
                                  value={s3Dir}
                                  onChange={(e) => {
                                    changeS3Dir(e.target.value);
                                  }}
                                  className="full-width"
                                  size="small"
                                  label="Dir name"
                                  variant="outlined"
                                />
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                        <Divider>IMAGE SETTINGS</Divider>
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                Accepted
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedFileType}
                                size="small"
                                onChange={modifyFileType}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {acceptedFileType.map((row1) => (
                                  <MenuItem key={row1.const} value={row1.lable}>
                                    <Checkbox
                                      checked={
                                        selectedFileType.indexOf(row1.lable) > -1
                                      }
                                    />
                                    <ListItemText primary={row1.lable} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>


                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                Single / Multiple
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={imageTakenCount}
                                size="small"
                                onChange={(e) => setImageTakenCount(e.target.value)}

                              >
                                <MenuItem value="single">single</MenuItem>
                                <MenuItem value="mulitple">mulitple</MenuItem>
                              </Select>
                            </FormControl>

                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Capture Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fileSource}
                                size="small"
                                label="group"
                                onChange={(e) => {
                                  setFileSource(e.target.value);
                                }}
                              >
                                <MenuItem value="local">Storage</MenuItem>
                                <MenuItem value="camera">Camera</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "location") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="On open"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid className="field-container" item xs={6} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="On Click"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "check") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={12}>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDownConst}
                                onChange={(e) => {
                                  addValueDropDownConst(e.target.value);
                                }}
                                label="Key"
                                variant="outlined"
                              />
                            </span>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDown}
                                onChange={(e) => {
                                  addValueDropDown(e.target.value);
                                }}
                                label="Item"
                                variant="outlined"
                              />
                            </span>
                            <IconButton
                              onClick={pushValueToDropDown}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SendIcon />
                            </IconButton>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <Stack direction="row" spacing={1}>
                              {dropDownValue.map((row) => (
                                <Chip
                                  label={row.title}
                                  onDelete={() => {
                                    removeDropDownValus(row.const);
                                  }}
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "data_grid") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={12}
                          >
    
                            Grid Payload From
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Form"
                              optionFilterProp="children"
                              value={gridPayloadFrom}
                              onChange={(e) => { setGridPayloadFrom(e) }}
                            >
                              {gridPayloadParentForm.map((row, index) => (
                                <Option value={row.type}> {row.type}</Option>
                              ))}
                            </AntSelect>
                            Grid Payload Group
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Schema"
                              optionFilterProp="children"
                              value={gridPayloadGroup}
                              onChange={(e) => { setGridPayloadGroup(e) }}
                            >
    
                              {gridPayloadParentForm.map((row, index) => (
                                <Option value={row.type}> {row.type}</Option>
                              ))}
    
                            </AntSelect>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  }else if (selectedTextType === "lable_draft") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={12}
                          >
                            Grid Payload From
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Form"
                              optionFilterProp="children"
                              value={selectedParentFormListId}
                              onChange={(e) => { ChangeSelectedParentForm(e) }}
                            >

                              {parentFormList.map((row, index) => (
                                <Option value={row.id}> {row.name}</Option>
                              ))}


                            </AntSelect>
                            Grid Payload Schema
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Schema"
                              optionFilterProp="children"
                              value={selectedParentFormSchemaId}
                              onChange={(e) => { setselectedParentFormSchemaId(e) }}
                            >

                              {selectedParentFormList.map((row, index) => (
                                <Option value={row.keyTxt}> {row.keyTxt}</Option>
                              ))}


                            </AntSelect>
          <Grid container spacing={2} className="field-container">
                    <Grid className="field-container"
                      item
                      xs={2}
                      md={2}>
                            <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox 
                                onChange={() => setIsHTML(!isHTML)} 
                                checked={isHTML} 
                                />
                              }
                              label="isHtml"
                            />
                          </FormGroup>
                    </Grid>
                    {
                      !isHTML && (
                    <>
                    <Grid
                    className="field-container"
                    item
                    xs={4}
                    md={4}
                  >
                    <AntSelect
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select a Tag"
                  optionFilterProp="children"
                  value={headerList}
                  onChange={(e) => setheaderList(e) }
                >

                  {['h1','h2','h3','p','span'].map((row) => (
                    <Option value={row}> {row}</Option>
                  ))}

                </AntSelect>
                  </Grid>
                
                  <Grid className="field-container"
                    item
                    xs={2}
                    md={2}>
                          <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox 
                              onChange={() => setIsBold(!isBold)} 
                              checked={isBold} 
                              />
                            }
                            label="Bold"
                          />
                        </FormGroup>
                  </Grid> 

                  <Grid
                    className="field-container"
                    item
                    xs={4}
                    md={4}
                  >
                        <AntSelect  
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select Font Size"
                            optionFilterProp="children"
                            defaultValue={3}
                            value={fontSize}
                            onChange={(e) => setFontSize(e) }
                        >
                      {Array.from(Array(24)).map((e, i) => (
                        <Option value={i+1}>{i+1}</Option>
                        ))}

                </AntSelect>
                  </Grid>
                  </>
                      )
                    }        
          </Grid>
                            <textarea rows="6" style={{ marginTop: 10, width: '100%' }} placeholder="lable name" onChange={(e) => changeLableDefaula(e.target.value)} >{
                              defaultLableValue
                            }</textarea>

                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "timeLine") {
                    return (
                      <div className="setting-container">
                        <AntCheckBox value ={isLocationEnable} onChange={(e) => changeTimeLineData(e, 'Location')}>
                          Location                
                        </AntCheckBox>
                        <AntCheckBox value={isDateEnable} onChange={(e) => changeTimeLineData(e, 'Date')}>
                          Date & Time
                        </AntCheckBox>
                        <AntCheckBox value={isResumeEnable} onChange={(e) => changeTimeLineData(e, 'resume')}>
                          Resume
                        </AntCheckBox>
                      </div>
                    );
                  }else if (selectedTextType === "repeat") {
                    return (
                      <div className="setting-container">




                      </div>
                    );
                  } else {
                    return (
                      <>
                        <CssBaseline />
                        <Container maxWidth="sm">
                          <Box
                            className="no-select-compoment"
                            sx={{ bgcolor: "#cfe8fc" }}
                          >
                            <div>
                              <h5>Please select element to continue</h5>
                            </div>
                          </Box>
                        </Container>
                      </>
                    );
                  }
                })()}

                <Grid container spacing={2}>
                  <Grid className="field-container" item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={referenceChacked} checked={reference} />
                        }
                        label="Reference"
                      />
                    </FormGroup>
                  </Grid>
                  {!reference ? (
                    <></>
                  ) : (
                    <>
                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            reference
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={referenceKey}
                            label="reference"
                            onChange={(e) => {
                              setReferenceKey(e.target.value);
                            }}
                          >
                            {elementKeyList.map((row) => (
                              <MenuItem value={row.keyName}>{row.keyName}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                <button
                  className="advance-button"
                  onClick={() => {
                    setAdvalceOpen(!isOpenAdvance);
                  }}
                >
                  {" "}
                  Advance{" "}
                  {showInHeader ? showInHeader : 'false'}
                </button>

                <Grid
                  className={isOpenAdvance ? "show-div" : "closs-div"}
                  container
                  spacing={2}
                >
                  <Grid className="field-container" item xs={12} sm={12} md={12}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            setShowInHeader(!showInHeader);
                          }}
                          checked={showInHeader}
                        />
                      }
                      label="Show in header"
                    />
                  </Grid>

                  {showInHeader ? (
                    <Grid className="field-container" item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSearchableKey(!searchableKey);
                            }}
                            checked={searchableKey}
                          />
                        }
                        label="Enable Search"
                      />
                    </Grid>
                  ) : null}
                  {
                      (enumLoadType === 'payload' && showInHeader === true && searchableKey === true && (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent")) && (
                        <Grid className="field-container" item xs={12} md={12} lg={12}>
                                <FormControl fullWidth>
                                  Search Key List
                                  <AntSelect
                                      mode="multiple"
                                      allowClear
                                      style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                      }}
                                      value={searchKeyListArray}
                                      onChange={(e) => setSearchKeyListArray(e)}
                                    >
                                      {selectFormEnumSet.map((row) => (
                                        <Option key={row.keyTxt}>{row.lableTxt}</Option>
                                      ))}
                                    </AntSelect>
                                </FormControl>
                              </Grid>
                      )}
                  {showInHeader ? (
                    <Grid className="field-container" item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSortable(!sortable);
                            }}
                            checked={sortable}
                          />
                        }
                        label="Enable Sorting"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </>
        }

      </Modal>
    </>
  );
};


export default SchemaElementAddEdit
