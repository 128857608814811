import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Col } from "antd";
import { initUserData } from "../../../../../../redux/slices/users";

export function View(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [value, setValue] = useState(null)


    const data = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const dataset = state.ruleFormElement.dataset
            if (dataset && props?.element?.keyTxt) {
                error = dataset?.database?.main[props.element.keyTxt] ? dataset.database.main[props.element.keyTxt] : 'null'

            }

        }

        return error

    })




    const init = () => {
        setValue(props.element)


    }
    useEffect(() => {

        init();

    }, [props]);


    return (

        <div className="answer">
            <div className="box">
                <div className="lable">{t(value?.lableTxt ? value.lableTxt : '-')} : </div> <div className="value">{data ? data : '-'}</div>
            </div>


        </div>

    );
}

export function ViewCloeck(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [value, setValue] = useState(null)

    // const sqlData = useSelector((state) => {
    //     let data = null
    //     const attandanceState = state.markAttandance ? state.markAttandance : null
    //     if (attandanceState?.attandanceDataSql) {
    //         data = attandanceState.attandanceDataSql

    //     }
    //     return data
    // })

    //Optimized Code
    const sqlData = useSelector(state => 
      state.markAttandance && state.markAttandance.attandanceDataSql
        ? state.markAttandance.attandanceDataSql
        : null
    );
    
    const userInformation = useSelector(
        (prState) => prState?.user?.loginUser?.database || null
      );
    // const data = useSelector((state) => {


    //     let error = null
    //     if (state?.ruleFormElement?.dataset) {
    //         const dataset = state.ruleFormElement.dataset
    //         if (dataset && props?.element?.keyTxt) {
    //             error = dataset?.database?.main[props.element.keyTxt] ? dataset.database.main[props.element.keyTxt] : 'null'

    //         }

    //     }

    //     return error

    // })

    const data = useSelector(state => {
      let error = null;
      const dataset = state?.ruleFormElement?.dataset;
      if (dataset && props?.element?.keyTxt) {
        error = dataset?.database?.main[props.element.keyTxt] || null;
      }
      return error;
    });
    

    const init = () => {
        setValue(props.elemen)

    }
    
    useEffect(() => {

        init();

    }, [props]);



    return (

        <div className="clocked-data">
            <div className="employee-data">


                
                <div className="normal-padding ">
                    <div className="welcome" style={{paddingLeft:'1px'}}>
                        Hi! {userInformation?.NAME ? userInformation?.NAME : '-'}{" "}
                        {userInformation?.LAST_NAME ? userInformation?.LAST_NAME : '-'}
                    </div>
                </div>

                <div className="flex-center-2">
                    <div className="answer ">
                        <div className="box">
                            <div className="lable">
                                {t('Employee ID')} :
                            </div>
                            <div className="value">
                                {userInformation?.emCode ? userInformation?.emCode : '-'}
                            </div>
                        </div>

                    </div>
                    <div className="answer ">
                        <div className="box">
                            <div className="lable">

                                {t('Status')} :
                            </div>
                            <div className="value">
                                {t('Working on')}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="clocked">
           
                <div className="container">
                    <div className="clocked-in">
                        <div className="header">
                            <div className="icon">
                                <ClockCircleOutlined style={{ fontWeight: 'bold' }} />
                            </div>
                            <div className="lable">
                                {t('Clock In')}
                            </div>
                        </div>
                        <div className="body">
                            <div className="timer">
                                {data?.clockIn?.image?.dateTime ? moment(data.clockIn.image.dateTime).format("HH:mm") : '-'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="clocked-out">
                        <div className="header">
                            <div className="icon">
                                <ClockCircleOutlined />
                            </div>
                            <div className="lable">
                                {t('Clock Out')}
                            </div>
                        </div>
                        <div className="body">
                            <div className="timer">
                                {data?.clockOut?.image?.dateTime ? moment(data.clockOut.image.dateTime).format("HH:mm") : '-'}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    );
}

export function ViewStored(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [value, setValue] = useState(null)


    const data = useSelector((state) => {

        let error = null
        if (state?.ruleFormElement?.dataset) {
            const dataset = state.ruleFormElement.dataset
            if (dataset && props?.element?.keyTxt) {
                error = dataset?.database?.main[props.element.keyTxt] ? dataset.database.main[props.element.keyTxt] : 'null'

            }

        }

        return error

    })

    const otherShop = useSelector((state) => {

        let error = null
        if (state?.ruleFormElement?.dataset) {
            const dataset = state.ruleFormElement.dataset
            if (dataset) {
                error = dataset?.database?.main[props.element.keyTxt]?.['otherShop'] ? dataset.database.main[props.element.keyTxt]?.['otherShop'] : []

            }

        }

        return error

    })

    const init = () => {
        setValue(props.elemen)

    }
    useEffect(() => {

        init();

    }, [props]);



    return (
        <div className="normal-padding">
            <div className="shop-data divider-div-answer">

                <span className="header">{t('Store details')}</span>
                <div className="answer">
                    <span className="lable">{t('Store Id')} : 
                    </span> 
                    <span className="value">
                        {data.StoreID ? data.StoreID : '-'}
                        {otherShop?.length > 0 && ', '}
                        {otherShop && otherShop.map((item) => (
                                item?.StoreID
                            )).join(', ')}
                    </span>
                    

                </div>
                <div className="answer">
                    <span className="lable">{t('Store Name')} : </span> <span className="value">{data.StoreName ? data.StoreName : '-'}</span>


                </div>
                <br></br>
            </div>

        </div>


    );
}