import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@mui/material";
import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    ForwardOutlined,
    CopyOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    EyeOutlined,
    FlagOutlined,
    ControlOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";

import moment from 'moment';





import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";

import {
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select as AntSelect,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form, Spin, DatePicker, Image, Drawer, Select
} from "antd";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { AttandaceView } from "./components/AttandaceView";

import { getAllAttandance, getAllAttandanceExport } from "../../redux/slices/attandance/attandanceSetting";

import * as XLSX from "xlsx";
import Title from "antd/es/typography/Title";
import { GridCard } from "./components/GridCard";
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const UserAttandance = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();


    const [selectedPage, setSelectedPage] = useState(1);

    const [dataPerPage, setDataPerPage] = useState(10);

    const [search, setSearch] = useState({});

    const [loading, setLoading] = useState(false)

    const [selectedData, setSelectedData] = useState(null)

    const [isModalOpen, setOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const showModal = (e) => {
        console.log('selected data', e)
        setSelectedData(e)
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const formatDate = (dateString) => {
        const date = dateString && new Date(dateString);
        const year = date?.getUTCFullYear();
        const month = String(date?.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date?.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Status',
            dataIndex: 'EMPStatus',
            key: 'EMPStatus',
        },
        {
            title: 'EmployeeCode',
            dataIndex: 'EmployeeCode',
            key: 'EmployeeCode',
        },
        {
            title: 'EmployeeName',
            dataIndex: 'EmployeeName',
            key: 'EmployeeName',
        },
        {
            title: 'AttendanceDate',
            dataIndex: 'AttendenceDate',
            key: 'AttendenceDate',
        },
        {
            title: 'StoreID',
            dataIndex: 'StoreID',
            key: 'StoreID',
        },
        {
            title: 'StoreName',
            dataIndex: 'StoreName',
            key: 'StoreName',
        }, {
            title: 'Brand',
            dataIndex: 'Brand',
            key: 'Brand',
        }

    ];

    const attandance = useSelector((state) => {

        let tempAtt = []

        if (state?.attandance?.attandance) {
            const tempData = state.attandance.attandance
            for (const tem of tempData) {
                let obj = {
                    key: tem.ID ? tem.ID : '-',
                    action: <div className="action-div-table">
                        <Button style={{ color: 'green' }} className="action-div-button" onClick={() => { showModal(tem) }} type="text" shape="circle" icon={<EyeOutlined />} />
                        {
                            tem.mongoData ?
                                <Button className="action-div-button" onClick={() => { showModal(tem.mongoData) }} type="text" shape="circle" icon={<FlagOutlined />} />
                                : null
                        }
                    </div>,
                    EmployeeCode: tem.EmployeeCode ? tem.EmployeeCode : '-',
                    EmployeeName: tem.EmployeeName ? tem.EmployeeName : '-',
                    AttendenceDate: tem.AttendenceDate ? formatDate(tem.AttendenceDate) : '-',
                    StoreID: tem.StoreID ? tem.StoreID : '-',
                    StoreName: tem.StoreName ? tem.StoreName : '-',
                    Brand: tem.Brand ? tem.Brand : '-',
                    EMPStatus: tem.EMPStatus ? t(tem.EMPStatus) : '-',


                }
                tempAtt.push(obj)
            }

        }
        return tempAtt

    })

    const dAtacount = useSelector((state) => {


        let count = 0
        if (state?.attandance?.NumberOfRows) {
            count = state.attandance.NumberOfRows

        }
        return count

    })
    const changePagination = (pageNo, pageSize) => {

        init(pageNo)
        setSelectedPage(pageNo)

        //getAnswerList(props.form.id,false,columns,pageNo, pageSize)

        //
    }

    const exportData = async (filters = search) => {
        const obj = {
            filters: filters
        }
        const expData = await dispatch(getAllAttandanceExport(obj))

        if (expData?.payload?.data) {
            const exD = expData.payload.data

            let excelArra = [];
            let headerArray = [
                "ID",
                "EmployeeCode",
                "EmpCode1",
                "AttendenceDate",
                "InTime_Hour",
                "InTime_Minute",
                "OutTime_Hour",
                "OutTime_Minute",
                "nWorkUnit",
                "JobType",
                "EmployeeName",
                "EMPStatus",
                "StoreID",
                "StoreName",
                "Brand",
                "LatIn",
                "LongIn",
                "LatOut",
                "LongOut",
                "ImageIn",
                "ImageOut",
                "UpdatedDateTime",
                "EkoID",
                "SaleType",
                "StoreType",
                "ProductCategory",
                "ClockoutDate",
                "ClockinDistance",
                "ClockoutDistance"
            ];
            excelArra.push(headerArray);

            if (exD) {
                for (const element of exD) {
                    excelArra.push([
                        element.ID,
                        element.EmployeeCode,
                        element.EmpCode1,
                        element.AttendenceDate,
                        element.InTime_Hour,
                        element.InTime_Minute,
                        element.OutTime_Hour,
                        element.OutTime_Minute,
                        element.nWorkUnit,
                        element.keyName,
                        element.JobType,
                        element.EmployeeName,
                        element.EMPStatus,
                        element.StoreID,
                        element.StoreName,
                        element.Brand,
                        element.LatIn,
                        element.LongIn,
                        element.LatOut,
                        element.LongOut,
                        element.ImageIn,
                        element.ImageOut,
                        element.UpdatedDateTime,
                        element.EkoID,
                        element.SaleType,
                        element.StoreType,
                        element.ProductCategory,
                        element.ClockoutDate,
                        element.ClockinDistance,
                        element.ClockoutDistance,
                    ]);
                }
            }

            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.aoa_to_sheet(excelArra);

            XLSX.utils.book_append_sheet(wb, ws, "my", true);
            XLSX.writeFile(wb, "Export Language.xlsx");
        }
    }

    const init = async (pageNo = selectedPage,
        filters = search
    ) => {
        setSelectedPage(pageNo)
        const obj = {
            selectedPage: pageNo,
            filters: filters
        }
        await dispatch(getAllAttandance(obj))
    }


    useEffect(() => {
        init()

    }, [props.match.params.id]);

    var schemaList = [
        {
            "lableTxt": "Employee Code",
            "keyTxt": "empCode",
            // "type": "drop",
            // "data": formList
        },
        {
            "lableTxt": "StoreId",
            "keyTxt": "StoreId",
            // "type": "drop",
            // "data": formList
        },
        {
            "lableTxt": "Attandance Date",
            "keyTxt": "attandDate",
            "type": "date",
            "data": [{ name: "Completed", id: "completed" }, { name: "In Progress", id: "inprogress" }, { name: "Pending", id: "pending" }, { name: "Rejected", id: "rejected" }]
        }
    ]

    const onFilter = (values) => {
        console.log("values", values)
        if (values.rules_field) {
            const result = values.rules_field.reduce((acc, current) => {
                acc[current.key] = current.value;
                return acc;
            }, {});
            if (result.attandDate) {
                result["attandDate"] = {
                    start: result?.attandDate[0],
                    end: result?.attandDate[1],
                }
            }
            console.log("result", result);
            setSearch(prevState => ({ ...prevState, ...result }));
            init(1, result)

        }

        setIsFilterOpen(false);
    }

    const clearFilter = () => {
        form.resetFields()
        setSearch({})
        init(1, {})
    }
    return (

        <>


            <div className="page-continer ">
                {
                    loading ? <div className="example"> <Spin /></div> :
                        <>
                            {

                            }
                            <div className="action-header" style={{ marginBottom: 10 }}>
                                <Button
                                    danger
                                    type="primary"
                                    icon={<ControlOutlined />}
                                    onClick={() => setIsFilterOpen(true)}
                                    style={{ marginRight: 10}}
                                />
                                <Button
                                    onClick={() => clearFilter()}
                                    style={{ marginRight: 10}}
                                >
                                    {t("CLEAR FILTER")}
                                </Button>
                                <Button danger onClick={() => { exportData() }} type="primary">{t('EXPORT')}</Button>
                            </div>
                            <div className="table-responsive">
                                <div className="scrol-table-div">
                                    <Table dataSource={attandance} columns={columns} pagination={false} />
                                </div>
                                <div className="mobile-table">
                                    {attandance.map((el) => (
                                        console.log(el, "el"),
                                        <GridCard
                                            data={el}
                                            showModal={showModal}
                                        />
                                    ))}
                                </div>
                            </div>




                            <div style={{ marginTop: 15 }} className="pagination-container">
                                <Pagination onChange={(page, pageSize) => { changePagination(page, pageSize) }} current={selectedPage} defaultCurrent={selectedPage} total={dAtacount} />
                            </div>

                        </>

                }

                <AttandaceView isOpen={isModalOpen} obj={selectedData} clossModel={handleCancel} />

            </div>

            <Drawer
                mask={true}
                className='drawer-filter'
                onClose={() => { setIsFilterOpen(false) }}
                //   placement={placement}
                open={isFilterOpen}
            >
                <div layout="vertical">
                    <div className="header-top">
                        <Title level={4}>
                            Filtter(s)
                        </Title>
                    </div>

                    <div className="sidebar-color">
                        <div className="">
                            <div className="">
                                <Form form={form} style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={onFilter} autoComplete="off">
                                    <Form.List name="rules_field">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <div key={key} className={`form-list-show`}>
                                                        <div className={`field-class`}>
                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'key']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    showSearch
                                                                    placeholder="Select Field"
                                                                    allowClear
                                                                >
                                                                    {schemaList.map((row, index) => (
                                                                        <Option key={index} value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}



                                                                </Select>
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`field-class`}>
                                                            <Form.Item dependencies={[["rules_field", name, "key"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["key"] ? type[name]["key"] : null

                                                                        const sc = schemaList.find((comp) => comp.keyTxt === nameLevel)


                                                                        if (sc) {
                                                                            if (sc.data) {
                                                                                enumArray = sc.data
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                        console.log('enumArray', enumArray)
                                                                        if (sc && sc.type == 'drop') {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        showValue ?
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, 'value']}
                                                                                            >
                                                                                                <Select
                                                                                                    style={{ width: '100%' }}
                                                                                                    showSearch
                                                                                                    placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                    allowClear
                                                                                                >
                                                                                                    {enumArray.map((row, index) => (
                                                                                                        <Option value={row.id}>{row.name}</Option>
                                                                                                    ))}




                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            : null
                                                                                    }
                                                                                </>



                                                                            );
                                                                        } else if (sc && sc.type == 'date') {

                                                                            return (
                                                                                <>


                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <RangePicker />

                                                                                    </Form.Item>




                                                                                </>



                                                                            );
                                                                        } else if (sc) {
                                                                            return (
                                                                                <>
                                                                                    {

                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'value']}
                                                                                        >
                                                                                            <Input
                                                                                                style={{ width: '100%' }}
                                                                                                showSearch
                                                                                                placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                allowClear
                                                                                            />

                                                                                        </Form.Item>

                                                                                    }
                                                                                </>



                                                                            );
                                                                        }


                                                                    }


                                                                    //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;


                                                                }}
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`action-class`}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </div>





                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        ADD FILTER
                                                    </Button>
                                                </Form.Item>


                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            FILTER
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>




                    </div>
                </div>
            </Drawer>

        </>

    );
};




export default UserAttandance
