import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

let initialState = {
    isShowRuleModel:false,
    NumberOfRows:0,
    formSchema:[],
    selectedForm:null,
    dataset:null,
    datasetNest:null,

};





export const openRuleElementModel = createAsyncThunk(
    "ruleElementForm/openRuleElementModel",
    async (obj) => {
        
       let endPoint = `${apiConfig.adminApi}/form/get-form-schema/${obj.form.id}`;

        const response = await axios.get(endPoint);


        let endPointForm = `${apiConfig.adminApi}/form/get-form-details/${obj.form.id}`;

        const responseForm = await axios.get(endPointForm);

        if(response.data){
            return {data:response.data, formData:responseForm.data} ;
        }else{
            return false;
        }
       
       
    }
);


export const closeRuleModel = createAsyncThunk(
    "ruleElementForm/closeRuleModel",
    async () => {
       
            return true;
       
    }
);




export const addNewRuleElementSet = createAsyncThunk(
    "ruleElementForm/addNewRuleSet",
    async (data) => {
       
        let endPoint = `${apiConfig.adminApi}/form/add-new-rule-element-set`;

        const response = await axios.post(endPoint,data);
        if(response.data){
            return response.data
        }else{
            return false;
        }
           
       
    }
);

export const ChangeElementRuleBehaviors = createAsyncThunk(
    "ruleElementForm/ChangeElementRuleBehaviors",
    async (data) => {
       
        let endPoint = `${apiConfig.adminApi}/form/add-rule-element-behaviors`;

        const response = await axios.post(endPoint,data);
        if(response.data){
            return response.data
        }else{
            return false;
        }
           
       
    }
);

export const saveCommonFieldReducer = createAsyncThunk(
    "ruleElementForm/saveCommonFieldReducer",
    async (data) => {
       
        let endPoint = `${apiConfig.adminApi}/form/save-common-field`;

        const response = await axios.post(endPoint,data);
        if(response.data){
            return response.data
        }else{
            return false;
        }
           
       
    }
);




export const getFormDetails = createAsyncThunk(
    "ruleElementForm/getFormDetails",
    async (data) => {
       
        let endPointForm = `${apiConfig.adminApi}/form/get-form-details/${data}`;

        const responseForm = await axios.get(endPointForm);
        if(responseForm.data){
            return responseForm.data
        }else{
            return false;
        }
           
       
    }
);


export const changeDataReducer = createAsyncThunk(
    "ruleElementForm/changeDataReducer",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);


export const changeDataReducerInside = createAsyncThunk(
    "ruleElementForm/changeDataReducerInside",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);









export const formRuleSlice = createSlice({
    name: "ruleElementForm",
    initialState,
    extraReducers: {

        [openRuleElementModel.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                isShowRuleModel: true,
                formSchema:action?.payload?.data?action.payload.data:[],
                selectedForm:action?.payload?.formData?action.payload.formData:null,
            }
        },
        [closeRuleModel.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                isShowRuleModel: false,
            }
        },
        [getFormDetails.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                selectedForm: action.payload?action.payload:null,
            }
        },
        [changeDataReducer.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                dataset: action.payload?action.payload:null,
            }
        },
        [changeDataReducerInside.fulfilled]: (state, action) => {
           
             
            return {
                ...state,
                datasetNest: action.payload?action.payload:null,
            }
        },

        
        
        
        

    },
});

export default formRuleSlice.reducer;


