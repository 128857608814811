import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import DialogContent from "@mui/material/DialogContent";
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { CheckCircleTwoTone, CloseCircleOutlined, ControlOutlined, FilterOutlined, HeartTwoTone, MinusCircleOutlined, PlusOutlined, SmileTwoTone, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    ForwardOutlined,
    CopyOutlined,
    UnorderedListOutlined,
    SettingOutlined, ExclamationCircleOutlined, EyeOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";

import { getAllFormsReducer, assignFormToComponentReducer, getAllassignFormToComponentReducer } from "../../redux/slices/formSettings"

import { FormViewInterface } from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import { FormGenarator } from "../../pages/settings/JsonForm/intefaces/FormGenarator";

import { getAllLanguage, addLanguageBulk } from "../../redux/slices/language";

import { updateSetting, deleteSideBarItem, deleteSideBarHeader, manageSideBarHeader, editSideBarHeader, addSideBarItem, editSideBarItem } from "../../redux/slices/configSetting";

import {
    initialReducer, flowStatusChangeReducer, filterWorkFlowReducer, workflowFormWithAnswers
} from "../../redux/slices/workflow";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";
import {
    Row,
    Col,
    Radio,
    Table,
    Drawer,
    Button,
    Select,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form, Avatar, List, Skeleton, Steps, DatePicker
} from "antd";

import Slide from "@mui/material/Slide";
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import * as XLSX from "xlsx";
import { setFormReducer } from "../../redux/slices/answer/answerSetting";
import { setAnswerViewReducer } from "../../redux/slices/dynamicPage";
import { ViewAnswerDrawer } from "../../components/layout/ViewAnswerDrawer";
import Title from "antd/es/typography/Title";
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;
const children = [];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const { Step } = Steps;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const WorkFlow = (props) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const Divider = styled(MuiDivider)(spacing);

    const [initLoading, setInitLoading] = useState(true);
    const [ setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [list, setList] = useState([]);
    const [isOpenAnswerView, openAnswerView] = useState(false);
    const [selectedData, setSelectedData] = React.useState({});
    const [groupList, setGroupList] = useState([]);
    const [schemaComponent, setScheemaComponent] = React.useState([]);

    const [isStatusChangeOpen, setStatusChangeOpen] = React.useState(false);

    const [searchSelectedForm, setSerchSelectedForm] = React.useState(null);


    const [selectedWorkFlowStatus, setSelectedWorkFlowStatus] = useState(null)
    const [selectedWorkFlowId, setSelectedWorkFlowId] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [open, setOpen] = useState(false)
    const [filters, setFilters] = useState({});
    const [filterOption, setFilterOption] = useState({
        formId: null,
        createData: null,
        value: null,
        empId: null,
        status: null
    })
    const [formList, setFormList] = useState([])

    const [sort,setSort] = useState(0)


    // pagination
    const [dataCount, setDataCount] = useState(0)

    const [selectedPage, setSelectedPage] = useState(1)

    const [dataPerPage, setDataPerPage] = useState(3)


    const [mainData, setMainData] = useState(null)
    const [subData, setSubData] = useState(null)
    //pagination end

    const subAnswerRemark = useSelector((state) => {
        let data = null
        const pageState = state.answer ? state.answer : null
        if (pageState?.dataRemark) {
            data = pageState?.dataRemark ? pageState.dataRemark : null
        }
        return data
    })


    const mainAnswerRemark = useSelector((state) => {
        let data = null
        const pageState = state.answer ? state.answer : null
        if (pageState?.formRemark) {
            data = pageState?.formRemark ? pageState.formRemark : null
        }
        return data
    })

    const filterWorkFlow = async (selectedPage = 1, rowPerRequest = 10) => {
        setLoading(true)
        init(selectedPage, rowPerRequest, false)
        setLoading(false)
        // 
    }

    const changeSearchFormIs = (e) => {
        setFilterOption(prevState => ({
            ...filterOption,
            formId: e
        }))
    }


    const changeSearchDateRange = (value, dateString) => {
        console.log("dateString", dateString)
        setFilterOption(prevState => ({
            ...filterOption,
            createData: dateString,
            value
        }))
    }



    const getAllForms = async () => {
        const array = await dispatch(getAllFormsReducer(true))


        setFormList(array.payload.respons.data)
        // 
    }

    const listForm = useSelector((state) => {

        return state.workFlow.workFlowFilters



    })
    const { loading, error } = useSelector((state) => state.workFlow);


    useEffect(()=> {
        
        if(listForm){
            const error = listForm.filter(item => !Object.keys(item.answer).length)
            if(error.length){
                notification.error({
                    message: t("Error"),
                    description: t("Workflow answer is not coming...!"),
                  });
            }
        }
    },[listForm])

    useEffect(() => {
        console.log("mergedData",listForm)
        if (error) {
            notification.error({
                message: t("Error"),
                description: t(error),
            });
        }
       
      }, [dispatch, error]);
    


    const viewAnswer = (answer, group, schema) => {
        //setSelectedData(row);

        const objPassing = {
            elementGroup: group,
            formInformation: null,
            jsonSchema: schema,
            row: answer

        }

        dispatch(setFormReducer(objPassing))
        dispatch(setAnswerViewReducer(objPassing))

    };

    const handleCloseUserView = () => {
        openAnswerView(false);
    };

    const statusChangeReducer = async () => {







        // setInitLoading(true)
        setStatusChangeOpen(false);
        const obj = {
            selectedWorkFlowId: selectedWorkFlowId,
            selectedWorkFlowStatus: selectedWorkFlowStatus,
            rejectReason: rejectReason ? rejectReason : null,
            mainAnswerRemark: mainAnswerRemark ? mainAnswerRemark : null,
            subAnswerRemark: subAnswerRemark ? subAnswerRemark : null,
        }

        await dispatch(flowStatusChangeReducer(obj))
        init(selectedPage, 10, false)
        // setInitLoading(false)

    };

    const changePagination = (pageNo, pageSize) => {
        init(pageNo, pageSize, false)
        setSelectedPage(pageNo)
        //
    }

    const init = async (selectedPage = 1, rowPerRequest = 10, reset = true, filter = filters) => {
        let payload = {}

        if (reset) {
            setSelectedPage(1)
            setFilterOption({
                formId: null,
                createData: null,
                value: null
            })
            payload = {
                selectedPage: selectedPage,
                rowPerRequest: rowPerRequest,
                perPage: rowPerRequest,
                page: selectedPage - 1,
                pagination: true,
                filters: {}
            }
        }
        else {
            console.log("selectedPage", selectedPage)
            setSelectedPage(selectedPage)
            payload = {
                selectedPage: selectedPage,
                rowPerRequest: rowPerRequest,
                perPage: rowPerRequest,
                page: selectedPage - 1,
                pagination: true,
                filters: {
                    employee_id: filter?.employee_id || undefined,
                    status: filter?.status || undefined,
                    formId: filter?.formId || undefined,
                    startAt: filter?.startAt ? moment(filter.startAt[0].format("YYYY-MM-DD HH:mm:ss")).utc().toISOString() : undefined,
                    endAt: filter?.startAt ? moment(filter.startAt[1].format("YYYY-MM-DD HH:mm:ss")).utc().toISOString() : undefined,
                    sort: filter?.sort || undefined,
                    task_id: filter?.task_id || undefined
                }
            }
        }

        setInitLoading(true)
        let worFlows =  await dispatch(workflowFormWithAnswers(payload))
        setDataCount(worFlows.payload.total)
        setInitLoading(false)
    }

    const onClickTab = async (e) => {

        if (e == 2) {

            const objPassing = {
                elementGroup: mainData.group,
                formInformation: null,
                jsonSchema: mainData.schema,
                row: mainData.answer,
                type: 'main'

            }

            dispatch(setFormReducer(objPassing))
        } else if (e == 3) {

            const objPassing = {
                elementGroup: subData?.group ? subData.group : null,
                formInformation: null,
                jsonSchema: subData?.schema,
                row: subData?.answer ? subData.answer : null,
                type: 'sub'

            }

            dispatch(setFormReducer(objPassing))
        }







    }


    const formattedDate = (Ddate) => {
        const date = moment(Ddate).format('MMMM Do, YYYY hh:mm A');
        return date
    }
    const handleMenuClick = (e) => {

        const action = e.key;
        const id = e.item.props.items.item;

        switch (action) {
            case "1":

                // setSelectedData(id.answer)
                // setGroupList(id.form.group)
                // setScheemaComponent(id.form.schema)
                // openAnswerView(true)
                viewAnswer(id.answer, id.form.group, id.form.schema)

                break;
            case "2":

                setStatusChangeOpen(true)

                let objPassing = {
                    elementGroup: id.form.group,
                    formInformation: null,
                    jsonSchema: id.form.schema,
                    row: id.answer,
                    type: 'main'

                }

                if(!Object.keys(id.answer).length){
                    notification.error({
                        message: t("Error"),
                        description: t("Workflow answer is not coming...!"),
                      });
                }

                dispatch(setFormReducer(objPassing))
                setMainData({
                    answer: id.answer,
                    group: id.form.group,
                    schema: id.form.schema
                })
                if (id?.answer?.formProccess?.jsonProcessAnswer && id?.answer?.formProccess?.proccessFormGroups && id?.answer?.formProccess?.proccessFormScheema) {
                    setSubData({
                        answer: id.answer.formProccess.jsonProcessAnswer,
                        group: id.answer.formProccess.proccessFormGroups,
                        schema: id.answer.formProccess.proccessFormScheema
                    })
                } else {
                    setSubData(null)
                }


                setSelectedWorkFlowId(id.workFlowDetails.dataTab._id ? id.workFlowDetails.dataTab._id : null)
                setSelectedWorkFlowStatus(id.workFlowDetails.dataTab.status ? id.workFlowDetails.dataTab.status : null)
                setRejectReason(id.workFlowDetails.dataTab.reason ? id.workFlowDetails.dataTab.reason : null)

                break;
            case "3":
                setSelectedData(id.answer.formProccess.jsonProcessAnswer)
                setGroupList(id.answer.formProccess.proccessFormGroups)
                setScheemaComponent(id.answer.formProccess.proccessFormScheema)
                openAnswerView(true)


                break;
            default:
                return;
        }
    };



    useEffect(() => {

        // createContainer()
        getAllForms()
        init()

    }, []);

    var schemaList = [
        {
            "lableTxt": "Form type",
            "keyTxt": "formId",
            "type": "drop",
            "data": formList
        },
        {
            "lableTxt": "Employee Code",
            "keyTxt": "employee_id",
            // "type": "drop",
            "data": formList
        },
        {
            "lableTxt": "Status",
            "keyTxt": "status",
            "type": "drop",
            "data": [{ name: "Completed", id: "completed" }, { name: "In Progress", id: "inprogress" }, { name: "Pending", id: "pending" }, { name: "Rejected", id: "rejected" }]
        },
        {
            "lableTxt": "Start Date and End Date",
            "keyTxt": "startAt",
            "type": "date",
            "data": formList
        },
        {
            "lableTxt": "Task Id",
            "keyTxt": "task_id",
            // "type": "date",
            // "data": formList
        }
    ]

    const onFilter = (values) => {
        console.log("values", values)
        if (values.rules_field) {
            const result = values.rules_field.reduce((acc, current) => {
                acc[current.key] = current.value;
                return acc;
            }, {});

            console.log("result", result);
            setFilters(prevState => ({ ...prevState, ...result }));
            init(1, 10, false, result)

        }

        setOpen(false);
    }

    const clearFilter = () => {
        form.resetFields()
        setFilters({})
        setSort(0)
        init(1, 10, true)
    }

    const onSort = () => {
        setSort(sort === 0 ? 1 : sort === 1 ? -1 : 0)
        setFilters(prevState => ({ ...prevState, sort:sort === 0 ? 1 : sort === 1 ? -1 : 0 }));
        init(1, 10, false, {...filters,sort:sort === 0 ? 1 : sort === 1 ? -1 : 0})
    }

    return (

        <React.Fragment>
            {

            }
            <Grid container spacing={6}>

                <Grid item xs={12}>

                    <div className="page-continer ">
                        <Grid style={{ marginBottom: 15 }} container spacing={6}>
                            <Grid item >
                                <div style={{ display: "flex" }}>
                                    <Button
                                        style={{backgroundColor:sort === 0 ? "white" : "#cb4249",color:sort === 0 ? "black" : "white"}}
                                        onClick={() => onSort()}
                                        icon={sort === 1 ? <SortAscendingOutlined style={{color:"white"}} /> : sort === -1 ? <SortDescendingOutlined style={{color:"white"}} /> : <FilterOutlined />}
                                    >
                                        {t("DATE")}
                                    </Button>
                                    <Button
                                        danger
                                        type="primary"
                                        icon={<ControlOutlined />}
                                        onClick={() => setOpen(true)}
                                        style={{ marginRight: 10,marginLeft:10 }}
                                    />
                                    <Button
                                        onClick={() => clearFilter()}
                                    >
                                        {t("CLEAR FILTER")}
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>

                    



                        <List
                            className="demo-loadmore-list"
                            loading={initLoading}
                            itemLayout="vertical"
                            dataSource={listForm}
                            renderItem={(item) => (
                                <List.Item
                                    extra={!(item.taskStage === "Completed" || item.rejected) && [
                                        <Dropdown.Button
                                            trigger={['click']}
                                            className="action-menu-button"
                                            overlay={
                                                <Menu
                                                    onClick={handleMenuClick}
                                                    items={[
                                                        // {
                                                        //     label: "View Answers",
                                                        //     key: "1",
                                                        //     items: { item: item },
                                                        //     icon: <EyeOutlined />,
                                                        // },
                                                        // {
                                                        //     label: "View Process",
                                                        //     key: "3",
                                                        //     items: { item: item },
                                                        //     icon: <EyeOutlined />,
                                                        // },
                                                        item.isSelfApprovalStage && !item.rejected ?
                                                            {
                                                                label: "Approve / Decline",
                                                                key: "2",
                                                                items: { item: item },
                                                                icon: <EditOutlined />,
                                                            } : null,
                                                        item.selfRejected && item.rejected ? {
                                                            label: "Approve / Decline",
                                                            key: "2",
                                                            items: { item: item },
                                                            icon: <EditOutlined />,
                                                        } : null,
                                                    ]}
                                                />
                                            }
                                            icon={<SettingTwoTone twoToneColor="#28a745" />}
                                        />
                                    ]}
                                    className='common-padding demo-loadmore-list-item'
                                >
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item?.createdUser?.userBit?.PERSONAL_PHOTO ? item.createdUser.userBit.PERSONAL_PHOTO : null} />}
                                            title={<div className="list-title-container">
                                                <div className="list-title">
                                                    {item?.form?.form?.name ? item.form.form.name : ''}
                                                </div>

                                                {
                                                    <>
                                                        {(() => {

                                                            if (item.rejected) {

                                                                return (
                                                                    <div className="status-container-reject">Rejected</div>
                                                                )
                                                            } else {
                                                                if (item.taskStage == 'pending') {
                                                                    return (
                                                                        <div className="status-container-pending">Pending</div>
                                                                    )

                                                                } else if (item.taskStage == 'inprogress') {

                                                                    return (
                                                                        <div className="status-container-inprogress">In Progress</div>
                                                                    )
                                                                }
                                                                else if (item.taskStage == 'Completed') {

                                                                    return (
                                                                        <div className="status-container-completed">Completed</div>
                                                                    )
                                                                }
                                                            }
                                                        })()}
                                                    </>
                                                }








                                            </div>}

                                        />

                                        <div>
                                            <Grid className="common-padding" container >
                                                {item.form.schema.filter((e) => e.showInHeaderWorkflow).map((eliment) => (
                                                    <>

                                                        <Grid item xs={4}>
                                                            <span className="header-value-span">
                                                                {eliment.lableTxt === 'start date and time' ? (
                                                                    <>
                                                                        <span>{t(eliment.lableTxt)} : </span>
                                                                        <span>{item?.answer?.data?.database?.main[eliment.keyTxt] ? formattedDate(item?.answer?.data?.database?.main[eliment.keyTxt]) : '-'}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{t(eliment.lableTxt)} : </span>
                                                                        <span>{item?.answer?.data?.database?.main[eliment.keyTxt] ? item.answer.data.database.main[eliment.keyTxt] : '-'}</span>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </Grid>


                                                    </>

                                                ))}
                                            </Grid>


                                        </div>
                                        <div>
                                            <Collapse accordion>
                                                <Panel header="Approval Flow" key="1">
                                                    <Steps direction="vertical" className="work-flow-step" size="small" current={!item.rejected ? (parseInt(item.currentStage) - 1) : (parseInt(item.rejectedStag) - 1)}>
                                                        {item.workFlowDetails.dataTab.approvalFlow.map((stage) => (
                                                            <Step description={
                                                                <>Responsible Users

                                                                    <List
                                                                        size="small"
                                                                        bordered
                                                                        dataSource={stage.userData}
                                                                        renderItem={(item) =>
                                                                            <List.Item>
                                                                                {item?.userBit?.EMAIL ? `${item.userBit.EMAIL} ` : '-'}
                                                                                {
                                                                                    stage?.status !== 'pending' ? <>
                                                                                        {
                                                                                            item.userBit.UF_USR_1642854306150 == stage.changedBy.UF_USR_1642854306150 ? stage.status === "Reject" ? <CloseCircleOutlined style={{color:"red"}} /> : <CheckCircleTwoTone twoToneColor="#52c41a" /> : null
                                                                                        }
                                                                                    </> : null
                                                                                }</List.Item>
                                                                        }
                                                                    />
                                                                </>
                                                            }

                                                                title={stage?.status === 'pending' ? 'PENDING' :
                                                                    stage?.changedBy?.NAME ? stage.changedBy.NAME : 'UNKNOWN'

                                                                }
                                                                icon={<Avatar className={` ${stage.status} ${stage.isCurrentUser && !item.rejected ? 'current-user' : null}`}
                                                                    src={stage?.status === 'pending' ? null :
                                                                        stage?.changedBy?.PERSONAL_PHOTO ? stage.changedBy.PERSONAL_PHOTO : null

                                                                    } />} />
                                                        ))}


                                                    </Steps>
                                                </Panel>
                                            </Collapse>


                                        </div>
                                    </Skeleton>


                                </List.Item>
                            )}
                        />
                    </div>
                    <div className="pagination-container">
                        <Pagination pageSizeOptions={['5', '10', '20', '50']} onChange={(page, pageSize) => {
                            changePagination(page, pageSize)
                        }} current={selectedPage} defaultCurrent={selectedPage} total={dataCount} />
                    </div>
                </Grid>
            </Grid>


            <Modal
                visible={isStatusChangeOpen}
                title="Approve / Reject"
                onOk={statusChangeReducer}
                onCancel={() => { setStatusChangeOpen(false) }}
                footer={[
                    <Button key="back" onClick={() => { setStatusChangeOpen(false) }}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={statusChangeReducer}>
                        Submit
                    </Button>,
                ]}
            >
                <Tabs onTabClick={onClickTab} defaultActiveKey="2">
                    <Tabs.TabPane tab="DATA" key="2">
                        <FormViewInterface
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="TASK" key="3">
                        <FormViewInterface
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="APPROVED/REJECTED" key="1">
                        <>
                            Review Work Flow:  <Select
                                value={selectedWorkFlowStatus}
                                style={{
                                    width: '100%',
                                }}
                                onChange={(e) => { setSelectedWorkFlowStatus(e) }}
                            >
                                <Option value="pending">pending</Option>
                                <Option value="Accept">Accept</Option>
                                <Option value="Reject">Reject</Option>
                            </Select>
                            {
                                selectedWorkFlowStatus == 'Reject' || selectedWorkFlowStatus == 'Accept' ? <>
                                    Reason :
                                    <TextArea value={rejectReason} onChange={(e) => { setRejectReason(e.target.value) }} rows={4} placeholder="Reason" />
                                </> : null
                            }
                        </>

                    </Tabs.TabPane>

                </Tabs>


                {/* const [selectedWorkFlowStatus, setSelectedWorkFlowStatus]=useState(null)
   
    const [rejectReason, setRejectReason]=useState(null) */}

            </Modal>

            <Dialog
                fullScreen
                open={isOpenAnswerView}
                onClose={handleCloseUserView}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseUserView}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t(selectedData.id)}
                        </Typography>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleCloseUserView}
                        >
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent className="full-screen-form-container-view">
                    <FormViewInterface
                        elementGroup={groupList}
                        jsonSchema={schemaComponent}
                        id={selectedData.data}
                        formDetails={selectedData}
                    />
                </DialogContent>
            </Dialog>

            <ViewAnswerDrawer />

            <Drawer
                mask={true}
                className='drawer-filter'
                onClose={() => { setOpen(false) }}
                //   placement={placement}
                open={open}
            >
                <div layout="vertical">
                    <div className="header-top">
                        <Title level={4}>
                            Filtter(s)
                        </Title>
                    </div>

                    <div className="sidebar-color">
                        <div className="">
                            <div className="">
                                <Form form={form} style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={onFilter} autoComplete="off">
                                    <Form.List name="rules_field">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <div key={key} className={`form-list-show`}>
                                                        <div className={`field-class`}>
                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'key']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    showSearch
                                                                    placeholder="Select Field"
                                                                    allowClear
                                                                >
                                                                    {schemaList.map((row, index) => (
                                                                        <Option key={index} value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}



                                                                </Select>
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`field-class`}>
                                                            <Form.Item dependencies={[["rules_field", name, "key"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["key"] ? type[name]["key"] : null

                                                                        const sc = schemaList.find((comp) => comp.keyTxt === nameLevel)


                                                                        if (sc) {
                                                                            if (sc.data) {
                                                                                enumArray = sc.data
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                        console.log('enumArray', enumArray)
                                                                        if (sc && sc.type == 'drop') {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        showValue ?
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, 'value']}
                                                                                            >
                                                                                                <Select
                                                                                                    style={{ width: '100%' }}
                                                                                                    showSearch
                                                                                                    placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                    allowClear
                                                                                                >
                                                                                                    {enumArray.map((row, index) => (
                                                                                                        <Option value={row.id}>{row.name}</Option>
                                                                                                    ))}




                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            : null
                                                                                    }
                                                                                </>



                                                                            );
                                                                        } else if (sc && sc.type == 'date') {

                                                                            return (
                                                                                <>


                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <RangePicker showTime />

                                                                                    </Form.Item>




                                                                                </>



                                                                            );
                                                                        } else if (sc) {
                                                                            return (
                                                                                <>
                                                                                    {

                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'value']}
                                                                                        >
                                                                                            <Input
                                                                                                style={{ width: '100%' }}
                                                                                                showSearch
                                                                                                placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                allowClear
                                                                                            />

                                                                                        </Form.Item>

                                                                                    }
                                                                                </>



                                                                            );
                                                                        }


                                                                    }


                                                                    //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;


                                                                }}
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`action-class`}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </div>





                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        ADD FILTER
                                                    </Button>
                                                </Form.Item>


                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            FILTER
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>




                    </div>
                </div>
            </Drawer>

        </React.Fragment >

    );
};




export default WorkFlow