import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

let initialState = {
    warningList:[],
    errorList:[],
    dataset:{}
};




export const addWarningListReducer = createAsyncThunk(
    "errorSetting/addWarningListReducer",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);


export const addErrorListReducer = createAsyncThunk(
    "errorSetting/addErrorListReducer",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);

export const addWarningListReducerAdd = createAsyncThunk(
    "errorSetting/addWarningListReducerAdd",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);


export const addErrorListReducerAdd = createAsyncThunk(
    "errorSetting/addErrorListReducerAdd",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);


export const changeDataReducer = createAsyncThunk(
    "errorSetting/changeDataReducer",
    async (obj) => {
     

        if(obj){
            return obj ;
        }else{
            return false;
        }
       
       
    }
);










export const formErrorSlice = createSlice({
    name: "errorSetting",
    initialState,
    extraReducers: {

        [addWarningListReducer.fulfilled]: (state, action) => {
            return {
                ...state,
                warningList:action?.payload?action.payload:[],
            }
        },
        [addErrorListReducer.fulfilled]: (state, action) => {
           
            return {
                ...state,
                errorList:action?.payload?action.payload:[],
            }
        },
        [changeDataReducer.fulfilled]: (state, action) => {
           
            return {
                ...state,
                dataset:action?.payload?action.payload:{},
            }
        },
        
        // [addWarningListReducerAdd.fulfilled]: (state, action) => {
        //     return {
        //         ...state,
        //         warningList:action?.payload?action.payload:[],
        //     }
        // },
        // [addErrorListReducerAdd.fulfilled]: (state, action) => {
           
        //     return {
        //         ...state,
        //         errorList:action?.payload?action.payload:[],
        //     }
        // }
        
        
        

    },
});

export default formErrorSlice.reducer;


