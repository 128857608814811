import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ValueDisplay } from "../elements/ValueDisplay";
import { ApprovalRow } from "../elements/ApprovalRow";

import { WorkFlowViewDrawer } from "./WorkFlowViewDrawer";

import {
  MinusCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { manageViewAnswerDrawer } from "../../redux/slices/dynamicPage";
import { openMoreOptionDrawer } from "../../redux/slices/answer/answerSetting";
import { workFlowDrawerOpen } from "../../redux/slices/workflow";

import { Radio, Tabs } from "antd";

import { MoreOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Select,
  Dropdown,
  Menu,
  notification,
  Input,
  Modal,
  Space,
  Checkbox,
  Form,
  Drawer,
  Typography,
  DatePicker,
} from "antd";
import { FormViewInterface } from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import DialogContent from "@mui/material/DialogContent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
  padding: "8px 0",
  marginBottom: "10px",
};

export function GridCard(props) {
  const { Title, Text } = Typography;
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [workFlow, setWorkFlow] = useState(null);
  const [display, setDisplay] = useState([]);
  // const [activeTask, setActiveTask] = useState(null);



  const openMoreOption = () => {
    dispatch(openMoreOptionDrawer(data));
  };

  const openWorkFlowDrawer = () => {
    if (workFlow) {
      dispatch(workFlowDrawerOpen(workFlow));
    }

    //
  };
  const taskProcessModelOpen = (data) => {
    props.moveTotask(data);
    // setFormScheemaTask(data.formProccess.proccessFormScheema)

    // setFormGroupTask(data.formProccess.proccessFormGroups)

    // setFormTask(data.formProccess.proccessForm)

    // setSelectedAnswerId(data.id)

    //
    // setjsonProcessAnswer(data.formProccess.jsonProcessAnswer)

    // setTaskProcessOpen(true)
  };

  useEffect(() => {
    if (props?.dataRow?.data) {
      setData(props.dataRow.data);
    }

    if (props?.dataRow?.data?.workFlow) {
      setWorkFlow(props.dataRow.data.workFlow);
    }

    // if (props?.dataRow?.activeTask) {
    //   setActiveTask(props?.dataRow?.activeTask[0]);
    // }

    if (props?.dataRow?.display) {
      setDisplay(props.dataRow.display);
    }

    console.log("props", props);
  }, [props]);

  return (
    <div  className="grid-row">
      <div style={{background:props.activeTask && props.shouldDisplayIcon ? "burlywood" : "default"}} className="grid-header">
        <div onClick={openWorkFlowDrawer} className="grid-header-1">
          {workFlow ? <ApprovalRow wrokFlow={workFlow} /> : null}
        </div>
        <div className="grid-header-2" style={{ width: "50%" }}>
          <div>
            <Button icon={<MoreOutlined />} onClick={openMoreOption} />
          </div>

          {data ? (
            <div>
              {/* {props.shouldDisplayIcon(data, props.loggedInUser, activeTask) &&
              activeTask ? (
                <Button
                  type="text"
                  style={{ verticalAlign: "0px" }}
                  onClick={props.resetPlayIcons}
                  icon={<RedoOutlined />}
                />
              ) : null}
              {props.shouldDisplayIcon(data, props.loggedInUser, activeTask) ? (
                <Button
                  type="text"
                  style={{ verticalAlign: "0px" }}
                  onClick={() => {
                    taskProcessModelOpen(data);
                  }}
                  icon={<PlayCircleOutlined />}
                />
              ) : null} */}

              {!data.finishedAt && props.isResponsibleUser ? (
                <>
                  {props.activeTask ? props.shouldDisplayIcon ? (
                    <>
                      <Button
                        type="text"
                        style={{ verticalAlign: "0px" }}
                        onClick={props.resetPlayIcons}
                        icon={<RedoOutlined />}
                      />

                      <Button
                        type="text"
                        style={{ verticalAlign: "0px" }}
                        onClick={() => {
                          taskProcessModelOpen(data);
                        }}
                        icon={<PlayCircleOutlined />}
                      />
                    </>
                  ) :
                    null
                    :
                    <Button
                      type="text"
                      style={{ verticalAlign: "0px" }}
                      onClick={() => {
                        taskProcessModelOpen(data);
                      }}
                      icon={<PlayCircleOutlined />}
                    />}



                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="grid-Body">
        {display.map((val) => (
          <Fragment key={val.key}>{<ValueDisplay value={val} />}</Fragment>
        ))}
      </div>
      <div className="grid-footer">
        <div className="grid-footer-created-by font-footer">
          <span>
            {`${data?.createdBy?.NAME ? data.createdBy.NAME : ""} ${data?.createdBy?.LAST_NAME ? data.createdBy.LAST_NAME : ""
              }`}
          </span>
        </div>
        <div className="grid-footer-created-at font-footer">
          <span>
            {data?.createdAt ? (
              <>{moment(data.createdAt).format("MMMM Do YYYY, hh:mm:ss")}</>
            ) : null}
          </span>
        </div>
      </div>
      <WorkFlowViewDrawer />
    </div>
  );
}
