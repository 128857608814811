

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';
import $ from 'jquery';

import {
    closeClockOutMenu, shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";

import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";


import {
    ShareLocation
} from "../../../../attandence/components/ShareLocation";
const { Option } = Select;

const AutoMoveToNext = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [lable, setLable] = useState(null)


    


    useEffect(() => {
      
        console.log('ptops',props)
        $('.MuiButton-containedPrimary').prop("hidden", true);
        $('.MuiButton-containedSecondary').prop("hidden", true);
        $(".MuiButton-containedPrimary").trigger("click")
        $('.MuiButton-containedPrimary').removeClass("Mui-disabled");
        // $('.MuiButton-containedPrimary').addClass("Mui-disabled");
       

    }, [props]);
    return (
     <>
     s
     </>



    );
}



export default withJsonFormsControlProps(AutoMoveToNext);
