import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@mui/material";
import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    ForwardOutlined,
    CopyOutlined,
    UnorderedListOutlined,
    SettingOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";



import { FormGenarator } from "../../JsonForm/intefaces/FormGenarator";

import { getAllLanguage, addLanguageBulk } from "../../../../redux/slices/language";

import { updateSetting, deleteSideBarItem, deleteSideBarHeader, manageSideBarHeader, editSideBarHeader, addSideBarItem, editSideBarItem } from "../../../../redux/slices/configSetting";

import {
    getAllFormsReducer
} from "../../../../redux/slices/formSettings";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";

import {
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form
} from "antd";
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import * as XLSX from "xlsx";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;
const children = [];



const SideBarConfig = (props) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const Divider = styled(MuiDivider)(spacing);

    const [tabPosition, setTabPosition] = useState('left');

    const [dataArray, setDataArray] = useState([])

    const [activeKey, setActiveKey] = useState('settings');

    const [openMainAdd, setMainAddOpen] = useState(false);



    //side header

    const [newSideHeaderTitle, setNewSideHeaderTitle] = useState('')
    const [newSideHeaderKey, setNewSideHeaderKey] = useState('')

    const [icon, setIcon] = useState('fa fa-heart')

    // const [listForm,setListForm]=useState([])

    const tempHeaderItems = useSelector(state => state.setting.sideMenuItems)
    const listForm = useSelector((state) => {

        let list = []
        if (state?.formSettings?.allActiveForms) {
            for (const form of state.formSettings.allActiveForms) {
                let obj = {
                    label: form.name,
                    key: form.id,
                    data: form
                }
                list = [...list, obj]
            }
        }
        return list
        // setListForm(list)


    })
    //

    // const createContainer = () =>{
    //     let tempArray=[
    //         {
    //             title:'Main',
    //             key:'main',
    //             items:[
    //                 {
    //                     title:'Dashboard',
    //                     key:'dashboard',
    //                     icon : 'https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-dashboard-line-black-icon-png-image_691037.jpg',
    //                     to:'/dashboard'
    //                 }
    //             ]
    //         },
    //         {
    //             title:'EMPLOYEE',
    //             key:'employee',
    //             items:[
    //                 {
    //                     title:'Users',
    //                     key:'users',
    //                     icon : 'https://flyclipart.com/thumb2/profile-icon-png-black-196391.png',
    //                     to:'/users'
    //                 }
    //             ]
    //         },
    //         {
    //             title:'SETTINGS',
    //             key:'settings',
    //             items:[
    //                 {
    //                     title:'Form',
    //                     key:'form',
    //                     icon : 'https://png.pngtree.com/png-vector/20190909/ourlarge/pngtree-form-icon-isolated-png-image_1727824.jpg',
    //                     to:'/manage-form'
    //                 },
    //                 {
    //                     title:'Component',
    //                     key:'component',
    //                     icon : 'https://w7.pngwing.com/pngs/465/905/png-transparent-computer-icons-module-icon-design-modular-programming-web-module-miscellaneous-angle-logo.png',
    //                     to:'/manage-component'
    //                 },
    //                 {
    //                     title:'Language',
    //                     key:'lanaguage',
    //                     icon : 'https://img.favpng.com/24/3/12/computer-icons-translation-icon-design-png-favpng-BYi4Y7LQCEPbaFANZcJxiBkKH.jpg',
    //                     to:'/language'
    //                 },
    //                 {
    //                     title:'Configuration',
    //                     key:'configuration',
    //                     icon : 'https://flyclipart.com/thumb2/phone-settings-setting-icon-292044.png',
    //                     to:'/app-config'
    //                 },
    //                 {
    //                     title:'SideBar',
    //                     key:'sidebase',
    //                     icon : 'https://flyclipart.com/thumb2/phone-settings-setting-icon-292044.png',
    //                     to:'/side-bar-config'
    //                 }
    //             ]
    //         },
    //         {
    //             title:'ACCOUNT PAGES',
    //             key:'account_page',
    //             items:[
    //                 {
    //                     title:'Profile',
    //                     key:'profile',
    //                     icon : 'https://flyclipart.com/thumb2/profile-icon-png-black-196391.png',
    //                     to:'/profile'
    //                 }
    //             ]
    //         }
    //     ]
    //     setDataArray(tempArray)


    // }
    const tabClick = (e) => {
        // 
        setActiveKey(e)
    }

    const onEdit = (targetKey, action) => {
        if (action === 'add') {
            setMainAddOpen(true)
        } else {
            //remove(targetKey);
        }
    };

    const addNewSideMenuItem = (key) => {
        const payload = {
            key: key,
            title: 'New Item',
            editable: true,
            hide: true,
            to: '/dynamic-page'
        }
        dispatch(addSideBarItem(payload))
    }

    const addNewSideHeader = () => {

        let tempArray = tempHeaderItems
        if (tempArray.find((e) => e.key === newSideHeaderKey)) {

            const payload = {
                key: newSideHeaderKey,
                title: newSideHeaderTitle,
                editable: true,
                hide: true
            }
            dispatch(editSideBarHeader(payload))

        } else {
            const payload = {
                key: newSideHeaderKey,
                title: newSideHeaderTitle,
                editable: true,
                hide: true
            }
            dispatch(manageSideBarHeader(payload))
        }
        setNewSideHeaderTitle('')
        setNewSideHeaderKey('')
        setMainAddOpen(false)

        return
    }

    const editHeader = (key, title) => {


        setMainAddOpen(true)
        setNewSideHeaderTitle(title)
        setNewSideHeaderKey(key)

    }

    const editSideBarItemFunction = (e, row, action) => {
        //row.title=e

        let obj = row
        if (action === 'name') {
            obj = {
                ...obj,
                title: e,
            }
        }
        if (action === 'type') {

            let url = ''
            if (e === 'Iframe') {
                url = '/dynamic-frame'
            }
            if (e === 'form') {
                url = '/dynamic-page'
            }
            obj = {
                ...obj,
                type: e,
                to: url
            }
        }
        if (action === 'redirect') {
            if (e === 'Add Iframe Url Here...' || e === '') {
                return
            }

            obj = {
                ...obj,
                redirect: e
            }
        }

        if (action === 'form-change') {

            obj = {
                ...obj,
                form: listForm.find(item => item.key === e)?.data
            }

        }

        if (action === 'action-change') {

            obj = {
                ...obj,
                actions: e
            }
        }
        if (action === 'icon-change') {

            obj = {
                ...obj,
                icon: e
            }
        }

        if (action === 'view-change') {

            obj = {
                ...obj,
                viewType: e
            }
        }




        dispatch(editSideBarItem(obj))


    }

    const deleteSideItemFunction = (item) => {
        //row.title=e

        dispatch(deleteSideBarItem(item))



    }



    const deleteHeader = (key) => {
        const btn = (
            <Button type="primary" size="small" onClick={() => {
                dispatch(deleteSideBarHeader({ id: key }))
            }}>
                Confirm
            </Button>
        );
        notification.open({
            message: 'Delete side nav header',
            description:
                'Chiled navigation items will be destroied, are you sure?',
            btn,
            key,
        });

    }

    const init = () => {
        dispatch(getAllFormsReducer(true))
    }

    const iconPickHandler = (e) => {

    }



    useEffect(() => {

        // createContainer()
        init()

    }, []);

    return (

        <React.Fragment>
            <Grid container spacing={6}>

                <Grid item xs={12}>

                    <div className="page-continer ">
                        <Button onClick={() => { setMainAddOpen(true) }} type="primary" danger>
                            ADD
                        </Button>


                        <Tabs onTabClick={(e) => { tabClick(e) }} tabPosition={tabPosition} activeKey={activeKey} onEdit={onEdit}>

                            {
                                tempHeaderItems.map((row) => (

                                    <TabPane tab={row.title} key={row.key}>

                                        {
                                            row.editable ?
                                                <>
                                                    <Button onClick={() => deleteHeader(row.key)} className='tab-action-btn' danger icon={<DeleteOutlined />}  >Delete Header</Button>

                                                    <Button onClick={() => editHeader(row.key, row.title)} className='tab-action-btn' danger type="primary" icon={<EditOutlined />}  > Edit Header</Button>



                                                </>
                                                : null
                                        }
                                        <Button onClick={() => addNewSideMenuItem(row.key)} className='tab-action-btn tab-action-new' danger type="primary" icon={<EditOutlined />}  > Add Item</Button>

                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {
                                                row.items ? <>
                                                    {

                                                        row.items.map((item, index) => (
                                                            <Collapse key={index} collapsible="header">
                                                                <Panel header={<>
                                                                    {
                                                                        item.editable ?
                                                                            <EditText
                                                                                className="inline-edit-tes"
                                                                                defaultValue={item.title}
                                                                                onSave={(e) => {
                                                                                    editSideBarItemFunction(e.value, item, 'name')
                                                                                }}
                                                                            />

                                                                            : item.title
                                                                    }

                                                                </>} key={item.key} extra={
                                                                    <>
                                                                        {
                                                                            item.editable ?
                                                                                <>
                                                                                    <Select
                                                                                        defaultValue={item.type ? item.type : null}
                                                                                        className='collaps-header-action-btn-type'
                                                                                        style={{
                                                                                            width: 120,
                                                                                        }}
                                                                                        onChange={(e) => editSideBarItemFunction(e, item, 'type')}
                                                                                    >
                                                                                        <Option value="Iframe">Iframe</Option>
                                                                                        <Option value="form">form</Option>
                                                                                        <Option value="external_page">External Page</Option>
                                                                                    </Select>
                                                                                    <button className='collaps-header-action-btn-hide'>Hide</button>
                                                                                    <button onClick={() => { deleteSideItemFunction(item) }} className='collaps-header-action-btn-delete'>Delete</button>

                                                                                </>
                                                                                : null
                                                                        }

                                                                    </>
                                                                }>
                                                                    <Row>
                                                                        <Col xs={24} sm={24} md={8}>
                                                                            <div className="demo-container">
                                                                                <div className="demo-icon">
                                                                                    <i className={item.icon ? item.icon : ''}></i>
                                                                                </div>
                                                                                <div className="demo-details">
                                                                                    <div>
                                                                                        {item.title ? item.title : null}
                                                                                    </div>
                                                                                    <div className="small-tag">
                                                                                        {item.type ? item.type : null}
                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={16}>
                                                                            {
                                                                                item.editable ?
                                                                                    <>
                                                                                        {(() => {
                                                                                            if (item.type == 'Iframe') {

                                                                                                return (
                                                                                                    <>
                                                                                                        <label className="inline-edit-label">Iframe Url or Metabse ID</label>
                                                                                                        <EditText
                                                                                                            className="inline-edit-ifrem-redirect"
                                                                                                            defaultValue={item.redirect ? item.redirect : 'Add Iframe Url Here...'}
                                                                                                            onSave={(e) => {
                                                                                                                editSideBarItemFunction(e.value, item, 'redirect')
                                                                                                            }}
                                                                                                        />

                                                                                                    </>

                                                                                                )
                                                                                            } else if (item.type == 'form') {


                                                                                                return (
                                                                                                    <>
                                                                                                        <Select
                                                                                                            className="form-select-drop"
                                                                                                            showSearch
                                                                                                            placeholder="Select Form"
                                                                                                            optionFilterProp="children"
                                                                                                            value={item.form != '' ? item.form.name : 'Select Form'}
                                                                                                            onChange={(e) => { editSideBarItemFunction(e, item, 'form-change') }}
                                                                                                        >
                                                                                                            {listForm.map((item) => (
                                                                                                                <Option value={item.key}>{item.label}</Option>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                        View
                                                                                                        <Select
                                                                                                            style={{ marginTop: 20, marginLeft: 20 }}
                                                                                                            showSearch
                                                                                                            placeholder="Select a Icon"
                                                                                                            optionFilterProp="children"
                                                                                                            value={item.viewType ? item.viewType : 'all'}
                                                                                                            onChange={(e) => { editSideBarItemFunction(e, item, 'view-change') }}
                                                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                        >
                                                                                                            <Option value="all">All</Option>
                                                                                                            <Option value="user_only">Created Only</Option>
                                                                                                            <Option value="participants">Participant's</Option>



                                                                                                        </Select>
                                                                                                    </>


                                                                                                )

                                                                                            }
                                                                                            else if (item.type == 'external_page') {
                                                                                                return (
                                                                                                    <EditText
                                                                                                        className="inline-edit-ifrem-redirect"
                                                                                                        defaultValue={item.redirect ? item.redirect : 'Add redirect Url Here...'}
                                                                                                        onSave={(e) => {
                                                                                                            editSideBarItemFunction(e.value, item, 'redirect')
                                                                                                        }}
                                                                                                    />
                                                                                                )

                                                                                            }

                                                                                        })()}
                                                                                    </>
                                                                                    : null
                                                                            }

                                                                        </Col>

                                                                    </Row>

                                                                    <div className='colapse-body'>
                                                                        {
                                                                            item.editable ?
                                                                                <>
                                                                                    {(() => {
                                                                                        if (item.type == 'Iframe') {

                                                                                            return (
                                                                                                <>
                                                                                                    icon

                                                                                                    <Select
                                                                                                        style={{ marginTop: 20, marginLeft: 20 }}
                                                                                                        showSearch
                                                                                                        placeholder="Select a Icon"
                                                                                                        optionFilterProp="children"
                                                                                                        onChange={(e) => { editSideBarItemFunction(e, item, 'icon-change') }}
                                                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                    >
                                                                                                        <Option value="fa fa-heart">Heart</Option>
                                                                                                        <Option value="fa fa-tasks">Task</Option>
                                                                                                        <Option value="fa fa-check-square">check</Option>
                                                                                                        <Option value="fas fa-chart-line">line</Option>
                                                                                                        <Option value="fas fa-users">users</Option>
                                                                                                        <Option value="fa fa-list">list</Option>
                                                                                                        <Option value="far fa-folder">folder</Option>
                                                                                                        <Option value="fal fa-language">language</Option>
                                                                                                        <Option value="fa fa-cogs">cogs</Option>
                                                                                                        <Option value="fas fa-globe">globe</Option>
                                                                                                        <Option value="far fa-file">file</Option>
                                                                                                        <Option value="far fa-address-card">card</Option>




                                                                                                    </Select></>
                                                                                            )
                                                                                        } else if (item.type == 'form') {


                                                                                            return (
                                                                                                <>
                                                                                                    Actions
                                                                                                    <Select
                                                                                                        mode="multiple"
                                                                                                        allowClear
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                        }}
                                                                                                        placeholder="Add actions"
                                                                                                        defaultValue={item.actions ? item.actions : []}
                                                                                                        onChange={(e) => editSideBarItemFunction(e, item, 'action-change')}
                                                                                                    >
                                                                                                        <Option key='Add'>Add</Option>
                                                                                                        <Option key='Import'>Import</Option>
                                                                                                        <Option key='Export'>Export</Option>
                                                                                                        <Option key='Pagination'>Pagination</Option>
                                                                                                        <Option key='Search'>Search</Option>

                                                                                                    </Select>
                                                                                                    icon

                                                                                                    <Select
                                                                                                        style={{ marginTop: 20, marginLeft: 20 }}
                                                                                                        showSearch
                                                                                                        placeholder="Select a Icon"
                                                                                                        optionFilterProp="children"
                                                                                                        onChange={(e) => { editSideBarItemFunction(e, item, 'icon-change') }}
                                                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                    >
                                                                                                        <Option value="fa fa-heart">Heart</Option>
                                                                                                        <Option value="fa fa-tasks">Task</Option>
                                                                                                        <Option value="fa fa-check-square">check</Option>
                                                                                                        <Option value="fas fa-chart-line">line</Option>
                                                                                                        <Option value="fas fa-users">users</Option>
                                                                                                        <Option value="fa fa-list">list</Option>
                                                                                                        <Option value="far fa-folder">folder</Option>
                                                                                                        <Option value="fal fa-language">language</Option>
                                                                                                        <Option value="fa fa-cogs">cogs</Option>
                                                                                                        <Option value="fas fa-globe">globe</Option>
                                                                                                        <Option value="far fa-file">file</Option>
                                                                                                        <Option value="far fa-address-card">card</Option>




                                                                                                    </Select>


                                                                                                </>


                                                                                            )

                                                                                        }

                                                                                    })()}


                                                                                </>
                                                                                : null
                                                                        }

                                                                    </div>





                                                                </Panel>
                                                            </Collapse>

                                                        ))
                                                    }
                                                </> : null

                                            }
                                        </Space>
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                    </div>
                </Grid>
            </Grid>

            <Modal
                open={openMainAdd}
                title="Add Side Menu Item"
                onOk={() => { setMainAddOpen(false) }}
                onCancel={() => { setMainAddOpen(false) }}
                footer={[
                    <Button danger key="back" onClick={() => { setMainAddOpen(false) }}>
                        Return
                    </Button>,
                    <Button danger type="primary" onClick={() => { addNewSideHeader() }}>
                        Submit
                    </Button>
                ]}
            >
                <div>
                    Title
                    <Input value={newSideHeaderTitle} onChange={(e) => setNewSideHeaderTitle(e.target.value)} placeholder="title" />

                </div>

                <div>
                    Key
                    <Input value={newSideHeaderKey} onChange={(e) => setNewSideHeaderKey(e.target.value)} placeholder="Key" />

                </div>


            </Modal>

        </React.Fragment>

    );
};



export default SideBarConfig
