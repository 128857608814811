import React, { useState, useEffect } from "react";
import {
    useDispatch, connect
} from "react-redux";
import './style.css'
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import {
    CardContent,
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography

} from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

import { spacing } from "@mui/system";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




import { getAllFormsReducer, assignFormToComponentReducer, getAllassignFormToComponentReducer } from "../../../redux/slices/formSettings"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import FormDetails from './compoment/FormDetails'






const ManageComponentSettings = (props) => {



    const { t } = useTranslation();

    const dispatch = useDispatch();


    const Card = styled(MuiCard)(spacing);

    const Divider = styled(MuiDivider)(spacing);


    const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


    const [selectedComponent, changeSelectedComponent] = useState('')

    const [formList, setFormList] = useState([])

    const [formComponentList, setFormComponentList] = useState([])


    const [openSelectForm, setSelectForm] = useState(false)


    const tableRow =
        [
            {
                name: 'user',
                key: 'userEdit',
                action: 'edit'
            },
            {
                name: 'Time Line',
                key: 'timeLineEdit',
                action: 'edit'
            },
            {
                name: 'Language',
                key: 'language',
                action: 'edit'
            },
            {
                name: 'App config',
                key: 'app_config',
                action: 'edit/add'
            },
            {
                name: 'App permission',
                key: 'permission',
                action: 'edit/add'
            },
            {
                name: 'Attendance',
                key: 'Attendance',
                action: 'edit/add'
            },

            
        ]



    const getAllForms = async () => {
        const array = await dispatch(getAllFormsReducer(true))
        setFormList(array.payload.respons.data)
        // 
    }

    const getAllFormComponent = async () => {
        const res = await dispatch(getAllassignFormToComponentReducer())
        setFormComponentList(res.payload)
        // 
    }

    const openAssignFormToComponent = async (e) => {
        setSelectForm(true)
        changeSelectedComponent(e)
        // 
    }

    const assignFormToComponent = async (e) => {
        let obj = {
            formId: e,
            componentName: selectedComponent
        }


        await dispatch(assignFormToComponentReducer(obj))

        getAllForms()
        getAllFormComponent()
        closeAssignFormToComponent(e)

    }

    const closeAssignFormToComponent = async () => {
        setSelectForm(false)
        changeSelectedComponent('')
        // 
    }




    useEffect(() => {


        getAllForms()
        getAllFormComponent()
    }, []);


    return (
        <>
            <React.Fragment>
              

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card mb={6}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Forms
                                </Typography>
                                <Typography variant="body2" gutterBottom>

                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Component</TableCell>
                                                    <TableCell align="left">Action</TableCell>
                                                    <TableCell align="left">Form</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {tableRow.map((row) => (
                                                    <TableRow
                                                        key={row.key}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.action}</TableCell>
                                                        <TableCell>
                                                            {
                                                                formComponentList ? formComponentList.find((e) => e.componentName === row.key) && formComponentList.find((e) => e.componentName === row.key).form.status === 'Active' ? <>
                                                                    <Button onClick={() => openAssignFormToComponent(row.key)}>{formComponentList.find((e) => e.componentName === row.key).form.name}</Button>
                                                                </> : <>
                                                                    <Button onClick={() => openAssignFormToComponent(row.key)}>ADD</Button>
                                                                </> : <Button onClick={() => openAssignFormToComponent(row.key)}>ADD</Button>
                                                            }

                                                        </TableCell>
                                                    </TableRow>

                                                ))}


                                            </TableBody>
                                        </Table>
                                    </TableContainer>


                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>



                <Dialog
                    fullWidth={true}
                    maxWidth='sm'
                    onClose={closeAssignFormToComponent} open={openSelectForm}>
                    <DialogTitle>Select Form</DialogTitle>
                    <List sx={{ pt: 0 }}>
                        {
                            formList ? <>
                                {formList.map((row) => (
                                    <ListItem button onClick={() => { assignFormToComponent(row.id) }} key={row.id}>
                                        <Alert className='style-list-alert' icon={false} severity="success">
                                            <AlertTitle>{row.name}</AlertTitle>
                                            {row.description} — <strong><FormDetails status={row.status} /></strong>
                                        </Alert>
                                    </ListItem>
                                ))}
                            </> : <></>
                        }


                        {/* <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add account" />
                        </ListItem> */}
                    </List>
                    <DialogActions>
                        <Button onClick={closeAssignFormToComponent}>Close</Button>
                    </DialogActions>
                </Dialog>








            </React.Fragment>
        </>
    )
}


export default ManageComponentSettings
