

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';

import { Carousel, Select, InputNumber, Row, Col, Card, Avatar, Skeleton, Switch, Popover, Button as AntButton, notification, Image } from 'antd';
import { init } from 'i18next';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchBar from "material-ui-search-bar";
import imageCompression from 'browser-image-compression';
import { Camera } from "../storage/camera";
import { useTranslation } from "react-i18next";
 
// or
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UploadImageToS3WithReactS3 } from "../storage/s3";
const useStyles = makeStyles({
    table: {
        // minWidth: 350
    },
    th: {
        width: "70%",
    },
    td: {
        width: "10%",
        paddingRight: 0,
        paddingLeft: 0
    }
});
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const { Option } = Select;
const { Meta } = Card;
const DataGrid = (props) => {

    const [valueData, setValueData] = useState([])
    const [enumList, setEnumList] = useState([])
    const [cardImage, setCardImage] = useState();
    const [isLoading, loading] = useState(true)

    const [isCameraOpen, setIsCameraOpen] = useState(false);

    const [gridArray, setGridArray] = useState([])

    const [selectedRow, setSelectedRow] = useState(null)
    const [count, setCount] = useState(0)
    const [imgset, setimageSet] = useState([])
    const [lable, setLable] = useState(null)
    const [sectionTitle, setSectionTitle] = useState(null)
    const [rows, setRows] = useState(null);
    const [searched, setSearched] = useState("");
    const classes = useStyles();
    const { t, i18n } = useTranslation();



    const updateData = async (key, value) => {


        const newState = gridArray.map(obj => {
            // 👇️ if id equals 2, update country property
            if (obj.value === value) {
                return { ...obj, count: key };
            }

            // 👇️ otherwise return object as is
            return obj;
        });

        props.handleChange(props.path, newState)
    };

    const countBarcodes = () => {
        setCount(0)
        gridArray.map(i => {
            if (i.count > 0) {
                setCount(count => count + 1)
            }
        })
    }
    useEffect(() => {
        countBarcodes()
    }, [gridArray])

    const updateDataImgProduct = async (imageBlob, se) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            // let tempUpload = true;

            const compressedFile = await imageCompression(imageBlob.target.files[0], options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            let tempUpload = await UploadImageToS3WithReactS3(compressedFile, "mertracker", 'merTrackerProduct');
            if (tempUpload) {

                const newState = gridArray.map(obj => {
                    // 👇️ if id equals 2, update country property
                    if (obj.value === se) {
                        return { ...obj, img: tempUpload };
                    }

                    // 👇️ otherwise return object as is
                    return obj;
                });

                props.handleChange(props.path, newState)
            }







        } catch (e) {
            console.log("error", e)
        }






        // props.handleChange(props.path, newState)

    };

    const init = async (e) => {

        loading(true)
        if (props?.schema?.detail?.multiStage) {
            //console.log(props?.schema?.detail,'props?.schema?.detail')
            setLable(props?.schema?.detail?.lableTxt ? t(props.schema.detail.lableTxt) : null)

        }
        setSectionTitle(props?.schema?.detail?.sectionTitle ? t(props.schema.detail.sectionTitle) : null)
        let array = []
        let con = e?.data ? e.data : e.schema.defaultArray ? e.schema.defaultArray : []
        array = e?.data ? e.data : e.schema.defaultArray ? e.schema.defaultArray : []

        const parentImageKey = e?.schema?.detail?.parentImage ? e.schema.detail.parentImage : null
        let imageArray = []
        if (parentImageKey) {
            for (const arrObj of array) {
                const allanswerSelect = arrObj?.allansw[parentImageKey] ? arrObj.allansw[parentImageKey] : null
                if (allanswerSelect) {
                    imageArray.push(allanswerSelect)
                }
            }
        }

        setimageSet(imageArray)


        let temoparr = []
        for (let sing of con) {


            sing = {
                ...sing,
                displayArray: sing.value.split('-')
            }
            temoparr.push(sing)
        }
        // temoparr.sort(function (a, b) {

        //     let aSize = 0
        //     let bSize = 0
        //     switch (b.allansw.SIZE_DESCRIPTION) {
        //         case 'S':
        //             bSize = 1
        //             break;
        //         case 'M':
        //             bSize = 2
        //             break;
        //         case 'L':
        //             bSize = 3
        //             break;
        //         case 'XL':
        //             bSize = 4
        //             break;
        //         case 'XXL':
        //             bSize = 5
        //             break;
        //         case 'F':
        //             bSize = 6
        //             break;
        //         default:
        //             bSize = parseInt(b.allansw.SIZE_DESCRIPTION)
        //     }

        //     switch (a.allansw.SIZE_DESCRIPTION) {
        //         case 'S':
        //             aSize = 1
        //             break;
        //         case 'M':
        //             aSize = 2
        //             break;
        //         case 'L':
        //             aSize = 3
        //             break;
        //         case 'XL':
        //             aSize = 4
        //             break;
        //         case 'XXL':
        //             aSize = 5
        //             break;
        //         case 'F':
        //             aSize = 6
        //             break;
        //         default:
        //             aSize = parseInt(a.allansw.SIZE_DESCRIPTION)
        //     }

        //     if (a.allansw.COLOR_CODE) {
        //         return a.allansw.COLOR_CODE.localeCompare(b.allansw.COLOR_CODE) || aSize - bSize;
        //     } else {
        //         return parseInt(aSize) - parseInt(bSize);
        //     }

        // });


        setGridArray(temoparr)
        console.log("temoparr", temoparr)
        loading(false)
        setRows(null)
        setSearched("")
    };



    useEffect(() => {
        // alert()
        init(props)
    }, [props]);


    const requestSearch = (searchedVal) => {
        if (searchedVal === "") {
            setRows(null)
        }
        else {
            const filteredRows = gridArray.filter((row) => {
                return row.value.toLowerCase().includes(searchedVal.toLowerCase());
            });
            setRows(filteredRows);
        }
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const scrollToTop = () => {
        console.log("scrollToTop")
        window.scrollTo(0, 0);
    };


    const renderTitle = (row) => (
        row.displayArray ?
            <>
                {
                    row.displayArray.map((displayVal) => (

                        <div>
                           {t(displayVal)}
                        </div>

                    ))
                }
            </>
            : '-'
    )

    const renderInput = (row) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input
                type="number"
                style={{ width: 35, height: 35, textAlign: "center", border: "1px solid #8D8D8D" }}
                value={row.count === 0 ? null : row.count}
                placeholder='0'
                min={0}
                max={999}
                onInput={(e) => {
                    const value = parseInt(e.target.value.slice(0, 3));
                    updateData(value, row.value);
                }}
            />
        </div>

    )

    const renderImage = (row) => (
        row.img ?
            <IconButton color="primary" aria-label="upload picture" component="label">
                <input onChange={(e) => { updateDataImgProduct(e, row.value) }} hidden accept="image/*" type="file" capture="environment" />


                {/* <Avatar src={row.img.location ? row.img.location : null} /> */}
                <Image
                    width={50}
                    height={50}
                    preview={false}
                    src={row.img.location ? row.img.location : null}
                />


            </IconButton> :
            <>


                <IconButton color="primary" aria-label="upload picture" component="label">
                    <input onChange={(e) => { updateDataImgProduct(e, row.value) }} hidden accept="image/*" type="file" capture="environment" />


                    <PhotoCamera />


                </IconButton>
            </>
    )

    const renderPopover = (row) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Popover content={(
                <>

                    {(() => {
                        const objTemp = row.allansw
                        if (objTemp) {
                            if (objTemp) {

                                return (
                                    <>
                                        {
                                            Object.keys(objTemp).map((objKey) => (
                                                <>
                                                    <div>
                                                        {objKey.replace("_", " ")} - {objTemp[objKey] ? objTemp[objKey].toString() : null}
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </>
                                )
                            } else {
                                <>
                                    empty
                                </>
                            }

                        } else {
                            return (
                                <>
                                    empty
                                </>
                            )
                        }


                    })()}
                </>
            )} title="" placement="bottom" trigger="focus">
                <AntButton type="text" shape="circle" icon={<InfoCircleOutlined />} />
            </Popover>
        </div>

    )
    const mapData = rows ? rows : gridArray
    return (
        <div className='custom-component data-grid-height'>

            {
                !isLoading ? <>
                   
                    {
                        sectionTitle ? <div className='grid-view-lable' dangerouslySetInnerHTML={{ __html: sectionTitle }}ddd></div> : null
                    }
                    {
                        lable ? <div className='grid-view-lable'>{t(lable)} ({count}/{gridArray.length} Items )</div> : null
                    }

                    {
                        imgset.length != 0 ? <>

                            <Carousel >
                                {
                                    imgset.map((row) =>
                                        <div className='carousel-image'>
                                            <img src={row.location} alt="Italian Trulli" />
                                        </div>
                                    )
                                }
                            </Carousel>
                        </> : null
                    }

                    <Row className='data-grid-answer'>

                        {gridArray.map((row) => (

                            <Col style={{ padding: 10, width: '100%' }} xm={24} sm={24} md={8} key={row.value}>
                                <Card
                                    style={{
                                        width: '100%',
                                        marginTop: 16,
                                    }}
                                >
                                    <div>


                                        <Meta
                                            avatar={
                                                renderImage(row)
                                            }
                                            title={<>
                                                {
                                                    row.displayArray ?
                                                        <>
                                                            {
                                                                row.displayArray.map((displayVal) => (

                                                                    <div>
                                                                        {displayVal}
                                                                    </div>

                                                                ))
                                                            }
                                                        </>
                                                        : '-'
                                                }
                                                <Popover content={(
                                                    <>

                                                        {(() => {
                                                            const objTemp = row.allansw
                                                            if (objTemp) {
                                                                if (objTemp) {

                                                                    return (
                                                                        <>
                                                                            {
                                                                                Object.keys(objTemp).map((objKey) => (
                                                                                    <>
                                                                                        <div>
                                                                                            {objKey.replace("_", " ")} - {objTemp[objKey] ? objTemp[objKey].toString() : null}
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    )
                                                                } else {
                                                                    <>
                                                                        empty
                                                                    </>
                                                                }

                                                            } else {
                                                                return (
                                                                    <>
                                                                        empty
                                                                    </>
                                                                )
                                                            }


                                                        })()}





                                                    </>
                                                )} title="" placement="bottom" trigger="focus">
                                                    <AntButton type="text" shape="circle" icon={<InfoCircleOutlined />} />
                                                </Popover>
                                            </>}
                                        />

                                    </div>
                                    <div style={{ float: 'right' }}>

                                        <input
                                            type="number"
                                            style={{ width: "100px" }}
                                            value={row.count === 0 ? null : row.count}
                                            placeholder='0'
                                            min={0}
                                            max={999}
                                            onInput={(e) => {
                                                const value = parseInt(e.target.value.slice(0, 3));
                                                updateData(value, row.value);
                                            }}
                                        />


                                    </div>

                                </Card>


                            </Col>


                        ))}

                    </Row>
                    <Paper className='mobile-data-grid-answer'>
                        <SearchBar
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                            style={{ border: "none" }}
                        />
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {mapData.map((row) => (
                                        <TableRow key={row.value}>
                                            <TableCell className={classes.th} component="th" scope="row">
                                                {
                                                    renderTitle(row)
                                                }
                                            </TableCell>
                                            <TableCell className={classes.td} size="small">{renderInput(row)}</TableCell>
                                            <TableCell className={classes.td} size="small">{renderImage(row)}</TableCell>
                                            <TableCell className={classes.td} size="small">
                                                {renderPopover(row)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>



                </> : null
            }



            <Dialog fullWidth={true}
                maxWidth='md' open={isCameraOpen} onClose={() => setIsCameraOpen(false)} >
                <DialogTitle>take photo</DialogTitle>
                <DialogContent>
                    <div id="frontCam" className="" role="dialog">
                        <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            role="document"
                        >
                            <div className="modal-content">

                                <div className="">
                                    <Camera
                                        loading={loading}
                                        onCapture={(blob) => {
                                            updateDataImgProduct(blob);
                                        }}

                                        onClear={() => setCardImage(undefined)}
                                    />

                                    {/* <button >Open Camera</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCameraOpen(false)}>{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </div>



    );
}



export default withJsonFormsControlProps(DataGrid);
