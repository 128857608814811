import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig,version } from "../../config";

let initialState = {
    loading:false,
    isOpenAdd:false,
    isDeleted: false,
    schamaList:[],
    profiles:[]
};

export const onDomLoading = createAsyncThunk(
    "internal/onDomLoading",


    async (code) => {

       return false
    }
);

export const openAddExportReducer = createAsyncThunk(
    "internal/openAddExportReducer",


    async () => {

       return true
    }
);
export const closeAddExportReducer = createAsyncThunk(
    "internal/closeAddExportReducer",


    async () => {

       return true
    }
);


export const getSchemaReducer = createAsyncThunk(
    "internal/getSchemaReducer",


    async (id) => {
         
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-schema/${id}`;

        const response = await axios.get(endPoint);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const addNewProfileReducer = createAsyncThunk(
    "internal/addNewProfileReducer",


    async (data) => {
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/add-new-export-profile`;

        const response = await axios.post(endPoint,data);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const updateProfileReducer = createAsyncThunk(
    "internal/updateProfileReducer",


    async (data) => {
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/update-export-profile`;

        const response = await axios.post(endPoint,data);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const deleteProfileReducer = createAsyncThunk(
    "internal/deleteProfileReducer",
    async (data) => {
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/delete-export-profile`;

        const response = await axios.post(endPoint,data);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const getProfileReducer = createAsyncThunk(
    "internal/getProfileReducer",


    async (data) => {
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-export-profile`;

        const response = await axios.post(endPoint,data);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);





export const settingSlice = createSlice({
    name: "internal",
    initialState,
    extraReducers: {

        [onDomLoading.pending]: (state, action) => {
            //
           

            return {
                ...state,
                loading:true
            }
        },
        [openAddExportReducer.pending]: (state, action) => {
            //
           
            return {
                ...state,
                isOpenAdd:false
            }
            
        },
        [openAddExportReducer.fulfilled]: (state, action) => {
            return {
                ...state,
                isOpenAdd:true
            }
        },
        [closeAddExportReducer.pending]: (state, action) => {
            //
           
            return {
                ...state,
                isOpenAdd:true
            }
            
        },
        [closeAddExportReducer.fulfilled]: (state, action) => {
            return {
                ...state,
                isOpenAdd:false
            }
        },
        [getSchemaReducer.fulfilled]: (state, action) => {
             
            const temp=action?.payload?action.payload:[]
            return {
                ...state,
                schamaList:temp
            }
        },
        [addNewProfileReducer.pending]: (state, action) => {
           
            return {
                ...state
            }
        },
        [addNewProfileReducer.fulfilled]: (state, action) => {
             
            const temp=action?.payload?.data?action.payload.data:[]
            return {
                ...state,
                isOpenAdd:false,
                profiles:temp
                //schamaList:temp
            }
        },
        [deleteProfileReducer.pending]: (state, action) => {
           
            return {
                ...state,
                isDeleted: false
            }
        },
        [deleteProfileReducer.fulfilled]: (state, action) => {
             
            const temp=action?.payload?.data?action.payload.data:[]
            return {
                ...state,
                isDeleted: true
                //schamaList:temp
            }
        },
        [updateProfileReducer.pending]: (state, action) => {
           
            return {
                ...state,
                isDeleted: false
            }
        },
        [updateProfileReducer.fulfilled]: (state, action) => {
             
            return {
                ...state,
                isOpenAdd:false,
                isDeleted: true
            }
        },
        [getProfileReducer.fulfilled]: (state, action) => {
             
            const temp=action?.payload?.data?action.payload.data:[]
            return {
                ...state,
                profiles:temp,
                //schamaList:temp
            }
        },

    },
});

export default settingSlice.reducer;


