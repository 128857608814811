import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

const initialState = {
 visibleFilterDrawer:false,
    visibleAnswerDrawer:false,
    answer:null,
    isFormView:false
};

export const getProcessingFormReducer = createAsyncThunk(
    "dynamicPage/getProcessingForm",
    async (payload) => {
        console.log("payload",payload)
        let endPoint = `${apiConfig.adminApi}/form/get-process-forms`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, payload, config);
        return response.data;
    }
);

export const getProcessingFormAnswersReducer = createAsyncThunk(
    "dynamicPage/getProcessingFormAnswers",
    async (payload) => {
        console.log("payload",payload)
        let endPoint = `${apiConfig.adminApi}/form/get-Answers`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, payload, config);
        return response.data;
    }
);

//

export const manageFilterDrawer = createAsyncThunk(
    "dynamicPage/manageFilterDrawer",
    async (data) => {

            return data;

    }
);
export const manageViewAnswerDrawer = createAsyncThunk(
    "dynamicPage/manageViewAnswerDrawer",
    async (data) => {

        return data;

    }
);


export const getDynamicPageInitial = createAsyncThunk(
    "dynamicPage/getDynamicPageInitial",
    async (formId) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/setting/get-config-by-id/${formId}`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.get(endPoint,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const addDynamicWidget = createAsyncThunk(
    "dynamicPage/addDynamicWidget",
    async () => {
        let array = [];
        let formdata={}
        let endPoint = `${apiConfig.adminApi}/setting/add-dynamic-widget`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint,formdata,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const handleDynamicWidget = createAsyncThunk(
    "dynamicPage/handleDynamicWidget",
    async (formdata) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/setting/handle-dynamic-widget`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint,formdata,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const addFormToWidget = createAsyncThunk(
    "dynamicPage/addFormToWidget",
    async (formdata) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/setting/add-form-to-widget`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint,formdata,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const deleteDynamicWidget = createAsyncThunk(
    "dynamicPage/deleteDynamicWidget",
    async (formdata) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/setting/delete-dynamic-widget`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint,formdata,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);




export const FormLoading = createAsyncThunk(
    "dynamicPage/FormLoading",
    async (data) => {
       

        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);

export const setAnswerViewReducer = createAsyncThunk(
    "dynamicPage/setAnswerViewReducer",
    async (data) => {


        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);








export const dynamicPageSlice = createSlice({
    name: "dynamicPage",
    initialState,
    extraReducers: {
        [FormLoading.fulfilled]: (state, action) => {
         
            
            const st=  {
                ...state,
                isLoading:action.payload
            }
            
       
            return st
        },
        [getDynamicPageInitial.fulfilled]: (state, action) => {
         
           // alert()
            const st=  {
                ...state,
                    contain: action.payload.data
                    
                }
            
           return st
        },
        [manageFilterDrawer.fulfilled]: (state, action) => {

            const st=  {
                ...state,
                visibleFilterDrawer:action.payload

            }

            return st
        },
        [manageViewAnswerDrawer.fulfilled]: (state, action) => {

            const st=  {
                ...state,
                visibleAnswerDrawer:action.payload

            }

            return st
        },
        [setAnswerViewReducer.fulfilled]: (state, action) => {

            const data=action.payload
             
            console.log('data',data)
            const st=  {
                ...state,
                answer:action.payload,
                visibleAnswerDrawer:true,
                isFormView:data.isFormView?data.isFormView:false

            }

            return st
        },


    },
});

export default dynamicPageSlice.reducer;

