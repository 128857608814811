

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select } from 'antd';
import { init } from 'i18next';
const { Option } = Select;

const Details = (props) => {
    const { t, i18n } = useTranslation();
    const [val, setVal] = useState(null)

    const [reqFields, setReqField] = useState([])
    const [data, setData] = useState({})
    const init = (data) => {
        setReqField(data?.rootSchema?.properties?.database?.properties?.main?.required ? data.rootSchema.properties.database.properties.main.required : [])

    }
    //

    useEffect(() => {
        init(props)

        //
    }, [props]);
    return (
        <>

            {
                reqFields ? <>
                    {reqFields.map((row) => (
                       <>{
                        data[row]?null:<div>{row} is required</div>
                        }</>
                    ))}
                </> : <></>
            }



        </>



    );
}



export default withJsonFormsControlProps(Details);
