import { useTranslation } from "react-i18next";

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import Select from 'react-select';
import { init } from 'i18next';
import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";

const MultiSelect = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState([])
    const [enumList, setEnumList] = useState([])
    const [lableName, setLableName] = useState('')
    const [isLoading, loading] = useState(true)
    const [isLoadingEnumValue, setLoadngEnumValue] = useState(false)
    const [fullObj, setfullObj] = useState([])
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const updateData = async (e) => {

        let arr = []
        for (const els of e) {
            arr.push(els.value)

        }

        // setValueData(props.data)
        props.handleChange(props.path, arr)


    };

    const errorList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.condition) {
                if (props?.schema?.schemaElement?.ConditionBase) {
                    const conitionBase = props?.schema?.schemaElement?.ConditionBase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })


    const filterList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.filter) {
                if (props?.schema?.schemaElement?.filterbase) {
                    const conitionBase = props?.schema?.schemaElement?.filterbase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })

    const getConditionalData = async () => {
        if (props?.schema?.schemaElement?.ConditionBase) {
            let obj = {
                schama: props?.schema?.schemaElement ? props.schema.schemaElement : null,
                value: errorList
            }
            setLoadngEnumValue(true)
            if (errorList) {
                if(wholeDataObject?.database?.main?.Grouping_By){
                    obj.schama = { ...obj.schama, "grouped" : wholeDataObject?.database?.main?.Grouping_By }
                }

                if(wholeDataObject?.database?.main?.sortBy){
                    if(wholeDataObject?.database?.main?.sortBy.length > 0){
                        obj.schama = { ...obj.schama, "sortBy" : wholeDataObject?.database?.main?.sortBy }
                    }
                }

                const p = await dispatch(getCOnditionalEnum(obj))
                let unum = p?.payload?.data?.data?.enum ? p.payload.data.data.enum : []
                setfullObj(p?.payload?.data?.data?.fullObject ? p.payload.data.data.fullObject : [])
                let opt = []
                for (const els of unum) {

                    opt.push({
                        value: els.const,
                        label: els.title,
                    })
                }
                setEnumList(opt)

                if (props.data) {


                    let lable = opt.find((e) => e.value == props.data)
                    let vat = {
                        value: props.data.toString(),
                        label: lable?.label ? lable.label : '-'
                    }
                    setValueData(vat)
                }
                // setEnumList(unum)
                setLoadngEnumValue(false)
            }

        }


    }


    const getFilterData = async () => {
        if (props?.schema?.schemaElement?.filterbase) {

            if (props?.schema?.schemaElement?.filterField) {
                const filField = props.schema.schemaElement.filterField
                const datasetFilter=fullObj.filter((e)=>e.data.Format===filterList)
                 
                 
                let opt = []
                const extraEnum=props?.schema?.schemaElement?.extraDisplayArray?props.schema.schemaElement.extraDisplayArray:null
                if(props?.schema?.schemaElement?.enumLable){
                    for (const els of datasetFilter) {

                        let lbl=els.data[props.schema.schemaElement.enumLable]?els.data[props.schema.schemaElement.enumLable]:'-'

                        if(extraEnum){
                            for(const enValKey of extraEnum){
                                lbl += ` ${els.data[enValKey]?els.data[enValKey]:'-'}`
                            }
                        }
                        opt.push({
                            value: els.const,
                            label: lbl,
                        })
                    }
                }
                
                setEnumList(opt)

            }




        }


    }

    useEffect(() => {
        getFilterData()

    }, [filterList]);

    useEffect(() => {
        getConditionalData()

    }, [errorList]);

    const init = async (e) => {
        loading(true)
        let opt = []
        setLableName(props.schema.schemaElement.lableTxt ? props.schema.schemaElement.lableTxt : '')
        //setValueData(props.data ? props.data : [])
        if (props?.schema?.schemaElement?.condition) {

            if (props.data) {

                if(props.data){
                    let ans=[]
                    for(const els of props.data){
                        let lable = enumList.find((e) => e.value == els)
                         
                         
                        if(lable){
                            let vat = {
                                value: els,
                                label: lable.label
                            }
                            ans.push(vat)
                        }
                        
                        
                    }
                    setValueData(ans)
                }

                
                
            }
        } else {




            if (props?.schema?.enumList) {

                for (const els of props.schema.enumList) {

                    opt.push({
                        value: els.const,
                        label: els.title,
                    })
                }

                setEnumList(opt)
                if (props.data) {

                     
                     
                    let ans=[]
                    for(const els of props.data){
                        let lable = opt.find((e) => e.value == els)
                         
                         
                        if(lable){
                            let vat = {
                                value: els,
                                label: lable.label
                            }
                            ans.push(vat)
                        }
                        
                        
                    }
                    setValueData(ans)
                }
                //setEnumList(props.schema.enumList)

            }
        }




        loading(false)

    };
    const [device, setDevice] = useState(false);

    const customizeForDevice = () => {

        var ua = navigator.userAgent;
        var checker = {
            iphone: ua.match(/(iPhone|iPod|iPad)/),
            blackberry: ua.match(/BlackBerry/),
            android: ua.match(/Android/)
        };
        if (checker.android) {
            setDevice(false)
        } else if (checker.iphone) {
            setDevice(false)
        }
        else if (checker.blackberry) {
            setDevice(false)
        }
        else {
            setDevice(true)
        }

    }
    //

    useEffect(() => {
        customizeForDevice()
        init()

        //
    }, [props]);
    return (
        <>
            {
                !isLoading ? <>

                    <div className='zindex-0-div' style={{
                        width: '100%',
                        marginTop: '15px',
                    }}>{t(lableName)}{
                            props?.required ? <span className='required-field'>*</span> : null
                        }</div>

                    <Select isSearchable={device} value={valueData} isMulti onChange={(e) => { updateData(e) }} options={enumList} />
                    {/* <Select
                        mode="multiple"
                        getPopupContainer={() => document.getElementById('jsonFormDynamicId')}
                        autoFocus={true}
                        listHeight={150}
                        allowClear
                        style={{
                            width: '100%',
                            marginBottom: '15px'
                        }}
                        placeholder={t(lableName)}
                        value={valueData}
                        onChange={(e) => updateData(e)}
                    >


                        {enumList.map((row) => (
                            <Option key={row.const}>{row.title}</Option>
                        ))}



                    </Select> */}

                </> : null
            }


        </>



    );
}



export default withJsonFormsControlProps(MultiSelect);
