import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import moment from 'moment'
import {Button, Col} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function ReloadButton(props) {

    const init = () =>{
    }
    useEffect(() => {

        init();

    }, [props]);



    return (

        <div className="reload-button-container"> <Button
            className="reload-button"
            danger
            type="primary"
            icon={<ReloadOutlined />}
            onClick={() => {
                window.location.reload(false);
            }}
        />
        </div>

    );
}