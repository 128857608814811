import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import { useDispatch, connect, useSelector } from "react-redux";
import auth from './auth/auth'
import { getSiteBarHeader } from "./redux/slices/configSetting";
export const ProtectedRoute = ({ component: Component, ...rest }) => {

    const tempHeaderItems = useSelector(state => state.setting.permission)
    return (
        <Route {...rest} render={

            (props) => {
                if (auth.isAuthenticated()) {
                    const url = props.match.url
                    var result = /[^/]*$/.exec(url)[0];
                    if (tempHeaderItems) {
                        let permision = tempHeaderItems.length === 0 ? [] : tempHeaderItems
                   

                        if (permision.find((e) => !e.value && result === e.key)) {
                            return <Redirect to={{
                                pathname: '/unautherize'
                            }} />
                        } else {
                            return <Component {...props} />
                        }
                    } else {
                        return <Component {...props} />
                    }


                } else {
                    const url = props.match.url
                    const urlParams = new URLSearchParams(window.location.search);
                    const myParam = urlParams.get('emp-code');
                    return <Redirect to={{
                        pathname: '/sign-in',
                        state: {
                            url: url,
                            empCode:myParam?myParam:null
                        }
                    }} />
                }

            }
        } />
    )
}