import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { manageFilterDrawer } from "../../redux/slices/dynamicPage";

import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox, Form, Drawer, Typography, DatePicker
} from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function Filter(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showDrawer = useSelector((state) => {
        let data = false
        const pageState = state.page ? state.page : null
        if (pageState?.visibleFilterDrawer) {
            data = pageState.visibleFilterDrawer
        }

        return data
    })


    const schemaList = useSelector((state) => {

        if(props.schemaList){
            return props.schemaList
        }
         
        let formSchema =[]

        if (!state?.page?.contain) {
            return []
        }

        let container = state.page.contain

        if (!container?.form?.schema) {
            return []
        }

        formSchema = container.form.schema ? container.form.schema : []



        return formSchema
    })




    const FilterValue = (e) => {
        props.onFilter(e.rules_field ? e.rules_field : null)
        closeDrawer()
    }
    const closeDrawer = () => {
        dispatch(manageFilterDrawer(false))
    }
    useEffect(() => {
        if(props.setForm){
            props.setForm(form)
        }
    }, [props]);

    const onReset = () => {
        form?.resetFields()
        if(props.onClear){
            props.onClear()
        }
    }



    return (

        <div className="data-div-att filter-div" style={style}>
            <Drawer
                mask={true}
                className='drawer-filter'
                onClose={() => { closeDrawer() }}
                //   placement={placement}
                open={showDrawer}
            >
                <div layout="vertical">
                    {

                    }
                    <div className="header-top">
                        <Title level={4}>
                            {props.title ? props.title : "Filtter(s)"}
                        </Title>

                    </div>

                    <div className="sidebar-color">
                        <div className="">
                            <div className="">
                                <Form form={form} style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={FilterValue} autoComplete="off">
                                    <Form.List name="rules_field">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <div key={key} className={`form-list-show`}>
                                                        <div className={`field-class`}>
                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'key']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    showSearch
                                                                    placeholder="Select Field"
                                                                    allowClear
                                                                >
                                                                    {schemaList.map((row, index) => (
                                                                        <Option key={index} value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}



                                                                </Select>
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`field-class`}>
                                                            <Form.Item dependencies={[["rules_field", name, "key"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["key"] ? type[name]["key"] : null

                                                                        const sc = schemaList.find((comp) => comp.keyTxt === nameLevel)


                                                                        if (sc) {
                                                                            if (sc.enum) {
                                                                                enumArray = sc.enum
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                        console.log('enumArray', enumArray)
                                                                        if (sc && sc.type == 'drop') {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        showValue ?
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, 'value']}
                                                                                            >
                                                                                                <Select
                                                                                                    style={{ width: '100%' }}
                                                                                                    showSearch
                                                                                                    placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                    allowClear
                                                                                                >
                                                                                                    {enumArray.map((row, index) => (
                                                                                                        <Option key={index} value={row.const}>{row.title} - {row.const}</Option>
                                                                                                    ))}




                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            : null
                                                                                    }
                                                                                </>



                                                                            );
                                                                        } else if (sc && sc.type == 'date') {

                                                                            return (
                                                                                <>


                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <RangePicker showTime />

                                                                                    </Form.Item>




                                                                                </>



                                                                            );
                                                                        } else if (sc) {
                                                                            return (
                                                                                <>
                                                                                    {

                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'value']}
                                                                                        >
                                                                                            <Input
                                                                                                style={{ width: '100%' }}
                                                                                                showSearch
                                                                                                placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                allowClear
                                                                                            />

                                                                                        </Form.Item>

                                                                                    }
                                                                                </>



                                                                            );
                                                                        }


                                                                    }


                                                                    //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;


                                                                }}
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`action-class`}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </div>





                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        ADD FILTER
                                                    </Button>
                                                </Form.Item>


                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            FILTER
                                        </Button>
                                        <Button style={{marginLeft:5}} onClick={() => onReset()}>
                                            RESET
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>




                    </div>


                </div>


            </Drawer>
        </div>

    );
}