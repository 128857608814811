import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

const initialState = {
    roles:[],
    loading:false
};



//


export const getAllRoles = createAsyncThunk(
    "roleReducer/getAllRoles",
    async (formId) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/role/get-roles`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.get(endPoint,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const updateRole = createAsyncThunk(
    "roleReducer/updateRole",
    async (formId) => {
        let array = [];
        let endPoint = `${apiConfig.adminApi}/role/update-roles`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint,formId,config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);





export const FormLoading = createAsyncThunk(
    "dynamicPage/FormLoading",
    async (data) => {
       

        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);






export const roleslice = createSlice({
    name: "roleReducer",
    initialState,
    extraReducers: {
        [getAllRoles.fulfilled]: (state, action) => {
         
            
            const st=  {
                ...state,
                loading:false
            }
            
            return st
        },

    },
});

export default roleslice.reducer;


