

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, {  useEffect, useState } from 'react';




const Total = (props) => {

    const [valueData,setValueData]=useState(0)

    const updateData = async () => {
          
                setValueData(props.data)
                //props.handleChange(props.path, props.data)
             
    
    };

    //

    useEffect(() => {
   
        setValueData(props.data)
      
        //
    },[props.data]);
    return (
        <>
           <button onClick={updateData}>x</button>
           {valueData}
        </>



    );
}



export default withJsonFormsControlProps(Total);
