import React, { useState, useEffect } from "react";

import { useDispatch, connect, useSelector } from "react-redux";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { ElementValue } from "./components/ElementValue"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';

import { Col, Row } from 'antd';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Button, Modal } from 'antd';
import { Form, Input, Space, Select, Card, Collapse } from 'antd';
import { closeRuleModel, addNewRuleSet, getFormDetails } from "../../../../redux/slices/formRuleSetting"
const { Option } = Select;
const { Panel } = Collapse;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};




export function RuleManageMent(props) {

    const [openAddRule, setOpenAddRule] = useState(false)


    const [targetField, setTargetField] = useState(null)

    const [Addobject, setAddObject] = useState(null)

    const dispatch = useDispatch();
    const isOpenModel = useSelector((state) => {
        return state?.ruleForm?.isShowRuleModel ? state.ruleForm.isShowRuleModel : null
    })
    const selectedSchema = useSelector((state) => {
         
        return state?.ruleForm?.formSchema ? state.ruleForm.formSchema : []
    })

    const selectedForm = useSelector((state) => {
         
        return state?.ruleForm?.selectedForm ? state.ruleForm.selectedForm : null
    })
    const ruleCloseFunction = () => {

        dispatch(closeRuleModel())


    }

    const clossAddRuleModel = () => {

        setOpenAddRule(false)


    }
    const openAddRuleModel = () => {

        setOpenAddRule(true)


    }

    const onChangeField = (value) => {

         ;


    }


    const onFinish = async (values) => {

        let obj = {
            ...values,
            selectedForm: selectedForm.id
        }
        await dispatch(addNewRuleSet(obj))

        await dispatch(getFormDetails(selectedForm.id))
    };
    const onChange = async (values) => {

    };

    useEffect(() => { }, [props]);

    return (
        <>
            <Modal
                title="Rule management"
                visible={isOpenModel}
                footer={[
                    <Button danger key="back" onClick={ruleCloseFunction}>
                        Cancel
                    </Button>,
                    <Button danger key="submit" type="primary" onClick={ruleCloseFunction}>
                        Submit
                    </Button>
                ]}
            >


                <div>

                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                        <Form.List name="rules">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'field']}
                                                rules={[{ required: true, message: 'Missing Field' }]}
                                            >
                                                <Select
                                                    placeholder="Select Field"
                                                    allowClear
                                                >
                                                    {
                                                        selectedSchema.map((element) => (
                                                            <Option value={element.keyTxt}>{element.lableTxt}</Option>
                                                        ))
                                                    }


                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'answer']}
                                                rules={[{ required: true, message: 'Missing Answer' }]}
                                            >
                                                <Input placeholder="Answer" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'action']}
                                                rules={[{ required: false, message: 'Missing Field' }]}
                                            >
                                                <Select
                                                    placeholder="Select action"
                                                    allowClear
                                                >
                                                <Option value='equal'>equal</Option>
                                                <Option value='equalLarger'>equal or larger</Option>
                                                <Option value='equalSmaller'>equal or smaller</Option>
                                                <Option value='notEqual'>not equal</Option>     
                                                <Option value='larger'>larger</Option>
                                                <Option value='smaller'>smaller</Option>
                                                <Option value='notempty'>not empty</Option>
                                                <Option value='empty'>empty</Option>


                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>


                                </>
                            )}
                        </Form.List>
                        <Form.Item name="message" label="Display message">
                            <Input />
                        </Form.Item>
                        <Form.Item name="type" label="messageType">
                            <Select
                                placeholder="Select Field"
                                allowClear
                            >
                                <Option value='warning'>warning</Option>

                                <Option value='error'>error</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                    {
                        selectedForm?.ruleSet ? <>
                            <Collapse onChange={onChange}>


                                {
                                    selectedForm.ruleSet.map((element) => (
                                        <>
                                            <Panel header={`${element.type === 'warning' ? '[Warning]' : '[Error]'}-${element.message}`} key={element._id}>
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <th>Field</th>
                                                            <th>Answer</th>
                                                        </tr>
                                                        {
                                                            element.rules ? <>
                                                                {
                                                                    element.rules.map((el) => (
                                                                        <>


                                                                            <tr>
                                                                                <td>{el.field}</td>
                                                                                <td>{el.answer}</td>
                                                                            </tr>



                                                                        </>
                                                                    ))
                                                                }
                                                            </> : null
                                                        }
                                                    </table>
                                                </div>
                                            </Panel>
                                        </>
                                    ))
                                }
                            </Collapse>
                        </> : null

                    }
                </div>


            </Modal>



            {/*  */}
        </>
    );
}