import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import moment from 'moment'
import {Col} from "antd";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function SqureLable(props) {

    const dispatch = useDispatch();

    const [value, setValue] = useState(null)
    const [lable, setLable] = useState(null)
    const [loading, setLoading] = useState(null)


    const init = () =>{
        setValue(props.value?props.value:null)
        setLable(props.lable?props.lable:null)
    }
    useEffect(() => {

        init();

    }, [props]);



    return (

            <div className="data-div-att" style={style}>
                <div>
                    {lable}
                </div>
                <div>
                    {  value  }
                </div>
            </div>

    );
}