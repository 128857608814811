import React, { useState, useEffect } from "react";
import {
    useDispatch,  connect
} from "react-redux";
import '../style.css'
import { useTranslation } from "react-i18next";








const FormDetails = (props) => {



    const { t } = useTranslation();



    const [status,setstatus]=useState(null)



    useEffect(() => {
        setstatus(props.status)

    }, []);


    return (
        <>
            {status}
        </>
    )
}


export default FormDetails
