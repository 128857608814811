

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Alert, DatePicker as AntdDatePicker } from 'antd';
import { init } from 'i18next';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const DatePicker = (props) => {
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState(null)
    const [lableName, setLableName] = useState('')
    const [minimum, setMin] = useState(moment())
    const [isLoading, loading] = useState(true)
    const [warning, setWarning] = useState(null)
    const [isHide, setIsHide] = useState(false)

    const [isStart, SetStart] = useState(null)
    const [isFinish, SetFinish] = useState(null)


    const init = async (e) => {
        loading(true)
        setLableName(props.schema.schemaElement.lableTxt ? props.schema.schemaElement.lableTxt : '')
       props.schema.ui === 'hide' ? setIsHide(true) : setIsHide(false)
        let schema = props?.schema?.schemaElement ? props.schema.schemaElement : null

        if (schema.start) {
            SetStart(true)
            if (props.data) {
                setValueData()

            }else{
                props.handleChange(props.path, moment().toISOString())
            }
            // if (!valueData) {
            //    
            // }
            //  
        } else if (schema.finish){
            SetFinish(true)
            // if (props.data) {
            //     setValueData(props.data)

            // }
            //  
             //   props.handleChange(props.path, moment().format('YYYY-MM-DD LT'))
          

        }else {
            if (props.data) {
                setValueData(moment(props.data, dateFormat))

            }
        }



        loading(false)

    };
    const onChange = (date, dateString) => {
        // )
        props.handleChange(props.path, dateString)
    };

    //

    useEffect(() => {

        init()

        //
    }, [props]);
    return (
        <>
            {
                !isLoading ? <div className={isHide ? `hide-div` : `show-div`}>

                    {
                        isStart || isFinish? <></> : <>
                             {!props?.schema?.schemaElement?.isHideInEdit && (<div style={{
                                width: '100%',
                                marginTop: '15px',
                            }}>{t(lableName)} {
                                    props?.required ? <span className='required-field'>*</span> : null
                                }</div>)}

                            {
                                !props?.schema?.schemaElement?.isHideInEdit && (
                                    valueData ?
                                        <AntdDatePicker
                                            disabledDate={d => !d || d.isBefore(minimum)}
                                            disabled={props?.schema?.schemaElement?.disableEl ? props?.schema?.schemaElement?.disableEl : false}
                                            defaultValue={valueData ? valueData : ''}
                                            inputReadOnly={true} style={{
                                                width: '100%',
                                                marginBottom: '15px'
                                            }} onChange={onChange} />
                                        : <AntdDatePicker
                                            disabledDate={d => !d || d.isBefore(minimum)}
                                            disabled={props?.schema?.schemaElement?.disableEl ? props?.schema?.schemaElement?.disableEl : false}
                                            inputReadOnly={true} style={{
                                                width: '100%',
                                                marginBottom: '15px'
                                            }} onChange={onChange} />
                                )
                            }

                        </>
                    }





                </div> : null
            }



        </>



    );
}



export default withJsonFormsControlProps(DatePicker);
