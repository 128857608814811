import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import { CheckCircleTwoTone, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { manageViewAnswerDrawer } from "../../redux/slices/dynamicPage";
import { Avatar, List, Radio, Steps, Tabs } from 'antd';
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox, Form, Drawer, Typography, DatePicker
} from "antd";
import { FormViewInterface } from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import DialogContent from "@mui/material/DialogContent";
import { t } from "i18next";
import { Step } from "react-form-stepper";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function ViewAnswerDrawer(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();
    const showDrawer = useSelector((state) => {
        let data = false
        const pageState = state.page ? state.page : null
        if (pageState?.visibleAnswerDrawer) {
            data = pageState.visibleAnswerDrawer
        }

        return data
    })



    const formSchema = useSelector((state) => {
        let data = []

        const pageState = state.answer ? state.answer : null
        if (pageState?.jsonSchema) {

            data = pageState?.jsonSchema ? pageState.jsonSchema : []
        }

        return data
    })

    const formInformation = useSelector((state) => {
        let data = []
        const pageState = state.answer ? state.answer : null
        if (pageState?.formInformation) {
            data = pageState?.formInformation ? pageState.formInformation : []
        }

        return data
    })
    const formGroup = useSelector((state) => {
        let data = []
        const pageState = state.answer ? state.answer : null
        if (pageState?.elementGroup) {
            data = pageState?.elementGroup ? pageState.elementGroup : []
        }

        return data
    })

    const selectedAnswer = useSelector((state) => {
        let data = null
        const pageState = state.answer ? state.answer : null

        if (pageState?.row) {
            data = pageState?.row?.data ? pageState.row.data : null
        }

        return data
    })

    const selectedData = useSelector((state) => {
        let data = null
        const pageState = state.answer ? state.answer : null

        if (pageState?.row) {
            data = pageState?.row ? pageState.row : null
        }

        return data
    })

    const workFlow = useSelector((state) => {
        return state?.answer?.workFlow
    })



    const closeDrawer = () => {
        dispatch(manageViewAnswerDrawer(false))
    }
    useEffect(() => {

        // init();

    }, [props]);



    return (

        <div className="data-div-att answer-drawer" style={style}>
            <Drawer
                mask={true}
                onClose={() => { closeDrawer() }}
                width={window.innerWidth > 900 ? '75%' : '100%'}
                height={'100%'}
                //   placement={placement}
                placement={window.innerWidth > 900 ? 'right' : 'bottom'}
                open={showDrawer}
            >
                <div layout="vertical">



                    <div className="">
                        <div className="">
                            <div className="">
                                <Tabs defaultActiveKey="1">
                                    <Tabs.TabPane tab="ANSWERS" key="1">
                                        <FormViewInterface
                                            elementGroup={formGroup}
                                            formInformation={formInformation}
                                            jsonSchema={formSchema}
                                            id={selectedAnswer}
                                            formDetails={selectedData}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="WORK FLOWS" key="2">
                                        {workFlow ?
                                            <div>
                                            {
                                              // !workFlow.rejected?'yes':(parseInt(workFlow.rejectedStage) - 1)
                                            }
                  
                                            {(() => {
                                              if (workFlow.rejected) {
                                                
                                                  return (
                                                    <div className="status-container-reject">
                                                      {t("Rejected")}
                                                    </div>
                                                  )
                                              } else {
                                                if (workFlow.taskStage == "pending") {
                                                  return (
                                                    <div className="status-container-pending">
                                                      {t("Pending")}
                                                    </div>
                                                  );
                                                } else if (
                                                  workFlow.taskStage == "inprogress"
                                                ) {
                                                  return (
                                                    <div className="status-container-inprogress">
                                                      {t("In Progress")}
                                                    </div>
                                                  );
                                                } else if (
                                                  workFlow.taskStage == "Completed"
                                                ) {
                                                  return (
                                                    <div className="status-container-completed">
                                                      {t("Completed")}
                                                    </div>
                                                  );
                                                }
                                              }
                                            })()}
                                            <Steps
                                              direction="vertical"
                                              className="work-flow-step"
                                              size="small"
                                              current={
                                                !workFlow.rejected
                                                  ? parseInt(workFlow.currentStage) - 1
                                                  : parseInt(workFlow.rejectedStage) - 1
                                              }
                                            >
                                              {workFlow.dataTab.approvalFlow.map(
                                                (stage) => (
                                                  <Step
                                                    description={
                                                      <>
                                                        {stage.reason ? (
                                                          <>NOTE : {stage.reason}</>
                                                        ) : null}
                                                        <div>Responsible Users</div>
                                                        <List
                                                          size="small"
                                                          bordered
                                                          dataSource={stage.userData}
                                                          renderItem={(item) => (
                                                            <List.Item>
                                                              {item?.userBit?.EMAIL
                                                                ? item.userBit.EMAIL
                                                                : "-"}
                                                              {stage?.status !== "pending" ? (
                                                                <>
                                                                  {
                                                                    item.userBit.UF_USR_1642854306150 == stage?.changedBy?.UF_USR_1642854306150 ? stage.status === "Reject" ? <CloseCircleOutlined style={{ color: "red" }} /> : <CheckCircleTwoTone twoToneColor="#52c41a" /> : null
                                                                  }
                                                                </>
                                                              ) : null}
                                                            </List.Item>
                                                          )}
                                                        />
                                                      </>
                                                    }
                                                    title={
                                                      stage?.status === "pending"
                                                        ? "PENDING"
                                                        : stage?.changedBy?.NAME
                                                          ? stage.changedBy.NAME
                                                          : "UNKNOWN"
                                                    }
                                                    icon={
                                                      <Avatar
                                                        className={` ${stage.status} ${stage.isCurrentUser
                                                          ? "current-user"
                                                          : null
                                                          }`}
                                                        src={
                                                          stage?.userData?.userBit?.PERSONAL_PHOTO
                                                            ? stage.userData.userBit
                                                              .PERSONAL_PHOTO
                                                            : null
                                                        }
                                                      />
                                                    }
                                                  />
                                                )
                                              )}
                                            </Steps>
                                          </div>
                                            :
                                            "NOT AVAILABLE"
                                        }

                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="TIMELINE" key="3">
                                        NOT AVAILABLE
                                    </Tabs.TabPane>
                                </Tabs>


                            </div>
                        </div>




                    </div>


                </div>


            </Drawer>
        </div>

    );
}