import React, { useState, useEffect } from "react";
import {
  useDispatch
} from "react-redux";
import './style.css'

import { getFormSchema, getAllGroupRaducer, getFormDetails, submitFormAnswer } from "../../redux/slices/formSettings"
import { Alert } from 'antd';

import {
  Table as AntTable,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Button, Modal, Steps, Avatar, Spin, Tooltip
} from "antd";

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { message, Space } from 'antd';

import { FormInterfaceDynamic } from "../settings/JsonForm/intefaces/FormInterfaceDynamic";

import { useTranslation } from "react-i18next";

import { FormInterface } from "../settings/JsonForm/intefaces/FormInterface"
import { getLanguage } from "../../redux/slices/language";

const PublicForm = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [selectedFormId, setSelectedFormId] = React.useState(null);
  const [isLoadingElement, loadingElement] = useState(false)
  const [schemaComponent, setScheemaComponent] = React.useState([]);
  const [elementByGroup, setElementByGroup] = useState([]);
  const [formDetails, setFromDetails] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState('main');
  const [groupList, setGroupList] = useState([]);

  const [language, setLanguagesss] = useState(null);

  const getFormSchimaToDom = async (e) => {
    loadingElement(true)
    const res = await dispatch(getFormSchema(e))

    setScheemaComponent(res.payload)
    changeGroup(selectedGroupId ? selectedGroupId : 'main', res.payload)


    const tempformDetails = await dispatch(getFormDetails(e))
    setFromDetails(tempformDetails.payload)
    loadingElement(false)

  }

  const handleMenuClick = (language) => {

     
    localStorage.setItem('language', language.key);
    setLanguage(language.key)

    if(language.key=='en'){
      setLanguagesss('ENGLISH')
    }
    if(language.key=='th'){
      setLanguagesss('THAI')
    }
  };


  const setLanguage = async (code) => {

    const pack = await dispatch(getLanguage(code))

    i18n.addResourceBundle(code, 'translation', pack.payload, true, true);



    i18n.changeLanguage(code);
  }

  const changeGroup = (id, data = false) => {

    let tempSchemaComponent = []

    if (data) {
      if (id === 'main') {
        tempSchemaComponent = data.filter((e) => e.group === null)
        setElementByGroup(tempSchemaComponent)
      } else {
        tempSchemaComponent = data.filter((e) => e.group === id)
        setElementByGroup(tempSchemaComponent)
      }
    } else {
      if (id === 'main') {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === null)
        setElementByGroup(tempSchemaComponent)
      } else {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === id)
        setElementByGroup(tempSchemaComponent)
      }
    }


    setSelectedGroupId(id)
  }

  const getAllGroup = async () => {

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    // const myParam ='7c73dcfa-fc79-46c9-8ff4-ff13345ced15'

    const res = await dispatch(getAllGroupRaducer(myParam))

    if (res.payload) {
      setGroupList(res.payload)
    }



  }
  const [formDataSet, setFormDataSet] = useState({});
  const [addbtnDisable, setaddbtnDisable] = useState(false);


  const [isSuccess, setSuccess] = useState(false);



  const changeDataTrigger = (data, err) => {

     
    if (err.length != 0) {
      setaddbtnDisable(true)
    } else {
      setaddbtnDisable(false)
    }
    setFormDataSet(data)
  }

  const formSubmit = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');
    const tempData = {
      data: formDataSet,
      form: {...formDetails, schema: null, group: null }
    }

    //   
    const res = await dispatch(submitFormAnswer(tempData))
    if (res) {
      setSuccess(true)
    }

  }

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'English',
          key: 'en',
        },
        {
          label: 'Thai',
          key: 'th',
        }

      ]}
    />
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');
    //const myParam ='7c73dcfa-fc79-46c9-8ff4-ff13345ced15'

    setSelectedFormId(myParam)
    getFormSchimaToDom(myParam)
    getAllGroup()

  }, []);

  return (
    <>

      {/* <Modal
        visible={isSubmitFormOpen}
        title="Add"
        width={1000}
        onOk={() => { openSubmitForm(false) }}
        onCancel={() => { openSubmitForm(false) }}
        footer={[
          <Button danger key="back" onClick={() => { openSubmitForm(false) }}>
            Return
          </Button>,
          <Button danger key="back" onClick={() => { draftForm() }}>
            Draft
          </Button>,
          <Button disabled={addbtnDisable} danger key="submit" type="primary" onClick={() => { formSubmit() }}>
            Submit
          </Button>
        ]}
      > */}


      <div className=" full-screen-form-container-view">
        {
          schemaComponent.length !== 0 && groupList.length !== 0 ? <>
            <div className="public-form full-screen-form-container-public-form">
              {
                isSuccess ?
                  <>
                    <Alert
                      message="Success"
                      description="Form submit succuss."
                      type="success"
                      showIcon
                    />
                  </> : <>

                    <div className="action">
                      <Dropdown.Button overlay={menu}>
                        CHANGE LANGUAGE ({language?language:'ENGLISH'})
                      </Dropdown.Button>
                    </div>

                    <div>
                      <FormInterfaceDynamic
                        elementGroup={groupList}
                        jsonSchema={schemaComponent}
                        formDetails={formDetails}
                        //// handleClose={()=>{openSubmitForm(false)}}
                        // refreshPage={()=>{openSubmitForm(false)}}
                        changeDataTrigger={(data, err) => changeDataTrigger(data, err)}
                      />
                    </div>
                
                    <div className="action">
                      <Button disabled={addbtnDisable} danger key="submit" type="primary" onClick={() => { formSubmit() }}>
                      {t('Submit')}
                      </Button>
                    </div>


                  </>
              }


            </div>


          </> : null
        }
      </div>







    </>

  );
}

export default PublicForm;
