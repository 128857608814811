export const getLocation = async () => {
  try {
    const res = await getGeo();
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


// const getGeo = async () => {
//     return new Promise((resolve, reject) => {
//         let goptions = {
//             enableHighAccuracy: true,
//             timeout: 9000,
//             maximumAge: 0,
//             // maximumAge: 0,
//         };
//         function success(pos) {
//             var crd = pos.coords;

//             resolve(crd);
//             // if(props.data[props.schema.defaultArray.keyTxt]){
//             //     props.handleChange(props.path,  props.data[props.schema.defaultArray.keyTxt])
//             // }else{

//             const respo = {
//                 accuracy: crd.accuracy,
//                 latitude: crd.latitude,
//                 longitude: crd.longitude,
//             }

//             //}

//         }

//         function error(err) {

//             console.warn(`ERROR(${err.code}): ${err.message}`);
//             alert(`Geo ERROR(${err.code}): ${err.message}! Please allow location service and Try Again..`)

//             reject(err);
//         }
//         navigator.geolocation.getCurrentPosition(success, error, goptions);
//     });
// };

const getGeo = async () => {
  const goptions = {
    enableHighAccuracy: true,
    timeout: 9000,
    maximumAge: 0,
  };

  try {
    const pos = await new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, goptions)
    );
    const crd = pos.coords;

    return {
      accuracy: crd.accuracy,
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
  } catch (err) {
    console.error(`ERROR(${err.code}): ${err.message}`);
    alert(
      `Geo ERROR(${err.code}): ${err.message}! Please allow location service and Try Again..`
    );
    throw err;
  }
};
