

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Camera } from "./camera";
// or
import { Grid, Link, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { UploadImageToS3WithReactS3 } from "./s3";
import { CameraOutlined } from '@ant-design/icons';
import { Button as AntButton, Space } from 'antd';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FileControl = (props) => {
    const { t, i18n } = useTranslation();
    const [imgData, setImage] = useState(null)

    const [acceptedType, setAccetptedType] = useState('')
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();

    const [loading, setLoading] = useState(false);

    const [fileGetType, setFileGetType] = useState('local');

    const [domLoading, setDomLoading] = useState(false);


    const acceptedFileType = [
        {
            const: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lable: "EXCEL"
        },
        {
            const: "image/*",
            lable: "image"
        },
        {
            const: "video/*",
            lable: "video"
        },
        {
            const: "audio/*",
            lable: "audio"
        },
        {
            const: ".pdf",
            lable: "PDF"
        }

    ];

    const uploadImage = async (e) => {

        let tempUpload = await UploadImageToS3WithReactS3(e.target.files[0], props.schema.s3Bucket, props.schema.s3Dir);
        if (tempUpload) {
            props.handleChange(props.path, tempUpload)
            setImage(tempUpload)
        }


    };

    const setAcceptType = (type) => {
        if (type.length === 0) {
            setAccetptedType('')
        } else {
            let acceptedTypeArray = []
            for (const element of type) {
                const key = acceptedFileType.find((e) => e.lable === element)
                acceptedTypeArray.push(key.const)
            }

            //var arr = ['Hi', 'I', 'am', 'a', 'comma', 'separated', 'list'];


            setAccetptedType(acceptedTypeArray.join(','))
        }
    }

    const updateData = async (imageBlob) => {

        setLoading(true);
        try {
            // let tempUpload = true;
            let tempUpload = await UploadImageToS3WithReactS3(imageBlob, props.schema.s3Bucket, props.schema.s3Dir);
            if (tempUpload) {
                props.handleChange(props.path, tempUpload)
                setImage(tempUpload)
                setIsCameraOpen(false)
            }




        } catch (e) {

        }
        setLoading(false);

        // props.updateEmployeeData({ id: props.location.state.sectionData.taskId , ...body }).then((res) => {
        //   if (res.payload.length > 0) {
        //    // this.props.history.push("/app/employees/tasks");
        //   } else {
        //    // this.props.history.push("/login");
        //   }
        // });
    };

    useEffect(() => {

        setDomLoading(true)

        setAcceptType(props.schema.selectedFileType ? props.schema.selectedFileType : [])
        setFileGetType(props.schema.captureType ? props.schema.captureType : 'local')
        setImage(props?.data?.location ? props.data : null)
        setDomLoading(false)
        //
    }, [props]);
    return (
        <div className='custom-component'>
            {
                !domLoading ? (<>
                    {

                        fileGetType === 'local' ? (<>
                            <div className='camera-lable'>
                                {

                                    props.schema.storeMethod === "s3" ?
                                        <>
                                            <div>{t(props.label ? props.label : '-')}{
                                                props?.required ? <span className='required-field'>*</span> : null
                                            }</div>
                                            <div>
                                                <IconButton color="primary" aria-label="upload picture" component="label">
                                                    <input onChange={(e) => { uploadImage(e) }} hidden accept={acceptedType} type="file" />
                                                    {
                                                        imgData ?
                                                            <>

                                                                <img onClick={() => setIsCameraOpen(true)} className='form-image-show' src={imgData.location} alt={imgData.location} />
                                                            </>
                                                            :

                                                            <UploadFileIcon />
                                                    }

                                                </IconButton>
                                            </div>

                                        </>
                                        :
                                        <h4>Not available option</h4>
                                }

                            </div>
                        </>) : (<>
                            <div className='camera-lable'>
                                <div>{t(props.label ? props.label : '-')}{
                                    props?.required ? <span className='required-field'>*</span> : null
                                }</div>
                                {
                                    imgData ?
                                        <>

                                            <img onClick={() => setIsCameraOpen(true)} src={imgData.location} className='form-image-show' alt={imgData.location} />
                                        </>
                                        :
                                        <AntButton
                                            type=""
                                            danger
                                            icon={<CameraOutlined />}
                                            onClick={() => setIsCameraOpen(true)}
                                        >
                                            {t('OPEN CAMERA')}
                                        </AntButton>

                                }

                                <Dialog
                                    fullScreen
                                    open={isCameraOpen}
                                    onClose={() => setIsCameraOpen(false)}
                                    TransitionComponent={Transition}
                                >
                                    {/* <AppBar sx={{ position: "relative" }}>
                                        <Toolbar>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={() => setIsCameraOpen(false)}
                                                aria-label="close"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                               
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => setIsCameraOpen(false)}
                                            >
                                                Close
                                            </Button>
                                        </Toolbar>
                                    </AppBar> */}
                                    <DialogContent className="full-screen-form-container-view">
                                        <Camera
                                            loading={loading}
                                            onCapture={(blob) => {
                                                updateData(blob);
                                            }}
                                            clossCamera={() => setIsCameraOpen(false)}
                                            onClear={() => setCardImage(undefined)}
                                        />
                                    </DialogContent>
                                </Dialog>


                            </div>
                        </>)
                    }

                </>) : null
            }
        </div>



    );
}



export default withJsonFormsControlProps(FileControl);
