/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import { useDispatch, connect, useSelector } from "react-redux";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import {
  openSideNev, closeSideNev
} from "../../redux/slices/sideNevSetting";
import { getSiteBarHeader } from "../../redux/slices/configSetting";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const dispatch = useDispatch();
  //const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)

  const openDrawer = () => {
    dispatch(visible ? closeSideNev() : openSideNev());
  };

  const visible = useSelector(state => state.sideNev?.open || false);

  const handleSidenavType = type => setSidenavType(type);
  const handleSidenavColor = color => setSidenavColor(color);
  const handleFixedNavbar = type => setFixed(type);

  const [isApiCallForMobile, setIsApiCallForMobile] = useState(true)

  if (width < 991 && isApiCallForMobile) {
    setIsApiCallForMobile(false)
    dispatch(getSiteBarHeader())
  }


  function setVisibleFunction(e) {
    dispatch(openSideNev())

  }
  function setInviseFunction(e) {

    dispatch(closeSideNev())
  }
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {

    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    if (hasWindow) {
      const widths = hasWindow ? window.innerWidth : null;
      return widths;
    }
    return null;
  }

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWidth(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return null;
  }, [getWindowDimensions, hasWindow]);

  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      {width < 991 ?
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setInviseFunction()}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
            } `}
        >
          <Layout
            className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""
              }`}
          >
            <Sider
              trigger={null}
              width='100%'
              className={`sider-primary ant-layout-sider-primary ${sidenavType === "red" ? "active-route" : ""
                }`}
            >
              <Sidenav color={sidenavColor} setDrawerVisible={setVisibleFunction} />
            </Sider>
          </Layout>
        </Drawer>
        :
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {

          }}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
            }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>
      }
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""} ant-layout-header-custom`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""} ant-layout-header-custom`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
