import { useState, useEffect } from "react";

import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space, Tooltip } from "antd";

import { NavLink, Link, useHistory } from "react-router-dom";
import { Col, Row } from "antd";

import { DashboardApp } from "../elements/DashboardApp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, connect } from "react-redux";

function DashBoardApplication(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [applicationList, setApplicationList] = useState([]);
  const [dashboardName, setDashBoardName] = useState(null);

  const applist = useSelector((prState) => {
    try {
      const container = prState.setting.applicationList || [];
      const defVa = container[0] || {};
      const appA =
        defVa.data?.database?.main["dd5ea25a-e2c2-42df-85ed-7a4d6b883400"] ||
        [];
      if (appA.length) {
        return defVa;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  });

  const dashBoardlistAll = useSelector((prState) => {
    let app = [];

    if (prState?.setting) {
      const container = prState.setting.applicationList;
      app = container || [];
    }

    return app;
  });

  const dashBoardlist = useSelector((prState) => {
    return (
      prState.setting?.applicationList?.map((con) => ({
        label: con.data.database.main.Dashboard_Name,
        key: con.id,
      })) || []
    );
  });

  const handleButtonClick = (e) => {};
  const handleMenuClick = (e) => {
    const key = e.key;
    if (dashBoardlistAll) {
      const selectedD = dashBoardlistAll.find((el) => el.id === key);
      if (
        selectedD &&
        selectedD.data &&
        selectedD.data.database &&
        selectedD.data.database.main
      ) {
        const { "dd5ea25a-e2c2-42df-85ed-7a4d6b883400": appA, Dashboard_Name } =
          selectedD.data.database.main;
        if (appA && appA.length) {
          setApplicationList(appA);
        }
        if (Dashboard_Name) {
          setDashBoardName(Dashboard_Name);
        }
      }
      console.log("click", selectedD);
    }
  };

  useEffect(() => {
    if (applist?.data?.database?.main["dd5ea25a-e2c2-42df-85ed-7a4d6b883400"]) {
      const appA =
        applist.data.database.main["dd5ea25a-e2c2-42df-85ed-7a4d6b883400"];
      if (appA && appA.length) {
        setApplicationList(appA);
      }
    }
    if (applist?.data?.database?.main?.Dashboard_Name) {
      setDashBoardName(applist.data.database.main.Dashboard_Name);
    }
  }, [applist]);

  return (
    <div
      className="dashboard-application-wrapper"
      style={{ marginBottom: "10vh" }}
    >
      <div style={{ marginLeft: "5px" }}>
        <Dropdown.Button
          placement="bottom"
          size="small"
          menu={{ items: dashBoardlist, onClick: handleMenuClick }}
          onClick={handleButtonClick}
        >
          {dashboardName}
        </Dropdown.Button>
      </div>
      <Row justify="center">
        {applicationList ? (
          <>
            {applicationList.map((app) => (
              <Col key={`c-${app.Name}`}>
                <div key={`d-${app.Name}`} className="application-container">
                  <DashboardApp element={app} key={`da-${app.Name}`} />
                </div>
              </Col>
            ))}
          </>
        ) : null}
      </Row>
    </div>
  );
}


export default DashBoardApplication;
