import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { ValueDisplay } from "../elements/ValueDisplay";


import {
    MinusCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    RedoOutlined,
} from "@ant-design/icons";


import { Radio, Tabs } from "antd";

import { MoreOutlined } from "@ant-design/icons";
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space,
    Checkbox,
    Form,
    Drawer,
    Typography,
    DatePicker,
} from "antd";
import DialogContent from "@mui/material/DialogContent";
import { ValueDisplay } from "../../../components/elements/ValueDisplay";
import { openMoreOptionDrawer } from "../../../redux/slices/answer/answerSetting";
import { workFlowDrawerOpen } from "../../../redux/slices/workflow";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: "8px 0",
    marginBottom: "10px",
};

export function UserGridCard(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();

    // const [data, setData] = useState(null);
    const [workFlow, setWorkFlow] = useState(null);
    const [display, setDisplay] = useState([]);

    const { data, showModal } = props;

    return (
        <div className="grid-row">
            <div className="grid-header">
                <div className="grid-header-1">
                </div>
                <div className="grid-header-2" style={{ width: "50%" }}>
                    <div>
                        {data.action}
                    </div>
                </div>
            </div>
            <div className="grid-Body">
                {}
                <Fragment key={data.ID}>{<ValueDisplay value={{
                    col: {
                        title: "ID"
                    },
                    value: data.ID
                }} />}</Fragment>
                <Fragment key={data.NAME}>{<ValueDisplay value={{
                    col: {
                        title: "Name"
                    },
                    value: data.NAME
                }} />}</Fragment>
                <Fragment key={data.LAST_NAME}>{<ValueDisplay value={{
                    col: {
                        title: "Last Name"
                    },
                    value: data.LAST_NAME
                }} />}</Fragment>
                
                <Fragment key={data.EMAIL}>{<ValueDisplay value={{
                    col: {
                        title: "Email"
                    },
                    value: data.EMAIL
                }} />}</Fragment>
                
                <Fragment key={data.PERSONAL_MOBILE}>{<ValueDisplay value={{
                    col: {
                        title: "Mobile"
                    },
                    value: data.PERSONAL_MOBILE
                }} />}</Fragment>
                <Fragment key={data.PERSONAL_CITY}>{<ValueDisplay value={{
                    col: {
                        title: "City"
                    },
                    value: data.PERSONAL_CITY
                }} />}</Fragment>
                <Fragment key={data.WORK_POSITION}>{<ValueDisplay value={{
                    col: {
                        title: "Position"
                    },
                    value: data.WORK_POSITION
                }} />}</Fragment>
                
                
            </div>
            <div className="grid-footer">
                <div className="grid-footer-created-by font-footer">
                    <span>

                    </span>
                </div>
                <div className="grid-footer-created-at font-footer">
                    <span>

                    </span>
                </div>
            </div>
        </div>
    );
}
