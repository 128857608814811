import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getFetchUsers } from "../../../../redux/slices/users";
import { seveEmailForForm, getMailTemplateById, notificationHandleReducer } from "../../../../redux/slices/formSettings";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {
  addFormElementToServer,
  getFormSchema,
  editFormElementToServer,
  getAllElementKeyReducer,
  addValueToKeyElementReducer,
  deleteFormElementFromServer,
  deleteFormGroupFromServer,
  getAllGroupRaducer,
  addGroupRaducer,
  changeOrderReducer,
  changeOrderElementReducer,

} from "../../../../redux/slices/formSettings";
import { Radio } from 'antd';
import { Tabs } from 'antd';
import { useDispatch, connect } from "react-redux";
import "../style.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SendIcon from "@mui/icons-material/Send";

import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import Filter2Icon from "@mui/icons-material/Filter2";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { spacing } from "@mui/system";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

import {
  FileAddOutlined,
  FontColorsOutlined,
  CalculatorOutlined,
  DownCircleOutlined,
  CalendarOutlined,
  ProfileOutlined,
  AimOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Button as AntButton,
  Select as AntSelect,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Input,
  Modal, Space, Checkbox as AntdCheck
} from "antd";

import { init } from "i18next";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const { Option } = AntSelect;
const { TextArea } = Input;
const EmailTemplate = (props) => {

  const dispatch = useDispatch();
  const [valueObj, setValueObje] = useState({
    emailElement: null,
    emailContent: null,
    formTID: null,
    emailKey: null,
    bitrixtemplate: null

  })

  const [dreft, setDraft] = useState(null)

  const [dreftApproval, setDraftApproval] = useState(null)
  const [dreftRejected, setDraftRejected] = useState(null)
  const [dreftApproved, setDraftApproved] = useState(null)


  const [notificationSet, setNotificationSet] = useState({
    approval_bitrix: false,
    approval_email: false,
    approved_bitrix: false,
    approved_email: false,
    rejected_bitrix: false,
    rejected_email: false,
    submit_bitrix: false,
    submit_email: false

  })

  const [suggestions, setSuggestions] = useState([])

  const changeEmailElement = (e) => {
    setValueObje(prevState => ({
      ...prevState,
      emailKey: e
    }));
  }


  const saveEmail = async () => {
     

    await dispatch(seveEmailForForm(valueObj))
  }

  const notificationHandle = async (option, action) => {
     
    setNotificationSet(prevState => ({
     ...prevState,
     [option]:action.target.checked
    }))
    await dispatch(notificationHandleReducer({
      option: option,
      action: action.target.checked,
      form: props.selectedFormId
    }))
  }

  const init = async (props) => {
     


    let mailContent = null
    let mailContentApproval = null
    let mailContentRejected = null
    let mailContentApproved = null
    let mailKey = null
    if (props.schemaComponent) {
      let arr = []
      for (const el of props.schemaComponent) {
        const obj = { text: el.keyTxt, value: el.keyTxt, url: el.keyTxt }
        arr.push(obj)
      }
      setSuggestions(arr)
    }

    const res = await dispatch(getMailTemplateById(props.selectedFormId))
     
    if (res?.payload?.message?.payload) {

      const me = res.payload.message
      setNotificationSet({
        approval_bitrix: me.approval_bitrix ? me.approval_bitrix : false,
        approval_email: me.approval_email ? me.approval_email : false,
        approved_bitrix: me.approved_bitrix ? me.approved_bitrix : false,
        approved_email: me.approved_email ? me.approved_email : false,
        rejected_bitrix: me.rejected_bitrix ? me.rejected_bitrix : false,
        rejected_email: me.rejected_email ? me.rejected_email : false,
        submit_bitrix: me.submit_bitrix ? me.submit_bitrix : false,
        submit_email: me.submit_email ? me.submit_email : false
      })
      // const [notificationSet, setNotificationSet] = useState({


      // })
      if (res.payload.message.payload.emailContent) {
        const htmlValu = JSON.parse(res.payload.message.payload.emailContent)
        const contentBlock = htmlToDraft(htmlValu);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setDraft(editorState)
        }
        mailContent = res.payload.message.payload.emailContent
         
        // setDraft(contentBlock)
      }

      if (res.payload.message.payload.emailContentApproval) {
        const htmlValu = JSON.parse(res.payload.message.payload.emailContentApproval)
        const contentBlock = htmlToDraft(htmlValu);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setDraftApproval(editorState)
        }
        mailContentApproval = res.payload.message.payload.emailContentApproval
         
        // setDraft(contentBlock)
      }

      if (res.payload.message.payload.emailContentRejected) {
        const htmlValu = JSON.parse(res.payload.message.payload.emailContentRejected)
        const contentBlock = htmlToDraft(htmlValu);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setDraftRejected(editorState)
        }
        mailContentRejected = res.payload.message.payload.emailContentRejected
         
        // setDraft(contentBlock)
      }

      if (res.payload.message.payload.emailContentApproved) {
        const htmlValu = JSON.parse(res.payload.message.payload.emailContentApproved)
        const contentBlock = htmlToDraft(htmlValu);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setDraftApproved(editorState)
        }
        mailContentApproved = res.payload.message.payload.emailContentApproved
         
        // setDraft(contentBlock)
      }
      mailKey = res.payload.message.payload.emailKey ? res.payload.message.payload.emailKey : null


    }


    setValueObje(prevState => ({
      ...prevState,
      formTID: props.selectedFormId,
      emailContent: mailContent,
      emailContentApproval: mailContentApproval,
      emailContentRejected: mailContentRejected,
      emailContentApproved: mailContentApproved,
      emailKey: mailKey,
      bitrixtemplate: res?.payload?.message?.payload?.bitrixtemplate ? res.payload.message.payload.bitrixtemplate : null,
      bitrixtemplateapproval: res?.payload?.message?.payload?.bitrixtemplateapproval ? res.payload.message.payload.bitrixtemplateapproval : null,
      bitrixtemplaterejected: res?.payload?.message?.payload?.bitrixtemplaterejected ? res.payload.message.payload.bitrixtemplaterejected : null,
      bitrixtemplateapproved: res?.payload?.message?.payload?.bitrixtemplateapproved ? res.payload.message.payload.bitrixtemplateapproved : null
    }));

  }

  const onEditorStateChange = (ed, action) => {
    let value = null
    if (action === 'created') {
      value = draftToHtml(convertToRaw(ed.getCurrentContent()))
      setValueObje(prevState => ({
        ...prevState,
        emailContent: JSON.stringify(value)
      }));
      setDraft(ed)
    }
    if (action === 'approval') {
      value = draftToHtml(convertToRaw(ed.getCurrentContent()))
      setValueObje(prevState => ({
        ...prevState,
        emailContentApproval: JSON.stringify(value)
      }));
      setDraftApproval(ed)
    }
    if (action === 'rejected') {
      value = draftToHtml(convertToRaw(ed.getCurrentContent()))
      setValueObje(prevState => ({
        ...prevState,
        emailContentRejected: JSON.stringify(value)
      }));
      setDraftRejected(ed)
    }
    if (action === 'approved') {
      value = draftToHtml(convertToRaw(ed.getCurrentContent()))
      setValueObje(prevState => ({
        ...prevState,
        emailContentApproved: JSON.stringify(value)
      }));
      setDraftApproved(ed)
    }





    // 
    // setValueObje(prevState => ({
    //   ...prevState,
    //   formTID: props.selectedFormId
    // }));

  }


  const onBitrixTemplateChange = (ed, target) => {

    if (target === 'created') {
      setValueObje(prevState => ({
        ...prevState,
        bitrixtemplate: ed.target.value
      }));
    }
    if (target === 'approval') {
      setValueObje(prevState => ({
        ...prevState,
        bitrixtemplateapproval: ed.target.value
      }));
    }
    if (target === 'rejected') {
      setValueObje(prevState => ({
        ...prevState,
        bitrixtemplaterejected: ed.target.value
      }));
    }
    if (target === 'approved') {
      setValueObje(prevState => ({
        ...prevState,
        bitrixtemplateapproved: ed.target.value
      }));
    }




    // setDraft(ed)
    // 
    // setValueObje(prevState => ({
    //   ...prevState,
    //   formTID: props.selectedFormId
    // }));

  }


  useEffect(() => {

    init(props)
  }, [props]);

  return (
    <>
      <Modal
        open={props.isOpen}
        title="Notofication Template"
        onOk={props.closeEmailTempleteFunction}
        onCancel={props.closeEmailTempleteFunction}
        footer={[
          <AntButton danger key="back" onClick={props.closeEmailTempleteFunction}>
            Return
          </AntButton>,
          <AntButton danger key="submit" type="primary" onClick={saveEmail}>
            Save
          </AntButton>
        ]}
      >


        <>


          {
            props.schemaComponent ?
              <AntSelect
                defaultValue={valueObj.emailKey}
                style={{ width: '100%' }}
                onChange={(e) => changeEmailElement(e)}
              >
                {props.schemaComponent.map((row) => (
                  <Option value={row.keyTxt}>{row.keyTxt}</Option>
                ))}
              </AntSelect>
              : null

          }


          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Email Template created" key="1">

              <AntdCheck checked={notificationSet.submit_email} onChange={(e) => { notificationHandle('submit_email', e) }}>Enable Answer Submited Notification</AntdCheck>

              <Editor
                editorState={dreft}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: suggestions,
                }}
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { onEditorStateChange(e, 'created') }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bitirx Template created" key="2">
              <AntdCheck checked={notificationSet.submit_bitrix} onChange={(e) => { notificationHandle('submit_bitrix', e) }}>Enable Answer Submited Bitrix Notification</AntdCheck>

              <TextArea value={valueObj.bitrixtemplate} onChange={(e) => onBitrixTemplateChange(e, 'created')} rows={4} placeholder="add bitrix template" />
            </Tabs.TabPane>


            <Tabs.TabPane tab="Email Template Approval" key="3">
              <AntdCheck checked={notificationSet.approval_email} onChange={(e) => { notificationHandle('approval_email', e) }}>Enable Approval Email Notification</AntdCheck>

              <Editor
                editorState={dreftApproval}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: suggestions,
                }}
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { onEditorStateChange(e, 'approval') }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bitirx Template Approval" key="4">
              <AntdCheck checked={notificationSet.approval_bitrix} onChange={(e) => { notificationHandle('approval_bitrix', e) }}>Enable Approval Bitrix Notification</AntdCheck>

              <TextArea value={valueObj.bitrixtemplateapproval ? valueObj.bitrixtemplateapproval : null} onChange={(e) => onBitrixTemplateChange(e, 'approval')} rows={4} placeholder="add bitrix template" />
            </Tabs.TabPane>


            <Tabs.TabPane tab="Email Template Rejected" key="5">
              <AntdCheck checked={notificationSet.rejected_email} onChange={(e) => { notificationHandle('rejected_email', e) }}>Enable Rejected Email Notification</AntdCheck>

              <Editor
                editorState={dreftRejected}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: suggestions,
                }}
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { onEditorStateChange(e, 'rejected') }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bitirx Template Rejected" key="6">
              <AntdCheck checked={notificationSet.rejected_bitrix} onChange={(e) => { notificationHandle('rejected_bitrix', e) }}>Enable Rejected Bitrix Notification</AntdCheck>

              <TextArea value={valueObj.bitrixtemplaterejected ? valueObj.bitrixtemplaterejected : null} onChange={(e) => onBitrixTemplateChange(e, 'rejected')} rows={4} placeholder="add bitrix template" />
            </Tabs.TabPane>


            <Tabs.TabPane tab="Email Template Approved" key="7">
              <AntdCheck checked={notificationSet.approved_email} onChange={(e) => { notificationHandle('approved_email', e) }}>Enable Approved Email Notification</AntdCheck>

              <Editor
                editorState={dreftApproved}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions: suggestions,
                }}
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { onEditorStateChange(e, 'approved') }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bitirx Template Approved" key="8">
              <AntdCheck checked={notificationSet.approved_bitrix} onChange={(e) => { notificationHandle('approved_bitrix', e) }}>Enable Approved Bitrix Notification</AntdCheck>

              <TextArea value={valueObj.bitrixtemplateapproved ? valueObj.bitrixtemplateapproved : null} onChange={(e) => onBitrixTemplateChange(e, 'approved')} rows={4} placeholder="add bitrix template" />
            </Tabs.TabPane>

          </Tabs>





        </>



      </Modal>

    </>
  );
};


export default EmailTemplate
