
import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import {
    QuestionCircleOutlined,
    EditOutlined,
    EyeOutlined,
    DeleteOutlined,
    UnorderedListOutlined,
    CopyOutlined,
    CheckCircleTwoTone
} from "@ant-design/icons";
import { manageMoreOptionDrawer } from "../../redux/slices/answer/answerSetting";
import { workFlowDrawerClose } from "../../redux/slices/workflow";

import {Avatar, Radio, Steps, Tabs} from 'antd';
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox , Form, Drawer, Typography,  DatePicker
} from "antd";
import { Divider, List } from 'antd';
import {FormViewInterface} from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import DialogContent from "@mui/material/DialogContent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
const { Step } = Steps;
export function WorkFlowViewDrawer(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();
    const showDrawer = useSelector((state) => {
        let data = false
        const pageState=state.workFlow?state.workFlow:null
        if(pageState?.viewWorkFlowDwawer){
            data=pageState.viewWorkFlowDwawer
        }
         
        return data
    })

    const workFlow = useSelector((state) => {
        let data = null
        const pageState=state.workFlow?state.workFlow:null
        if(pageState?.workFlowDetails){
            data=pageState.workFlowDetails
        }
         
        return data
    })







    const closeDrawer = () =>{
        dispatch(workFlowDrawerClose(true))
    }


    useEffect(() => {

        // init();

    }, [props]);



    return (
        <div className='more-option-div class-workflow-Drawer'>
            <Drawer
                onClose={() => { closeDrawer() }}
                width={'100%'}
                //   placement={placement}
                placement='bottom'
                open={showDrawer}
            >
                <div >

                    {
                        workFlow?<>
                            <Steps direction="vertical" className="work-flow-step" size="small" current={!workFlow.rejected ? (parseInt(workFlow.currentStage) - 1) : (parseInt(workFlow.rejectedStage) - 1)}>
                                {workFlow.dataTab.approvalFlow.map((stage) => (
                                    <Step description={<>
                                        {stage.reason ? <>NOTE : {stage.reason}</> : null}
                                        <div>Responsible Users</div>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={stage.userData}
                                            renderItem={(item) => <List.Item>{item?.userBit?.EMAIL ? item.userBit.EMAIL : '-'}
                                                {
                                                    stage?.status !== 'pending' ? <>
                                                        {
                                                            item.userBit.UF_USR_1642854306150 == stage.changedBy.UF_USR_1642854306150 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null
                                                        }
                                                    </> : null
                                                }
                                            </List.Item>}
                                        />
                                    </>}
                                          title={stage?.status === 'pending' ? 'PENDING' :
                                              stage?.changedBy?.NAME ? stage.changedBy.NAME : 'UNKNOWN'

                                          } icon={<Avatar className={` ${stage.status} ${stage.isCurrentUser && !workFlow.rejected ? 'current-user' : null}`} src={stage?.userData?.userBit?.PERSONAL_PHOTO ? stage.userData.userBit.PERSONAL_PHOTO : null} />} />
                                ))}


                            </Steps>
                        </>:null
                    }


                </div>


            </Drawer>
        </div>


    );
}