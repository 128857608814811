import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import '../style.css'
import moment from 'moment'

export function ElementValue(props) {

    const dispatch = useDispatch();

    const [value, setValue] = useState(null)



    const elementCreate = () => {

        const value = props.value
        if (props.type === 'date') {
            const date = moment(value);
            setValue(date.format('MM-DD-YYYY'))
            //   alert(date)
        } else {
            setValue(value)
        }

    }



    useEffect(() => {

        elementCreate();

    }, [props]);


    const editUserSubmit = () => {

    }

    return (
        <>
            {
                value
            }
        </>
    );
}