

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button, Modal, Checkbox } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';

import {
  getAllShops
} from "../../../../../redux/slices/shopSeting";
import { getMoreShop } from '../../../../../redux/slices/attandance/markAttandanceSetting';
import Title from 'antd/es/typography/Title';

const { Option } = Select;



const ShopControl = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [val, setVal] = useState(null)

  const [reqFields, setReqField] = useState([])

  const [warning, setWarning] = useState(null)

  const [data, setData] = useState({})


  const [isLoading, loading] = useState(true)
  const [isHideInEdit, setHideInEdit] = useState(false)

  const [selectedStore, setSelectedStore] = useState(null)
  const [openAddMoreShopModal, setOpenAddMoreShopModal] = useState(false)
  const [moreShopOption, setMoreShopOption] = useState([])
  const [selectedMoreStore, setSelectedMoreStore] = useState([])
  const [moreOptionLoader, setMoreOptionLoader] = useState(false)
  const optionsPerRow = 4

  const init = (data) => {

    console.log('data', data)
    if (data?.schema?.dataobj?.warning) {
      setWarning(data.schema.dataobj.warning)
    }

    if (data.data) {
      setSelectedStore(data.data)
    }

    if (data?.uischema?.rule?.effect) {
      if (data.uischema.rule.effect === 'SHOW') {
        setHideInEdit(true)
      } else {
        setHideInEdit(false)
      }
    } else {
      setHideInEdit(false)
    }

  }

  const [latitude, setlatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)


  const [loadingMsg, setLoadingMsg] = useState('')
  const [locationFail, setLoacationFail] = useState(false)


  const stores = useSelector(state => state.markAttandance?.shopData || []);
  const attendance = useSelector(state => state.markAttandance?.attandance || []);
  const userData = useSelector(state => state.markAttandance?.userData || []);

  const updateData = async (e) => {
    setMoreOptionLoader(true)
    const branch = stores.find(b => b.StoreID === e);
    console.log("branch", branch);

    const data = {
      branchCode: branch?.StoreID
    }
    const res = await dispatch(getMoreShop(data))
    setMoreShopOption(res?.payload?.data)
    setMoreOptionLoader(false)
    if (res?.payload?.data.length) {
      handleAddMoreShop()
    }

    if (!branch) return;

    let updatedBranch = {
      ...branch,
      isUserAvailable: userData || false
    };

    props.handleChange(props.path, updatedBranch);
  };


  const handleAddMoreShop = () => {
    setOpenAddMoreShopModal(true)
  }

  const handleClose = () => {
    setOpenAddMoreShopModal(false)
    setSelectedMoreStore([])
    const branch = stores.find(b => b.StoreID === moreShopOption[0]?.underwear_mapping_store);
    let updatedBranch = {
      ...branch,
      isUserAvailable: userData || false,
      otherShop: selectedMoreStore
    };

    props.handleChange(props.path, updatedBranch);
  }

  const handleAddMoreOnchange = (value) => {
    const updatedSelectedValues = [...selectedMoreStore];
    if (updatedSelectedValues.includes(value)) {
      updatedSelectedValues.splice(updatedSelectedValues.indexOf(value), 1);
    } else {
      updatedSelectedValues.push(value);
    }
    setSelectedMoreStore(updatedSelectedValues);
  }

  const submitOptions = () => {
    const branch = stores.find(b => b.StoreID === moreShopOption[0]?.underwear_mapping_store);

    const newObj = []
    selectedMoreStore?.map((item) => {
      const fullObj = moreShopOption.find(b => b.Branch_Code === item);
      newObj.push(fullObj)
    })

    if (!branch) return;

    let updatedBranch = {
      ...branch,
      isUserAvailable: userData || false,
      otherShop: newObj
    };
    props.handleChange(props.path, updatedBranch);
    setOpenAddMoreShopModal(false)
  }

  const renderOptionWithCheckbox = (row) => (
    <Option key={row.Branch_Code} value={row.Branch_Code}>
      <Checkbox
        style={{ marginRight: '5px' }}
        checked={selectedMoreStore.includes(row.Branch_Code)}
        onChange={() => handleAddMoreOnchange(row.Branch_Code)}
      />
      {row.Branch_Code}
    </Option>
  );
  //

  useEffect(() => {

    init(props)

  }, [props]);
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} className="antd-input-div red-font">
          {t("Enter clock in information")}
        </Col>
        <Col xs={24} sm={24} md={12} className="antd-input-div">
          <div style={{ width: "100%", marginTop: "7px" }}>
            <span className="lable-drop">{t("Branch Code")}</span>
            {props?.required && <span className="required-field">*</span>}
          </div>
          <Select
            allowClear
            style={{ width: "100%", marginBottom: "7px" }}
            placeholder={t("Branch Code")}
            disabled={props.schema.disable}
            value={selectedStore?.StoreID || null}
            onChange={e => updateData(e)}
            loading={moreOptionLoader}
          >
            {stores.map(row => (
              <Option key={row.StoreID}>{row.StoreID}</Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} className="antd-input-div-right">
          <div style={{ width: "100%", marginTop: "7px" }}>
            <span className="lable-drop">{t("Branch :")} </span>
            {props?.required && <span className="required-field"> *</span>}
          </div>
          <Input
            value={selectedStore?.StoreName || null}
            placeholder="store name"
            disabled
          />
        </Col>
      </Row>
      {warning && warning !== "" && (
        <Alert message={warning} type="warning" closable />
      )}

      {openAddMoreShopModal &&
        <Modal
          className='add-more-shop-modal'
          open={openAddMoreShopModal}
          onOk={submitOptions}
          onCancel={handleClose}
          centered
          footer={[
            <Button key="back" onClick={() => submitOptions()}>
              Save
            </Button>,
            <Button key="back" onClick={handleClose}>
              Cancel
            </Button>

          ]}
        >
          <Title level={5}>are you also working with below stores? please confirm.</Title>
          <Row gutter={[16, 16]}>
            {moreShopOption.length && moreShopOption.map((option, index) => (
              <Col key={`option-${index}`} span={24 / optionsPerRow} xs={24} sm={12} md={8} lg={6} xl={6}>
                <Checkbox checked={selectedMoreStore.includes(option.Branch_Code)}
                  onChange={() => handleAddMoreOnchange(option.Branch_Code)}>
                  {option?.Branch_Code}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Modal >
      }
    </>
  );

}

// const ShopControl = (props) => {
//     const dispatch = useDispatch();
//     const { t, i18n } = useTranslation();
//     const [val, setVal] = useState(null)

//     const [reqFields, setReqField] = useState([])

//     const [warning, setWarning] = useState(null)

//     const [data, setData] = useState({})


//     const [isLoading, loading] = useState(true)
//     const [isHideInEdit, setHideInEdit] = useState(false)

//     const [selectedStore, setSelectedStore] = useState(null)

//     const init = (data) => {

//         console.log('data',data)
//         if (data?.schema?.dataobj?.warning) {
//             setWarning(data.schema.dataobj.warning)
//         }

//         if(data.data){
//             setSelectedStore(data.data)
//         }

//         if (data?.uischema?.rule?.effect) {
//             if (data.uischema.rule.effect === 'SHOW') {
//                 setHideInEdit(true)
//             } else {
//                 setHideInEdit(false)
//             }
//         } else {
//             setHideInEdit(false)
//         }

//     }

//     const [latitude, setlatitude] = useState(null)
//     const [longitude, setLongitude] = useState(null)


//     const [loadingMsg, setLoadingMsg] = useState('')
//     const [locationFail, setLoacationFail] = useState(false)



//     const stores = useSelector((state) => {

//         let error = []
//         if (state?.markAttandance?.shopData) {

//             let ttt = state.markAttandance.shopData
//             error=ttt
//         }
//         return error

//     })

//     const attendance = useSelector((state) => {

//         let error = []
//         if (state?.markAttandance?.attandance) {

//             let ttt = state.markAttandance.attandance
//             error=ttt
//         }
//         return error

//     })


//     const userData = useSelector((state) => {

//         let error = null
//         if (state?.markAttandance?.userData) {

//             let ttt = state.markAttandance.userData
//             error=ttt
//         }
//         return error

//     })

//     const updateData = async (e) => {

//         let branchCode = e
//         let branch = stores.find((b) => b.StoreID == e)
//                 console.log('branch',branch)

//         branch = {
//             ...branch,
//             isUserAvailable: userData ? userData : false
//         }



//         if (branch) {
//             props.handleChange(props.path, branch)
//         }

//         //  
//     };



//     //

//     useEffect(() => {

//         init(props)

//         //
//     }, [props]);
//     return (
//         <>
//             {
//                 <>
//                  <Row>
//                             <Col xs={24} sm={24} md={24} className='antd-input-div red-font'>
//                             {t('Enter clock in information')}
//                             </Col>
//                             <Col xs={24} sm={24} md={12} className='antd-input-div'>
//                                 <div style={{
//                                     width: '100%',
//                                     marginTop: '7px',
//                                 }}>

//                                     <span className='lable-drop'>{t('Branch Code')}</span>
//                                  {
//                                         props?.required ? <span className='required-field'>*</span> : null
//                                     }</div>
//                                 <Select
//                                     allowClear
//                                     style={{
//                                         width: '100%',
//                                         marginBottom: '7px'
//                                     }}
//                                     placeholder={t('Branch Code')}
//                                     disabled={props.schema.disable ? true : false}
//                                     value={selectedStore?.StoreID ? selectedStore.StoreID : null}
//                                     onChange={(e) => updateData(e)}
//                                 >

//                                         {stores.map((row) => (
//                                         <Option key={row.StoreID}>{row.StoreID}</Option>
//                                     ))}







//                                 </Select>
//                             </Col>
//                             <Col xs={24} sm={24} md={12} className='antd-input-div-right'>
//                                 <div style={{
//                                     width: '100%',
//                                     marginTop: '7px',
//                                 }}>
//                                      <span className='lable-drop'>{t('Branch :')} </span>


//                                 {
//                                         props?.required ? <span className='required-field'> *</span> : null
//                                     }</div>
//                                 <Input value={selectedStore?.StoreName ? selectedStore.StoreName : null} placeholder="store name" disabled />
//                             </Col>
//                         </Row>

//                         {
//                             warning ? <>
//                                 {
//                                     warning != '' ? <>
//                                         <Alert
//                                             message={warning}
//                                             type="warning"
//                                             closable
//                                         />
//                                     </> : null
//                                 }

//                             </> : null
//                         }

//                 </>


//                     }

//         </>



//     );
// }



export default withJsonFormsControlProps(ShopControl);
