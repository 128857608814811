import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import { useTranslation } from "react-i18next";

import { Grid, Link, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { useHistory } from "react-router-dom";
import { FormViewInterface } from "../viewInteface/FormViewInterface";
import { CloseCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  CheckCircleTwoTone,
  HeartTwoTone,
  SmileTwoTone,
} from "@ant-design/icons";
import { Filter } from "../../../../components/elements/Filter";

import { ViewAnswerDrawer } from "../../../../components/layout/ViewAnswerDrawer";
import { MoreOption } from "../../../../components/layout/MoreOption";

import { GridCard } from "../../../../components/layout/GridCard";
import {AttandaceView} from "../../../attandence/components/AttandaceView";
import { ControlOutlined } from "@ant-design/icons";
//import myData from './2.json';
import {
  getFormAnswerList,
  deleteSelectedAnswer,
  submitFormAnswer,
  editFormAnswer,
  submitTaskProcessReducer,
  submitBulkData,
  getFormAnswerListExportReducer,
  resetTaskProcessReducer,
  getActiveTaskList,
  generateFormAnswerListExportReducer,
  getExportListReducer,
  getFormAnswerListExportProfileReducer,
  getFormAnswerById,
} from "../../../../redux/slices/formSettings";
import {
  addWarningListReducerAdd,
  addErrorListReducerAdd,
  changeDataReducer,
} from "../../../../redux/slices/errorSetting";
import {
  getProcessingFormAnswersReducer,
  getProcessingFormReducer,
  manageFilterDrawer,
  manageViewAnswerDrawer,
  setAnswerViewReducer,
} from "../../../../redux/slices/dynamicPage";
import { setFormReducer } from "../../../../redux/slices/answer/answerSetting";

import IconButton from "@mui/material/IconButton";

import { FormInterfaceDynamic } from "../intefaces/FormInterfaceDynamic";

import * as XLSX from "xlsx";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { FormInterfaceDynamicEdit } from "../intefaces/FormInterfaceDynamicEdit";

import {
  Table as AntTable,
  Input as AntInput,
  Select,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Button,
  Modal,
  Steps,
  Avatar,
  Spin,
  Tooltip,
  Popover,
  Alert,
  List,
  Radio,
  Drawer,
  Collapse
} from "antd";

import {
  Input,
  SettingTwoTone,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  CopyOutlined,
  SearchOutlined,
  PlusOutlined,
  PlayCircleOutlined,
  ExportOutlined,
  LoadingOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import { FullScreenLoader } from "../../../../components/elements/FullScreenLoader";

const { RangePicker } = DatePicker;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const { Step } = Steps;
const { Option } = Select;
const { Search } = AntInput;

export const DynamicAnswer = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form,setForm] = useState();
  const [filterForm, setFilterForm] = useState();
  const [generateForm,setGenerateForm] = useState({});
  const [exportFilterArray, setExportFilterArray] = useState({})
  const [selectedPageExport, setSelectedPageExport] = useState(1);
  const [totalExportFile, setTotalExportFile] = useState(0);
  const [clearState, setClearState] = useState(false)
  const [pendingTasks, setPendingTasks] = useState([]);


  const dispatch = useDispatch();
  //   const errorList = useSelector((state) => {

  //
  //     let error = []
  //     if (state?.errorReducer?.errorList) {
  //         error = state.errorReducer.errorList

  //     }
  //     return error

  // })

  // const warningList = useSelector((state) => {

  //
  //     let warning = []
  //     if (state?.errorReducer?.warningList) {
  //         warning = state.errorReducer.warningList

  //     }
  //     return warning

  // })

  const [enableReset, setEnableReset] = useState(false);

  const [excelName, setExcelName] = useState({});

  const [isOpenExportFilter, setOpenFilter] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [warningList, setWarningList] = useState([]);

  const [answerList, setAnswerList] = useState([]);

  const [isOpenAnswerView, openAnswerView] = useState(false);

  const [isOpenAnswerEdit, openAnswerEdit] = useState(false);

  const [isOpenAnswerDuplicate, openAnswerDuplicate] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [schemaComponent, setScheemaComponent] = React.useState([]);

  const [selectedData, setSelectedData] = React.useState({});

  // pagination
  const [dataCount, setDataCount] = useState(0);

  const [selectedPage, setSelectedPage] = useState(1);

  const [dataPerPage, setDataPerPage] = useState(10);

  //pagination end

  //table
  const [columns, setColomn] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [apiData, setApiData] = useState();
  const [activeTask, setActiveTask] = useState();

  // sorting

  //
  const [isloading, setLoading] = useState(true);

  //

  const [sortableKey, setSortablekey] = useState(false);

  const [sortableAsyn, setSortableAsyn] = useState(true);

  const [isDeleteViewShow, setDeletedView] = useState(false);

  const [deletedItem, setSelectedItem] = useState(null);
  //
  const [serachKey, setSearchKey] = useState([]);

  const [selectedMainProfile, setMainExportProfile] = useState("all");

  //submit
  const [isSubmitFormOpen, openSubmitForm] = useState(false);

  //
  const [container, setContainer] = useState(null);

  const [hasActiveTask, setHasActiveTask] = useState(false)

  const [Task, setTask] = useState(() => {
    const stateFromStorage = localStorage.getItem("activeTask");
    if (stateFromStorage) {
      let tmp = JSON.parse(stateFromStorage);
      return tmp.activeTaskId || "";
    } else {
      return "";
    }
  });
  useEffect(() => { }, [Task]);

  useEffect(() => {
    // Fetch pending tasks initially

    // Polling interval in milliseconds
    let intervalId = null;
    if(isOpenExportFilter){
      const pollingInterval = 5000; // 5 seconds

      intervalId = setInterval(() => {
  
        if (pendingTasks.length === 0) {
          clearInterval(intervalId);
        }
        else{
          setExportFilesList();
        }
      }, pollingInterval);
  
     
    }
    else if(intervalId){
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
    
  }, [pendingTasks,isOpenExportFilter]);

  

  //

  //

  //task process

  const [isTaskProcessOpen, setTaskProcessOpen] = useState(false);

  const [formSchemaTask, setFormScheemaTask] = useState(null);
  const [formGroupTask, setFormGroupTask] = useState(null);
  const [formTask, setFormTask] = useState(null);

  const [formDataSetTask, setFormDataSetTask] = useState(null);

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const [ruleArray, setRuleArray] = useState([]);

  const [jsonProcessAnswer, setjsonProcessAnswer] = useState({});
  const [allAnswerRow, setAllAnswerRow] = useState([]);

  const [enableAttendance,setEnableAttendance] = useState(false)

const [attendanceView,setViewAttendance] = useState(false)
const [attendanceViewData,setViewAttendanceData] = useState(false)


  const sortingFunction = (data) => { };

  function getProcessFormByProject(array, project) {
    // Find the object with the "Project" key
    const projectObj = array.find(obj => obj.key === "Project");
  
    // Find the enum object with the given project value
    const projectEnum = projectObj.enum.find(item => item.const === project);
  
    if (projectEnum) {
      // Get the data object associated with the project
      const projectData = projectObj.fullObject.find(item => item.const === project);
      return projectData.data.processing_form;
    }
  
    return null; // Project not found
  }
  

  const taskProcessModelOpen = async(data) => {
    // console.log("data", data);
    setLoading(true);
    const stateToPersist = {
      activeTaskId: data.data.database.main.task_id,
      selectedAnswerId: data.id,
    };
    const newData = {
      ...data,
      ...await getProccessForm(data.id)
      // formProccess: {
      //   ...data.formProccess,
      //   jsonProcessAnswer: {
      //     ...data.formProccess.jsonProcessAnswer,
      //     data: {
      //       ...data.formProccess.jsonProcessAnswer?.data,
      //       database: {
      //         ...data.formProccess.jsonProcessAnswer?.data.database,
      //         main: {
      //           ...data.formProccess.jsonProcessAnswer?.data?.database?.main,
      //           ...data.data.database.main,
      //           active: true,
      //         },
      //       },
      //     },
      //   },
      // },
    };

    localStorage.setItem("activeTask", JSON.stringify(stateToPersist));
    setLoading(false);
    history.push({
      pathname: "/task-process/" + data.id,
      state: {
        detail: newData,
        form: props.form,
        last: props.formId,
        formInformation: formInformation,
      },
    });
    // setFormScheemaTask(data.formProccess.proccessFormScheema)

    // setFormGroupTask(data.formProccess.proccessFormGroups)

    // setFormTask(data.formProccess.proccessForm)

    // setSelectedAnswerId(data.id)

    //
    // setjsonProcessAnswer(data.formProccess.jsonProcessAnswer)

    // setTaskProcessOpen(true)
  };

  const taskProcessModelClose = () => {
    setTaskProcessOpen(false);
  };

  const changeDataTriggerTask = (data, err) => {
    // console.log("changeDataTriggerTask data", data);
    if (err.length != 0) {
      //setaddbtnDisable(true)
    } else {
      //setaddbtnDisable(false)
    }
    setFormDataSetTask(data);
  };

  const saveFormTaskProcess = async () => {
    try {
      setLoading(true);
      const obj = {
        data: formDataSetTask,
        selectedAnswerId: selectedAnswerId,
      };

      const action = await dispatch(submitTaskProcessReducer(obj));
      if (action.payload?.success) {
        setTaskProcessOpen(false);
        setFormAnswer(props.form.id);
        // notification.success({
        //   message: "Success",
        //   description: "Task process saved successfully",
        // })
      } else {
        notification.error({
          message: "Error",
          description: action.payload?.message,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTaskProcessOpen(false);
      // getAnswerList()
      setFormAnswer(props.form.id);
      //
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  //task process enf
  const menuClicked = (e) => {
    const action = e.key;
    //const id = e.item.props.items.id;
  };

  const handleMenuClick = (e) => {
    const action = e.key;
    const id = e.item.props.items.id;

    switch (action) {
      case "1":
        editAnswer(id);
        break;
      case "2":  
        viewAnswer(id);
        break;
      case "3":
        setDeletedView(true);
        setSelectedItem(id);
        break;
      case "4":
        // if (id?.formProccess?.jsonProcessAnswer) {
        viewProcessAnswer(id);
        // }

        break;
      case "5":
        duplicateAnswer(id);
        //duplicateForm(id.data)

        break;
      case "attendance":
        setViewAttendance(true);
        setViewAttendanceData(id);
        //duplicateForm(id.data)

        break;
      default:
        return;
    }
  };

  const searchAnswerList = async (id) => {
    const data = {
      formData: id,
      sorting: sortableKey ? sortableKey : false,
      order: sortableKey ? sortableKey : false,
      search: serachKey ? serachKey : null,
    };

    const res = await dispatch(getFormAnswerList(data));

    if (res.payload) {
      if (res.payload.data) {
        if (res.payload.data.data) {
          setAnswerList(res.payload.data.data);
        }
      }
    }
  };

  const [paginatedPage, setPaginatedPage] = useState(0);

  const changePagination = (pageNo, pageSize) => {
    setSelectedPage(pageNo);

    setFormAnswer(props.form.id, false, columns, pageNo, pageSize);

    //
  };


  const handleCloseAttendance = (e) => {

    // console.log("handleCloseAttendance",e? e : null)
    setViewAttendance(false);
    setViewAttendanceData(null);


  }

  const clearFilter = () => {
    filterValue([]);
    form?.resetFields()
  }


  const filterValue = (array) => {
    let tempArray = [];
    if (array) {
      for (let it of array) {
        let key = it.key ? it.key : null;
        let val = it.value ? it.value : null;
        if (key && val) {
          if (Array.isArray(val)) {
            let startDate = val[0] ? val[0] : null;
            let endDate = val[1] ? val[1] : null;

            if (endDate && startDate){
              startDate = moment(startDate.format("YYYY-MM-DD HH:mm:ss")).utc().toISOString();
              endDate = moment(endDate.format("YYYY-MM-DD HH:mm:ss")).utc().toISOString();
            }

            tempArray.push({
              key: key,
              value: [startDate, endDate],
              isDate: true,
            });
          } else {
            tempArray.push({
              key: key,
              value: val,
            });
          }
        }
      }
    }
    setSearchKey(tempArray);

    setFormAnswer(
      props.form.id,
      false,
      columns,
      1,
      dataPerPage,
      false,
      tempArray
    );
  };

  const shouldDisplayIcon = (el, loggedInUser, activeTask) => {
    // console.log("el", el);
    // console.log("loggedInUser", loggedInUser);

    const isFormProccessComplete =
      el?.isComplete;
    // console.log("isFormProccessComplete", isFormProccessComplete);
    const isResponsibleUser =
      el.data.database.main.Responsible_Users === loggedInUser;
    // console.log("isResponsibleUser", isResponsibleUser);

    if (activeTask) {
      console.log("activeTask", activeTask);
      // console.log( "el.formProccess.jsonProcessAnswer.data.database.main.active",
      //  el.formProccess.jsonProcessAnswer.data.database.main.active);
      return (
        el.formProccess &&
        el.responsibleUser &&
        !isFormProccessComplete &&
        isResponsibleUser &&
        el.formProccess.jsonProcessAnswer?.data.database.main.active
      );
    } else {
      return (
        el.formProccess &&
        el.responsibleUser &&
        !isFormProccessComplete &&
        isResponsibleUser
      );
    }
  };

  // const checkActiveTask = (currentTaskId) => {
  //   if (activeTask) {
  //     const task = activeTask.find(task => task.task_id == currentTaskId)
  //     if (task) {
  //       return true
  //     }
  //     return false
  //   }
  // }

  const checkActiveTask = (el) => {
    return el.active
  }

  const checkResponsibleUser = (responsibleUser) => {
    if (typeof responsibleUser === 'string') {
      return responsibleUser === loggedInUser
    }
    else if (Array.isArray(responsibleUser)) {
      return responsibleUser.includes(loggedInUser)
    }
    return false
  }

  const setFormAnswer = async (id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    forExport = false,
    filterArra = serachKey ? serachKey : null) => {
    const viewType = props?.containerData?.viewType
      ? props.containerData.viewType
      : "all";

      const data = {
      formData: id,
      sorting: tempSort ? tempSort : sortableKey ? sortableKey : false,
      order: sortableKey ? !sortableAsyn : false,
      search: filterArra ? filterArra : null,
      viewType: viewType,
      selectedPageParam: selectedPageParam,
      rowPerRequest: rowPerRequest,
      headersOnly: true
    };
    setSelectedPage(selectedPageParam)
    setLoading(true)
    const formAnswer = await dispatch(getFormAnswerList(data))
    setApiData(formAnswer)
    setHasActiveTask(formAnswer.payload.data.data.some((item) => item.active === true))
  }

  useEffect(() => {
    if (apiData) {
      getAnswerList()
    }
  }, [apiData, activeTask, hasActiveTask])

  const getAnswerList = async (
    id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    forExport = false,
    filterArra = serachKey ? serachKey : null
  ) => {
    setSortablekey(tempSort);
    if (tempSort) {
      setSortableAsyn(!sortableAsyn);
    }
    const res = apiData;
    // lokesh request from ibtihaj
    // console.log("res", res);
    if (res.error) {
      setLoading(false);
      notification.error({
        message: "Data load error - " + res.error.name,
        description: res.error.message,
      });
      return;
    }
    let processingTime = res.payload.data.stats.appProcessing;
    // let activeTask = res.payload.data.activeTask;


    // if(!activeTask) {
    //   console.log("activeTask", activeTask);
    // }

    const jsonRes = res.payload.data;
    setDataCount(jsonRes.count);

    let tempArray = [];
    let tempArrayMobile = [];

    //setAllAnswerRow(jsonRes.data)

    for (let i = 0; i < jsonRes.data.length; i++) {
      let el = jsonRes.data[i];
      let row = {};
      row.key = i;

      let arrayOfMobileCol = [];
      for (let i = 0; i < colArray.length; i++) {
        let objKey = {};
        let col = colArray[i];

        if (col.key != "action" && col.key != "createdAt") {
          objKey = {
            ...objKey,
            key: col.key,
            col: col,
          };
        }

        let tempMobileVal = null;
        if (col.key === "createdAt" ||
          col.key === "FinishedAt" ||
          col.key === "finishedAt" ||
          col.key === "updatedAt") {
          //row[col.key]='s'

          row[col.key] = (
            <>
              {el[col.key]
                ? moment(el[col.key])
                  .utcOffset(420)
                  .format("YYYY-MM-DD")
                : "-"}
            </>
          );
        } else if (col.type === "date") {
          //row[col.key]='s'

          row[col.key] = <>{row[col.key]
            ? moment(row[col.key])
              .utcOffset(420)
              .format("MMMM Do, YYYY hh:mm A")
            : "-"}</>;
        } else if (col.type === "timeLine") {
          //row[col.key]='s'

          if (el.data.database.main[col.key]) {
            const colAnswer = el.data.database.main[col.key];
            if (colAnswer.start.dateTime && !colAnswer.finishd.dateTime) {
              row[col.key] = <>{t("IN PROGRESS")}</>;
            }
            if (colAnswer.paused.find((e) => e.pauseTime && !e.resumeTime)) {
              row[col.key] = <>{t("HOLD")}</>;
            }
            if (colAnswer.finishd.dateTime) {
              row[col.key] = <>{t("DONE")}</>;
            }
          } else {
            row[col.key] = <>{t("PENDING")}</>;
          }
        } else if (col.type === "date") {
          if (el.data.database.main[col.key]) {
            const temDate = el.data.database.main[col.key];
            row[col.key] = (
              <>
                {el.createdAt
                  ? moment(temDate).utcOffset(420).format("MMMM Do, YYYY hh:mm A")
                  : "-"}
              </>
            );
          } else {
            row[col.key] = <>-</>;
          }
        } else if (col.type === "Shops") {
          //console.log(el)
          // if (el.data.database.main[col.key]) {
          //   const temDate = el.data.database.main[col.key]
          //   row[col.key] = (
          //     <>
          //      '-'
          //     </>
          //   )
          // } else {
          //   row[col.key] = (
          //     <>
          //       -
          //     </>
          //   )
          // }
        } else {
          if (el.data.database.main[col.key]) {
            if (Array.isArray(el.data.database.main[col.key])) {
              row[col.key] = (
                <>
                  {el.data.database.main[col.key].map((enCo) => (
                    <>
                      <div>
                        {col?.enum?.find((e) => e.const == enCo)?.title
                          ? col.enum.find((e) => e.const == enCo).title
                          : "not found"}

                        {col.fullObject.length !== 0 ? (
                          <>
                            <Popover
                              content={
                                <>
                                  {(() => {
                                    const objTemp = col.fullObject.find(
                                      (e) => e.const == enCo
                                    );
                                    if (objTemp) {
                                      if (objTemp.data) {
                                        return (
                                          <>
                                            {Object.keys(objTemp.data).map(
                                              (objKey) => (
                                                <>
                                                  <div>
                                                    {objKey}-
                                                    {objTemp.data[objKey]
                                                      ? objTemp.data[
                                                        objKey
                                                      ].toString()
                                                      : null}
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </>
                                        );
                                      } else {
                                        <>empty</>;
                                      }
                                    } else {
                                      return <>empty</>;
                                    }
                                  })()}
                                </>
                              }
                              title=""
                              trigger="focus"
                            >
                              <Button
                                type="text"
                                shape="circle"
                                icon={<QuestionCircleOutlined />}
                              />
                            </Popover>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ))}
                </>
              );
            } else {
              row[col.key] = (
                <>
                  {col.fullObject ? (
                    <>
                      {el.data.database.main[col.key]}

                      {col.fullObject.length !== 0 ? (
                        <>
                          <Popover
                            content={
                              <>
                                {(() => {
                                  const objTemp = col.fullObject.find(
                                    (e) =>
                                      e.const == el.data.database.main[col.key]
                                  );
                                  if (objTemp) {
                                    if (objTemp.data) {
                                      return (
                                        <>
                                          {Object.keys(objTemp.data).map(
                                            (objKey) => (
                                              <>
                                                <div>
                                                  {objKey}-
                                                  {objTemp.data[objKey]
                                                    ? objTemp.data[
                                                      objKey
                                                    ].toString()
                                                    : null}
                                                </div>
                                              </>
                                            )
                                          )}
                                        </>
                                      );
                                    } else {
                                      <>empty</>;
                                    }
                                  } else {
                                    return <>empty</>;
                                  }
                                })()}
                              </>
                            }
                            title=""
                            trigger="focus"
                          >
                            <Button
                              type="text"
                              shape="circle"
                              icon={<QuestionCircleOutlined />}
                            />
                          </Popover>
                        </>
                      ) : null}
                    </>
                  ) : (
                    el.data.database.main[col.key]
                  )}
                  { }
                </>
              );
            }
          }
        }

        if (col.key != "action" && col.key != "createdAt") {
          objKey = {
            ...objKey,
            value: el.data.database.main[col.key],
          };
          arrayOfMobileCol.push(objKey);
        }
      }


      row.action = (
        <>
          <>
            {/* {shouldDisplayIcon(el, loggedInUser, activeTask) && activeTask  ? ( */}
            {/* {el.id === activeTask ? console.log("active") : console.log(activeTask)} */}
            {!el.finishedAt && checkResponsibleUser(el?.data?.database?.main?.Responsible_Users) ? (
              <>
                {hasActiveTask ? checkActiveTask(el) ? (
                  <>
                    <Button
                      type="text"
                      style={{ verticalAlign: "0px" }}
                      onClick={() => resetPlayIcons(el)}
                      icon={<RedoOutlined />}
                    />

                    <Button
                      type="text"
                      style={{ verticalAlign: "0px" }}
                      onClick={() => {
                        taskProcessModelOpen(el);
                      }}
                      icon={<PlayCircleOutlined />}
                    />
                  </>
                ) :
                  null
                  :
                  <Button
                    type="text"
                    style={{ verticalAlign: "0px" }}
                    onClick={() => {
                      taskProcessModelOpen(el);
                    }}
                    icon={<PlayCircleOutlined />}
                  />}
              </>
            ) : null}
          </>
          <Dropdown.Button
            trigger={["click"]}
            className="action-menu-button"
           
            menu={{
              items: el?.data?.database?.main?.Responsible_Users ===
                loggedInUser &&
                el?.data?.database?.main?.Responsible_Users !==
                el.createdBy.emCode
                ? [
                  {
                    label: t("View"),
                    key: "2",
                    items: { id: el },
                    icon: <EyeOutlined />,
                  },
                  {
                    label: t("View Processing Form"),
                    key: "4",
                    items: { id: el },
                    icon: <EyeOutlined />,
                    disabled:
                      !el?.isComplete,
                  },
                  // {
                  //   label: t("Duplicate Task"),
                  //   key: "5",
                  //   items: { id: el },
                  //   icon: <CopyOutlined />,
                  // },
                ]
                : [
                  enableAttendance? null : container?.permision?.update && {
                    label: t("Edit Item"),
                    key: "1",
                    items: { id: el },
                    icon: <EditOutlined />,
                    disabled: el?.isComplete,
                  },
                  enableAttendance?  {
                    label: t("View Attendance Details"),
                    key: "attendance",
                    items: { id: el },
                    icon: <EyeOutlined />,
                  } :  {
                    label: t("View Item Details"),
                    key: "2",
                    items: { id: el },
                    icon: <EyeOutlined />,
                  },
                  el?.isComplete?
                  {
                    label: t("View Processing Form"),
                    key: "4",
                    items: { id: el },
                    icon: <EyeOutlined />,
                  } : null,
                  enableAttendance? null : container?.permision?.delete && {
                    label: t("Delete Item"),
                    key: "3",
                    items: { id: el },
                    icon: <DeleteOutlined className="delete-icon" />,
                    disabled:
                      el?.isComplete,
                  },
                  enableAttendance? null : {
                    label: t("Duplicate"),
                    key: "5",
                    items: { id: el },
                    icon: <CopyOutlined />,
                  },
                ],
              onClick: handleMenuClick
            }}
            icon={<SettingTwoTone twoToneColor="#28a745" />}
          />

          {el.draft ? <div className="draft-batch">{t("DRAFT")}</div> : null}
        </>
      );
      row.extenalData = el.workFlow ? el.workFlow : false;
      row.element = el ? el : false;
      row.activeTask = hasActiveTask && checkActiveTask(el)
      tempArray.push(row);

      tempArrayMobile.push({
        data: el,
        display: arrayOfMobileCol,
        activeTask: activeTask,
      });
    }

    setAllAnswerRow(tempArrayMobile);

    // setSelectedPage(selectedPageParam);
    setAnswers(tempArray);

    if (res.payload) {
      if (res.payload.data) {
        if (res.payload.data.data) {
          setAnswerList(res.payload.data.data);
        }
      }
    }

    setLoading(false);
    notification.success({
      message: t("Success"),
      description: t("list loaded successfully in " + processingTime),
    });
  };

  // task
  // need to be refactored for better performance and more useable code for other pages as filtering through data can be done from frontend
  const getAnswerListClearFilter = async (
    id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    forExport = false
  ) => {
    setLoading(true);
    const viewType = props?.containerData?.viewType
      ? props.containerData.viewType
      : "all";

    const data = {
      formData: id,
      sorting: tempSort ? tempSort : sortableKey ? sortableKey : false,
      order: sortableKey ? !sortableAsyn : false,
      search: null,
      viewType: viewType,
      selectedPageParam: selectedPageParam,
      rowPerRequest: rowPerRequest,
      // headersOnly: false,
    };

    setSortablekey(tempSort);
    if (tempSort) {
      setSortableAsyn(!sortableAsyn);
    }
    const res = await dispatch(getFormAnswerList(data));

    const jsonRes = res.payload.data;



    setDataCount(jsonRes.count);

    let tempArray = [];
    let tempArrayMobile = [];

    for (let i = 0; i < jsonRes.data.length; i++) {
      let el = jsonRes.data[i];
      let mobileObj = {
        data: el,
      };
      let row = {};
      row.key = i;

      for (let i = 0; i < colArray.length; i++) {
        let objKey = {};
        let col = colArray[i];

        objKey = {
          ...objKey,
          key: col.key,
        };
        if (col.key === "createdAt") {
          //row[col.key]='s'

          row[col.key] = (
            <>
              {el.createdAt
                ? moment(el.createdAt)
                  .utcOffset(420)
                  .format("MMMM Do, YYYY hh:mm A")
                : "-"}
            </>
          );
        } else if (col.type === "timeLine") {
          //row[col.key]='s'

          if (el.data.database.main[col.key]) {
            const colAnswer = el.data.database.main[col.key];
            if (colAnswer.start.dateTime && !colAnswer.finishd.dateTime) {
              row[col.key] = <>{t("IN PROGRESS")}</>;
            }
            if (colAnswer.paused.find((e) => e.pauseTime && !e.resumeTime)) {
              row[col.key] = <>{t("HOLD")}</>;
            }
            if (colAnswer.finishd.dateTime) {
              row[col.key] = <>{t("DONE")}</>;
            }
          } else {
            row[col.key] = <>{t("PENDING")}</>;
          }
        } else {
          if (el.data.database.main[col.key]) {
            if (Array.isArray(el.data.database.main[col.key])) {
              row[col.key] = (
                <>
                  {el.data.database.main[col.key].map((enCo) => (
                    <>
                      <div>
                        {col?.enum?.find((e) => e.const == enCo)?.title
                          ? col.enum.find((e) => e.const == enCo).title
                          : "not found"}

                        {col.fullObject.length !== 0 ? (
                          <>
                            <Popover
                              content={
                                <>
                                  {(() => {
                                    const objTemp = col.fullObject.find(
                                      (e) => e.const == enCo
                                    );
                                    if (objTemp) {
                                      if (objTemp.data) {
                                        return (
                                          <>
                                            {Object.keys(objTemp.data).map(
                                              (objKey) => (
                                                <>
                                                  <div>
                                                    {objKey}-
                                                    {objTemp.data[objKey]
                                                      ? objTemp.data[
                                                        objKey
                                                      ].toString()
                                                      : null}
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </>
                                        );
                                      } else {
                                        <>empty</>;
                                      }
                                    } else {
                                      return <>empty</>;
                                    }
                                  })()}
                                </>
                              }
                              title=""
                              trigger="focus"
                            >
                              <Button
                                type="text"
                                shape="circle"
                                icon={<QuestionCircleOutlined />}
                              />
                            </Popover>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ))}
                </>
              );
            } else {
              row[col.key] = (
                <>
                  {col.fullObject ? (
                    <>
                      {el.data.database.main[col.key]}

                      {col.fullObject.length !== 0 ? (
                        <>
                          <Popover
                            content={
                              <>
                                {(() => {
                                  const objTemp = col.fullObject.find(
                                    (e) =>
                                      e.const == el.data.database.main[col.key]
                                  );
                                  if (objTemp) {
                                    if (objTemp.data) {
                                      return (
                                        <>
                                          {Object.keys(objTemp.data).map(
                                            (objKey) => (
                                              <>
                                                <div>
                                                  {objKey}-
                                                  {objTemp.data[objKey]
                                                    ? objTemp.data[
                                                      objKey
                                                    ].toString()
                                                    : null}
                                                </div>
                                              </>
                                            )
                                          )}
                                        </>
                                      );
                                    } else {
                                      <>empty</>;
                                    }
                                  } else {
                                    return <>empty</>;
                                  }
                                })()}
                              </>
                            }
                            title=""
                            trigger="focus"
                          >
                            <Button
                              type="text"
                              shape="circle"
                              icon={<QuestionCircleOutlined />}
                            />
                          </Popover>
                        </>
                      ) : null}
                    </>
                  ) : (
                    el.data.database.main[col.key]
                  )}
                  { }
                </>
              );
            }
          }
        }

        objKey = {
          ...objKey,
          value: row[col.key],
        };
        mobileObj = {
          data: el,
          show: objKey,
        };
        tempArrayMobile.push(mobileObj);
      }

      // setAllAnswerRow(tempArrayMobile)
      //


      row.action = (
        <>
          <>
            {checkActiveTask(el) ? (
              <Button
                type="text"
                style={{ verticalAlign: "0px" }}
                onClick={() => resetPlayIcons(el)}
                icon={<RedoOutlined />}
              />
            ) : null}

            {shouldDisplayIcon(el, loggedInUser, activeTask) ? (
              <Button
                type="text"
                style={{ verticalAlign: "0px" }}
                onClick={() => {
                  taskProcessModelOpen(el);
                }}
                icon={<PlayCircleOutlined />}
              />
            ) : null}
          </>
          <Dropdown.Button
            trigger={["click"]}
            className="action-menu-button"
            menu={{
              onClick: handleMenuClick,
              items:
                !el.workFlow || el.draft
                  ? [
                    {
                      label: t("Edit"),
                      key: "1",
                      items: { id: el },
                      icon: <EditOutlined />,
                    },
                    {
                      label: t("View"),
                      key: "2",
                      items: { id: el },
                      icon: <EyeOutlined />,
                    },
                    {
                      label: t("View Processing Form"),
                      key: "4",
                      items: { id: el },
                      icon: <EyeOutlined />,
                    },
                    {
                      label: t("Delete Answers"),
                      key: "3",
                      items: { id: el },
                      icon: <DeleteOutlined className="delete-icon" />,
                    },
                    {
                      label: t("Duplicate Task"),
                      key: "5",
                      items: { id: el },
                      icon: <CopyOutlined />,
                    },
                  ]
                  : [
                    el?.workFlow?.rejected &&
                      !jsonRes.data.find((e) => e.reference == el.id)
                      ? {
                        label: t("Edit"),
                        key: "1",
                        items: { id: el },
                        icon: <EditOutlined />,
                      }
                      : null,
                    {
                      label: t("View"),
                      key: "2",
                      items: { id: el },
                      icon: <EyeOutlined />,
                    },
                    {
                      label: t("View Processing Form"),
                      key: "4",
                      items: { id: el },
                      icon: <EyeOutlined />,
                    },
                    {
                      label: t("Duplicate Task"),
                      key: "5",
                      items: { id: el },
                      icon: <CopyOutlined />,
                    },
                    el?.workFlow?.taskStage === "rejected"
                      ? {
                        label: t("Delete Answers"),
                        key: "3",
                        items: { id: el },
                        icon: <DeleteOutlined className="delete-icon" />,
                      }
                      : null,
                  ]

            }}

            icon={<SettingTwoTone twoToneColor="#28a745" />}
          />

          {el.draft ? <div className="draft-batch">{t("DRAFT")}</div> : null}
        </>
      );
      row.extenalData = el.workFlow ? el.workFlow : false;
      row.element = el ? el : false;
      tempArray.push(row);
    }

    // setSelectedPage(selectedPageParam);
    setAnswers(tempArray);

    if (res.payload) {
      if (res.payload.data) {
        if (res.payload.data.data) {
          setAnswerList(res.payload.data.data);
        }
      }
    }

    setLoading(false);
    notification.success({
      message: t("Success"),
      description: t("Updated successfully"),
    });
  };

  const tempformSchema = useSelector((state) => {
    let data = [];
    const pageState = state.page ? state.page : null;
    if (pageState?.contain) {
      data = pageState?.contain?.form?.schema
        ? pageState.contain.form.schema
        : [];
    }

    return data;
  });

  const tempformInformation = useSelector((state) => {
    let data = [];
    const pageState = state.page ? state.page : null;
    if (pageState?.contain) {
      data = pageState?.contain?.form ? pageState.contain.form : [];
    }

    return data;
  });
  const tempformGroup = useSelector((state) => {
    let data = [];
    const pageState = state.page ? state.page : null;
    if (pageState?.contain) {
      data = pageState?.contain?.form?.group
        ? pageState.contain.form.group
        : [];
    }

    return data;
  });

  const loggedInUser = useSelector((state) => {
    let data = null;
    data = state?.user?.loginUser?.userData?.user?.emCode
      ? state?.user?.loginUser?.userData?.user?.emCode
      : null;
    return data;
  });

  const viewAnswer = async (row) => {
    try {

      const data = {
        formData: row.formId,
        search: [
            {
                "key": "id",
                "value": row.id
            }
        ]
      
      };
      const rowDataSearch = await dispatch(getFormAnswerById(data));

      let rowData = rowDataSearch.payload
      
      setSelectedData(rowData);
  
      const objPassing = {
        elementGroup: tempformGroup,
        formInformation: tempformInformation,
        jsonSchema: tempformSchema,
        row:rowData,
      };
  
      dispatch(setFormReducer(objPassing)).catch((err) => {
        notification.error({
          message: t("Error"),
          description: t("Error - setFormReducer"),
        });
      });
  
      // setAnswerViewReducer requires setFormReducer
  
      dispatch(setAnswerViewReducer(objPassing)).catch((err) => {
        notification.error({
          message: t("Error"),
          description: t("Error - setAnswerViewReducer"),
        });
      });

    } catch (error) {
      console.log("error", error);
      notification.error({
        message: t("Error"),
        description: t("Error - viewAnswer"),
      });
    }

  };

  // if (id?.formProccess?.jsonProcessAnswer) {
  //   viewProcessAnswer(id);
  // }

  const [selectedAnswerProcess, setSelectedAnswerProcess] = useState(null);
  const [answerViewTask, openAnswerViewTask] = useState(false);

  const getProccessForm = async (taskID) => {
    let data = {
      "formData": props.form.id,
      "sorting": false,
      "order": false,
      "search": [
        {
          "key": "id",
          "value": taskID
        }
      ],
      "viewType": "all",
      "selectedPageParam": 1,
      "rowPerRequest": 10,
      // headersOnly: true
    }
    const processForm = await dispatch(getProcessingFormReducer(data))
    // const processFormAnswer = await dispatch(getProcessingFormAnswersReducer(data))

    // console.log("formAnswer",await dispatch(getProcessingFormReducer(data)))
    if (processForm.payload) {
      return {
        // ...processFormAnswer.payload.data[0],
        ...processForm.payload.data[0],
      }
    }
    return false
  }
  const viewProcessAnswer = async (row) => {

    const data = await getProccessForm(row.id)

    if (data?.formProccess?.jsonProcessAnswer) {
      const objPassing = {
        elementGroup: data.formProccess.proccessFormGroups
          ? data.formProccess.proccessFormGroups
          : null,
        formInformation: data.formProccess.proccessForm
          ? data.formProccess.proccessForm
          : null,
        jsonSchema: data.formProccess.proccessFormScheema
          ? data.formProccess.proccessFormScheema
          : null,
        row: data.formProccess.jsonProcessAnswer
          ? data.formProccess.jsonProcessAnswer
          : null,
        workFlow: row.workFlow ? row.workFlow : null,
        isFormView: true,
      };

      await dispatch(setFormReducer(objPassing));
      await dispatch(setAnswerViewReducer(objPassing));
    }


    // setSelectedAnswerProcess(row.formProccess ? row.formProccess : null);
    // openAnswerViewTask(true);
  };

  const editAnswer = async (row) => {
     
    try {

      const data = {
        formData: row.formId,
        search: [
            {
                "key": "id",
                "value": row.id
            }
        ]
      
      };
      const rowDataSearch = await dispatch(getFormAnswerById(data));

      let rowData = rowDataSearch.payload;

       setSelectedData(rowData);
        openAnswerEdit(true);

     } catch (error) {
      console.log("error", error);
      notification.error({
        message: t("Error"),
        description: t("Error - editAnswer"),
      });
    }

  };

  const duplicateAnswer = (row) => {
    row.isDuplicate = true;
    setSelectedData(row);
    openAnswerDuplicate(true);
  };

  const handleCloseUserView = () => {
    openAnswerView(false);
  };

  const handleCloseUserEdit = () => {
    openAnswerEdit(false);
    setFormAnswer(props.form.id);
  };

  const handleCloseUserduplicate = () => {
    openAnswerDuplicate(false);
    setFormAnswer(props.form.id);
  };

  const deleteItem = async () => {
    const val = {
      id: deletedItem.id,
      formId: props.form.id,
    };
    // for reset the answers for the deleted task
    if (deletedItem.formProccess) {
      dispatch(resetTaskProcessReducer({ id: deletedItem.id, processingFormId: getProcessFormByProject(columns,deleteItem.data.database.main.Project) }))
        .then(async () => {
          localStorage.removeItem("activeTask");
          // setActiveTaskList()
          const res = await dispatch(deleteSelectedAnswer(val));

          if (res.payload) {
            searchAnswerList(props.form.id);
            setDeletedView(false);
            setFormAnswer(props.form.id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    else {
      const res = await dispatch(deleteSelectedAnswer(val));
      if (res.payload) {
        searchAnswerList(props.form.id);
        setDeletedView(false);
        setFormAnswer(props.form.id);
      }
    }
  };

  const [formDataSet, setFormDataSet] = useState({});
  const [addbtnDisable, setaddbtnDisable] = useState(false);

  const [defError, setdefError] = useState([]);
  const changeDataTrigger = async (data, err = defError) => {
    setdefError(err);

    if (err.length != 0) {
      setaddbtnDisable(true);
    } else {
      setaddbtnDisable(false);
    }

    const dataset = data?.database?.main ? data.database.main : null;
    let dataWarningArray = [];
    let dataErrorArray = [];

    if (dataset) {
      props.dataHandle(dataset);
      for (const rule of ruleArray) {
        let similerities = 0;
        for (const ruleObj of rule.rules) {
          const field = ruleObj.field ? ruleObj.field : null;
          const value = ruleObj.answer ? ruleObj.answer : null;
          const action = ruleObj.action ? ruleObj.action : null;

          if (field && value) {
            if (dataset[field]) {
              if (!action) {
                if (dataset[field] == value) {
                  similerities++;
                }
              } else if (action == "equal") {
                if (dataset[field] == value) {
                  similerities++;
                }
              } else if (action === "notEqual") {
                if (dataset[field] != value) {
                  similerities++;
                }
              } else if (action === "equalLarger") {
                if (dataset[field] >= value) {
                  similerities++;
                }
              } else if (action === "equalSmaller") {
                if (dataset[field] <= value) {
                  similerities++;
                }
              } else if (action === "larger") {
                if (dataset[field] > value) {
                  similerities++;
                }
              } else if (action === "smaller") {
                if (dataset[field] < value) {
                  similerities++;
                }
              } else if (action === "notempty") {
                if (dataset[field]) {
                  similerities++;
                }
              } else if (action === "empty") {
                if (!dataset[field]) {
                  similerities++;
                }
              }
            }
          }
        }
        if (rule.rules.length === similerities) {
          if (rule.type == "warning") {
            dataWarningArray.push(rule);
          } else {
            dataErrorArray.push(rule);
          }
        }
      }
    }
    if (dataErrorArray.length != 0 || err.length != 0) {
      setaddbtnDisable(true);
    } else {
      setaddbtnDisable(false);
    }
    dispatch(addWarningListReducerAdd(dataWarningArray));
    dispatch(addErrorListReducerAdd(dataErrorArray));
    //dispatch(changeDataReducer(dataErrorArray))
    setErrorList(dataErrorArray);
    setWarningList(dataWarningArray);
    //           const [errorList, setErrorList] = useState([]);

    // const [warningList, setWarningList] = useState([]);

    setFormDataSet(data);
  };

  const draftFormStart = async (data) => {
    const tempData = {
      data: data,
      form: { ...props.form, schema: null, group: null },
      draft: true,
    };

    const res = await dispatch(submitFormAnswer(tempData));

    openSubmitForm(false);
    setFormAnswer(props.form.id, false, props.columns);
    editAnswer(res.payload.data.data);
  };

  const [formDataSetEdit, setFormDataSetEdit] = useState({});

  const changeDataTriggerEdit = async (data) => {
    setFormDataSetEdit(data);
  };

  const formDraftEditTimeLine = async (data) => {
    const tempData = {
      answerId: selectedData.id,
      data: data,
      form: props.form.id,
      draft: true,
    };

    const res = await dispatch(editFormAnswer(tempData));

    //getAnswerList(props.form.id, false, props.columns);
    return res;
  };

  const formSubmitEdit = async () => {
    const tempData = {
      answerId: selectedData.id,
      data: formDataSetEdit,
      form: props.form.id,
      rejected: selectedData?.workFlow?.rejected ? true : false,
    };

    const res = await dispatch(editFormAnswer(tempData));
    // const res = await dispatch(submitFormAnswer(tempData))

    handleCloseUserEdit();
    setFormAnswer(props.form.id, false, props.columns);
  };

  const formDraftEdit = async () => {
    const tempData = {
      answerId: selectedData.id,
      data: formDataSetEdit,
      form: props.form.id,
      draft: true,
    };

    const res = await dispatch(editFormAnswer(tempData));
    // const res = await dispatch(submitFormAnswer(tempData))

    handleCloseUserEdit();
    setFormAnswer(props.form.id, false, props.columns);
  };

  const duplicateForm = async (payload) => {
    const tempData = {
      data: payload,
      form: { ...props.form, schema: null, group: null },
    };

    const res = await dispatch(submitFormAnswer(tempData));

    openSubmitForm(false);
    setFormAnswer(props.form.id, false, props.columns);
  };

  const importJson = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const workSheed = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheed);

    const temparray = [];

    for (const el of jsonData) {
      temparray.push(el);
    }

    const obj = {
      data: temparray,
      form: props.form,
    };

    await dispatch(submitBulkData(obj));
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const formSubmit = async () => {
    setSubmitLoading(true);
    const tempData = {
      data: formDataSet,
      form: { ...props.form, group: null, schema: null },
    };

    let trigerValue = schemaComponent.find((el) => el.trigerAll);

    if (trigerValue) {
      let triggerSet = formDataSet?.database?.main[trigerValue.keyTxt]
        ? formDataSet.database.main[trigerValue.keyTxt]
        : null;
      if (triggerSet) {
        for (let trigerVal of triggerSet) {
          let formData = formDataSet;

          formData = {
            ...formData,
            database: {
              ...formData.database,
              main: {
                ...formData.database.main,
                [trigerValue.keyTxt]: trigerVal,
              },
            },
          };
          let re = {
            data: formData,
            form: { ...props.form, schema: null, group: null },
          };

          const res = await dispatch(submitFormAnswer(re));
        }
      } else {
        const res = await dispatch(submitFormAnswer(tempData));
      }
    } else {
      const res = await dispatch(submitFormAnswer(tempData));
    }
    //for()
    setSubmitLoading(false);
    setFormDataSet({});

    openSubmitForm(false);
    setFormAnswer(props.form.id, false, props.columns);
  };

  const formDupicate = async () => {
    const tempData = {
      data: formDataSet,
      form: { ...props.form, schema: null, group: null },
    };

    const res = await dispatch(submitFormAnswer(tempData));

    openAnswerDuplicate(false);
    setFormAnswer(props.form.id, false, props.columns);
  };

  const draftForm = async () => {
    const tempData = {
      data: formDataSet,
      form: { ...props.form, schema: null, group: null },
      draft: true,
    };

    const res = await dispatch(submitFormAnswer(tempData));

    openSubmitForm(false);
    setFormAnswer(props.form.id, false, props.columns);
  };

  //export function

  const [exportProfiles, setExportProfile] = useState([]);

  const generateAnswerListExport = async (
    id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    forExport = true,
    profile = "all"
  ) => {
    const viewType = props?.containerData?.viewType
      ? props.containerData.viewType
      : "all";

    const data = {
      formData: id,
      sorting: tempSort ? tempSort : sortableKey ? sortableKey : false,
      order: sortableKey ? !sortableAsyn : false,
      search: serachKey ? serachKey : null,
      viewType: viewType,
      forExport: forExport,
      profile
    };

    setSortablekey(tempSort);
    if (tempSort) {
      setSortableAsyn(!sortableAsyn);
    }
    const res = await dispatch(generateFormAnswerListExportReducer(data));

    return res;
  };


  const getAnswerListExport = async (
    id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    forExport = false,
    profile = "all"
  ) => {
    const viewType = props?.containerData?.viewType
      ? props.containerData.viewType
      : "all";

    const data = {
      id
    };

    setSortablekey(tempSort);
    if (tempSort) {
      setSortableAsyn(!sortableAsyn);
    }
    const res = await dispatch(getFormAnswerListExportProfileReducer(data));

    return res;
  };

  const createDataSet = async (tempAnswer, profile) => {
    const mainElements = container.schema;
    let procesAnser = [];

    let subDevider = mainElements.find((me) => me.sub_devider);

    let summeryKeys = [];
    let selectedProfilearray = null;
    if (profile != "all") {
      const selectedProfileObj = exportProfiles.find((e) => e._id == profile);
      if (selectedProfileObj?.elements) {
        selectedProfilearray = selectedProfileObj.elements;
      }
    }

    //const languageSet = langs.payload.data;
    let excelArra = [];
    let headerArray = ["CreatedBy", "createdAt", "finishedAt"];

    let headerTranslateArray = [
      t("CreatedBy"),
      t("createdAt"),
      t("finishedAt"),
    ];

    if (mainElements) {
      for (const element of mainElements) {
        if (profile != "all") {
          if (selectedProfilearray) {
            if (!selectedProfilearray.find((es) => es == element.keyTxt)) {
              continue;
            }
          }
        }

        headerTranslateArray.push(t(element.keyTxt));
        headerArray.push(element.keyTxt);
      }
      if (tempAnswer.length !== 0) {
        if (tempAnswer[0].formProccess) {
          procesAnser = tempAnswer[0].formProccess.proccessFormScheema
            ? tempAnswer[0].formProccess.proccessFormScheema
            : [];

          let selectedProcessForm = tempAnswer[0].formProccess.proccessForm;
          // console.log("subSet", selectedProcessForm);
          // console.log("subSet", selectedSubValue);
          let selectedSubCat = selectedSubValue.find(
            (sub) => sub.form.id === selectedProcessForm.id
          );

          // console.log("subSet", selectedSubCat);

          let subSet = null;

          if (selectedSubCat) {
            if (selectedSubCat.slectedVal != "all") {
              let allSubProfiles = selectedProcessForm.exportProfiles
                ? selectedProcessForm.exportProfiles
                : [];
              subSet = allSubProfiles.find(
                (temSub) => temSub._id === selectedSubCat.slectedVal
              );
            }
          }

          // selectedSubValue

          for (const element of procesAnser) {
            if (subSet) {
              if (
                !subSet.elements.find((subERl) => subERl === element.keyTxt)
              ) {
                continue;
              }
            }
            if (element.type === "lable_draft") {
              continue;
            }
            if (element.type === "data_grid") {
              headerTranslateArray.push(t(element.keyTxt));
              headerTranslateArray.push(t("qty of stock"));
              headerTranslateArray.push(t("image of stock"));

              headerArray.push(element.keyTxt);

              headerArray.push("qty of stock static");
              headerArray.push("image of stock static");
            } else {
              headerTranslateArray.push(t(element.keyTxt));
              headerArray.push(element.keyTxt);
            }
          }

          if (subSet) {
            if (subSet.elements.find((subERl) => subERl === "workFlow")) {
              headerTranslateArray.push(t("status"));
              headerArray.push("_workFlow_status");
            }
            if (subSet.elements.find((subERl) => subERl === "checkList")) {
              headerTranslateArray.push(t("Deduction"));
              headerArray.push("_workFlow_point");
            }
          }
        }
        const isWorkFlow = tempAnswer.find((e) => e.workFlow);
        if (isWorkFlow) {
          headerTranslateArray.push(t("WorkFlow"));
          headerArray.push("WorkFlow");
        }
      }
    }

    excelArra.push(headerTranslateArray);

    if (tempAnswer) {
      for (const element of tempAnswer) {
        let currentRow = excelArra.length;
        const tempRowArra = [];

        const indexOfOb = tempAnswer.findIndex((t) => t.id == element.id);

        if (headerArray) {
          let position = 0;
          let productDraft = [];
          let productOrderDraft = [];
          for (const formElement of headerArray) {
            if (formElement === "CreatedBy") {
              tempRowArra.push(
                element?.createdBy?.email ? element.createdBy.email : null
              );
            } else if (formElement === "finishedAt") {
              // console.log(element);
              tempRowArra.push(element.finishedAt);
            } else if (formElement === "createdAt") {
              tempRowArra.push(element.createdAt);
            } else if (
              formElement === "qty of stock static" ||
              formElement === "image of stock static"
            ) {
              continue;
            } else if (formElement === "WorkFlow") {
              if (element.workFlow) {
                let workFlowString = "";
                if (element.workFlow.rejected) {
                  workFlowString += ` Status : Rejected\n`;
                } else {
                  workFlowString += ` Status : ${element.workFlow.taskStage
                    ? element.workFlow.taskStage
                    : null
                    }, \n`;
                }

                workFlowString += "Approval Details : \n";
                if (element.workFlow.approvalStageData) {
                  if (Array.isArray(element.workFlow.approvalStageData)) {
                    const workdataArr = element.workFlow.approvalStageData;
                    for (const workFlowStage of workdataArr) {
                      workFlowString += `Stage : ${workFlowStage.flowStage ? workFlowStage.flowStage : null
                        } \n`;
                      workFlowString += `Resposible Person : ${workFlowStage.resPerson ? workFlowStage.resPerson : null
                        } \n`;
                      workFlowString += `Resposible Person Name: ${workFlowStage?.resPerson?.userData?.userDB?.NAME
                        ? workFlowStage.resPerson.userData.userDB.NAME
                        : null
                        } \n`;
                      workFlowString += `status : ${workFlowStage.status ? workFlowStage.status : null
                        } \n`;
                      workFlowString += `Note : ${workFlowStage.reason ? workFlowStage.reason : null
                        } ,\n`;
                    }
                  }
                }
                tempRowArra.push(workFlowString);
              }
            } else {
              if (element?.data?.database?.main[formElement]) {
                const shemaObj = mainElements.find(
                  (e) => e.keyTxt === formElement
                );

                if (shemaObj) {
                  if (shemaObj.type === "drop") {
                    if (shemaObj.selectType === "multi") {
                      const valueOfArra = element?.data?.database?.main[
                        formElement
                      ]
                        ? element.data.database.main[formElement]
                        : [];

                      tempRowArra.push(valueOfArra.toString());
                    } else {
                      tempRowArra.push(
                        element?.data?.database?.main[formElement]
                          ? element.data.database.main[formElement]
                          : null
                      );
                    }
                  } else if (shemaObj.type === "html_camera") {
                    let valueOfArra = element?.data?.database?.main[formElement]
                      ? element.data.database.main[formElement]
                      : [];
                    valueOfArra = valueOfArra.location;
                    if (valueOfArra) {
                      let imhSt = "";
                      for (const imObj of valueOfArra) {
                        imhSt += imObj;
                        imhSt += " , ";
                      }
                      tempRowArra.push(imhSt.toString());
                    } else {
                      tempRowArra.push(null);
                    }
                  } else if (shemaObj.type == "File") {
                    let valueOfArra = element?.data?.database?.main[formElement]
                      ? element.data.database.main[formElement]
                      : [];
                    valueOfArra = valueOfArra.location;
                    if (valueOfArra) {
                      tempRowArra.push(valueOfArra.toString());
                    } else {
                      tempRowArra.push(null);
                    }
                  } else if (shemaObj.type === "timeLine") {
                    let valueStr = "";
                    let valStr = "";
                    let valDetails = "";
                    const colAnswer =
                      element?.data?.database?.main[formElement];

                    if (colAnswer) {
                      if (
                        colAnswer.start.dateTime &&
                        !colAnswer.finishd.dateTime
                      ) {
                        valStr = "IN PROGRESS";
                      }
                      if (
                        colAnswer.paused.find(
                          (e) => e.pauseTime && !e.resumeTime
                        )
                      ) {
                        valStr = "HOLD";
                      }
                      if (colAnswer.finishd.dateTime) {
                        valStr = "DONE";
                      }
                    } else {
                      valStr = "PENDING";
                    }

                    valueStr += `status : ${valStr} \n`;

                    if (colAnswer) {
                      if (colAnswer?.start?.dateTime) {
                        valueStr += `START TIME : ${colAnswer.start.dateTime} \n`;
                      }
                      if (colAnswer?.start?.dateTime) {
                        const lat = colAnswer?.start?.isLocationEnable?.latitude
                          ? colAnswer.start.isLocationEnable.latitude
                          : null;
                        const lon = colAnswer?.start?.isLocationEnable
                          ?.longitude
                          ? colAnswer.start.isLocationEnable.longitude
                          : null;
                        valueStr += `START LOCATION latitude : ${lat} , longitude ${lon} \n`;
                      }

                      if (colAnswer?.finishd?.dateTime) {
                        valueStr += `FINISH TIME : ${colAnswer.finishd.dateTime} \n`;
                      }
                      if (colAnswer?.paused) {
                        for (const st of colAnswer.paused) {
                          const resume = st.resume ? "ture" : "false";
                          const pauseTime = st?.pauseTime ? st.pauseTime : null;
                          const pauselat = st?.pouseLocation?.latitude
                            ? st.pouseLocation.latitude
                            : null;
                          const pauselong = st?.pouseLocation?.longitude
                            ? st.pouseLocation.longitude
                            : null;

                          const resumeTime = st?.resumeTime
                            ? st.pauseTime
                            : null;
                          const resumelat = st?.resumeLocation?.latitude
                            ? st.pouseLocation.latitude
                            : null;
                          const resumelong = st?.resumeLocation?.longitude
                            ? st.pouseLocation.longitude
                            : null;
                          var ret = "-";
                          if (st.resumeDiff) {
                            let duration = st.resumeDiff;
                            var hrs = ~~(duration / 3600);
                            var mins = ~~((duration % 3600) / 60);
                            var secs = ~~duration % 60;

                            // Output like "1:01" or "4:03:59" or "123:03:59"

                            if (hrs > 0) {
                              ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
                            }

                            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
                            ret += "" + secs;
                          }

                          valueStr += `Is resume : ${resume} \n`;
                          valueStr += `POUSED TIME : ${pauseTime} \n`;
                          valueStr += `POUSED LOCATION latitude : ${pauselat} , longitude ${pauselong} \n`;

                          valueStr += `RESUME TIME : ${resumeTime} \n`;
                          valueStr += `RESUME LOCATION latitude : ${resumelat} , longitude ${resumelong} \n`;

                          valueStr += `HOLD DURATION : ${ret}  \n`;
                        }
                      }

                      if (colAnswer?.finishd?.dateTime) {
                        const lat = colAnswer?.finishd?.isLocationEnable
                          ?.latitude
                          ? colAnswer.finishd.isLocationEnable.latitude
                          : null;
                        const lon = colAnswer?.finishd?.isLocationEnable
                          ?.longitude
                          ? colAnswer.finishd.isLocationEnable.longitude
                          : null;
                        valueStr += `FINISH LOCATION latitude : ${lat} , longitude ${lon} \n`;
                      }
                    }

                    tempRowArra.push(valueStr);
                  } else {
                    tempRowArra.push(
                      element?.data?.database?.main[formElement]
                        ? element.data.database.main[formElement]
                        : null
                    );
                  }
                } else {
                  // this will never happened
                  tempRowArra.push(null);
                }
              } else if (formElement === "_workFlow_status") {
                //summeryKeys

                let fulStatus = null;
                let summeryObj = {
                  key: element?.data?.database?.main[subDevider.keyTxt]
                    ? element.data.database.main[subDevider.keyTxt]
                    : null,
                };

                if (element.formProccess) {
                  if (element.formProccess.jsonProcessAnswer) {
                    if (element.formProccess.jsonProcessAnswer.isComplete) {
                      if (element.workFlow) {
                        if (element.workFlow.rejected) {
                          fulStatus = "Not Passed";
                        } else {
                          if (element.workFlow.taskStage === "Completed") {
                            fulStatus = "Passed";
                          } else {
                            fulStatus = "Approval Pending";
                          }
                        }
                      } else {
                        fulStatus = "workflow not found";
                      }
                    } else {
                      const deadLineSchema = mainElements.find(
                        (e) => e.dead_line
                      );
                      if (deadLineSchema) {
                        let deadling = element?.data?.database?.main[
                          deadLineSchema.keyTxt
                        ]
                          ? element.data.database.main[deadLineSchema.keyTxt]
                          : null;
                        if (deadling) {
                          var elDate = new Date(deadling);
                          var now = new Date();
                          now.setHours(0, 0, 0, 0);
                          if (elDate < now) {
                            fulStatus = "Missed";
                          } else {
                            fulStatus = "Pending";
                          }
                        } else {
                          fulStatus = "-";
                        }
                      } else {
                        fulStatus = "-";
                      }
                    }
                  } else {
                    const deadLineSchema = mainElements.find(
                      (e) => e.dead_line
                    );
                    if (deadLineSchema) {
                      let deadling = element?.data?.database?.main[
                        deadLineSchema.keyTxt
                      ]
                        ? element.data.database.main[deadLineSchema.keyTxt]
                        : null;
                      if (deadling) {
                        var elDate = new Date(deadling);
                        var now = new Date();
                        now.setHours(0, 0, 0, 0);
                        if (elDate < now) {
                          fulStatus = "Missed";
                        } else {
                          fulStatus = "Pending";
                        }
                      } else {
                        fulStatus = "-";
                      }
                    } else {
                      fulStatus = "-";
                    }
                  }
                } else {
                  fulStatus = "form process not assign";
                }

                tempRowArra.push(fulStatus);

                //summeryKeys

                summeryObj = {
                  ...summeryObj,
                  status: fulStatus,
                };

                summeryKeys.push(summeryObj);
              } else if (formElement === "_workFlow_point") {
                if (element.workFlow) {
                  if (element?.workFlow?.dataTab?.approvalFlow) {
                    let approvalFolws = element.workFlow.dataTab.approvalFlow;
                    let point = 0;
                    for (let approvalFlow of approvalFolws) {
                      if (approvalFlow.subAnswerRemark) {
                        Object.keys(approvalFlow.subAnswerRemark).forEach(
                          function (key) {
                            const objOfVal = approvalFlow.subAnswerRemark[key];
                            if (objOfVal.value) {
                              point = point + objOfVal.value;
                            }
                          }
                        );
                      }
                    }
                    tempRowArra.push(point);
                  } else {
                    tempRowArra.push(0);
                  }
                } else {
                  tempRowArra.push(null);
                }
              } else {
                if (element.formProccess) {
                  const shemaObj = procesAnser.find(
                    (e) => e.keyTxt === formElement
                  );
                  if (shemaObj) {
                    if (shemaObj.type === "File") {
                      if (
                        element?.formProccess?.jsonProcessAnswer?.data?.database
                          ?.main[formElement]
                      ) {
                        if (
                          Array.isArray(
                            element.formProccess.jsonProcessAnswer.data.database
                              .main[formElement]
                          )
                        ) {
                          const arrImg = element.formProccess.jsonProcessAnswer
                            .data.database.main[formElement]
                            ? element.formProccess.jsonProcessAnswer.data
                              .database.main[formElement]
                            : [];
                          let temImgArr = "";
                          for (const elim of arrImg) {
                            temImgArr += elim.location ? elim.location : null;
                            temImgArr += ", ";
                          }
                          tempRowArra.push(temImgArr);
                        } else {
                          tempRowArra.push(
                            element?.formProccess?.jsonProcessAnswer?.data
                              ?.database?.main[formElement]?.location
                              ? element.formProccess.jsonProcessAnswer.data
                                .database.main[formElement].location
                              : "-"
                          );
                        }
                      } else {
                        tempRowArra.push(null);
                      }
                    }
                    if (shemaObj.type === "html_camera") {
                      if (
                        element?.formProccess?.jsonProcessAnswer?.data?.database
                          ?.main[formElement]
                      ) {
                        if (
                          Array.isArray(
                            element.formProccess.jsonProcessAnswer.data.database
                              .main[formElement]
                          )
                        ) {
                          const arrImg = element.formProccess.jsonProcessAnswer
                            .data.database.main[formElement]
                            ? element.formProccess.jsonProcessAnswer.data
                              .database.main[formElement]
                            : [];
                          let temImgArr = "";
                          for (const elim of arrImg) {
                            temImgArr += elim.location ? elim.location : null;
                            temImgArr += " \n";
                          }
                          tempRowArra.push(temImgArr);
                        } else {
                          tempRowArra.push(
                            element?.formProccess?.jsonProcessAnswer?.data
                              ?.database?.main[formElement]?.location
                              ? element.formProccess.jsonProcessAnswer.data
                                .database.main[formElement].location
                              : null
                          );
                        }
                      } else {
                        tempRowArra.push(null);
                      }
                    } else if (shemaObj.type === "location") {
                      if (
                        element?.formProccess?.jsonProcessAnswer?.data?.database
                          ?.main[formElement]
                      ) {
                        const latitude = element?.formProccess
                          ?.jsonProcessAnswer?.data?.database?.main[formElement]
                          ?.latitude
                          ? element.formProccess.jsonProcessAnswer.data.database
                            .main[formElement].latitude
                          : null;
                        const longitude = element?.formProccess
                          ?.jsonProcessAnswer?.data?.database?.main[formElement]
                          ?.longitude
                          ? element.formProccess.jsonProcessAnswer.data.database
                            .main[formElement].longitude
                          : null;
                        tempRowArra.push(
                          `latitude:${latitude} longitude:${longitude}`
                        );
                      } else {
                        tempRowArra.push(null);
                      }
                    } else if (shemaObj.type === "data_grid") {
                      let displaSt = "";
                      const selectedSch = tempAnswer[indexOfOb];

                      const gridSc =
                        selectedSch.formProccess.proccessFormScheema.find(
                          (ll) => ll.keyTxt == shemaObj.keyTxt
                        );

                      const allAnswerObj = element?.formProccess
                        ?.jsonProcessAnswer
                        ? element.formProccess.jsonProcessAnswer
                        : null;
                      //
                      //
                      let prodCunt = 0;

                      if (shemaObj?.isExportMultiRow) {
                        const nameofSe = `${gridSc.keyTxt}`;
                        const thisVal = allAnswerObj?.data?.database?.main[
                          nameofSe
                        ]
                          ? allAnswerObj.data.database.main[nameofSe]
                          : null;
                        //console.log(nameofSe,thisVal,'gridScgridScgridSc')
                        if (thisVal) {
                          for (const arrVal of thisVal) {
                            productOrderDraft.push({
                              value: arrVal.value,
                              count: arrVal.count,
                            });
                          }
                        }
                      } else if (shemaObj.multiStage) {
                        for (const arrVal of gridSc.arrayValue) {
                          const groupName =
                            arrVal.group_production.selectedgroup;
                          const subgroupName = arrVal.group_production
                            ?.selectedsubgroup
                            ? `-${arrVal.group_production?.selectedsubgroup}`
                            : "";

                          const nameofSe = `${gridSc.keyTxt}-${groupName}${subgroupName}`;
                          const thisVal = allAnswerObj?.data?.database?.main[
                            nameofSe
                          ]
                            ? allAnswerObj.data.database.main[nameofSe]
                            : null;

                          if (thisVal) {
                            for (let AnsSte of thisVal) {
                              // if (prodCunt === 0) {
                              //   tempRowArra.push(AnsSte.value ? AnsSte.value : '-')
                              //   tempRowArra.push(AnsSte.count ? AnsSte.count : '0')
                              //   tempRowArra.push(AnsSte?.img?.location ? AnsSte.img.location : null)
                              // }

                              // displaSt += `PROPUCT NAME : ${AnsSte.value ? AnsSte.value : '-'} Image : ${AnsSte?.img?.location ? AnsSte.img.location : null}  COUNT : ${AnsSte.count ? AnsSte.count : '0'} \n`
                              prodCunt++;
                            }
                          } else {
                            const arrayofEnumVal = arrVal?.group_production
                              ?.value
                              ? arrVal.group_production.value
                              : [];
                            for (let AnsSte of arrayofEnumVal) {
                              // if (prodCunt === 0) {
                              //   tempRowArra.push(AnsSte.value ? AnsSte.value : '-')
                              //   tempRowArra.push(AnsSte.count ? AnsSte.count : '0')
                              //   tempRowArra.push(AnsSte?.img?.location ? AnsSte.img.location : null)
                              // }
                              prodCunt++;
                            }
                          }
                        }

                        const proObj = {
                          count: prodCunt,
                          position: position,
                          employee: {
                            index: headerArray.indexOf("Responsible_Users"),
                            data: element.data.database.main.Responsible_Users,
                          },
                          project: {
                            index: headerArray.indexOf("Project"),
                            data: element.data.database.main.Project,
                          },
                          store: {
                            index: headerArray.indexOf("store"),
                            data: element.data.database.main.store,
                          },
                          taskId: {
                            index: headerArray.indexOf("task_id"),
                            data: element.data.database.main.task_id,
                          },
                        };
                        productDraft.push(proObj);
                      } else {
                        if (
                          element?.formProccess?.jsonProcessAnswer?.data
                            ?.database?.main[formElement]
                        ) {
                          if (
                            element?.formProccess?.jsonProcessAnswer?.data
                              ?.database?.main[formElement]
                          ) {
                            const gridA = element?.formProccess
                              ?.jsonProcessAnswer?.data?.database?.main[
                              formElement
                            ]
                              ? element.formProccess.jsonProcessAnswer.data
                                .database.main[formElement]
                              : [];
                            let sring = "";

                            for (const gridItem of gridA) {
                              sring += `Item : ${gridItem?.value ? gridItem.value : null
                                } Image : ${gridItem?.img?.location
                                  ? gridItem.img.location
                                  : null
                                } Count : ${gridItem?.count ? gridItem.count : null
                                } \n`;
                            }
                            tempRowArra.push(sring);
                          } else {
                            tempRowArra.push("no stock or no data found");
                          }
                        }
                        tempRowArra.push("no stock or no data found");
                      }
                    } else if (shemaObj.type === "lable_draft") {
                      continue;
                    } else {
                      tempRowArra.push(
                        element?.formProccess?.jsonProcessAnswer?.data?.database
                          ?.main[formElement]
                          ? element.formProccess.jsonProcessAnswer.data.database
                            .main[formElement]
                          : null
                      );
                    }
                  } else {
                    tempRowArra.push(
                      element?.formProccess?.jsonProcessAnswer?.data?.database
                        ?.main[formElement]
                        ? element.formProccess.jsonProcessAnswer.data.database
                          .main[formElement]
                        : null
                    );
                  }
                } else {
                  tempRowArra.push(null);
                }
              }
            }

            position++;
          }
          excelArra.push(tempRowArra);

          if (productDraft.length != 0) {
            //let array=
            const selectedSch = tempAnswer[indexOfOb];

            let rowArr = [];
            // const allAnswerObj = element?.formProccess?.jsonProcessAnswer ? element.formProccess.jsonProcessAnswer : null
            for (const singledraft of productDraft) {
              const scemaTemp = headerArray[singledraft.position];
              const gridSc = selectedSch.formProccess.proccessFormScheema.find(
                (ll) => ll.keyTxt == scemaTemp
              );

              let numberOfDy = 0;

              let singlerow = [];
              if (gridSc.arrayValue) {
                for (const arrVal of gridSc.arrayValue) {
                  const groupName = arrVal.group_production.selectedgroup;
                  const subgroupName = arrVal.group_production?.selectedsubgroup
                    ? `-${arrVal.group_production?.selectedsubgroup}`
                    : "";

                  const nameofSe = `${gridSc.keyTxt}-${groupName}${subgroupName}`;
                  const allAnswerObj = element?.formProccess?.jsonProcessAnswer
                    ? element.formProccess.jsonProcessAnswer
                    : null;
                  const thisVal = allAnswerObj?.data?.database?.main[nameofSe]
                    ? allAnswerObj.data.database.main[nameofSe]
                    : null;

                  if (thisVal) {
                    for (let AnsSte of thisVal) {
                      singlerow.push({
                        lable: AnsSte.value ? AnsSte.value : null,
                        count: AnsSte.count ? AnsSte.count : "0",
                        img: AnsSte?.img?.location ? AnsSte.img.location : null,
                        product_value: productOrderDraft[numberOfDy]
                          ? productOrderDraft[numberOfDy]["value"]
                          : null,
                        product_count: productOrderDraft[numberOfDy]
                          ? productOrderDraft[numberOfDy]["count"]
                          : null,
                        singledraft: singledraft,
                      });

                      // displaSt += `PROPUCT NAME : ${AnsSte.value ? AnsSte.value : '-'} Image : ${AnsSte?.img?.location ? AnsSte.img.location : null}  COUNT : ${AnsSte.count ? AnsSte.count : '0'} \n`
                      numberOfDy++;
                    }
                  } else {
                    const arrayofEnumVal = arrVal?.group_production?.value
                      ? arrVal.group_production.value
                      : [];
                    for (let AnsSte of arrayofEnumVal) {
                      singlerow.push({
                        lable: AnsSte.value ? AnsSte.value : null,
                        count: AnsSte.count ? AnsSte.count : "0",
                        img: AnsSte?.img?.location ? AnsSte.img.location : null,
                        product_value: productOrderDraft[numberOfDy]
                          ? productOrderDraft[numberOfDy]["value"]
                          : null,
                        product_count: productOrderDraft[numberOfDy]
                          ? productOrderDraft[numberOfDy]["count"]
                          : null,
                        singledraft: singledraft,
                      });
                      numberOfDy++;
                    }
                  }
                }
              }

              let fullArr = [];
              for (const onTem of singlerow) {
                let arrayT = [];
                for (let i = 0; i <= singledraft.position; i++) {
                  if (i == singledraft.position) {
                    arrayT.push(onTem.lable);
                    arrayT.push(onTem.count);
                    arrayT.push(onTem.img);
                    arrayT.push(onTem.product_value);
                    arrayT.push(onTem.product_count);
                  } else {
                    arrayT.push(null);
                  }
                  //text += cars[i] + "<br>";
                }
                arrayT[onTem.singledraft.employee.index] =
                  onTem.singledraft.employee.data;
                arrayT[onTem.singledraft.project.index] =
                  onTem.singledraft.project.data;
                arrayT[onTem.singledraft.store.index] =
                  onTem.singledraft.store.data;
                arrayT[onTem.singledraft.taskId.index] =
                  onTem.singledraft.taskId.data;
                excelArra.push(arrayT);
              }
              excelArra.push(null);

              //
            }
          }
        }
      }
    }

    if (summeryKeys.length != 0) {
      excelArra.push([]);
      excelArra.push([]);
      excelArra.push([]);
      excelArra.push(["Summery"]);
      let sumHeader = [
        "#",
        "planned",
        "Pending",
        "Missed",
        "Passed",
        "Not Passed",
        "form process not assign",
        "workflow not found",
        "Approval Pending",
        "-",
      ];
      excelArra.push(sumHeader);
      const grouped = SubDeviderGroup(summeryKeys, (ob) => ob.key);
      for (let gEl of grouped) {
        let tempEl = gEl[0] ? gEl[0] : null;
        let summeryTemp = [tempEl];
        let tempArrStatus = gEl[1] ? gEl[1] : null;
        if (tempArrStatus) {
          const plannedCount = tempArrStatus.length;
          const PendingCount = tempArrStatus.filter(
            (fil) => fil.status == "Pending"
          ).length;

          const MissedCount = tempArrStatus.filter(
            (fil) => fil.status == "Missed"
          ).length;
          const PassedCount = tempArrStatus.filter(
            (fil) => fil.status == "Passed"
          ).length;
          const Not_PassedCount = tempArrStatus.filter(
            (fil) => fil.status == "Not Passed"
          ).length;
          const formProcessNotAssignCount = tempArrStatus.filter(
            (fil) => fil.status == "form process not assign"
          ).length;
          const workFlowNotFoundCount = tempArrStatus.filter(
            (fil) => fil.status == "workflow not found"
          ).length;
          const ApprovalpendingCount = tempArrStatus.filter(
            (fil) => fil.status == "Approval Pending"
          ).length;
          const NotRecognizeCount = tempArrStatus.filter(
            (fil) => fil.status == "-"
          ).length;

          summeryTemp.push(plannedCount);
          summeryTemp.push(PendingCount);

          summeryTemp.push(MissedCount);
          summeryTemp.push(PassedCount);
          summeryTemp.push(Not_PassedCount);
          summeryTemp.push(formProcessNotAssignCount);
          summeryTemp.push(workFlowNotFoundCount);
          summeryTemp.push(ApprovalpendingCount);
          summeryTemp.push(NotRecognizeCount);
        }

        excelArra.push(summeryTemp);
        //console.log(gEl)
      }
    }

    // sumeryHeader

    return excelArra;
  };

  function SubDeviderGroup(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x.data.database.main[key]] =
        rv[x.data.database.main[key]] || []).push(x);
      return rv;
    }, {});
  };

  const [getAllSubForm, setSubForm] = useState([]);
  const [selectedProject,setSelectedProject] = useState(null);

  const [selectedSubValue, setSelectedSubValue] = useState([]);

  const [loadingSub, setLoadingSub] = useState(false);
  const exportFunctionOpen = async () => {
    setSelectedProject(null)
    setOpenFilter(true);
    setLoadingSub(true);
    const mainElements = container.schema;

    const diEl = mainElements.find((e) => e.divider);
    const exportAnswer = await getAnswerListExport(
      props.form.id,
      false,
      props.columns,
      1,
      dataPerPage,
      true,
      selectedMainProfile
    );

    // const tempAnswer = allAnswerRow

    //
    // let tempAnswer = exportAnswer?.payload?.data?.data
    //   ? exportAnswer.payload.data.data
    //   : [];

    // let arrrrr = [];
    // if (diEl) {
    //   const vc = diEl.keyTxt;
    //   const groupByCategory = await groupBy(tempAnswer, vc);
    //   var result = Object.entries(groupByCategory);

    //   let tempexportProfiles = [];
    //   for (const ggg of result) {
    //     if (ggg[1]) {
    //       const temObj = ggg[1][0]?.formProccess?.proccessForm?.exportProfiles
    //         ? ggg[1][0].formProccess.proccessForm
    //         : null;
    //       const schema = ggg[1][0]?.formProccess?.proccessFormScheema
    //         ? ggg[1][0].formProccess.proccessFormScheema
    //         : [];
    //       if (temObj) {
    //         tempexportProfiles.push({
    //           obj: temObj,
    //           schema: schema,
    //           name: ggg[0] ? ggg[0] : null,
    //         });
    //       }
    //     }
    //   }

      // console.log("temObj", tempexportProfiles);

      setSubForm(exportAnswer.payload.data.processingForms);
    // }
    await setExportFilesList()
    setLoadingSub(false);
  };

  const setExportFilesList = async (pageNo = 1,filters=exportFilterArray) => {
    setSelectedPageExport(pageNo)
    const getExportFilesList = await dispatch(getExportListReducer({ "id": props.form.id, "page": pageNo,...filters }))
    const pendingTask = getExportFilesList.payload.data?.filter(data => data.status === "pending")
    setPendingTasks(pendingTask)
    setExportFiles(getExportFilesList.payload.data)
    setTotalExportFile(getExportFilesList.payload.total)
  }

  const onChangePagination = async (pageNo) => {
    await setExportFilesList(pageNo)
  }

  function findProfileId(data, profileId) {
    for (const item of data) {
        const exportProfiles = item.obj.exportProfiles;
        for (const profile of exportProfiles) {
            if (profile._id === profileId) {
                return item.name;
            }
        }
    }
    return null;
}

  const mainExportFieldSet = async (e) => {
    setMainExportProfile(e.target.value);
  };

  const selectProject = (e,val) => {
    // console.log("selectProject",e,val,selectedProject)
    setSelectedProject(getAllSubForm.find(item => item.id == e))
  };

  const subExportFieldSet = async (e, val) => {
    if(val !== "all"){
      const _generateForm = {...generateForm}
      _generateForm[e.key] = val
      setGenerateForm(_generateForm);
    }
    else{
      const _generateForm = {...generateForm}
      delete _generateForm[e.key]; 
      setGenerateForm(_generateForm);
    }
  };

  const [exportLoadin, setExportLoading] = useState(false);

  const [exportFiles, setExportFiles] = useState([]);

  const [deviders, setDivider] = useState({});

  const changeDevider = async (val, obj) => {
    // console.log(val);
    // console.log(obj);
    if (obj.name) {
      setDivider((prevState) => ({
        ...prevState,
        [obj.name]: val,
      }));
    }
  };

  function groupItemBynested(array, property) {
    var hash = {},
      props = property.split(".");
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  }

  const exportThisFile = async (file) => {
    const currentDate = new Date().toLocaleDateString();
    const devider = deviders[file.name] ? deviders[file.name] : null;

    let sheets = [];
    if (devider) {
      const sheets = file.sheets;
      const headerArray = file.sheets[0];

      let excelPreArra = headerArray?.excelArra ? headerArray.excelArra : [];

      excelPreArra = excelPreArra.filter(
        (es) => es.formProccess.jsonProcessAnswer
      );

      excelPreArra = excelPreArra.filter(
        (es) => es.formProccess.jsonProcessAnswer.data.database.main[devider]
      );

      var keyPath =
        "formProccess.jsonProcessAnswer.data.database.main." + devider;
      // console.log(keyPath);
      const groupByCategory = await groupItemBynested(excelPreArra, keyPath);
      var result = Object.values(groupByCategory);
      // let wb = XLSX.utils.book_new()
      let wb = XLSX.utils.book_new();
      let ss = 0;
      for (const rs of result) {
        ss++;

        let sheetname = "sheet" + ss;
        let temObj = rs[0];
        if (temObj) {
          if (
            temObj?.formProccess?.jsonProcessAnswer?.data?.database?.main[
            devider
            ]
          ) {
            sheetname =
              temObj.formProccess.jsonProcessAnswer.data.database.main[devider];
          }
          // console.log(temObj);
        }

        const excelArra = await createDataSet(rs, selectedMainProfile);
        // console.log(excelArra, "excelArra");

        let dd = XLSX.utils.aoa_to_sheet(excelArra);
        XLSX.utils.book_append_sheet(wb, dd, sheetname, true);
      }
      if (Object.keys(excelName).length > 0) {
        // console.log("excelName", excelName[file.name]);
        let profileName = excelName[file.name];
        XLSX.writeFile(
          wb,
          `${file.name}_${profileName[0].profileName}_Export.xlsx`
        );
      } else {
        XLSX.writeFile(wb, `${file.name}_ALL_Export_${currentDate}.xlsx`);
      }

      // console.log(result);

      //  let deviderIndex=headerArray.findIndex((de)=>de==devider)
      //  console.log(deviderIndex)
      //  const groupByCategory = await groupBy(tempAnswer, devider)
      //  var result = Object.entries(groupByCategory);
    } else {
      if (file?.sheets[0]) {
        // console.log(file);
        sheets = file.sheets[0];
        if (sheets.excelArra) {
          const excelArra = await createDataSet(
            sheets.excelArra,
            selectedMainProfile
          );
          let wb = XLSX.utils.book_new();

          // console.log(excelArra, "excelArra1");

          let dd = XLSX.utils.aoa_to_sheet(excelArra);
          XLSX.utils.book_append_sheet(wb, dd, "sheet", true);
          if (Object.keys(excelName).length > 0) {
            // console.log("excelName", excelName[file.name]);
            let profileName = excelName[file.name];
            XLSX.writeFile(
              wb,
              `${file.name}_${profileName[0].profileName}_Export_${currentDate}.xlsx`
            );
          } else {
            XLSX.writeFile(wb, `${file.name}_ALL_Export_${currentDate}.xlsx`);
          }
        }
      }
    }
  };

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
  
    return true;
  }

  const exportFunction = async () => {
    setExportLoading(true);
    const mainElements = container.schema;
    if(!selectedProject && getAllSubForm.length !== 0){
      notification.error({
        message: "Error",
        description: "Please Select Project",
      });
    }
    else{
      let profile = isEmpty({...generateForm}) ? "all" : {...generateForm}
      if(profile === "all" && getAllSubForm.length !== 0){
        profile = {
          [selectedProject.relatedProject[0].data.database.main.project_name]:"all"
        }
      }
      // console.log("profile",profile,selectedProject)
      const exportAnswer = await generateAnswerListExport(
        props.form.id,
        false,
        props.columns,
        1,
        dataPerPage,
        true,
        profile
      );
      // console.log("exportAnswer.payload.data.export",exportAnswer.payload.data.export)
      if(!exportAnswer.payload.data.export){
        notification.error({
          message: "Error",
          description: "No Data Is Coming...!",
        });
      }
      else{
        notification.success({
          message: "Success",
          description: "File Generated Successfully!",
        });
      }
      await setExportFilesList()
    }
    

    setExportLoading(false);
  };

  const openFilterMenu = () => {
    dispatch(manageFilterDrawer(true));
  };

  const openAnswerMenu = () => {
    dispatch(manageViewAnswerDrawer(true));
  };

  const [formRuleSetElement, setformRuleSetElement] = useState([]);
  const [formCommonField, setformCommonField] = useState([]);

  const [formInformation, setFormInformation] = useState(null);

  const [pageTitle, setPageTitle] = useState(null);
  useEffect(() => {
    // setActiveTaskList()
    setGroupList(props.groupList);
    setPageTitle(
      props?.containerData?.title ? props.containerData.title : null
    );

    setScheemaComponent(props.schema);
    if (props.form) {
      setExportProfile(
        props.form.exportProfiles ? props.form.exportProfiles : []
      );

      setFormInformation(props.form);
      if (props.form.ruleSet) {
        setRuleArray(props.form.ruleSet);
      }
      if (props.form.formRuleSetElement) {
        setformRuleSetElement(props.form.formRuleSetElement);
      }
      if (props.form.formCommonField) {
        setformCommonField(props.form.formCommonField);
      }

      props.form.id =="ed1aaa24-0b43-4e30-8334-f357c8d2a877"? setEnableAttendance(true):setEnableAttendance(false)

      // console.log(props, "propspropsprops");
    }
    setColomn(props.columns);
    setContainer(props.containerData);
    setFormAnswer(props.form.id, false, props.columns);
  }, []);

  useMemo(()=>{
    setColomn(props.columns);
  },[props.columns])

  const setActiveTaskList = async () => {
    const empCode = localStorage.getItem("emCode")
    let activeTasks = await dispatch(getActiveTaskList({
      empCode, payload: {
        "parentForm": props.form.id
      }
    }));
    if (activeTasks.payload.length !== 0) {
      setActiveTask(activeTasks.payload)
    }
    else {
      setActiveTask()
    }
  }

  var schemaList = [
    {
      "lableTxt": "Form Id",
      "keyTxt": "id",
      // "type": "drop",
      // "data": formList
    },
    {
      "lableTxt": "Created By",
      "keyTxt": "createdBy",
      // "type": "drop",
      // "data": formList
    },
    {
      "lableTxt": "Created At",
      "keyTxt": "createdAt",
      "type": "date",
      // "data": formList
    }
  ]

  const onFilter = (values) => {
    // console.log("values", values)
    if (values) {
      const result = values.reduce((acc, current) => {
        acc[current.key] = current.value;
        return acc;
      }, {});

      // console.log("result", result);
      setExportFilterArray(prevState => ({ ...prevState, ...result }))
      setExportFilesList(1,result)
      // setFilters(prevState => ({ ...prevState, ...result }));
      // init(1, 10, false, result)


    }

    // setOpen(false);
  }

  const onClearFilter = () => {
    filterForm?.resetFields()
    setExportFilterArray({})
    setExportFilesList(1,{})
  }



  const resetPlayIcons = async (data) => {
    let answerId = data.id;
    if (answerId) {
      let obj = {
        data: {
          bitrix: {
            main: {},
          },
          database: {
            main: {},
          },
        },
        selectedAnswerId: answerId,
      };
      dispatch(resetTaskProcessReducer({ id: answerId, processingFormId: getProcessFormByProject(columns,data.data.database.main.Project) }))
        .then(() => {
          localStorage.removeItem("activeTask");
          // window.location.reload();
          // setActiveTaskList()
          setFormAnswer(props.form.id, false, columns, selectedPage);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <>
        <div className="page-action-header">
          {(() => {
            if (container) {
              if (container.actions) {
                return (
                  <>
                    <Button
                      danger
                      type="primary"
                      icon={<ControlOutlined />}
                      onClick={openFilterMenu}
                    />

                    {!isOpenExportFilter ?
                      <Filter
                        key="1"
                        setForm={setForm}
                        onFilter={(filterArray) => {
                          filterValue(filterArray);
                        }}
                        onClear={clearFilter}
                      />
                      :
                      <Filter
                      key="2"
                      title={t("Export Filter")}
                      setForm={setFilterForm}
                      onFilter={(filterArray) => {
                        onFilter(filterArray);
                      }}
                      schemaList={schemaList}
                      onClear={onClearFilter}
                    />
                    }
                   

                    <Button
                      onClick={() => clearFilter()}
                    >
                      {t("CLEAR FILTER")}
                    </Button>
                  </>
                );
              }
            }
          })()}
        </div>

        <div className="desktop-div">
          <Grid
            className="page-action-header"
            style={{ padding: 8 }}
            container
            spacing={2}
          >
            <Grid style={{ padding: 8, paddingBottom: 0 }} item xs={12}>
              <Grid container spacing={2}>
                {(() => {
                  if (container) {
                    if (container.actions) {
                      //add permissions here
                      if (container.actions.find((e) => e === "Add") && container?.permision?.add) {
                        return (
                          <>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Button
                                block={true}
                                danger
                                onClick={() => openSubmitForm(true)}
                                icon={<PlusOutlined />}
                              >
                                {t("Add")}
                              </Button>
                            </Grid>
                          </>
                        );
                      }
                    }
                  }
                })()}

                {(() => {
                  if (container) {
                    if (container.actions) {
                      if (container.actions.find((e) => e === "Export") && container?.permision?.export) {
                        return (
                          <>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Button
                                block={true}
                                danger
                                onClick={exportFunctionOpen}
                              >
                                {t("Export")}
                              </Button>
                            </Grid>
                          </>
                        );
                      }
                    }
                  }
                })()}
                 {(() => {
                  if (container) {
                    if (container.actions) {
                      if (container.actions.find((e) => e === "Import") && container?.permision?.import) {
                        return (
                          <>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <label
                                for="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="fa fa-cloud-upload"></i> Import
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => importJson(e)}
                              />
                            </Grid>
                          </>
                        );
                      }
                    }
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="mobile-div">
          {(() => {
            if (container) {
              if (container.actions) {
                if (container.actions.find((e) => e === "Add") && container?.permision?.add) {
                  return (
                    <div className="btn-container-header">
                      <Button
                        danger
                        type="primary"
                        onClick={() => openSubmitForm(true)}
                        icon={<PlusOutlined />}
                      ></Button>
                    </div>
                  );
                }
              }
            }
          })()}

          {(() => {
            if (container) {
              if (container.actions) {
                if (container.actions.find((e) => e === "Export") && container?.permision?.export) {
                  return (
                    <div className="btn-container-header">
                      <Button
                        danger
                        type="primary"
                        onClick={exportFunctionOpen}
                        icon={<ExportOutlined />}
                      ></Button>

                      {/*<label for="file-upload" class="custom-file-upload">*/}
                      {/*  <i class="fa fa-cloud-upload"></i> Import*/}
                      {/*</label>*/}
                      {/*<input id="file-upload" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => importJson(e)} />*/}
                    </div>
                  );
                }
              }
            }
          })()}
        </div>

        {isloading ? (
          <div className="example">
            <Spin />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <div className="mobile-table">
                {allAnswerRow.map((el) => (
                  <GridCard
                    moveTotask={(ta) => {
                      taskProcessModelOpen(ta);
                    }}
                    shouldDisplayIcon={checkActiveTask(el.data)}
                    loggedInUser={loggedInUser}
                    isResponsibleUser={checkResponsibleUser(el?.data?.data?.database?.main?.Responsible_Users)}
                    resetPlayIcons={() => resetPlayIcons(el.data)}
                    dataRow={el}
                    activeTask={hasActiveTask}
                  />
                ))}
              </div>
              <AntTable
                columns={columns}
                dataSource={answers}
                sorter={sortingFunction}
                onRow={(record, index) => ({
                  style: {
                    background: record.activeTask ? "burlywood" : "default"
                  }
                })}
                expandable={{
                  expandedRowRender: (item) => (
                    <>
                      {item.extenalData ? (
                        <div>
                          {
                            // !item.extenalData.rejected?'yes':(parseInt(item.extenalData.rejectedStage) - 1)
                          }

                          {(() => {
                            if (item.extenalData.rejected) {
                              if (
                                answers.find(
                                  (e) => e.element.reference == item.element.id
                                )
                              ) {
                                return (
                                  <div className="status-container-reject">
                                    {t("CLOSED")}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="status-container-reject">
                                    {t("Rejected")}
                                  </div>
                                );
                              }
                            } else {
                              if (item.extenalData.taskStage == "pending") {
                                return (
                                  <div className="status-container-pending">
                                    {t("Pending")}
                                  </div>
                                );
                              } else if (
                                item.extenalData.taskStage == "inprogress"
                              ) {
                                return (
                                  <div className="status-container-inprogress">
                                    {t("In Progress")}
                                  </div>
                                );
                              } else if (
                                item.extenalData.taskStage == "Completed"
                              ) {
                                return (
                                  <div className="status-container-completed">
                                    {t("Completed")}
                                  </div>
                                );
                              }
                            }
                          })()}
                          <Steps
                            direction="vertical"
                            className="work-flow-step"
                            size="small"
                            current={
                              !item.extenalData.rejected
                                ? parseInt(item.extenalData.currentStage) - 1
                                : parseInt(item.extenalData.rejectedStage) - 1
                            }
                          >
                            {item.extenalData.dataTab.approvalFlow.map(
                              (stage) => (
                                <Step
                                  description={
                                    <>
                                      {stage.reason ? (
                                        <>NOTE : {stage.reason}</>
                                      ) : null}
                                      <div>Responsible Users</div>
                                      <List
                                        size="small"
                                        bordered
                                        dataSource={stage.userData}
                                        renderItem={(item) => (
                                          <List.Item>
                                            {item?.userBit?.EMAIL
                                              ? item.userBit.EMAIL
                                              : "-"}
                                            {stage?.status !== "pending" ? (
                                              <>
                                                {
                                                  item.userBit.UF_USR_1642854306150 == stage?.changedBy?.UF_USR_1642854306150 ? stage.status === "Reject" ? <CloseCircleOutlined style={{ color: "red" }} /> : <CheckCircleTwoTone twoToneColor="#52c41a" /> : null
                                                }
                                              </>
                                            ) : null}
                                          </List.Item>
                                        )}
                                      />
                                    </>
                                  }
                                  title={
                                    stage?.status === "pending"
                                      ? "PENDING"
                                      : stage?.changedBy?.NAME
                                        ? stage.changedBy.NAME
                                        : "UNKNOWN"
                                  }
                                  icon={
                                    <Avatar
                                      className={` ${stage.status} ${stage.isCurrentUser && !item.rejected
                                        ? "current-user"
                                        : null
                                        }`}
                                      src={
                                        stage?.userData?.userBit?.PERSONAL_PHOTO
                                          ? stage.userData.userBit
                                            .PERSONAL_PHOTO
                                          : null
                                      }
                                    />
                                  }
                                />
                              )
                            )}
                          </Steps>
                        </div>
                      ) : null}
                    </>
                  ),

                  rowExpandable: (record) => record.extenalData,
                  columnWidth: "5%",
                }}
                pagination={false}
                className="ant-border-space"
              />
              <div className="pagination-container">
                <Pagination
                  onChange={(page, pageSize) => {
                    changePagination(page, pageSize);
                  }}
                  // defaultCurrent={selectedPage}
                  current={selectedPage}
                  total={dataCount}
                />
              </div>
            </div>
          </>
        )}

        <Dialog
          fullScreen
          open={isOpenAnswerView}
          onClose={handleCloseUserView}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseUserView}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {t(selectedData.id)}
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={handleCloseUserView}
              >
                {t("Close")}
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent className="full-screen-form-container-view">
            <FormViewInterface
              elementGroup={groupList}
              formInformation={formInformation}
              jsonSchema={schemaComponent}
              id={selectedData.data}
              formDetails={selectedData}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={answerViewTask}
          onClose={() => {
            openAnswerViewTask(false);
          }}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  openAnswerViewTask(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {t(selectedData.id)}
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  openAnswerViewTask(false);
                }}
              >
                {t("Close")}
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent className="full-screen-form-container-view">
            <FormViewInterface
              elementGroup={
                selectedAnswerProcess?.proccessFormGroups
                  ? selectedAnswerProcess.proccessFormGroups
                  : []
              }
              jsonSchema={
                selectedAnswerProcess?.proccessFormScheema
                  ? selectedAnswerProcess.proccessFormScheema
                  : []
              }
              id={
                selectedAnswerProcess?.jsonProcessAnswer?.data
                  ? selectedAnswerProcess.jsonProcessAnswer.data
                  : null
              }
              formDetails={
                selectedAnswerProcess?.proccessForm
                  ? selectedAnswerProcess.proccessForm
                  : null
              }
            />
          </DialogContent>
        </Dialog>

        <Modal
          visible={isOpenAnswerEdit}
          title={`EDIT ${pageTitle ? pageTitle : null}`}
          width={1420}
          onOk={handleCloseUserEdit}
          onCancel={handleCloseUserEdit}
          footer={[
            <Button danger key="back" onClick={handleCloseUserEdit}>
              {t("Return")}
            </Button>,
            <Button danger key="back" onClick={formDraftEdit}>
              {t("Draft")}
            </Button>,
            <Button
              danger
              key="submit"
              type="primary"
              onClick={() => {
                formSubmitEdit();
              }}
            >
              {t("Submit")}
            </Button>,
          ]}
        >
          <FormInterfaceDynamicEdit
            normalView={true}
            jsonSchema={schemaComponent}
            elementGroup={groupList}
            data={selectedData}
            formInformation={formInformation}
            formDraftEditTimeLine={formDraftEditTimeLine}
            formDetails={props.form.id}
            changeDataTrigger={(data) => changeDataTriggerEdit(data)}
          />
        </Modal>

        <Modal
          visible={isOpenAnswerDuplicate}
          title={`DUPLICATE ${pageTitle ? pageTitle : null}`}
          width={1420}
          onOk={handleCloseUserduplicate}
          onCancel={handleCloseUserduplicate}
          footer={[
            <Button danger key="back" onClick={handleCloseUserduplicate}>
              {t("Return")}
            </Button>,
            <Button
              disabled={addbtnDisable}
              danger
              key="submit"
              type="primary"
              onClick={() => {
                formDupicate();
              }}
            >
              {t("DUPLICATE")}
            </Button>,
          ]}
        >
          <FormInterfaceDynamicEdit
            jsonSchema={schemaComponent}
            elementGroup={groupList}
            data={selectedData}
            formInformation={formInformation}
            formDraftEditTimeLine={formDraftEditTimeLine}
            formDetails={props.form.id}
            changeDataTrigger={(data, err) => changeDataTrigger(data, err)}
          />
        </Modal>

        <Dialog
          open={isDeleteViewShow}
          onClose={() => {
            setDeletedView(false);
          }}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {t("Remove Item")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("Are you sure! item will be remove from the list")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setDeletedView(false);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                deleteItem();
              }}
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          visible={isSubmitFormOpen}
          title={`ADD ${pageTitle ? pageTitle : null}`}
          width={1420}
          onOk={() => {
            openSubmitForm(false);
          }}
          onCancel={() => {
            openSubmitForm(false);
          }}
          footer={[
            <Button
              danger
              key="back"
              onClick={() => {
                openSubmitForm(false);
              }}
            >
              {t("Return")}
            </Button>,
            <Button
              danger
              key="back"
              onClick={() => {
                draftForm();
              }}
            >
              {t("Draft")}
            </Button>,
            <Button
              disabled={addbtnDisable}
              danger
              key="submit"
              type="primary"
              onClick={() => {
                formSubmit();
              }}
            >
              {t("Submit")}
            </Button>,
          ]}
        >
          <div style={{ marginBottom: 10 }}>
            {errorList ? (
              <>
                {errorList.length != 0 ? (
                  <>
                    {errorList.map((row) => (
                      <>
                        <Alert
                          message={row.message ? row.message : "-"}
                          type="error"
                          showIcon
                        />
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}

            {warningList ? (
              <>
                {warningList.length != 0 ? (
                  <>
                    {warningList.map((row) => (
                      <>
                        <Alert
                          message={row.message ? row.message : "-"}
                          type="info"
                          showIcon
                        />
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <FormInterfaceDynamic
            elementGroup={groupList}
            jsonSchema={schemaComponent}
            id={selectedData.data}
            formDetails={selectedData}
            draftFormStart={draftFormStart}
            formInformation={formInformation}
            // handleClose={()=>{openSubmitForm(false)}}
            // refreshPage={()=>{openSubmitForm(false)}}
            changeDataTrigger={(data, err) => changeDataTrigger(data, err)}
            formRuleSetElement={formRuleSetElement}
            formCommonField={formCommonField}
          />
        </Modal>

         {/* start attendance view */}

        
          
          <AttandaceView isOpen={attendanceView} obj={attendanceViewData} clossModel={()=>handleCloseAttendance()} />


        {/* end attendance view  */}
        <Modal
          visible={isTaskProcessOpen}
          title={`ADD ${pageTitle ? pageTitle : null}`}
          width={1000}
          onOk={() => {
            taskProcessModelClose(false);
          }}
          onCancel={() => {
            taskProcessModelClose(false);
          }}
          footer={[
            <Button
              danger
              key="back"
              onClick={() => {
                taskProcessModelClose(false);
              }}
            >
              {t("Return")}
            </Button>,
            <Button
              danger
              key="back"
              onClick={() => {
                draftForm();
              }}
            >
              {t("Draft")}
            </Button>,
            <Button
              disabled={addbtnDisable}
              danger
              key="submit"
              type="primary"
              onClick={() => {
                saveFormTaskProcess();
              }}
            >
              {t("Submit")}
            </Button>,
          ]}
        >
          {jsonProcessAnswer ? (
            <FormInterfaceDynamicEdit
              jsonSchema={formSchemaTask}
              elementGroup={formGroupTask}
              data={jsonProcessAnswer ? jsonProcessAnswer : null}
              formDetails={props.form.id}
              formDraftEditTimeLine={formDraftEditTimeLine}
              changeDataTrigger={(data, err) =>
                changeDataTriggerTask(data, err)
              }
            />
          ) : (
            <FormInterfaceDynamic
              elementGroup={formGroupTask}
              jsonSchema={formSchemaTask}
              id={formDataSetTask}
              formDetails={formTask}
              // handleClose={()=>{openSubmitForm(false)}}
              // refreshPage={()=>{openSubmitForm(false)}}
              changeDataTrigger={(data, err) =>
                changeDataTriggerTask(data, err)
              }
            />
          )}
        </Modal>

        <Modal
          open={isOpenExportFilter}
          title={t("Export")}
          onOk={() => {
            setOpenFilter(false);
          }}
          onCancel={() => {
            setOpenFilter(false);
          }}
          footer={[
            <Button
              danger
              key="back"
              onClick={() => {
                setOpenFilter(false);
              }}
            >
              Return
            </Button>,

          ]}
        >
          <div>
            {loadingSub ? (
              <>
                <div style={{ width: "100%" }}>
                  <Spin />
                </div>
              </>
            ) : (
              <>
                <Collapse>
                  <Collapse.Panel header={t("Export Options")} key={'0'}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                      <div style={{ width: "40%" }}>MAIN FORM</div>
                      <div style={{ width: '100%' }}>
                        <Select
                          style={{ width: '100%' }}
                          showSearch
                          onChange={mainExportFieldSet}
                          defaultValue="all"
                        >
                          <Option value={"all"}> ALL</Option>
                          {exportProfiles.map((s) => (
                            <Option value={s._id}> {s.profileName}</Option>

                          ))}
                        </Select>
                      </div>
                    </div>
                      {getAllSubForm.length !== 0 &&
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                          <div style={{ width: "40%" }}>Project</div>
                          <div style={{ width: '100%' }}>
                            <Select
                              style={{ width: '100%' }}
                              showSearch
                              onChange={selectProject}
                            // defaultValue="all"
                            >
                              {/* <Option value={"all"}> ALL</Option> */}
                              {getAllSubForm.map((s) => (
                                <Option key={s.name} value={s.id}> {s.relatedProject[0].data.database.main.project_name}</Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      }
                    <div>
                      {selectedProject && (
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                          <div style={{ width: "40%" }}>{selectedProject.relatedProject[0].data.database.main.project_name}</div>

                          {/* <div>Divider</div>
                      <div>
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="selectDevider"
                          allowClear
                          onChange={(esss) => changeDevider(esss, e)}
                        >
                          {e.schema.map((row, index) => (
                            <Option value={row.keyTxt}>{row.keyTxt}</Option>
                          ))}
                        </Select>
                      </div> */}
                          
                            <div style={{ width: '100%' }}>
                              <Select
                                key={selectedProject.id}
                                style={{ width: '100%' }}
                                showSearch
                                onChange={(val, option) => {
                                  subExportFieldSet(option, val);
                                }}
                                defaultValue="all"
                              >
                                <Option key={selectedProject.relatedProject[0].data.database.main.project_name} value={"all"}> ALL</Option>
                                {selectedProject.exportedProfiles.map((s) => (
                                  <Option key={selectedProject.relatedProject[0].data.database.main.project_name} value={s._id}> {s.profileName}</Option>
                                ))}
                              </Select>
                            </div>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        loading={exportLoadin}
                        disabled={exportLoadin}
                        danger
                        key="submit"
                        type="primary"
                        onClick={exportFunction}
                      >
                        EXPORT
                      </Button>
                    </div>

                  </Collapse.Panel>
                </Collapse>


                {exportFiles.length != 0 ? (
                  <>
                    <div className="export-file-list">
                      <h4>{t("File List")}</h4>
                      <div style={{ display: "flex",marginBottom:5 }}>
                        <Search placeholder="search" style={{ width: "unset" }} onSearch={() => { }} enterButton />
                        <>
                          <Button
                            danger
                            type="primary"
                            icon={<ControlOutlined />}
                            onClick={openFilterMenu}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                          <Button
                            onClick={() => onClearFilter()}
                          >
                            {t("CLEAR FILTER")}
                          </Button>
                        </>
                      </div>

                      <div style={{ marginBottom: 5, height: "40vh", overflow: "scroll" }}>
                        <table>
                          <tr>
                            <th style={{ width: "15%" }}>Form</th>
                            <th style={{ width: "15%" }}>Profile</th>
                            <th style={{ width: "15%" }}>Created By</th>
                            <th style={{ width: "25%" }}>Created At</th>
                            <th style={{ width: "15%" }}>Status</th>
                            <th style={{ width: "50%" }}>Download</th>
                          </tr>

                          {exportFiles.map((ef) => (
                            <tr>
                              <td>{ef.formData?.name}</td>
                              <td>{ef.profileName}</td>
                              <td>{ef.createdBy}<Popover
                                content={
                                  <>
                                    {(() => {
                                      const objTemp = ef
                                      if (objTemp) {
                                        if (objTemp) {
                                          return (
                                            <>
                                              {Object.keys(objTemp).map(
                                                (objKey) => {
                                                  if(objTemp[objKey] && typeof objTemp[objKey] === "object"){
                                                    return Object.keys(objTemp[objKey]).map((subObjKey) => {
                                                      return (
                                                        <>
                                                          <div>
                                                            {subObjKey}-
                                                            {objTemp[objKey][subObjKey]
                                                              ? objTemp[
                                                                objKey
                                                              ][subObjKey].toString()
                                                              : null}
                                                          </div>
                                                        </>
                                                      )
                                                    })
                                                  }
                                                }
                                              )}
                                            </>
                                          );
                                        } else {
                                          <>empty</>;
                                        }
                                      } else {
                                        return <>empty</>;
                                      }
                                    })()}
                                  </>
                                }
                                title=""
                                trigger="focus"
                              >
                                <Button
                                  type="text"
                                  shape="circle"
                                  icon={<QuestionCircleOutlined />}
                                />
                              </Popover></td>
                              <td>{moment(ef.createdAt).utcOffset(420).format("MMMM Do, YYYY hh:mm A")}</td>
                              <td>{ef.status}</td>
                              <td>
                                {" "}
                                <Button
                                  disabled={!ef.isComplete}
                                  onClick={() => {
                                    // exportThisFile(ef);
                                    window.location.href = ef.url

                                  }}
                                  style={{
                                    width: "100%"
                                  }}
                                // style={{
                                //   width: "95px",
                                //   overflow: "hidden",
                                //   whiteSpace: "nowrap",
                                //   display: "block",
                                //   text-overflow: "ellipsis"
                                // }}

                                >
                                  <span style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                    textOverflow: "ellipsis",
                                    textAlign: "start"
                                  }}>
                                    {ef.reportName ? ef.reportName : "-"}
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>

                      <div style={{ marginTop: 5 }} className="pagination-container">
                        <Pagination
                          onChange={(page, pageSize) => {
                            onChangePagination(page);
                          }}
                          // defaultCurrent={selectedPage}
                          current={selectedPageExport}
                          total={totalExportFile}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </Modal>
      </>

      <ViewAnswerDrawer />

      <MoreOption
        handleMenuClick={(e) => {
          handleMenuClick(e);
        }}
        permission={container?.permision}
      />
    </>
  );
};