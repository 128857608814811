import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import axios from "axios";
import { apiConfig } from "./config";


const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      "Languages": "Languages"
    },
  },
  th: {
    translation: {
      "Languages": "Languages Thai"
    }
  }
};
let array = []
function updateValue(key) {

  if (!array.find((e) => e === key)) {

    array.push(key)
    let endPoint = `${apiConfig.adminApi}/language/add-language-bundle/`;

   // axios.post(endPoint, { 'value': key });
  }



}

function insertMissingValue() {

  let endPoint = `${apiConfig.adminApi}/language/add-language-bundle/`;

  axios.post(endPoint, array);

}


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: "th",
    fallbackLng: "en",
    saveMissing: true,
    updateMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
      updateValue(key)

    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n;

