import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig,version } from "../../config";

let initialState = {
    sideMenuItems: [
        {
            title: 'Main',
            key: 'main',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Dashboard',
                    key: 'dashboard',
                    icon: 'fas fa-chart-line',
                    to: '/dashboard',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'WorkFlow',
                    key: 'workflow',
                    icon: 'fas fa-chart-line',
                    to: '/workflow',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'EMPLOYEE',
            key: 'employee',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Users',
                    key: 'users',
                    icon: 'fas fa-users',
                    to: '/users',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Attendance',
                    key: 'Attendance',
                    icon: 'fas fa-users',
                    to: '/attendance',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Attandance List',
                    key: 'AttendanceList',
                    icon: 'fas fa-users',
                    to: '/attendance-list',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'SETTINGS',
            key: 'settings',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Form',
                    key: 'form',
                    icon: 'fa fa-list',
                    to: '/manage-form',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Component',
                    key: 'component',
                    icon: 'far fa-folder',
                    to: '/manage-component',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Language',
                    key: 'lanaguage',
                    icon: 'fas fa-globe',
                    to: '/language',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Configuration',
                    key: 'configuration',
                    icon: 'fa fa-cogs',
                    to: '/app-config',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'SideBar',
                    key: 'sidebase',
                    icon: 'fa fa-list',
                    to: '/side-bar-config',
                    editable: false,
                    hide: false,
                },
                {
                    title: 'Permission',
                    key: 'permission',
                    icon: 'fa fa-list',
                    to: '/role',
                    editable: false,
                    hide: false,
                }
            ]
        },
        {
            title: 'ACCOUNT PAGES',
            key: 'account_page',
            editable: false,
            hide: false,
            items: [
                {
                    title: 'Profile',
                    key: 'profile',
                    icon: 'far fa-address-card',
                    to: '/profile',
                    editable: false,
                    hide: false,
                }
            ]
        }
    ],
    permission: [],
    isLoading:false,
    configuration:{},
    version:version.appVersion,
    applicationList:[]


};





export const getAppSetting = createAsyncThunk(
    "config/getAppSetting",
    async () => {
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/setting/get-settings/`;

        const response = await axios.get(endPoint);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);

export const getSiteBarHeader = createAsyncThunk(
    "config/getSiteBarHeader",
    async () => {
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/setting/get-site-bar-header/`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);

export const manageSideBarHeader = createAsyncThunk(
    "config/manageSideBarHeader",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/add-side-bar-header/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);

export const addSideBarItem = createAsyncThunk(
    "config/addSideBarItem",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/add-side-bar-item/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);

export const deleteSideBarItem = createAsyncThunk(
    "config/deleteSideBarItem",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/delete-side-bar-item/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);




export const editSideBarItem = createAsyncThunk(
    "config/editSideBarItem",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/edit-side-bar-item/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);






export const editSideBarHeader = createAsyncThunk(
    "config/editSideBarHeader",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/edit-side-bar-header/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);

export const deleteSideBarHeader = createAsyncThunk(
    "config/deleteSideBarHeader",
    async (data) => {

        let endPoint = `${apiConfig.adminApi}/setting/delete-side-bar-header/`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, data, config);


        if (response?.data) {
            return response?.data;
        } else {
            throw Error;
        }
    }
);










export const updateSetting = createAsyncThunk(
    "config/updateSetting",


    async (code) => {

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        let endPoint = `${apiConfig.adminApi}/setting/update-settings/`;

        const response = await axios.post(endPoint, code, config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);




export const settingSlice = createSlice({
    name: "config",
    initialState,
    extraReducers: {

        [manageSideBarHeader.fulfilled]: (state, action) => {

            return {
                state,
                sideMenuItems: [...state.sideMenuItems, action.payload.data]
            };
        },
        [editSideBarHeader.fulfilled]: (state, action) => {

            const headerVal = action.payload.data



            return {
                ...state,
                sideMenuItems: state.sideMenuItems.map(data => {
                    if (data.key === headerVal.key) {
                        return headerVal;
                    }
                    else {
                        return data;
                    }
                }
                )
            }
        },
        [deleteSideBarHeader.fulfilled]: (state, action) => {

            const headerVal = action.payload.data



            const array = current(state.sideMenuItems)

            const index = array.findIndex((e) => e.key == headerVal)

            return {
                ...state,
                sideMenuItems: state.sideMenuItems.filter((e) => e.key != headerVal)

            }
        },
        [addSideBarItem.fulfilled]: (state, action) => {

            const headerVal = action.payload.data



            return {
                ...state,
                sideMenuItems: state.sideMenuItems.map((data) => {
                    if (data.key === headerVal.parentkey) {

                        if (data.items) {
                            return {
                                ...data,
                                items: [...data.items, headerVal]
                            }
                        } else {
                            return {
                                ...data,
                                items: [headerVal]
                            }
                        }



                    }
                    else {

                        return data;
                    }
                }
                )
            }
        },
        [deleteSideBarItem.fulfilled]: (state, action) => {

            const headerVal = action.payload.data

            const parentKey = action.payload.parentKey



            return {
                ...state,
                sideMenuItems: state.sideMenuItems.map((data) => {
                    if (data.key === parentKey) {


                        return {
                            ...data,
                            items: data.items.filter((e) => e.id != headerVal)
                        }

                    }
                    else {

                        return data;
                    }
                }
                )
            }
        },
        [editSideBarItem.fulfilled]: (state, action) => {

            const headerVal = action.payload.data



            return {
                ...state,
                sideMenuItems: state.sideMenuItems.map((data) => {
                    if (data.key === headerVal.parentkey) {


                        return {
                            ...data,
                            items: data.items.map((el) => {
                                if (el.id === headerVal.id) {
                                    return headerVal;
                                }
                                else {
                                    return el;
                                }
                            })
                        }

                    }
                    else {

                        return data;
                    }
                }
                )
            }
        },
        [getSiteBarHeader.pending]: (state, action) => {
            //
           

            return {
                ...state,
                isLoading:true
            }
        },
        [getSiteBarHeader.fulfilled]: (state, action) => {
            //
            const dynHeader = action.payload.data

            let initSt = initialState.sideMenuItems.concat(dynHeader)
            const chileds = action.payload.list
            const headerPermision = action.payload.permision.headerPermision
            const applicationList = action?.payload?.applicationList?action.payload.applicationList:[]
            const headerPermisionList = action.payload.permision.lostVisibilityArrat
            const configuration = action?.payload?.config?action.payload.config:{}
            const dashboard =action?.payload?.dashboard?action.payload.dashboard:{}

             
            for (let index = 0; index < initSt.length; ++index) {
                const header = initSt[index];
                const filteredChild = chileds.filter((e) => e.parentkey === header.key)
               
                initSt[index]={
                    ...initSt[index],
                    hasAccess:headerPermision.find((e)=>e.value && e.key==header.key)?true:false
                }
                if (header.items) {
                    let tempArray = initSt[index].items

                    for (const fill of filteredChild) {

                        initSt[index] = {
                            ...initSt[index],
                            items: [...initSt[index].items, fill]
                        }



                    }



                } else {
                    initSt[index].items = [
                        ...filteredChild
                    ]


                }

            }

            const datapackage = {
                ...state,
                isLoading:false,

                sideMenuItems: initSt.map((data) => {

                    return {
                        ...data,
                        items:data.items.map((itemData)=>{
                            
                            return {
                                ...itemData,
                                hasAccess:headerPermisionList.find((e) => e.value && e.key == itemData.key) ? true : false
                            }
                        })
                    };

                }
                ),
                permission:headerPermisionList,
                configuration:configuration,
                dashboard:dashboard,
                applicationList:applicationList

            }

            return datapackage
        },


    },
});

export default settingSlice.reducer;


