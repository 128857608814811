import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/users"
import languageReducer from "./slices/language"
import formSettingsReducer from "./slices/formSettings"
import settingReducer from "./slices/configSetting"
import dynamicPageReducer from "./slices/dynamicPage"
import workFlow from "./slices/workflow"
import attandance from "./slices/attandance/attandanceSetting"
import markAttandance from "./slices/attandance/markAttandanceSetting"
import ruleForm from "./slices/formRuleSetting"
import ruleFormElement from "./slices/ruleElement"
import permisionReducer from "./slices/permissionSetting"
import shopReducer from "./slices/shopSeting"
import errorReducer from "./slices/errorSetting"
import sideReducer from "./slices/sideNevSetting"
import internalExportSetting from "./slices/internalExportSetting"
import filterSetting from "./slices/filter/filterSetting"
import answerSetting from "./slices/answer/answerSetting"
import elementReducer from "./slices/element/elementReducer"
export const store = configureStore({
  reducer: {
    user: userReducer,
    language: languageReducer,
    formSettings: formSettingsReducer,
    setting:settingReducer,
    page:dynamicPageReducer,
    workFlow:workFlow,
    permision:permisionReducer,
    shops:shopReducer,
    attandance:attandance,
    markAttandance:markAttandance,
    ruleForm:ruleForm,
    errorReducer:errorReducer,
    ruleFormElement:ruleFormElement,
    exportInternalState: internalExportSetting,
    sideNev:sideReducer,
    filter:filterSetting,
    answer:answerSetting,
    element:elementReducer
  },
});
