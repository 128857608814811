import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { getFetchUsers, updateBulkUserService, getFetchUsersWithoutPaginate } from "../../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  Card,
  Typography,
} from "@mui/material";

import {
  Button as AntButton,
} from "antd";

import { ManageUser } from "../settings/JsonForm/ManageUser"

import { ViewUser } from "./ViewUser"
//
import { getFormSchema, getFormId, getAllGroupRaducer } from "../../redux/slices/formSettings"
import {
  getFormAnswerList,
  deleteSelectedAnswer,
} from "../../redux/slices/formSettings";
import InputLabel from '@mui/material/InputLabel';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';

import * as XLSX from 'xlsx';



import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {

  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);

  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

// .sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function CustomPaginationActionsTable() {
  const { t } = useTranslation();
  let { usersFetchLoading, usersFetchData } = useSelector(
    (state) => state.user
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [openUserManageModel, setOpenUserManageModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isUpdatingBulkUser, updatingBulkUser] = useState(false)
  const [schemaComponent, setScheemaComponent] = React.useState([]);
  const [templateSelectPopup, setTemplateSelecetPopup] = useState(false)


  const [openExportDialog, setOpenExportDialog] = useState(false);

  const [isOpenUserView, openUserView] = useState(false);

  const [groupList, setGroupList] = useState([]);

  const [selectedFormId, setSelectedFormId] = useState(null);

  const [BulkUserEditList, setBulkUserEdit] = useState({})


  const [checkedUsers, setCheckedUsers] = useState([])

  const dispatch = useDispatch();

  const getFormSchimaToDom = async () => {

    const res = await dispatch(getFormSchema())
    // setScheemaComponent(res.payload)


  }


  const handleClickOpen = async (user, formId) => {
    setSelectedUser(user);
    setOpenUserManageModel(true);
  };

  const viewEmployeeData = async (user, formId) => {
    setSelectedUser(user);
    openUserView(true);
  };


  const handleCloseTemplateSelect = () => {
    setTemplateSelecetPopup(false)
  };

  const handleOpenTemplateSelect = () => {
    setTemplateSelecetPopup(true)
  };




  const onChangeLastName = (e) => {
    setSelectedUser((prevState) => {
      return ({
        ...prevState,
        LAST_NAME: e.target.value,
      });
    });
  }
  const onChangeMobile = (e) => {
    setSelectedUser((prevState) => {
      return ({
        ...prevState,
        PERSONAL_MOBILE: e.target.value,
      });
    });
  }
  const onChangeCity = (e) => {
    setSelectedUser((prevState) => {
      return ({
        ...prevState,
        PERSONAL_CITY: e.target.value,
      });
    });
  }
  const onChangePosition = (e) => {
    setSelectedUser((prevState) => {
      return ({
        ...prevState,
        WORK_POSITION: e.target.value,
      });
    });
  }

  const handleClose = () => {
    setOpenUserManageModel(false);
  };

  const handleCloseUserView = () => {

    openUserView(false);
    fetchAllUsers();
  };



  const fetchAllUsersWithoutPaginate = async () => {
    try {

      let requestUsers = await dispatch(getFetchUsersWithoutPaginate());

      if (requestUsers?.payload?.result?.length > 0) {

        return requestUsers.payload.result
      } else {
        return []
      }
    } catch (e) {
      alert('failed...')

    }
  }

  const fetchAllUsers = async () => {
    try {
      let tempFilters = {
        nameFilter,
        lastNameFilter,
        emailFilter,
        cityFilter,
        page,
        statusFilter
      };
      let requestUsers = await dispatch(getFetchUsers(tempFilters));

      if (requestUsers?.payload?.result?.length > 0) {

      } else if (
        requestUsers?.payload == undefined ||
        requestUsers?.payload == null
      ) {
        alert('failed...')
      }
    } catch (e) {

      alert('failed...')
    }
  };

  const getAllGroup = async () => {

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');
    const res = await dispatch(getAllGroupRaducer(myParam))

    if (res.payload) {
      setGroupList(res.payload)
    }



  }

  const getFormData = async () => {


    const formData = await dispatch(getFormId('userEdit'));
    if (formData) {
      if (formData.payload) {
        if (formData.payload.length != 0) {



          setSelectedFormId(formData.payload[0].id)
          const formSchema = await dispatch(getFormSchema(formData.payload[0].id));
          const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));

          if (gg.payload) {
            setGroupList(gg.payload)
          }

          if (formSchema.payload) {
            setScheemaComponent(formSchema.payload)
          }
        }


      }
    }



  }




  useEffect(() => {
    fetchAllUsers();
    getFormSchimaToDom()
    getAllGroup();
    getFormData();
  }, []);

  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data)

    const workSheed = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData = XLSX.utils.sheet_to_json(workSheed)

    const temparray = []

    for (const el of jsonData) {
      if (el.ID) {
        temparray.push(el)
      }
    }
     
    setBulkUserEdit({ data: temparray });
  }


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - usersFetchData?.result?.length)
      : 0;

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      fetchAllUsers();
    } catch (e) {

      alert('failed...')
    }
  };

  const submitBulkEdit = async () => {
    updatingBulkUser(true)
    try {

      let res = await dispatch(updateBulkUserService(BulkUserEditList));

      alert('updated...')

      fetchAllUsers()
      updatingBulkUser(false)
      setBulkUserEdit({})
      setOpenExportDialog(false)

    } catch (e) {
      updatingBulkUser(false)
      setLoading(false)
      alert('error...')
    }
  }

  const createExchelTemplate = async (type) => {

    const formData = await dispatch(getFormSchema(selectedFormId));

    const tempCheckUsers = checkedUsers




    const departmentDataPayload = await dispatch(getFormAnswerList({
      formData: 'eea90583-a3a4-43e7-b2db-ea63e9c85c1a'
    }));


    const divDataPayload = await dispatch(getFormAnswerList({
      formData: 'f41871c7-d329-43ea-946a-28581b84c8dd'
    }));


    const userGroupPayload = await dispatch(getFormAnswerList({
      formData: '6c6b666c-d6ba-4aee-9b17-ae4dbb9919d3'
    }));

     


    const departmentData = departmentDataPayload.payload.data.data
    const divData = divDataPayload.payload.data.data
    const jobGrade = userGroupPayload.payload.data.data


    let obj = { ID: '' }

    let excelArra = []
    let headerArray = ['ID']
    let depHeaderArra = [['Department Name']]
    let divHeaderArra = [['Division Name']]

    let userGroups = [['User Group Name']]



    for (const element of formData.payload) {


      headerArray.push(element.keyTxt)


    }


    for (const element of departmentData) {

      let row = []
      if (element?.data?.database?.main?.dep_name) {
        row.push(element.data.database.main.dep_name)
        depHeaderArra.push(row)
      }
    }

    for (const element of divData) {

      let row = []
      if (element?.data?.database?.main?.div_name) {
        row.push(element.data.database.main.div_name)
        divHeaderArra.push(row)
      }
    }

    for (const element of jobGrade) {

      let row = []
      if (element?.data?.database?.main?.job_grade_name) {
        row.push(element.data.database.main.job_grade_name)
        userGroups.push(row)
      }
    }


    excelArra.push(headerArray)

    if (type === 's') {
      const tempUsers = usersFetchData?.result?.length > 0
        ? usersFetchData?.result : []




      for (const userId of tempCheckUsers) {

        // const tempUserDB = await dispatch(getUserById(userId))
        //   
        let userArray = []
        const userD = tempUsers.find((uId) => userId == uId.bitrix.ID)
        for (const headerKey of headerArray) {
          if (userD.bitrix[headerKey]) {

            const val = userD.bitrix[headerKey] ? userD.bitrix[headerKey] : null
            userArray.push(val)
          } else if (userD.database[headerKey]) {

            const val = userD.database[headerKey] ? userD.database[headerKey] : null
            userArray.push(val)
          } else {

            userArray.push(null)
          }

        }
        excelArra.push(userArray)
        //array.push(element.keyTxt)


      }
    }

    if (type === 'a') {
      const tempUsers = await fetchAllUsersWithoutPaginate()
      // const dnUser = await dispatch(getBitrixAllDbFields())



      for (const userId of tempUsers) {

        let userArray = []
        for (const headerKey of headerArray) {
          if (userId.bitrix[headerKey]) {

            const val = userId.bitrix[headerKey] ? userId.bitrix[headerKey] : null
            userArray.push(val)
          } else if (userId.database[headerKey]) {

            const val = userId.database[headerKey] ? userId.database[headerKey] : null
            userArray.push(val)
          } else {

            userArray.push(null)
          }

        }
        excelArra.push(userArray)
        //array.push(element.keyTxt)


      }

    }



     

     
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelArra),
      wsDep = XLSX.utils.aoa_to_sheet(depHeaderArra),
      wsDiv = XLSX.utils.aoa_to_sheet(divHeaderArra),
      wsUserGroup = XLSX.utils.aoa_to_sheet(userGroups)





    XLSX.utils.book_append_sheet(wb, ws, 'my');
    XLSX.utils.book_append_sheet(wb, wsDep, 'department');
    XLSX.utils.book_append_sheet(wb, wsDiv, 'division');
    XLSX.utils.book_append_sheet(wb, wsUserGroup, 'user group');
    //  XLSX.utils.book_append_sheet(wbDep, wsDep, 'department');

    XLSX.writeFile(wb, 'template.xlsx')


  }

  const checkThisUser = (e) => {
    let tempCheckUsers = checkedUsers
    const uIndex = tempCheckUsers.findIndex((ind) => ind == e)

    if (uIndex > 0) {
      tempCheckUsers.splice(uIndex, 1);
    } else {
      tempCheckUsers.push(e)


    }
    setCheckedUsers(tempCheckUsers)
  }
  const editUserProcces = async (e) => {

    setLoading(true)


    if (e) {



      setLoading(false)
      setSelectedUser({})
      handleClose()
      fetchAllUsers()
    } else {
      setLoading(false)

      alert('error...')
    }






  }

  return (
    <>
      {" "}


      <Dialog
        fullScreen
        open={openUserManageModel}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className='full-screen-dialog-body'>
          <ManageUser elementGroup={groupList} jsonSchema={schemaComponent} id={selectedUser} onSubmit={(e) => { editUserProcces(e) }} handleClose={handleClose} />
        </div>


      </Dialog>



      <Dialog onClose={handleCloseTemplateSelect} open={templateSelectPopup}>
        <DialogTitle>Select Export Type</DialogTitle>
        <div className='list-btn'>
          <Button onClick={() => createExchelTemplate('e')} variant="contained" size="large">
            {t('Empty Template')}
          </Button>
        </div>
        <div className='list-btn'>
          <Button onClick={() => createExchelTemplate('s')} variant="contained" size="large">
            {t('Template with selected user(s)')}
          </Button>
        </div>
        <div className='list-btn'>
          <Button onClick={() => createExchelTemplate('a')} variant="contained" size="large">
            {t('Template with all user(s)')}
          </Button>
        </div>




      </Dialog>

      <Dialog
        fullScreen
        open={isOpenUserView}
        onClose={handleCloseUserView}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseUserView}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('User Details')}
            </Typography>
            <Button variant="contained" color="success" onClick={handleCloseUserView}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent className='full-screen-form-container-view'>
          <ViewUser elementGroup={groupList} jsonSchema={schemaComponent} id={selectedUser} />
        </DialogContent>

      </Dialog>




      <Dialog fullWidth
        maxWidth="sm" open={openExportDialog} onClose={() => setOpenExportDialog(false)}>
        <DialogTitle>{t('Bulk Edit')}</DialogTitle>
        <DialogContent>

          <input style={{ display: 'block' }} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleFile(e)} />


        </DialogContent>
        <DialogActions>
          {
            isUpdatingBulkUser ? <>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </> :
              <>
                <Button onClick={() => setOpenExportDialog(false)}>Cancel</Button>
                <Button onClick={submitBulkEdit}>Edit</Button>
              </>

          }

        </DialogActions>
      </Dialog>

      <Card sx={{ padding: 8 }}>
        <Grid container rowSpacing={2} columnSpacing={0}>

          <Grid className='pading-layout' item xs={6} md={3}>
            <TextField
              style={{ width: '100%' }}
              onChange={(e) => {
                setNameFilter(e.target.value);
              }}
              id="outlined-basic"
              label={t('Name')}
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid className='pading-layout' item xs={6} md={3}>
            <TextField
              style={{ width: '100%' }}
              onChange={(e) => {
                setLastNameFilter(e.target.value);
              }}
              id="outlined-basic"
              label={t('Last Name')}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid className='pading-layout' item xs={6} md={3}>
            <TextField
              style={{ width: '100%' }}
              onChange={(e) => {
                setEmailFilter(e.target.value);
              }}
              id="outlined-basic"
              label={t('Email')}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid className='pading-layout' item xs={6} md={3}>
            <TextField
              style={{ width: '100%' }}
              onChange={(e) => {
                setCityFilter(e.target.value);
              }}
              id="outlined-basic"
              label={t('City')}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid className='pading-layout' item xs={6} md={3}>
          <FormControl fullWidth>
          <InputLabel style={{top:'-5px'}} id="demo-simple-select-label">Status</InputLabel>
            <Select
             style={{ width: '100%' }}
             size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={(e)=>{setStatusFilter(e.target.value)}}
            >
              <MenuItem value={true}>ACTIVE</MenuItem>
              <MenuItem value={false}>DEACTIVE</MenuItem>
            </Select>
            </FormControl>
          
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            justifyContent="flex-start"
            alignItems="stretch"
            style={{ display: "flex" }}
          >
            <Grid >
              <AntButton danger type="primary" onClick={fetchAllUsers} size="large">
                {t('Search')}
              </AntButton>
            </Grid>
            <Grid style={{ marginLeft: 5 }}>
              <AntButton danger type="primary" onClick={() => setOpenExportDialog(true)} size="large">
                {t('Bulk Edit')}
              </AntButton>
            </Grid>
            <Grid style={{ marginLeft: 5 }}>
              <AntButton danger type="primary" onClick={() => handleOpenTemplateSelect()} size="large">
                {t('Template')}
              </AntButton>

            </Grid>





          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersFetchLoading ? (
                  <>
                    <TableRow key={999}>
                      <TableCell colSpan={9}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {(rowsPerPage > 0 && usersFetchData?.result?.length > 0
                      ? usersFetchData?.result
                      : usersFetchData
                    ).map((row) => (
                      <TableRow key={row.bitrix.name}>
                        <TableCell scope="row"><Checkbox onChange={() => checkThisUser(row.bitrix.ID)} /></TableCell>
                        <TableCell scope="row">{row.bitrix.ID}</TableCell>
                        <TableCell scope="row">{row.bitrix.NAME}</TableCell>
                        <TableCell scope="row">{row.bitrix.LAST_NAME}</TableCell>
                        <TableCell scope="row">{row.bitrix.EMAIL}</TableCell>
                        <TableCell scope="row">{row.bitrix.PERSONAL_MOBILE}</TableCell>
                        <TableCell scope="row">{row.bitrix.PERSONAL_CITY}</TableCell>
                        <TableCell scope="row">{row.bitrix.WORK_POSITION}</TableCell>
                        <TableCell scope="row">
                          {/*<IconButton size="small" color="primary" aria-label="edit user" onClick={() => handleClickOpen(row, 'userEdit')}>*/}
                          {/*  <EditIcon fontSize="inherit" />*/}
                          {/*</IconButton>*/}
                          <IconButton size="small" color="primary" aria-label="edit user" onClick={() => viewEmployeeData(row.bitrix, 'userEdit')}>
                            <RemoveRedEyeIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25]}
                    colSpan={8}
                    count={usersFetchData?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
