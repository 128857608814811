import React, { useState, useEffect } from "react";

import {
    useDispatch, useSelector
} from "react-redux";
import { Alert, Button, Col, Image, Result, Row, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { RoundedStatusRed } from "../../../../components/elements/RoundedStatus";
import { FormInterfaceDynamic } from "../../../settings/JsonForm/intefaces/FormInterfaceDynamic";
import {
    changeDataReducer,
    getFormAnswerList, submitFormAnswer
} from "../../../../redux/slices/attandance/markAttandanceSetting";
import moment from 'moment'
import { CheckCircleTwoTone,WarningOutlined , HeartTwoTone, SmileTwoTone } from '@ant-design/icons'
import { useHistory } from "react-router-dom";


export function CheckingForm(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory();



    const formGroupTask = useSelector((state) => {
        let data = []
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formGroupTask) {
            data = attandanceState.formGroupTask
        }
        return data
    })

    const ruleArray = useSelector((state) => {
        let data = []
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.ruleArray) {
            data = attandanceState.ruleArray
        }
        return data
    })

    const formInformation = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formInformation) {
            data = attandanceState.formInformation
        }
        return data
    })

    const formdata = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formInformation) {
            data = attandanceState.formInformation
        }
        return data
    })

    const formSchemaTask = useSelector((state) => {
        let data = []
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formSchemaTask) {
            data = attandanceState.formSchemaTask
        }
        return data
    })


    const addbuttonDisable = useSelector((state) => {
        let data = false
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.addbuttonDisable) {
            data = attandanceState.addbuttonDisable
        }
        return data
    })


    const formDataSetTask = useSelector((state) => {
        let data = null
        const attandanceState = state.markAttandance ? state.markAttandance : null
        if (attandanceState?.formDataSetTask) {
            data = attandanceState.formDataSetTask
        }
        return data
    })






    const changeDataTriggerTask = (data, err) => {

        dispatch(changeDataReducer({
            data,
            err,
            ruleArray
        }))


    }



    const getFormAnswer = async (id) => {

        const data = {
            formData: id,
            sorting: false,
            order: false,
            search: null,
            viewType: "user_only"
        };
        const res = await dispatch(getFormAnswerList(data));


    }
    


    const [isOpenResultPopup, setResultPopup] = useState(false)

    const [time, setTime] = useState(null)

    const formSubmitClocked = async (e) => {
       
        if (e.clockIn && !e.clockOut) {
            let tim = e.clockIn.image.dateTime ? moment(e.clockIn.image.dateTime).format("HH:mm") : '-'
            setTime(tim)
        }
        console.log('setResultPopup(true)')
        setResultPopup(true)
       
    }

    
    const [loading, setLoading] = useState(false)
    const clossPopup = async () => {
        setLoading(true)
        console.log('setLoading(true)')
       await formSubmit()

       setLoading(false)
        setResultPopup(false)
        console.log('setResultPopup(false)')

    }


    // const formSubmit = async () => {

    //     const tempData = {
    //         data: formDataSetTask,
    //         form: {...formdata, schema: null, group: null }
    //     }
    //     console.log('tempData',tempData)

    //     await dispatch(submitFormAnswer(tempData))
    //     //getFormAnswer(formdata.id)
    //     window.location.reload();
    // }

    // const formSubmit = async () => {
    
    //     const tempData = {
    //         data: formDataSetTask,
    //         form: {...formdata, schema: null, group: null }
    //     }
    //     console.log('tempData',tempData)
    
    //     const response = await dispatch(submitFormAnswer(tempData))

    //     if (response.message === 'answer submit successfully') {
    //         window.location.reload();
    //     }
    
    // }
    
    const formSubmit = async () => {
        const tempData = {
            data: formDataSetTask,
            form: {...formdata, schema: null, group: null }
        }
        console.log('tempData',tempData)
    
        try {
            const response = await dispatch(submitFormAnswer(tempData))
            console.log('form submit response',response)
            if (response.payload.status === 200) {
                // window.location.reload();
                history.go(0)
            }
        } catch (error) {
            console.error('error in form subit 196 checkingform',error);
        }
    }
    




    const init = () => {

    }





    useEffect(() => {

        init();

    }, [props]);



    return (
        <div>
            {/* <Row>
                <Col span={4} offset={10}>
                    <RoundedStatusRed lable={'Current status : out of work'}></RoundedStatusRed>
                </Col>
            </Row> */}
            <FormInterfaceDynamic
                elementGroup={formGroupTask}
                jsonSchema={formSchemaTask}
                id={formDataSetTask}
                formDetails={null}
                formInformation={formInformation}
                changeDataTrigger={(data, err) => changeDataTriggerTask(data, err)}
                formSubmit={formSubmitClocked }
            />

            <Modal
             className="clocking-form-copntainer"
                open={isOpenResultPopup}
                onOk={clossPopup}
                onCancel={clossPopup}
                centered
                footer={[
                    <Button key="back" onClick={clossPopup} loading={loading}>
                       {t('OK, Got it')} 
                       {/* {setTimeout(() => {  clossPopup() }, 3000)}   */}
                       
                    </Button>,

                ]}
            >
                <div className="msg-container">
                    <div className="lable-div">
                        <span className="lable-icon">
                            {/* <CheckCircleTwoTone twoToneColor="#52c41a" /> */}
                            <WarningOutlined twoToneColor="#e49c55"/>
                        </span>
                    </div>
                    <div className="lable-div">
                        <span className="title-div">
                            {t('Clock In is completed!')}
                        </span>
                    </div>
                    <div className="lable-div">
                        <div className="lable-div-time-lable">
                            {t('Your clock in')}
                        </div>
                        <div className="lable-div-time">
                            {time ? time : '-'}
                        </div>
                    </div>
                </div>
            </Modal>


            {/* <Button disabled={addbuttonDisable} style={{ width: '100%' }} type="primary" danger onClick={() => formSubmit(true)}>
                {t('Clock In')}
            </Button> */}
        </div>

    );
}