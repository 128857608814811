

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import { useDispatch, connect, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { CameraOutlined } from '@ant-design/icons';
import { Camera } from "./camera";
import { getLocation } from "../../../../../utils/location";
import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";

import {
    shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";

import $ from 'jquery';
import { Image, Select, Col, Row, Input, Alert, Spin } from 'antd';
// or
import { Grid, Link, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { UploadImageToS3WithReactS3 } from "./s3";
import { Button as AntButton, Space } from 'antd';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ClockedControl = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [imgData, setImage] = useState(null)
    const [locationError, setlocationError] = useState(false)
    const [error, seterror] = useState('User denied Geolocation')

    const [acceptedType, setAccetptedType] = useState('')
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();

    const [loading, setLoading] = useState(false);


    const [device, setDevice] = useState(false);

    const [fileGetType, setFileGetType] = useState('local');

    const [domLoading, setDomLoading] = useState(false);

    const [isMulitiPleImage, setMultiImage] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isHideInEdit, setHideInEdit] = useState(false)

    const [isImageUploading, setImgUploading] = useState(false)

    const err = useSelector((state) => {


        let error = false
        if (state?.markAttandance) {
            const dataset = state?.markAttandance?.addbuttonDisable ? state.markAttandance.addbuttonDisable : false

            error = dataset

        }
        if (props?.schema?.clockOut) {
            error = false
        }

        return error

    })

    const errorList = useSelector((state) => {


        let error = []
        if (state?.markAttandance?.errorList) {
            error = state.markAttandance.errorList

        }
        return error

    })

    const selectedShop = useSelector((state) => {


        let error = null
        if (state?.markAttandance?.selectedAnswer?.data?.database?.main?.shops?.StoreID) {

            error = state.markAttandance.selectedAnswer.data.database.main.shops.StoreID
            //error=45454
            console.log('error', error)

        }
        return error

    })

    const warningList = useSelector((state) => {


        let warning = []
        if (state?.markAttandance?.warningList) {
            warning = state.markAttandance.warningList

        }
        return warning

    })

    const acceptedFileType = [
        {
            const: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lable: "EXCEL"
        },
        {
            const: "image/*",
            lable: "image"
        },
        {
            const: "video/*",
            lable: "video"
        },
        {
            const: "audio/*",
            lable: "audio"
        },
        {
            const: ".pdf",
            lable: "PDF"
        }

    ];

    const getGeo = async () => {
        return new Promise((resolve, reject) => {
            let goptions = {
                enableHighAccuracy: true,
                timeout: 9000,
                maximumAge: 0,
            };
            function success(pos) {
                var crd = pos.coords;

                resolve(crd);
                // if(props.data[props.schema.defaultArray.keyTxt]){
                //     props.handleChange(props.path,  props.data[props.schema.defaultArray.keyTxt])
                // }else{

                const respo = {
                    accuracy: crd.accuracy,
                    latitude: crd.latitude,
                    longitude: crd.longitude,
                }


                //}

            }

            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                // alert(
                //     "Error!: " + err.message + " \n " + uiText[uiText.lang].pageReload
                // );
                // location.reload();
                reject(err);
            }
            navigator.geolocation.getCurrentPosition(success, error, goptions);
        });
    };

    const uploadImage = async (e) => {
        setImgUploading(true)
        let attandance = imgData


        let tempUpload = await UploadImageToS3WithReactS3(e, props.schema.s3Bucket, props.schema.s3Dir);

        let respo = {}
        let geo = await getLocation()

        console.log('geo clock out', geo)

        if (geo.code) {
            console.log('geo.code', geo.code)

            if (geo.code == 1) {
                setlocationError(true)
                console.log('setlocationError(true)')
                setImgUploading(false)
                console.log('setImgUploading(false)')

            }
            return
        } else {
            setlocationError(false)
            console.log('setlocationError(false)')

        }


        respo = {
            ...respo,
            accuracy: geo.accuracy,
            latitude: geo.latitude,
            longitude: geo.longitude,
        }


        tempUpload = {
            ...tempUpload,
            absalute_location: respo,
            dateTime: new Date(),
        }
        if (props?.schema?.clockOut) {

            // const res = await dispatch(shareLocationReducer())


            // console.log('res', res)
            // if (res?.payload?.showData) {
            //     let Nearshops = res?.payload?.showData
            //     console.log('res', Nearshops)
            //     if (!Nearshops.find((e) => e.StoreID == selectedShop)) {
            //         alert(t('Clocking Shop couldnt fond in you location'))
            //         setImgUploading(false)
            //         return

            //     }

            // }
            //selectedShop


            attandance = {
                ...attandance,
                clockOut: {
                    image: tempUpload,
                    location: respo,
                    dateTime: new Date(),
                },
                status: 'out'

            }
        } else {
            attandance = {
                ...attandance,
                clockIn: {
                    image: tempUpload,
                    location: respo,
                    dateTime: new Date(),
                },
                status: 'in'

            }
        }


        if (tempUpload) {

            if (isMulitiPleImage) {
                if (!imgData) {
                    let image = []
                    image.push(tempUpload)
                    props.handleChange(props.path, image)
                } else {
                    let image = [...imgData, tempUpload]
                    props.handleChange(props.path, image)
                }

            } else {
                props.handleChange(props.path, attandance)
            }

            //setImage(tempUpload)
        }

        if (props?.schema?.formSubmit) {
            props.schema.formSubmit(attandance)
        }

        setImgUploading(false)
        // form submit should be here
    };

    const setAcceptType = (type) => {
        if (type.length === 0) {
            setAccetptedType('')
        } else {
            let acceptedTypeArray = []
            for (const element of type) {
                const key = acceptedFileType.find((e) => e.lable === element)
                acceptedTypeArray.push(key.const)
            }

            //var arr = ['Hi', 'I', 'am', 'a', 'comma', 'separated', 'list'];


            setAccetptedType(acceptedTypeArray.join(','))
        }
    }

    const updateData = async (imageBlob) => {

        setLoading(true);
        uploadImage(imageBlob)
        setIsCameraOpen(false)

        setLoading(false);

    };

    const customizeForDevice = () => {

        var ua = navigator.userAgent;

        var checker = {
            iphone: ua.match(/(iPhone|iPod|iPad)/),
            blackberry: ua.match(/BlackBerry/),
            android: ua.match(/Android/)
        };
        if (checker.android) {
            setDevice(false)
        } else if (checker.iphone) {
            setDevice(false)
        }
        else if (checker.blackberry) {
            setDevice(false)
        }
        else {
            setDevice(true)
        }

    }

    useEffect(() => {
        $('.MuiButton-containedPrimary').prop("hidden", true);
        $('.MuiButton-containedSecondary').prop("hidden", true);

        customizeForDevice()
        setDomLoading(true)
        setAcceptType(props.schema.selectedFileType ? props.schema.selectedFileType : [])
        setFileGetType(props.schema.captureType ? props.schema.captureType : 'local')
        setMultiImage(props?.schema?.mulitpleImage ? props.schema.mulitpleImage : false)

        if (props?.schema?.schemaElement?.group) {
            //console.log('share-location', props.schema.schemaElement.group)
            dispatch(selectGroup(props.schema.schemaElement.group))

        } else {
            dispatch(selectGroup(null))

        }

        console.log('data',props.data)
        if (props?.schema?.mulitpleImage) {

            setImage(props?.data ? props.data : null)
        } else {
            setImage(props?.data ? props.data : null)
        }


        if (props?.uischema?.rule?.effect) {
            if (props.uischema.rule.effect === 'SHOW') {
                setHideInEdit(true)
            } else {
                setHideInEdit(false)
            }
        } else {
            setHideInEdit(false)
        }


        setDomLoading(false)
        //
    }, [props]);
    return (
        <>

            {
                locationError ? <>
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            marginBottom: '10px'
                        }}
                    >
                        <Alert
                            message={`Error : ${t(error)}`}
                            description={t("Can not clocking without shareing location. please share location to continue.")}
                            type="error"
                        />
                    </Space>
                </> : null
            }
            {
                isImageUploading ? <div className='sping-div'>
                    <Spin tip='Image Uploading'></Spin>
                </div> :
                    !err ? <>
                        {
                            imgData?.clockIn && imgData?.clockOut ? <>
                                <div className='photCapture-IMAGE-device'>
                                    <AntButton
                                        type="primary"
                                        danger
                                        icon={<CameraOutlined />}
                                        onClick={() => { window.location.reload() }}
                                    >
                                        {t('Go back')}
                                    </AntButton>
                                </div>

                            </> : <>
                                <>

                                    {
                                        !domLoading ? (<>

                                            <div className='camera-lable'>

                                                <div style={{ marginBottom: 10 }}>
                                                    {
                                                        errorList ?
                                                            <>
                                                                {
                                                                    errorList.length != 0 ?
                                                                        <>

                                                                            {
                                                                                errorList.map((row) => (
                                                                                    <>
                                                                                        <Alert message={row.message ? row.message : '-'} type="error" showIcon />
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </> : null
                                                                }

                                                            </>
                                                            : null
                                                    }

                                                    {
                                                        warningList ?
                                                            <>
                                                                {
                                                                    warningList.length != 0 ?
                                                                        <>

                                                                            {
                                                                                warningList.map((row) => (
                                                                                    <>
                                                                                        <Alert message={row.message ? row.message : '-'} type="info" showIcon />
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </> : null
                                                                }

                                                            </>
                                                            : null
                                                    }

                                                </div>

                                                {
                                                    imgData ?
                                                        <>

                                                            <Row>
                                                                {
                                                                    props?.schema?.clockOut ? null : <>
                                                                        {/* <Col xs={24} sm={24} md={12} className='antd-input-div'>
                                <div style={{
                                    width: '100%',
                                    marginTop: '15px',
                                }}>
                                    {t('Clock-In Latitude :')}

                                </div>
                                <Input value={
                                    imgData?.clockIn?.location?.latitude ?
                                        imgData.clockIn.location.latitude :
                                        null
                                } placeholder="latitude" disabled />
                            </Col>
                            <Col xs={24} sm={24} md={12} className='antd-input-div-right'>
                                <div style={{
                                    width: '100%',
                                    marginTop: '15px',
                                }}>{t('Clock-In Longitude :')} </div>
                                <Input value={
                                    imgData?.clockIn?.location?.longitude ?
                                        imgData.clockIn.location.longitude :
                                        null
                                } placeholder="Longitude" disabled />
                            </Col>

                            <Col xs={24} sm={24} md={12} className='antd-input-div'>
                                <div style={{
                                    width: '100%',
                                    marginTop: '15px',
                                }}>
                                    {t('Clock-In Date :')}

                                </div>
                                <Input value={
                                    imgData?.clockIn?.dateTime ?
                                        moment(imgData.clockIn.dateTime).format('MM/DD/YYYY')
                                        :
                                        null
                                } placeholder="Basic usage" disabled />
                            </Col>
                            <Col xs={24} sm={24} md={12} className='antd-input-div-right'>
                                <div style={{
                                    width: '100%',
                                    marginTop: '15px',
                                }}>{t('Clock-In Time :')} </div>
                                <Input value={
                                    imgData?.clockIn?.dateTime ?
                                        moment(imgData.clockIn.dateTime).format('h:mm:ss a') :
                                        null
                                } placeholder="Basic usage" disabled />
                            </Col> */}
                                                                    </>
                                                                }


                                                                <Col xs={24} sm={24} md={12} className='antd-input-div div-with-image'>
                                                                    <>
                                                                        {
                                                                            props?.schema?.clockIn ? null : <>
                                                                                <AntButton type="primary" danger onClick={() => setVisible(true)}>
                                                                                    {t('View Clock-In Image')}
                                                                                </AntButton>
                                                                            </>
                                                                        }
                                                                    </>



                                                                    {
                                                                        props?.schema?.clockOut ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                {
                                                                                    device ?

                                                                                        <div className='photCapture-IMAGE-device'>
                                                                                            <AntButton

                                                                                                type="primary"
                                                                                                danger
                                                                                                icon={<CameraOutlined />}
                                                                                                onClick={() => setIsCameraOpen(true)}
                                                                                                style={{ marginRight: 10 }}
                                                                                            >
                                                                                                {t('TAKE A PHOTO')}
                                                                                            </AntButton>
                                                                                        </div>


                                                                                        :
                                                                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                                                                            <input onChange={(e) => { 
                                                                                                uploadImage(e.target.files[0])
                                                                                                 }} hidden accept={acceptedType} type="file" capture="environment" />
                                                                                            {


                                                                                                <div className='photCapture-IMAGE'>
                                                                                                    <div>
                                                                                                        <CameraOutlined />
                                                                                                        <span>Take a photo to clock in</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                        </IconButton>
                                                                                }
                                                                            </>

                                                                    }

                                                                    <Image
                                                                        width={200}
                                                                        style={{
                                                                            display: 'none',
                                                                        }}
                                                                        src={imgData.clockIn.image.location}
                                                                        preview={{
                                                                            visible,
                                                                            src: imgData.clockIn.image.location,
                                                                            onVisibleChange: (value) => {
                                                                                setVisible(value);
                                                                            },
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <>
                                                                {
                                                                    props?.schema?.clockOut ?
                                                                        <>
                                                                            {
                                                                                imgData?.clockOut ?
                                                                                    <>
                                                                                        <Row>
                                                                                            {/* <Col xs={24} sm={24} md={12} className='antd-input-div'>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '15px',
                                                    }}>
                                                        {t('Clock-Out Latitude :')}

                                                    </div>
                                                    <Input value={
                                                        imgData?.clockOut?.location?.latitude ?
                                                            imgData.clockOut.location.latitude :
                                                            null
                                                    } placeholder="latitude" disabled />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} className='antd-input-div-right'>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '15px',
                                                    }}>{t('Clock-Out Longitude :')} </div>
                                                    <Input value={
                                                        imgData?.clockOut?.location?.longitude ?
                                                            imgData.clockOut.location.longitude :
                                                            null
                                                    } placeholder="Longitude" disabled />
                                                </Col>

                                                <Col xs={24} sm={24} md={12} className='antd-input-div'>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '15px',
                                                    }}>
                                                        {t('Clock-Out Date :')}

                                                    </div>
                                                    <Input value={
                                                        imgData?.clockOut?.dateTime ?
                                                            moment(imgData.clockOut.dateTime).format('MM/DD/YYYY')
                                                            :
                                                            null
                                                    } placeholder="Basic usage" disabled />
                                                </Col>
                                                <Col xs={24} sm={24} md={12} className='antd-input-div-right'>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '15px',
                                                    }}>{t('Clock-Out Time :')} </div>
                                                    <Input value={
                                                        imgData?.clockOut?.dateTime ?
                                                            moment(imgData.clockOut.dateTime).format('h:mm:ss a') :
                                                            null
                                                    } placeholder="Basic usage" disabled />
                                                </Col> */}

                                                                                            <Col xs={24} sm={24} md={12} className='antd-input-div div-with-image'>
                                                                                                <AntButton type="primary" danger onClick={() => setVisible(true)}>
                                                                                                    {t('View Clock-Out Image')}
                                                                                                </AntButton>

                                                                                                <>
                                                                                                    {
                                                                                                        device ?
                                                                                                            <div className='photCapture-IMAGE-device'>
                                                                                                                <AntButton
                                                                                                                    type="primary"
                                                                                                                    danger
                                                                                                                    icon={<CameraOutlined />}
                                                                                                                    onClick={() => setIsCameraOpen(true)}
                                                                                                                    style={{ marginRight: 10 }}
                                                                                                                >
                                                                                                                    {t('Clock-out photo')}
                                                                                                                </AntButton>
                                                                                                            </div>


                                                                                                            :
                                                                                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                                                                                <input onChange={(e) => { uploadImage(e.target.files[0]) }} hidden accept={acceptedType} type="file" capture="environment" />
                                                                                                                <div className='photCapture-IMAGE'>
                                                                                                                    <div>
                                                                                                                        <PhotoCamera />
                                                                                                                        <span>Take a photo to clock out</span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </IconButton>
                                                                                                    }
                                                                                                </>

                                                                                                <Image
                                                                                                    width={200}
                                                                                                    style={{
                                                                                                        display: 'none',
                                                                                                    }}
                                                                                                    src={imgData.clockOut.image.location}
                                                                                                    preview={{
                                                                                                        visible,
                                                                                                        src: imgData.clockOut.image.location,
                                                                                                        onVisibleChange: (value) => {
                                                                                                            setVisible(value);
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </> :
                                                                                    <div style={{ marginTop: 10 }}>
                                                                                        {
                                                                                            device ?
                                                                                                <div className='photCapture-IMAGE-device'>
                                                                                                    <AntButton
                                                                                                        type="primary"
                                                                                                        danger
                                                                                                        icon={<CameraOutlined />}
                                                                                                        onClick={() => setIsCameraOpen(true)}
                                                                                                    >
                                                                                                        {t('Clock-out photo')}
                                                                                                    </AntButton>
                                                                                                </div>
                                                                                                //clock out button


                                                                                                :
                                                                                                <IconButton color="primary" aria-label="upload picture" component="label">
                                                                                                    <input onChange={(e) => { uploadImage(e.target.files[0]) }} hidden accept={acceptedType} type="file" capture="environment" />
                                                                                                    {

                                                                                                        <div className='photCapture-IMAGE'>
                                                                                                            <div>
                                                                                                                <PhotoCamera />
                                                                                                                <span>Take a photo to clock out</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }

                                                                                                </IconButton>
                                                                                              
                                                                                        }

                                                                                    </div>
                                                                            }
                                                                        </>

                                                                        : null
                                                                }
                                                            </>



                                                        </>
                                                        :
                                                        <>
                                                            <div>
                                                                {
                                                                    device ?
                                                                        <div className='photCapture-IMAGE-device'>
                                                                            <AntButton
                                                                                disabled={errorList.length != 0 ? true : false}
                                                                                type="primary"
                                                                                danger
                                                                                icon={<CameraOutlined />}
                                                                                onClick={() => setIsCameraOpen(true)}
                                                                            >
                                                                                {t(' PHOTO')}
                                                                            </AntButton>
                                                                        </div>


                                                                        :
                                                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                                                            <input onChange={(e) => { uploadImage(e.target.files[0]) }} hidden accept={acceptedType} type="file" capture="environment" />
                                                                            {/* in here after photo */}
                                                                            {


                                                                                <div className='photCapture-IMAGE'>
                                                                                    <div>
                                                                                        <CameraOutlined />
                                                                                        <span>Take a photo to clock in</span>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </IconButton>
                                                                }

                                                            </div>
                                                        </>

                                                }

                                            </div>

                                        </>) : null
                                    }
                                </>
                            </>

                        }

                    </> : null

            }

            <Dialog
                fullScreen
                open={isCameraOpen}
                onClose={() => setIsCameraOpen(false)}
                TransitionComponent={Transition}
            >

                <DialogContent className="full-screen-form-container-view">
                    <Camera
                        loading={loading}
                        onCapture={(blob) => {
                            updateData(blob);
                        }}
                        clossCamera={() => setIsCameraOpen(false)}
                        onClear={() => setCardImage(undefined)}
                    />
                </DialogContent>
            </Dialog>


        </>




    );
}



export default withJsonFormsControlProps(ClockedControl);
