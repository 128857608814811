import React, { useState, useEffect } from "react";

import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import { Col } from "antd";
import { useCountdownTimer } from 'use-countdown-timer';
import $ from 'jquery';
import { FullScreenLoader } from '../../../../../components/elements/FullScreenLoader'
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function CountDown(props) {

    // const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    //     timer: 1000 * 5,
    //   });

    const [setTimer, setTimerDom] = useState(null)
    const [time, setTimeLeft] = useState(null)
    const [diff, setDiff] = useState(null)

    const displayTimeLeft = seconds => {
        let minutesLeft = Math.floor(seconds / 60);
        let secondsLeft = seconds % 60;
        minutesLeft = minutesLeft.toString().length === 1 ? "0" + minutesLeft : minutesLeft;
        secondsLeft = secondsLeft.toString().length === 1 ? "0" + secondsLeft : secondsLeft;
        return `${minutesLeft}:${secondsLeft}`;
    }

    
    useEffect(() => {
        // startTimer(diff)
        if (!diff) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            let timer = diff - 1
            setDiff(timer);

            var minutes = parseInt(timer / 60, 10);
            var seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            var textContent = minutes + ":" + seconds;
            setTimeLeft(textContent)
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);

    }, [diff])


    //setTimerDom()

    const [loading, setLoading] = useState(false)
    useEffect(() => {

        setLoading(true)
        if (props.timmer && props.timoutTime) {


            const tim = new Date(props.timmer).toString()

            var date1 = new Date(props.timmer); // 9:00 AM
            var date2 = new Date(); // 5:00 PM

            // the following is to handle cases where the times are on the opposite side of
            // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM

            const dif = date2 - date1

            var minutes = Math.floor(dif / 60000);
            var millisecondsTime = props.timoutTime * 60000



            const timeDif = millisecondsTime - dif

            var secondsCurr = (timeDif / 1000).toFixed(0);

            if (timeDif < 0) {
                window.location.reload();
            }
            setDiff(secondsCurr)
            setTimerDom(tim)
        }

        setLoading(false)
        //setTimerDom()

    }, [props]);


    return (
        <>
                    {
                        time ? <span className='timmer-value'>

                            {time?time:'XX:XX'}
                        </span> : loading && <FullScreenLoader />
                    }

        </>



    );
}