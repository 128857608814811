import React, { useState, useEffect } from "react";
import { SqureLable } from '../../../components/elements/SqureLable'

import {
    useDispatch, useSelector
} from "react-redux";
import {Alert, Button, Col, Image, Result, Row} from "antd";
import {useTranslation} from "react-i18next";
import {
    closeClockOutMenu, shareLocationReducer
} from "../../../redux/slices/attandance/markAttandanceSetting";

const style = {
    padding: '8px 0',
    marginBottom: '10px'
};


export function ShareLocation(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const shareLocationReload = useSelector(state => {
        let data = false;
        const attandanceState = state.markAttandance || null;
        if (attandanceState?.shareLocationReload) {
            data = attandanceState.shareLocationReload;
        }
        return data;
    });

    const locationData = useSelector(state => {
        let data = null;
        const attandanceState = state.markAttandance || null;
        if (attandanceState?.locationData) {
            data = attandanceState.locationData;
        }
        return data;
    });
    const shopData = useSelector(state => {
        let data = [];
        const attandanceState = state.markAttandance || null;
        if (attandanceState?.shopData) {
            data = attandanceState.shopData;
        }
        return data;
    });

    const shareLocationFunction = async () => {
        try {
            dispatch(shareLocationReducer());
        } catch (error) {
            console.error(error);
            // add code here to handle the error in a user-friendly manner
        }
    };

    const init = () => {};

    useEffect(() => {
        init();
    }, [props]);

    return (
        <div className="share-location-div">
            
            <div>
                {locationData ? (
                    <>
                        <Alert message={`${t('latitude')}: ${locationData.latitude}`} type="success" />
                        <Alert message={`${t('longitude')}: ${locationData.longitude}`} type="success" />
                        {shopData.length === 0 ? (
                            <Alert message={t('Cant proceed no shops Found')} type="error" />
                        ) : null}
                    </>
                ) : null}
            </div>
        </div>
    );
}



// export function ShareLocation(props) {

//     const dispatch = useDispatch();
//     const { t, i18n } = useTranslation();


//     const shareLocationReload = useSelector((state) => {
//         let data = false
//         const attandanceState=state.markAttandance?state.markAttandance:null
//         if(attandanceState?.shareLocationReload){
//             data=attandanceState.shareLocationReload
//         }
//         return data
//     })

//     const locationData = useSelector((state) => {
//         let data = null
//         const attandanceState=state.markAttandance?state.markAttandance:null
//         if(attandanceState?.locationData){
//             data=attandanceState.locationData
//         }
//         return data
//     })
//     const shopData = useSelector((state) => {
//         let data = []
//         const attandanceState=state.markAttandance?state.markAttandance:null
//         if(attandanceState?.shopData){
//             data=attandanceState.shopData
//         }
//         return data
//     })

//     const shareLocationFunction = async () => {
//         dispatch(shareLocationReducer())

//     }

//     const init = () =>{

//     }





//     useEffect(() => {

//         init();

//     }, [props]);



//     return (

//            <div className="share-location-div">
//                {/* <Button onClick={shareLocationFunction}
//                        loading={shareLocationReload}
//                        disabled={shareLocationReload}
//                >{t('SHARE LOCATION')}</Button> */}


//                <div>
//                    {
//                        locationData?<>
//                            <Alert message={`${t('latitude')} : ${locationData.latitude}`} type="success" />
//                            <Alert message={`${t('longitude')} : ${locationData.longitude}`} type="success" />


//                            {
//                                shopData.length ===0?
//                                    <Alert message={t('Cant proceed no shops Found')} type="error" />

//                                    :null
//                            }
//                        </>:null
//                    }
//                </div>
//            </div>

//     );
// }