import React, { useState, useEffect } from "react";
import { getUserById, updateUserService } from "../../redux/slices/users";

import {
    useDispatch
} from "react-redux";
import './style.css'


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './style.css'
import Stack from '@mui/material/Stack';

import { GroupUserEdit } from "../settings/JsonForm/intefaces/GroupUserEdit"

import {
    Button
} from "@mui/material";

import { ElementValue } from "./components/ElementValue"

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function ViewUser(props) {

    const dispatch = useDispatch();



    const [groupList, setGroupList] = useState([])
    const [jsonScema, setJsonScema] = useState([])
    const [groupSelectedList, setGroupSelectedList] = useState([])
    const [openUserManageModel, setOpenUserManagmentModule] = useState(false)
    const [user, setUser] = useState({})

    const [isLoading, loding] = useState(false)


    const [userDB, setUserDB] = useState({})

    const handleClose = () => {
        setOpenUserManagmentModule(false)
    }

    const editGroup = (id) => {

        setGroupSelectedList(groupList.filter((e) => e.groupId == id))
        setOpenUserManagmentModule(true)
    }


    const editGroupSubmit = async (data) => {
        loding(true)





        setUser((prevState) => {
            return ({
                ...prevState,
                ...data.bitrix.main
            });
        });

        setUserDB((prevState) => {
            return ({
                ...prevState,
                ...data.database.main
            });
        });


        let obj = {
            userId: user.ID,
            bitrix: {
                main: {
                    ...user,
                    ...data.bitrix.main
                }

            },
            database: {
                main: {
                    ...userDB,
                    ...data.database.main
                }
            }
        }



        try {

            let res = await dispatch(updateUserService(obj));

            if (res) {
                loding(false)
                handleClose()
            } else {
                loding(false)
                handleClose()
            }


        } catch (e) {

        }



    }

    const genarateViewInterface = async () => {
        loding(true)

        const tempUserDB = await dispatch(getUserById(props.id.ID))
        if (tempUserDB.payload) {
            setUserDB(tempUserDB.payload)
        }

        const group = props.elementGroup
        setGroupList(group)
        const schemaElement = props.jsonSchema
        setJsonScema(schemaElement)

        const tempUser = props.id
        setUser(tempUser)

        loding(false)
    }



    useEffect(() => {


        genarateViewInterface();


    }, []);


    const editUserSubmit = () => {

    }

    return (
        <>
            {
                isLoading ? (null) : (
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {groupList ? (
                                    <>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            {
                                                groupList.map((row) => (
                                                    <>
                                                        <Grid item sm={12} md={6}>
                                                            <div className='group-el-container'>
                                                                <div className='group-el-header'>
                                                                    {row.groupLable}

                                                                    <div className='edit-button-group'><Button variant="contained" color="primary" onClick={() => editGroup(row.groupId)}>Edit</Button></div>
                                                                </div>

                                                                <Grid className='group-el-body' container>
                                                                    {

                                                                        jsonScema.filter((e) => e.group === row.groupId).map((element) => (

                                                                            <>
                                                                                <Grid className={element.fieldPlace === 'database' ? "element-value-database element-value-container" : 'element-value-bitrix element-value-container'} container rowSpacing={1}>

                                                                                    <Grid item xs={4} md={4}>
                                                                                        <div className='element-value'>
                                                                                            <Stack direction="row" spacing={1}>

                                                                                                {element.lableTxt}
                                                                                                {/*<Chip style={{marginLeft:4}} label={element.fieldPlace === 'bitrix'?'Bitrix':'Database'} color={element.fieldPlace === 'bitrix'?'warning':'error'} size="small" variant="outlined" />*/}
                                                                                            </Stack>


                                                                                        </div>

                                                                                    </Grid>
                                                                                    <Grid item xs={8} md={8}>
                                                                                        <div className='element-lable'>
                                                                                            {
                                                                                                element.fieldPlace === 'bitrix' ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            isLoading ? (null) : (
                                                                                                                <ElementValue type={element.type} value={user[element.keyTxt] ? user[element.keyTxt] : '-'} />
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                ) : null

                                                                                            }
                                                                                            {
                                                                                                element.fieldPlace === 'database' ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            isLoading ? (null) : (
                                                                                                                <ElementValue type='' value={userDB[element.keyTxt] ? userDB[element.keyTxt] : '-'} />
                                                                                                            )
                                                                                                        }


                                                                                                    </>
                                                                                                ) : null

                                                                                            }



                                                                                        </div>

                                                                                    </Grid>


                                                                                </Grid>
                                                                            </>



                                                                        )
                                                                        )

                                                                    }
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                ))
                                            }
                                        </Grid>
                                    </>





                                ) : (null)
                                }



                            </Grid>
                        </Box>
                    </>
                )
            }


            <Dialog

                open={openUserManageModel}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div className='full-screen-dialog-body'>
                    <GroupUserEdit elementGroup={groupSelectedList} jsonSchema={jsonScema} id={user} handleClose={handleClose} editGroupSubmit={(data) => editGroupSubmit(data)} />
                </div>


            </Dialog>



        </>
    );
}