import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

let initialState = {
    shops: [
        
    ]
};





export const getAllShops = createAsyncThunk(
    "permision/getAllShops",
    async (data) => {
        
        let endPoint = `${apiConfig.adminApi}/shops/get-all-shop/`;
        endPoint+=data.latitude
        endPoint+='/'
        endPoint+=data.longitude

     
        const token = localStorage.getItem('token')
        console.log(token? true :"no token")

        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);












export const shopSlice = createSlice({
    name: "permision",
    initialState,
    extraReducers: {

        [getAllShops.fulfilled]: (state, action) => {


        },

    },
});

export default shopSlice.reducer;


