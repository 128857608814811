import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig,apiConfigForm } from "../../config";

const initialState = {
    forms: []
};



//

export const addFormElementToServer = createAsyncThunk(
    "formEliment/addFormElementToServer",
    async (code) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/add-scheema`;

        const response = await axios.post(endPoint, code);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const editFormElementToServer = createAsyncThunk(
    "formEliment/editFormElementToServer",
    async (code) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/update-form-element`;

        const response = await axios.put(endPoint, code);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);




export const deleteFormElementFromServer = createAsyncThunk(
    "formEliment/deleteFormElementFromServer",
    async (data) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/deleteSchema`;

        const response = await axios.post(endPoint, data);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const deleteFormGroupFromServer = createAsyncThunk(
    "formEliment/deleteFormGroupFromServer",
    async (data) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/deleteGroup`;

        const response = await axios.post(endPoint, data);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);




export const changeOrderReducer = createAsyncThunk(
    "formEliment/changeOrderReducer",
    async (data) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/change-order`;

        const response = await axios.post(endPoint, data);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const changeOrderElementReducer = createAsyncThunk(
    "formEliment/changeOrderElementReducer",
    async (data) => {

        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/change-order-element`;

        const response = await axios.post(endPoint, data);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);





export const getFormSchema = createAsyncThunk(
    "formEliment/getFormSchema",
    async (id) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-schema/${id}`;

        const response = await axios.get(endPoint);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);



export const getFormDetails = createAsyncThunk(
    "formEliment/getFormDetails",
    async (id) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-details/${id}`;

        const response = await axios.get(endPoint);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const getMailTemplateById = createAsyncThunk(
    "formEliment/getMailTemplateById",
    async (id) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-mail-template-by-id/${id}`;

        const response = await axios.get(endPoint);

        if (response.data) {
            array = response.data
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);






export const addFormReducer = createAsyncThunk(
    "formEliment/addFormReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/form-managemant`;

        const response = await axios.post(endPoint, formData);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const addValueToKeyElementReducer = createAsyncThunk(
    "formEliment/addValueToKeyElementReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        if (form) {
            array = form
            array.push(formData)
            localStorage.setItem("elementKeyList", JSON.stringify(array));

        } else {
            array.push(formData)
            localStorage.setItem("elementKeyList", JSON.stringify(array));
        }


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const addAsShowUserReducer = createAsyncThunk(
    "formEliment/addAsShowUserReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/add-as-show-user`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const changeSchemaValueReducer = createAsyncThunk(
    "formEliment/changeSchemaValueReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/change-schema-value`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const changeGroupValueReducer = createAsyncThunk(
    "formEliment/changeGroupValueReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/change-group-value`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);



export const changeFormValueReducer = createAsyncThunk(
    "formEliment/changeFormValueReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/change-form-value`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);





export const changeAsParentFormReducer = createAsyncThunk(
    "formEliment/changeAsParentFormReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/change-as-parent-form`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const changeDisableElementReducer = createAsyncThunk(
    "formEliment/changeDisableElementReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/change-disable`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const connectToSystemUserReducer = createAsyncThunk(
    "formEliment/connectToSystemUserReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/connect-to-system-user`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);



export const seveEmailForForm = createAsyncThunk(
    "formEliment/seveEmailForForm",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/save-email-setting`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);


export const notificationHandleReducer = createAsyncThunk(
    "formEliment/notificationHandleReducer",
    async (formData) => {


        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/handle-notification`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);






export const saveSelectedProccesingFormReducer = createAsyncThunk(
    "formEliment/saveSelectedProccesingFormReducer",
    async (formData) => {


        let array = [];
       // var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/form/add-proccessing-form`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const getTaskProcessingFormReducer = createAsyncThunk(
    "formEliment/getTaskProcessingFormReducer",
    async (formData) => {
        try{
            let endPoint = `${apiConfig.adminApi}/form/processFormList`;
            const response = await axios.post(endPoint,formData);
            return response.data
        }
        catch(error){
            return error
        }
    }
  );

export const saveSelectedTaskProccesingFormReducer = createAsyncThunk(
    "formEliment/saveSelectedTaskProccesingFormReducer",
    async (formData) => {


        let array = [];
       // var form = JSON.parse(localStorage.getItem("elementKeyList"));

        let endPoint = `${apiConfig.adminApi}/forms/update/list`;

        const response = await axios.post(endPoint, formData);


        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);




// export const getAllFormsReducer = createAsyncThunk(
//     "formEliment/getAllFormsReducer",
//     async (data) => {
       
//         let array = [];
//         let endPoint=''
//         if(data.pagination){
//             if(data.searchFilter){
//                 endPoint = `${apiConfig.adminApi}/form/get-form/all?pagination=true&page=${data.selectedPage}&row=${data.rowPerRequest}&key=${data.searchFilter}`;
//             }else{
//                 endPoint = `${apiConfig.adminApi}/form/get-form/all?pagination=true&page=${data.selectedPage}&row=${data.rowPerRequest}`;
//             }
             
//         }else{
//             endPoint = `${apiConfig.adminApi}/form/get-form/1`;
//         }
     
       


//         const response = await axios.get(endPoint);

//         if (response.data) {
//             array = response.data
//         }




//         if (array.length !== 0) {
      
//             return array;
//         } else {
//             throw Error;
//         }
//     }
// );

export const getAllFormsReducer = createAsyncThunk(
  "formEliment/getAllFormsReducer",
  async (data) => {
    let endPoint;
    if (data.pagination) {
      endPoint = `${apiConfig.adminApi}/form/get-form/all?pagination=true&page=${data.selectedPage}&row=${data.rowPerRequest}${data.searchFilter ? `&key=${data.searchFilter}` : ''}`;
    } else {
      endPoint = `${apiConfig.adminApi}/form/get-form/1`;
    }

    const response = await axios.get(endPoint);
    if (response.data.length !== 0) {
      return response.data;
    } else {
      throw Error;
    }
  }
);


export const getAllFormsDashboard = createAsyncThunk(
    "formEliment/getAllFormsDashboard",
    
);

// export const getAllFormsDashboard = createAsyncThunk(
//     "formEliment/getAllFormsDashboard",
//     async () => {
//         const endPoint = `${apiConfig.adminApi}/form/get-form-dash`;
//         const token = localStorage.getItem('token');
//         const config = { headers: { authorization: `Bearer ${token}` } };

//         try {
//             const response = await axios.get(endPoint, config);

//             if (!response.data) {
//                 throw Error("No data returned from API");
//             }

//             return response.data;
//         } catch (err) {
//             return { error: err.message };
//         }
//     }
// );



// export const getAllGroupRaducer = createAsyncThunk(
//     "formEliment/getAllGroupRaducer",
//     async (id) => {

//         let array = [];
//         let endPoint = `${apiConfig.adminApi}/form/get-form-group/${id}`;



//         const response = await axios.get(endPoint);

//         if (response.data) {
//             array = response.data
//         }




//         if (array.length !== 0) {
//             return array;
//         } else {
//             throw Error;
//         }
//     }
// );


export const getAllGroupRaducer = createAsyncThunk(
  "formEliment/getAllGroupRaducer",
  async (id) => {
    const endPoint = `${apiConfig.adminApi}/form/get-form-group/${id}`;
    const response = await axios.get(endPoint);
    if (response.data.length !== 0) {
      return response.data;
    } else {
      throw Error;
    }
  }
);


// export const addGroupRaducer = createAsyncThunk(
//     "formEliment/addGroupRaducer",
//     async (formData) => {


//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/add-form-group`;

//         const response = await axios.post(endPoint, formData);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }
//     }
// );

export const addGroupRaducer = createAsyncThunk(
  "formEliment/addGroupRaducer",
  async (formData) => {
    const endPoint = `${apiConfig.adminApi}/form/add-form-group`;
    const response = await axios.post(endPoint, formData);
    if (response) {
      return response;
    } else {
      throw Error;
    }
  }
);



// export const getAllassignFormToComponentReducer = createAsyncThunk(
//     "formEliment/getAllassignFormToComponentReducer",
//     async (withComponent = false) => {

//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/get-assign-form-to-component`;

//         const response = await axios.get(endPoint);

//         if (response.data) {
//             array = response.data
//         }




//         if (array.length !== 0) {
//             return array;
//         } else {
//             throw Error;
//         }



//     }
// );

export const getAllassignFormToComponentReducer = createAsyncThunk(
  "formEliment/getAllassignFormToComponentReducer",
  async (withComponent = false) => {
    const endPoint = `${apiConfig.adminApi}/form/get-assign-form-to-component`;
    const response = await axios.get(endPoint);
    if (response.data.length !== 0) {
      return response.data;
    } else {
      throw Error;
    }
  }
);


// export const loadParentFormsReducer = createAsyncThunk(
//     "formEliment/loadParentFormsReducer",
//     async (formId) => {

//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/get-parent-form/${formId}`;

//         const response = await axios.get(endPoint);

//         if (response.data) {
//             array = response.data
//         }




//         if (array.length !== 0) {
//             return array;
//         } else {
//             throw Error;
//         }



//     }
// );

export const loadParentFormsReducer = createAsyncThunk(
  "formEliment/loadParentFormsReducer",
  async (formId) => {
    const endPoint = `${apiConfig.adminApi}/form/get-parent-form/${formId}`;
    const response = await axios.get(endPoint);
    if (response.data.length !== 0) {
      return response.data;
    } else {
      throw Error;
    }
  }
);



// export const onChangeFormStatusRaducer = createAsyncThunk(
//     "formEliment/onChangeFormStatusRaducer",
//     async (formData) => {


//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/update-form-status`;

//         const response = await axios.put(endPoint, formData);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }

//     }
// );

export const onChangeFormStatusRaducer = createAsyncThunk(
    "formEliment/onChangeFormStatusRaducer",
    async (formData) => {
        let endPoint = `${apiConfig.adminApi}/form/update-form-status`;

        try {
            const response = await axios.put(endPoint, formData);
            if (response.data) {
                return response.data;
            } else {
                throw new Error('No data returned from the server');
            }
        } catch (error) {
            console.error(error);
            throw new Error(`An error occurred while updating form status: ${error.message}`);
        }
    }
);


// export const submitFormAnswer = createAsyncThunk(
//     "formEliment/submitFormAnswer",
//     async (formData) => {


//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/submit-form-answer`;

//         const token = localStorage.getItem('token')
//         let config = {
//             headers: {
//                 authorization: 'Bearer ' + token,
//             }
//         }

//         const response = await axios.post(endPoint, formData, config);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }

//     }
// );


export const submitFormAnswer = createAsyncThunk(
  "formEliment/submitFormAnswer",
  async (formData) => {
    const endPoint = `${apiConfig.adminApi}/form/submit-form-answer`;
    // const endPoint = `http://localhost:4020/form/submit-form-answer`;

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        authorization: `Bearer ` + token,
      },
    };

    try {
      const response = await axios.post(endPoint, formData, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


// export const submitTaskProcessReducer = createAsyncThunk(
//     "formEliment/submitTaskProcessReducer",
//     async (formData) => {


//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/submit-form-answer-task`;

//         const token = localStorage.getItem('token')
//         let config = {
//             headers: {
//                 authorization: 'Bearer ' + token,
//             }
//         }

//         const response = await axios.post(endPoint, formData, config);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }

//     }
// );

export const submitTaskProcessReducer = createAsyncThunk(
  "formEliment/submitTaskProcessReducer",
  async (formData) => {
    const endPoint = `${apiConfig.adminApi}/form/submit-form-answer-task`;
    // const endPoint = `http://localhost:4020/form/submit-form-answer`;

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        authorization: `Bearer ` + token,
      },
    };

    try {
      const response = await axios.post(endPoint, {...formData,isComplete:false}, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getFormAnswerTaskReducer = createAsyncThunk(
    "formEliment/getFormAnswerTaskReducer",
    async (formData) => {
      const endPoint = `${apiConfig.adminApi}/form/get-form-answer-task`;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ` + token,
        },
      };
  
      try {
        const response = await axios.post(endPoint, formData, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const getExportListReducer = createAsyncThunk(
    "formEliment/getExportListReducer",
    async (formData) => {
      const endPoint = `${apiConfig.adminApi}/form/getExportlist`;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ` + token,
        },
      };
  
      try {
        const response = await axios.post(endPoint, formData, config);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  export const generateFormAnswerListExportReducer = createAsyncThunk(
    "formEliment/generateFormAnswerListExportReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/export/formAnswer`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const resetTaskProcessReducer = createAsyncThunk(
    "formEliment/resetTaskProcessReducer",
    async (formData) => {
      const endPoint = `${apiConfig.adminApi}/form/reset-task/${formData.processingFormId}/${formData.id}`;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          authorization: `Bearer ` + token,
        },
      };
  
      try {
        const response = await axios.post(endPoint,formData.payload,config);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

// export const submitBulkData = createAsyncThunk(
//     "formEliment/submitBulkData",
//     async (formData) => {


//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/submit-bulk-set`;

//         const token = localStorage.getItem('token')
//         let config = {
//             headers: {
//                 authorization: 'Bearer ' + token,
//             }
//         }

//         const response = await axios.post(endPoint, formData, config);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }

//     }
// );


export const submitBulkData = createAsyncThunk(
  "formEliment/submitBulkData",
  async (formData) => {
    const endPoint = `${apiConfig.adminApi}/form/submit-bulk-set`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        authorization: `Bearer ` + token,
      },
    };

    try {
      const response = await axios.post(endPoint, formData, config);
      if (!response) throw new Error("Empty response received");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


// export const completeTaskProcessReducer = createAsyncThunk(
//     "formEliment/completeTaskProcessReducer",
//     async (formData) => {



//         let array = [];

//         let endPoint = `${apiConfig.adminApi}/form/submit-form-answer-task`;

//         const token = localStorage.getItem('token')
//         let config = {
//             headers: {
//                 authorization: 'Bearer ' + token,
//             }
//         }

//         const response = await axios.post(endPoint, formData, config);

//         array = response


//         if (array) {
//             return array;
//         } else {
//             throw Error;
//         }

//     }
// );

export const completeTaskProcessReducer = createAsyncThunk(
    "formEliment/completeTaskProcessReducer",
    async (formData) => {

        let endPoint = `${apiConfig.adminApi}/form/submit-form-answer-task`;
        // const endPoint = `http://localhost:4020/form/submit-form-answer`;

        const token = localStorage.getItem('token');
        const config = {
            headers: {
                authorization: `Bearer ` + token,
            },
        };

        try {
            const response = await axios.post(endPoint, {...formData,isComplete:true}, config);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);






export const changeRejectionMethodReducer = createAsyncThunk(
    "formEliment/changeRejectionMethodReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/change-rejection-method`;


        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);
export const editFormAnswer = createAsyncThunk(
    "formEliment/editFormAnswer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/edit-form-answer`;
        // let endPoint = `http://localhost:4020/form/edit-form-answer`;


        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const deleteSelectedAnswer = createAsyncThunk(
    "formEliment/deleteSelectedAnswer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/delete-selected-answer`;

        const response = await axios.post(endPoint, formData);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);



export const saveFlowReducer = createAsyncThunk(
    "formEliment/saveFlowReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/save-form-flow`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getFlowReducer = createAsyncThunk(
    "formEliment/saveFlowReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-flow/${formData}`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getAllDatabaseUserReducer = createAsyncThunk(
    "formEliment/getAllDatabaseUserReducer",
    async () => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/all-db-user`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);





export const getCOnditionalEnum = createAsyncThunk(
    "formEliment/getCOnditionalEnum",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-enum-list`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getFormAnswerList = createAsyncThunk(
    "formEliment/getFormAnswerList",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-Answers`;
        // let endPoint = `http://localhost:4020/form/get-Answers`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);
export const getFormAnswerById = createAsyncThunk(
    "formEliment/getFormAnswerById",
    async (formData) => {

        try{

            let endPoint = `${apiConfig.adminApi}/form/get-Answers`;
        // let endPoint = `http://localhost:4020/form/get-Answers`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        // console.log("response",response)

        return response.data.data[0];

        } catch(error) {
            throw error;
        }

        
    }
);

export const getActiveTaskList = createAsyncThunk(
    "formEliment/getActiveTaskList",
    async (formData) => {

        console.log("empCode,formData",formData.empCode,formData.payload)
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/active-tasks/${formData.empCode}`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData.payload,config);

        array = response.data


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getFormAnswerListExportReducer = createAsyncThunk(
    "formEliment/getFormAnswerListExportReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-answer`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);

export const getFormAnswerListExportProfileReducer = createAsyncThunk(
    "formEliment/getFormAnswerListExportProfileReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-export-info`;

        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.post(endPoint, formData,config);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);





export const assignFormToComponentReducer = createAsyncThunk(
    "formEliment/assignFormToComponentReducer",
    async (formData) => {


        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/assign-form-to-component`;

        const response = await axios.post(endPoint, formData);

        array = response


        if (array) {
            return array;
        } else {
            throw Error;
        }

    }
);




export const getAllElementKeyReducer = createAsyncThunk(
    "formEliment/getAllElementKeyReducer",
    async () => {

        let array = [];
        var form = JSON.parse(localStorage.getItem("elementKeyList"));

        if (form) {

            array = form
            localStorage.setItem("elementKeyList", JSON.stringify(array));

        }


        if (array) {

            return array;
        } else {
            throw Error;
        }
    }
);

export const getFormId = createAsyncThunk(
    "formEliment/getFormId",
    async (formId) => {
        let array = [];

        let endPoint = `${apiConfig.adminApi}/form/get-form-id/${formId}`;

        const response = await axios.get(endPoint);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);



export const filterValueForm = createAsyncThunk(
    "formEliment/filterValueForm",
    async (data) => {
       

        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);












export const formSettingsSlice = createSlice({
    name: "formEliment",
    initialState,
    extraReducers: {
       
        [getAllFormsDashboard.fulfilled]: (state, action) => {
         
            
            const st=  {
                    forms: action.payload.respons.data,
                    filterForms: action.payload.respons.data.filter((e)=> e.status != 'Deleted' && e.status)
                }
            
            return st
        },
        [getAllFormsReducer.fulfilled]: (state, action) => {
         
            
            const st=  {
                    ...state,
                    allActiveForms:action.payload.respons.data
                }
            
          
            return st
        },
        [filterValueForm.fulfilled]: (state, action) => {
            const status=action.payload.status
            const type=action.payload.type
            
            const st= {
                  ...state,
                  forms:state.forms,
                  filterForms:state.forms.filter(function(data) {
                     
                    if(!data.status){
                        return false
                    }
                    if(data.status=='Deleted'){
                        return false;
                    }
               
                        if (status.find((e)=>e==data.status && type.find((e)=>e==data.formType))) {
                            return true; // skip
                        }

                        if(type.find((e)=>e=='AF')){
                            if (status.find((e)=>e==data.status && (type.find((e)=>e==data.formType) || !data.formType))) {
                                return true; // skip
                            }
                        }
                        
                          
               
                   
                   
                    return false;
                  }).map(function(data) { return data })
                  
                  
               
                    
                }
           
             return st
        },

        
        


    },
});

export default formSettingsSlice.reducer;


