import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getFetchUsers } from "../../../../redux/slices/users";
import {
    addFormElementToServer,
    getFormSchema,
    getAllElementKeyReducer,
    addValueToKeyElementReducer,
    deleteFormElementFromServer,
    deleteFormGroupFromServer,
    getAllGroupRaducer,
    addGroupRaducer,
    changeOrderReducer,
    changeOrderElementReducer,
    addAsShowUserReducer, loadParentFormsReducer, changeAsParentFormReducer, changeDisableElementReducer, connectToSystemUserReducer, changeSchemaValueReducer,
    saveFlowReducer, getFlowReducer, getAllDatabaseUserReducer, changeRejectionMethodReducer, getFormDetails, getAllFormsReducer, saveSelectedProccesingFormReducer, getFormAnswerListExportReducer
} from "../../../../redux/slices/formSettings";
import { FilterOutlined } from '@ant-design/icons';
import { useDispatch, connect } from "react-redux";
import "../style.css";
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ReviewForm } from "../ReviewForm";
import { TestForm } from "../TestForm";
import Skeleton from "@mui/material/Skeleton";
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
} from "@mui/material";

import CalculationArray from '../custom/calculation/CalculationArray';
import {
    Drawer,
    Switch, Typography
} from "antd";


import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";

import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { spacing } from "@mui/system";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";


import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

import SchemaElementEdit from "../compoment/SchemaElementEdit";

import EmailTemplate from "../compoment/EmailTemplate";


import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    FontColorsOutlined,
    CalculatorOutlined,
    DownCircleOutlined,
    CalendarOutlined,
    ProfileOutlined,
    AimOutlined,
} from "@ant-design/icons";

import {
    Row,
    Col,
    Button as AntButton,
    Select as AntSelect,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox as AntCheckBox, Form
} from "antd";

const { Option } = AntSelect;





const WorkFlowMgt = (props) => {
    const { Title, Text } = Typography;
    const approvalType = [
        { const: 'supervisor', value: 'department supervisor' },
        { const: 'department_head', value: 'department head' },
        { const: 'de_supervisor', value: 'division supervisor' },
        { const: 'bu_manager', value: 'division manager' },
        { const: 'user_grade', value: 'User grade' },
        { const: 'custom', value: 'Custom' },
        { const: 'ammount', value: 'Ammount base' },
        { const: 'employee_group', value: 'Employee Group' },
    ]

    const dispatch = useDispatch();
    const [selectedApprovalType, setApprovalType] = useState([])
    const [EmployeeGroupList, setEmployeeGroupList] = useState([])
    const [rejectionMethod, setRejectionMethod] = useState(null)
    const [approvalRow, setApprovalRow] = useState([])


    const [displayRow, setDisplayRow] = useState([])

    const [allDatabaseUsers, setAllDatabAseUsers] = useState([])
    const [openAppovalFlow, setOpenApprovalFlow] = useState(false)
    const [mainApprovalUserType, setMainApprovalUserType] = useState([])
    const [mainApprovalCustomUser, setMainApprovalCustomUser] = useState(null)
    const [approvalMethod, setApprovalMethod] = useState(null)
    const [ruleTargetField, setRuleTargetField] = useState(null)
    const [customSelectedUser, setCustomSelectedUser] = useState(null)
    const [amountBase, setAmountBase] = useState([])
    const [schemaComponent, setScheemaComponent] = React.useState([]);
    const [fieldList, setFieldList] = useState([])
    const [selectedEnumValue, setSelectedEnumValue] = useState([])
    const [targetRuleEnum, setTargetRuleEnum] = useState(null)
    const [isOpenFilter, openFillter] = useState(false)

    const deleteItem = async (row) => {
        let newArray = approvalRow.filter((e) => e.flowStage != row.flowStage)

        if (newArray.length != 0) {
            newArray = newArray.map((data, index) => {
                return {
                    ...data,
                    flowStage: index + 1
                }
            })
        }


        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("id");



        let obj = {
            approvalRow: newArray.length === 0 ? null : newArray,
            formId: myParam
        }
        await dispatch(saveFlowReducer(obj))

        const res = await dispatch(getFlowReducer(myParam))
         
        if (res?.payload?.data?.message) {
            setApprovalRow(res.payload.data.message)
            setDisplayRow(res.payload.data.message)
        } else {
            setApprovalRow([])
            setDisplayRow([])
        }
        notification.success({
            message: `Success`,
            description:
                `Form work flow save...`,

        });


    };

    const getFormAnswerList = async () => {
        const data = {
            formData: '67e28a0a-2ad9-46fa-b611-8cacdfc5d698',
            forExport: true
        };


        const res = await dispatch(getFormAnswerListExportReducer(data));
        if (res?.payload?.data?.data) {
            setEmployeeGroupList(res.payload.data.data)
        }
         

    }

    const init = async () => {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("id");
        const schema = await dispatch(getFormSchema(myParam));
        setScheemaComponent(schema.payload);
        const res = await dispatch(getFlowReducer(myParam))

        await getFormAnswerList()
        const form = await dispatch(getFormDetails(myParam))

        setRejectionMethod(form?.payload?.rejectionMethod ? form.payload.rejectionMethod : null)
         
        if (res?.payload?.data?.message) {
            setApprovalRow(res.payload.data.message)
            setDisplayRow(res.payload.data.message)
        }


        const resUsers = await dispatch(getAllDatabaseUserReducer())
         
        if (resUsers?.payload?.data) {

             
            setAllDatabAseUsers(resUsers.payload.data.filter((e) => e.emCode && e.email))
        }

        //  setOpenApprovalFlow(true)

    }

    const approvalFlowCloss = () => {
        setOpenApprovalFlow(false)
    }
    //

    const addNewFlow = () => {
        if(selectedApprovalType.length === 0){
            notification.error({
                message: `Error`,
                description:
                    `Please add responsible user`,
    
            });
        }
        else
        {
            
            let obj = {
                selectedApprovalType: selectedApprovalType,
                flowStage: approvalRow.length + 1
            }
    
            if (selectedApprovalType.find((e) => e === 'custom')) {
                if (!customSelectedUser) {
                    return
                }
                obj.selectedCustomer = customSelectedUser
            }
            if (selectedApprovalType.find((e) => e === 'ammount')) {
                if (!amountBase) {
                    return
                }
                obj.amountBase = amountBase
            }
    
    
            if (approvalMethod === 'rule') {
                obj.ruleBase = fieldList
            }
    
    
            setApprovalRow(oldArray => [...oldArray, obj]);
            setDisplayRow(oldArray => [...oldArray, obj]);
        }
    };

    const changeRuleTargetField = (e) => {
        setRuleTargetField(e)
        const sc = schemaComponent.find((comp) => comp.keyTxt === e)

         
        if (sc) {
            if (sc.enum) {
                setSelectedEnumValue(sc.enum)
            }
        }
    }

    const submitElementValue = async (values) => {
        setFieldList(values.rules_field ? values.rules_field : [])


    };

    const FilterValue = async (values) => {
        //
         
         
        const datar = approvalRow
        const ruleArra = values.rules_field ? values.rules_field : []

        let tempArr = []
        for (const rule of datar) {
            const ruleBase = rule.ruleBase

            let count = 0
            for (const targetRule of ruleArra) {
                if (ruleBase.find((e) => e.field === targetRule.field && e.value === targetRule.value)) {
                    count++
                }

            }
            if (count == ruleArra.length) {
                tempArr.push(rule)
            }


        }

        setDisplayRow(tempArr)
         ;


    };

    const saveFlow = async () => {
        if(selectedApprovalType.length === 0){
            notification.error({
                message: `Error`,
                description:
                    `Please add responsible user`,
    
            });
        }
        else{
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get("id");
    
            let mainApprovalData = null
            // if (mainApprovalUserType) {
            //     mainApprovalData = {
            //         mainApprovalUserType: mainApprovalUserType
            //     }
            //     if (mainApprovalUserType === 'custom') {
            //         if (!mainApprovalCustomUser) {
            //             alert('please assign main approval user')
            //             return
            //         }
            //         mainApprovalData = {
            //             ...mainApprovalData,
            //             mainApprovalCustomUser: mainApprovalCustomUser
            //         }
    
            //     }
    
            // }
            if (selectedApprovalType) {
                mainApprovalData = {
                    mainApprovalUserType: selectedApprovalType
                }
                if (selectedApprovalType.includes('custom')) {
                    if (!customSelectedUser) {
                        notification.error({
                            message: `Error`,
                            description:
                                `please assign main approval user`,
                
                        });
                        return
                    }
                    mainApprovalData = {
                        ...mainApprovalData,
                        mainApprovalCustomUser: customSelectedUser
                    }
    
                }
    
            }
    
    
    
            if (approvalRow.length != 0) {
    
    
                let obj = {
                    approvalRow: approvalRow,
                    formId: myParam,
                    mainApprovalData: mainApprovalData ? mainApprovalData : null,
                    ruleType: approvalMethod ? approvalMethod : null,
                    ruleBaseElement: ruleTargetField ? ruleTargetField : null
                }
                await dispatch(saveFlowReducer(obj))
            }
    
            notification.success({
                message: `Success`,
                description:
                    `Form work flow save...`,
    
            });
        }

    };
    const changeTargetEnum = (e) => {
        setTargetRuleEnum(e)
    }

    const onFinish = async (values) => {
        setAmountBase(values.rules ? values.rules : [])


    };

    useEffect(() => {
         
        init()
    }, []);

    return (
        <>


            <div className="page-continer ">
                <Modal
                    open={openAppovalFlow}
                    title="Approval Flow"

                    onOk={approvalFlowCloss}
                    onCancel={approvalFlowCloss}
                    width={1000}
                    footer={[
                        <AntButton danger key="back" onClick={approvalFlowCloss}>
                            Return
                        </AntButton>,
                        <AntButton danger key="submit" type="primary" onClick={saveFlow}>
                            Save
                        </AntButton>
                    ]}
                >
                    <div>
                        <AntSelect
                            showSearch
                            style={{ width: '100%' }}
                            className="flow-input"
                            placeholder="Approval method"
                            optionFilterProp="children"
                            onChange={(e) => { setApprovalMethod(e) }}
                        >


                            <Option value='static'>STATIC</Option>
                            <Option value='rule'>RULE</Option>


                        </AntSelect>
                    </div>
                    <div>
                        <>
                            {/* <AntSelect
                                showSearch
                                required
                                style={{ width: '100%' }}
                                className="flow-input"
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={(e) => { console.log(mainApprovalUserType); setMainApprovalUserType(prevVal => [...prevVal,e])}}
                                // onChange={(e) => { setMainApprovalUserType(e)}}
                            >

                                {approvalType.map((row, index) => (
                                    <Option value={row.const}> {row.value}</Option>
                                ))}


                            </AntSelect> */}

                                {/* {console.log("here", mainApprovalUserType)}
                            {
                                mainApprovalUserType.find((e) => e === 'custom') ?
                                    <AntSelect
                                        mode="multiple"
                                        required
                                        showSearch
                                        className="flow-input"
                                        style={{ width: '100%' }}
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={(e) => {console.log(mainApprovalCustomUser); setMainApprovalCustomUser(e) }}
                                    >

                                        {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                                            <Option value={row.emCode}> {row.email}</Option>
                                        ))}


                                    </AntSelect>
                                    : null
                            } */}
                            {/* <AntButton danger key="submit" type="primary" onClick={addNewFlow}>
                                ADD F
                            </AntButton> */}

                        </>
                        {
                            approvalMethod === 'rule' ? <>
                                <>


                                    <Form style={{ width: '100%'}} name="dynamic_form_nest_item" onFinish={submitElementValue} autoComplete="off">
                                        <Form.List name="rules_field">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space className="aray-div-me" key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">


                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'field']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <AntSelect
                                                                    style={{ width: '250px' }}
                                                                    showSearch
                                                                    placeholder="Select person"
                                                                    allowClear
                                                                >
                                                                    {schemaComponent.map((row, index) => (
                                                                        <Option value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}



                                                                </AntSelect>
                                                            </Form.Item>

                                                            <Form.Item dependencies={[["rules_field", name, "field"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["field"] ? type[name]["field"] : null

                                                                        const sc = schemaComponent.find((comp) => comp.keyTxt === nameLevel)

                                                                         
                                                                        if (sc) {
                                                                            if (sc.enum) {
                                                                                enumArray = sc.enum
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                    }

                                                                    //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;

                                                                    return (
                                                                        <>
                                                                            {
                                                                                showValue ?
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <AntSelect
                                                                                            style={{ width: '250px' }}
                                                                                            showSearch
                                                                                            placeholder="Select person"
                                                                                            allowClear
                                                                                        >
                                                                                            {enumArray.map((row, index) => (
                                                                                                <Option value={row.const}>{row.title}</Option>
                                                                                            ))}




                                                                                        </AntSelect>
                                                                                    </Form.Item>
                                                                                    : null
                                                                            }
                                                                        </>



                                                                    );
                                                                }}
                                                            </Form.Item>

                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add field
                                                        </AntButton>
                                                    </Form.Item>


                                                </>
                                            )}
                                        </Form.List>
                                        <Form.Item>
                                            <AntButton type="primary" htmlType="submit">
                                                SAVE BEFORE MOVE FURTHER
                                            </AntButton>
                                        </Form.Item>
                                    </Form>
                                </>
                            </> : null
                        }

                    </div>
                    Main Responsible User
                    <AntSelect
                        showSearch
                        mode="multiple"
                        className="flow-input"
                        style={{ width: '100%' }}
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={(e) => { setApprovalType(e) }}
                    >

                        {approvalType.map((row, index) => (
                            <Option value={row.const}> {row.value}</Option>
                        ))}


                    </AntSelect>


                    {
                        selectedApprovalType.find((e) => e === 'custom') ?
                            <AntSelect
                                mode="multiple"
                                showSearch
                                className="flow-input"
                                style={{ width: '100%' }}
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={(e) => { setCustomSelectedUser(e) }}
                            >

                                {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                                    <Option value={row.emCode}> {row.email}</Option>
                                ))}


                            </AntSelect>
                            : null
                    }
                    {
                        selectedApprovalType.find((e) => e === 'ammount') ?
                            <div style={{ width: '100%' }}>

                                <Form style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                                    <Form.List name="rules">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space className="aray-div-me" key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">


                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'min_value']}
                                                        >
                                                            <Input style={{ width: '80px' }} placeholder="min ammount" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'max_value']}
                                                        >
                                                            <Input style={{ width: '80px' }} placeholder="max amount" />
                                                        </Form.Item>

                                                        <Form.Item

                                                            {...restField}
                                                            name={[name, 'action']}
                                                            rules={[{ required: false, message: 'Missing Field' }]}
                                                        >
                                                            <AntSelect
                                                                style={{ width: '250px' }}
                                                                mode="multiple"
                                                                showSearch
                                                                placeholder="Select person"
                                                                allowClear
                                                            >
                                                                {approvalType.filter((e) => e.const !== 'ammount').map((row, index) => (
                                                                    <Option value={row.const}> {row.value}</Option>
                                                                ))}

                                                                {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                                                                    <Option value={row.emCode}> {row.email}</Option>
                                                                ))}


                                                            </AntSelect>
                                                        </Form.Item>
                                                        <Form.Item dependencies={[["rules", name, "action"]]}>
                                                            {({ getFieldValue }) => {
                                                                const type = getFieldValue("rules") ? getFieldValue("rules") : null
                                                                let showValue = false
                                                                if (type[name]) {
                                                                    const nameLevel = type[name]["action"] ? type[name]["action"] : null
                                                                    if (nameLevel.find((e) => e === 'employee_group')) {
                                                                        showValue = true
                                                                    } else {
                                                                        showValue = false
                                                                    }
                                                                     ;
                                                                     ;
                                                                }

                                                                //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;

                                                                return (
                                                                    <>
                                                                        {
                                                                            showValue ?
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'groupId']}
                                                                                >
                                                                                    <AntSelect
                                                                                        style={{ width: '250px' }}
                                                                                        mode="multiple"
                                                                                        showSearch
                                                                                        placeholder="Select person"
                                                                                        allowClear
                                                                                    >
                                                                                        {EmployeeGroupList.map((row, index) => (
                                                                                            <Option value={row.id}> {row?.data?.database?.main?.GROUP_NAME ? row.data.database.main.GROUP_NAME : '-'}</Option>
                                                                                        ))}




                                                                                    </AntSelect>
                                                                                </Form.Item>
                                                                                : null
                                                                        }
                                                                    </>



                                                                );
                                                            }}
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Add field
                                                    </AntButton>
                                                </Form.Item>


                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <AntButton type="primary" htmlType="submit">
                                            SAVE BEFORE MOVE FURTHER
                                        </AntButton>
                                    </Form.Item>
                                </Form>


                            </div>
                            : null
                    }
                    <AntButton danger key="submit" type="primary" onClick={addNewFlow}>
                        ADD
                    </AntButton>


                </Modal>
                <AntButton type="primary" danger onClick={setOpenApprovalFlow}>ADD</AntButton>
                <AntButton type="" danger onClick={() => { openFillter(!isOpenFilter) }} icon={<FilterOutlined />} />
                <Drawer
                    className="settings-drawer"
                    mask={true}
                    width={700}
                    onClose={() => { openFillter(!isOpenFilter) }}
                    //   placement={placement}
                    open={isOpenFilter}
                >
                    <div layout="vertical">
                        <div className="header-top">
                            <Title level={4}>
                                Filtter(s)
                            </Title>

                        </div>

                        <div className="sidebar-color">
                            <div className="">
                                <div className="">
                                    <Form style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={FilterValue} autoComplete="off">
                                        <Form.List name="rules_field">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space className="aray-div-me" key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">


                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'field']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <AntSelect
                                                                    style={{ width: '250px' }}
                                                                    showSearch
                                                                    placeholder="Select person"
                                                                    allowClear
                                                                >
                                                                    {schemaComponent.map((row, index) => (
                                                                        <Option value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}



                                                                </AntSelect>
                                                            </Form.Item>

                                                            <Form.Item dependencies={[["rules_field", name, "field"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["field"] ? type[name]["field"] : null

                                                                        const sc = schemaComponent.find((comp) => comp.keyTxt === nameLevel)

                                                                         
                                                                        if (sc) {
                                                                            if (sc.enum) {
                                                                                enumArray = sc.enum
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                    }

                                                                    //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;

                                                                    return (
                                                                        <>
                                                                            {
                                                                                showValue ?
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <AntSelect
                                                                                            style={{ width: '250px' }}
                                                                                            showSearch
                                                                                            placeholder="Select person"
                                                                                            allowClear
                                                                                        >
                                                                                            {enumArray.map((row, index) => (
                                                                                                <Option value={row.const}>{row.title}</Option>
                                                                                            ))}




                                                                                        </AntSelect>
                                                                                    </Form.Item>
                                                                                    : null
                                                                            }
                                                                        </>



                                                                    );
                                                                }}
                                                            </Form.Item>

                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            ADD FILTER
                                                        </AntButton>
                                                    </Form.Item>


                                                </>
                                            )}
                                        </Form.List>
                                        <Form.Item>
                                            <AntButton type="primary" htmlType="submit">
                                                FILTER
                                            </AntButton>
                                        </Form.Item>
                                    </Form>

                                </div>
                            </div>




                        </div>


                    </div>


                </Drawer>


                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>STAGE</TableCell>
                                <TableCell>TARGET FIELD</TableCell>
                                <TableCell>APPROVAL METHOD</TableCell>
                                <TableCell>type</TableCell>
                                {/* <TableCell>Email</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Action</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {displayRow.map((row, index) => (
                                <TableRow>
                                    
                                    <TableCell>

                                        <IconButton
                                            size="small"
                                            sx={{ color: "red" }}
                                            onClick={() => { deleteItem(row) }}
                                            aria-label="upload picture"
                                            component="span"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>

                                        {index+1}
                                    </TableCell>
                                    <TableCell>

                                        {
                                            row.ruleBase ? <>
                                                {
                                                    row.ruleBase.map((ruleSet) => (
                                                        <div>
                                                            <div>Target Field :  {ruleSet.field}</div>
                                                            <div>Target Value :  {ruleSet.value}</div>
                                                            <hr />
                                                        </div>
                                                    ))
                                                }

                                            </> : null
                                        }
                                    </TableCell>
                                    <TableCell>

                                        {row.selectedApprovalType.toString()}
                                    </TableCell>
                                    <TableCell>
                                        <>
                                            <div>
                                                {
                                                    row.selectedApprovalType == 'custom' ?

                                                        <>
                                                            {
                                                                row.selectedCustomer.toString()
                                                            }
                                                        </>


                                                        :
                                                        row.selectedApprovalType == 'ammount' ?

                                                            <div>
                                                                <table>
                                                                    <tr>
                                                                        <th>MIN</th>
                                                                        <th>MAX</th>
                                                                        <th>APPROVAL</th>
                                                                    </tr>



                                                                    {
                                                                        row.amountBase ? <>
                                                                            {row.amountBase.map((rowAMount, indexAmount) => (
                                                                                <tr>
                                                                                    <td>{rowAMount.min_value ? rowAMount.min_value : null}</td>
                                                                                    <td>{rowAMount.max_value ? rowAMount.max_value : null}</td>
                                                                                    <td>{rowAMount.action ?
                                                                                        rowAMount.action.map((eAction) => (
                                                                                            <div>
                                                                                                {eAction}

                                                                                                {
                                                                                                    eAction === 'employee_group' ? <>
                                                                                                        {
                                                                                                            rowAMount.groupId.map((eGroup) => (
                                                                                                                <div>{
                                                                                                                    EmployeeGroupList.find((eGrList) => eGrList.id === eGroup)?.data?.database?.main?.GROUP_NAME ?
                                                                                                                        EmployeeGroupList.find((eGrList) => eGrList.id === eGroup).data.database.main.GROUP_NAME : null
                                                                                                                }</div>
                                                                                                            ))
                                                                                                        }

                                                                                                    </> : null
                                                                                                }
                                                                                                {/* {EmployeeGroupList.map((row, index) => (
                                                          <Option value={row.id}> {row?.data?.database?.main?.GROUP_NAME ? row.data.database.main.GROUP_NAME : '-'}</Option>
                                                        ))} */}
                                                                                            </div>
                                                                                        ))
                                                                                        : null}</td>
                                                                                </tr>

                                                                            ))}
                                                                        </> : null
                                                                    }
                                                                </table>
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    row.selectedApprovalType.toString()
                                                                }
                                                            </>

                                                }
                                            </div>

                                        </>

                                    </TableCell>





                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>

            </div>



        </>
    );
};


export default WorkFlowMgt
