import React, { useState, useEffect } from "react";
import {
    useDispatch,connect
} from "react-redux";
import './style.css'
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

import { AnswerList } from './compoment/AnswerList'




import { getFormSchema, getAllGroupRaducer } from "../../../redux/slices/formSettings"








const ViewAnswers = (props) => {



    const { t } = useTranslation();

    const dispatch = useDispatch();


    const Card = styled(MuiCard)(spacing);

    const Divider = styled(MuiDivider)(spacing);





    const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);



    const [answerListSelectedId, setListSelectedId] = useState(null)
    const [groupList, setGroupList] = useState([])

    const [schema, setScheemaComponent] = useState([])


    const [isLoadig, setLoading] = useState(false)

    const [columns, setColomn] = useState([]);

    const getFormData = async (formId) => {
        setLoading(true)

        const formSchema = await dispatch(getFormSchema(formId));
        const gg = await dispatch(getAllGroupRaducer(formId));

        let colArray = []
        const widthcol = (90 / formSchema.payload.length)
        colArray.push(
            {
                title: "Created At",
                key: "createdAt",
                dataIndex: "createdAt",
                width: '5%',
            },
        )
        for (const scemaEl of formSchema.payload) {

            if(scemaEl.showInHeader){
                colArray.push({
                    title: scemaEl.lableTxt,
                    dataIndex: scemaEl.keyTxt,
                    key: scemaEl.keyTxt,
                    width: widthcol + '%',
                    sorter: (a, b) => a.keyTxt - b.keyTxt,
                })
            }
           


        }

        colArray.push(
            {
                title: "Action",
                key: "action",
                dataIndex: "action",
                width: '5%'
            },
        )
        setColomn(colArray)

        if (gg.payload) {
            setGroupList(gg.payload)
        }

        if (formSchema.payload) {
            setScheemaComponent(formSchema.payload)
        }
        setLoading(false)


    }



    const initProp = (id) => {
        setLoading(true)
        setListSelectedId(id)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        getFormData(myParam)
        initProp(myParam)
        setLoading(false)

    }, []);


    return (
        <>
            <React.Fragment>
                
                <div className="page-continer">

                                    {
                                        isLoadig ? null :
                                            <AnswerList formId={answerListSelectedId} schema={schema} groupList={groupList} columns={columns} />
                                    }

                </div>












            </React.Fragment>
        </>
    )
}

export default ViewAnswers;
