import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@mui/material";
import {
    ExportOutlined,
    SearchOutlined,
    ImportOutlined,
    FileAddOutlined,
    SettingTwoTone,
    DeleteOutlined,
    EditOutlined,
    ForwardOutlined,
    CopyOutlined,
    UnorderedListOutlined,
    SettingOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";



import { FormGenarator } from "../../JsonForm/intefaces/FormGenarator";

import { getAllLanguage, addLanguageBulk } from "../../../../redux/slices/language";

import { updateSetting, deleteSideBarItem, deleteSideBarHeader, manageSideBarHeader, editSideBarHeader, addSideBarItem, editSideBarItem, getSiteBarHeader } from "../../../../redux/slices/configSetting";

import {
    getAllFormsReducer
} from "../../../../redux/slices/formSettings";


import {
    changeHeaderVisibilityReducer, getAllHeaderByRole, changeSiderListVisibilityReducer,changeListActionReducer
} from "../../../../redux/slices/permissionSetting";


import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { spacing } from "@mui/system";

import {
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form
} from "antd";
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import * as XLSX from "xlsx";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;
const children = [];

const headerVisibility = [
    {
        label: 'Show',
        value: true,
    },
    {
        label: 'Hide',
        value: false,
    },
];

const actionCapability = [
    {
        label: 'NOT',
        value: false,
    },
    {
        label: 'YES',
        value: true,
    },
];


const PermisionEdit = (props) => {

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();


    const [tabPosition, setTabPosition] = useState('left');


    const [activeKey, setActiveKey] = useState('settings');

    const [openMainAdd, setMainAddOpen] = useState(false);



    //side header

    const [newSideHeaderTitle, setNewSideHeaderTitle] = useState('')
    const [newSideHeaderKey, setNewSideHeaderKey] = useState('')


    const [selectedRole, setSelectedRole] = useState(null)
    // const [listForm,setListForm]=useState([])

    const tempHeaderItems = useSelector(state => state.permision.sideMenuItems)


    const tempHeaderItemssss = useSelector(state => state)

    const listForm = useSelector((state) => {

        let list = []
        if (state?.formSettings?.allActiveForms) {
            for (const form of state.formSettings.allActiveForms) {
                let obj = {
                    label: form.name,
                    key: form.id,
                    data: form
                }
                list = [...list, obj]
            }
        }
        return list
        // setListForm(list)


    })

    const tabClick = (e) => {

        setActiveKey(e)
    }

    const onEdit = (targetKey, action) => {
        if (action === 'add') {
            setMainAddOpen(true)
        } else {
            //remove(targetKey);
        }
    };



    const addNewSideHeader = () => {

        let tempArray = tempHeaderItems
        if (tempArray.find((e) => e.key === newSideHeaderKey)) {

            const payload = {
                key: newSideHeaderKey,
                title: newSideHeaderTitle,
                editable: true,
                hide: true
            }
            dispatch(editSideBarHeader(payload))

        } else {
            const payload = {
                key: newSideHeaderKey,
                title: newSideHeaderTitle,
                editable: true,
                hide: true
            }
            dispatch(manageSideBarHeader(payload))
        }
        setNewSideHeaderTitle('')
        setNewSideHeaderKey('')
        setMainAddOpen(false)

        return
    }



    const init = () => {
        //
        setSelectedRole(props.match.params.role)
        dispatch(getAllHeaderByRole(props.match.params.role))
        dispatch(getAllFormsReducer(true))
    }


    const changeSiderHeaderVisibility = async (key, e) => {
        const obj = {
            value: e.target.value,
            key: key,
            role: selectedRole
        }
        await dispatch(changeHeaderVisibilityReducer(obj))
        dispatch(getSiteBarHeader())
        dispatch(getAllHeaderByRole(selectedRole))
    }


    const changeSiderListVisibility = async (key, e) => {
        const obj = {
            value: e.target.value,
            key: key,
            role: selectedRole
        }
        await dispatch(changeSiderListVisibilityReducer(obj))
        dispatch(getSiteBarHeader())
        dispatch(getAllHeaderByRole(selectedRole))
    }

    const changeListAction = async (key, e,action) => {
        const obj = {
            value: e.target.value,
            key: key,
            role: selectedRole,
            action
        }
        await dispatch(changeListActionReducer(obj))
        // dispatch(getSiteBarHeader())
        dispatch(getAllHeaderByRole(selectedRole))
    }

    useEffect(() => {

        // createContainer()
        init()

    }, []);

    return (

        <React.Fragment>
            <Grid container spacing={6}>

                <Grid item xs={12}>

                    <div className="page-continer ">


                        <Tabs onTabClick={(e) => { tabClick(e) }} tabPosition={tabPosition} activeKey={activeKey} onEdit={onEdit}>

                            {
                                tempHeaderItems.map((row) => (
                                    <TabPane tab={row.title} key={row.key}>


                                        <Space direction="vertical" style={{ width: '100%' }}>
                                    
                                            <div className="role-permision-main-header">
                                                <Radio.Group
                                                    options={headerVisibility}
                                                    onChange={(e) => { changeSiderHeaderVisibility(row.key, e) }}
                                                    value={row.hasAccess ? true : false}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />


                                            </div>
                                            {
                                                row.items ? <>
                                                    {


                                                        row.items.map((item, index) => (
                                                            <Collapse key={index} collapsible="header">
                                                                <Panel header={<>
                                                                    {item.title}
                                                                    {

                                                                    }
                                                                    <div className="role-permision-main-item">
                                                                        <Radio.Group
                                                                            options={headerVisibility}
                                                                            onChange={(e) => { changeSiderListVisibility(item.key, e) }}
                                                                            value={item.hasAccess ? true : false}
                                                                            optionType="button"
                                                                            buttonStyle="solid"
                                                                        />

                                                                    </div>
                                                                </>} key={item.key}>

                                                                    <div className='colapse-body'>
                                                                        <Row>
                                                                            <Col xs={24} sm={24} md={6} lg={6} style={{marginTop:10}}>
                                                                                ADD ACCESS
                                                                                <Radio.Group
                                                                                style={{marginLeft:15}}
                                                                                    options={actionCapability}
                                                                                    onChange={(e) => { changeListAction(item.key, e,'ADD') }}
                                                                                   value={item?.Add}
                                                                                    optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Col>
                                                                            <Col xs={24} sm={24} md={6} lg={6} style={{marginTop:10}}>
                                                                                DELETE ACCESS
                                                                                <Radio.Group
                                                                                style={{marginLeft:15}}
                                                                                    options={actionCapability}
                                                                                    onChange={(e) => { changeListAction(item.key, e,'DELETE') }}
                                                                                   value={item?.Delete}
                                                                                    optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Col>
                                                                            <Col xs={24} sm={24} md={6} lg={6} style={{marginTop:10}}>
                                                                                UPDATE ACCESS
                                                                                <Radio.Group
                                                                                style={{marginLeft:15}}
                                                                                    options={actionCapability}
                                                                                    onChange={(e) => { changeListAction(item.key, e,'UPDATE') }}
                                                                                   value={item?.Update}
                                                                                    optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Col>
                                                                            <Col xs={24} sm={24} md={6} lg={6} style={{marginTop:10}}>
                                                                                IMPORT ACCESS
                                                                                <Radio.Group
                                                                                style={{marginLeft:15}}
                                                                                    options={actionCapability}
                                                                                    onChange={(e) => { changeListAction(item.key, e,'IMPORT') }}
                                                                                   value={item?.Import}
                                                                                    optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Col>
                                                                            <Col xs={24} sm={24} md={6} lg={6} style={{marginTop:10}}>
                                                                                EXPORT ACCESS
                                                                                <Radio.Group
                                                                                style={{marginLeft:15}}
                                                                                    options={actionCapability}
                                                                                    onChange={(e) => { changeListAction(item.key, e,'EXPORT') }}
                                                                                   value={item?.Export}
                                                                                    optionType="button"
                                                                                    buttonStyle="solid"
                                                                                />
                                                                            </Col>

                                                                        </Row>

                                                                    </div>





                                                                </Panel>
                                                            </Collapse>

                                                        ))
                                                    }
                                                </> : null

                                            }
                                        </Space>
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                    </div>
                </Grid>
            </Grid>

            <Modal
                visible={openMainAdd}
                title="Add Side Menu Item"
                onOk={() => { setMainAddOpen(false) }}
                onCancel={() => { setMainAddOpen(false) }}
                footer={[
                    <Button danger key="back" onClick={() => { setMainAddOpen(false) }}>
                        Return
                    </Button>,
                    <Button danger type="primary" onClick={() => { addNewSideHeader() }}>
                        Submit
                    </Button>
                ]}
            >
                <div>
                    Title
                    <Input value={newSideHeaderTitle} onChange={(e) => setNewSideHeaderTitle(e.target.value)} placeholder="title" />

                </div>

                <div>
                    Key
                    <Input value={newSideHeaderKey} onChange={(e) => setNewSideHeaderKey(e.target.value)} placeholder="Key" />

                </div>


            </Modal>

        </React.Fragment>

    );
};




export default PermisionEdit
