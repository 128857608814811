import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
    visible:false,
    activeMenu: null,
    activeSubMenu: null
};

export const setActiveMenu = createAsyncThunk(
    "sideNev/setActiveMenu",
    async (status) => {
        return status
    }
)

export const setActiveSubMenu = createAsyncThunk(
    "sideNev/setActiveSubMenu",
    async (status) => {
        return status
    }
)


export const openSideNev = createAsyncThunk(
    "sideNev/openSideNev",
    async () => {
       return true
    }
);


export const closeSideNev = createAsyncThunk(
    "sideNev/closeSideNev",
    async () => {
        return false
    }
);



export const sideNevSlice = createSlice({
    name: "sideNev",
    initialState,
    extraReducers: {

        [openSideNev.fulfilled]: (state, action) => {

            return {
                ...state,
                open: true,
            }
        },
        [closeSideNev.fulfilled]: (state, action) => {

            return {
                ...state,
                open: false,
            }
        },
        [setActiveMenu.fulfilled]: (state, action) => {
            return {
                ...state,
                activeMenu: action.payload,
            }
        },
        [setActiveSubMenu.fulfilled]: (state, action) => {
            return {
                ...state,
                activeSubMenu: action.payload,
            }
        },
        
    },
});

export default sideNevSlice.reducer;


