import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../../config";

let initialState = {
    show:false
};





export const manageFilterDrawer = createAsyncThunk(
    "filterList/manageFilterDrawer",
    async (data) => {
         


        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);





export const attandanceFilterSlice = createSlice({
    name: "filterList",
    initialState,
    extraReducers: {

        [manageFilterDrawer.fulfilled]: (state, action) => {

             
            return {
                ...state,
            }
        },

    },
});

export default attandanceFilterSlice.reducer;


