class Auth{

    constructor(){
        this.authenticated=false
    }

    // login(token,cb){
    //     localStorage.setItem("token", token);
    //     const tempToken=localStorage.getItem('token')
    //     if(tempToken){
    //         this.authenticated =true    
    //     }else{
    //         this.authenticated =false 
    //     }
       
    //     cb(this.authenticated)
    // }

    login(token, cb) {
      localStorage.setItem('token', token);
      this.authenticated = !!localStorage.getItem('token');
      cb(this.authenticated);
    }
    

    logout(cb){
        localStorage.removeItem('token')
        localStorage.removeItem('emCode');
        this.authenticated=false
        cb()
    }
    
    isAuthenticated() {
      const token = localStorage.getItem('token');
    
      this.authenticated = token ? true : false;
    
      return this.authenticated;
    }
    


}

export default new Auth()