
import { AimOutlined, CalculatorOutlined, CalendarOutlined, DownCircleOutlined, FileAddOutlined, FontColorsOutlined, ProfileOutlined } from '@ant-design/icons';
import ReactSelect, { components } from 'react-select'

const FormElement = ({ changeSelectedType, setIsLoadForm, selectedTextType }) => {

    const addElementOption = [
        { label: 'Text', value: 'string', icon: <FontColorsOutlined /> },
        { label: 'Number', value: 'number', icon: <CalculatorOutlined /> },
        { label: 'Dropdown', value: 'drop', icon: <DownCircleOutlined /> },
        { label: 'Dynamic Content', value: 'dynamicContent', icon: <DownCircleOutlined /> },
        { label: 'Dropdown Tree', value: 'dropTree', icon: <DownCircleOutlined /> },
        { label: 'Date', value: 'date', icon: <CalendarOutlined /> },
        { label: 'Checkbox', value: 'check', icon: <ProfileOutlined /> },
        { label: 'Location', value: 'location', icon: <AimOutlined /> },
        { label: 'Location Share', value: 'locationShare', icon: <AimOutlined /> },
        { label: 'File', value: 'File', icon: <FileAddOutlined /> },
        { label: 'HTML CAMERA', value: 'html_camera', icon: <FileAddOutlined /> },
        { label: 'Total', value: 'total', icon: <FileAddOutlined /> },
        { label: 'Clearing', value: 'clearing', icon: <FileAddOutlined /> },
        { label: 'Index', value: 'index', icon: <FileAddOutlined /> },
        { label: 'Data Grid', value: 'data_grid', icon: <FileAddOutlined /> },
        { label: 'Lable', value: 'lable_draft', icon: <FileAddOutlined /> },
        { label: 'Form', value: 'Form', icon: <FileAddOutlined /> },
        { label: 'TIME LINE', value: 'timeLine', icon: <FileAddOutlined /> },
        { label: 'REPEAT', value: 'repeat', icon: <FileAddOutlined /> },
        { label: 'CALCULATION', value: 'calculation', icon: <FileAddOutlined /> },
        { label: 'NEAR SHOPS', value: 'shops', icon: <FileAddOutlined /> },
        { label: 'CLOCKED', value: 'cloacked', icon: <FileAddOutlined /> },
        { label: 'Answer View', value: 'answer_view', icon: <FileAddOutlined /> },
        { label: 'NEXT', value: 'move_to_next_stage', icon: <FileAddOutlined /> },
        { label: 'SET TIMMER', value: 'formTimeOut', icon: <FileAddOutlined /> },
        { label: 'Auto Fill', value: 'autofill', icon: <FileAddOutlined /> },
        { label: 'Sorting Option', value: 'sortBy', icon: <FileAddOutlined /> },
    ]

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            {props.data.icon} &nbsp; &nbsp;
            {props.data.label}
        </Option>
    );
    const selectedOption = addElementOption.filter((item) => item.value === selectedTextType)
    return <ReactSelect
        options={addElementOption}
        components={{ Option: IconOption }}
        defaultValue={selectedOption[0]}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        onChange={(option) => {
            changeSelectedType(option.value)
            if (['data_grid', 'lable_draft', 'Form', 'timeLine', 'repeat', 'calculation', 'shops', 'cloacked', 'answer_view', 'move_to_next_stage', 'formTimeOut', 'autofill', 'sortBy'].includes(option.value)) {
                setIsLoadForm(true)
            }
        }}
    />
}

export default FormElement