import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getFetchUsers } from "../../../../redux/slices/users";
import { getAllFormsReducer } from "../../../../redux/slices/formSettings";
import data from './el.json';
import {
  addFormElementToServer,
  getFormSchema,
  editFormElementToServer,
  getAllElementKeyReducer,
  addValueToKeyElementReducer,
  deleteFormElementFromServer,
  deleteFormGroupFromServer,
  getAllGroupRaducer,
  addGroupRaducer,
  changeOrderReducer,
  changeOrderElementReducer, loadParentFormsReducer,
} from "../../../../redux/slices/formSettings";
import { useDispatch, connect } from "react-redux";
import "../style.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Icon,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SendIcon from "@mui/icons-material/Send";

import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import Filter2Icon from "@mui/icons-material/Filter2";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { spacing } from "@mui/system";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

import {
  FileAddOutlined,
  FontColorsOutlined,
  CalculatorOutlined,
  DownCircleOutlined,
  CalendarOutlined,
  ProfileOutlined,
  AimOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  Row,
  Col,
  Radio,
  Button as AntButton,
  Select as AntSelect,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Input,
  Modal,
} from "antd";
import FormElement from "../FormElements";
const { Option } = AntSelect;

const SchemaElementEdit = (props) => {
  console.log('props', props)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const acceptedFileType = [
    {
      const:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      lable: "EXCEL",
    },
    {
      const: "image/*",
      lable: "image",
    },
    {
      const: "video/*",
      lable: "video",
    },
    {
      const: "audio/*",
      lable: "audio",
    },
    {
      const: ".pdf",
      lable: "PDF",
    },
  ];

  const { t } = useTranslation();

  const Divider = styled(MuiDivider)(spacing);

  const [elementOption, setElementOption] = React.useState({
    lableTxt: null,
    keyTxt: null,
  });

  const [open, setOpen] = React.useState(false);
  const [openManageElementKey, setOpenManagementElementKey] =
    React.useState(false);

  const [selectedTextType, changeSelectedType] = React.useState(null);

  const [dropDownValue, setDropDownVal] = React.useState([]);
  const [selectedFormId, setSelectedFormId] = React.useState(null);
  const [orderingValue, setOrderingValue] = React.useState([]);
  const [sortRefreshed, setSortRefreshed] = React.useState(true);

  const [orderLabel, setOrderLable] = useState('')
  const [valueDropDown, addValueDropDown] = React.useState(null);
  const [valueDropDownConst, addValueDropDownConst] = React.useState(null);
  const [schemaComponent, setScheemaComponent] = React.useState([]);
  const [defaultLableValue, changeLableDefaula] = useState(null)
  const [openAddFormEliment, openFormEliment] = React.useState(false);

  const [elementKeyList, manageElementKeyList] = React.useState([]);
  const [availableElementKeyList, manageAvailableElementKeyList] =
    React.useState([]);
  const [keyElementValueTxt, setKeyElementValueTxt] = React.useState("");

  const [fileStoreType, changeFileStoreType] = React.useState("s3");
  const [s3Bucket, changeS3Bucket] = React.useState("");
  const [s3Dir, changeS3Dir] = React.useState("");

  const [groupNametxt, setGroupNameTxt] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [groupColtxt, setGroupColtxt] = useState("");

  const [isOpenAdvance, setAdvalceOpen] = useState(false);

  const [groupLabletxt, setGroupLabletxt] = useState("");
  const [groupTypetxt, setGroupTypetxt] = useState("object");

  const [groupList, setGroupList] = useState([]);
  const [elementByGroup, setElementByGroup] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("main");

  const [savingType, setSavingType] = useState("bitrix");

  const [textFormModel, openTextFormModel] = useState(false);

  const [selectedFileType, setSelectedFileType] = React.useState([]);

  const [fileSource, setFileSource] = React.useState("local");

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [isLoadingElement, loadingElement] = useState(true);

  const [loading, isLoading] = useState(false);
  const [requier, setRequred] = useState(false);
  const [reference, setReference] = useState(false);

  const [selectedGroupConst, setSelectedGroupConst] = useState("");

  const [enumLoadType, setEnumLoadType] = useState('custom');


  const [referenceKey, setReferenceKey] = useState(null);


  const [enumConst, setEnumCost] = useState(null);

  const [enumLable, setEnumLable] = useState(null);


  const [grouped, setGrouped] = useState(null);
  const [isLoadForm, setIsLoadForm] = useState(false)

  //advance
  const [showInHeader, setShowInHeader] = useState(false);
  const [searchableKey, setSearchableKey] = useState(false);
  const [sortable, setSortable] = useState(false);


  //form

  const [activeFormList, setActiveFormList] = useState([])
  const [selectedFormForPayload, setSelectedFormForPayload] = useState(null)
  const [selectFormEnumSet, setSelectedFormEnumSet] = useState([])

  const [selectedParentFormSchemaId, setselectedParentFormSchemaId] = useState(null)


  const [selectType, setSelectType] = useState('single')

  const modifyFileType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFileType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // component state

  const [elementManageFormType, setElementManageFormType] = useState("ADD");
  //const [elementKeyListEdit, manageElementKeyListEdit] = React.useState([]);

  const handleCloseTestForm = () => {
    openTextFormModel(false);
  };
  const handleOpenTestForm = () => {
    openTextFormModel(true);
  };

  const requeredChecked = () => {
    setRequred(!requier);
  };

  const referenceChacked = () => {
    setReference(!reference);
  };

  const handleCloseManageElementKey = () => {
    setOpenManagementElementKey(false);
  };
  const handleOpenManageElementKey = () => {
    setOpenManagementElementKey(true);
  };
  const [elemtnDeletePromt, setElemtnDeletePromt] = useState(false);
  const [selectedDeletElementData, setSelectedDeletElementData] =
    useState(null);

  const [isOpenEditSchema, setEditSchema] = useState(false);

  const openEditSchema = () => {
    setEditSchema(true);
  };
  const closeEditSchema = () => {
    setEditSchema(false);
  };

  const deleteFormComponent = (data) => {
    setSelectedDeletElementData(data);

    setElemtnDeletePromt(true);
  };
  const deleteElementConfirmed = async () => {
    let payload = {
      formId: selectedFormId,
      schemaKey: selectedDeletElementData.keyTxt,
    };

    await dispatch(deleteFormElementFromServer(payload));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }
    setElemtnDeletePromt(false);
  };

  const [groupDeletePromt, setGroupDeletePromt] = useState(false);
  const [selectedDeletGroupData, setSelectedDeletGroupData] = useState(null);

  const deleteFormGroup = (data) => {
    setSelectedDeletGroupData(data);

    setGroupDeletePromt(true);
  };
  const deleteGroupConfirmed = async () => {
    let payload = {
      formId: selectedFormId,
      groupId: selectedDeletGroupData.groupId,
    };

    await dispatch(deleteFormGroupFromServer(payload));
    getAllGroup();
    setElemtnDeletePromt(false);
  };

  const editFormComponent = async () => {
    setElementManageFormType("EDIT");

    const result = await dispatch(getAllElementKeyReducer());

    let elKeyArray = result.payload;

    let tempArray = [];
    for (const element of elKeyArray) {
      tempArray.push(element);
    }
    manageAvailableElementKeyList(tempArray);
  };

  const addValueToGroup = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    let obj = {
      formId: myParam,
      groupName: groupNametxt,
      groupLable: groupLabletxt,
      groupType: groupTypetxt,
      groupColtxt: groupColtxt,
    };

    if (groupTypetxt === "stage") {
      obj["selectedCategory"] = selectedCategory;
    }

    const res = await dispatch(addGroupRaducer(obj));

    if (res) {
      getAllGroup();
    }
  };

  const getAllGroup = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const res = await dispatch(getAllGroupRaducer(myParam));

    if (res.payload) {
      setGroupList(res.payload);
    }
  };

  const addValueToKeyElement = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const obj = {
      formId: myParam,
      keyName: keyElementValueTxt,
    };

    const res = await dispatch(addValueToKeyElementReducer(obj));
    if (res) {
      const result = await dispatch(getAllElementKeyReducer(myParam));

      setKeyElementValueTxt("");
      manageElementKeyList(result.payload);
    }
  };

  const removeValueToKeyElement = (e) => {
    let array = elementKeyList;
    array = array.filter((x) => x !== e);
    manageElementKeyList(array);
  };

  const clearFormFunction = () => {
    changeSelectedType("");
    setDropDownVal([]);
    addValueDropDown("");

    addValueDropDownConst("");
    setElementOption({
      lableTxt: null,
      keyTxt: null,
      id: null,
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFormElimepOpen = () => {
    let elKeyArray = elementKeyList;
    let elArray = schemaComponent;

    if (elArray.length === 0) {
      manageAvailableElementKeyList(elKeyArray);
    } else {
      if (elKeyArray.length !== 0) {
        let tempArray = [];
        for (const element of elKeyArray) {
          tempArray.push(element);
        }
        manageAvailableElementKeyList(tempArray);
        // let tempArra=[]
      } else {
        manageAvailableElementKeyList([]);
      }
    }

    clearFormFunction();
    openFormEliment(true);
  };

  const handleFormELimentCloss = () => {
    openFormEliment(false);
  };

  const [person, setPerson] = useState({
    schema: {
      properties: {
        name: {
          type: "string",
        },
      },
    },
    uischema: {
      type: "Control",
      scope: "#/properties/name",
    },
    data: {},
  });

  const dispatch = useDispatch();

  const schema = person.schema;
  const uischema = person.uischema;
  const initialData = person.data;

  const getFormSchimaToDom = async (e) => {
    loadingElement(true);
    const res = await dispatch(getFormSchema(e));

    setScheemaComponent(res.payload);
    changeGroup(selectedGroupId ? selectedGroupId : "main", res.payload);

    loadingElement(false);
  };

  const getAllKeyElement = async (e) => {
    let tempFilters = {
      nameFilter,
      lastNameFilter,
      emailFilter,
      cityFilter,
      page,
    };


    const myParam = '86248fe9-52fc-486e-aa2e-2eecb74902e6';

    getFormSchimaToDom(myParam);


    let tempObj = data


    manageElementKeyList(tempObj);
  };
  const changeDisplayValue = (e) => {
    setElementOption((prevState) => {
      return {
        ...prevState,
        lableTxt: e.target.value,
      };
    });
  };

  const [selectedParentFormList, setselectedParentFormList] = useState([])
  const [selectedParentFormListId, setselectedParentFormListId] = useState(null)
  const [selectedParentGroupeList, setSelectedParentGrouplist] = useState([])
  const ChangeSelectedParentForm = (id) => {

    const formTemp = parentFormList.find((e) => e.id === id)

    setselectedParentFormListId(id)
    setSelectedParentGrouplist(formTemp.groupe)

    setselectedParentFormList(formTemp.schema)

  }

  const changeWarning = (e) => {
    setElementOption((prevState) => {
      return {
        ...prevState,
        warning: e.target.value,
      };
    });
  };



  const [isDepent, setIsDepend] = useState(false)

  const [dependDataSet, setDependDataSet] = useState(null)
  const [dependKey, setDependKey] = useState(null)
  const [dependDataElement, setDependDataElement] = useState([])
  const [dependTargetValue, setDependTargetValue] = useState(null)
  const [parentFormList, setParentFormList] = useState([])
  const getSelectedFormForDepend = async (e) => {
    const res = await dispatch(getFormSchema(e));
    setDependDataSet(e)
    if (res.payload) {

      setDependDataElement(res.payload)
    }

  }

  const loadParentForms = async () => {

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    // let parentForms = await dispatch(loadParentFormsReducer(myParam))
    // setParentFormList(parentForms.payload ? parentForms.payload : [])
    //  

  }

  const [indexRuleType, setIndexRuleType] = useState(null)

  const [randomNumberLength, setRandomNumberLength] = useState(0)
  const [dateType, setDateType] = useState(null)
  const [staticChar, setStatiChar] = useState(null)
  const [ruleArray, setRuleArray] = useState([])

  const [formSelectType, setFormSelectType] = useState(null)
  const [cloningForm, setCloaningForm] = useState(null)

  const changeIndexRuleType = (e) => {
    setIndexRuleType(e)
  }

  const addRuleFunction = () => {
    let obj = {
      type: '',
      value: ''
    }
    if (!indexRuleType) {
      alert('Type is Required')
      return
    }

    if (indexRuleType === 'date') {
      if (!dateType) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: dateType
      }
    }

    if (indexRuleType === 'static') {
      if (!staticChar) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: staticChar
      }
    }

    if (indexRuleType === 'random') {
      if (!randomNumberLength) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: randomNumberLength
      }
    }

    setRuleArray(oldArray => [...oldArray, obj]);

  }

  const [extraDisplayArray, setExtraDisplayArray] = useState([])
  const extraValueSet = (e) => {

    setExtraDisplayArray(e)
  }

  const [imageTakenCount, setImageTakenCount] = useState(null)



  const addElementToSchema = async () => {
    let object = elementOption;

    if( object.minChar && object.maxChar && Number(object?.minChar) >= Number(object?.maxChar)) {
      alert("Min length should be less then Max length");
      return;
    }

    object = {
      ...object,
      group: selectedGroupConst,
      isRequired: requier,
      reference: reference ? reference : false,
      referenceKey: reference ? referenceKey : null
    };

    object.type = selectedTextType;
    object.fieldPlace = savingType ? savingType : "bitrix";

    if (!object.type) {
      alert(`Please select element type!`);

      return;
    }

    if (showInHeader) {
      object.showInHeader = showInHeader ? showInHeader : false;
      object.searchableKey = searchableKey ? searchableKey : false;
      object.sortable = sortable ? sortable : false;
    }

    if (reference === true && !referenceKey) {
      alert(`Please select reference Key!`);

      return;
    }
    if (selectedTextType === 'data_grid' || selectedTextType === 'lable_draft') {

      object = {
        ...object,
        selectedParentFormListId: selectedParentFormListId,
        selectedParentFormSchemaId: selectedParentFormSchemaId,
        defaultLableValue: defaultLableValue ? defaultLableValue : null
      }
    }
    if (selectedTextType === 'Form') {

      object = {
        ...object,
        formSelectType: formSelectType,
        cloningForm: cloningForm,
      }
    }

    //let array = schemaComponent

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0) {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      if (enumLoadType !== 'custom') {
        if (!enumConst) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!enumLable) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }
        if (!selectedFormForPayload) {
          alert(`Selected element should have at least one Enum values!`);

          return;
        }

        object.enumLoadType = enumLoadType;
        object.enumConst = enumConst;
        object.enumLable = enumLable;
        object.grouped = grouped
        object.selectedFormForPayload = selectedFormForPayload;
        object.extraDisplayArray = extraDisplayArray;
        object.sortBy = orderingValue;
      } else {

        object.enum = dropDownValue;


      }


      if (isDepent) {
        if (!dependDataSet) {
          alert('please select depend form')

          return
        }
        if (!dependKey) {
          alert('please select depend form')

          return
        }
        if (!dependTargetValue) {
          alert('please select depend form')

          return
        }
        object.dependTargetValue = dependTargetValue;
        object.isDepent = true;
        object.dependDataSet = dependDataSet;
        object.dependKey = dependKey;

      }

      object.selectType = selectType

    }



    ////////
    if (selectedTextType === "date") {
      object.format = "date";
    }

    if (selectedTextType === "location") {
      object.getType = "onClick";
    }

    if (selectedTextType === "File" || selectedTextType === "html_camera") {
      object.fileStoreType = fileStoreType;
      object.s3Bucket = s3Bucket;
      object.s3Dir = s3Dir;
      object.selectedFileType = selectedFileType;
      object.captureType = fileSource;
      object.imageTakenCount = imageTakenCount
    }
    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      object.enum = dropDownValue;
    }

    if (selectedTextType === 'index') {

      if (ruleArray.length == 0) {
        alert('rules empty')
        return
      }

      object = {
        ...object,
        group: null,
        ruleArray: ruleArray
      }





    }

    object.formId = selectedFormId;

    await dispatch(editFormElementToServer(object));

    props.editFormSuccess();
  };

  const editElementToSchema = async () => {
    let object = elementOption;

    if (schemaComponent.find((e) => e.keyTxt === object.keyTxt)) {
      alert(`Cant use same element key for the multiple elements!`);

      return;
    }
    object.type = selectedTextType;

    //let array = schemaComponent

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0) {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === "date") {
      object.format = "date";
    }

    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert(`Selected element should have at least one Enum values!`);

        return;
      }
      object.enum = dropDownValue;
    }

    object.formId = selectedFormId;

    await dispatch(addFormElementToServer(object));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

    // changeGroup(selectedGroupId)

    // setScheemaComponent(array)
    handleFormELimentCloss();
  };

  const changeOrderFunction = async (id, direction) => {
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };
    if (direction === "up") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex - 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex + 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderReducer(payloadOb));
    if (result) {
      getAllGroup();
    }
  };

  const changeOrderFunctionElement = async (id, direction) => {
    loadingElement(true);
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };

    if (direction === "up") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex - 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex + 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderElementReducer(payloadOb));

    // setSelectedFormId(myParam)
    getFormSchimaToDom(myParam);

    loadingElement(false);
  };

  const changeGroup = (id, data = false) => {
    let tempSchemaComponent = [];

    if (data) {
      if (id === "main") {
        tempSchemaComponent = data.filter((e) => e.group === null);
        setElementByGroup(tempSchemaComponent);
      } else {
        tempSchemaComponent = data.filter((e) => e.group === id);
        setElementByGroup(tempSchemaComponent);
      }
    } else {
      if (id === "main") {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === null);
        setElementByGroup(tempSchemaComponent);
      } else {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === id);
        setElementByGroup(tempSchemaComponent);
      }
    }

    setSelectedGroupId(id);
  };

  const pushValueToDropDown = () => {
    let rempArray = dropDownValue;
    let obj = {
      const: "",
      title: "",
    };

    if (rempArray.find((e) => e.const === valueDropDownConst)) {
      alert(`Cant use same key for the multiple Enums!`);

      return;
    }

    obj.const = valueDropDownConst;
    obj.title = valueDropDown;

    addValueDropDown("");
    addValueDropDownConst("");

    setDropDownVal([...dropDownValue, obj]);
  };

  const removeDropDownValus = (item) => {
    if (dropDownValue) {
      setDropDownVal(dropDownValue.filter((post) => post.const != item));
    }
  };
  const pushValueToOrdering = () => {
    if (!orderLabel) {
      alert('Please select sort By')
      return false;
    }
    let obj = { "sort_key": orderLabel, "sort_type": 1, "sort_by": 'ALPHANUM' };
    setOrderLable('');
    setOrderingValue([...orderingValue, obj]);
  };

  const removeOrderingValus = (item) => {
    if (orderingValue) {
      setSortRefreshed(false);
      let selectedSorting = orderingValue
      console.log(selectedSorting, 'selectedSorting')
      selectedSorting.splice(item, 1)
      setOrderLable(item);

      setOrderingValue(selectedSorting);
      setTimeout(() => { setSortRefreshed(true); }, 100)
      setOrderLable('');
    }
  };

  const initialPropBind = async (props) => {
    // const [enumLoadType, setEnumLoadType] = useState('custom');


    // const [referenceKey, setReferenceKey] = useState(null);


    // const [enumConst, setEnumCost] = useState(null);

    // const [enumLable, setEnumLable] = useState(null);

    
    isLoading(true)
    setRequred(props?.init?.isRequired ? true : false)


    if (props?.init) {
      if (props.init.type == 'index') {
        setRuleArray(props.init.ruleArray)
      }
    }

    if (props.init.type === 'data_grid' || props.init.type === 'lable_draft') {


      changeLableDefaula(props.init.defaultLableValue)


    }

    if (props.init.enumLoadType && props.init.enumLoadType === "payload") {
      setEnumLoadType("payload")
      if (props.init.selectedFormForPayload) {
        setSelectedFormForPayload(props.init.selectedFormForPayload)
        const res = await dispatch(getFormSchema(props.init.selectedFormForPayload));
        setSelectedFormEnumSet(res.payload)
        if (props.init.enumConst) {
          setEnumCost(props.init.enumConst)
        }
        if (props.init.enumLable) {
          setEnumLable(props.init.enumLable)
        }
      }

    }
    if (props.init.type == 'File' || props.init.type == 'html_camera') {
      changeFileStoreType(props?.init?.fileStoreType ? props.init.fileStoreType : 's3')
      changeS3Bucket(props?.init?.s3Bucket ? props.init.s3Bucket : null)

      changeS3Dir(props?.init?.s3Dir ? props.init.s3Dir : null)
      setSelectedFileType(props?.init?.selectedFileType ? props.init.selectedFileType : null)
      setFileSource(props?.init?.captureType ? props.init.captureType : null)

      setImageTakenCount(props?.init?.imageTakenCount ? props.init.imageTakenCount : null)


    }

    setElementOption({
      enum: props.init.enum ? props.init.enum : null,
      fieldPlace: props.init.fieldPlace ? props.init.fieldPlace : null,
      formId: props.init.formId ? props.init.formId : null,
      group: props.init.group ? props.init.group : null,
      isMulitSelect: props.init.isMulitSelect ? props.init.isMulitSelect : null,
      keyTxt: props.init.keyTxt ? props.init.keyTxt : null,
      lableTxt: props.init.lableTxt ? props.init.lableTxt : null,
      orderNumber: props.init.orderNumber ? props.init.orderNumber : null,
      type: props.init.type ? props.init.type : null,
      minChar: props?.init?.absaulteData?.minChar ? props?.init?.absaulteData?.minChar : null,
      maxChar: props?.init?.absaulteData?.maxChar ? props?.init?.absaulteData?.maxChar : null
    });

    if (props.init.showInHeader) {

      setShowInHeader(true);

      if (props.init.sortable) {
        setSortable(true);
      }
      if (props.init.searchableKey) {
        setSearchableKey(true);
      }
    }

    setDropDownVal(props.init.enum ? props.init.enum : []);
    setOrderingValue(props.init.sortBy ? props.init.sortBy : [])
    changeSelectedType(props.init.type);
    setSavingType(props.init.fieldPlace);
    setSelectedGroupConst(props.init.group);
    if (props.init.reference) {
      setReference(true);
      setReferenceKey(props.init.reference);
    }
    isLoading(false)
  };


  const getAllForms = async () => {
    const array = await dispatch(getAllFormsReducer(true))
    setActiveFormList(array.payload.respons.data)
    // 
  }


  const getSelectedForm = async (e) => {
    const res = await dispatch(getFormSchema(e));
    setSelectedFormForPayload(e)
    if (res.payload) {

      setSelectedFormEnumSet(res.payload)
    }

  }



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    loadParentForms()
    getAllKeyElement(myParam);
    setSelectedFormId(myParam);
    getFormSchimaToDom(myParam);
    getAllGroup();
    initialPropBind(props);
    getAllForms()


  }, [props]);

  useEffect(() => {
    if (isLoadForm) {
      setIsLoadForm(false)
      loadParentForms()
    }
  }, [isLoadForm])

  return (
    <>
      <Modal
        title="Edit Element"
        visible={props.isOpenEditSchema}
        onCancel={props.closeEditSchema}
        width={'90vw'}
        footer={[
          <AntButton danger key="back" onClick={props.closeEditSchema}>
            {t('Cancel')}
          </AntButton>,
          <AntButton
            danger
            key="submit"
            type="primary"
            onClick={addElementToSchema}
          >
            {t('Submit')}
          </AntButton>,
        ]}
      >
        {
          isLoadingElement ? null :
            <>
              <Grid
                className="page-action-header"
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <FormElement changeSelectedType={changeSelectedType} setIsLoadForm={setIsLoadForm} selectedTextType={selectedTextType} />
                </Grid>
              </Grid>
              {/* <Grid
                className="page-action-header"
                style={{ padding: 8 }}
                container
                spacing={2}
              >
                <Grid style={{ padding: 8, paddingBottom: 0 }} item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "string" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("string");
                        }}
                        icon={<FontColorsOutlined />}
                      >
                        {t('Text')}
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "number" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("number");
                        }}
                        icon={<CalculatorOutlined />}
                      >
                        {t('Number')}
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "drop" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("drop");
                        }}
                        icon={<DownCircleOutlined />}
                      >
                        {t('Dropdown')}
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "dynamicContent" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("dynamicContent");
                        }}
                        icon={<DownCircleOutlined />}
                      >
                        {t('Dynamic Content')}
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "dropTree" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("dropTree");
                        }}
                        icon={<DownCircleOutlined />}
                      >

                        {t('Dropdown Tree')}
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "date" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("date");
                        }}
                        icon={<CalendarOutlined />}
                      >
                        {t('Dropdown Tree')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "check" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("check");
                        }}
                        icon={<ProfileOutlined />}
                      >

                        {t('Checkbox')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "location" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("location");
                        }}
                        icon={<AimOutlined />}
                      >

                        {t('Location')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "File" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("File");
                        }}
                        icon={<FileAddOutlined />}
                      >

                        {t('File')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "html_camera" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("html_camera");
                        }}
                        icon={<FileAddOutlined />}
                      >

                        {t('HTML CAMERA')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "total" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("total");
                        }}
                        icon={<FileAddOutlined />}
                      >
                        {t('TOtal')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "clearing" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("clearing");
                        }}
                        icon={<FileAddOutlined />}
                      >
                        {t('Clearing')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "index" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("index");
                        }}
                        icon={<FileAddOutlined />}
                      >

                        {t('Index')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "data_grid" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("data_grid");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >

                        {t('Data Grid')}
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "lable_draft" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("lable_draft");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        Lable
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "Form" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("Form");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        Form
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "timeLine" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("timeLine");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        TIME LINE
                      </AntButton>
                    </Grid>

                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "repeat" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("repeat");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        REPEAT
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "calculation" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("calculation");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        CALCULATION
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "shops" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("shops");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        NEAR SHOPS
                      </AntButton>
                    </Grid>
                    <Grid item xs={6}
                      sm={3}
                      md={2}
                      lg={2}>
                      <AntButton
                        block={true}
                        danger
                        type={
                          selectedTextType === "cloacked" ? "primary" : ''
                        }
                        onClick={() => {
                          changeSelectedType("cloacked");
                          loadParentForms()
                        }}
                        icon={<FileAddOutlined />}
                      >
                        CLOCKED
                      </AntButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid className="box-setting-display" container spacing={2}>
                <Grid container spacing={2}>
                  <Grid className="field-container" item xs={12} md={6}>
                    <TextField
                      value={elementOption.lableTxt}
                      onChange={changeDisplayValue}
                      className="full-width"
                      size="small"
                      label="Field lable"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid className="field-container" item xs={12} md={6}>
                    <TextField
                      value={elementOption.warning}
                      onChange={changeWarning}
                      className="full-width"
                      size="small"
                      label="Warning"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid className="field-container" item xs={12} md={6}>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">group</InputLabel>
                      <Select

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedGroupConst}
                        size="small"
                        label="group"
                        onChange={(e) => {
                          setSelectedGroupConst(e.target.value);
                        }}
                      >
                        <MenuItem value="">Main</MenuItem>

                        {groupList.map((row1) => (
                          <MenuItem value={row1.groupId}>{row1.groupName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className="field-container" item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={requier} onChange={requeredChecked} />}
                        label="Required"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {(() => {
                  if (selectedTextType === "string") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Max length"
                              size="small"
                              variant="outlined"
                              type="number"
                              value={elementOption?.maxChar}
                              onChange={(e) => {
                                if (/^[0-9]{0,6}$/.test(e.target.value)) {
                                  setElementOption({
                                    ...elementOption,
                                    maxChar: e.target.value
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Min length"
                              size="small"
                              variant="outlined"
                              type="number"
                              value={elementOption?.minChar}
                              onChange={(e) => {
                                if (/^[0-9]{0,6}$/.test(e.target.value)) {
                                  setElementOption({
                                    ...elementOption,
                                    minChar: e.target.value
                                  })
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "number") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Max"
                              size="small"
                              variant="outlined"
                              type="number"
                            />
                          </Grid>
                          <Grid className="field-container" item xs={6} md={4}>
                            <TextField
                              className="full-width"
                              label="Min"
                              variant="outlined"
                              size="small"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );

                  } else if (selectedTextType === "Form") {
                    return (

                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={6}>
                            <AntSelect
                              style={{ width: '200px' }}
                              onChange={(e) => { setFormSelectType(e) }}
                            >
                              <Option value="manual">select form</Option>
                              <Option value="auto">Auto Create</Option>
                            </AntSelect>
                          </Grid>
                          <Grid className="field-container" item xs={6}>
                            <AntSelect
                              style={{ width: '200px' }}
                              onChange={(e) => { setCloaningForm(e) }}
                            >

                              {activeFormList.map((row) => (
                                <Option value={row.id}>{row.name}</Option>

                              ))}
                            </AntSelect>
                          </Grid>
                        </Grid>
                      </div>

                    );

                  } else if (selectedTextType === "shops") {
                    return (
                      <div className="setting-container">

                      </div>
                    );

                  } else if (selectedTextType === "index") {
                    return (
                      <div className="field-container-index">
                        <Row>
                          <Col className="index-action" span={8}>
                            <AntSelect
                              style={{ width: '100%' }}
                              onChange={changeIndexRuleType}
                            >
                              <Option value="date">date</Option>
                              <Option value="static">static number</Option>
                              <Option value="random">random number</Option>
                            </AntSelect>
                          </Col>
                          <Col className="index-action" span={8}>
                            {

                              indexRuleType == "date" ?
                                <AntSelect
                                  style={{ width: '100%' }}
                                  onChange={(e) => { setDateType(e) }}
                                >
                                  <Option value="MM">Month</Option>
                                  <Option value="DD">Date</Option>
                                  <Option value="YYYY">Year</Option>
                                  <Option value="MMDD">Month & Date</Option>
                                  <Option value="YYYYMMDD">Year, Month & Date</Option>
                                </AntSelect> : indexRuleType == 'static' ?
                                  <Input onChange={(e) => { setStatiChar(e.target.value) }} style={{ width: '100%' }} placeholder='static number' /> : indexRuleType == 'random' ?
                                    <Input onChange={(e) => { setRandomNumberLength(e.target.value) }} style={{ width: '100%' }} placeholder='number length' />
                                    : null

                            }







                          </Col>
                          <Col className="index-action" span={8}>
                            <AntButton onClick={addRuleFunction} className="index-action" type="primary" danger style={{ width: '100%' }}>Add Rule</AntButton>
                          </Col>
                        </Row>
                        <table>
                          <tr>
                            <th>Rule Type</th>
                            <th>Rule</th>
                            <th>Action</th>
                          </tr>
                          {ruleArray.map((rule) => (
                            <tr>
                              <td>{rule.type}</td>
                              <td>{rule.value}</td>
                              <td><button> x </button></td>
                            </tr>
                          ))}


                        </table>


                      </div>

                    );
                  } else if (selectedTextType === "drop" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
                    return (
                      <div style={{ width: '100%' }} className="setting-container">
                        <Grid container spacing={2}>

                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={selectType}
                                label="group"
                                onChange={(e) => {
                                  setSelectType(e.target.value);
                                }}
                              >
                                <MenuItem value="single">single</MenuItem>

                                <MenuItem value="multi">mulit</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Enum DataLoad
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={enumLoadType}
                                label="group"
                                onChange={(e) => {
                                  setEnumLoadType(e.target.value);
                                }}
                              >
                                <MenuItem value="custom">Custom</MenuItem>

                                <MenuItem value="payload">Payload</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>



                          {
                            enumLoadType === 'custom' ? <>
                              <Grid className="field-container" item xs={12} md={12}>
                                <span>
                                  <TextField
                                    className="array-value-add-container"
                                    size="small"
                                    value={valueDropDownConst}
                                    onChange={(e) => {
                                      addValueDropDownConst(e.target.value);
                                    }}
                                    label="Key"
                                    variant="outlined"
                                  />
                                </span>
                                <span>
                                  <TextField
                                    className="array-value-add-container"
                                    size="small"
                                    value={valueDropDown}
                                    onChange={(e) => {
                                      addValueDropDown(e.target.value);
                                    }}
                                    label="Item"
                                    variant="outlined"
                                  />
                                </span>
                                <IconButton
                                  onClick={pushValueToDropDown}
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <SendIcon />
                                </IconButton>
                              </Grid>
                              <Grid className="field-container" item xs={12} md={12}>
                                <div className="table-scrol-mid">
                                  <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">key</TableCell>
                                        <TableCell align="left">lable</TableCell>
                                        <TableCell align="left">action</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {dropDownValue.map((row) => (
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th": {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell>{row.const}</TableCell>
                                          <TableCell>{row.title}</TableCell>
                                          <TableCell>
                                            <Button
                                              onClick={() => {
                                                removeDropDownValus(row.const);
                                              }}
                                            >
                                              {" "}
                                              x
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </div>
                              </Grid>
                            </> : <>
                              <Grid className="field-container" item xs={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    Select Form
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={selectedFormForPayload}
                                    label="group"
                                    onChange={(e) => {
                                      getSelectedForm(e.target.value)

                                    }}
                                  >

                                    {activeFormList.map((row) => (
                                      <MenuItem value={row.id}>{row.name}</MenuItem>

                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {
                                selectedFormForPayload ?
                                  <>
                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Enum Const
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={enumConst}
                                          onChange={(e) => {
                                            setEnumCost(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Enum Lable
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={enumLable}
                                          onChange={(e) => {
                                            setEnumLable(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>


                                    <Grid className="field-container" item xs={6} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel>
                                          Grouped
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label="group"
                                          value={grouped}
                                          onChange={(e) => {
                                            setGrouped(e.target.value)

                                          }}
                                        >

                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid className="field-container" item xs={12}>
                                      <h4>{t('Order Settings')}</h4>
                                    </Grid>
                                    <Grid container spacing={2} style={{ marginBottom: "30px" }}>
                                      <Grid className="field-container" item xs={6} md={6}>
                                        <FormControl fullWidth>
                                          <InputLabel>

                                            {t('Order By')}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-ordering"
                                            size="small"
                                            label="ordering"
                                            value={orderLabel}
                                            onChange={(e) => {
                                              setOrderLable(e.target.value)
                                            }}
                                          >
                                            {selectFormEnumSet.map((row) => (
                                              <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Button onClick={pushValueToOrdering}>{t('Add Order By')}</Button>
                                    </Grid>
                                    {sortRefreshed && <>
                                      {orderingValue.map((row, index) => (<Grid container spacing={2}>
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <TextField
                                              className="array-value-add-container"
                                              size="small"
                                              value={row.sort_key}
                                              Readonly
                                              label="Key"
                                              variant="outlined"
                                              inputProps={
                                                { readOnly: true, }
                                              }
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>

                                              {t('Order Type')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-ordering"
                                              size="small"
                                              label="ordering"
                                              defaultValue={row.sort_by}
                                              onChange={(e) => {
                                                setOrderLable(e.target.value)
                                                row.sort_by = e.target.value
                                              }}
                                            >
                                              <MenuItem value={`ALPHANUM`}>Alphanumeric</MenuItem>
                                              <MenuItem value={`CUSOR`}>Custom Order</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {row.sort_by == 'CUSOR' && <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>
                                              {t('Select Form')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              size="small"
                                              defaultValue={row.sort_form}
                                              label="group"
                                              onChange={(e) => {
                                                console.log(e.target.value, 'e.target.valuee.target.value')
                                                row.sort_form = e.target.value
                                              }}
                                            >

                                              {activeFormList.map((row) => (
                                                <MenuItem value={row.id}>{row.name}</MenuItem>

                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>}
                                        <Grid className="field-container" item xs={4} md={4}>
                                          <FormControl fullWidth>
                                            <InputLabel>

                                              {t('Data Order')}
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-ordering"
                                              size="small"
                                              label="ordering"
                                              defaultValue={row.sort_type}
                                              onChange={(e) => {
                                                //setEnumLable(e.target.value)
                                                row.sort_type = e.target.value
                                              }}
                                            >
                                              <MenuItem value={1}>ASC</MenuItem>
                                              <MenuItem value={-1}>DESC</MenuItem>
                                            </Select>
                                          </FormControl>
                                          <Grid item xs={2} md={2}><Button onClick={() => removeOrderingValus(index)}><DeleteOutlined /></Button></Grid>
                                        </Grid>
                                      </Grid>))}
                                    </>}
                                    <hr />
                                    <div style={{
                                      width: '100%',
                                      marginTop: '15px',
                                    }}>Based on</div>
                                    <AntSelect
                                      mode="multiple"
                                      allowClear
                                      style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                      }}
                                      placeholder='Extra Values'

                                      onChange={(e) => extraValueSet(e)}
                                    >


                                      {schemaComponent.map((row) => (
                                        <Option key={row.keyTxt}>{row.lableTxt}</Option>
                                      ))}



                                    </AntSelect>
                                    <div style={{
                                      width: '100%',
                                      marginTop: '15px',
                                    }}>Extra Values</div>
                                    <AntSelect
                                      mode="multiple"
                                      allowClear
                                      style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                      }}
                                      placeholder='Extra Values'

                                      onChange={(e) => extraValueSet(e)}
                                    >


                                      {selectFormEnumSet.map((row) => (
                                        <Option key={row.keyTxt}>{row.lableTxt}</Option>
                                      ))}



                                    </AntSelect>

                                  </>

                                  : null
                              }



                              {/* const [isDepent,setIsDepend]=useState(false)

const [dependDataSet,setDependDataSet]=useState(null)
const [dependDataElement,setDependDataElement]=useState([])
const [dependKey,setDependKey]=useState(null) */}

                              <Grid className="field-container" item xs={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    IS depend
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={isDepent}
                                    label="group"
                                    onChange={(e) => {
                                      setIsDepend(e.target.value)

                                    }}
                                  >

                                    <MenuItem value='false'>Indipend</MenuItem>

                                    <MenuItem value='true'>Depend</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {
                                isDepent ? <>
                                  <Grid className="field-container" item xs={12} md={12}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Select Form
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        value={dependDataSet}
                                        label="group"
                                        onChange={(e) => {
                                          getSelectedFormForDepend(e.target.value)

                                        }}
                                      >

                                        {activeFormList.map((row) => (
                                          <MenuItem value={row.id}>{row.name}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid className="field-container" item xs={6} md={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Depend key
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="group"
                                        value={dependKey}
                                        onChange={(e) => {
                                          setDependKey(e.target.value)

                                        }}
                                      >

                                        {dependDataElement.map((row) => (
                                          <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid className="field-container" item xs={6} md={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>
                                        Depend target value
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        label="group"
                                        value={dependTargetValue}
                                        onChange={(e) => {
                                          setDependTargetValue(e.target.value)

                                        }}
                                      >

                                        {schemaComponent.map((row) => (
                                          <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>

                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </> : null
                              }
                              {/* <Grid className="field-container" item xs={12} md={12}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Select Form
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={enumLoadType}
                                  label="group"
                                  onChange={(e) => {
                                    setEnumLoadType(e.target.value);
                                  }}
                                >

                                  {activeFormList.map((row) => (
                                      <MenuItem value={row.id}>{row.name}</MenuItem>
                                    
                                  ))}
                                </Select>
                              </FormControl>
                              </Grid> */}
                            </>
                          }

                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "radio") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDownConst}
                                onChange={(e) => {
                                  addValueDropDownConst(e.target.value);
                                }}
                                label="Key"
                                variant="outlined"
                              />
                            </span>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDown}
                                onChange={(e) => {
                                  addValueDropDown(e.target.value);
                                }}
                                label="Item"
                                variant="outlined"
                              />
                            </span>{" "}
                            <IconButton
                              onClick={pushValueToDropDown}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SendIcon />
                            </IconButton>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <Stack direction="row" spacing={1}>
                              {dropDownValue.map((row) => (
                                <Chip
                                  label={row.title}
                                  onDelete={() => {
                                    removeDropDownValus(row.const);
                                  }}
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "date") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}></Grid>
                      </div>
                    );
                  } else if (selectedTextType === "date_diff") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}></Grid>
                      </div>
                    );
                  } else if (selectedTextType === "File" || selectedTextType === "html_camera") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Store Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={fileStoreType}
                                label="group"
                                onChange={(e) => {
                                  changeFileStoreType(e.target.value);
                                }}
                              >
                                <MenuItem value="s3">Amazon S3</MenuItem>

                                <MenuItem value="local">Local</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {fileStoreType === "s3" ? (
                            <>
                              <Grid className="field-container" item xs={12} md={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    bucket
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={s3Bucket}
                                    size="small"
                                    label="group"
                                    onChange={(e) => {
                                      changeS3Bucket(e.target.value);
                                    }}
                                  >
                                    <MenuItem value="mertracker">Mertracker</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid className="field-container" item xs={12} md={6}>
                                <TextField
                                  value={s3Dir}
                                  onChange={(e) => {
                                    changeS3Dir(e.target.value);
                                  }}
                                  className="full-width"
                                  size="small"
                                  label="Dir name"
                                  variant="outlined"
                                />
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                        <Divider>IMAGE SETTINGS</Divider>
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={12}>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                Accepted
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedFileType}
                                size="small"
                                onChange={modifyFileType}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {acceptedFileType.map((row1) => (
                                  <MenuItem key={row1.const} value={row1.lable}>
                                    <Checkbox
                                      checked={
                                        selectedFileType.indexOf(row1.lable) > -1
                                      }
                                    />
                                    <ListItemText primary={row1.lable} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>


                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                Single / Multiple
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={imageTakenCount}
                                size="small"
                                onChange={(e) => setImageTakenCount(e.target.value)}

                              >
                                <MenuItem value="single">single</MenuItem>
                                <MenuItem value="mulitple">mulitple</MenuItem>
                              </Select>
                            </FormControl>

                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Capture Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fileSource}
                                size="small"
                                label="group"
                                onChange={(e) => {
                                  setFileSource(e.target.value);
                                }}
                              >
                                <MenuItem value="local">Storage</MenuItem>
                                <MenuItem value="camera">Camera</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "location") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="On open"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid className="field-container" item xs={6} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="On Click"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "check") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid className="field-container" item xs={12} md={12}>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDownConst}
                                onChange={(e) => {
                                  addValueDropDownConst(e.target.value);
                                }}
                                label="Key"
                                variant="outlined"
                              />
                            </span>
                            <span>
                              <TextField
                                className="array-value-add-container"
                                size="small"
                                value={valueDropDown}
                                onChange={(e) => {
                                  addValueDropDown(e.target.value);
                                }}
                                label="Item"
                                variant="outlined"
                              />
                            </span>
                            <IconButton
                              onClick={pushValueToDropDown}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <SendIcon />
                            </IconButton>
                          </Grid>
                          <Grid className="field-container" item xs={12} md={6}>
                            <Stack direction="row" spacing={1}>
                              {dropDownValue.map((row) => (
                                <Chip
                                  label={row.title}
                                  onDelete={() => {
                                    removeDropDownValus(row.const);
                                  }}
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "lable_draft") {
                    return (
                      <div className="setting-container">
                        <Grid container spacing={2}>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={12}
                          >
                            Grid Payload From
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Form"
                              optionFilterProp="children"
                              value={selectedParentFormListId}
                              onChange={(e) => { ChangeSelectedParentForm(e) }}
                            >

                              {parentFormList.map((row, index) => (
                                <Option value={row.id}> {row.name}</Option>
                              ))}


                            </AntSelect>
                            Grid Payload Schema
                            <AntSelect
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Schema"
                              optionFilterProp="children"
                              value={selectedParentFormSchemaId}
                              onChange={(e) => { setselectedParentFormSchemaId(e) }}
                            >

                              {selectedParentFormList.map((row, index) => (
                                <Option value={row.keyTxt}> {row.keyTxt}</Option>
                              ))}


                            </AntSelect>
                            <textarea rows="6" style={{ marginTop: 10, width: '100%' }} placeholder="lable name" onChange={(e) => changeLableDefaula(e.target.value)} >{
                              defaultLableValue
                            }</textarea>


                          </Grid>
                        </Grid>
                      </div>
                    );
                  } else if (selectedTextType === "repeat") {
                    return (
                      <div className="setting-container">




                      </div>
                    );
                  } else {
                    return (
                      <>
                        <CssBaseline />
                        <Container maxWidth="sm">
                          <Box
                            className="no-select-compoment"
                            sx={{ bgcolor: "#cfe8fc" }}
                          >
                            <div>
                              <h5>Please select element to continue</h5>
                            </div>
                          </Box>
                        </Container>
                      </>
                    );
                  }
                })()}

                <Grid container spacing={2}>
                  <Grid className="field-container" item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={referenceChacked} checked={reference} />
                        }
                        label="Reference"
                      />
                    </FormGroup>
                  </Grid>
                  {!reference ? (
                    <></>
                  ) : (
                    <>
                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            reference
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={referenceKey}
                            label="reference"
                            onChange={(e) => {
                              setReferenceKey(e.target.value);
                            }}
                          >
                            {elementKeyList.map((row) => (
                              <MenuItem value={row.keyName}>{row.keyName}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                <button
                  className="advance-button"
                  onClick={() => {
                    setAdvalceOpen(!isOpenAdvance);
                  }}
                >
                  {" "}
                  Advance{" "}
                  {showInHeader ? showInHeader : 'false'}
                </button>

                <Grid
                  className={isOpenAdvance ? "show-div" : "closs-div"}
                  container
                  spacing={2}
                >
                  <Grid className="field-container" item xs={12} sm={6} md={4}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            setShowInHeader(!showInHeader);
                          }}
                          checked={showInHeader}
                        />
                      }
                      label="Show in header"
                    />
                  </Grid>

                  {showInHeader ? (
                    <Grid className="field-container" item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSearchableKey(!searchableKey);
                            }}
                            checked={searchableKey}
                          />
                        }
                        label="Enable Seach"
                      />
                    </Grid>
                  ) : null}
                  {showInHeader ? (
                    <Grid className="field-container" item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSortable(!sortable);
                            }}
                            checked={sortable}
                          />
                        }
                        label="Enable Sorting"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </>
        }

      </Modal>
    </>
  );
};


export default SchemaElementEdit
