import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { getUserById, getLogedInUserData } from "../../../../redux/slices/users";
import { submitFormAnswer } from "../../../../redux/slices/formSettings"
import {
    useDispatch
} from "react-redux";
import '../style.css'
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { useTranslation } from "react-i18next";
import { JsonForms } from '@jsonforms/react';
import moment from 'moment'

import GeoLocation from '../custom/geoLocation/GeoLocation';
import FileControl from '../custom/storage/FileControl';
import Total from '../custom/total/Total';

import { rankWith, scopeEndsWith } from '@jsonforms/core';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

export function FormInterface(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();



    const [render, setRender] = useState([
        ...materialRenderers
    ])

    const firstUpdate = useRef(true);
    const [dataBaseData, setDataBaseData] = useState([])

    const [formDetails, setFormDetails] = useState({})

    const [submitSuccess, setSubmitSuccess] = useState(false)

    const [totalFields, setTotalFields] = useState([])




    const [renderSuccess, setRenderSuccess] = useState(true)

    const [formSchema, setFormSchema] = useState({
        schema: {
            "properties": {
                "name": {
                    "type": "string",
                    "title": "First Name"
                },
                "blabla": {
                    "type": "string",
                    "title": "First Name"
                }
            }
        }, uischema: {
            "properties": {
                "name": {
                    "type": "string",
                },
                "blabla": {
                    "type": "string",

                }
            }
        },
        data: {}

    })

    const createNonGroupElement = (mainEl) => {
        let schemaObj = {}
        let typeOfElement = 'string';
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {
            typeOfElement = 'string';
        }
        let eleObj = {}
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {
            schemaObj = {
                type: typeOfElement,
                oneOf: mainEl.enum ? mainEl.enum : [],
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'date') {
            schemaObj = {
                type: typeOfElement,
                format: 'date',
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'check') {
            schemaObj = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: mainEl.enum ? mainEl.enum : [],
                },
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'location') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: GeoLocation
            })
            setRender(renderers);
            return false
        }
        else if (mainEl.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: FileControl
            })
            schemaObj = {
                type: 'string',
                storeMethod: mainEl.fileStoreType,
                s3Bucket: mainEl.s3Bucket,
                s3Dir: mainEl.s3Dir,
                selectedFileType: mainEl.selectedFileType ? mainEl.selectedFileType : null,
                captureType: mainEl.captureType ? mainEl.captureType : null
            }
        }
        else {
            schemaObj = {
                type: typeOfElement,
                title: t(mainEl.lableTxt)
            }
        }
        return schemaObj
    }


    const createGroupElement = (elementGroup, schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: {}
        }

        wrappingObj.schema['title'] = t(elementGroup.groupName)



        if (schemaElement.length !== 0) {
            let eleArra = []
            if (elementGroup.groupType === 'array') {
                wrappingObj.schema['type'] = 'array'
                wrappingObj.schema['items'] = {}
                wrappingObj.schema.items['type'] = 'object'
                wrappingObj.schema.items['properties'] = {}
                for (const elementData of schemaElement) {
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {
                        let eleObj = {}
                        if (elementData.type === 'drop' || elementData.type === 'radio') {

                            let arrayOfList = []
                            for (const listItem of elementData.enum) {
                                arrayOfList.push(listItem.const)
                            }

                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                enum: elementData.enum ? arrayOfList : [],
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'date') {


                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'check') {
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'string',
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir
                            }
                        } else {
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            } else {
                wrappingObj.schema['properties'] = {}
                for (const elementData of schemaElement) {
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        if (elementData.type === 'drop' || elementData.type === 'radio') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                oneOf: elementData.enum ? elementData.enum : [],
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'date') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'check') {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj = {
                                type: 'string',
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir,
                                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null
                            }
                        } else {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            }




        }
        return wrappingObj

    }


    const createGroupElementObj = (schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: {}
        }



        let eleArra = []

        wrappingObj.schema = {}

        let typeOfElement = 'string';
        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {
            typeOfElement = 'string';
        }

        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {


            wrappingObj.schema = {
                type: typeOfElement,
                oneOf: schemaElement.enum ? schemaElement.enum : [],
                title: t(schemaElement.lableTxt)
            }


        } else if (schemaElement.type === 'date') {


            wrappingObj.schema = {
                type: typeOfElement,
                format: 'date',
                title: t(schemaElement.lableTxt)
            }



        } else if (schemaElement.type === 'check') {
            wrappingObj.schema = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: schemaElement.enum ? schemaElement.enum : [],
                },
                title: t(schemaElement.lableTxt)
            }


        }
        else if (schemaElement.type === 'total') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: Total
            })
            wrappingObj.schema = {
                type: 'string',
                value: 0
            }

        }
        else if (schemaElement.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: FileControl
            })
            wrappingObj.schema = {
                type: 'string',
                storeMethod: schemaElement.fileStoreType,
                s3Bucket: schemaElement.s3Bucket,
                s3Dir: schemaElement.s3Dir,
                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                captureType: schemaElement.captureType ? schemaElement.captureType : null
            }
        }
        else if (schemaElement.type === 'number') {

            wrappingObj.schema = {
                type: 'integer',
                title: t(schemaElement.lableTxt)
            }
        } else {

            wrappingObj.schema = {
                type: typeOfElement,
                title: t(schemaElement.lableTxt)
            }


        }











        return wrappingObj

    }


    const getUserInformationDatabase = async (id) => {
        return await dispatch(getUserById(id))
    }

    const createForm = async () => {
        const session = localStorage.getItem('token')
        let userData = {}
        let profile = {}
        if (session) {
            userData = await dispatch(getLogedInUserData());
            if (userData.payload) {
                profile = userData.payload.profile
            }

        }


        const group = props.elementGroup
        const schemaElement = props.jsonSchema

        setTotalFields(schemaElement.filter((e) => e.type === 'total'))

        let schemaObject = {}

        schemaObject["properties"] = {
            bitrix: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            },
            database: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            }

        }
        schemaObject['type'] = 'object'
        let uiSchema = {
            "type": "VerticalLayout",
            "elements": []
        }

        let dataBody = {
            bitrix: {
                main: {

                }
            },
            database: {
                main: {

                }
            }

        }

        const mainField = schemaElement.filter((e) => e.group === null)



        if (mainField.length !== 0) {



            const calCount = 2;
            for (const mainEl of mainField) {
                const schemaObj = createNonGroupElement(mainEl)

                if (schemaObj) {
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        schemaObject.properties.bitrix.properties.main.properties[mainEl.keyTxt] = schemaObj
                    } else {
                        schemaObject.properties.database.properties.main.properties[mainEl.keyTxt] = schemaObj
                    }

                }

            }

            const chunkSize = 2;
            for (let i = 0; i < mainField.length; i += chunkSize) {
                const chunk = mainField.slice(i, i + chunkSize);
                let mainGroupSchema = {
                    "type": "HorizontalLayout",
                    "elements": []
                }

                for (const mainEl of chunk) {
                    let scope = ''
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                    } else {
                        scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope
                    })
                }
                uiSchema.elements.push(mainGroupSchema)
            }

        }


        for (const elementGroup of group) {
            if (elementGroup.groupType === 'object') {

                for (const elementData of schemaElement) {

                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        const resGropEn = createGroupElementObj(elementData)

                        if (resGropEn) {
                            if (elementData.fieldPlace === 'bitrix' || elementData.fieldPlace === '') {
                                schemaObject.properties.bitrix.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                                if (elementData.isRequired) {
                                    schemaObject.properties.bitrix.properties.main.required.push(elementData.keyTxt)
                                }

                                if (elementData.reference) {
                                    if (profile.userBit) {
                                        dataBody.bitrix.main = { ...dataBody.bitrix.main, [elementData.keyTxt]: profile.userBit[elementData.reference] ? profile.userBit[elementData.reference] : '-' }
                                    }

                                }



                            } else {
                                schemaObject.properties.database.properties.main.properties[elementData.keyTxt] = resGropEn.schema


                                if (elementData.isRequired) {
                                    schemaObject.properties.database.properties.main.required.push(elementData.keyTxt)
                                }
                                if (elementData.reference) {
                                    if (profile.userBit) {
                                        dataBody.database.main = { ...dataBody.database.main, [elementData.keyTxt]: profile.userBit[elementData.reference] ? profile.userBit[elementData.reference] : '-' }

                                    }

                                }


                            }

                            // schemaObject.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                        }



                    }


                }
                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                },
                )
                const groupBelongElemeny = schemaElement.filter((e) => e.group === elementGroup.groupId)

                const perChunk = elementGroup.groupColtxt ? elementGroup.groupColtxt : 1;
                const resultArraySplices = groupBelongElemeny.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / perChunk)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, [])


                for (const splice of resultArraySplices) {



                    let mainGroupSchema = {
                        "type": "HorizontalLayout",
                        "elements": []
                    }




                    for (const mainEl of splice) {

                        let scope = ''
                        let option = {
                        }

                        let rule = {

                        }
                        if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {
                            // scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                            scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                        } else {
                            scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                        }

                        if (mainEl.type === 'date') {
                            option['format'] = 'date'
                            option['views'] = ['year', 'month', 'date']
                            option['dateFormat'] = "YYYY-MM-DD"
                            option['dateSaveFormat'] = "YYYY-MM-DD"

                        }

                        if (mainEl.multiLine) {
                            if (mainEl.multiLine == 'M') {
                                option['multi'] = true
                            }
                        }

                        if (mainEl.reference) {

                            option['readonly'] = true

                        }

                        mainGroupSchema.elements.push({
                            "type": "Control",
                            "scope": scope,
                            "options": option,
                        })

                    }


                    uiSchema.elements.push(mainGroupSchema)
                }


            }
            else if (elementGroup.groupType === 'category') {

                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                })
                // uiSchema.elements.push({
                //     "type": "Categorization",
                //     "text": elementGroup.groupLable
                // })

                let catObj = {
                    "type": "Categorization",
                    "elements": [],
                    "options": {
                        "variant": "stepper",
                        "showNavButtons": true
                    }
                }
                const relatedStage = group.filter((e) => e.selectedCategory === elementGroup.groupId && e.groupType === 'stage')
                if (relatedStage.length === 0) {
                    continue;
                }
                for (const elementRelatedStage of relatedStage) {

                    let stageObj = {
                        "type": "Category",
                        "label": elementRelatedStage.groupLable,
                        "elements": []
                    }

                    if (elementRelatedStage.groupType === 'stage') {
                        const filteredEleimentSchemaCategory = schemaElement.filter((e) => e.group === elementRelatedStage.groupId)
                        for (const elementDataStage of filteredEleimentSchemaCategory) {
                            const resGropEn = createGroupElementObj(elementDataStage)
                            let scope = ''
                            if (resGropEn) {

                                if (elementDataStage.fieldPlace === 'bitrix' || elementDataStage.fieldPlace === '') {
                                    schemaObject.properties.bitrix.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/bitrix/properties/main/properties/" + elementDataStage.keyTxt
                                } else {
                                    schemaObject.properties.database.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/database/properties/main/properties/" + elementDataStage.keyTxt
                                }
                                stageObj.elements.push({

                                    "type": "HorizontalLayout",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": scope
                                        }

                                    ]
                                })
                            }
                        }

                    }
                    catObj.elements.push(stageObj)
                }
                uiSchema.elements.push(catObj)

            }

            else if (elementGroup.groupType === 'array') {

                const resGropEn = createGroupElement(elementGroup, schemaElement.filter((e) => e.type != 'total'))

                if (resGropEn) {
                    let scope = ''
                    if (resGropEn.fieldPlace === 'bitrix' || resGropEn.fieldPlace === '') {
                        schemaObject.properties.bitrix.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/bitrix/properties/main/properties/" + elementGroup.groupId
                    } else {
                        schemaObject.properties.database.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/database/properties/main/properties/" + elementGroup.groupId
                    }
                    //schemaObject.properties.main.properties[elementGroup.groupId] = resGropEn.schema

                    let mainGroupSchema = {
                        "type": "VerticalLayout",
                        "elements": []
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope
                    })

                    uiSchema.elements.push(mainGroupSchema)
                }

                

            }


        }

        setFormSchema((prevState) => {
            return {
                ...prevState,
                schema: schemaObject,
                uischema: uiSchema,
                data: dataBody
            };
        });

        setRenderSuccess(true)

    }

    const handleLanguageChanged = () => {
        createForm();
    }

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        createForm();
        setFormDataFunction()

    }, [props.formDetails]);


    const setFormDataFunction = () => {
        setFormDetails(props.formDetails)
    }

    const setData = (data, err) => {

        let tempData = data
        let tot = 0
        if (totalFields) {
            for (const totalFiel of totalFields) {
                if (tempData?.database?.main[totalFiel.group]) {
                    if (tempData.database.main[totalFiel.group].length !== 0) {
                        let tottt=0
                        for (const subOfTot of tempData.database.main[totalFiel.group]) {
                            
                            if (subOfTot[totalFiel.targetValue]) {
                                
                                tottt=parseFloat(tottt)+parseFloat(subOfTot[totalFiel.targetValue])
                                // tempData.database.main.test=tempData.database.main[totalFiel.group][0]['price']
                            }
                        }
                        tempData.database.main[totalFiel.keyTxt]=tottt
                    }

                }
            }

        }


        //  if(data?.database?.main['795c4d86-9da9-4e98-8600-507701bc6c21']){

        //     if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'].length !== 0){

        //             if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']){
        //                 data.database.main.total=data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']
        //             }

        //     }

        // }




        setFormSchema((prevState) => {
            return ({
                ...prevState,
                data: tempData
            });
        });


     
        // setFormSchema((prevState) => {
        //     return ({
        //         ...prevState.data.database.main,

        //     });
        // });

    }


    const submitForm = async () => {



        const tempData = {
            data: formSchema.data,
            form: {...formDetails, schema: null, group: null}
        }

        const res = await dispatch(submitFormAnswer(tempData))

        setSubmitSuccess(true)
    }
    const testFunction = () => {


    }


    return (

        <div className="public-form-container">

            <div className="full-screen-form-container-public-form">
                <button onClick={testFunction}></button>
                {
                    submitSuccess ? null : <>
                        <div className="form-header-button">
                            <button onClick={submitForm}>SUBMIT</button>
                        </div>

                    </>
                }

                <div className="form-header-title">
                    {formDetails.name}
                </div>

                <div className="form-date-range">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={12} sm={6} md={6}>
                                {t("Start Date")} : {formDetails.activeDateStart ? moment(formDetails.activeDateStart).format('MMMM Do, YYYY') : 'No Active Start Date'}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                End Date : {formDetails.activeDateEnd ? moment(formDetails.activeDateEnd).format('MMMM Do, YYYY') : 'No Active End Date'}
                            </Grid>
                        </Grid>
                    </Box>

                </div>

                <div className="form-responsible-person">

                    Responsible Person : {formDetails.selectedUser ? formDetails.selectedUser : 'No Responsible User'}
                </div>

                <div className="form-header-discription">
                    {formDetails.description}
                </div>

                <div className="form-header-discription">
                    {totalFields.map((row) => (
                        <h1 style={{textAlign:'right'}}>
                            {row.lableTxt} : {formSchema?.data?.database?.main[row.keyTxt] ? formSchema.data.database.main[row.keyTxt] : 0}
                        </h1>


                    ))}
                </div>

                {
                    submitSuccess ? <>
                        <Alert severity="success">Form successfully submited — Thank you for your time!</Alert>
                    </> : <>
                        <JsonForms
                            schema={formSchema.schema}
                            uischema={formSchema.uischema}
                            data={formSchema.data}
                            renderers={render}
                            cells={materialCells}
                            onChange={({ data, _errors }) => setData(data, _errors)}
                        />


                    </>
                }

            </div>

        </div>


    );
}