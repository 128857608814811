import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../../config";

let initialState = {
    attandance: [
        
    ],
    NumberOfRows:0,
    empAttandance:null
};





export const getAllAttandance = createAsyncThunk(
    "permision/getAllAttandance",
    async (data) => {
        
        let endPoint = `${apiConfig.adminApi}/attendance/get-all-attandance/`;
       
        endPoint+=`${data.selectedPage}?dem=0`
        if(data.filters){
            if(data.filters.empCode){
                endPoint+=`&empcode=${data.filters.empCode}`
            }
            if(data.filters.StoreId){
                endPoint+=`&StoreId=${data.filters.StoreId}`
            }
            if(data.filters.attandDate){
                endPoint+=`&startDate=${data.filters.attandDate.start}`
                endPoint+=`&endDate=${data.filters.attandDate.end}`
                //endPoint+=`&StoreId=${data.filters.StoreId}`
            }
            
           
        }

       
     
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);


export const getAllAttandanceByemCode = createAsyncThunk(
    "permision/getAllAttandanceByemCode",
    async (data) => {
        console.log('getAllAttandanceByemCode data',data )

        let endPoint = `${apiConfig.adminApi}/attendance/get-arrandance-by-emcpde/`;
       
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);


export const getAllAttandanceExport = createAsyncThunk(
    "permision/getAllAttandanceExport",
    async (data) => {
        
        let endPoint = `${apiConfig.adminApi}/attendance/get-all-attandance-export?dem=0`;
       

        if(data.filters){
            if(data.filters.empCode){
                endPoint+=`&empcode=${data.filters.empCode}`
            }
            if(data.filters.StoreId){
                endPoint+=`&StoreId=${data.filters.StoreId}`
            }
            if(data.filters.attandDate){
                endPoint+=`&startDate=${data.filters.attandDate.start}`
                endPoint+=`&endDate=${data.filters.attandDate.end}`
                //endPoint+=`&StoreId=${data.filters.StoreId}`
            }
            
           
        }

       
     
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }

        const response = await axios.get(endPoint,config);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);











export const attandanceSlice = createSlice({
    name: "permision",
    initialState,
    extraReducers: {

        [getAllAttandance.fulfilled]: (state, action) => {
            const data=action?.payload?.data?action.payload.data:[]
            const dataCount=action?.payload?.dataCount?.NumberOfRows?action.payload.dataCount.NumberOfRows:0
             
            return {
                ...state,
                attandance: data,
                NumberOfRows:dataCount
            }
        },
      

    },
});

export default attandanceSlice.reducer;


