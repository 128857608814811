import React, { useState, useEffect } from "react";

import {Button, Col} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function RoundedStatusRed(props) {
    const { t, i18n } = useTranslation();
const [lable,setLable]=useState(null)
    const init = () =>{
        setLable(props.lable)
    }
    useEffect(() => {

        init();

    }, [props]);



    return (

        <div className="attandance-status attandence-out-of-word">
            <div style={{ textAlign: 'center' }}>{lable?t(lable):null}</div>
        </div>

    );
}

export function RoundedStatusGreen(props) {
    const { t, i18n } = useTranslation();
    const [lable,setLable]=useState(null)
    const init = () =>{
        setLable(props.lable)
    }
    useEffect(() => {

        init();

    }, [props]);



    return (
        <div className="attandance-status attandence-working">
        <div style={{ textAlign: 'center' }}>{lable?t(lable):null}</div>
        </div>

    );
}