

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Camera } from "./camera";
import { Image, Alert, Spin } from 'antd';
// or
import { Grid, Link, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { UploadImageToS3WithReactS3 } from "./s3";
import { CameraOutlined } from '@ant-design/icons';
import { Button as AntButton, Space } from 'antd';
import imageCompression from 'browser-image-compression';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlCamera = (props) => {
    const { t, i18n } = useTranslation();
    const [imgData, setImage] = useState(null)

    const [acceptedType, setAccetptedType] = useState('')
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();

    const [loading, setLoading] = useState(false);


    const [device, setDevice] = useState(false);

    const [fileGetType, setFileGetType] = useState('local');

    const [domLoading, setDomLoading] = useState(false);

    const [warning, setWarning] = useState(null);

    const [isMulitiPleImage, setMultiImage] = useState(false);
    const acceptedFileType = [
        {
            const: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lable: "EXCEL"
        },
        {
            const: "image/*",
            lable: "image"
        },
        {
            const: "video/*",
            lable: "video"
        },
        {
            const: "audio/*",
            lable: "audio"
        },
        {
            const: ".pdf",
            lable: "PDF"
        }

    ];

    const uploadImage = async (e) => {

        let tempUpload = await UploadImageToS3WithReactS3(e, props.schema.s3Bucket, props.schema.s3Dir);
        if (tempUpload) {

            if (isMulitiPleImage) {
                if (!imgData) {
                    let image = []
                    image.push(tempUpload)
                    props.handleChange(props.path, image)
                } else {
                    let image = [...imgData, tempUpload]
                    props.handleChange(props.path, image)
                }

            } else {
                props.handleChange(props.path, tempUpload)
            }

            //setImage(tempUpload)
        }


    };

    const setAcceptType = (type) => {
        if (type.length === 0) {
            setAccetptedType('')
        } else {
            let acceptedTypeArray = []
            for (const element of type) {
                const key = acceptedFileType.find((e) => e.lable === element)
                acceptedTypeArray.push(key.const)
            }

            //var arr = ['Hi', 'I', 'am', 'a', 'comma', 'separated', 'list'];


            setAccetptedType(acceptedTypeArray.join(','))
        }
    }

    const updateData = async (imageBlob) => {
        setIsCameraOpen(false)
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageBlob, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            setLoading(true);
            await uploadImage(compressedFile); // write your own logic
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
        // await uploadImage(imageBlob)


        // props.updateEmployeeData({ id: props.location.state.sectionData.taskId , ...body }).then((res) => {
        //   if (res.payload.length > 0) {
        //    // this.props.history.push("/app/employees/tasks");
        //   } else {
        //    // this.props.history.push("/login");
        //   }
        // });
    };

    const customizeForDevice = () => {

        var ua = navigator.userAgent;
        var checker = {
            iphone: ua.match(/(iPhone|iPod|iPad)/),
            blackberry: ua.match(/BlackBerry/),
            android: ua.match(/Android/)
        };
        if (checker.android) {
            setDevice(false)
        } else if (checker.iphone) {
            setDevice(false)
        }
        else if (checker.blackberry) {
            setDevice(false)
        }
        else {
            setDevice(true)
        }

    }

    useEffect(() => {
        customizeForDevice()
        setDomLoading(true)
        setAcceptType(props.schema.selectedFileType ? props.schema.selectedFileType : [])
        setFileGetType(props.schema.captureType ? props.schema.captureType : 'local')
        setMultiImage(props?.schema?.mulitpleImage ? props.schema.mulitpleImage : false)

        setWarning(props?.schema?.schemaEl?.warning ? props.schema.schemaEl.warning : null)
        if (props?.schema?.mulitpleImage) {

            setImage(props?.data ? props.data : null)
        } else {
            setImage(props?.data ? props.data : null)
        }


        setDomLoading(false)
        //
    }, [props]);
    return (
        <div className='custom-component'>

            {!loading ?
                !domLoading ? (<>
                    {

                        fileGetType === 'local' ? (<>
                            <div className='camera-lable'>
                                {

                                    props.schema.storeMethod === "s3" ?
                                        <>
                                            {
                                                warning ?
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Alert message={t(warning)} type="error" />
                                                    </div>
                                                    :
                                                    <div>{t(props.schema.htmlLable ? props.schema.htmlLable : '-')}{
                                                        props?.required ? <span className='required-field'>*</span> : null
                                                    }</div>

                                            }



                                            {imgData ?
                                                <div style={{ padding: 5 }}>

                                                    {
                                                        setMultiImage && Array.isArray(imgData) ? <>
                                                            <Image.PreviewGroup>

                                                                {imgData.map((row) => (
                                                                    <Image width={200} src={row.location} />
                                                                ))}


                                                            </Image.PreviewGroup>
                                                        </> : <>
                                                            <Image.PreviewGroup>
                                                                <Image width={200} src={imgData.location} />

                                                            </Image.PreviewGroup>
                                                        </>
                                                    }
                                                </div>


                                                : null}

                                            <div>
                                                {
                                                    device ?
                                                        <AntButton
                                                            type=""
                                                            danger
                                                            icon={<CameraOutlined />}
                                                            onClick={() => setIsCameraOpen(true)}
                                                        >
                                                            {t('OPEN CAMERA')}
                                                        </AntButton>

                                                        :
                                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                                            <input onChange={(e) => { uploadImage(e.target.files[0]) }} hidden accept={acceptedType} type="file" capture="environment" />
                                                            {


                                                                <div className='photCapture-IMAGE'>
                                                                    <div>
                                                                        <PhotoCamera />
                                                                        <span>OPEN CAMERA</span>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </IconButton>
                                                }

                                            </div>

                                        </>
                                        :
                                        <h4>Not available option</h4>
                                }

                            </div>
                        </>) : (<>
                            <div className='camera-lable'>
                                {
                                    warning ?
                                        <div style={{ marginBottom: 10 }}>
                                            <Alert message={t(warning)} type="error" />
                                        </div>
                                        :
                                        <div>{t(props.label ? props.label : '-')}{
                                            props?.required ? <span className='required-field'>*</span> : null
                                        }</div>

                                }

                                {
                                    imgData ?


                                        <img onClick={() => setIsCameraOpen(true)} src={imgData.location} className='form-image-show' alt={imgData.location} />
                                        :
                                        <div>
                                            {
                                                device ?
                                                    <AntButton
                                                        type=""
                                                        danger
                                                        icon={<CameraOutlined />}
                                                        onClick={() => setIsCameraOpen(true)}
                                                    >
                                                        {t('OPEN CAMERA')}
                                                    </AntButton>

                                                    :
                                                    <IconButton color="primary" aria-label="upload picture" component="label">
                                                        <input onChange={(e) => { uploadImage(e.target.files[0]) }} hidden accept={acceptedType} type="file" capture="environment" />
                                                        {
                                                            <div className='photCapture-IMAGE'>
                                                                <div>
                                                                    <PhotoCamera />
                                                                    <span>OPEN CAMERA</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </IconButton>
                                            }
                                        </div>

                                }




                            </div>
                        </>)
                    }

                </>) : null 
                : <div className="example"> <Spin /></div> 
            }
            <Dialog
                fullScreen
                open={isCameraOpen}
                onClose={() => setIsCameraOpen(false)}
                TransitionComponent={Transition}
            >
                {/* <AppBar sx={{ position: "relative" }}>
                                        <Toolbar>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={() => setIsCameraOpen(false)}
                                                aria-label="close"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                               
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => setIsCameraOpen(false)}
                                            >
                                                Close
                                            </Button>
                                        </Toolbar>
                                    </AppBar> */}
                <DialogContent className="full-screen-form-container-view">
                    <Camera
                        loading={loading}
                        onCapture={(blob) => {
                            updateData(blob);
                        }}
                        clossCamera={() => setIsCameraOpen(false)}
                        onClear={() => setCardImage(undefined)}
                    />
                </DialogContent>
            </Dialog>
        </div>



    );
}



export default withJsonFormsControlProps(HtmlCamera);
