

import { withJsonFormsCellProps } from '@jsonforms/react';
import { React, useEffect } from 'react';

// or




const GeoCell = (props) => {



    const getGeo = async () => {
        return new Promise((resolve, reject) => {
            let goptions = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            };
            function success(pos) {
                var crd = pos.coords;

                resolve(crd);
                props.handleChange(props.path, crd)
            }

            function error(err) {
                console.warn(`Geo location ERROR(${err.code}): ${err.message}`);
                // alert(
                //     "Error!: " + err.message + " \n " + uiText[uiText.lang].pageReload
                // );
                // location.reload();
                reject(err);
            }
            navigator.geolocation.getCurrentPosition(success, error, goptions);
        });
    };

    useEffect(() => {




        //
    }, []);
    return (
        <div className='s'>
            <h1>ssss</h1>
            {/* <IconButton color="primary" aria-label="upload picture" component="label" onClick={getGeo}>

                <MyLocationIcon />
            </IconButton> */}
        </div>
    );
}



export default withJsonFormsCellProps(GeoCell);
