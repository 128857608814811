import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import UsersTable from "../Users/UsersTable";
import { useDispatch, connect, useSelector } from "react-redux";
import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { Col, Row, Button, Result, Image, Alert } from 'antd';
import { FormInterfaceDynamic } from "../settings/JsonForm/intefaces/FormInterfaceDynamic";
import { FormInterfaceDynamicEdit } from "../settings/JsonForm/intefaces/FormInterfaceDynamicEdit";
import { getFormSchema, getFormId, getAllGroupRaducer, getFormDetails } from "../../redux/slices/formSettings";


import { ClockingResult } from './components/ClockingResult'
import { ClockOutResult } from './components/ClockOutResult'
import { ShareLocation } from './components/ShareLocation'

import { CheckingForm } from './components/formComponent/CheckingForm'
import { CheckoutForms } from './components/formComponent/CheckoutForms'

import { ReloadButton } from '../../components/elements/CustomizButtons'

import { FullScreenLoader } from '../../components/elements/FullScreenLoader'

import { RoundedStatusRed,RoundedStatusGreen } from '../../components/elements/RoundedStatus'


import {
    initState,getFormAnswerList,shareLocationReducer,changeDataReducer,changeDataEditAction,submitFormAnswer,editFormAnswer
} from "../../redux/slices/attandance/markAttandanceSetting";
import moment from "moment";

const Card = styled(MuiCard)(spacing);


const Divider = styled(MuiDivider)(spacing);


const style = {
    padding: '8px 0',
    marginBottom: '10px'
};


function Attendance(props) {
    // console.log('props',props)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [isLoading, loading] = useState(true);


    const selectedAnswer = useSelector((state) => {
        // console.log('state',state)
        let data = null
        const attandanceState=state.markAttandance?state.markAttandance:null
        if(attandanceState?.selectedAnswer){
        
            let tempClockingData=attandanceState?.selectedAnswer?.data?.database?.main?.clocked?.clockIn?.dateTime?
            attandanceState.selectedAnswer.data.database.main.clocked.clockIn.dateTime:null

            if(tempClockingData){
                let currentDate= new Date().toLocaleString('en-US', {
                    timeZone: 'Asia/Bangkok',
                })
                if(moment(currentDate).format('YYYY-M-D')==moment(tempClockingData).format('YYYY-M-D')){
                    data=attandanceState.selectedAnswer
                }

            }
           

            //console.log('datadata',tempClockingData)
        }
        return data
    })



    // const shareLocation = useSelector((state) => {
    //     let data = false
    //     const attandanceState=state.markAttandance?state.markAttandance:null
    //     if(attandanceState?.shareLocation){
    //         data=attandanceState.shareLocation
    //     }
    //     return data
    // })

const shareLocation = useSelector(state =>  state.markAttandance?.shareLocation || false );


    // const viewClockInData = useSelector((state) => {
    //     let data = false
    //     const attandanceState=state.markAttandance?state.markAttandance:null
    //     if(attandanceState?.viewClockInData){
    //         data=attandanceState.viewClockInData
    //     }
    //     return data
    // })

    const viewClockInData = useSelector(state => state.markAttandance?.viewClockInData || false);


    // const viewClockoutMenu = useSelector((state) => {
    //     let data = false
    //     const attandanceState=state.markAttandance?state.markAttandance:null
    //     if(attandanceState?.viewClockoutMenu){
    //         data=attandanceState.viewClockoutMenu
    //     }
    //     return data
    // })

const viewClockoutMenu = useSelector(state => state.markAttandance?.viewClockoutMenu || false);

// const getFormData = async () => {
//     console.time('getFormData')
//   loading(true)
//   const formData = await dispatch(getFormId('Attendance'));
//   if (!formData.payload || formData.payload.length === 0) {
//     loading(false)
//     return;
//   }
//   await dispatch(initState(formData.payload[0]))
//   getFormAnswer(formData.payload[0].id)
//   loading(false)
//   console.timeEnd('getFormData')
// }

// const getFormData = async () => {
//     console.time('getFormData')
//     loading(true)
//     const formData = await dispatch(getFormId('Attendance'));
//     if (!formData.payload || formData.payload.length === 0) {
//         loading(true) ;
//         return  
//     } else {
//         await dispatch(initState(formData.payload[0]));
//         getFormAnswer(formData.payload[0].id)
//         loading(false)
//     }
//     console.timeEnd('getFormData')
// }

// const getFormData = async () => {
//   console.time('getFormData Attendance');
//   loading(true);
//   const formData = await dispatch(getFormId('Attendance'));
//   console.log('formData',formData)
//   if (!formData.payload || formData.payload.length === 0) {
//     console.log('inside if not formData',formData)
//     loading(false);

//     alert('No Form data from API \n ระบบกำลังมีปัญหา คุณสามารถลองใหม่ได้ในอีกสักครู่')
//     console.timeEnd('getFormData Attendance');

//     return ;
// }

//   dispatch(initState(formData.payload[0]))
//     .then(() => getFormAnswer(formData.payload[0].id))
//     .finally(() => {
//       loading(false);
//       console.timeEnd('getFormData Attendance');
//     });
// };

const getFormData = async () => {
  loading(true);

  try {
    const formData = await dispatch(getFormId('Attendance'));

    if (formData.payload && formData.payload.length !== 0) {
      dispatch(initState(formData.payload[0])).then(() => {
        getFormAnswer(formData.payload[0].id);
      });
    } else {
      console.log('Inside "if not formData"');
    //   alert('No Form data from API \n ระบบกำลังมีปัญหา คุณสามารถลองใหม่ได้ในอีกสักครู่');
    }
  } catch (err) {
    console.error('Error!', err);
  } finally {
    loading(false);
  }
};

const getFormAnswer = async (id) => {
    console.time('getFormAnswer Attendance')
    loading(true);
   
    
    const todayStartDate = moment().startOf('day').toISOString();
    const todayEndDate = moment().add(1, 'day').set({ hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();

    const data = {
        formData: id,
        sorting: false,
        order: false,
        search: [
            {
                "key": "createdAt",
                "value": [
                    todayStartDate,
                    todayEndDate
                ],
                "isDate": true
            }
        ],
        viewType: "user_only"
    };

    try {
        const res = await dispatch(getFormAnswerList(data));
        console.log('getFormAnswer attendance res ',res)
    } finally {
        loading(false);
        console.timeEnd('getFormAnswer Attendance')
    }
};




//     const getFormAnswer = async (id) => {
// console.time('getFormAnswer Attendance')
//         const data = {
//             formData: id,
//             sorting: false,
//             order: false,
//             search: null,
//             viewType: "user_only"
//         };
//         const res = await dispatch(getFormAnswerList(data));
//         console.log('getFormAnswer attendance res ',res)
//         console.timeEnd('getFormAnswer Attendance')

//     }


    useEffect(() => {
        getFormData()
    }, []);

    useEffect(() => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    },[])

    return (
        <React.Fragment>
            {
                // viewClockoutMenu ? <ClockOutResult /> : viewClockInData ? <ClockingResult /> :
                    <>
                         
                                    {
                                        isLoading ? <FullScreenLoader /> :
                                            <div className="attandence-div">
                                                {/* <ReloadButton /> */}
                                                {
                                                    // shareLocation ?
                                                        <>
                                                            {
                                                                selectedAnswer ?
                                                                    <CheckoutForms/>
                                                                    :<CheckingForm />

                                                            }
                                                        </>
                                                        // : <ShareLocation />

                                                }
                                            </div>
                                    }
                               
                    </>
            }

        </React.Fragment >
    );
}

export default Attendance;
