

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { Checkbox } from 'antd';
import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";
import { Select ,InputNumber  } from 'antd';
import { init } from 'i18next';
import { Button,notification,message } from 'antd';

const { Option } = Select;

const CheckCustom = (props) => {

    const dataList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            
                        error = data?.database?.main?data.database.main:null
                  

        }

        return error

    })

    const dispatch = useDispatch();
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const { t, i18n } = useTranslation();
    const [Urenderfunsed, setrenderfyn] = useState(false)

    const [Lable, setLable] = useState(null)
    const [ isChecked,setChecked] = useState(false)

    const [schema, setSchema] = useState([])
    const [ group,setGroup] = useState([])

    const [ targetArray,setTargetArray] = useState(null)

    const init = async () => {
        setLable(props?.schema?.schemaElement?.lableTxt?props.schema.schemaElement.lableTxt:'-')
        setTargetArray(props?.schema?.schemaElement?.targetField?props.schema.schemaElement.targetField:null)

        setSchema(props?.schema?.allSchema?props.schema.allSchema:[])
        setGroup(props?.schema?.allGroup?props.schema.allGroup:[])

        if(props.data){
            setChecked(true)
        }else{
            setChecked(false)
        }
    }

    useEffect( () => {
        console.log(wholeDataObject.database,'wholeDataObject')
    },[wholeDataObject])

    const uniqByKeepFirst = (a, key) => {
        let seen = new Set();
        return a.filter(item => {
            let k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }

    const onChange = async () => {
   
        
        let targeGroup  = group.find((e)=>e.groupId===targetArray)
        let targeSchema = schema.find((e)=>e.group===targetArray && e.type==='dropTree') 
    

     const condition = targeSchema.condition?true:false
     if(condition){
        const ConditionBase = targeSchema.ConditionBase?targeSchema.ConditionBase:null
        const ConditionField = targeSchema.ConditionField?targeSchema.ConditionField:null
        if(ConditionBase && ConditionField){
            
            if(dataList){
                const selectedCondition=dataList[ConditionBase]?dataList[ConditionBase]:null
                 

                if(selectedCondition){
                    let Sub_Grouping_By = wholeDataObject?.database?.main?.Sub_Grouping_By

                    if(!wholeDataObject?.database?.main?.Grouping_By){
                        message.error('Please Select Group');
                        return
                    }

                    if(wholeDataObject?.database?.main?.Grouping_By){
                        targeSchema = { ...targeSchema, "grouped" : wholeDataObject?.database?.main?.Grouping_By }
                    }

                    if( wholeDataObject?.database?.main?.sortBy ){
                        if( wholeDataObject?.database?.main?.sortBy.length > 0 ){
                            targeSchema = { ...targeSchema, "sortBy" : wholeDataObject?.database?.main?.sortBy }
                        }
                    }

                    let obj = {
                        schama: targeSchema ?targeSchema: null,
                        value: selectedCondition
                    }
                   
                    

                        const p= await dispatch(getCOnditionalEnum(obj))
                         
                        const paylod=p?.payload?.data?.data?p.payload.data.data:null

                        let fullob=paylod?.fullObject?paylod.fullObject:[]

                        const filter = targeSchema.filter?true:false
                        let rand=[]
                        let filterbase=null
                        let filterField=null
                        if(filter){
                            filterbase = targeSchema.filterbase?targeSchema.filterbase:null
                            filterField = targeSchema.filterField?targeSchema.filterField:null
                            const selectedFiler=dataList[filterbase]?dataList[filterbase]:null

                            
                            if(selectedFiler){
                                for(const tda of fullob){
                                    
                                    const arrsss=tda.data[filterField]
                                    if(arrsss){
                                       
                                        if(arrsss.find((el)=>el==selectedFiler)){
                                            rand.push(tda)
                                           }
                                    }
                                  
                                 }
                            }else{
                                rand=fullob 
                            }
                            

                        }else{
                            rand=fullob
                        }
                        //console.log(rand,targeSchema.grouped,'randrandrand')
                        let dataArr=[]
                        let c=0
                        let item=0
                        var groupSet = [];
                        if(Sub_Grouping_By){
                            groupSet = await uniqByKeepFirst(rand, it => `${it.data[targeSchema.grouped]}__${it.data[Sub_Grouping_By]}`)
                           
                            
                            for(let gr of groupSet){
                                c++
                                if(gr.data[targeSchema.grouped]){

                                    let tempProdArray=[]
                                    for(const tda of rand){
                                    
                                        if(tda.data[targeSchema.grouped]===gr.data[targeSchema.grouped] && tda.data[Sub_Grouping_By]===gr.data[Sub_Grouping_By]){
                                            tempProdArray.push(tda.const)
                                            item++
                                        }
                                    }
                                    //rand.filter((ra)=>ra.data[targeSchema.grouped]===gr.data[targeSchema.grouped])
                                    let dataob={
                                        [targeSchema.keyTxt]:{
                                            "selectedgroup": gr.data[targeSchema.grouped],
                                            "selectedsubgroup": gr.data[Sub_Grouping_By],
                                            "value":tempProdArray
                                        },
                                        "name":  `${gr.data[targeSchema.grouped]} - ${gr.data[Sub_Grouping_By]}`
                                    }
                                    dataArr.push(dataob)
                                }
                                
                            }
                        }else{
                            groupSet = await uniqByKeepFirst(rand, it => it.data[targeSchema.grouped])
                            
                            for(let gr of groupSet){
                                c++
                                if(gr.data[targeSchema.grouped]){

                                    let tempProdArray=[]
                                    for(const tda of rand){
                                        
                                        console.log(tda,'tdatdatda')

                                        if(tda.data[targeSchema.grouped]===gr.data[targeSchema.grouped]){
                                            tempProdArray.push(tda.const)
                                            item++
                                        }
                                    }
                                    //rand.filter((ra)=>ra.data[targeSchema.grouped]===gr.data[targeSchema.grouped])
                                   // console.log(tempProdArray,'tempProdArraytempProdArray')
                                    let dataob={
                                        [targeSchema.keyTxt]:{
                                            "selectedgroup": gr.data[targeSchema.grouped],
                                            "value":tempProdArray
                                        },
                                        "name":  gr.data[targeSchema.grouped]
                                    }
                                    dataArr.push(dataob)
                                }
                                
                            }
                        }
                            

                        //console.log(dataArr,'dataArrdataArr')
                        let mess=`${c} groupes and ${item} products will be created`
                        const key = `open${Date.now()}`;
                        const btn = (
                            <Button type="primary" size="small" onClick={() => {
                               // console.log(dataList,dataArr,targetArray,'dataListdataList')

                                const  retobj={
                                    bitrix:{main:{}},
                                    database:{
                                        main:{
                                            ...dataList,
                                            [targetArray]:dataArr
                                        }
                                    }
                                   }
                                 
                                props.schema.addBulkDataList(retobj)

                            }}>
                              Confirm
                            </Button>
                          );
                          notification.open({
                            message: 'Warning',
                            description:mess,
                            btn,
                            key,
                          });
                         
                       
                        
                }else{
                    message.error('Please select brand first');
                }

               
            }
         
           
        }
     }
    //  "fb21d0cc-725b-4efa-81a3-2cadc4351d69": [
    //     {
    //         "group_production": {
    //             "selectedgroup": "120-171664",
    //             "value": [
    //                 "8851512079327"
    //             ]
    //         }
    //     }
    // ],

   // 
   
    };
 
    //

    useEffect(() => {
        //
        init()

        //
    }, [props]);
    return (
        <>
        
       <Button onClick={onChange}>{Lable?Lable:null}</Button>

        </>



    );
}



export default withJsonFormsControlProps(CheckCustom);
