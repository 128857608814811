import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import {
    EyeOutlined,
    FlagOutlined,
    ControlOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";
import {
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select as AntSelect,
    Pagination,
    Dropdown,
    Menu,
    notification, Space, Tabs, Collapse, Modal, Input, Form, Spin, DatePicker, Image, Drawer, Select
} from "antd";

import * as XLSX from "xlsx";
import Title from "antd/es/typography/Title";

import { getFormSchema, getFormId, getAllGroupRaducer, getFormAnswerList } from "../../redux/slices/formSettings";

// reducers
import { getPaginatedUser, getFetchUser, updateBulkUserService, getFetchUsersWithoutPaginate, getFetchUsers } from "../../redux/slices/users";
import { UserGridCard } from "./components/UserGridCard";
import DialogTitle from "@mui/material/DialogTitle";
import Profile from '../Profile';
import { DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "80vw",
            maxWidth: "80vw",  // User Model width as 80vw screen
        },
    },
}));

const gridStyle = {
    width: '50%',

};

// to re-use profile from data
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose && (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </DialogTitle>
    );
}

function Users(props) {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);

    const [dataPerPage, setDataPerPage] = useState(10);

    const [search, setSearch] = useState({});

    const [loading, setLoading] = useState(false)

    const [selectedData, setSelectedData] = useState(null)

    const [selectedFormId, setSelectedFormId] = useState(null);

    const [schemaComponent, setScheemaComponent] = useState([]);

    const [isOpen, setOpen] = useState(false);

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [templateSelectPopup, setTemplateSelecetPopup] = useState(false);

    const [checkedUsers, setCheckedUsers] = useState([]);

    const [openExportDialog, setOpenExportDialog] = useState(false);

    const [isUpdatingBulkUser, updatingBulkUser] = useState(false);

    const [BulkUserEditList, setBulkUserEdit] = useState({})

    // get login user data from loginUser Users's Store

    const {loginUser} = useSelector(
        (state) => state.user
    );

    let { usersFetchData } = useSelector(
        (state) => state.user
    );

    console.log("userID: " , loginUser?.userData?.user?.ID);

    const columns = [
        {
            title: '#',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
        },
        {
            title: 'Name',
            dataIndex: 'NAME',
            key: 'NAME',
        },
        {
            title: 'Last Name',
            dataIndex: 'LAST_NAME',
            key: 'LAST_NAME',
        },
        {
            title: 'Email',
            dataIndex: 'EMAIL',
            key: 'EMAIL',
        },
        {
            title: 'Mobile',
            dataIndex: 'PERSONAL_MOBILE',
            key: 'PERSONAL_MOBILE',
        },
        {
            title: 'City',
            dataIndex: 'PERSONAL_CITY',
            key: 'PERSONAL_CITY',
        },
        {
            title: 'Position',
            dataIndex: 'WORK_POSITION',
            key: 'WORK_POSITION',
        }

    ];

    // handlers
    const clearFilter = () => {
        form.resetFields()
        setSearch({})
        init(1, {})
    }

    // get form data like form schema 
    const getFormData = async () => {

        setLoading(true)

        const formData = await dispatch(getFormId('userEdit'));
        if (formData) {
            if (formData.payload) {
                if (formData.payload.length !== 0) {
                    setSelectedFormId(formData.payload[0].id)
                    const formSchema = await dispatch(getFormSchema(formData.payload[0].id));
                    const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));
                    if (formSchema.payload) {
                        setScheemaComponent(formSchema.payload)
                    }
                }
            }
        }

        setLoading(false)

    }

    const init = async (pageNo = page,
        filters = search
    ) => {
        setPage(pageNo)
        const obj = {
            page: pageNo,
            ...filters
        }
        await dispatch(getPaginatedUser(obj))
    }


    useEffect(() => {
        init()
        getFormData();
    }, [props.match.params.id]);

    const showModal = (e) => {
        console.log('selected data', e)
        setSelectedData(e)
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const userList = useSelector((state) => {

        let users = []

        if (state?.user?.usersFetchData?.result) {
            const tempData = state.user.usersFetchData.result
            for (const temp of tempData) {
                let user = temp?.bitrix || temp?.database || {};
                let rowData = {
                    key: user?.ID || '-',
                    action: <div className="action-div-table">
                        <Button style={{ color: 'green' }} className="action-div-button" onClick={() => { showModal(user) }} type="text" shape="circle" icon={<EyeOutlined />} />
                        {
                            user.mongoData ?
                                <Button className="action-div-button" onClick={() => { showModal(user.mongoData) }} type="text" shape="circle" icon={<FlagOutlined />} />
                                : null
                        }
                    </div>,
                    ID: user.ID || '-',
                    NAME: user.NAME || '-',
                    LAST_NAME: user.LAST_NAME || '-',
                    EMAIL: user.EMAIL || '-',
                    PERSONAL_MOBILE: user.PERSONAL_MOBILE || '-',
                    PERSONAL_CITY: user.PERSONAL_CITY || '-',
                    WORK_POSITION: user.WORK_POSITION || '-',
                }
                users.push(rowData)
            }

        }
        return users

    })

    const dAtacount = useSelector((state) => {


        let count = 0
        if (state?.user?.usersFetchData?.total) {
            count = state.user.usersFetchData.total

        }
        return count

    })
    const changePagination = (pageNo, pageSize) => {

        init(pageNo)
        setPage(pageNo)
    }

    const onFilter = (values) => {
        console.log("values", values)
        if (values.rules_field) {
            const result = values.rules_field.reduce((acc, current) => {
                acc[current.key] = current.value;
                return acc;
            }, {});
            console.log("result", result);
            setSearch(prevState => ({ ...prevState, ...result }));
            init(1, result)

        }

        setIsFilterOpen(false);
    }

    // TODO: need to apply user check box then export selected users
    const createExchelTemplate = async (type) => {

        const formData = await dispatch(getFormSchema(selectedFormId));
        const tempCheckUsers = checkedUsers;
        const departmentDataPayload = await dispatch(getFormAnswerList({
            formData: 'eea90583-a3a4-43e7-b2db-ea63e9c85c1a'
        }));
        const divDataPayload = await dispatch(getFormAnswerList({
            formData: 'f41871c7-d329-43ea-946a-28581b84c8dd'
        }));
        const userGroupPayload = await dispatch(getFormAnswerList({
            formData: '6c6b666c-d6ba-4aee-9b17-ae4dbb9919d3'
        }));

        const departmentData = departmentDataPayload.payload.data.data
        const divData = divDataPayload.payload.data.data
        const jobGrade = userGroupPayload.payload.data.data
        let excelArra = []
        let headerArray = ['ID']
        let depHeaderArra = [['Department Name']]
        let divHeaderArra = [['Division Name']]
        let userGroups = [['User Group Name']]

        for (const element of formData.payload) {
            headerArray.push(element.keyTxt);
        }
        for (const element of departmentData) {
            let row = []
            if (element?.data?.database?.main?.dep_name) {
                row.push(element.data.database.main.dep_name)
                depHeaderArra.push(row)
            }
        }

        for (const element of divData) {
            let row = []
            if (element?.data?.database?.main?.div_name) {
                row.push(element.data.database.main.div_name)
                divHeaderArra.push(row)
            }
        }

        for (const element of jobGrade) {
            let row = []
            if (element?.data?.database?.main?.job_grade_name) {
                row.push(element.data.database.main.job_grade_name)
                userGroups.push(row)
            }
        }

        excelArra.push(headerArray)
        // type ->  's' then it's export selected user data
        if (type === 's') {
            const tempUsers = usersFetchData?.result?.length > 0
                ? usersFetchData?.result : [];
            for (const userId of tempCheckUsers) { 
                let userArray = []
                const userD = tempUsers.find((uId) => userId == uId.bitrix.ID)
                for (const headerKey of headerArray) {
                    if (userD.bitrix[headerKey]) {

                        const val = userD.bitrix[headerKey] ? userD.bitrix[headerKey] : null
                        userArray.push(val)
                    } else if (userD.database[headerKey]) {

                        const val = userD.database[headerKey] ? userD.database[headerKey] : null
                        userArray.push(val)
                    } else {

                        userArray.push(null)
                    }
                }
                excelArra.push(userArray);
        }
    }

        // type ->  'e' then it's exports logged in user data
        if (type === 'e') {
            let userArray = []
                const userD = loginUser?.profile || {};
                for (const headerKey of headerArray) {
                    if (userD?.userBit?.[headerKey]) {
                        const val = userD.userBit[headerKey] || null;
                        userArray.push(val)
                    } else if (userD?.userDB?.[headerKey]) {
                        const val = userD.userDB[headerKey] || null;
                        userArray.push(val)
                    } else {
                        userArray.push(null)
                    }
                }
                excelArra.push(userArray);
        }

        // type ->  'a' then it's export all the user data
        if (type === 'a') {
            const tempUsers = await fetchAllUsersWithoutPaginate();
            for (const userId of tempUsers) {

                let userArray = []
                for (const headerKey of headerArray) {
                    if (userId.bitrix[headerKey]) {

                        const val = userId.bitrix[headerKey] ? userId.bitrix[headerKey] : null
                        userArray.push(val)
                    } else if (userId.database[headerKey]) {

                        const val = userId.database[headerKey] ? userId.database[headerKey] : null
                        userArray.push(val)
                    } else {

                        userArray.push(null)
                    }

                }
                excelArra.push(userArray);
            }
        }

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.aoa_to_sheet(excelArra),
            wsDep = XLSX.utils.aoa_to_sheet(depHeaderArra),
            wsDiv = XLSX.utils.aoa_to_sheet(divHeaderArra),
            wsUserGroup = XLSX.utils.aoa_to_sheet(userGroups)

        XLSX.utils.book_append_sheet(wb, ws, 'my');
        XLSX.utils.book_append_sheet(wb, wsDep, 'department');
        XLSX.utils.book_append_sheet(wb, wsDiv, 'division');
        XLSX.utils.book_append_sheet(wb, wsUserGroup, 'user group');
        XLSX.writeFile(wb, 'template.xlsx')
    }

    const checkThisUser = (e) => {
        let tempCheckUsers = checkedUsers
        const uIndex = tempCheckUsers.findIndex((ind) => ind == e)

        if (uIndex > 0) {
            tempCheckUsers.splice(uIndex, 1);
        } else {
            tempCheckUsers.push(e);
        }
        setCheckedUsers(tempCheckUsers)
    }
    const handleCloseTemplateSelect = () => {
        setTemplateSelecetPopup(false)
    };

    const fetchAllUsersWithoutPaginate = async () => {
        try {
            let requestUsers = await dispatch(getFetchUsersWithoutPaginate());
            if (requestUsers?.payload?.result?.length > 0) {
                return requestUsers.payload.result
            } else {
                return []
            }
        } catch (e) {
            alert('failed...')

        }
    }

    const handleOpenTemplateSelect = () => {
        setTemplateSelecetPopup(true)
    };

    const submitBulkEdit = async () => {
        updatingBulkUser(true)
        try {

            let res = await dispatch(updateBulkUserService(BulkUserEditList));

            alert('updated...')
            updatingBulkUser(false)
            setBulkUserEdit({})
            setOpenExportDialog(false)

        } catch (e) {
            updatingBulkUser(false)
            setLoading(false)
            alert('error...')
        }
    }

    const handleFile = async (e) => {
        const file = e.target.files[0];
        const data = await file.arrayBuffer()
        const workbook = XLSX.read(data);
        const workSheed = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = XLSX.utils.sheet_to_json(workSheed);
        const temparray = [];
        for (const el of jsonData) {
            if (el.ID) {
                temparray.push(el)
            }
        }
        setBulkUserEdit({ data: temparray });
    }



    return (

        <>
            <Dialog onClose={handleCloseTemplateSelect} open={templateSelectPopup}>
                <DialogTitle sx={{ m: 0, p: 2 }} >Select Export Type</DialogTitle>
                <div className='list-btn'>
                    <Button type="primary" onClick={() => createExchelTemplate('e')} variant="contained" size="large">
                        {t('Empty Template')}
                    </Button>
                </div>
                <div className='list-btn'>
                    <Button type="primary" onClick={() => createExchelTemplate('s')} variant="contained" size="large">
                        {t('Template with selected user(s)')}
                    </Button>
                </div>
                <div className='list-btn'>
                    <Button type="primary" onClick={() => createExchelTemplate('a')} variant="contained" size="large">
                        {t('Template with all user(s)')}
                    </Button>
                </div>

            </Dialog>

            <Dialog fullWidth
                maxWidth="sm" open={openExportDialog} onClose={() => setOpenExportDialog(false)}>
                <DialogTitle>{t('Bulk Edit')}</DialogTitle>
                <DialogContent>

                    <input style={{ display: 'block' }} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleFile(e)} />


                </DialogContent>
                <DialogActions>
                    {
                        isUpdatingBulkUser ? <>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        </> :
                            <>
                                <Button onClick={() => setOpenExportDialog(false)}>Cancel</Button>
                                <Button onClick={submitBulkEdit}>Edit</Button>
                            </>

                    }

                </DialogActions>
            </Dialog>

            {/* get form data like form schema  */}
            <BootstrapDialog
                fullWidth={true}
                onClose={handleCancel}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
            >

                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCancel}
                ></BootstrapDialogTitle>

                <DialogContent dividers>
                    <Profile userId={selectedData?.ID} />
                </DialogContent>
            </BootstrapDialog>

            <div className="page-continer ">
                {
                    loading ? <div className="example"> <Spin /></div> :
                        <>
                            {

                            }
                            <div className="action-header" style={{ marginBottom: 10 }}>
                                <Button
                                    danger
                                    type="primary"
                                    icon={<ControlOutlined />}
                                    onClick={() => setIsFilterOpen(true)}
                                    style={{ marginRight: 10 }}
                                />
                                <Button
                                    onClick={() => clearFilter()}
                                    style={{ marginRight: 10 }}
                                >
                                    {t("CLEAR FILTER")}
                                </Button>
                                <Button danger type="primary" onClick={() => setOpenExportDialog(true)} style={{ marginRight: 10 }}>
                                    {t('Import')} </Button>
                                <Button danger type="primary" onClick={() => handleOpenTemplateSelect()} style={{ marginRight: 10 }}>
                                    {t('Export')}
                                </Button>
                                {/* <Button danger onClick={() => { exportData() }} type="primary">{t('EXPORT')}</Button> */}
                            </div>
                            <div className="table-responsive">
                                <div className="scrol-table-div">
                                    {/* apply title user language */}
                                    {/* onChange={(row) => checkThisUser(row.bitrix.ID)} */}
                                    <Table dataSource={userList} columns={columns.map(col => { let { title, ...rest } = col; return { title: t(title), ...rest } })} pagination={false} />
                                </div>
                                <div className="mobile-table">
                                    {userList.map((el) => (
                                        <UserGridCard
                                            data={el}
                                            showModal={showModal}
                                        />
                                    ))}
                                </div>
                            </div>

                            <div style={{ marginTop: 15 }} className="pagination-container">
                                <Pagination onChange={(page, pageSize) => { changePagination(page, pageSize) }} current={page} defaultCurrent={page} total={dAtacount} />
                            </div>

                        </>
                }

            </div>

            <Drawer
                mask={true}
                className='drawer-filter'
                onClose={() => { setIsFilterOpen(false) }}
                open={isFilterOpen}
            >
                <div layout="vertical">
                    <div className="header-top">
                        <Title level={4}>
                            Filtter(s)
                        </Title>
                    </div>

                    <div className="sidebar-color">
                        <div className="">
                            <div className="">
                                <Form form={form} style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={onFilter} autoComplete="off">
                                    <Form.List name="rules_field">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <div key={key} className={`form-list-show`}>
                                                        <div className={`field-class`}>
                                                            <Form.Item

                                                                {...restField}
                                                                name={[name, 'key']}
                                                                rules={[{ required: false, message: 'Missing Field' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    showSearch
                                                                    placeholder="Select Field"
                                                                    allowClear
                                                                >
                                                                    {schemaComponent.map((row, index) => (
                                                                        <Option key={index} value={row.keyTxt}> {row.lableTxt}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`field-class`}>
                                                            <Form.Item dependencies={[["rules_field", name, "key"]]}>
                                                                {({ getFieldValue }) => {
                                                                    const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                                                    let showValue = false
                                                                    let enumArray = []
                                                                    if (type[name]) {
                                                                        const nameLevel = type[name]["key"] ? type[name]["key"] : null
                                                                        const sc = schemaComponent.find((comp) => comp.keyTxt === nameLevel)

                                                                        if (sc) {
                                                                            if (sc.data) {
                                                                                enumArray = sc.data
                                                                                showValue = true
                                                                            } else {
                                                                                showValue = false
                                                                            }
                                                                        }
                                                                        if (sc && sc.type == 'drop') {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        showValue ?
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                name={[name, 'value']}
                                                                                            >
                                                                                                <Select
                                                                                                    style={{ width: '100%' }}
                                                                                                    showSearch
                                                                                                    placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                    allowClear
                                                                                                >
                                                                                                    {enumArray.map((row, index) => (
                                                                                                        <Option value={row.id}>{row.name}</Option>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            : null
                                                                                    }
                                                                                </>

                                                                            );
                                                                        } else if (sc && sc.type == 'date') {

                                                                            return (
                                                                                <>


                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                    >
                                                                                        <RangePicker />

                                                                                    </Form.Item>

                                                                                </>
                                                                            );
                                                                        } else if (sc) {
                                                                            return (
                                                                                <>
                                                                                    {

                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'value']}
                                                                                        >
                                                                                            <Input
                                                                                                style={{ width: '100%' }}
                                                                                                showSearch
                                                                                                placeholder={sc.lableTxt ? sc.lableTxt : '-'}
                                                                                                allowClear
                                                                                            />

                                                                                        </Form.Item>

                                                                                    }
                                                                                </>

                                                                            );
                                                                        }


                                                                    }

                                                                }}
                                                            </Form.Item>
                                                        </div>

                                                        <div className={`action-class`}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </div>
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        ADD FILTER
                                                    </Button>
                                                </Form.Item>

                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            FILTER
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

        </>

    );
}

export default Users;
