

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col } from 'antd';
import { init } from 'i18next';
const { Option } = Select;

const LableTemp = (props) => {
    const { t, i18n } = useTranslation();
    const [val, setVal] = useState(null)
    const [storeName, setStoreName] = useState(null)
    const [format, setFormat] = useState(null)

    const [showFullObj, setShowFullObj] = useState(false)
    const [obj, setObj] = useState([])
    const init = async (e) => {
        const isHtml = Object.keys(e.schema.all).includes('isHTML') ? e.schema.all?.isHTML : (![undefined, null].includes(e.schema.all?.absaulteData) && Object.keys(e.schema.all?.absaulteData).includes('isHTML') ? e?.schema?.all?.absaulteData?.isHTML : true)
        const isBold = Object.keys(e.schema.all).includes('isBold') ? e?.schema?.all?.isBold : (![undefined, null].includes(e.schema.all?.absaulteData) && Object.keys(e.schema.all?.absaulteData).includes('isBold') ? e?.schema?.all?.absaulteData?.isBold : false)
        const headerList = Object.keys(e.schema.all).includes('headerList') ? e?.schema?.all?.headerList : (![undefined, null].includes(e.schema.all?.absaulteData) && Object.keys(e.schema.all?.absaulteData).includes('headerList') ? e?.schema?.all?.absaulteData?.headerList : null)
        const fontSize = Object.keys(e.schema.all).includes('fontSize') ? e?.schema?.all?.fontSize :(![undefined, null].includes(e.schema.all?.absaulteData) && Object.keys(e.schema.all?.absaulteData).includes('fontSize') ? e?.schema?.all?.absaulteData?.fontSize : 14)
        if (e?.schema?.display) {
            setVal(t(e?.schema?.display))
        } else {
            if (e?.label) {
                if (isHtml) {
                    let valu = e.schema.all.lableTxt ? `<span className='lable-name-div'> ${t(e.schema.all.lableTxt)}</span>` : '-'
                     
                    valu += ' : '
                    valu += e.schema.label ? t(e.schema.label) : '-'
                    setVal(valu)
                } else {
                    let htmltagStart = null
                    let htmltagend = null
                    if (headerList) {
                        htmltagStart = `<${headerList}>`
                        htmltagend = `</${headerList}>`
                    }
                    let htmltagboldStart = null
                    let htmltagbolend = null
                    if (isBold) {
                        htmltagboldStart = `<b>`
                        htmltagbolend = `</b>`
                    }
                    let valu = e.schema.all.lableTxt ? `<span className='lable-name-div' style="
                    font-size: ${fontSize}px;display: inline-block;"> 
                    ${htmltagStart ?  htmltagStart: ''}
                        ${htmltagboldStart ?  htmltagboldStart: ''}
                            ${t(e.schema.all.lableTxt)}${`:${e.schema.label ? t(e.schema.label) : '-'}`}
                        ${htmltagbolend ?  htmltagbolend: ''}
                    ${htmltagend ?  htmltagend: ''}
                    </span>
                    ` : '-'

                    // valu += ' : '
                    // valu += e.schema.label ? e.schema.label : '-'
                    setVal(valu)
                }
            }
        }

        const fullObj = e?.schema?.all?.selectedLableFullObject ? e.schema.all.selectedLableFullObject : null
        let Objarray = []
        if (fullObj) {
             
            Object.keys(fullObj).map((objKey) => (
                Objarray.push({
                    key: objKey,
                    value: fullObj[objKey] ? fullObj[objKey].toString() : null
                })

            ))

            if (fullObj?.Store_Name) {
                setStoreName(fullObj.Store_Name)
            }
            if (fullObj?.Format) {
                setFormat(fullObj.Format)
            }


        }


        setObj(Objarray)
         

    };

    //

    useEffect(() => {

        //
        init(props)
        //
    }, [props]);

  
    return (
        <div className='custom-component zindex-0-div'>

            <Row className='custom-component-row zindex-0-div'>
                {
                    format ?
                        <>
                            <Col span={12}>
                                <div className='show-obje show-obje-fixed zindex-0-div ' style={{ marginTop: 18 }} dangerouslySetInnerHTML={{ __html: val }} />
                            </Col>
                            <Col span={12}>
                                <div><span className='lable-name-div'>{t('Store Format')} </span> :  {format==='Express'?' Go Fresh':format}</div>
                            </Col>
                        </>
                        : <> 
                        <Col span={24} style={{maxWidth: '100%'}}>
                            <div className='show-obje show-obje-fixed zindex-0-div ' style={{ marginTop: 18 }} dangerouslySetInnerHTML={{ __html: val }} />
                        </Col></>
                }

            </Row>







            {/* {obj.length != 0 ? <div className='show-obje'>
                <Button size='small' onClick={(e) => { setShowFullObj(!showFullObj) }} type="text" shape="circle" icon={<CaretDownOutlined />} />
            </div> : null} */}
            {
                storeName ?
                    <div className='store-name'> <span className='lable-name-div'>{t('Store Name')}</span>  :  {storeName}</div>
                    : null
            }

            {
                showFullObj ? <>
                    {
                        obj.map((row) => (
                            <div>
                                <span className='lable-name-div'>{t(row.key)}</span> - {t(row.value)}

                            </div>
                        ))
                    }
                </> : null
            }

        </div>



    );
}



export default withJsonFormsControlProps(LableTemp);
