import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../../config";
import React, { useState } from "react";
import { getLocation } from "../../../utils/location";

let initialState = {
    selectedGroup:null
};





export const initState = createAsyncThunk(
    "element/initState",
    async (data) => {

        
            return true;
       
    }
);



export const selectGroup = createAsyncThunk(
    "element/selectGroup",
    async (data) => {

        
            return data;
       
    }
);






export const elementReducerSlice = createSlice({
    name: "element",
    initialState,
    extraReducers: {

        [initState.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            return {
                ...state,
                selectedGroup:null
            }
        },
        [selectGroup.fulfilled]: (state, action) => {
            const data = action.payload ? action.payload : null
            return {
                ...state,
                selectedGroup:data
            }
        },

    },



});

export default elementReducerSlice.reducer;


