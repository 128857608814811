/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio, Drawer, Checkbox, Skeleton
} from "antd";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined, FilterOutlined, PlayCircleOutlined
} from "@ant-design/icons";



import { useHistory } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";


import DashBoardApplication from "../components/layout/DashBoardApplication";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import { getAllFormsReducer, getAllFormsDashboard, onChangeFormStatusRaducer, filterValueForm } from "../redux/slices/formSettings"

import { addDynamicWidget, handleDynamicWidget, addFormToWidget, deleteDynamicWidget } from "../redux/slices/dynamicPage"
import { getSiteBarHeader } from "../redux/slices/configSetting";

import { DownloadOutlined, EyeOutlined, ForwardOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

// import { Responsive, WidthProvider } from "react-grid-layout";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";
import "./App.css";

import ava1 from "../assets/images/logo-shopify.svg";
import ava2 from "../assets/images/logo-atlassian.svg";
import ava3 from "../assets/images/logo-slack.svg";
import ava4 from "../assets/images/logo-spotify.svg";
import ava5 from "../assets/images/logo-jira.svg";
import ava6 from "../assets/images/logo-invision.svg";
import team1 from "../assets/images/team-1.jpg";
import team2 from "../assets/images/team-2.jpg";
import team3 from "../assets/images/team-3.jpg";
import team4 from "../assets/images/team-4.jpg";
import card from "../assets/images/info-card-1.jpg";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);

function Home() {

  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const history = useHistory();
  console.log('history', history)
  const onChange = (e) => console.log(e) ;

  const tempHeaderItems = useSelector(state => state.formSettings)

  const configuration = useSelector(state => state.setting.configuration)


  const dashboard = useSelector(state => state.setting.dashboard)

  const [reverse, setReverse] = useState(false);
  const [visible, setVisible] = useState(false);


  const [selectedType, setSelectedType] = useState(['AF', 'PF', 'IF', 'IW'])
  const [selectedStatus, setSelectedStatus] = useState(['Active', 'Draft', 'Deactivated'])

  const [loading, setLoading] = useState(false)



  const [layouts, setLayouts] = useState(null);

  const listForm = useSelector((state) => {
     
    let list = []
    if (state?.formSettings?.forms) {
      for (const form of state.formSettings.forms) {
        let obj = {
          label: form.name,
          key: form.id,
          data: form
        }
        list = [...list, obj]
      }
    }
    return list
    // setListForm(list)


  })

  const taskProcessModelOpen = (data, formid, formDet) => {

    history.push({
      pathname: "/task-process/" + data.id,
      state: {
        detail: data,
        form: formDet,
        last: formid
      }
    })
    // setFormScheemaTask(data.formProccess.proccessFormScheema)

    // setFormGroupTask(data.formProccess.proccessFormGroups)

    // setFormTask(data.formProccess.proccessForm)

    // setSelectedAnswerId(data.id)



    //  
    // setjsonProcessAnswer(data.formProccess.jsonProcessAnswer)

    // setTaskProcessOpen(true)
  }

  const [widgetArray, setWidgetArray] = useState(
    [
      {
        "i": "widget1",
        "x": 2,
        "y": 2,
        "w": 2,
        "h": 2,
        "width": 2,
        "height": 2
      },
      {
        "i": "widget2",
        "x": 0,
        "y": 0,
        "w": 2,
        "h": 2,
        "width": 2,
        "height": 2
      },
      {
        "i": "widget3",
        "x": 2,
        "y": 0,
        "w": 2,
        "h": 2,
        "width": 2,
        "height": 2
      },
      {
        "i": "widget4",
        "x": 0,
        "y": 4,
        "w": 2,
        "h": 2,
        "width": 2,
        "height": 2
      }
    ]
  );

  const handleMenuClick = async (e, i) => {
    message.info('widget change.');

    const obj = {
      widget: i,
      form: e.key
    }
    await dispatch(addFormToWidget(obj))
    dispatch(getSiteBarHeader())
  };

  const handleModify = async (layouts, layout) => {

    if (layouts.length == 0) {
      return
    }

    const obj = {
      layouts: layouts,
      layout: layout
    }
    await dispatch(handleDynamicWidget(obj))
    dispatch(getSiteBarHeader())
  };

  const handleAdd = async () => {
    await dispatch(addDynamicWidget())
    dispatch(getSiteBarHeader())

    // setWidgetArray([
    //   ...widgetArray,
    //   { i: "widget" + (widgetArray.length + 1), x: 0, y: 0, w: 2, h: 2 },
    // ]);
    // 
  };

  const handleDelete = async (key) => {
    const obj = {
      key: key,
    }
    await dispatch(deleteDynamicWidget(obj))
    dispatch(getSiteBarHeader())



  };




  const list = [
    {
      img: ava1,
      Title: "Soft UI Shopify Version",
      bud: "$14,000",
      progress: <Progress percent={60} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Jessica Doe">
            <img className="tootip-img" src={team4} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava2,
      Title: "Progress Track",
      bud: "$3,000",
      progress: <Progress percent={10} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava3,
      Title: "Fix Platform Errors",
      bud: "Not Set",
      progress: <Progress percent={100} size="small" status="active" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava4,
      Title: "Launch new Mobile App",
      bud: "$20,600",
      progress: <Progress percent={100} size="small" status="active" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava5,
      Title: "Add the New Landing Page",
      bud: "$4,000",
      progress: <Progress percent={80} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Jessica Doe">
            <img className="tootip-img" src={team4} alt="" />
          </Tooltip>
        </div>
      ),
    },

    {
      img: ava6,
      Title: "Redesign Online Store",
      bud: "$2,000",
      progress: (
        <Progress
          percent={100}
          size="small"
          status="exception"
          format={() => "Cancel"}
        />
      ),
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const timelineList = [
    {
      title: "$2,400 - Redesign store",
      time: "09 JUN 7:20 PM",
      color: "green",
    },
    {
      title: "New order #3654323",
      time: "08 JUN 12:20 PM",
      color: "green",
    },
    {
      title: "Company server payments",
      time: "04 JUN 3:10 PM",
    },
    {
      title: "New card added for order #4826321",
      time: "02 JUN 2:45 PM",
    },
    {
      title: "Unlock folders for development",
      time: "18 MAY 1:30 PM",
    },
    {
      title: "New order #46282344",
      time: "14 MAY 3:30 PM",
      color: "gray",
    },
  ];

  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {

      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const getInit = async () => {
    setLoading(true)
    // await dispatch(getAllFormsDashboard())

    setLoading(false)
  }

  const plainOptionsAdmin = ['Active', 'Draft', 'Deactivated'];
  const formTypeAdmin = [
    {
      label: "Admin Forms",
      value: "AF",
    },
    {
      label: "Public forms",
      value: "PF",
    },
    {
      label: "Internal - Workflow",
      value: "IW",
    },

    {
      label: "Internal - Form",
      value: "IF",
    },
  ]

  const plainOptions = ['Active'];
  const formType = [
    {
      label: "Internal - Workflow",
      value: "IW",
    },

    {
      label: "Internal - Form",
      value: "IF",
    },
  ]

  const onChangeFilter = (e, fil) => {

    if (fil === 'status') {
      const filters = { status: e, type: selectedType }
      setSelectedStatus(e)


      dispatch(filterValueForm(filters))
    } else {
      const filters = { status: selectedStatus, type: e }
      setSelectedType(e)

      dispatch(filterValueForm(filters))
    }

  }

  const moveToForm = (e) => {

    if (e.type === 'PF') {

      history.push("/forminterface?id=" + e.id)
    } else {
      history.push("/internal-form/submit-form?id=" + e.id)

    }

  }

  const moveToAnswer = (e) => {

    let link = "/internal-form/answer?id=" + e.id
    history.push(link)

  }



  useEffect(() => {
    getInit()
  }, []);

  return (
    <>
      <div className="layout-content">


        <Skeleton loading={loading} active>

          <DashBoardApplication />
          

         {/* latest working dashboard widgets, disabled for better UI for now */}
          {/* <div>
            <button onClick={() => handleAdd()}>Add Widget</button>

            <ResponsiveReactGridLayout
              //onLayoutChange={handleModify}
              onDragStop={handleModify}
              onResizeStop={handleModify}
              verticalCompact={true}
              layout={layouts}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              preventCollision={false}
              cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }}
              autoSize={true}
              margin={{
                lg: [20, 20],
                md: [20, 20],
                sm: [20, 20],
                xs: [20, 20],
                xxs: [20, 20],
              }}
            >
              {dashboard?.widget?.map((widget, index) => {

                return (
                  <div
                    className="reactGridItem"
                    key={index}
                    data-grid={{
                      x: widget?.x,
                      y: widget?.y,
                      w: widget?.width ? widget.width : widget?.w,
                      h: widget?.height ? widget.height : widget?.h,
                      i: widget.i,
                      minW: 2,
                      maxW: Infinity,
                      minH: 2,
                      maxH: Infinity,
                      isDraggable: true,
                      isResizable: true,
                    }}
                  >
                    {
                       
                    }
                    <button
                      className="deleteButton"
                      onClick={() => handleDelete(widget.i)}
                    >
                      x
                    </button>
                    <Dropdown.Button
                      trigger={['click']}
                      className="action-menu-button"
                      overlay={<Menu
                        onClick={(e) => { handleMenuClick(e, widget.i) }}
                        items={listForm}
                      />}>
                    </Dropdown.Button>
                    <div className="widget-body">

                      <div className="ant-list-box table-responsive">
                        {
                          widget?.formSchama ?

                            <table className="width-100">
                              <thead>
                                <tr>
                                  <th>action</th>
                                  {
                                    widget.formSchama.filter((e) => e.showInHeaderDashboard).map((s) => {
                                      return (
                                        <th>{s.lableTxt}</th>
                                      )

                                    })
                                  }


                                </tr>
                              </thead>

                              <tbody>
                                {
                                  widget.array.map((row) => {
                                    return (
                                      <>

                                        {

                                          // row.formProccess && row.responsibleUser && !row?.formProccess?.jsonProcessAnswer?.isComplete ?

                                          < tr >
                                          {
                                                
                                          }
                                            <td> {

                                              row.formProccess && row.responsibleUser && !row?.formProccess?.jsonProcessAnswer?.isComplete ? <Button type="text" style={{ verticalAlign: '0px' }} onClick={() => { taskProcessModelOpen(row, widget.form, row.formProccess.proccessForm) }} icon={<PlayCircleOutlined />} /> : null
                                            }</td>

                                            <>
                                              {
                                                widget.formSchama.filter((e) => e.showInHeaderDashboard).map((s) => {
                                                  return (
                                                    <td>{row?.data?.database?.main[s.keyTxt] ? row.data.database.main[s.keyTxt] : '-'}</td>
                                                  )

                                                })
                                              }

                                            </>




                                          </tr>
                                          //: null
                                        }

                                      </>

                                    )

                                  })
                                }
                              </tbody>



                            </table> : <></>
                        }


                      </div>
                    </div>
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
          </div> */}

          {/* <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Echart />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <LineChart />
              </Card>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
              <Card bordered={false} className="criclebox cardbody h-full">
                <div className="project-ant">
                  <div>
                    <Title level={5}>Projects</Title>
                    <Paragraph className="lastweek">
                      done this month<span className="blue">40%</span>
                    </Paragraph>
                  </div>
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      <Radio.Group onChange={onChange} defaultValue="a">
                        <Radio.Button value="a">ALL</Radio.Button>
                        <Radio.Button value="b">ONLINE</Radio.Button>
                        <Radio.Button value="c">STORES</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="ant-list-box table-responsive">
                  <table className="width-100">
                    <thead>
                      <tr>
                        <th>COMPANIES</th>
                        <th>MEMBERS</th>
                        <th>BUDGET</th>
                        <th>COMPLETION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <h6>
                              <img
                                src={d.img}
                                alt=""
                                className="avatar-sm mr-10"
                              />{" "}
                              {d.Title}
                            </h6>
                          </td>
                          <td>{d.member}</td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.bud}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="percent-progress">{d.progress}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="uploadfile shadow-none">
                  <Upload {...uploadProps}>
                    <Button
                      type="dashed"
                      className="ant-full-box"
                      icon={<ToTopOutlined />}
                    >
                      <span className="click">Click to Upload</span>
                    </Button>
                  </Upload>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="timeline-box">
                  <Title level={5}>Orders History</Title>
                  <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    this month <span className="bnb2">20%</span>
                  </Paragraph>

                  <Timeline
                    pending="Recording..."
                    className="timelinelist"
                    reverse={reverse}
                  >
                    {timelineList.map((t, index) => (
                      <Timeline.Item color={t.color} key={index}>
                        <Title level={5}>{t.title}</Title>
                        <Text>{t.time}</Text>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                  <Button
                    type="primary"
                    className="width-100"
                    onClick={() => setReverse(!reverse)}
                  >
                    {<MenuUnfoldOutlined />} REVERSE
                  </Button>
                </div>
              </Card>
            </Col>
          </Row> */}

          {/* <Row gutter={[24, 0]}>
            <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Row gutter>
                  <Col
                    xs={24}
                    md={12}
                    sm={24}
                    lg={12}
                    xl={14}
                    className="mobile-24"
                  >
                    <div className="h-full col-content p-20">
                      <div className="ant-muse">
                        <Text>Built by developers</Text>
                        <Title level={5}>Muse Dashboard for Ant Design</Title>
                        <Paragraph className="lastweek mb-36">
                          From colors, cards, typography to complex elements, you
                          will find the full documentation.
                        </Paragraph>
                      </div>
                      <div className="card-footer">
                        <a className="icon-move-right" href="#pablo">
                          Read More
                          {<RightOutlined />}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    sm={24}
                    lg={12}
                    xl={10}
                    className="col-img"
                  >
                    <div className="ant-cret text-right">
                      <img src={card} alt="" className="border10" />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
              <Card bordered={false} className="criclebox card-info-2 h-full">
                <div className="gradent h-full col-content">
                  <div className="card-content">
                    <Title level={5}>Work with the best</Title>
                    <p>
                      Wealth creation is an evolutionarily recent positive-sum
                      game. It is all about who take the opportunity first.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a className="icon-move-right" href="#pablo">
                      Read More
                      <RightOutlined />
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
          </Row> */}
        </Skeleton>
      </div >
    </>
  );
}

export default Home;
