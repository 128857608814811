import React, { useState, useEffect } from "react";


import {
    useDispatch, useSelector
} from "react-redux";
import moment from 'moment'
import { ValueDisplay } from '../elements/ValueDisplay'
import { ApprovalRow } from '../elements/ApprovalRow'

import { WorkFlowViewDrawer } from './WorkFlowViewDrawer'

import { MinusCircleOutlined, PlayCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { manageViewAnswerDrawer } from "../../redux/slices/dynamicPage";
import { openMoreOptionDrawer } from "../../redux/slices/answer/answerSetting";
import { workFlowDrawerOpen } from "../../redux/slices/workflow";
import { authenticatedWithUrl, signInWithCredintials } from "../../redux/slices/users";
import { Radio, Tabs } from 'antd';
import auth from "../../auth/auth"
import { MoreOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox, Form, Drawer, Typography, DatePicker
} from "antd";
import { FormViewInterface } from "../../pages/settings/JsonForm/viewInteface/FormViewInterface";
import DialogContent from "@mui/material/DialogContent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};



export function KeyLogRedirection(props) {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();


    const [isAuthenticating, setAuthenticating] = useState(false)

    const [status, setStatus] = useState(null)
    const redirectWithUrl = async (myParam) => {
        // const hisoryUrl = props?.location?.state?.url ? props.location.state.url : null

        const respons = await dispatch(authenticatedWithUrl(myParam))

        if (respons.payload.login) {
            setStatus('Redirecting...')
            if (respons.payload.token) {

                auth.login(respons.payload.token, (res) => {
                    if (res) {
                        // alert(res)
                        props.history.push("/attendance")
                    }
                    //
                })
                //await signIn(respons.payload.token);
                //navigate("/dashboard/default");
            } else {
                setAuthenticating(false)
            }
        } else {
            setAuthenticating(false)
        }

    }

    useEffect(() => {
        console.log('redirect', props.log)
        const isAuth = props?.log?.authenticated ? props.log.authenticated : null
        const empCode = props?.log?.tokenParsed?.empCode ? props.log.tokenParsed.empCode : null
        console.log('redirect', isAuth)
        console.log('redirect', empCode)
        if (isAuth && empCode) {
            console.log('redirect', props.log)
            redirectWithUrl(empCode)
        }
    }, [props]);



    return (

        <div className='grid-row'>

            {status}
        </div>

    );
}