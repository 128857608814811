/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, Skeleton, Switch } from 'antd';
import React, { useState } from 'react';
const { Meta } = Card;

function Unautherize() {
  

  return (
    <>
      <Card
        style={{
          width: '100%',
          marginTop: 16,
        }}
      >
        <Meta
          avatar={<Avatar
            style={{
              height:'100%',
              width:' 99px'
            }}
            src="https://www.pngall.com/wp-content/uploads/2/No-Entry-Symbol.png" />}
          title="Unautherize access"
          description="you dont have access to this page...."
        />
      </Card>
    </>
  );
}

export default Unautherize;
