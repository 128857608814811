import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import moment from 'moment'
import {Col} from "antd";
import {ElementValue} from "../../pages/settings/JsonForm/viewInteface/components/ElementValue";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function ApprovalRow(props) {

    const dispatch = useDispatch();

    const [approval, setApproval] = useState(null)

    const [rejected, setRejected] = useState(false)

    const [status, setStatus] = useState(null)

    const init = () =>{
         

        if(props.wrokFlow.approvalStageData){
            setApproval(props.wrokFlow.approvalStageData)
        }
        if(props.wrokFlow.taskStage){
            setStatus(props.wrokFlow.taskStage)
        }
        if(props.wrokFlow.rejected){
            setRejected(props.wrokFlow.rejected)
        }

    }
    useEffect(() => {

        init();

    }, [props]);



    return (

        <div className="">
            <div className=''>
                {
                    rejected ?<div className='status-container-reject'>rejected</div>
                        :status=='Completed'?<div className='status-container-completed'>{status}</div>
                        : <div className='status-container-inprogress'>{status}</div>

                }
            </div>

        </div>

    );
}