

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select ,InputNumber  } from 'antd';
import { init } from 'i18next';
const { Option } = Select;

const Clearing = (props) => {
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const { t, i18n } = useTranslation();
    const [drowDown, setDrowDown] = useState(0)
    const [Used, setUsed] = useState(0)
    const [Urenderfunsed, setrenderfyn] = useState(false)
    const init = async () => {
        setUsed(props?.data?.used ? props.data.used : 0)

        setDrowDown(props?.data?.drowDown ? props.data.drowDown : 0)


    }
    const updateData = async (e) => {

        const propData = props
        const formData = wholeDataObject
        const used = formData?.database?.main[propData.schema.info.targetValue] ? wholeDataObject.database.main[propData.schema.info.targetValue] : 0
        let obj = {
            used: used,
            drowDown: e,
            pay: e - used
        }
        // setValueData(props.data)
        props.handleChange(props.path, obj)


    };
    //

    useEffect(() => {
        //
        init()

        //
    }, [props]);
    return (
        <>
        <h3>{ props?.schema?.info?.lableTxt?t( props.schema.info.lableTxt):'-'}</h3>
            <table>
                <tr>
                    <td> {t('Drowdown')}</td>
                    <td>
                    <InputNumber   formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} value={drowDown} onChange={updateData} />
                    </td>
                      
                </tr>
                <tr>
                    <td>{t('Used')}</td>
                    <td>{Used?Used.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0}</td>
                </tr>
                <tr>
                    <td>{t('Pay')}</td>
                    <td>{(drowDown - Used).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
            </table>


        </>



    );
}



export default withJsonFormsControlProps(Clearing);
