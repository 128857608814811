

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';
import $ from 'jquery';

import {
    closeClockOutMenu, shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";

import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";


import {
    ShareLocation
} from "../../../../attandence/components/ShareLocation";
const { Option } = Select;

const AutoFill = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [lable, setLable] = useState(null)
    const [selectedElement, setselectedElement] = useState(null)
    const errorList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.absaulteData?.parentFocusValue) {

                const conitionBase = props.schema.schemaElement.absaulteData.parentFocusValue
                if (data?.database?.main[conitionBase]) {
                    error = data.database.main[conitionBase]

                    if(props?.schema?.schemaElement?.parentEnum){
                        const selectedEn=props.schema.schemaElement.parentEnum.find((ed)=>ed.const===error)

                        if(selectedEn.data[props.schema.schemaElement.keyTxt]){
                            error=selectedEn.data[props.schema.schemaElement.keyTxt]
                        }
                        
                    }
                    
                }


            }

        }

        return error

    })

    useEffect(() => {
        props.handleChange(props.path,errorList)
        //setselectedElement(props.schema.schemaElement.absaulteData.parentFocusValue?props.schema.schemaElement.absaulteData.parentFocusValue:null)
    }, [errorList]);



    useEffect(() => {
        console.log('autofill', props)
        //setselectedElement(props.schema.schemaElement.absaulteData.parentFocusValue?props.schema.schemaElement.absaulteData.parentFocusValue:null)
    }, [props]);
    return (
     <></>



    );
}



export default withJsonFormsControlProps(AutoFill);
