

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import { useDispatch, connect, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { CameraOutlined } from '@ant-design/icons';
import { Camera } from "./camera";
import { getLocation } from "../../../../../utils/location";
import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";

import {
    shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import $ from 'jquery';
import { Image, Select, Col, Row, Input, Alert, Spin } from 'antd';
// or
import { Grid, Link, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { UploadImageToS3WithReactS3 } from "./s3";
import { Button as AntButton, Space } from 'antd';
import { DatePicker } from 'antd';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const dateFormat = 'YYYY/MM/DD ';
const ClockedEdit = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [value, setValue] = React.useState('2022-04-07');
    const [valueOut, setValueOut] = React.useState('2022-04-07');
    const [data, setData] = useState(null)
    const onChangeIn = (value, dateString) => {


        console.log('Selected Time: ', moment(dateString).format('MM:DD:YYYY, h:mm:ss a'));
        if (props?.data?.clockIn?.dateTime) {
            let samplObj = props.data
            samplObj = {
                ...samplObj,
                clockIn: {
                    ...samplObj.clockIn,
                    dateTime: moment(dateString).format('M/D/YYYY, h:mm:ss a')
                }
            }
            props.handleChange(props.path, samplObj)

        }
    };

    const onChangeOut = (value, dateString) => {


        if (props?.data?.clockOut?.dateTime) {
            let samplObj = props.data
            samplObj = {
                ...samplObj,
                clockOut: {
                    ...samplObj.clockOut,
                    dateTime: moment(dateString).format('M/D/YYYY, h:mm:ss a')
                }
            }
            props.handleChange(props.path, samplObj)

        }
    };
    const onOk = (value) => {

        console.log('onOk: ', value);
    };

    useEffect(() => {

        $('.MuiButton-containedPrimary').prop("hidden", true);
        $('.MuiButton-containedSecondary').prop("hidden", true);

        if (props?.data?.clockIn?.dateTime) {
            console.log('onOk: ', props.data.clockIn.dateTime);
            setValue(props.data.clockIn.dateTime)
        }
        if (props?.data?.clockOut?.dateTime) {
            console.log('onOk: ', props.data.clockOut.dateTime);
            setValueOut(props.data.clockOut.dateTime)
        }
        //
    }, [props]);
    return (
        <div className='clocked-normal-view'>
            <div className='clocked-normal-view-atta clocked-normal-view-left'>
                <div style={{fontWeight:'bold'}}>
                    ClockIn Date / Time
                </div>
                <div>
                    <DatePicker style={{width:'100%'}} showTime value={dayjs(value)} onChange={onChangeIn} />
                </div>


            </div>
            <div className='clocked-normal-view-atta clocked-normal-view-right'>
                <div style={{fontWeight:'bold'}}>
                    ClockOut Date / Time
                </div>
                <div>
                    <DatePicker style={{width:'100%'}}  showTime value={dayjs(valueOut)} onChange={onChangeOut} />
                </div>


            </div>



        </div>




    );
}



export default withJsonFormsControlProps(ClockedEdit);
