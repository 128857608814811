import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "../JsonForm/style.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
} from "@mui/material";
import {
  ExportOutlined,
  SearchOutlined,
  ImportOutlined,
  FileAddOutlined,
  SettingTwoTone,
  DeleteOutlined,
  EditOutlined,
  ForwardOutlined,
  CopyOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";

// import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { LanguageEdit } from "../JsonForm/intefaces/LanguageEdit";

import { getAllLanguage,addLanguageBulk } from "../../../redux/slices/language";

import {
  getFormSchema,
  getFormId,
  getAllGroupRaducer,
} from "../../../redux/slices/formSettings";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  Row,
  Col,
  Radio,
  Table,
  Button,
  Select as AntSelect,
  Pagination,
  Dropdown,
  Menu,
  notification,
} from "antd";

import * as XLSX from "xlsx";

const Language = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const Divider = styled(MuiDivider)(spacing);

  const [openFormManageModelm, setFormManagementModel] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState({});

  const [jsonScema, setScheemaComponent] = useState([]);
  const [groupSelectedList, setGroupList] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [searchText, setSearch] = useState("");

  const [selectEnglish, setSelectEnglish] = useState(false);
  const [selectThai, setSelectThai] = useState(false);

  const [showFormAnswerList, setShowFormAnswerList] = useState(false);
  const [languages, setLanguages] = useState([]);

  // pagination
  const [dataCount, setDataCount] = useState(0);

  const [selectedPage, setSelectedPage] = useState(1);

  const [dataPerPage, setDataPerPage] = useState(10);

  //pagination end

  //table
  const [columns, setColomn] = useState([]);

  const handleFile = async (e) => {
    setLoading(true)
    const file = e.target.files[0];
    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data)

    const workSheed = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData = XLSX.utils.sheet_to_json(workSheed)
    await dispatch(addLanguageBulk(jsonData));
    setLoading(false)

  }

  const getFormData = async () => {
    const formData = await dispatch(getFormId("language"));
    if (formData) {
      if (formData.payload) {
        if (formData.payload.length != 0) {
          const formSchema = await dispatch(
            getFormSchema(formData.payload[0].id)
          );
          const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));

          let colArray = [];
          const widthcol = 95 / formSchema.payload.length;
          for (const scemaEl of formSchema.payload) {
            colArray.push({
              title: scemaEl.lableTxt,
              dataIndex: scemaEl.keyTxt,
              key: scemaEl.keyTxt,
              width: widthcol + "%",
            });
          }

          colArray.push({
            title: "Action",
            key: "action",
            dataIndex: "action",
            width: "5%",
          });
          setColomn(colArray);

          if (gg.payload) {
            setGroupList(gg.payload);
          }

          if (formSchema.payload) {
            setScheemaComponent(formSchema.payload);
          }
          getAllLanguageFunction(1, dataPerPage, colArray);
        }
      }
    }
  };

  const exportSheet = async () => {
    const obj = {
      searchText: searchText !== "" ? searchText : false,
      selectThai: selectThai,
      selectEnglish: selectEnglish,
      pagination: false,
    };

    const langs = await dispatch(getAllLanguage(obj));
    const languageSet = langs.payload.data;
    let excelArra = [];
    let headerArray = ["Key", "English", "Thai"];
    excelArra.push(headerArray);

    if (languageSet) {
      for (const element of languageSet) {
        excelArra.push([element.keyName, element.en, element.th]);
      }
    }

    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelArra);

    XLSX.utils.book_append_sheet(wb, ws, "my", true);
    XLSX.writeFile(wb, "Export Language.xlsx");
  };

  const handleFormManageModel = (e) => {
    setFormManagementModel(e);
  };
  const editFormManageModel = (e) => {
    setSelectedLanguage(e);
    setFormManagementModel(true);
  };

  const handleCloseFormManagement = () => {
    setFormManagementModel(false);
  };

  const changePagination = (pageNo, pageSize) => {
    setSelectedPage(pageNo);
    getAllLanguageFunction(pageNo, pageSize);
  };

  const handleMenuClick = (e) => {
 
    const action = e.key;
    const id = e.item.props.items.id;
    switch (action) {
      case "1":
        editFormManageModel(id);
        break;
      default:
        return;
    }
  };

  const getAllLanguageFunction = async (
    selectedPageParam = 1,
    rowPerRequest = dataPerPage,
    colArray = columns
  ) => {

    const obj = {
      searchText: searchText !== "" ? searchText : false,
      selectThai: selectThai,
      selectEnglish: selectEnglish,
      selectedPage: selectedPageParam,
      rowPerRequest: rowPerRequest,
      pagination: true,
    };
    const langs = await dispatch(getAllLanguage(obj));

    const jsonRes = langs.payload;
    setDataCount(jsonRes.total);

    let tempArray = [];
    if(jsonRes.data){
      for (let i = 0; i < jsonRes.data.length; i++) {
        let el = jsonRes.data[i];
        let row = {};
  
        for (let i = 0; i < colArray.length; i++) {
          let col = colArray[i];
          if (col.key === "keyWord") {
            row.keyWord = el.keyName;
          } else {
            row[col.key] = el[col.key];
          }
        }
        row.action = (
          <>
            <Dropdown.Button
              trigger={["click"]}
              className="action-menu-button"
              overlay={
                <Menu
                  onClick={handleMenuClick}
                  items={[
                    {
                      label: "Edit",
                      key: "1",
                      items: { id: el },
                      icon: <EditOutlined />,
                    },
                  ]}
                />
              }
              icon={<SettingTwoTone twoToneColor="#28a745" />}
            />
          </>
        );
        tempArray.push(row);
      }
    }else{
      setLanguages([])
    }
    


    setSelectedPage(selectedPageParam);
    setLanguages(tempArray);
  };

  const refreshPage = () => {
    setLoading(true);
    getAllLanguageFunction();
    setSelectedLanguage({});
    setFormManagementModel(false);
    setLoading(false);
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <>
      <React.Fragment>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div className="page-continer ">
              <Typography variant="body2" gutterBottom>
                <Grid
                  className="page-action-header"
                  style={{ padding: 8 }}
                  container
                  spacing={2}
                >
                  <Grid
                    style={{ padding: 8, paddingBottom: 0 }}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                  >
                    <TextField
                      label="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid
                    style={{ padding: 8, paddingBottom: 0 }}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSelectEnglish(!selectEnglish);
                            }}
                            checked={selectEnglish}
                          />
                        }
                        label="English"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    style={{ padding: 8, paddingBottom: 0 }}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setSelectThai(!selectThai);
                            }}
                            checked={selectThai}
                          />
                        }
                        label="Thai"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid
                  className="page-action-header"
                  style={{ padding: 8 }}
                  container
                  spacing={2}
                >
                  <Grid style={{ padding: 8, paddingBottom: 0 }} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Button
                          block={true}
                          danger
                          onClick={() => getAllLanguageFunction()}
                          icon={<SearchOutlined />}
                        >
                          Search
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Button
                          block={true}
                          danger
                          onClick={exportSheet}
                          icon={<ExportOutlined />}
                        >
                          Export
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <div className="image-upload export-button">
                          <label className="lable-class" for="file-input">
                            Import
                          </label>

                          <input
                            id="file-input"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            onChange={(e) => handleFile(e)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Button
                          block={true}
                          danger
                          onClick={() => {
                            handleFormManageModel(true);
                          }}
                          icon={<FileAddOutlined />}
                        >
                          New
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>

              <div className="table-responsive">
                {isLoading ? null : (
                  <>
                    <Table
                      columns={columns}
                      dataSource={languages}
                      pagination={false}
                      className="ant-border-space"
                    />
                    <div className="pagination-container">
                      <Pagination
                        onChange={(page, pageSize) => {
                          changePagination(page, pageSize);
                        }}
                        defaultCurrent={1}
                        total={dataCount}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={openFormManageModelm}
          onClose={handleCloseFormManagement}
        >
          <DialogContent>
            <LanguageEdit
              elementGroup={groupSelectedList}
              jsonSchema={jsonScema}
              id={selectedLanguage}
              handleClose={refreshPage}
              refreshPage={() => refreshPage()}
            />

            {/* <ManageLanguage selectedLanguage={selectedLanguage} refreshPage={() => { refreshPage() }} /> */}
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={showFormAnswerList}
          onClose={() => {
            setShowFormAnswerList(false);
          }}
        >
          <DialogTitle>Submit Answer List</DialogTitle>
          <DialogContent>
            {/* <ManageUser jsonSchema={schemaComponent} /> */}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </>
  );
};



export default Language
