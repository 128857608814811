

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';
import $ from 'jquery';
import { FullScreenLoader } from '../../../../../components/elements/FullScreenLoader'
import {
    closeClockOutMenu, shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";
import { NavLink, Link, useHistory } from "react-router-dom";
import { Space } from 'antd';
import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";
import auth from "../../../../../auth/auth.js";


import {
    ShareLocation
} from "../../../../attandence/components/ShareLocation";
const { Option } = Select;

const ShareLocationJson = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [lable, setLable] = useState(null)


    const history = useHistory();


    const init = (data) => {

        if (props?.schema?.schemaElement?.group) {
            //console.log('share-location', props.schema.schemaElement.group)
            dispatch(selectGroup(props.schema.schemaElement.group))

        } else {
            dispatch(selectGroup(null))

        }

        if (props?.schema?.schemaElement?.lableTxt) {
            setLable(props.schema.schemaElement.lableTxt)
        } else {
            setLable(null)
        }
    }

    const [latitude, setlatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)


    const [selectedStore, setSelectedStore] = useState(null)

    const [loadingMsg, setLoadingMsg] = useState('')
    const [locationFail, setLoacationFail] = useState(null)


    const getGeo = async () => {
        

        const token = localStorage.getItem("token");
              console.log(token? token :"no token")

        if(localStorage.getItem("token")){

            
            const res = await dispatch(shareLocationReducer({name:`${userInformation.NAME} ${userInformation.LAST_NAME}` ,emp:userInformation.emCode}))

            // if (res.payload) {
                
            // }

        } else{
            alert(t('กรุณาเข้าสู่ระบบอีกครั้ง | Please login again'))
            
            auth.logout((res) => {
                if (!res) {
                  // alert(res)
                  history.push("/sign-in?logout=true");
                }

                //
              });

        }

        

    };

    const [isgpsLoading, gpsLoading] = useState(false)

    const stores = useSelector((state) => {


        let error = null
        if (state?.markAttandance?.shopData) {


            error = state.markAttandance.shopData
            if (error.length != 0) {
                $(".MuiButton-containedPrimary").trigger("click")
                $('.MuiButton-containedPrimary').removeClass("Mui-disabled");

            }

        }
        return error

    })

    const userInformation = useSelector(
        (prState) => prState?.user?.loginUser?.database || null
      );
    const isLoading = useSelector((state) => {


        let error = null
        if (state?.markAttandance?.shareLocationReload) {


            error = state.markAttandance.shareLocationReload


        }
        return error

    })

    const error = useSelector((state) => {


        let error = null
        if (state?.markAttandance?.locationError) {


            error = state.markAttandance.locationError


        }
        return error

    })



    useEffect(() => {



        $('.MuiButton-containedPrimary').prop("hidden", true);
        $('.MuiButton-containedSecondary').prop("hidden", true);

        // $('.MuiButton-containedPrimary').addClass("Mui-disabled");
        init(props)

    }, [props]);
    return (
        <div className='share-location-page'>

            {
                isLoading ? <><FullScreenLoader /> </> : null
            }
            <div className='share-location-page-welcome'>
                <span>{t('Hi')}!</span>
            </div>
            <div className='share-location-page-lable'>
                <span>{t('Are you ready to work')} ? <br /> {t('Please share your current location to clock in')}</span>
            </div>

            {
                error ?
                    <div className='error-div'>

                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                            }}
                        >
                            <Space
                                direction="vertical"
                                style={{
                                    width: '100%',
                                    marginBottom:'10px'
                                }}
                            >
                                <Alert
                                    message={`Error : ${t(error)}`}
                                    description={t("Can not clocking without shareing location. please share location to continue.")}
                                    type="error"
                                />
                            </Space>
                        </Space>

                    </div>
                    : null
            }

            <div onClick={getGeo} className='share-location-page-Button'>
                <Button type='primary' danger >{t('Share my location')}</Button>
            </div>
            <ShareLocation />





        </div>



    );
}



export default withJsonFormsControlProps(ShareLocationJson);
