import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Radio,
  Dropdown, Menu, Space, Popover, Skeleton
} from "antd";

import moment from 'moment'
import { styled } from "@mui/material/styles";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import Dialog from '@mui/material/Dialog';
import { getFetchUsers, updateUserService, updateBulkUserService, getFetchUsersWithoutPaginate, getUserDataById, getBitrixAllDbFields, getLogedInUserData } from "./../redux/slices/users";
import { addFormElementToServer, getFormSchema, getFormId, getAllGroupRaducer } from "./../redux/slices/formSettings"
import { ElementValue } from "./Users/components/ElementValue"
import { GroupUserEdit } from "./settings/JsonForm/intefaces/GroupUserEdit"
import Slide from '@mui/material/Slide';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import Clearing from "./settings/JsonForm/custom/clearing/Clearing";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const gridStyle = {
  width: '50%',

};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  background: "white",
  padding: "8px 0",
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

const Profile = (props) => {


  const { userId } = props || {};
  console.log('user Id', userId);


  //  useEffect(()=>{

  //  setUserData(obj)

  //  },[])

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [schemaComponent, setScheemaComponent] = useState([]);
  const [groupSelectedList, setGroupSelectedList] = useState([])
  const [isLoading, loding] = useState(false)
  const [openUserManageModel, setOpenUserManagmentModule] = useState(false)

  const [user, setUser] = useState({})


  const [userDB, setUserDB] = useState({})

  const getFormData = async (id) => {

    setLoading(true)

    const formData = await dispatch(getFormId('userEdit'));
    if (formData) {
      if (formData.payload) {
        if (formData.payload.length !== 0) {



          setSelectedFormId(formData.payload[0].id)
          const formSchema = await dispatch(getFormSchema(formData.payload[0].id));
          const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));

          // if userId is exist then user data from `getUserDataById` 
          let userData = {};
          if (props?.userId) {
            userData = await dispatch(getUserDataById(props.userId));
          }
          // userId is does exist then user data from `getLogedInUserData using token` 
          else {
            userData = await dispatch(getLogedInUserData());
          }
          if (userData?.payload) {
            setUser(userData.payload.profile.userBit ? userData.payload.profile.userBit : {})
            setUserDB(userData.payload.profile.userDB ? userData.payload.profile.userDB : {})
          }
          if (gg.payload) {
            setGroupList(gg.payload)
          }
          if (formSchema.payload) {
            setScheemaComponent(formSchema.payload)
          }
        }
      }
    }

    setLoading(false)

  }

  const handleClose = () => {
    setOpenUserManagmentModule(false)
  }
  const editGroup = (id) => {

    setGroupSelectedList(groupList.filter((e) => e.groupId == id))
    setOpenUserManagmentModule(true)
  }

  const editGroupSubmit = async (data) => {
    loding(true)
    setUser((prevState) => {
      return ({
        ...prevState,
        ...data.bitrix.main
      });
    });

    setUserDB((prevState) => {
      return ({
        ...prevState,
        ...data.database.main
      });
    });


    let obj = {
      userId: user.ID,
      bitrix: {
        main: {
          ...user,
          ...data.bitrix.main
        }

      },
      database: {
        main: {
          ...userDB,
          ...data.database.main
        }
      }
    }



    try {

      let res = await dispatch(updateUserService(obj));

      if (res) {
        loding(false)
        handleClose()
      } else {
        loding(false)
        handleClose()
      }


    } catch (e) {

    }



  }

  const menu = (
    <Menu
      items={[
        {
          label: 'Submit and continue',
          key: '1',
        },
      ]}
    />
  );
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  useEffect(() => {

    getFormData(props?.userId)


  }, [props?.userId]);




  return (
    <>
      <Skeleton loading={loading} active>

        <Card
          className="card-profile-head"
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>

              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  <Avatar size={74} shape="square" src={user?.PERSONAL_PHOTO ? user.PERSONAL_PHOTO : profilavatar} />

                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{user?.NAME ? user.NAME : ''} {user?.LAST_NAME ? user.LAST_NAME : ''}</h4>

                    <p>{user?.WORK_POSITION ? user.WORK_POSITION : ''}</p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >


              </Col>
            </Row>
          }
        >


          <Card.Grid className="left-profile-content" hoverable={false} style={gridStyle}>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('Employee ID')}:
              </Col>
              <Col md={16} className="question-answer">
                {user?.UF_USR_1642854306150 ? user.UF_USR_1642854306150 : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('Active')}:
              </Col>
              <Col md={16} className="question-answer ">
                {user?.ACTIVE ? <span className="active-answer">Active</span> : <span className="deactive-answer">Deactive</span>}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('Date of Join')}:
              </Col>
              <Col md={16} className=" question-answer">

                {user?.DATE_REGISTER ? moment(user.DATE_REGISTER).format('MM-DD-YYYY') : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('User Type')}:
              </Col>
              <Col md={16} className="question-answer">
                {user?.USER_TYPE ? user.USER_TYPE : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('Work Position')}:
              </Col>
              <Col md={16} className="question-answer">
                {user?.WORK_POSITION ? user.WORK_POSITION : '-'}
              </Col>
            </Row>
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}
          >
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('Birthday')}:
              </Col>
              <Col md={16} className="question-answer">
                {user?.PERSONAL_BIRTHDAY ? moment(user.PERSONAL_BIRTHDAY).format('MM-DD-YYYY') : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name ">
                {t('Email')}:
              </Col>
              <Col md={16} className="question-answer mobile">
                {user?.EMAIL ? user.EMAIL : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name ">
                {t('Mobile')}:
              </Col>
              <Col md={16} className=" question-answer mobile">
                {user?.PERSONAL_MOBILE ? user.PERSONAL_MOBILE : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name">
                {t('City')}:
              </Col>
              <Col md={16} className="question-answer">
                {user?.PERSONAL_CITY ? user.PERSONAL_CITY : '-'}
              </Col>
            </Row>
            <Row gutter={[24, 0]}>

              <Col md={8} className="question-name ">
                {t('Personal Mobile')}:
              </Col>
              <Col md={16} className="question-answer mobile">
                {user?.PERSONAL_PHONE ? user.PERSONAL_PHONE : '-'}
              </Col>
            </Row>
          </Card.Grid>



        </Card>

      </Skeleton>

      <Row gutter={[24, 0]}>

        {
          loading ? <>

          </> : <>

            {groupList ? (
              <>
                {
                  groupList.map((row) => (
                    <Col span={24} md={8} className="mb-24">
                      <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">
                          {t(row.groupLable)}</h6>}
                        className="header-solid h-full card-profile-information"
                        extra={<Button onClick={() => editGroup(row.groupId)} type="link">{pencil}</Button>}
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                      >
                        <hr className="my-25" />
                        <Descriptions title="">
                          {

                            schemaComponent.filter((e) => e.group === row.groupId).map((element) => (

                              <Descriptions.Item label={t(element.lableTxt)} span={3}>
                                {
                                  element.fieldPlace === 'bitrix' ? (
                                    <>
                                      {
                                        loading ? (null) : (
                                          <ElementValue type={element.type} value={user[element.keyTxt] ? user[element.keyTxt] : '-'} />

                                        )
                                      }
                                    </>
                                  ) : null

                                }
                                {
                                  element.fieldPlace === 'database' ? (
                                    <>
                                      {
                                        loading ? (null) : (
                                          <ElementValue type='' value={userDB[element.keyTxt] ? userDB[element.keyTxt] : '-'} />

                                        )
                                      }


                                    </>
                                  ) : null

                                }
                              </Descriptions.Item>





                            )
                            )

                          }

                        </Descriptions>
                      </Card>
                    </Col>
                  ))
                }
              </>

            ) : (
              null
            )}

          </>
        }

      </Row>

      <Dialog

        open={openUserManageModel}
        onClose={handleClose}
        TransitionComponent={Transition}
        // className="user-edit---"
        sx = {{zIndex: "1300 !important"}}
      >
        <div className='full-screen-dialog-body' >
          <GroupUserEdit elementGroup={groupSelectedList} jsonSchema={schemaComponent} id={user} handleClose={handleClose} editGroupSubmit={(data) => editGroupSubmit(data)} />
        </div>


      </Dialog>
    </>
  );

}

export default Profile;
