import React, { useState, useEffect } from "react";
import { updateUserService } from "../../../redux/slices/users";
import {
    useDispatch
} from "react-redux";
import './style.css'
import { materialRenderers, materialCells, } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';




import GeoLocation from './custom/geoLocation/GeoLocation';
import FileControl from './custom/storage/FileControl';
import GeoCell from './custom/geoLocation/GeoCell';


import {
    rankWith, scopeEndsWith, uiTypeIs,
    schemaMatches,
    schemaTypeIs,
    and
} from '@jsonforms/core';





export function TestForm(props) {

    const dispatch = useDispatch();


    const renderers = [
        ...materialRenderers,
        //register custom renderers
        {
            tester: rankWith(
                3, //increase rank as needed
                scopeEndsWith('image')
            ), renderer: FileControl
        },
        {
            tester: rankWith(
                3, //increase rank as needed
                scopeEndsWith('aa')
            ), renderer: GeoLocation
        },
    ];

    const renderCells = [
        ...materialCells,
        {
            tester: rankWith(
                100, //increase rank as needed
                and(
                    uiTypeIs('Control'),
                    schemaTypeIs('number'),
                    schemaMatches(schema => {

                        if (schema.hasOwnProperty('customRender')) {
                            var cellschema = schema;
                            return cellschema['customRender'] === 'rating';
                        }
                        return false;
                    })
                )
            ), renderer: GeoCell
        },
    ];


    const [formSchema, setFormSchema] = useState({
        schema: {
            "type": "object",
            "properties": {
                "name": {
                    "type": "string",
                    "minLength": 3,
                    "description": "Please enter your name"
                },
                "vegetarian": {
                    "type": "boolean"
                },
                "birthDate": {
                    "type": "string",
                    "format": "date"
                },
                "nationality": {
                    "type": "string",
                    "enum": [
                        "DE",
                        "IT",
                        "JP",
                        "US",
                        "RU",
                        "Other"
                    ]
                },
                "personalData": {
                    "type": "object",
                    "properties": {
                        "age": {
                            "type": "integer",
                            "description": "Please enter your age."
                        },
                        "height": {
                            "type": "number"
                        },
                        "drivingSkill": {
                            "type": "number",
                            "maximum": 10,
                            "minimum": 1,
                            "default": 7
                        }
                    },
                    "required": [
                        "age",
                        "height"
                    ]
                },
                "occupation": {
                    "type": "string"
                },
                "postalCode": {
                    "type": "string",
                    "maxLength": 5
                }
            },
            "required": [
                "occupation",
                "nationality"
            ]
        }, uischema: {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "HorizontalLayout",
                    "elements": [
                        {
                            "type": "Control",
                            "scope": "#/properties/name"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/personalData/properties/age"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/birthDate"
                        }
                    ]
                },
                {
                    "type": "Label",
                    "text": "Additional Information"
                },
                {
                    "type": "HorizontalLayout",
                    "elements": [
                        {
                            "type": "Control",
                            "scope": "#/properties/personalData/properties/height"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/nationality"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/occupation",
                            "suggestion": [
                                "Accountant",
                                "Engineer",
                                "Freelancer",
                                "Journalism",
                                "Physician",
                                "Student",
                                "Teacher",
                                "Other"
                            ]
                        }
                    ]
                }
            ]
        },
        data: {

        }

    })



    useEffect(() => {





    }, [props]);

    const setData = (data, err) => {

        setFormSchema((prevState) => {
            return ({
                ...prevState,
                data: data
            });
        });
    }
    const editUserSubmit = async () => {


        return
        let userData = formSchema.data
        userData.id = props.id.ID

        try {

            let res = await dispatch(updateUserService(userData));

            if (res) {
                props.onSubmit(true)
            } else {
                props.onSubmit(false)
            }


        } catch (e) {

        }
    }


    return (
        <>
            <JsonForms
                schema={formSchema.schema}
                uischema={formSchema.uischema}
                data={formSchema.data}
                renderers={renderers}
                cells={renderCells}
                onChange={({ data, errors }) => {

                }}
            />

            <button onClick={editUserSubmit}>Submit</button>
        </>
    );
}