

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// or




const GeoLocation = (props) => {

    const [data, setData] = useState(null)

    const getGeo = async (data) => {
        
            return new Promise((resolve, reject) => {
                let goptions = {
                    enableHighAccuracy: true,
                    timeout: 9000,
                    maximumAge: 0,
                };
                function success(pos) {
                    var crd = pos.coords;

                    resolve(crd);
                    // if(props.data[props.schema.defaultArray.keyTxt]){
                    //     props.handleChange(props.path,  props.data[props.schema.defaultArray.keyTxt])
                    // }else{

                    const respo = {
                        accuracy: crd.accuracy,
                        latitude: crd.latitude,
                        longitude: crd.longitude,
                    }

                    props.handleChange(props.path, respo)
                    //}

                }

                function error(err) {
                    console.warn(`ERROR(${err.code}): ${err.message}`);
                    // alert(
                    //     "Error!: " + err.message + " \n " + uiText[uiText.lang].pageReload
                    // );
                    // location.reload();
                    reject(err);
                }
                // navigator.geolocation.getCurrentPosition(success, error, goptions);
            });
        


    };
    const updateData = async (e) => {

        // setValueData(props.data)
        props.handleChange(props.path, e)


    };

    useEffect(() => {
       
        if(props.data){
            setData(props.data)
        }else{
            getGeo(props)
        }
    
       
        //
    }, [props]);
    return (
        <></>
        // <div className='s'>
        //     <IconButton color="primary" aria-label="upload picture" component="label" onClick={getGeo}>

        //         <MyLocationIcon />
        //     </IconButton>
        // </div>
    );
}



export default withJsonFormsControlProps(GeoLocation);
