

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from 'antd';
import { init } from 'i18next';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';


import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";


const SingleSelect = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState([])
    const [enumList, setEnumList] = useState([])
    const [lableName, setLableName] = useState('')
    const [isLoading, loading] = useState(true)
    const [warning, setWarning] = useState(null)
    const [fullObj, setfullObj] = useState([])
    const [isLoadingEnumValue, setLoadngEnumValue] = useState(false)

    const [isHideInEdit, setHideInEdit] = useState(false)
    const updateData = async (e) => {
        // setValueData(props.data)
        if(e?.value){
            props.handleChange(props.path, e?.value)
        }else{
            props.handleChange(props.path,undefined)
        }



    };

    const errorList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.condition) {
                if (props?.schema?.schemaElement?.ConditionBase) {
                    const conitionBase = props?.schema?.schemaElement?.ConditionBase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })


    const filterList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.filter) {
                if (props?.schema?.schemaElement?.filterbase) {
                    const conitionBase = props?.schema?.schemaElement?.filterbase
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                }

            }

        }

        return error

    })

    const getConditionalData = async () => {
        if (props?.schema?.schemaElement?.ConditionBase) {
            let obj = {
                schama: props?.schema?.schemaElement ? props.schema.schemaElement : null,
                value: errorList
            }
            setLoadngEnumValue(true)
            if (errorList) {

                const p = await dispatch(getCOnditionalEnum(obj))
                let unum = p?.payload?.data?.data?.enum ? p.payload.data.data.enum : []
                setfullObj(p?.payload?.data?.data?.fullObject ? p.payload.data.data.fullObject : [])
                let opt = [
                    {
                        value: null,
                        label: 'select',
                    }
                ]
                for (const els of unum) {

                    opt.push({
                        value: els.const,
                        label: els.title,
                    })
                }
                setEnumList(opt)

                if (props.data) {


                    let lable = opt.find((e) => e.value == props.data)
                    let vat = {
                        value: props.data.toString(),
                        label: lable?.label ? lable.label : '-'
                    }
                    setValueData(vat)
                }
                // setEnumList(unum)
                setLoadngEnumValue(false)
            }

        }


    }


    const getFilterData = async () => {
        if (props?.schema?.schemaElement?.filterbase) {

            if (props?.schema?.schemaElement?.filterField) {
                promiseOptions()
            }
        }
    }

    useEffect(() => {
        getFilterData()
    }, [filterList]);

    useEffect(() => {
        getConditionalData()
    }, [errorList]);

    const init = async (e) => {
        loading(true)
        let opt = [ {
            value: null,
            label: 'select',
        }]
        setLableName(props.schema.schemaElement.lableTxt ? props.schema.schemaElement.lableTxt : '')
        //setValueData(props.data ? props.data : [])
        if (props?.schema?.schemaElement?.condition) {

            if (props.data) {


                let lable = enumList.find((e) => e.value == props.data)
                let vat = {
                    value: props.data.toString(),
                    label: lable?.label ? lable.label : '-'
                }
                setValueData(vat)
            }
        } else {




            if (props?.schema?.enumList) {

                for (const els of props.schema.enumList) {

                    opt.push({
                        value: els.const,
                        label: els.title,
                    })
                }

                setEnumList(opt)
                if (props.data) {

                     
                    let lable = opt.find((e) => e.value == props.data)
                    let vat = {
                        value: props.data,
                        label: lable?.label ? lable.label : '-'
                    }
                    setValueData(vat)
                }
                //setEnumList(props.schema.enumList)

            }
        }



        if (props?.schema?.schemaElement?.warning) {
            setWarning(props.schema.schemaElement.warning)

        }

        if (props?.uischema?.rule?.effect) {
            if (props.uischema.rule.effect === 'SHOW') {
                setHideInEdit(true)
            } else {
                setHideInEdit(false)
            }
        } else {
            setHideInEdit(false)
        }

        loading(false)

    };

    //

    const [device, setDevice] = useState(false);

    const customizeForDevice = () => {

        var ua = navigator.userAgent;
        var checker = {
            iphone: ua.match(/(iPhone|iPod|iPad)/),
            blackberry: ua.match(/BlackBerry/),
            android: ua.match(/Android/)
        };
        if (checker.android) {
            setDevice(false)
        } else if (checker.iphone) {
            setDevice(false)
        }
        else if (checker.blackberry) {
            setDevice(false)
        }
        else {
            setDevice(true)
        }

    }
    useEffect(() => {
        customizeForDevice()
        setValueData([])
        init()

        //
    }, [props]);

    const promiseOptions = (inputValue) =>
        new Promise(async (resolve) => {
            let obj = {
                schama: props?.schema?.schemaElement ? props.schema.schemaElement : null,
                value: errorList,
                search: inputValue,
                filter:filterList
            }
            // obj = {
            //     ...obj,
            //     schama:{
            //         ...obj.schama,
            //         "searchKeyList":["Store_Code", "Store_Name"]
            //     }
            // }
            const p = await dispatch(getCOnditionalEnum(obj))
            let unum = p?.payload?.data?.data?.enum ? p.payload.data.data.enum : []
            setfullObj(p?.payload?.data?.data?.fullObject ? p.payload.data.data.fullObject : [])
            let opt = [
                {
                    value: null,
                    label: 'select',
                }
            ]
            for (const els of unum) {

                opt.push({
                    value: els.const,
                    label: els.title,
                })
            }
            setEnumList(opt)
            resolve(opt)
        });
    return (
        <>
            {
                !isLoading ? <div className={isHideInEdit ? `hide-div` : `show-div`}>

                    <div style={{
                        width: '100%',
                        marginTop: '7px',

                    }}>
                       <span className='lable-drop'>{t(lableName)} </span> 
                        {
                            props?.required ? <span className='required-field'>*</span> : null
                        }</div>
                    {props?.schema?.schemaElement?.filter ?
                        <AsyncSelect cacheOptions loadOptions={promiseOptions} isClearable={true} isSearchable={device} isLoading={isLoadingEnumValue} pagination={true} isDisabled={isLoadingEnumValue} value={valueData} onChange={(e) => { updateData(e) }} defaultOptions={enumList} />
                        :
                        <Select isClearable={true} isSearchable={device} isLoading={isLoadingEnumValue} pagination={true} isDisabled={isLoadingEnumValue} value={valueData} onChange={(e) => { updateData(e) }} options={enumList} />
                    }
                       
                    {/* <Select isClearable={true} isSearchable={device} isLoading={isLoadingEnumValue} pagination={true} isDisabled={isLoadingEnumValue} value={valueData} onChange={(e) => { updateData(e) }} options={enumList} /> */}
                    {/* <Select
                        showSearch={enumList.length > 100 ? true : false}
                        
                         getPopupContainer={trigger => trigger.parentNode}
                        listHeight={150}
                        style={{
                            width: '100%',
                            marginBottom: '15px'
                        }}
                        placeholder={t(lableName)}
                        value={valueData}
                        onChange={(e) => updateData(e)}
                        disabled={props.schema.disable || isLoadingEnumValue ? true : false}
                    >


                        {enumList.map((row) => (
                            <Option key={row.const}>{row.title}</Option>
                        ))}



                    </Select> */}
                    {
                        warning ? <>
                            {
                                warning != '' ? <>
                                    <Alert
                                        message={warning}
                                        type="warning"
                                        closable
                                    />
                                </> : null
                            }

                        </> : null
                    }


                </div> : null
            }


        </>



    );
}



export default withJsonFormsControlProps(SingleSelect);
