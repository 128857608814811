import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SmileOutlined } from '@ant-design/icons';
import { Timeline, Checkbox } from 'antd';
import { Image } from 'antd';

export function ElementValue(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState(null)

    const [grids, setGrid] = useState([])
    const [data, setData] = useState(null)
    const [element, setElement] = useState([])
    const [extension, setExtension] = useState(null)
    const [gridRow, setGridRow] = useState([])
    const elementCreate = () => {


        const value = props.value
        if (props.type === 'date') {


            if (value) {
                if (value != '-') {
                    if (props?.element?.finish || props?.element?.start) {

                        const date = moment(value);
                        setValue(date.format("MMMM Do YYYY, hh:mm:ss"))
                    } else {
                        const date = moment(value);
                        setValue(date.format("MMMM Do YYYY, hh:mm:ss"))
                    }

                }
            }


        }
        else if (props.type === 'File' || props.type === 'html_camera') {



            // if (value?.location) {
            //      [0].split('.').pop().trim())
            //     setExtension(value.location.split(/[#?]/)[0].split('.').pop().trim())
            // }
            setValue(value)


        }
        else if (props.type === 'location') {


            setValue(JSON.stringify(value))
        }
        else if (props.type === 'data_grid') {

            setElement(props?.element?.arrayValue ? props.element.arrayValue : [])

            let arra = props?.element?.arrayValue ? props.element.arrayValue : []

            const allData = props?.allData ? props.allData : null

            const element = props?.element ? props.element : null
            let completearray = []
            if (element) {
                if (element.multiStage) {
                    for (const temArra of arra) {

                        const groupOfEl = temArra?.group_production?.selectedgroup ? temArra.group_production.selectedgroup : ''

                        const subgroupOfEl = temArra?.group_production?.selectedsubgroup ? `-${temArra.group_production.selectedsubgroup}` : ''

                        let keytext = `${element.keyTxt}-${groupOfEl}${subgroupOfEl}`


                        let dddd = allData[keytext] ? allData[keytext] : null

                        let subarr = []
                        if (dddd) {
                            for (const valarr of dddd) {
                                let c = valarr.count ? valarr.count : 0;
                                let ins = valarr.img ? valarr.img : null;
                                let valss = valarr.value ? valarr.value : null;

                                subarr.push({
                                    count: c,
                                    img: ins,
                                    value: valss
                                })
                                completearray.push({
                                    count: c,
                                    img: ins,
                                    value: valss
                                })
                            }
                        } else {
                            if (temArra?.group_production?.value) {
                                for (const valarr of temArra.group_production.value) {
                                    let c = valarr.count ? valarr.count : 0;
                                    let ins = valarr.img ? valarr.img : null;
                                    let valss = valarr.value ? valarr.value : null;

                                    subarr.push({
                                        count: c,
                                        img: ins,
                                        value: valss
                                    })
                                    completearray.push({
                                        count: c,
                                        img: ins,
                                        value: valss
                                    })
                                }
                            }

                        }


                        //allData[keytext]

                    }
                }
            }
            setGridRow(completearray)

            setGrid(value && value != '-' ? value : [])
        }
        else if (props.type === 'timeLine') {

            setData(value && value != '-' ? value : null)

        }
        else if (props.type === 'dropTree') {

            setData(value)

        }

        else {

            setValue(t(value))
        }

    }



    useEffect(() => {

        elementCreate();
        console.log("Object.keys", props)

    }, []);


    const editUserSubmit = () => {

    }

    return (
        <>
            <div>
                {
                    props.type === 'File' || props.type === 'html_camera' ? <>
                        {
                            <>
                                {
                                    value && Array.isArray(value) ?

                                        <>
                                            <Image.PreviewGroup>
                                                {value.map((row) => (
                                                    row?.location ?

                                                        < Image style={{ marginRight: 2 }} width={50} src={row.location} />


                                                        : null
                                                ))}
                                            </Image.PreviewGroup>
                                        </>
                                        :
                                        value && typeof value === "object" && !value.location ?
                                            <>
                                                <Image.PreviewGroup>

                                                    {Object.keys(value).map((key) => (
                                                        value[key]?.location ?
                                                            < Image style={{ marginRight: 2 }} width={50} src={value[key]?.location} />
                                                            : null
                                                    ))
                                                    }

                                                </Image.PreviewGroup>
                                            </>

                                            :
                                            value?.location ?
                                                <>
                                                    {
                                                        value.location.split(/[#?]/)[0].split('.').pop().trim() == 'JPEG' || value.location.split(/[#?]/)[0].split('.').pop().trim() == 'jpeg' || value.location.split(/[#?]/)[0].split('.').pop().trim() == 'png' || value.location.split(/[#?]/)[0].split('.').pop().trim() == 'PNG' ? <>
                                                            <Image.PreviewGroup>
                                                                <Image
                                                                    width={50}
                                                                    src={value.location}
                                                                />
                                                            </Image.PreviewGroup>

                                                        </> : <>
                                                            <a href={value.location} target="_blank">download attachment</a>
                                                        </>

                                                    }

                                                </>
                                                :
                                                <>-</>

                                }
                            </>

                        }


                    </>
                        : props.type === 'data_grid' ? <div className="data-rgid-main">
                            <table>
                                {

                                }
                                <tr>
                                    <th>Lable</th>
                                    <th>Count</th>
                                    <th>Image</th>
                                </tr>



                                {
                                    gridRow.map((gridRowte, index) => (
                                        <tr>
                                            <td>{gridRowte.value}</td>
                                            <td>{gridRowte.count}</td>
                                            <td>{gridRowte?.img?.location ?
                                                <>
                                                    {
                                                        gridRowte?.img?.location ? <> < Image style={{ marginRight: 2 }} width={50} src={gridRowte.img.location} /> </> : <>-</>
                                                    }

                                                </> : <>-</>
                                            }</td>
                                        </tr>
                                    ))
                                }
                            </table>

                        </div> : (props.type === 'dropTree') ? <>
                            <table>
                                <tr>
                                    <th>product group</th>
                                    <th>items</th>
                                </tr>

                                <tr style={{ textAlign: 'left' }}>
                                    <td>{data?.selectedgroup ? data.selectedgroup : '-'}</td>
                                    <td>{data?.value ? data.value.toString() : '-'}</td>
                                </tr>



                            </table>

                        </> : props.type === 'timeLine' ? <>
                            {
                                data ?
                                    <Timeline style={{ marginTop: 15 }}>
                                        {
                                            data?.start?.dateTime ?
                                                <Timeline.Item color="green">
                                                    <p>Task start at {data?.start?.dateTime ? data.start.dateTime : '-'}</p>
                                                    {
                                                        data?.start?.isLocationEnable?.accuracy ?
                                                            <>
                                                                <p>Task start From, Accuracy {data?.start?.isLocationEnable?.accuracy ? data.start.isLocationEnable.accuracy : '-'}  latitude {data?.start?.isLocationEnable?.latitude ? data.start.isLocationEnable.latitude : '-'}  longitude {data?.start?.isLocationEnable?.longitude ? data.start.isLocationEnable.longitude : '-'}</p>
                                                            </>

                                                            : null
                                                    }


                                                </Timeline.Item> : null
                                        }

                                        {data.paused.map((row) => (
                                            <Timeline.Item color="gray">
                                                <p>Task Hold</p>
                                                {
                                                    row.pauseTime ?
                                                        <p>Task Paused at {row.pauseTime}</p> : null

                                                }
                                                {
                                                    row.pouseLocation ?
                                                        <p>Task Paused from, Accuracy {row.pouseLocation.accuracy ? row.pouseLocation.accuracy : '-'}  latitude {row.pouseLocation.latitude ? row.pouseLocation.latitude : '-'}  longitude {row.pouseLocation.longitude ? row.pouseLocation.longitude : '-'}</p> : null

                                                }

                                                {
                                                    row.resumeTime ?
                                                        <p>Task Resume at {row.resumeTime}</p> : null

                                                }
                                                {
                                                    row.resumeLocation ?
                                                        <p>Task Resume from, Accuracy {row.resumeLocation.accuracy ? row.resumeLocation.accuracy : '-'}  latitude {row.resumeLocation.latitude ? row.resumeLocation.latitude : '-'}  longitude {row.resumeLocation.longitude ? row.resumeLocation.longitude : '-'}</p> : null

                                                }
                                                {
                                                    row.resumeDiff ?
                                                        <p>Task Hold time (sec.) {row.resumeDiff}</p> : null

                                                }



                                            </Timeline.Item>
                                        ))}


                                        {
                                            data?.finishd?.dateTime ?
                                                <Timeline.Item color="green">
                                                    <p>Task Finished at {data?.finishd?.dateTime ? data.finishd.dateTime : '-'}</p>
                                                    {
                                                        data?.finishd?.isLocationEnable?.accuracy ?
                                                            <>
                                                                <p>Task Finished From, Accuracy {data?.finishd?.isLocationEnable?.accuracy ? data.finishd.isLocationEnable.accuracy : '-'}  latitude {data?.finishd?.isLocationEnable?.latitude ? data.finishd.isLocationEnable.latitude : '-'}  longitude {data?.finishd?.isLocationEnable?.longitude ? data.finishd.isLocationEnable.longitude : '-'}</p>
                                                            </>

                                                            : null
                                                    }


                                                </Timeline.Item> : null
                                        }

                                    </Timeline> : null
                            }

                        </> :
                            <>

                                {value}
                            </>

                }
            </div>


        </>
    );
}