import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getFetchUsers } from "../../../redux/slices/users";
import {
  addFormElementToServer,
  getFormSchema,
  getAllElementKeyReducer,
  addValueToKeyElementReducer,
  deleteFormElementFromServer,
  deleteFormGroupFromServer,
  getAllGroupRaducer,
  addGroupRaducer,
  changeOrderReducer,
  changeOrderElementReducer,
  addAsShowUserReducer, loadParentFormsReducer, changeAsParentFormReducer, changeDisableElementReducer, connectToSystemUserReducer, changeSchemaValueReducer, changeGroupValueReducer,
  saveFlowReducer, getFlowReducer, getAllDatabaseUserReducer, changeRejectionMethodReducer, getFormDetails, getAllFormsReducer, saveSelectedProccesingFormReducer, getFormAnswerListExportReducer, saveSelectedTaskProccesingFormReducer, getTaskProcessingFormReducer
} from "../../../redux/slices/formSettings";
import { useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import "./style.css";
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ReviewForm } from "./ReviewForm";
import { TestForm } from "./TestForm";
import Skeleton from "@mui/material/Skeleton";
import { JsonForms } from '@jsonforms/react';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  CircularProgress,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";

import CalculationArray from './custom/calculation/CalculationArray';

import { InputNumber } from 'antd';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";

import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { spacing } from "@mui/system";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";


import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

import SchemaElementEdit from "./compoment/SchemaElementEdit";
import SchemaElementAddEdit from "./compoment/SchemaElementAddEdit"

import EmailTemplate from "./compoment/EmailTemplate";


import {
  ExportOutlined,
  SearchOutlined,
  ImportOutlined,
  FileAddOutlined,
  SettingTwoTone,
  DeleteOutlined,
  EditOutlined,
  FontColorsOutlined,
  CalculatorOutlined,
  DownCircleOutlined,
  CalendarOutlined,
  ProfileOutlined,
  AimOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Button as AntButton,
  Select as AntSelect,
  Dropdown,
  Menu,
  notification,
  Input,
  Modal,
  Space, Checkbox as AntCheckBox, Form
} from "antd";
import FormElement from "./FormElements";

const { Option } = AntSelect;

const UserSetting = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const acceptedFileType = [
    {
      const:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      lable: "EXCEL",
    },
    {
      const: "image/*",
      lable: "image",
    },
    {
      const: "video/*",
      lable: "video",
    },
    {
      const: "audio/*",
      lable: "audio",
    },
    {
      const: ".pdf",
      lable: "PDF",
    },
  ];

  const { t } = useTranslation();
  const history = useHistory();
  const Divider = styled(MuiDivider)(spacing);

  const [elementOption, setElementOption] = React.useState({
    lableTxt: null,
    keyTxt: null,
  });

  const [open, setOpen] = React.useState(false);
  const [openManageElementKey, setOpenManagementElementKey] =
    React.useState(false);

  const [selectedTextType, changeSelectedType] = React.useState(null);

  const [dropDownValue, setDropDownVal] = React.useState([]);
  const [selectedFormId, setSelectedFormId] = React.useState(null);

  const [valueDropDown, addValueDropDown] = React.useState(null);
  const [valueDropDownConst, addValueDropDownConst] = React.useState(null);
  const [schemaComponent, setScheemaComponent] = React.useState([]);

  const [openAddFormEliment, openFormEliment] = React.useState(false);

  const [elementKeyList, manageElementKeyList] = React.useState([]);
  const [availableElementKeyList, manageAvailableElementKeyList] =
    React.useState([]);
  const [keyElementValueTxt, setKeyElementValueTxt] = React.useState("");

  const [fileStoreType, changeFileStoreType] = React.useState("s3");
  const [s3Bucket, changeS3Bucket] = React.useState("");
  const [s3Dir, changeS3Dir] = React.useState("");

  const [groupNametxt, setGroupNameTxt] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [groupColtxt, setGroupColtxt] = useState("");

  const [groupLabletxt, setGroupLabletxt] = useState("");
  const [groupTypetxt, setGroupTypetxt] = useState("object");

  const [groupList, setGroupList] = useState([]);
  const [elementByGroup, setElementByGroup] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("main");

  const [savingType, setSavingType] = useState("database");
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  const [textFormModel, openTextFormModel] = useState(false);

  const [selectedFileType, setSelectedFileType] = React.useState([]);

  const [fileSource, setFileSource] = React.useState("local");

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [arrayType, setArrayType] = useState(null);

  const [isLoadingElement, loadingElement] = useState(false);

  const [reference, setReference] = useState(false);

  const [referenceKey, setReferenceKey] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [isLoadForm, setIsLoadForm] = useState(false)

  const [elementLength, SetElementLength] = React.useState({
    minChar: null,
    maxChar: null
  })

//enable geo location
const [isGeoLocation, setIsGeoLocation] = React.useState(false)
const [geoLocationForm, setgeoLocationForm] = React.useState(null)

  //add-edit
  const [isAddElement, setIsAddElement] = useState(false)
  const [isEditElement, setIEditElement] = useState(false)

  // email template start

  const [openEmailTemplate, setemailTemplateOpen] = useState(false)

  const openEmailTemplateFunction = () => {
    setemailTemplateOpen(true)
  };


  const closeEmailTempleteFunction = () => {
    setemailTemplateOpen(false)
  };

  //email template end

  const [targetValue, setTargetValue] = useState(null)

  const modifyFileType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFileType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // component state
  const [EmployeeGroupList, setEmployeeGroupList] = useState([])

  const getFormAnswerList = async () => {
    const data = {
      formData: '67e28a0a-2ad9-46fa-b611-8cacdfc5d698',
      forExport: true
    };

    const res = await dispatch(getFormAnswerListExportReducer(data));
    if (res?.payload?.data?.data) {
      setEmployeeGroupList(res.payload.data.data)
    }


  }

  const [lableShowingElement, setShowingElement] = useState([])

  const changeViewElement = (e) => {
    setShowingElement(e)
  }

  const [timmerMin, setTimmerMin] = useState(null)


  const onChangeTimmer = (e) => {
    setTimmerMin(e)
  }


  const [parentFocusValue, setParentFocue] = useState(null)


  const parentFocus = (e) => {
    setParentFocue(e)
  }

  const [elementManageFormType, setElementManageFormType] = useState("ADD");
  //const [elementKeyListEdit, manageElementKeyListEdit] = React.useState([]);

  const referenceChacked = () => {
    setReference(!reference);
  };

  const handleCloseTestForm = () => {
    openTextFormModel(false);
  };
  const handleOpenTestForm = () => {
    openTextFormModel(true);
  };

  const handleCloseManageElementKey = () => {
    setOpenManagementElementKey(false);
  };
  const handleOpenManageElementKey = () => {
    setOpenManagementElementKey(true);
  };
  const [elemtnDeletePromt, setElemtnDeletePromt] = useState(false);
  const [selectedDeletElementData, setSelectedDeletElementData] =
    useState(null);

  const [isOpenEditSchema, setEditSchema] = useState(false);

  const [selectedEditData, setSelectedEditData] = useState({});

  const openEditSchema = (e) => {
    setSelectedEditData(e);
    // setEditSchema(true);
    setIsAddElement(false)
    setIEditElement(true)
  };
  // close add edit
  const closeEditSchema = () => {
    // setEditSchema(false);
    setIEditElement(false)
  };
  const closeAddSchema = () => {
    addSuccess()
    clearFormFunction();
    setIsAddElement(false);
  }

  const deleteFormComponent = (data) => {
    setSelectedDeletElementData(data);

    setElemtnDeletePromt(true);
  };
  const deleteElementConfirmed = async () => {
    let payload = {
      formId: selectedFormId,
      schemaKey: selectedDeletElementData.keyTxt,
    };

    await dispatch(deleteFormElementFromServer(payload));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }
    setElemtnDeletePromt(false);
  };

  const [groupDeletePromt, setGroupDeletePromt] = useState(false);
  const [selectedDeletGroupData, setSelectedDeletGroupData] = useState(null);

  const deleteFormGroup = (data) => {
    setSelectedDeletGroupData(data);

    setGroupDeletePromt(true);
  };
  const deleteGroupConfirmed = async () => {
    let payload = {
      formId: selectedFormId,
      groupId: selectedDeletGroupData.groupId,
    };

    await dispatch(deleteFormGroupFromServer(payload));
    getAllGroup();
    setElemtnDeletePromt(false);
  };

  const editFormComponent = (data) => {
    setElementManageFormType("EDIT");

    let elKeyArray = elementKeyList;
    let elArray = schemaComponent;

    if (elArray.length === 0) {
      manageAvailableElementKeyList(elKeyArray);
    } else {
      if (elKeyArray.length !== 0) {
        let tempArray = [];
        for (const element of elKeyArray) {
          if (!elArray.find((x) => x.keyTxt === element.keyName)) {
            tempArray.push(element);
          }
        }
        manageAvailableElementKeyList(tempArray);
        // let tempArra=[]
      } else {
        manageAvailableElementKeyList([]);
      }
    }

    changeSelectedType(data.type);
    setDropDownVal([]);
    if (data.enum) {
      setDropDownVal(data.enum);
    }

    addValueDropDown("");

    addValueDropDownConst("");
    setElementOption({
      lableTxt: data.lableTxt,
      keyTxt: data.keyTxt,
      id: data.keyTxt,
      formId: data.formId,
    });

    openFormEliment(true);
  };

  const addValueToGroup = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    let obj = {
      formId: myParam,
      groupName: groupNametxt,
      groupLable: groupLabletxt,
      groupType: groupTypetxt,
      groupColtxt: groupColtxt,
    };

    if (groupTypetxt === "stage") {
      obj["selectedCategory"] = selectedCategory;
    }

    if (groupTypetxt === "array") {
      obj["selectedArrayType"] = arrayType;
    }
    const res = await dispatch(addGroupRaducer(obj));

    if (res) {
      getAllGroup();
    }
  };

  const getAllGroup = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const res = await dispatch(getAllGroupRaducer(myParam));

    if (res.payload) {
      setGroupList(res.payload);
    }
  };

  const addValueToKeyElement = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const obj = {
      formId: myParam,
      keyName: keyElementValueTxt,
    };

    const res = await dispatch(addValueToKeyElementReducer(obj));
    if (res) {
      const result = await dispatch(getAllElementKeyReducer(myParam));

      setKeyElementValueTxt("");
      manageElementKeyList(result.payload);
    }
  };

  const removeValueToKeyElement = (e) => {
    let array = elementKeyList;
    array = array.filter((x) => x !== e);
    manageElementKeyList(array);
  };

  const clearFormFunction = () => {
    changeSelectedType("");
    setDropDownVal([]);
    addValueDropDown("");

    addValueDropDownConst("");
    setElementOption({
      lableTxt: null,
      keyTxt: null,
      id: null,
    });
    addValueDropDown("");
    setCalculationArray(null)
    setTargetValue(null)
    addValueDropDownConst(null)
    addValueDropDown(null)
    setParentFocue(null)
    setStatiChar(null)
    setRandomNumberLength(0)
    changeFileStoreType("s3")
    changeS3Bucket("")
    changeS3Dir("")
    setSelectedFileType([])
    setFileSource("local")
    setselectedParentFormSchemaId(null)
    setDateEEnable(null)
    setLocationEnable(null)
    setResumeEnable(null)
    setReference(false)
    setReferenceKey(null)
    addValueDropDownConst("");
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFormElimepOpen = () => {
    let elKeyArray = elementKeyList;
    let elArray = schemaComponent;

    if (elArray.length === 0) {
      manageAvailableElementKeyList(elKeyArray);
    } else {
      if (elKeyArray.length !== 0) {
        let tempArray = [];
        for (const element of elKeyArray) {
          if (!elArray.find((x) => x.keyTxt === element.keyName)) {
            tempArray.push(element);
          }
        }
        manageAvailableElementKeyList(tempArray);
        // let tempArra=[]
      } else {
        manageAvailableElementKeyList([]);
      }
    }

    clearFormFunction();
    // openFormEliment(true);
    setIEditElement(false)
    setIsAddElement(true);
  };

  const handleFormELimentCloss = () => {
    openFormEliment(false);
    clearFormFunction();
  };

  const [person, setPerson] = useState({
    schema: {
      properties: {
        name: {
          type: "string",
        },
      },
    },
    uischema: {
      type: "Control",
      scope: "#/properties/name",
    },
    data: {},
  });

  const dispatch = useDispatch();

  const schema = person.schema;
  const uischema = person.uischema;
  const initialData = person.data;

  const getFormSchimaToDom = async (e) => {
    loadingElement(true);
    const res = await dispatch(getFormSchema(e));

    setScheemaComponent(res.payload);
    changeGroup(selectedGroupId ? selectedGroupId : "main", res.payload);

    loadingElement(false);
  };

  const getAllKeyElement = async (e) => {
    let tempFilters = {
      nameFilter,
      lastNameFilter,
      emailFilter,
      cityFilter,
      page,
    };

    const res = await dispatch(getFetchUsers(tempFilters));

    let keyArray = res.payload.result[0];
    const propertyNames = Object.keys(keyArray);

    let tempObj = [];

    for (const el of propertyNames) {
      tempObj.push({ keyName: el });
    }
    manageElementKeyList(tempObj);
  };
  const changeDisplayValue = (e) => {


    setElementOption((prevState) => {
      return {
        ...prevState,
        lableTxt: e.target.value,
        keyTxt: e.target.value.split(' ').join('_'),
      };
    });
  };


  //indexing
  const [indexRuleType, setIndexRuleType] = useState(null)

  const [randomNumberLength, setRandomNumberLength] = useState(0)
  const [dateType, setDateType] = useState(null)
  const [staticChar, setStatiChar] = useState(null)
  const [ruleArray, setRuleArray] = useState([])
  const changeIndexRuleType = (e) => {

    setIndexRuleType(e)
  }

  const addRuleFunction = () => {
    let obj = {
      type: '',
      value: ''
    }
    if (!indexRuleType) {
      alert('Type is Required')
      return
    }

    if (indexRuleType === 'date') {
      if (!dateType) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: dateType
      }
    }

    if (indexRuleType === 'static') {
      if (!staticChar) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: staticChar
      }
    }

    if (indexRuleType === 'random') {
      if (!randomNumberLength) {
        alert('date is Required')
        return
      }
      obj = {
        ...obj,
        type: indexRuleType,
        value: randomNumberLength
      }
    }

    setRuleArray(oldArray => [...oldArray, obj]);

  }


  //data grid

  const [parentFormList, setParentFormList] = useState([])
  const [selectedParentFormList, setselectedParentFormList] = useState([])
  const [selectedParentFormListId, setselectedParentFormListId] = useState(null)

  const [calucaluationArray, setCalculationArray] = useState(null)
  const [calucaluationArrayObj, setCalculationArrayObj] = useState([])
  const [selectedParentGroupeList, setSelectedParentGrouplist] = useState([])
  const [selectedParentFormSchemaId, setselectedParentFormSchemaId] = useState(null)
  const loadParentForms = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    let parentForms = await dispatch(loadParentFormsReducer(myParam))
    setParentFormList(parentForms.payload ? parentForms.payload : [])


  }

  const ChangeSelectedParentForm = (id) => {

    const formTemp = parentFormList.find((e) => e.id === id)

    setselectedParentFormListId(id)
    setSelectedParentGrouplist(formTemp.groupe)

    setselectedParentFormList(formTemp.schema)

  }



  //end of data grid

  const [defaultLableValue, changeLableDefaula] = useState(null)
  const [dateInputType, setDateInputType] = useState(null)

  // timeLine

  const [isLocationEnable, setLocationEnable] = useState(null)
  const [isDateEnable, setDateEEnable] = useState(null)
  const [isResumeEnable, setResumeEnable] = useState(null)

  const changeTimeLineData = (e, lable) => {


    if (lable === 'Date') {
      setDateEEnable(e.target.checked)
    }
    if (lable === 'Location') {
      setLocationEnable(e.target.checked)
    }
    if (lable === 'resume') {
      setResumeEnable(e.target.checked)
    }

  }

  const addElementToSchema = async () => {
    let object = elementOption;

    if (schemaComponent.find((e) => e.keyTxt === object.keyTxt)) {
      alert("Cant use same element key for the multiple elements!");
      return;
    }
    
    if(elementLength.minChar && elementLength.maxChar && Number(elementLength.minChar) >= Number(elementLength.maxChar)) {
      alert("Min length should be less then Max length");
      return;
    }
    object.type = selectedTextType;
    object.fieldPlace = savingType ? savingType : "bitrix";

    object.reference = reference ? reference : false;
    object.referenceKey = reference ? referenceKey : null;
    object.minChar = elementLength.minChar
    object.maxChar = elementLength.maxChar


    if (reference === true && !referenceKey) {
      alert("Please select reference Key!");
      return;
    }

    if (!object.type) {
      alert("Please select element type!");
      return;
    }
    //let array = schemaComponent

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0) {
        alert("Selected element should have at least one Enum values!");
        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === "date") {
      object.format = "date";
      object.inputType = dateInputType;
    }

    if (selectedTextType === "location") {
      object.getType = "onClick";
    }

    if (selectedTextType === "timeLine") {
      object.isLocationEnable = isLocationEnable;
      object.isDateEnable = isDateEnable;
      object.isResumeEnable = isResumeEnable;

    }



    if (selectedTextType === "File" || 'html_camera' || selectedTextType === "cloacked") {
      object.fileStoreType = fileStoreType;
      object.s3Bucket = s3Bucket;
      object.s3Dir = s3Dir;
      object.selectedFileType = selectedFileType;
      object.captureType = fileSource;
    }

    if (selectedTextType === "answer_view") {
      object.showingElements = lableShowingElement
    }

    if (selectedTextType === "formTimeOut") {
      object.showingElements = timmerMin
    }


    if (selectedTextType === "autofill") {
      object.parentFocusValue = parentFocusValue
    }


    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert("Selected element should have at least one Enum values!");

        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === 'total' || selectedTextType === 'clearing') {

      if (!targetValue) {
        alert()
        return
      }

      const gId = object.group
      const groupobj = groupList.find((e) => e.groupId == gId)
      if (groupobj.groupType != 'array') {
        alert('cant procees this action with this gorup')
        return
      }
      object.targetValue = targetValue;

    }

    if (selectedTextType === 'index') {

      if (ruleArray.length == 0) {
        alert('rules empty')
        return
      }

      object = {
        ...object,
        group: null,
        ruleArray: ruleArray
      }

    }

    if (selectedTextType === 'calculation') {
      object.operation = calucaluationArrayObj;
    }

    if (selectedTextType === 'data_grid' || selectedTextType === 'lable_draft') {

      object = {
        ...object,
        selectedParentFormListId: selectedParentFormListId,
        selectedParentFormSchemaId: selectedParentFormSchemaId,
        defaultLableValue: defaultLableValue ? defaultLableValue : null
      }

    }
    if (selectedTextType === 'Form') {

      object = {
        ...object
      }

    }

    object.formId = selectedFormId;

    await dispatch(addFormElementToServer(object));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

    setElementOption({
      lableTxt: null,
      keyTxt: null,
    });

    // changeGroup(selectedGroupId)
    clearFormFunction()
    setSavingType("database");
    setIsMultiSelect(false);

    setShowingElement([])
    // setScheemaComponent(array)
    // handleFormELimentCloss();
    closeAddSchema()

  };

  const addSuccess = async () => {
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

  }

  const editSuccess = async () => {
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

    setSavingType("database");
    setIsMultiSelect(false);

    // setScheemaComponent(array)
    closeEditSchema();
  };

  const editElementToSchema = async () => {
    let object = elementOption;

    if (schemaComponent.find((e) => e.keyTxt === object.keyTxt)) {
      alert("Cant use same element key for the multiple elements!");

      return;
    }
    object.type = selectedTextType;

    //let array = schemaComponent

    if (selectedTextType === "drop" || selectedTextType === "radio" || selectedTextType === "dropTree" || selectedTextType === "dynamicContent") {
      if (dropDownValue.length === 0) {
        alert("Selected element should have at least one Enum values!");

        return;
      }
      object.enum = dropDownValue;
    }
    if (selectedTextType === "date") {
      object.format = "date";
    }

    if (selectedTextType === "check") {
      if (dropDownValue.length === 0) {
        alert("Selected element should have at least one Enum values!");

        return;
      }
      object.enum = dropDownValue;
    }

    object.formId = selectedFormId;

    await dispatch(addFormElementToServer(object));
    const res = await dispatch(getFormSchema(selectedFormId));
    setScheemaComponent(res.payload);

    let tempSchemaComponent = [];
    if (selectedGroupId === "main") {
      tempSchemaComponent = res.payload.filter((e) => e.group === null);
      setElementByGroup(tempSchemaComponent);
    } else {
      tempSchemaComponent = res.payload.filter(
        (e) => e.group === selectedGroupId
      );
      setElementByGroup(tempSchemaComponent);
    }

    // changeGroup(selectedGroupId)

    // setScheemaComponent(array)
    // handleFormELimentCloss();
    closeAddSchema()
  };

  const changeOrderFunction = async (id, direction) => {
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };
    if (direction === "up") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex - 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = groupList.findIndex((x) => x.groupId === id);
      selectObj = {
        id: id,
        currentVal: groupList[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: groupList[selectedObjIndex + 1].groupId,
        currentVal: groupList[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderReducer(payloadOb));
    if (result) {
      getAllGroup();
    }
  };

  const setAsShowUser = async (value, key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam
    }
    await dispatch(addAsShowUserReducer(obj))
    getFormSchimaToDom(myParam);
  }


  const setIsRequeired = async (value, key, keyWord) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam,
      keyWord: keyWord
    }
    await dispatch(changeSchemaValueReducer(obj))
    getFormSchimaToDom(myParam);
  }

  const changeGroupConfigaration = async (value, key, keyWord) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam,
      keyWord: keyWord
    }
    await dispatch(changeGroupValueReducer(obj))
    //getFormSchimaToDom(myParam);
  }




  const changeAsParentForm = async (value, key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam
    }
    await dispatch(changeAsParentFormReducer(obj))
    getFormSchimaToDom(myParam);
  }
  const changeDisableElement = async (value, key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam
    }
    await dispatch(changeDisableElementReducer(obj))
    getFormSchimaToDom(myParam);
  }

  const connectToSystemUser = async (value, key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");


    let obj = {
      value: value.target.checked,
      key: key,
      formId: myParam
    }
    await dispatch(connectToSystemUserReducer(obj))
    getFormSchimaToDom(myParam);
  }




  const changeOrderFunctionElement = async (id, direction) => {
    loadingElement(true);
    let selectObj = {
      id: id,
      currentVal: null,
    };

    let moveObj = {
      id: null,
      currentVal: null,
    };

    if (direction === "up") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex - 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex - 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    if (direction === "down") {
      const selectedObjIndex = elementByGroup.findIndex((x) => x.keyTxt === id);
      selectObj = {
        id: id,
        currentVal: elementByGroup[selectedObjIndex + 1].orderNumber,
      };
      moveObj = {
        id: elementByGroup[selectedObjIndex + 1].keyTxt,
        currentVal: elementByGroup[selectedObjIndex].orderNumber,
      };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const payloadOb = {
      formId: myParam,
      select: selectObj,
      move: moveObj,
    };

    const result = await dispatch(changeOrderElementReducer(payloadOb));

    // getAllKeyElement(myParam)
    // setSelectedFormId(myParam)
    getFormSchimaToDom(myParam);

    loadingElement(false);
  };

  const handleMenuClick = (e) => {
    const action = e.key;
    const id = e.item.props.items.id;
    switch (action) {
      case "1":
        deleteFormComponent(id);
        break;
      case "2":

        openEditSchema(id);
        break;
      default:
        return;
    }
  };

  const changeGroup = (id, data = false) => {
    let tempSchemaComponent = [];

    if (data) {
      if (id === "main") {
        tempSchemaComponent = data.filter((e) => e.group === null);

        setElementByGroup(tempSchemaComponent);
      } else {
        tempSchemaComponent = data.filter((e) => e.group === id);

        setElementByGroup(tempSchemaComponent);
      }
    } else {
      if (id === "main") {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === null);

        setElementByGroup(tempSchemaComponent);
      } else {
        tempSchemaComponent = schemaComponent.filter((e) => e.group === id);

        setElementByGroup(tempSchemaComponent);
      }
    }

    setSelectedGroupId(id);
  };

  const pushValueToDropDown = () => {
    let array = dropDownValue;
    let obj = {
      const: "",
      title: "",
    };

    if (array.find((e) => e.const === valueDropDownConst)) {
      alert("Cant use same key for the multiple Enums!");

      return;
    }

    obj.const = valueDropDownConst;
    obj.title = valueDropDown;

    array.push(obj);
    addValueDropDown("");
    addValueDropDownConst("");
    setDropDownVal(array);
  };

  const removeDropDownValus = (item) => {
    if (dropDownValue) {
      setDropDownVal(dropDownValue.filter((post) => post !== item));
    }

    //setDropDownVal(array)
  };


  // form configuration
  const [openFormConfig, setOpenFormConfig] = useState(false)
  const [openFormSetting, setOpenFormSetting] = useState(false)
  const [allAvailableForm, setAvailableForm] = useState([])

  const [selectedProccesingForm, setSelectedProccesingForm] = useState(null)
  const [selectedTaskProcessingForm, setSelectedTaskProcessingForm] = useState(null)

  const formConfigCloss = () => {
    setOpenFormConfig(false)
  }

  const formSettingClose = () => {
    setOpenFormSetting(false)
    setIsGeoLocation(false)
    setgeoLocationForm(null)
  }

  const setFormProccess = async (e) => {

    setSelectedProccesingForm(e)
  }

  const setFormTaskProcess = async (e) => {
    setSelectedTaskProcessingForm(e)
  }


  const saveSelectedProccesingForm = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const obj = {
      id: myParam,
      key: selectedProccesingForm
    }

    await dispatch(saveSelectedProccesingFormReducer(obj))
  }

  const saveSelectedTaskProccesingForm = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const obj = {
      main_form_id: myParam,
      task_processing_forms: selectedTaskProcessingForm,
      enableGeo: isGeoLocation,
      geoFormId: geoLocationForm,
    }

    try {
      await dispatch(saveSelectedTaskProccesingFormReducer(obj))

      notification.success({
        message: t("Success"),
        description: t("processing form added successfully"),
      });
      formSettingClose()
    }
    catch (error) {
      notification.error({
        message: t("Error"),
        description: t(error),
      });
    }

  }

  const formConfigOpen = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const res = await dispatch(getFlowReducer(myParam))


    const form = await dispatch(getFormDetails(myParam))
    const array = await dispatch(getAllFormsReducer(true))
    setAvailableForm(array.payload.respons.data)

    setSelectedProccesingForm(form.payload.proccesingForm)


    setOpenFormConfig(true)
  }

  const formSettingOpen = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const obj = {
      main_form_id: myParam,
    }
    const res = await dispatch(getFlowReducer(myParam))


    // const array = await dispatch(getFormDetails(myParam))
    const form = await dispatch(getTaskProcessingFormReducer(obj))
    const array = await dispatch(getAllFormsReducer(true))
    setAvailableForm(array.payload.respons.data)
    
    setgeoLocationForm(form?.payload?.geoFormId ? form?.payload?.geoFormId : null )
    setIsGeoLocation(form?.payload?.enableGeo ? form?.payload?.enableGeo : false)
    setSelectedTaskProcessingForm(form?.payload?.taskProcessingForm?.length > 0 ? form?.payload?.taskProcessingForm : [])
    
    setOpenFormSetting(true)
  }
  //end form congfig
  //approval row

  const [approvalRow, setApprovalRow] = useState([])
  const [openAppovalFlow, setOpenApprovalFlow] = useState(false)
  const [selectedApprovalType, setApprovalType] = useState([])
  //main
  const [mainApprovalUserType, setMainApprovalUserType] = useState([])

  const [ruleTargetField, setRuleTargetField] = useState(null)
  const [selectedEnumValue, setSelectedEnumValue] = useState([])
  const [approvalMethod, setApprovalMethod] = useState(null)

  const [isChanging, setChanging] = useState(false)
  const [allDatabaseUsers, setAllDatabAseUsers] = useState([])
  const [customSelectedUser, setCustomSelectedUser] = useState(null)
  const [mainApprovalCustomUser, setMainApprovalCustomUser] = useState(null)
  const [rejectionMethod, setRejectionMethod] = useState(null)

  //change rejection method function
  const changeRejectionMethod = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const obj = {
      formId: myParam,
      changeMethod: e
    }
    setRejectionMethod(e)
    await dispatch(changeRejectionMethodReducer(obj))

  }


  const changeRuleTargetField = (e) => {
    setRuleTargetField(e)
    const sc = schemaComponent.find((comp) => comp.keyTxt === e)


    if (sc) {
      if (sc.enum) {
        setSelectedEnumValue(sc.enum)
      }
    }
  }

  const [targetRuleEnum, setTargetRuleEnum] = useState(null)

  const changeTargetEnum = (e) => {
    setTargetRuleEnum(e)
  }



  const approvalType = [
    { const: 'supervisor', value: 'department supervisor' },
    { const: 'department_head', value: 'department head' },
    { const: 'de_supervisor', value: 'division supervisor' },
    { const: 'bu_manager', value: 'division manager' },
    { const: 'user_grade', value: 'User grade' },
    { const: 'custom', value: 'Custom' },
    { const: 'ammount', value: 'Ammount base' },
    { const: 'employee_group', value: 'Employee Group' },
  ]



  const approvalFlowCloss = () => {
    setOpenApprovalFlow(false)
  }
  const approvalFlowOpen = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const res = await dispatch(getFlowReducer(myParam))

    await getFormAnswerList()
    const form = await dispatch(getFormDetails(myParam))

    setRejectionMethod(form?.payload?.rejectionMethod ? form.payload.rejectionMethod : null)

    if (res?.payload?.data?.message) {
      setApprovalRow(res.payload.data.message)
    }


    const resUsers = await dispatch(getAllDatabaseUserReducer())

    if (resUsers?.payload?.data) {


      setAllDatabAseUsers(resUsers.payload.data.filter((e) => e.emCode && e.email))
    }

    setOpenApprovalFlow(true)
  }

  const approvalFlowMove = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    history.push("work-flow?id=" + myParam)
  }


  const moveExportSettting = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    history.push("export-profile?id=" + myParam)
  }






  const deleteItem = async (row) => {
    let newArray = approvalRow.filter((e) => e.flowStage != row.flowStage)

    if (newArray.length != 0) {
      newArray = newArray.map((data, index) => {
        return {
          ...data,
          flowStage: index + 1
        }
      })
    }


    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");



    let obj = {
      approvalRow: newArray.length === 0 ? null : newArray,
      formId: myParam
    }
    await dispatch(saveFlowReducer(obj))

    const res = await dispatch(getFlowReducer(myParam))

    if (res?.payload?.data?.message) {
      setApprovalRow(res.payload.data.message)
    } else {
      setApprovalRow([])
    }
    notification.success({
      message: `Success`,
      description:
        `Form work flow save...`,

    });


  };

  const [amountBase, setAmountBase] = useState([])

  const [fieldList, setFieldList] = useState([])

  const onFinish = async (values) => {
    setAmountBase(values.rules ? values.rules : [])


  };

  const submitElementValue = async (values) => {
    setFieldList(values.rules_field ? values.rules_field : [])


  };


  const saveFlow = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    let mainApprovalData = null
    if (mainApprovalUserType) {
      mainApprovalData = {
        mainApprovalUserType: mainApprovalUserType
      }
      if (mainApprovalUserType === 'custom') {
        if (!mainApprovalCustomUser) {
          alert('please assign main approval user')
          return
        }
        mainApprovalData = {
          ...mainApprovalData,
          mainApprovalCustomUser: mainApprovalCustomUser
        }

      }

    }




    if (approvalRow.length != 0) {


      let obj = {
        approvalRow: approvalRow,
        formId: myParam,
        mainApprovalData: mainApprovalData ? mainApprovalData : null,
        ruleType: approvalMethod ? approvalMethod : null,
        ruleBaseElement: ruleTargetField ? ruleTargetField : null
      }
      await dispatch(saveFlowReducer(obj))
    }

    notification.success({
      message: `Success`,
      description:
        `Form work flow save...`,

    });


  };


  // onChange={(e) => { changeRuleTargetField(e) }}
  // >

  //   {schemaComponent.map((row, index) => (
  //     <Option value={row.keyTxt}> {row.lableTxt}</Option>
  //   ))}

  // </AntSelect>


  // <AntSelect
  //   showSearch
  //   style={{ width: '100%' }}
  //   placeholder="Rule target value"
  //   optionFilterProp="children"
  //   onChange={(e) => { changeTargetEnum(e) }}


  const addNewFlow = () => {


    ;
    let obj = {
      selectedApprovalType: selectedApprovalType,
      flowStage: approvalRow.length + 1
    }

    if (selectedApprovalType.find((e) => e === 'custom')) {
      if (!customSelectedUser) {
        return
      }
      obj.selectedCustomer = customSelectedUser
    }
    if (selectedApprovalType.find((e) => e === 'ammount')) {
      if (!amountBase) {
        return
      }
      obj.amountBase = amountBase
    }


    if (approvalMethod === 'rule') {
      obj.ruleBase = fieldList
    }


    setApprovalRow(oldArray => [...oldArray, obj]);
  };

  const dragChangeSort = (row, direction) => {
    setChanging(true)
    let currentStage = row
    let flowTemp = approvalRow
    if (direction === 'up') {
      const selInd = parseInt(currentStage.flowStage) - 1
      const effectInd = parseInt(currentStage.flowStage) - 2
      let prevStage = flowTemp[effectInd]


      const newKeyForSelectedKey = prevStage.flowStage
      const newKeyForPriviorsKey = currentStage.flowStage

      const newObjectSel = {
        ...currentStage,
        flowStage: newKeyForSelectedKey
      }
      const newObjectChange = {
        ...prevStage,
        flowStage: newKeyForPriviorsKey
      }
      flowTemp[selInd] = newObjectChange
      flowTemp[effectInd] = newObjectSel


      setApprovalRow(flowTemp)

      setChanging(false)


    }
    //  ;
    // let obj = {
    //   selectedApprovalType: selectedApprovalType,
    //   flowStage: approvalRow.length + 1,
    //   lable: approvalType.find((e) => e.const == selectedApprovalType).value ? approvalType.find((e) => e.const == selectedApprovalType).value : null
    // }


    // setApprovalRow(oldArray => [...oldArray, obj]);
  };


  const init = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    getAllKeyElement(myParam);
    setSelectedFormId(myParam);
    getFormSchimaToDom(myParam);
    getAllGroup();
  }


  const [render, setRender] = useState([
    ...materialRenderers
  ])

  const [cellRender, setCellRender] = useState([
    ...materialCells,
    {
      tester: rankWith(
        3, //increase rank as needed
        scopeEndsWith('yssss')
      ), cell: CalculationArray
    }

  ])
  const [isTestFormOpen, openTestForm] = useState(false)

  const [uischemaa, setUiSchema] = useState({
    "type": "VerticalLayout",
    "elements": [
      {
        "type": "Control",
        "scope": "#/properties/comments",
        "options": {
          "elementLabelProp": "yssss",
          "detail": {
            "type": "VerticalLayout",
            "elements": [
              {
                "type": "Control",
                "scope": "#/properties/yssss"
              },
              {
                "type": "Control",
                "scope": "#/properties/name"
              }
            ]
          }
        }
      }
    ]
  })
  const [schemaa, setSchema] = useState({
    "properties": {
      "comments": {
        "type": "array",
        "title": "Comments",
        "items": {
          "type": "object",
          "properties": {
            "yssss": {
              "type": "string"
            },
            "name": {
              "type": "string"
            }
          }
        }
      }
    }
  })


  const consoleShow = () => {


  }

  useEffect(() => {
    if (isLoadForm) {
      setIsLoadForm(false)
      loadParentForms()
    }
  }, [isLoadForm])

  //

  useEffect(() => {

    init()
  }, []);

  return (
    <>
      <React.Fragment>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div className="page-continer ">

              <Typography variant="body2" gutterBottom>
                <Row>
                  <Col xs={24} sm={24} md={24}>
                    <Grid
                      className="page-action-header"
                      style={{ padding: 8 }}
                      container
                      spacing={2}
                    >
                      <Grid
                        style={{ padding: 8, paddingBottom: 0 }}
                        item
                        xs={12}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              onClick={handleClickOpen}
                              icon={<SearchOutlined />}
                            >
                              View Form
                            </AntButton>
                          </Grid>
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              onClick={handleFormElimepOpen}
                              icon={<ExportOutlined />}
                            >
                              Add new Eliment
                            </AntButton>
                          </Grid>
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              onClick={handleOpenManageElementKey}
                              icon={<ImportOutlined />}
                            >
                              Add new Group
                            </AntButton>
                          </Grid>
                          {/* <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={approvalFlowOpen}
                              icon={<FileAddOutlined />}
                            >
                              Approval Flow
                            </AntButton>
                          </Grid> */}

                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={approvalFlowMove}
                              icon={<FileAddOutlined />}
                            >
                              Approval Setting
                            </AntButton>
                          </Grid>
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={moveExportSettting}
                              icon={<FileAddOutlined />}
                            >
                              Export Setting
                            </AntButton>
                          </Grid>


                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={formConfigOpen}
                              icon={<FileAddOutlined />}
                            >
                              Form Configuation
                            </AntButton>
                          </Grid>

                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={formSettingOpen}
                              icon={<FileAddOutlined />}
                            >
                              Form Settings
                            </AntButton>
                          </Grid>

                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <AntButton
                              block={true}
                              danger
                              //onClick={handleOpenTestForm}
                              onClick={openEmailTemplateFunction}
                              icon={<FileAddOutlined />}
                            >
                              Email Template
                            </AntButton>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid className="page-action-header" container spacing={2}>
                      <Grid
                        style={{ padding: 15, paddingBottom: 0 }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        lg={3}
                      >
                        <AntSelect
                          defaultValue="main"
                          style={{ width: 120 }}
                          onChange={(e) => changeGroup(e)}
                        >
                          <Option value="main">main</Option>
                          {groupList.map((row) => (
                            <Option value={row.groupId}>{row.groupName}</Option>
                          ))}
                        </AntSelect>
                      </Grid>


                    </Grid>

                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ width: 70 }}
                              align="left"
                            ></TableCell>
                            <TableCell align="left">lable</TableCell>
                            <TableCell align="left">key</TableCell>
                            <TableCell align="left">Saving</TableCell>
                            <TableCell align="left">Order Id</TableCell>
                            <TableCell align="left">Is Amount</TableCell>
                            <TableCell align="left">Show In widget Header</TableCell>
                            <TableCell align="left">Show in Task Process</TableCell>

                            <TableCell align="left">Show in workflow</TableCell>

                            <TableCell align="left">Translate</TableCell>
                            <TableCell align="left">Make Task Along That</TableCell>
                            <TableCell align="left">Dead Line</TableCell>
                            <TableCell align="left">Sub Devider</TableCell>
                            <TableCell align="left">Check in Approval</TableCell>
                            <TableCell align="left">Disable In Edit</TableCell>
                            <TableCell align="left">Divider</TableCell>
                            <TableCell align="left">start time</TableCell>
                            <TableCell align="left">finish time</TableCell>
                            <TableCell align="left">Is main</TableCell>
                            <TableCell align="left">hide In Edit</TableCell>
                            <TableCell align="left">hide In Add</TableCell>
                            <TableCell align="left">Processing form</TableCell>
                            <TableCell align="left">Grouped</TableCell>
                            <TableCell align="left">Required</TableCell>
                            <TableCell align="left">Showing belong user(s)</TableCell>
                            <TableCell align="left">Use As Parent Form</TableCell>
                            <TableCell align="left">ignore created user</TableCell>
                            <TableCell align="left">Disable</TableCell>
                            <TableCell align="left">Connect with System Data</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell style={{ width: 100 }} align="left">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoadingElement ? (
                            <>
                              {elementByGroup.map((row, index) => (
                                <TableRow
                                  key={row.keyTxt}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: 300 }}>
                                      <Skeleton />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <>
                              {elementByGroup.map((row, index) => (
                                <TableRow
                                  key={row.keyTxt}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    {index !== 0 ? (
                                      <IconButton
                                        size="small"
                                        sx={{ color: "red" }}
                                        onClick={() => {
                                          changeOrderFunctionElement(
                                            row.keyTxt,
                                            "up"
                                          );
                                        }}
                                        aria-label="upload picture"
                                        component="span"
                                      >
                                        <ArrowDropUpIcon />
                                      </IconButton>
                                    ) : null}

                                    {elementByGroup.length !== index + 1 ? (
                                      <IconButton
                                        size="small"
                                        sx={{ color: "red" }}
                                        onClick={() => {
                                          changeOrderFunctionElement(
                                            row.keyTxt,
                                            "down"
                                          );
                                        }}
                                        aria-label="upload picture"
                                        component="span"
                                      >
                                        <ArrowDropDownIcon />
                                      </IconButton>
                                    ) : null}
                                  </TableCell>
                                  <TableCell>{row.lableTxt}</TableCell>

                                  <TableCell align="left">
                                    {row.keyTxt}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.fieldPlace ? row.fieldPlace : "-"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.orderNumber ? row.orderNumber : "-"}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isMoney} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isMoney')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.showInHeaderDashboard} onChange={(e) => setIsRequeired(e, row.keyTxt, 'showInHeaderDashboard')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.showInHeaderTaskProcess} onChange={(e) => setIsRequeired(e, row.keyTxt, 'showInHeaderTaskProcess')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.showInHeaderWorkflow} onChange={(e) => setIsRequeired(e, row.keyTxt, 'showInHeaderWorkflow')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isTranslated} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isTranslated')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.trigerAll} onChange={(e) => setIsRequeired(e, row.keyTxt, 'trigerAll')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.dead_line} onChange={(e) => setIsRequeired(e, row.keyTxt, 'dead_line')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.sub_devider} onChange={(e) => setIsRequeired(e, row.keyTxt, 'sub_devider')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.checkInApproval} onChange={(e) => setIsRequeired(e, row.keyTxt, 'checkInApproval')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isDisableInEdit} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isDisableInEdit')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.divider} onChange={(e) => setIsRequeired(e, row.keyTxt, 'divider')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.start} onChange={(e) => setIsRequeired(e, row.keyTxt, 'start')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.finish} onChange={(e) => setIsRequeired(e, row.keyTxt, 'finish')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isMain} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isMain')}></Checkbox>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Checkbox checked={row.isHideInEdit} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isHideInEdit')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isHideInAdd} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isHideInAdd')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isProcessingForm} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isProcessingForm')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.groupArray} onChange={(e) => setIsRequeired(e, row.keyTxt, 'groupArray')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.isRequired} onChange={(e) => setIsRequeired(e, row.keyTxt, 'isRequired')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.showToUser} onChange={(e) => setAsShowUser(e, row.keyTxt)}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox onChange={(e) => changeAsParentForm(e, row.keyTxt)}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.igonoreCreatedUser} onChange={(e) => setIsRequeired(e, row.keyTxt, 'igonoreCreatedUser')}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.disableEl} onChange={(e) => changeDisableElement(e, row.keyTxt)}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Checkbox checked={row.connectToSystemUser} onChange={(e) => connectToSystemUser(e, row.keyTxt)}></Checkbox>
                                  </TableCell>
                                  <TableCell align="left">{row.type}</TableCell>
                                  <TableCell>
                                    <Dropdown.Button
                                      trigger={["click"]}
                                      className="action-menu-button"
                                      overlay={
                                        <Menu
                                          onClick={handleMenuClick}
                                          items={[
                                            {
                                              label: "Delet Item",
                                              key: "1",
                                              items: { id: row },
                                              icon: (
                                                <DeleteOutlined className="delete-icon" />
                                              ),
                                            },
                                            {
                                              label: "Edit Item",
                                              key: "2",
                                              items: { id: row },
                                              icon: <EditOutlined />,
                                            },
                                          ]}
                                        />
                                      }
                                      icon={
                                        <SettingTwoTone twoToneColor="#28a745" />
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    {/* <ReviewForm jsonSchema={schemaComponent} elementGroup={groupList} /> */}
                  </Col>
                </Row>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Modal
          visible={openManageElementKey}
          title="Manage Groups"
          onOk={handleCloseManageElementKey}
          onCancel={handleCloseManageElementKey}
          width={'80vw'}
          footer={[
            <AntButton danger key="back" onClick={handleCloseManageElementKey}>
              Return
            </AntButton>

          ]}
        >

          <Grid
            className="page-action-header"
            style={{ padding: 8 }}
            container
            spacing={2}
          >
            <Grid style={{ padding: 8, paddingBottom: 0 }} item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} >
                  <TextField
                    className="array-value-add-container"
                    size="small"
                    value={groupNametxt}
                    onChange={(e) => {
                      setGroupNameTxt(e.target.value);
                    }}
                    label="Key"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    className="array-value-add-container"
                    size="small"
                    value={groupLabletxt}
                    onChange={(e) => {
                      setGroupLabletxt(e.target.value);
                    }}
                    label="lable"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    style={{ width: '100%' }}
                    value={groupTypetxt}
                    label="Age"
                    onChange={(e) => {
                      setGroupTypetxt(e.target.value);
                    }}
                  >
                    <MenuItem value="object">Object</MenuItem>
                    <MenuItem value="array">Array</MenuItem>
                    <MenuItem value="category">Category</MenuItem>
                    <MenuItem value="stage">Stage</MenuItem>
                    <MenuItem value="custom_workFlow">Custom Work Flow</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6} sm={6} >
                  {groupTypetxt === "stage" ? (
                    <>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        style={{ width: '100%' }}
                        value={selectedCategory}
                        label="Age"
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                        }}
                      >
                        {groupList
                          .filter((e) => e.groupType === "category")
                          .map((row, index) => (
                            <MenuItem value={row.groupId}>
                              {row.groupName}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  ) : null}
                  {groupTypetxt === "array" ? (
                    <>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        style={{ width: '100%' }}
                        value={arrayType}
                        label="Age"
                        onChange={(e) => {
                          setArrayType(e.target.value);
                        }}
                      >
                        <MenuItem value="asgroup">As group</MenuItem>
                        <MenuItem value="aselement">As Element</MenuItem>
                      </Select>
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={6} sm={6} >
                  <TextField
                    className="array-value-add-container"
                    size="small"
                    value={groupColtxt}
                    onChange={(e) => {
                      setGroupColtxt(e.target.value);
                    }}
                    label="row count"
                    variant="outlined"
                    type="number"
                  />
                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <IconButton
                    onClick={addValueToGroup}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 70 }}></TableCell>
                    <TableCell>Group Name</TableCell>
                    <TableCell align="right">hide in add</TableCell>
                    <TableCell align="right">hide in edit</TableCell>
                    <TableCell align="right">Add Stepper</TableCell>
                    <TableCell align="right">Group Lable</TableCell>
                    <TableCell align="right">Group Type</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupList.map((row, index) => (
                    <TableRow key={row.groupId}>
                      <TableCell align="right">
                        {index !== 0 ? (
                          <IconButton
                            size="small"
                            sx={{ color: "red" }}
                            onClick={() => {
                              changeOrderFunction(row.groupId, "up");
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <ArrowDropUpIcon />
                          </IconButton>
                        ) : null}

                        {groupList.length !== index + 1 ? (
                          <IconButton
                            size="small"
                            sx={{ color: "red" }}
                            onClick={() => {
                              changeOrderFunction(row.groupId, "down");
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                      <TableCell>{row.groupName}</TableCell>
                      <TableCell align="left">
                        <Checkbox checked={row.hideInAdd} onChange={(e) => changeGroupConfigaration(e, row.groupId, 'hideInAdd')}></Checkbox>
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox checked={row.hideInEdit} onChange={(e) => changeGroupConfigaration(e, row.groupId, 'hideInEdit')}></Checkbox>
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox checked={row.setStepper} onChange={(e) => changeGroupConfigaration(e, row.groupId, 'setStepper')}></Checkbox>
                      </TableCell>
                      <TableCell align="right">{row.groupLable}</TableCell>
                      <TableCell align="right">{row.groupType}</TableCell>
                      <TableCell align="right">
                        <AntButton
                          type="text"
                          onClick={() => {
                            deleteFormGroup(row);
                          }}
                          icon={<DeleteOutlined className="delete-icon" />}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Modal>



        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>JSON Form View</DialogTitle>
          <DialogContent>
            <ReviewForm jsonSchema={schemaComponent} elementGroup={groupList} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={textFormModel}
          onClose={handleCloseTestForm}
        >
          <DialogTitle>test Form View</DialogTitle>
          <DialogContent>
            <TestForm jsonSchema={schemaComponent} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTestForm}>Cancel</Button>
          </DialogActions>
        </Dialog>

        {/* {isOpenEditSchema &&
          <SchemaElementEdit
            init={selectedEditData}
            selGro={selectedGroupId}
            editFormSuccess={() => {
              editSuccess();
            }}
            isOpenEditSchema={isOpenEditSchema}
            closeEditSchema={closeEditSchema}
          />
        } */}

      {/* common component */}
        {
          (isAddElement || isEditElement) &&
          <SchemaElementAddEdit
            init={selectedEditData}
            selGro={selectedGroupId}
            editFormSuccess={() => {
              editSuccess();
            }}
            isOpenEditSchema={isEditElement}
            closeEditSchema={closeEditSchema}
            isOpenAddSchema={isAddElement}
            closeAddSchema={closeAddSchema}
          />
        }


          {openAddFormEliment &&
        <Modal
          title="Add Element"
          visible={openAddFormEliment}
          onCancel={handleFormELimentCloss}
          footer={[
            <AntButton danger key="back" disabled={isFormSubmited} onClick={handleFormELimentCloss}>
              {t("Cancel")}
            </AntButton>,
            <AntButton danger key="submit" type="primary" disabled={isFormSubmited} onClick={addElementToSchema}>
              <span style={{
                marginRight: isFormSubmited ? '10px' : '0px'
              }}>{t("Submit")}</span> {isFormSubmited && <CircularProgress style={{
                height: '10px',
                width: '10px',
              }} />}
            </AntButton>
          ]}
        >
          <Grid
            className="page-action-header"
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <FormElement changeSelectedType={changeSelectedType} setIsLoadForm={setIsLoadForm} />
            </Grid>
          </Grid>




          <Grid className="box-setting-display" container spacing={2}>
            <Grid container spacing={2}>
              <Grid className="field-container" item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Saving Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={savingType}
                    label="group"
                    onChange={(e) => {
                      setSavingType(e.target.value);
                      setElementOption((prevState) => {
                        return {
                          ...prevState,
                          keyTxt: "",
                        };
                      });
                    }}
                  >
                    <MenuItem value="database">Database</MenuItem>
                    <MenuItem value="bitrix">Bitrix</MenuItem>
                    <MenuItem value="root">Root</MenuItem>

                  </Select>
                </FormControl>
              </Grid>

              <Grid className="field-container" item xs={12} md={6}>
                <TextField
                  value={elementOption.lableTxt}
                  onChange={changeDisplayValue}
                  className="full-width"
                  size="small"
                  label="Field lable"
                  variant="outlined"
                />
              </Grid>
              <Grid className="field-container" item xs={12} md={6}>
                {savingType === "bitrix" ? (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Key
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={elementOption.keyTxt}
                      label="Age"
                      onChange={(e) => {
                        setElementOption((prevState) => {
                          return {
                            ...prevState,
                            keyTxt: e.target.value,
                          };
                        });
                      }}
                    >
                      {availableElementKeyList.map((row) => (
                        <MenuItem value={row.keyName}>
                          {row.keyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    value={elementOption.keyTxt}
                    onChange={(e) => {
                      setElementOption((prevState) => {
                        return {
                          ...prevState,
                          keyTxt: e.target.value,
                        };
                      });
                    }}
                    className="full-width"
                    size="small"
                    label="Field Key"
                    variant="outlined"
                  />
                )}
              </Grid>

              <Grid className="field-container" item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={elementOption.group}
                    label="group"
                    onChange={(e) => {
                      setElementOption((prevState) => {
                        return {
                          ...prevState,
                          group: e.target.value,
                        };
                      });
                    }}
                  >
                    {groupList.map((row1) => (
                      <MenuItem value={row1.groupId}>
                        {row1.groupName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="field-container" item xs={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Required"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {(() => {
              if (selectedTextType === "string") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={6} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Line
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            value={elementOption.multiLine}
                            label="Age"
                            onChange={(e) => {
                              setElementOption((prevState) => {
                                return {
                                  ...prevState,
                                  multiLine: e.target.value,
                                };
                              });
                            }}
                          >
                            <MenuItem value="M">Multi Line</MenuItem>
                            <MenuItem value="S">Single Line</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid className="field-container" item xs={6} md={4}>
                        <TextField
                          size="small"
                          className="full-width"
                          label="Max length"
                          variant="outlined"
                          type="number"
                          value={elementLength.maxChar}
                          onChange={(e) => {
                            if (/^[0-9]{0,6}$/.test(e.target.value)) {
                              SetElementLength({
                                ...elementLength,
                                maxChar: e.target.value
                              })
                            }
                          }}
                        />
                      </Grid>
                      <Grid className="field-container" item xs={6} md={4}>
                        <TextField
                          size="small"
                          className="full-width"
                          label="Min length"
                          variant="outlined"
                          type="number"
                          value={elementLength.minChar}
                          onChange={(e) => {
                            if (/^[0-9]{0,6}$/.test(e.target.value)) {
                              SetElementLength({
                                ...elementLength,
                                minChar: e.target.value
                              })
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                );
              }
              else if (selectedTextType === "shops") {
                return (
                  <div className="setting-container">

                  </div>
                );
              }
              else if (selectedTextType === "calculation") {
                return (
                  <div className="setting-container">

                    Target element
                    <AntSelect
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a Schema"
                      optionFilterProp="children"
                      onChange={(e) => {
                        setCalculationArray(e);

                      }}
                    >


                      {elementByGroup.map((row, index) => (
                        <Option value={row.keyTxt}> {row.keyTxt}</Option>
                      ))}


                    </AntSelect>
                    <button onClick={() => {

                      setCalculationArrayObj(oldArray => [...oldArray, { field: calucaluationArray, operation: null }]);
                    }}>Add</button>

                    <div className="operation-list">

                      {calucaluationArrayObj.map((row, index) => (
                        <>{row.field}
                          <AntSelect
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="operation"
                            optionFilterProp="children"
                            onChange={(e) => {
                              const nextShapes = calucaluationArrayObj.map(shape => {
                                if (shape.field === row.field) {
                                  // No change

                                  return {
                                    ...shape,
                                    operation: e
                                  };
                                } else {
                                  return shape
                                }
                              });
                              setCalculationArrayObj(nextShapes);

                            }}
                          >


                            <Option value='-'> -</Option>
                            <Option value='+'> +</Option>
                            <Option value='*'> *</Option>

                          </AntSelect>
                        </>

                      ))}
                    </div>

                    {/* const [calucaluationArrayObj, setCalculationArrayObj] = useState(null) */}
                  </div>
                );
              } else if (selectedTextType === "number") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={6} md={4}>
                        <TextField
                          className="full-width"
                          label="Max"
                          size="small"
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid className="field-container" item xs={6} md={4}>
                        <TextField
                          className="full-width"
                          label="Min"
                          size="small"
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "total" || selectedTextType === "clearing") {
                return (
                  <div style={{ width: '100%' }} className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={6} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Target Field
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={targetValue}
                            size="small"
                            label="group"
                            onChange={(e) => {
                              setTargetValue(e.target.value);
                            }}
                          >
                    
                            {elementByGroup.filter((e) => e.type === 'number').map((row) => (
                              <MenuItem value={row.keyTxt}>
                                {row.lableTxt}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "Form") {
                return (
                  <div style={{ width: '100%' }} className="setting-container">

                  </div>
                );
              } else if (selectedTextType === "drop" || selectedTextType === 'dropTree' || selectedTextType === 'dynamicContent') {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDownConst}
                            onChange={(e) => {
                              addValueDropDownConst(e.target.value);
                            }}
                            label="Key"
                            variant="outlined"
                          />
                        </span>
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDown}
                            onChange={(e) => {
                              addValueDropDown(e.target.value);
                            }}
                            label="Item"
                            variant="outlined"
                          />
                        </span>
                        <IconButton
                          onClick={pushValueToDropDown}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <SendIcon />
                        </IconButton>
                      </Grid>
                      <Grid className="field-container" item xs={12} md={6}>
                        <div>
                          {dropDownValue.map((row) => (
                            <Chip
                              label={row.title}
                              onDelete={() => {
                                removeDropDownValus(row.const);
                              }}
                            />
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "radio") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={12} md={6}>
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDownConst}
                            onChange={(e) => {
                              addValueDropDownConst(e.target.value);
                            }}
                            label="Key"
                            variant="outlined"
                          />
                        </span>
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDown}
                            onChange={(e) => {
                              addValueDropDown(e.target.value);
                            }}
                            label="Item"
                            variant="outlined"
                          />
                        </span>{" "}
                        <IconButton
                          onClick={pushValueToDropDown}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <SendIcon />
                        </IconButton>
                      </Grid>
                      <Grid className="field-container" item xs={12} md={6}>
                        <Stack direction="row" spacing={1}>
                          {dropDownValue.map((row) => (
                            <Chip
                              label={row.title}
                              onDelete={() => {
                                removeDropDownValus(row.const);
                              }}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "date") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      Date type
                      <Col className="index-action" span={8}>
                        <AntSelect
                          value={dateInputType}
                          style={{ width: '100%' }}
                          onChange={(e) => setDateInputType(e)}
                        >
                          <Option value="user_input">User Input</Option>
                          <Option value="current">Current</Option>
                        </AntSelect>
                      </Col>
                    </Grid>
                  </div>
                );
              }
              else if (selectedTextType === "locationShare") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={12} md={6}>


                      </Grid>
                    </Grid>
                  </div>
                );

              }
              else if (selectedTextType === "answer_view") {
                return (
                  <div className="setting-container">

                    <Grid className="field-container" item xs={12} md={6}>
                      select showing element
                      <AntSelect
                        mode="multiple"
                        style={{ width: '450px' }}
                        onChange={changeViewElement}

                      >
                        {
                          schemaComponent.map((sc) => (
                            <Option value={sc.keyTxt ? sc.keyTxt : ' '}>{sc.lableTxt ? sc.lableTxt : ' '}</Option>
                          ))
                        }

                      </AntSelect>

                    </Grid>

                  </div>
                );

              }
              else if (selectedTextType === "move_to_next_stage") {
                return (
                  <div className="setting-container">



                  </div>
                );

              }
              else if (selectedTextType === "formTimeOut") {
                return (
                  <div className="setting-container">

                    <Grid className="field-container" item xs={12} md={6}>
                      Time Out After (MIN)
                      <InputNumber onChange={onChangeTimmer} />

                    </Grid>

                  </div>
                );

              }

              else if (selectedTextType === "autofill") {
                return (
                  <div className="setting-container">

                    <Grid className="field-container" item xs={12} md={6}>
                      parent
                      <input onChange={(e) => { parentFocus(e.target.value) }} />

                    </Grid>

                  </div>
                );

              }


              else if (selectedTextType === "index") {
                return (
                  <div className="field-container-index">
                    <Row>
                      <Col className="index-action" span={8}>
                        <AntSelect
                          style={{ width: '100%' }}
                          onChange={changeIndexRuleType}
                        >
                          <Option value="date">date</Option>
                          <Option value="static">static number</Option>
                          <Option value="random">random number</Option>
                        </AntSelect>
                      </Col>
                      <Col className="index-action" span={8}>
                        {

                          indexRuleType == "date" ?
                            <AntSelect
                              style={{ width: '100%' }}
                              onChange={(e) => { setDateType(e) }}
                            >
                              <Option value="MM">Month</Option>
                              <Option value="DD">Date</Option>
                              <Option value="YYYY">Year</Option>
                              <Option value="MMDD">Month & Date</Option>
                              <Option value="YYYYMMDD">Year, Month & Date</Option>
                            </AntSelect> : indexRuleType == 'static' ?
                              <Input onChange={(e) => { setStatiChar(e.target.value) }} style={{ width: '100%' }} placeholder='static number' /> : indexRuleType == 'random' ?
                                <Input onChange={(e) => { setRandomNumberLength(e.target.value) }} style={{ width: '100%' }} placeholder='number length' />
                                : null

                        }







                      </Col>
                      <Col className="index-action" span={8}>
                        <AntButton onClick={addRuleFunction} className="index-action" type="primary" danger style={{ width: '100%' }}>Add Rule</AntButton>
                      </Col>
                    </Row>
                    <table>
                      <tr>
                        <th>Rule Type</th>
                        <th>Rule</th>
                        <th>Action</th>
                      </tr>
                      {ruleArray.map((rule) => (
                        <tr>
                          <td>{rule.type}</td>
                          <td>{rule.value}</td>
                          <td><button> DELETE </button></td>
                        </tr>
                      ))}


                    </table>


                  </div>

                );
              } else if (selectedTextType === "html_camera" || selectedTextType === "cloacked") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Store Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fileStoreType}
                            size="small"
                            label="group"
                            onChange={(e) => {
                              changeFileStoreType(e.target.value);
                            }}
                          >
                            <MenuItem value="s3">Amazon S3</MenuItem>

                            <MenuItem value="local">Local</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {fileStoreType === "s3" ? (
                        <>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={6}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                bucket
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={s3Bucket}
                                size="small"
                                label="group"
                                onChange={(e) => {
                                  changeS3Bucket(e.target.value);
                                }}
                              >
                                <MenuItem value="mertracker">
                                  Mertracker
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={6}
                          >
                            <TextField
                              value={s3Dir}
                              size="small"
                              onChange={(e) => {
                                changeS3Dir(e.target.value);
                              }}
                              className="full-width"
                              label="Dir name"
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Divider>IMAGE SETTINGS</Divider>
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Accepted
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedFileType}
                            onChange={modifyFileType}
                            size="small"
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {acceptedFileType.map((row1) => (
                              <MenuItem key={row1.const} value={row1.lable}>
                                <Checkbox
                                  checked={
                                    selectedFileType.indexOf(row1.lable) >
                                    -1
                                  }
                                />
                                <ListItemText primary={row1.lable} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Capture Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fileSource}
                            label="group"
                            size="small"
                            onChange={(e) => {
                              setFileSource(e.target.value);
                            }}
                          >
                            <MenuItem value="local">Storage</MenuItem>
                            <MenuItem value="camera">Camera</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "File") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Store Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fileStoreType}
                            size="small"
                            label="group"
                            onChange={(e) => {
                              changeFileStoreType(e.target.value);
                            }}
                          >
                            <MenuItem value="s3">Amazon S3</MenuItem>

                            <MenuItem value="local">Local</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {fileStoreType === "s3" ? (
                        <>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={6}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                bucket
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={s3Bucket}
                                size="small"
                                label="group"
                                onChange={(e) => {
                                  changeS3Bucket(e.target.value);
                                }}
                              >
                                <MenuItem value="mertracker">
                                  Mertracker
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            className="field-container"
                            item
                            xs={12}
                            md={6}
                          >
                            <TextField
                              value={s3Dir}
                              size="small"
                              onChange={(e) => {
                                changeS3Dir(e.target.value);
                              }}
                              className="full-width"
                              label="Dir name"
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Divider>IMAGE SETTINGS</Divider>
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Accepted
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedFileType}
                            onChange={modifyFileType}
                            size="small"
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {acceptedFileType.map((row1) => (
                              <MenuItem key={row1.const} value={row1.lable}>
                                <Checkbox
                                  checked={
                                    selectedFileType.indexOf(row1.lable) >
                                    -1
                                  }
                                />
                                <ListItemText primary={row1.lable} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid className="field-container" item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Capture Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fileSource}
                            label="group"
                            size="small"
                            onChange={(e) => {
                              setFileSource(e.target.value);
                            }}
                          >
                            <MenuItem value="local">Storage</MenuItem>
                            <MenuItem value="camera">Camera</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "location") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid className="field-container" item xs={12} md={6}>

                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="On open"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid className="field-container" item xs={6} md={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="On Click"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </div>
                );

              } else if (selectedTextType === "check") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDownConst}
                            onChange={(e) => {
                              addValueDropDownConst(e.target.value);
                            }}
                            label="Key"
                            variant="outlined"
                          />
                        </span>
                        <span>
                          <TextField
                            className="array-value-add-container"
                            size="small"
                            value={valueDropDown}
                            onChange={(e) => {
                              addValueDropDown(e.target.value);
                            }}
                            label="Item"
                            variant="outlined"
                          />
                        </span>
                        <IconButton
                          onClick={pushValueToDropDown}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <SendIcon />
                        </IconButton>
                      </Grid>
                      <Grid className="field-container" item xs={12} md={6}>
                        <Stack direction="row" spacing={1}>
                          {dropDownValue.map((row) => (
                            <Chip
                              label={row.title}
                              onDelete={() => {
                                removeDropDownValus(row.const);
                              }}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                );
              } else if (selectedTextType === "data_grid") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >

                        Grid Payload From
                        <AntSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Form"
                          optionFilterProp="children"
                          onChange={(e) => { ChangeSelectedParentForm(e) }}
                        >

                          {parentFormList.map((row, index) => (
                            <Option value={row.id}> {row.name}</Option>
                          ))}


                        </AntSelect>
                        Grid Payload Group
                        <AntSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Schema"
                          optionFilterProp="children"
                          onChange={(e) => { setselectedParentFormSchemaId(e) }}
                        >

                          {selectedParentGroupeList.map((row, index) => (
                            <Option value={row.groupId}> {row.groupName}</Option>
                          ))}


                        </AntSelect>



                      </Grid>
                    </Grid>
                  </div>
                );
              }
              else if (selectedTextType === "lable_draft") {
                return (
                  <div className="setting-container">
                    <Grid container spacing={2}>
                      <Grid
                        className="field-container"
                        item
                        xs={12}
                        md={12}
                      >

                        Grid Payload From
                        <AntSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Form"
                          optionFilterProp="children"
                          onChange={(e) => { ChangeSelectedParentForm(e) }}
                        >

                          {parentFormList.map((row, index) => (
                            <Option value={row.id}> {row.name}</Option>
                          ))}


                        </AntSelect>
                        Grid Payload Schema
                        <AntSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Schema"
                          optionFilterProp="children"
                          onChange={(e) => { setselectedParentFormSchemaId(e) }}
                        >

                          {selectedParentFormList.map((row, index) => (
                            <Option value={row.keyTxt}> {row.keyTxt}</Option>
                          ))}


                        </AntSelect>
                        <textarea rows="6" style={{ marginTop: 10, width: '100%' }} placeholder="lable name" onChange={(e) => changeLableDefaula(e.target.value)} />


                      </Grid>
                    </Grid>
                  </div>
                );
              }
              else if (selectedTextType === "timeLine") {
                return (
                  <div className="setting-container">

                    <AntCheckBox onChange={(e) => changeTimeLineData(e, 'Location')}>
                      Location
                    </AntCheckBox>
                    <AntCheckBox onChange={(e) => changeTimeLineData(e, 'Date')}>
                      Date & Time
                    </AntCheckBox>
                    <AntCheckBox onChange={(e) => changeTimeLineData(e, 'resume')}>
                      Resume
                    </AntCheckBox>


                  </div>
                );
              } else if (selectedTextType === "repeat") {
                return (
                  <div className="setting-container">




                  </div>
                );
              }
              else {
                return (
                  <>
                    <CssBaseline />
                    <Container maxWidth="sm">
                      <Box
                        className="no-select-compoment"
                        sx={{ bgcolor: "#cfe8fc" }}
                      >
                        <div>
                          <h5>Please select element to continue</h5>
                        </div>
                      </Box>
                    </Container>
                  </>
                );
              }
            })()}
            <Grid container spacing={2}>
              <Grid className="field-container" item xs={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={referenceChacked}
                        checked={reference}
                      />
                    }
                    label="Reference"
                  />
                </FormGroup>
              </Grid>
              {!reference ? (
                <></>
              ) : (
                <>
                  <Grid className="field-container" item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        reference
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={referenceKey}
                        label="reference"
                        onChange={(e) => {
                          setReferenceKey(e.target.value);
                        }}
                      >
                        {elementKeyList.map((row) => (
                          <MenuItem value={row.keyName}>
                            {row.keyName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>


        </Modal>
        }




        <Dialog
          open={elemtnDeletePromt}
          onClose={() => setElemtnDeletePromt(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete element"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wanted to delete this element.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setElemtnDeletePromt(false)}>No</Button>
            <Button onClick={() => deleteElementConfirmed()} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={groupDeletePromt}
          onClose={() => setGroupDeletePromt(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete group"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wanted to delete this group.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setGroupDeletePromt(false)}>No</Button>
            <Button onClick={() => deleteGroupConfirmed()} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>


        <Modal
          visible={openAppovalFlow}
          title="Approval Flow"

          onOk={approvalFlowCloss}
          onCancel={approvalFlowCloss}
          width={1000}
          footer={[
            <AntButton danger key="back" onClick={approvalFlowCloss}>
              Return
            </AntButton>,
            <AntButton danger key="submit" type="primary" onClick={saveFlow}>
              Save
            </AntButton>
          ]}
        >


          <div>
            <AntSelect
              showSearch
              style={{ width: '100%' }}
              className="flow-input"
              placeholder="Approval method"
              optionFilterProp="children"
              onChange={(e) => { setApprovalMethod(e) }}
            >


              <Option value='static'>STATIC</Option>
              <Option value='rule'>RULE</Option>


            </AntSelect>
          </div>
          <div>
            <>
              Main Responsible User

              <AntSelect
                showSearch
                style={{ width: '100%' }}
                className="flow-input"
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={(e) => { setMainApprovalUserType(e) }}
              >

                {approvalType.map((row, index) => (
                  <Option value={row.const}> {row.value}</Option>
                ))}


              </AntSelect>


              {
                mainApprovalUserType.find((e) => e === 'custom') ?
                  <AntSelect
                    mode="multiple"
                    showSearch
                    className="flow-input"
                    style={{ width: '100%' }}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={(e) => { setMainApprovalCustomUser(e) }}
                  >

                    {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                      <Option value={row.emCode}> {row.email}</Option>
                    ))}


                  </AntSelect>
                  : null
              }
              <AntButton danger key="submit" type="primary" onClick={addNewFlow}>
                ADD
              </AntButton>

            </>
            {
              approvalMethod === 'rule' ? <>
                <>
                  <AntSelect
                    showSearch
                    className="flow-input"
                    style={{ width: '100%' }}
                    placeholder="Rule target field"
                    optionFilterProp="children"
                    onChange={(e) => { changeRuleTargetField(e) }}
                  >

                    {schemaComponent.map((row, index) => (
                      <Option value={row.keyTxt}> {row.lableTxt}</Option>
                    ))}

                  </AntSelect>


                  <AntSelect
                    showSearch
                    className="flow-input"
                    style={{ width: '100%' }}
                    placeholder="Rule target value"
                    optionFilterProp="children"
                    onChange={(e) => { changeTargetEnum(e) }}
                  >

                    {selectedEnumValue.map((row, index) => (
                      <Option value={row.const}> {row.title}</Option>
                    ))}

                  </AntSelect>

                  <Form style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={submitElementValue} autoComplete="off">
                    <Form.List name="rules_field">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space className="aray-div-me" key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">


                              <Form.Item

                                {...restField}
                                name={[name, 'field']}
                                rules={[{ required: false, message: 'Missing Field' }]}
                              >
                                <AntSelect
                                  style={{ width: '250px' }}
                                  showSearch
                                  placeholder="Select person"
                                  allowClear
                                >
                                  {schemaComponent.map((row, index) => (
                                    <Option value={row.keyTxt}> {row.lableTxt}</Option>
                                  ))}



                                </AntSelect>
                              </Form.Item>

                              <Form.Item dependencies={[["rules_field", name, "field"]]}>
                                {({ getFieldValue }) => {
                                  const type = getFieldValue("rules_field") ? getFieldValue("rules_field") : null
                                  let showValue = false
                                  let enumArray = []
                                  if (type[name]) {
                                    const nameLevel = type[name]["field"] ? type[name]["field"] : null

                                    const sc = schemaComponent.find((comp) => comp.keyTxt === nameLevel)


                                    if (sc) {
                                      if (sc.enum) {
                                        enumArray = sc.enum
                                        showValue = true
                                      } else {
                                        showValue = false
                                      }
                                    }
                                  }

                                  //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;

                                  return (
                                    <>
                                      {
                                        showValue ?
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                          >
                                            <AntSelect
                                              style={{ width: '250px' }}
                                              showSearch
                                              placeholder="Select person"
                                              allowClear
                                            >
                                              {enumArray.map((row, index) => (
                                                <Option value={row.const}>{row.title}</Option>
                                              ))}




                                            </AntSelect>
                                          </Form.Item>
                                          : null
                                      }
                                    </>



                                  );
                                }}
                              </Form.Item>

                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add field
                            </Button>
                          </Form.Item>


                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              </> : null
            }

          </div>
          <AntSelect
            showSearch
            mode="multiple"
            className="flow-input"
            style={{ width: '100%' }}
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={(e) => { setApprovalType(e) }}
          >

            {approvalType.map((row, index) => (
              <Option value={row.const}> {row.value}</Option>
            ))}


          </AntSelect>


          {
            selectedApprovalType.find((e) => e === 'custom') ?
              <AntSelect
                mode="multiple"
                showSearch
                className="flow-input"
                style={{ width: '100%' }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={(e) => { setCustomSelectedUser(e) }}
              >

                {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                  <Option value={row.emCode}> {row.email}</Option>
                ))}


              </AntSelect>
              : null
          }
          {
            selectedApprovalType.find((e) => e === 'ammount') ?
              <div style={{ width: '100%' }}>

                <Form style={{ width: '100%' }} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                  <Form.List name="rules">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space className="aray-div-me" key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">


                            <Form.Item
                              {...restField}
                              name={[name, 'min_value']}
                            >
                              <Input style={{ width: '80px' }} placeholder="min ammount" />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              name={[name, 'max_value']}
                            >
                              <Input style={{ width: '80px' }} placeholder="max amount" />
                            </Form.Item>

                            <Form.Item

                              {...restField}
                              name={[name, 'action']}
                              rules={[{ required: false, message: 'Missing Field' }]}
                            >
                              <AntSelect
                                style={{ width: '250px' }}
                                mode="multiple"
                                showSearch
                                placeholder="Select person"
                                allowClear
                              >
                                {approvalType.filter((e) => e.const !== 'ammount').map((row, index) => (
                                  <Option value={row.const}> {row.value}</Option>
                                ))}

                                {allDatabaseUsers.filter((e) => e.emCode).map((row, index) => (
                                  <Option value={row.emCode}> {row.email}</Option>
                                ))}


                              </AntSelect>
                            </Form.Item>
                            <Form.Item dependencies={[["rules", name, "action"]]}>
                              {({ getFieldValue }) => {
                                const type = getFieldValue("rules") ? getFieldValue("rules") : null
                                let showValue = false
                                if (type[name]) {
                                  const nameLevel = type[name]["action"] ? type[name]["action"] : null
                                  if (nameLevel.find((e) => e === 'employee_group')) {
                                    showValue = true
                                  } else {
                                    showValue = false
                                  }
                                  ;
                                  ;
                                }

                                //[name]["max_value"]?getFieldValue("rules")[name]["max_value"]:null;

                                return (
                                  <>
                                    {
                                      showValue ?
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'groupId']}
                                        >
                                          <AntSelect
                                            style={{ width: '250px' }}
                                            mode="multiple"
                                            showSearch
                                            placeholder="Select person"
                                            allowClear
                                          >
                                            {EmployeeGroupList.map((row, index) => (
                                              <Option value={row.id}> {row?.data?.database?.main?.GROUP_NAME ? row.data.database.main.GROUP_NAME : '-'}</Option>
                                            ))}




                                          </AntSelect>
                                        </Form.Item>
                                        : null
                                    }
                                  </>



                                );
                              }}
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add field
                          </Button>
                        </Form.Item>


                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>


              </div>
              : null
          }
          <AntButton danger key="submit" type="primary" onClick={addNewFlow}>
            ADD
          </AntButton>

          <>

            <TableContainer component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">TARGET FIELD</TableCell>
                    <TableCell align="left">stage</TableCell>
                    <TableCell align="left">type</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    isChanging ? null : <>
                      {approvalRow.map((row, index) => (
                        <TableRow>
                          <TableCell>
                            {

                            }
                            {
                              row.ruleBase ? <>
                                {
                                  row.ruleBase.map((ruleSet) => (
                                    <div>
                                      <div>Target Field :  {ruleSet.field}</div>
                                      <div>Target Value :  {ruleSet.value}</div>
                                      <hr />
                                    </div>
                                  ))
                                }

                              </> : null
                            }
                          </TableCell>
                          <TableCell>

                            {row.selectedApprovalType.toString()}
                          </TableCell>
                          <TableCell>
                            <>
                              <div>
                                {
                                  row.selectedApprovalType == 'custom' ?

                                    <>
                                      {
                                        row.selectedCustomer.toString()
                                      }
                                    </>


                                    :
                                    row.selectedApprovalType == 'ammount' ?

                                      <div>
                                        <table>
                                          <tr>
                                            <th>MIN</th>
                                            <th>MAX</th>
                                            <th>APPROVAL</th>
                                          </tr>



                                          {
                                            row.amountBase ? <>
                                              {row.amountBase.map((rowAMount, indexAmount) => (
                                                <tr>
                                                  <td>{rowAMount.min_value ? rowAMount.min_value : null}</td>
                                                  <td>{rowAMount.max_value ? rowAMount.max_value : null}</td>
                                                  <td>{rowAMount.action ?
                                                    rowAMount.action.map((eAction) => (
                                                      <div>
                                                        {eAction}

                                                        {
                                                          eAction === 'employee_group' ? <>
                                                            {
                                                              rowAMount.groupId.map((eGroup) => (
                                                                <div>{
                                                                  EmployeeGroupList.find((eGrList) => eGrList.id === eGroup)?.data?.database?.main?.GROUP_NAME ?
                                                                    EmployeeGroupList.find((eGrList) => eGrList.id === eGroup).data.database.main.GROUP_NAME : null
                                                                }</div>
                                                              ))
                                                            }

                                                          </> : null
                                                        }
                                                        {/* {EmployeeGroupList.map((row, index) => (
                                                          <Option value={row.id}> {row?.data?.database?.main?.GROUP_NAME ? row.data.database.main.GROUP_NAME : '-'}</Option>
                                                        ))} */}
                                                      </div>
                                                    ))
                                                    : null}</td>
                                                </tr>

                                              ))}
                                            </> : null
                                          }
                                        </table>
                                      </div>
                                      :
                                      <>
                                        {
                                          row.selectedApprovalType.toString()
                                        }
                                      </>

                                }
                              </div>

                            </>

                          </TableCell>
                          <TableCell>

                            <IconButton
                              size="small"
                              sx={{ color: "red" }}
                              onClick={() => { deleteItem(row) }}
                              aria-label="upload picture"
                              component="span"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>




                        </TableRow>
                      ))}
                    </>
                  }

                </TableBody>
              </Table>
            </TableContainer>

          </>



          <AntSelect
            className="mgt-5"
            style={{ width: '100%' }}
            placeholder="Add rejection method"
            value={rejectionMethod}
            onChange={(e) => { changeRejectionMethod(e) }}
          >


            <Option value='RN'> Running Number </Option>
            <Option value='RI'> Rivition </Option>
            <Option value='ST'> Static</Option>

          </AntSelect>
          {/* <AntTable columns={columns} dataSource={approvalRow} /> */}
        </Modal>

        <Modal
          visible={openFormConfig}
          title="Form Configuration"
          onOk={formConfigCloss}
          onCancel={formConfigCloss}
          footer={[
            <AntButton danger key="back" onClick={formConfigCloss}>
              Return
            </AntButton>,
            <AntButton danger key="submit" type="primary" onClick={saveSelectedProccesingForm}>
              Save
            </AntButton>
          ]}
        >

          <Space
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="baseline"
          >

            Proccesing Form
            <AntSelect
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a Form"
              optionFilterProp="children"
              value={selectedProccesingForm}
              onChange={(e) => { setFormProccess(e) }}
            >

              {allAvailableForm.map((row, index) => (
                <Option value={row.id}> {row.name}</Option>
              ))}


            </AntSelect>


          </Space>
        </Modal>

        <Modal
          visible={openFormSetting}
          title="Form Setting"
          onOk={formSettingClose}
          onCancel={formSettingClose}
          footer={[
            <AntButton danger key="back" onClick={formSettingClose}>
              Return
            </AntButton>,
            <AntButton danger key="submit" type="primary" onClick={saveSelectedTaskProccesingForm}>
              Save
            </AntButton>
          ]}
        >

          <Space
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="baseline"
          >

            Task processing forms
            <AntSelect
              showSearch
              mode="multiple"
              style={{ width: '100%', minWidth: 200 }}
              placeholder="Select a Form"
              optionFilterProp="children"
              value={selectedTaskProcessingForm}
              onChange={(e) => { setFormTaskProcess(e) }}
            >

              {allAvailableForm.map((row, index) => (
                <Option value={row.id}> {row.name}</Option>
              ))}

            </AntSelect>


          </Space>
          <Space
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="baseline"
          >
            <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={isGeoLocation} onChange={() => setIsGeoLocation(!isGeoLocation)}/>}
                    label="Enable Geo Functionality"
                  />
                </FormGroup>
          </Space>
          {
            isGeoLocation && (
              <Space
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="baseline"
          >

              Geo Locations form
            <AntSelect
              showSearch
              mode="single"
              style={{ width: '100%', minWidth: 200 }}
              placeholder="Select a Form"
              optionFilterProp="children"
              value={geoLocationForm || undefined}
              onChange={(e) => { setgeoLocationForm(e);}}
            >

              {allAvailableForm.map((row, index) => (
                <Option value={row.id}> {row.name}</Option>
              ))}

            </AntSelect>
          </Space>
            )
          }
        </Modal>

        <Modal
          visible={isTestFormOpen}
          title="Form Configuration"
          onOk={() => { openTestForm(false) }}
          onCancel={() => { openTestForm(false) }}
          footer={[
            <AntButton danger key="back" onClick={() => { openTestForm(false) }}>
              Return
            </AntButton>,
            <AntButton danger key="submit" type="primary" onClick={consoleShow}>
              Save
            </AntButton>
          ]}
        >

          <>
            <JsonForms

              schema={schemaa}
              uischema={uischemaa}
              renderers={render}
              cells={cellRender}

            />
          </>
        </Modal>


        {openEmailTemplate && 
          <EmailTemplate selectedFormId={selectedFormId} openEmailTemplateFunction={openEmailTemplateFunction} closeEmailTempleteFunction={closeEmailTempleteFunction} isOpen={openEmailTemplate} schemaComponent={schemaComponent} />
        }



      </React.Fragment>
    </>
  );
};


export default UserSetting
