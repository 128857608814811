/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authenticatedWithUrl, signInWithCredintials,getUserAttribute } from "../redux/slices/users";
import { useKeycloak } from "@react-keycloak/web";

import { getAllLanguage, addLanguageBulk } from "../redux/slices/language";

import { updateSetting, getAppSetting } from "../redux/slices/configSetting";
import {getLanguage} from "../redux/slices/language"

import {
  getFormSchema,
  getFormId,
  getAllGroupRaducer,
} from "../redux/slices/formSettings";

import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";

import auth from "../auth/auth"
import { KeyLogRedirection } from "../components/layout/KeyLogRedirection";
function onChange(checked) {

}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const SignIn = (props) => {
  // const { keycloak, initialized } = useKeycloak();
  // // const navigate = useNavigate();

  // useEffect( () => {
  //   //console.log(keycloak)
  //   const isLoggedIn = keycloak.authenticated;

  //   console.log('emcode sign in L63',isLoggedIn)
  //   // if(empCode){

  //   //     redirectWithUrl(empCode)
       
  //   // }
  // }, [keycloak]);

  const { t, i18n } = useTranslation();


  // const redirectWithUrl = async (myParam) => {
  //  // const hisoryUrl = props?.location?.state?.url ? props.location.state.url : null

  //   const respons = await dispatch(authenticatedWithUrl(myParam))

  //   if (respons.payload.login) {
  //     setStatus('Redirecting...')
  //     if (respons.payload.token) {

  //       auth.login(respons.payload.token, (res) => {
  //         if (res) {
  //           // alert(res)
  //           props.history.push("/dashboard")
  //         }
  //         //
  //       })
  //       //await signIn(respons.payload.token);
  //       //navigate("/dashboard/default");
  //     } else {
  //       setAuthenticating(false)
  //     }
  //   } else {
  //     setAuthenticating(false)
  //   }
    
  // }
  const [isLoading, setLoading] = useState(false)

  const [isAuthenticating, setAuthenticating] = useState(false)

  const [status, setStatus] = useState(null)

  const dispatch = useDispatch();

  const [footerLinks, setFooterLinks] = useState([])
  const [showLogout, setShowLogout] = useState([false])

  

  const checkAuth = async () => {
    if (auth.isAuthenticated()) {
      props.history.push("/dashboard")
    }

    const hisoryEmcode = props?.location?.state?.empCode ? props.location.state.empCode : null



    const hisoryUrl = props?.location?.state?.url ? props.location.state.url : null

    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('emp-code');
    setShowLogout(urlParams.get('logout'));



    if (myParam) {
      setLoading(false)
      setAuthenticating(true)
      setStatus('Authenticating...')
      const respons = await dispatch(authenticatedWithUrl(myParam))

      if (respons.payload.login) {
        setStatus('Redirecting...')
        if (respons.payload.token) {

          auth.login(respons.payload.token, (res) => {
            if (res) {
              // alert(res)
              props.history.push("/dashboard")
            }
            //
          })
          //await signIn(respons.payload.token);
          //navigate("/dashboard/default");
        } else {
          setAuthenticating(false)
        }
      } else {
        setAuthenticating(false)
      }

    } else if (hisoryEmcode) {
      setLoading(false)
      setAuthenticating(true)
      setStatus('Authenticating...')
      const respons = await dispatch(authenticatedWithUrl(hisoryEmcode))

      if (respons.payload.login) {
        setStatus('Redirecting...')
        if (respons.payload.token) {

          auth.login(respons.payload.token, (res) => {
            if (res) {
              // alert(res)
              if (hisoryUrl) {

                props.history.push(hisoryUrl)
              } else {
                props.history.push("/dashboard")
              }


            }
            //
          })
          //await signIn(respons.payload.token);
          //navigate("/dashboard/default");
        } else {
          setAuthenticating(false)
        }
      } else {
        setAuthenticating(false)
      }

    } else {
      setLoading(false)
      setShowLogout(urlParams.get('logout')?true:false);

     
    }
  }


  const onFinish = async (values) => {
    try {
      const logDet = await dispatch(getUserAttribute({
        userKey: values.email,
        attribute:'realM'
      }));

      console.log(logDet)
      if (logDet?.payload?.res) {
      
        props.history.push({
          pathname: "/auth",
          state: {
           email:values.email,
           realM:logDet.payload.res,
          }
        })
             // props.history.push("/auth")
           
      }
      //navigate("/dashboard/default");
    } catch (error) {
      const message = error.message || "Something went wrong";

      // setStatus({ success: false });
      // setErrors({ submit: message });
      // setSubmitting(false);
    }
  };

  const setLanguage = async (code) => {
    const pack = await dispatch(getLanguage(code));

    i18n.addResourceBundle(code, "translation", pack.payload, true, true);

    i18n.changeLanguage(code);
  };


  const getFormData = async () => {

    const formData = await dispatch(getFormId("app_config"));
    if (formData) {
      if (formData.payload) {
        if (formData.payload.length != 0) {
          const formSchema = await dispatch(
            getFormSchema(formData.payload[0].id)
          );

          const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));
          const getAnswer = await dispatch(getAppSetting())

          let arrayOfFooter = []
          let footerAnswer = {}
          if (getAnswer?.payload?.data?.footer) {
            footerAnswer = getAnswer.payload.data.footer

          } else {
            return
          }


          if (gg.payload) {

          }

          if (formSchema.payload) {
            const footerLink = formSchema.payload
            for (const lin of footerLink) {
              const answer = footerAnswer[lin.keyTxt] ? footerAnswer[lin.keyTxt] : null
              if (answer) {
                arrayOfFooter.push({
                  lable: lin.lableTxt,
                  url: answer
                })
              }
            }
            setFooterLinks(arrayOfFooter)
          }
        }
      }
    }

  };

  useEffect(() => {
    checkAuth()
    getFormData()
    setLanguage("th");
  }, []);

  return (

    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h5>{t('signin-header-tagline-left')}</h5>
          </div>

          <div className="header-col header-btn">

          </div>
        </Header>
        {showLogout ? 
        
        <Content className="signin">

        <Row justify="center">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18 }}
            md={{ span: 12 }}
            lg={{ span: 10 }}
          >
            <Title className="mb-15">{t('You are Logged Out!')}</Title>
            <Title className="font-regular text-muted" level={5}>
            {t('logged-out-body-p')}
            </Title>
             

          </Col>

        </Row>
       
      </Content>
        : 
        <Content className="signin">

          <Row justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
            >
              <Title className="mb-15">{t('Sign in')}</Title>
              <Title className="font-regular text-muted" level={5}>
              {t('signin-body-p-credentials')}
              </Title>
                   {/* start new commented form 1 */}

                  {/* <Form
                    layout="vertical"
                    className="row-col"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      className="username"
                      label="Email / Employee code"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email / Employee code" />
                    </Form.Item> */}
                   {/* end new commented form 1 */}

                            {/* start old commented form */}

                                  {/* <Form.Item
                                    className="username"
                                    label="Password"
                                    name="password"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your password!",
                                      },
                                    ]}
                                  >
                                    <Input.Password />
                                  </Form.Item> */}

                                  {/*<Form.Item*/}
                                  {/*  name="remember"*/}
                                  {/*  className="aligin-center"*/}
                                  {/*  valuePropName="checked"*/}
                                  {/*>*/}
                                  {/*  <Switch defaultChecked onChange={onChange} />*/}
                                  {/*  Remember me*/}
                                  {/*</Form.Item> */}
                            {/* end old commented form */}

                   {/* start new commented form 2 */}

                {/* <Form.Item>
                  <Button
                    type="primary"
                    danger
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                   NEXT
                  </Button>
                </Form.Item>
              </Form> */}
                                 {/* staendrt new commented form 2 */}

            </Col>

          </Row>
          {/* {!keycloak.authenticated && (
            <Button
              onClick={() => keycloak.login()}
            >
              Login With Keycloak
            </Button>
          )}
           {!!keycloak.authenticated && (
           <KeyLogRedirection log={keycloak}  history={props.history}/>
          )}
           */}
        </Content>
        
        }

        <Footer>
          <Menu mode="horizontal">
            {footerLinks.map((row) => (

              <Menu.Item><a
                href={row.url}
                className="navlink-cutom"
                style={{ color: '#1414145c' }}
                target="_blank"
              >
                {row.lable}
              </a></Menu.Item>

            ))}

          </Menu>


        </Footer>
      </Layout>
    </>
  )
}
export default SignIn;
