import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { FilterOutlined } from '@ant-design/icons';
import { useDispatch, connect, useSelector } from "react-redux";
import "../style.css";
import {
    openAddExportReducer, getSchemaReducer, getProfileReducer, deleteProfileReducer
} from "../../../../redux/slices/internalExportSetting";
import { Add } from "./Add";
import {
    Drawer,
    Switch, Typography
} from "antd";


import {
    Row,
    Col,
    Button,
    Select,
    Dropdown,
    Menu,
    notification,
    Input,
    Modal,
    Space, Checkbox as AntCheckBox, Form
} from "antd";

import {QuestionCircleOutlined, EditOutlined ,EyeOutlined,DeleteOutlined,UnorderedListOutlined ,CopyOutlined   } from "@ant-design/icons";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const { Option } = Select;





const ExportProfile = (props) => {
    const { Title, Text } = Typography;
    const dispatch = useDispatch();
    const [selectedProfile, setSelectedProfile] = useState(null)

    const profiles = useSelector((state) => {


        let temp = state?.exportInternalState?.profiles ? state.exportInternalState.profiles : []
        //  
        return temp

    })

    const isDeleted = useSelector((state) => {
        return state?.exportInternalState?.isDeleted
    })

    const openAddExport = () => {
        dispatch(openAddExportReducer())
    }

    const deleteProfile = (id) => {
        dispatch(deleteProfileReducer({id}))
    }

    const init = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("id");
        dispatch(getSchemaReducer(myParam))
        dispatch(getProfileReducer({ form: myParam }))
    }

    useEffect( () => {
        if(isDeleted){
            init()
        }
    },[isDeleted])

    useEffect(() => {
        init()
         
    }, []);

    return (
        <>


            <div className="page-continer ">
                <div>
                    <Button onClick={() =>{ setSelectedProfile(null); openAddExport() }}>Add New Profile</Button>
                </div>
                <div>
                    <TableContainer>
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>NAME</TableCell>
                                    <TableCell>KEYS</TableCell>
                                    <TableCell>ACTION</TableCell>
                                    {/* <TableCell>Email</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    profiles ? <>
                                        {
                                            profiles.map((e) => (
                                                <TableRow>
                                                    <TableCell>

                                                    </TableCell>
                                                    <TableCell>

                                                        {e.profileName}
                                                    </TableCell>
                                                    <TableCell>

                                                        {e.elements.toString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        <a style={{marginRight:'5px'}} onClick={() => { setSelectedProfile(e); openAddExport()} }><EditOutlined /></a>
                                                        <a onClick={() => deleteProfile(e._id)}><DeleteOutlined /></a>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </> : null
                                }

                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>



            </div>
            <Add id={selectedProfile} />
        </>
    );
};


export default ExportProfile
