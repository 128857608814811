

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Button } from 'antd';
import { init } from 'i18next';
import moment from 'moment'
import { SmileOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import { PersonAddDisabledOutlined } from '@material-ui/icons';
const { Option } = Select;


const TimeLine = (props) => {
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState([])
    const [enumList, setEnumList] = useState([])
    const [lableName, setLableName] = useState('')
    const [isLoading, loading] = useState(true)

    const [data, setData] = useState({
        start: {},
        paused: [],
        finishd: {}
    })
    const updateData = async (e) => {

        // setValueData(props.data)
        props.handleChange(props.path, e)


    };

    const getGeo = async () => {
        return new Promise((resolve, reject) => {
            let goptions = {
                enableHighAccuracy: true,
                timeout: 9000,
                maximumAge: 0,
            };
            function success(pos) {
                var crd = pos.coords;

                resolve(crd);
                // if(props.data[props.schema.defaultArray.keyTxt]){
                //     props.handleChange(props.path,  props.data[props.schema.defaultArray.keyTxt])
                // }else{

                const respo = {
                    accuracy: crd.accuracy,
                    latitude: crd.latitude,
                    longitude: crd.longitude,
                }

                 
                //props.handleChange(props.path, respo)
                //}

            }

            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                // alert(
                //     "Error!: " + err.message + " \n " + uiText[uiText.lang].pageReload
                // );
                // location.reload();
                reject(err);
            }
            navigator.geolocation.getCurrentPosition(success, error, goptions);
        });
        // 
    };

    const startFunction = async (e) => {
        var currentdate = new Date();
        const tempdate = moment().format('MM-DD-YYYY HH:mm:ss');
        let geo = {}
        let respo = {}
        if (props?.schema?.isLocationEnable) {

            geo = await getGeo()
            respo = {
                accuracy: geo.accuracy,
                latitude: geo.latitude,
                longitude: geo.longitude,
            }
             
        }
         
        const tempdata = {
            ...data,
            start: {
                ...data.start,
                dateTime: props?.schema?.isDateEnable ? tempdate : null,
                isLocationEnable: props?.schema?.isLocationEnable ? respo : null,
            }
        }
        // setValueData(props.data)
        props.handleChange(props.path, tempdata)

        let comData=wholeDataObject
        let obj={}
        comData.database.main[props.schema.keyTxt]=tempdata

         
        props.schema.testFunction(comData)



    };


    const finishedData = async (e) => {
        var currentdate = new Date();
        const tempdate = moment().format('MM-DD-YYYY HH:mm:ss');
        let geo = {}
        let respo = {}
        if (props?.schema?.isLocationEnable) {

            geo = await getGeo()
            respo = {
                accuracy: geo.accuracy,
                latitude: geo.latitude,
                longitude: geo.longitude,
            }
             
        }
         
        const tempdata = {
            ...data,
            finishd: {
                ...data.start,
                dateTime: props?.schema?.isDateEnable ? tempdate : null,
                isLocationEnable: props?.schema?.isLocationEnable ? respo : null,
            }
        }
        // setValueData(props.data)
        props.handleChange(props.path, tempdata)
        let comData=wholeDataObject
        let obj={}
        comData.database.main[props.schema.keyTxt]=tempdata

         
        props.schema.testFunction(comData)


    };


    const pousedData = async (e) => {
        var currentdate = new Date();
        const tempdate = moment().format('MM-DD-YYYY HH:mm:ss');
        let geo = {}
        let respo = {}
        if (props?.schema?.isLocationEnable) {

            geo = await getGeo()
            respo = {
                accuracy: geo.accuracy,
                latitude: geo.latitude,
                longitude: geo.longitude,
            }
             
        }
         
        const tempdata = {
            ...data,
            paused: [
                ...data.paused,
                {
                    pauseTime: tempdate,
                    resumeTime: null,
                    pouseLocation: props?.schema?.isLocationEnable ? respo : null,
                    resumeLocation: null
                }
            ]
        }
        // setValueData(props.data)
        props.handleChange(props.path, tempdata)
        let comData=wholeDataObject
        let obj={}
        comData.database.main[props.schema.keyTxt]=tempdata

         
        props.schema.testFunction(comData)


    };


    const resumeData = async (e) => {
        var currentdate = new Date();



        let geo = {}
        let respo = {}
        if (props?.schema?.isLocationEnable) {

            geo = await getGeo()
            respo = {
                accuracy: geo.accuracy,
                latitude: geo.latitude,
                longitude: geo.longitude,
            }
             
        }
         

        let tempDArray = data.paused
        tempDArray[tempDArray.length - 1] = {
            ...tempDArray[tempDArray.length - 1],
            name: 'newName'
        }

         
        const tempdata = {
            ...data,
            paused:
                data.paused.map((datas, index) => {
                    if (index === data.paused.length - 1) {
                        const tempdate = moment().format('MM-DD-YYYY HH:mm:ss');

                        var a = moment(datas.pauseTime);//now
                        var b = moment(tempdate);

                        return {
                            ...datas,
                            resumeTime: tempdate,
                            resumeDiff: b.diff(a, 'seconds'),
                            resumeLocation: props?.schema?.isLocationEnable ? respo : null,
                            resume: true
                        };
                    } else {
                        return {
                            ...datas,
                            resume: true
                        };
                    }


                })

        }
        // setValueData(props.data)
        props.handleChange(props.path, tempdata)


    };






    const init = async () => {
        loading(true)
         
        setData(props.data ? props.data : {
            start: {},
            finishd: {}, paused: []
        })

        loading(false)

    };

    //

    useEffect(() => {

        init()

        //
    }, [props]);
    return (
        <>
            {
                !isLoading ? <>

                    <Row>
                        <Col style={{ padding: 5 }} xm={24} sm={24} md={12} lg={6} xl={6}>
                            {
                                data?.start?.dateTime || data?.start?.isLocationEnable?.accuracy ?
                                    <Button disabled onClick={startFunction} className='time-line-button' danger>{t('START')}</Button> :
                                    <Button onClick={startFunction} className='time-line-button' danger>{t('START')}</Button>
                            }

                        </Col>
                        {
                            data?.start?.dateTime || data?.start?.isLocationEnable?.accuracy ?
                                <Col style={{ padding: 5 }} xm={24} sm={24} md={12} lg={6} xl={6}>

                                    {
                                        data?.paused.length === 0 || data?.paused[data.paused.length - 1].resume ? <Button onClick={pousedData} className='time-line-button' danger> {t('PAUSED')} </Button> : <Button onClick={resumeData} className='time-line-button' danger>{t('RESUME')}</Button>
                                    }


                                </Col> : null
                        }

                        {
                            data?.start?.dateTime || data?.start?.isLocationEnable?.accuracy ?
                                <Col style={{ padding: 5 }} xm={24} sm={24} md={12} lg={6} xl={6}>
                                    {
                                        data?.finishd?.dateTime || data?.finishd?.isLocationEnable?.accuracy ?
                                            <Button disabled onClick={finishedData} className='time-line-button' danger>{t('FINISHED')}</Button> :
                                            <Button onClick={finishedData} className='time-line-button' danger>{t('FINISHED')}</Button>
                                    }
                                </Col> : null
                        }


                    </Row>



                    <Timeline style={{ marginTop: 15 }}>
                        {
                            data?.start?.dateTime ?
                                <Timeline.Item color="green">
                                    <p>{t('Task start at')} {data?.start?.dateTime ? data.start.dateTime : '-'}</p>
                                    {
                                        data?.start?.isLocationEnable?.accuracy ?
                                            <>
                                                <p>{t('Task start From, Accuracy')} {data?.start?.isLocationEnable?.accuracy ? data.start.isLocationEnable.accuracy : '-'}  latitude {data?.start?.isLocationEnable?.latitude ? data.start.isLocationEnable.latitude : '-'}  longitude {data?.start?.isLocationEnable?.longitude ? data.start.isLocationEnable.longitude : '-'}</p>
                                            </>

                                            : null
                                    }


                                </Timeline.Item> : null
                        }

                        {data.paused.map((row) => (
                            <Timeline.Item color="gray">
                                <p>{t('Task Hold')}</p>
                                {
                                    row.pauseTime ?
                                        <p>{t('Task Paused at')} {row.pauseTime}</p> : null

                                }
                                {
                                    row.pouseLocation ?
                                        <p>{t('Task Paused from, Accuracy')} {row.pouseLocation.accuracy ? row.pouseLocation.accuracy : '-'}  latitude {row.pouseLocation.latitude ? row.pouseLocation.latitude : '-'}  longitude {row.pouseLocation.longitude ? row.pouseLocation.longitude : '-'}</p> : null

                                }

                                {
                                    row.resumeTime ?
                                        <p>{t('Task Resume at')} {row.resumeTime}</p> : null

                                }
                                {
                                    row.resumeLocation ?
                                        <p>{t('Task Resume from, Accuracy')} {row.resumeLocation.accuracy ? row.resumeLocation.accuracy : '-'}  latitude {row.resumeLocation.latitude ? row.resumeLocation.latitude : '-'}  longitude {row.resumeLocation.longitude ? row.resumeLocation.longitude : '-'}</p> : null

                                }
                                {
                                    row.resumeDiff ?
                                        <p>{t('Task Hold time')} (sec.) {row.resumeDiff}</p> : null

                                }



                            </Timeline.Item>
                        ))}


                        {
                            data?.finishd?.dateTime ?
                                <Timeline.Item color="green">
                                    <p>{t('Task Finished at')} {data?.finishd?.dateTime ? data.finishd.dateTime : '-'}</p>
                                    {
                                        data?.finishd?.isLocationEnable?.accuracy ?
                                            <>
                                                <p>{t('Task Finished From, Accuracy')} {data?.finishd?.isLocationEnable?.accuracy ? data.finishd.isLocationEnable.accuracy : '-'}  latitude {data?.finishd?.isLocationEnable?.latitude ? data.finishd.isLocationEnable.latitude : '-'}  longitude {data?.finishd?.isLocationEnable?.longitude ? data.finishd.isLocationEnable.longitude : '-'}</p>
                                            </>

                                            : null
                                    }


                                </Timeline.Item> : null
                        }
                    </Timeline>


                </> : null
            }


        </>



    );
}



export default withJsonFormsControlProps(TimeLine);
