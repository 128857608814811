

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { Checkbox } from 'antd';
import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";
import { Select ,InputNumber  } from 'antd';
import { init } from 'i18next';
import { Button,notification,message } from 'antd';
import moment from "moment";

const { Option } = Select;
const NumberIn = (props) => {

    const dataList = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset

            error = data?.database?.main?data.database.main:null


        }

        return error

    })

    const dispatch = useDispatch();
    const ctx = useJsonForms();

    const [value,setValue]=useState(0)


    const [lable,setLable]=useState(null)

    const [isMoney,setMoney]=useState(false)

    const init = async () => {

        setLable(props?.schema?.schemaElement?.lableTxt?props.schema.schemaElement.lableTxt:null)
        if(props?.schema?.schemaElement?.isMoney){
            setMoney(true)
        }else{
            setMoney(false)
        }
        if (props.data) {
            setValue(props.data)

        }
         
         
    }



    const onChange = async (e) => {

        props.handleChange(props.path, e)

    };

    //

    useEffect(() => {
        //
        init()

        //
    }, [props]);
    return (
        <>

            <div>
                {lable?lable:null}
            </div>

            <div>
                {
                    isMoney? <InputNumber
                        value={value}
                        style={{width:'100%'}}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={onChange}
                    />:<InputNumber
                        value={value}
                        style={{width:'100%'}}  onChange={onChange} />
                }

            </div>


            {/**/}

        </>



    );
}



export default withJsonFormsControlProps(NumberIn);
