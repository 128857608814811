

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select, Col, Row, Input, Alert, Spin, Button } from 'antd';
import { useDispatch, connect, useSelector } from "react-redux";
import { init } from 'i18next';
import $ from 'jquery';
import { useCountdownTimer } from 'use-countdown-timer';
import {
    closeClockOutMenu, shareLocationReducer
} from "../../../../../redux/slices/attandance/markAttandanceSetting";
import { CountDown } from './CountDown';
import {
    selectGroup
} from "../../../../../redux/slices/element/elementReducer";


import {
    ShareLocation
} from "../../../../attandence/components/ShareLocation";
const { Option } = Select;

const Timmer = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();


    const [lable, setLable] = useState(null)

    const sharedTime = useSelector((state) => {


        let error = null
        if (state?.markAttandance?.locationShareTime) {

            let ttt = state.markAttandance.locationShareTime

            error = ttt  // 9:00 AM

            // now()


        }
        return error

    })


    function now() {

    }

    let diffe = null
    const initTick = now();

    const displayTimeLeft = seconds => {
        let minutesLeft = Math.floor(seconds / 60);
        let secondsLeft = seconds % 60;
        minutesLeft = minutesLeft.toString().length === 1 ? "0" + minutesLeft : minutesLeft;
        secondsLeft = secondsLeft.toString().length === 1 ? "0" + secondsLeft : secondsLeft;
        return `${minutesLeft}:${secondsLeft}`;
    }
    const [timeLeft, setTimeLeft] = useState('');
    const [remtimmer, setRemaininTime] = useState(null)
    const [countT, setCountT] = useState(null)
    //const [timeRemaining, setTimeRemaining] = useState(50000);
    useEffect(() => {

        // setInterval(() => {

        //     console.log('sharedTime',remtimmer)
        // }, 1000);
        console.log('sharedTime', remtimmer)
    }, [remtimmer]);

    // function tick() {

    //     var count = remtimmer;
    //     var delay = 1000;
    //     var remaining = (count - (now() - initTick)) / 1000;

    //     remaining = remaining >= 0 ? remaining : 0;
    //     var secs = remaining.toFixed(2);
    //     console.log(secs)
    //     if (remaining) setTimeout(tick, delay);
    // }

    // useEffect(() => {

    //     if (sharedTime) {

    //         var date1 = new Date(sharedTime); // 9:00 AM
    //         var date2 = new Date(); // 5:00 PM

    //         // the following is to handle cases where the times are on the opposite side of
    //         // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM


    //         var minutes = Math.floor(sharedTime / 60000);


    //         var timoutTime = props?.schema?.schemaElement?.absaulteData?.showingElements ? props.schema.schemaElement.absaulteData.showingElements : null;
    //         if (timoutTime) {
    //             if (minutes > timoutTime) {
    //                 window.location.reload();
    //             } else {

    //                setRemaininTime(sharedTime)
    //             }
    //         }
    //     }

    // }, [props]);

    useEffect(() => {
        var timoutTime = props?.schema?.schemaElement?.absaulteData?.showingElements ? props.schema.schemaElement.absaulteData.showingElements : null;
        setCountT(timoutTime)

    }, [props]);
    return (
        <div className='timmer'>
            <div className='timmer-container'>
                <span className='timmer-static'>
                    {t('After')}
                </span>
                <CountDown
                    timmer={sharedTime} timoutTime={countT} />
                <span className='timmer-static'>
                {t('this page will be refreshed')}
                    
                </span>


            </div>

        </div>



    );
}



export default withJsonFormsControlProps(Timmer);
