import React, { useState, useEffect } from "react";
import LinesEllipsis from 'react-lines-ellipsis'

import {
    useDispatch
} from "react-redux";
import { useHistory } from "react-router-dom";
import auth from "../../auth/auth.js";
import { useTranslation } from "react-i18next";
import { setActiveMenu, setActiveSubMenu } from "../../redux/slices/sideNevSetting.js";

export function DashboardApp(props) {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()

    const defualtImg = 'https://pngimg.com/uploads/chrome_logo/chrome_logo_PNG7.png'

    const [lable, setLable] = useState(null)
    const [image, setImage] = useState(defualtImg)

    const [url, setUrl] = useState('#')
    const [openNew, setOpenNew] = useState(false)
    const browseLink = () => {
        if(localStorage.getItem("token")){
            
            if (openNew) {
                window.open(
                    url,
                    '_blank' // <- This is what makes it open in a new window.
                    );
                } else {
                    if (['MAS HR', 'Mer Tracker'].includes(lable)){
                        dispatch(setActiveMenu(['project']))
                        dispatch(setActiveSubMenu('5a378425-9231-4c8e-a925-2f99ca6dba59'))
                    } else {
                        dispatch(setActiveMenu(['employee']))
                        dispatch(setActiveSubMenu('Attendance'))
                    }
            history.push(url);
            
        }
    }else{ 
        alert(t('กรุณาเข้าสู่ระบบอีกครั้ง | Please login again'))
            
        auth.logout((res) => {
            if (!res) {
              // alert(res)
              history.push("/sign-in?logout=true");
            }

            //
          });
    }

    }
    useEffect(() => {

        const el = props.element ? props.element : null
        if (el) {
            // console.log('DashboardApp', el)
            setLable(el.Name ? el.Name : null)
            setUrl(el.Url ? el.Url : '#')
            setImage(el?.Picture?.location ? el.Picture.location : defualtImg)
            if (el.Open_in_new_window) {
                if (el.Open_in_new_window[0]) {
                    if (el.Open_in_new_window[0] == 'true') {
                        setOpenNew(true)
                    } else {
                        setOpenNew(false)
                    }
                } else {
                    setOpenNew(false)
                }
            } else {
                setOpenNew(false)
            }

        }

    }, [props]);

// add cache for testing perpose 
// useEffect(() => {
//     if ("caches" in window) {
//         // Opening given cache and putting our data into it
//         const data = new Response(JSON.stringify('hiii'));
//         caches.open('test').then((cache) => {
//             cache.put(url, data);
//         });
//         return
//     }
// },[])

    return (

        <div className="dashboard-app" onClick={browseLink}>
            <div className="dashboard-app-img">
                <img src={image} />

            </div>
            <div className="dashboard-app-lable">
                <span>
                    {
                        lable?lable:null
                    }
                    
                </span>
            </div>
        </div>

    );
}