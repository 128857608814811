import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  getUserById,
  getLogedInUserData,
} from "../../../../redux/slices/users";
import { submitFormAnswer } from "../../../../redux/slices/formSettings";
import { useDispatch } from "react-redux";
import "../style.css";
import { FullScreenLoader } from "../../../../components/elements/FullScreenLoader";
import { Alert as AntAl, Button, Space, Spin, notification } from "antd";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { useTranslation } from "react-i18next";
import { JsonForms } from "@jsonforms/react";
import moment from "moment";
import { Divider, Tag } from "antd";
import {
  UserOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Avatar, Badge } from "antd";
import Clearing from "../custom/clearing/Clearing";
import GeoLocation from "../custom/geoLocation/GeoLocation";
import NumberIn from "../custom/number/NumberIn";
import FileControl from "../custom/storage/FileControl";
import HtmlCamera from "../custom/storage/HtmlCamera";
import ShopControl from "../custom/shop/ShopControl";
import ClockedControl from "../custom/storage/ClockedControl";
import ClockedEdit from "../custom/storage/ClockedEdit";
import CustomSorting from "../custom/sorting/CustomSorting";
import DynamicStep from "../custom/element/DynamicStep";
import AutoMoveToNext from "../custom/element/AutoMoveToNext";

import DataGrid from "../custom/dataGrid/DataGrid";
import LableTemp from "../custom/lable/LableTemp";
import DatePicker from "../custom/Select/DatePicker";
import MultiSelect from "../custom/Select/MultiSelect";
import MultiSelectTree from "../custom/Select/MultiSelectTree";
import SingleSelect from "../custom/Select/SingleSelect";
import ShareLocationJson from "../custom/location/ShareLocationJson";
import ViewData from "../custom/viewdata/ViewData";
import Repeater from "../custom/repeater/Repeater";
import { SettingOutlined } from "@ant-design/icons";
import CalculationArray from "../custom/calculation/CalculationArray";
import CheckCustom from "../custom/check/CheckCustom";
import FormTemplate from "../custom/template/FormTemplate";
import { changeDataReducer } from "../../../../redux/slices/ruleElement";
import Calculation from "../custom/calculation/Calculation";

import TimeLine from "../custom/timeLine/TimeLine";

import { rankWith, scopeEndsWith } from "@jsonforms/core";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

export function FormInterfaceDynamicEdit(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState(null);
  const [render, setRender] = useState([...materialRenderers]);
  const [cellRender, setCellRender] = useState([...materialCells]);
  const [isErrorShow, setErrorShow] = useState(false);
  const [dataBaseData, setDataBaseData] = useState([]);

  const [formDetails, setFormDetails] = useState({});

  const [loadingFormData, setLoadingFormData] = useState(false);

  const [totalFields, setTotalFields] = useState([]);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [renderSuccess, setRenderSuccess] = useState(true);

  const [formSchema, setFormSchema] = useState({
    schema: {
      properties: {
        name: {
          type: "string",
          title: "First Name",
        },
        blabla: {
          type: "string",
          title: "First Name",
        },
      },
    },
    uischema: {
      properties: {
        name: {
          type: "string",
        },
        blabla: {
          type: "string",
        },
      },
    },
    data: {},
  });

  const createNonGroupElement = (mainEl) => {
    let schemaObj = {};
    let typeOfElement = "string";
    if (mainEl.type === "drop" || mainEl.type === "radio") {
      typeOfElement = "string";
    }
    let eleObj = {};
    if (mainEl.type === "drop" || mainEl.type === "radio") {
      schemaObj = {
        type: typeOfElement,
        oneOf: mainEl.enum ? mainEl.enum : [],
        title: t(mainEl.lableTxt),
      };
    } else if (mainEl.type === "date" || mainEl.type === "date_diff") {
      schemaObj = {
        type: typeOfElement,
        format: "date",
        title: t(mainEl.lableTxt),
      };
    } else if (mainEl.type === "check") {
      schemaObj = {
        type: "array",
        uniqueItems: true,
        items: {
          type: "string",
          oneOf: mainEl.enum ? mainEl.enum : [],
        },
        title: t(mainEl.lableTxt),
      };
    } else if (mainEl.type === "location") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(mainEl.keyTxt)
        ),
        renderer: GeoLocation,
      });
      setRender(renderers);
      return false;
    } else if (mainEl.type === "File") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(mainEl.keyTxt)
        ),
        renderer: FileControl,
      });
      schemaObj = {
        type: "string",
        storeMethod: mainEl.fileStoreType,
        s3Bucket: mainEl.s3Bucket,
        s3Dir: mainEl.s3Dir,
        selectedFileType: mainEl.selectedFileType
          ? mainEl.selectedFileType
          : null,
        captureType: mainEl.captureType ? mainEl.captureType : null,
      };
    } else {
      schemaObj = {
        type: typeOfElement,
        title: t(mainEl.lableTxt),
      };
    }
    return schemaObj;
  };

  const createGroupElement = (elementGroup, schemaElement) => {
    let wrappingObj = {
      schema: {},
      ui: [],
    };

    wrappingObj.schema["title"] = t(elementGroup.groupName);

    if (schemaElement.length !== 0) {
      let eleArra = [];
      if (elementGroup.groupType === "array") {
        wrappingObj.schema["type"] = "array";
        wrappingObj.schema["items"] = {};
        wrappingObj.schema.items["type"] = "object";
        wrappingObj.schema.items["properties"] = {};
        for (const elementData of schemaElement) {
          let typeOfElement = "string";
          if (elementData.type === "drop" || elementData.type === "radio") {
            typeOfElement = "string";
          }
          if (elementData.group === elementGroup.groupId) {
            let eleObj = {};
            let uiObj = {
              scope: "#/properties/" + elementData.keyTxt,
              type: "Control",
            };
            wrappingObj.ui.push(uiObj);
            if (elementData.type === "drop" || elementData.type === "radio") {
              let showingEnum = [];
              if (elementData?.isTranslated) {
                if (elementData.enum) {
                  for (const trEn of elementData.enum) {
                    let ob = {
                      const: trEn.const,
                      title: t(trEn.title ? trEn.title : "-"),
                    };
                    showingEnum.push(ob);
                  }
                }
              } else {
                showingEnum = elementData.enum ? elementData.enum : [];
              }
              if (elementData.selectType == "multi") {
                if (elementData.grouped) {
                  let renderers = render;
                  renderers.push({
                    tester: rankWith(
                      3, //increase rank as needed
                      scopeEndsWith(elementData.keyTxt)
                    ),
                    renderer: MultiSelectTree,
                  });

                  setRender(renderers);
                  wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                    enumList: showingEnum,
                    schemaElement: elementData,
                  };
                } else {
                  let renderers = render;
                  renderers.push({
                    tester: rankWith(
                      3, //increase rank as needed
                      scopeEndsWith(elementData.keyTxt)
                    ),
                    renderer: MultiSelect,
                  });

                  setRender(renderers);
                  wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                    enumList: showingEnum,
                    schemaElement: elementData,
                  };
                }
              } else {
                let renderers = render;
                renderers.push({
                  tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(elementData.keyTxt)
                  ),
                  renderer: SingleSelect,
                });
                setRender(renderers);
                wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                  enumList: showingEnum,
                  schemaElement: elementData,
                };
              }
            } else if (elementData.type === "number") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: NumberIn,
              });

              setRender(renderers);
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                schemaElement: elementData,
              };
            } else if (elementData.type === "dropTree") {
              let showingEnum = [];
              if (elementData?.isTranslated) {
                if (elementData.enum) {
                  for (const trEn of elementData.enum) {
                    let ob = {
                      const: trEn.const,
                      title: t(trEn.title ? trEn.title : "-"),
                    };
                    showingEnum.push(ob);
                  }
                }
              } else {
                showingEnum = elementData.enum ? elementData.enum : [];
              }

              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: MultiSelectTree,
              });

              setRender(renderers);
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                enumList: showingEnum,
                schemaElement: elementData,
              };
            } else if (
              elementData.type === "date" ||
              elementData.type === "date_diff"
            ) {
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                type: typeOfElement,
                format: "date",
                title: t(elementData.lableTxt),
              };
            } else if (elementData.type === "check") {
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  oneOf: elementData.enum ? elementData.enum : [],
                },
                title: t(elementData.lableTxt),
              };
            } else if (elementData.type === "location") {
              let renderers = render;

              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: GeoLocation,
              });

              setRender(renderers);
              return false;
            } else if (elementData.type === "calculation") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: Calculation,
              });

              setRender(renderers);
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                type: "number",
                title: t(elementData.lableTxt),
                info: elementData,
              };
            } else if (elementData.type === "File") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: FileControl,
              });
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                type: "string",
                storeMethod: elementData.fileStoreType,
                s3Bucket: elementData.s3Bucket,
                s3Dir: elementData.s3Dir,
              };
            } else if (elementData.type === "html_camera") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: HtmlCamera,
              });
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                title: t(elementData.lableTxt),
                storeMethod: elementData.fileStoreType,
                s3Bucket: elementData.s3Bucket,
                s3Dir: elementData.s3Dir,
                selectedFileType: schemaElement.selectedFileType
                  ? schemaElement.selectedFileType
                  : null,
                mulitpleImage:
                  schemaElement.imageTakenCount &&
                  schemaElement.imageTakenCount == "mulitple"
                    ? true
                    : false,
                schemaEl: schemaElement,
              };
            } else {
              wrappingObj.schema.items["properties"][elementData.keyTxt] = {
                type: typeOfElement,
                title: t(elementData.lableTxt),
              };
            }

            // wrappingObj.ui.push(eleObj)
          }
        }
      } else {
        wrappingObj.schema["properties"] = {};
        for (const elementData of schemaElement) {
          let typeOfElement = "string";
          if (elementData.type === "drop" || elementData.type === "radio") {
            typeOfElement = "string";
          }
          if (elementData.group === elementGroup.groupId) {
            if (elementData.type === "drop" || elementData.type === "radio") {
              wrappingObj.schema["properties"][elementData.keyTxt] = {
                type: typeOfElement,
                oneOf: elementData.enum ? elementData.enum : [],
                title: t(elementData.lableTxt),
              };
            } else if (
              elementData.type === "date" ||
              elementData.type === "date_diff"
            ) {
              wrappingObj.schema["properties"][elementData.keyTxt] = {
                type: typeOfElement,
                format: "date",
                title: t(elementData.lableTxt),
              };
            } else if (elementData.type === "check") {
              wrappingObj.schema["properties"][elementData.keyTxt] = {
                type: "array",
                uniqueItems: true,
                items: {
                  type: "string",
                  oneOf: elementData.enum ? elementData.enum : [],
                },
                title: t(elementData.lableTxt),
              };
            } else if (elementData.type === "location") {
              let renderers = render;

              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: GeoLocation,
              });

              setRender(renderers);
              return false;
            } else if (elementData.type === "File") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: FileControl,
              });
              wrappingObj = {
                storeMethod: elementData.fileStoreType,
                s3Bucket: elementData.s3Bucket,
                s3Dir: elementData.s3Dir,
                selectedFileType: schemaElement.selectedFileType
                  ? schemaElement.selectedFileType
                  : null,
              };
            } else if (elementData.type === "html_camera") {
              let renderers = render;
              renderers.push({
                tester: rankWith(
                  3, //increase rank as needed
                  scopeEndsWith(elementData.keyTxt)
                ),
                renderer: HtmlCamera,
              });
              wrappingObj = {
                storeMethod: elementData.fileStoreType,
                s3Bucket: elementData.s3Bucket,
                s3Dir: elementData.s3Dir,
                selectedFileType: schemaElement.selectedFileType
                  ? schemaElement.selectedFileType
                  : null,
                mulitpleImage:
                  schemaElement.imageTakenCount &&
                  schemaElement.imageTakenCount == "mulitple"
                    ? true
                    : false,
                schemaEl: schemaElement,
              };
            } else {
              wrappingObj.schema["properties"][elementData.keyTxt] = {
                type: typeOfElement,
                title: t(elementData.lableTxt),
              };
            }

            // wrappingObj.ui.push(eleObj)
          }
        }
      }
    }
    return wrappingObj;
  };

  const createGroupElementObj = (schemaElement) => {
    let wrappingObj = {
      schema: {},
      ui: {},
    };

    let eleArra = [];

    wrappingObj.schema = {};

    let typeOfElement = "string";
    if (schemaElement.type === "drop" || schemaElement.type === "radio") {
      typeOfElement = "string";
    }

    if (schemaElement.type === "drop" || schemaElement.type === "radio") {
      let showingEnum = [];
      if (schemaElement?.isTranslated) {
        if (schemaElement.enum) {
          for (const trEn of schemaElement.enum) {
            let ob = {
              const: trEn.const,
              title: t(trEn.title ? trEn.title : "-"),
            };
            showingEnum.push(ob);
          }
        }
      } else {
        showingEnum = schemaElement.enum ? schemaElement.enum : [];
      }
      if (schemaElement.selectType == "multi") {
        let renderers = render;
        renderers.push({
          tester: rankWith(
            3, //increase rank as needed
            scopeEndsWith(schemaElement.keyTxt)
          ),
          renderer: MultiSelect,
        });

        wrappingObj.schema = {
          enumList: showingEnum,
          schemaElement: schemaElement,
          disable: schemaElement.isDisableInEdit ? true : false,
        };
      } else {
        let renderers = render;
        renderers.push({
          tester: rankWith(
            3, //increase rank as needed
            scopeEndsWith(schemaElement.keyTxt)
          ),
          renderer: SingleSelect,
        });
        wrappingObj.schema = {
          enumList: showingEnum,
          schemaElement: schemaElement,
          disable: schemaElement.isDisableInEdit ? true : false,
        };
      }
    } else if (schemaElement.type === "Form") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: SingleSelect,
      });
      wrappingObj.schema = {
        enumList: schemaElement.enum ? schemaElement.enum : [],
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "timeLine") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: TimeLine,
      });
      wrappingObj.schema = {
        isDateEnable: schemaElement.isDateEnable,
        isLocationEnable: schemaElement.isLocationEnable,
        isResumeEnable: schemaElement.isResumeEnable,
        testFunction: testFunction,
        keyTxt: schemaElement.keyTxt,
      };
    } else if (
      schemaElement.type === "date" ||
      schemaElement.type === "date_diff"
    ) {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: DatePicker,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "check") {
      let uenuArray = [];
      for (const es of schemaElement.enum) {
        uenuArray.push({
          const: es.const,
          title: t(es.title),
        });
      }
      wrappingObj.schema = {
        type: "array",
        uniqueItems: true,
        items: {
          type: "string",
          oneOf: uenuArray ? uenuArray : [],
        },
        title: t(schemaElement.lableTxt),
      };
    } else if (schemaElement.type === "returnAll") {
      const ssss = props?.elementGroup ? props.elementGroup : [];
      const aaaa = props?.jsonSchema ? props.jsonSchema : [];

      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: CheckCustom,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
        allSchema: aaaa,
        allGroup: ssss,
        addBulkDataList: targeGroupChange,
      };
      wrappingObj.addBulkDataList = targeGroupChange;
    } else if (schemaElement.type === "sortBy") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: CustomSorting,
      });

      wrappingObj.schema = {
        schemaElement: schemaElement,
        addBulkDataList: targeGroupChange,
      };
      wrappingObj.addBulkDataList = targeGroupChange;
    } else if (schemaElement.type === "File") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: FileControl,
      });
      wrappingObj.schema = {
        storeMethod: schemaElement.fileStoreType,
        s3Bucket: schemaElement.s3Bucket,
        s3Dir: schemaElement.s3Dir,
        selectedFileType: schemaElement.selectedFileType
          ? schemaElement.selectedFileType
          : null,
        captureType: schemaElement.captureType
          ? schemaElement.captureType
          : null,
      };
    } else if (schemaElement.type === "html_camera") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: HtmlCamera,
      });
      wrappingObj.schema = {
        storeMethod: schemaElement.fileStoreType,
        s3Bucket: schemaElement.s3Bucket,
        s3Dir: schemaElement.s3Dir,
        selectedFileType: schemaElement.selectedFileType
          ? schemaElement.selectedFileType
          : null,
        captureType: schemaElement.captureType
          ? schemaElement.captureType
          : null,
        mulitpleImage:
          schemaElement.imageTakenCount &&
          schemaElement.imageTakenCount == "mulitple"
            ? true
            : false,
        htmlLable: schemaElement.lableTxt,
        schemaEl: schemaElement,
      };
    } else if (schemaElement.type === "data_grid") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: DataGrid,
      });
      wrappingObj.schema = {
        defaultArray: schemaElement.arrayValue,
        detail: schemaElement,
      };
    } else if (schemaElement.type === "clearing") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt),
          formSchema
        ),
        renderer: Clearing,
      });
      wrappingObj.schema = {
        info: schemaElement,
      };
    } else if (schemaElement.type === "stepper") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: DynamicStep,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "move_to_next_stage") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: AutoMoveToNext,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "answer_view") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: ViewData,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "locationShare") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: ShareLocationJson,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "cloacked") {
      //formSubmit

      let renderers = render;
      if (props.normalView) {
        //console.log('normalView')
        renderers.push({
          tester: rankWith(
            3, //increase rank as needed
            scopeEndsWith(schemaElement.keyTxt)
          ),
          renderer: ClockedEdit,
        });
      } else {
        //console.log('clockedView')
        renderers.push({
          tester: rankWith(
            3, //increase rank as needed
            scopeEndsWith(schemaElement.keyTxt)
          ),
          renderer: ClockedControl,
        });
      }

      wrappingObj.schema = {
        schemaElement: schemaElement,
        storeMethod: schemaElement.fileStoreType,
        s3Bucket: schemaElement.s3Bucket,
        s3Dir: schemaElement.s3Dir,
        selectedFileType: schemaElement.selectedFileType
          ? schemaElement.selectedFileType
          : null,
        captureType: schemaElement.captureType
          ? schemaElement.captureType
          : null,
        mulitpleImage:
          schemaElement.imageTakenCount &&
          schemaElement.imageTakenCount == "mulitple"
            ? true
            : false,
        htmlLable: schemaElement.lableTxt,
        testFunction: testFunction,
        clockOut: true,
        formSubmit: props.formSubmit ? props.formSubmit : false,
      };
    } else if (schemaElement.type === "dynamicContent") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: FormTemplate,
      });
      wrappingObj.schema = {
        schemaElement: schemaElement,
      };
    } else if (schemaElement.type === "shops") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: ShopControl,
      });
      wrappingObj.schema = {
        dataobj: schemaElement,
        disable: schemaElement.isDisableInEdit ? true : false,
      };
    } else if (schemaElement.type === "lable_draft") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: LableTemp,
      });
      wrappingObj.schema = {
        type: "string",
        title: schemaElement.lableTxt + " : " + schemaElement.arrayValue,
        label: schemaElement.arrayValue,
        display: schemaElement.defaultLableValue,
        all: schemaElement,
      };
    } else if (schemaElement.showInHeaderTaskProcess) {
        let renderers = render;
        renderers.push({
          tester: rankWith(
            3, //increase rank as needed
            scopeEndsWith(schemaElement.keyTxt)
          ),
          renderer: LableTemp,
        });
        wrappingObj.schema = {
          type: "string",
          title: schemaElement.lableTxt + " : " + schemaElement.arrayValue,
          label: schemaElement.arrayValue,
          display: schemaElement.defaultLableValue,
          all: schemaElement,
        };
      } else if (schemaElement.type === "repeat") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: Repeater,
      });
    } else if (schemaElement.type === "location") {
      let renderers = render;
      renderers.push({
        tester: rankWith(
          3, //increase rank as needed
          scopeEndsWith(schemaElement.keyTxt)
        ),
        renderer: GeoLocation,
      });
      wrappingObj.schema = {
        defaultArray: schemaElement,
      };
    } else if (schemaElement.type === "number") {
      wrappingObj.schema = {
        type: "integer",
        title: t(schemaElement.lableTxt),
      };
    } else {
      wrappingObj.schema = {
        type: typeOfElement,
        title: t(schemaElement.lableTxt),
      };
    }

    return wrappingObj;
  };

  const createGroupLable = (schemaElement) => {
    let wrappingObj = {
      schema: {},
      ui: {},
    };

    wrappingObj.schema = {};

    let renderers = render;
    renderers.push({
      tester: rankWith(
        3, //increase rank as needed
        scopeEndsWith(schemaElement.groupName)
      ),
      renderer: LableTemp,
    });
    wrappingObj.schema = {
      type: "string",
      title: schemaElement.groupName,
      label: schemaElement.groupName,
      display: schemaElement.groupName,
    };

    return wrappingObj;
  };

  const testFunction = (data) => {
    props.formDraftEditTimeLine(data);
  };

  const getUserInformationDatabase = async (id) => {
    return await dispatch(getUserById(id));
  };

  const createForm = async () => {
    setLoadingFormData(true);

    const session = localStorage.getItem("token");
    let userData = {};
    let profile = {};
    if (session) {
      userData = await dispatch(getLogedInUserData());
      if (userData.payload) {
        profile = userData.payload.profile;
        if (profile?.userDB?.userRole) {
          setUserRole(profile.userDB.userRole);
        }
      }
    }

    const group = props.elementGroup;
    const schemaElement = props.jsonSchema;
    setTotalFields(schemaElement.filter((e) => e.type === "total"));
    let schemaObject = {};

    schemaObject["properties"] = {
      bitrix: {
        properties: {
          main: {
            title: "",
            properties: {},
            required: [],
          },
        },
      },
      database: {
        properties: {
          main: {
            title: "",
            properties: {},
            required: [],
          },
        },
      },
    };
    schemaObject["type"] = "object";
    let uiSchema = {
      type: "VerticalLayout",
      elements: [],
    };
    let dataBody = {
      bitrix: {
        main: {},
      },
      database: {
        main: props?.data?.data?.database?.main
          ? props.data.data.database.main
          : {},
      },
    };

    const mainField = schemaElement.filter((e) => e.group === null && e.isHideInEdit !== true);

    // //console.log('mainField',mainField)

    if (mainField.length !== 0 && !props.data.isDuplicate) {
      const calCount = 2;
      for (const mainEl of mainField) {
        const schemaObj = createNonGroupElement(mainEl);

        if (schemaObj) {
          if (mainEl.fieldPlace === "bitrix" || mainEl.fieldPlace === "") {
            schemaObject.properties.bitrix.properties.main.properties[
              mainEl.keyTxt
            ] = schemaObj;
          } else {
            schemaObject.properties.database.properties.main.properties[
              mainEl.keyTxt
            ] = schemaObj;
          }
        }
      }

      const chunkSize = 2;
      for (let i = 0; i < mainField.length; i += chunkSize) {
        const chunk = mainField.slice(i, i + chunkSize);
        let mainGroupSchema = {
          type: "HorizontalLayout",
          elements: [],
        };

        let rule = {
          effect: "DISABLE",
          condition: {
            scope: "#/properties/dead",
            schema: {
              const: true,
            },
          },
        };
        for (const mainEl of chunk) {
          let scope = "";
          if (mainEl.fieldPlace === "bitrix" || mainEl.fieldPlace === "") {
            scope =
              "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt;
          } else {
            scope =
              "#/properties/database/properties/main/properties/" +
              mainEl.keyTxt;
          }

          if (mainEl.disableEl) {
            rule = {
              effect: "ENABLE",
              condition: {
                scope: "#/properties/dead",
                schema: {
                  const: true,
                },
              },
            };
          }

          mainGroupSchema.elements.push({
            type: "Control",
            scope: scope,
            rule: rule,
          });
        }
        uiSchema.elements.push(mainGroupSchema);
      }
    }

    for (const elementGroup of group) {
      if (elementGroup.groupType === "object") {
        for (const elementData of schemaElement) {
          if(elementData?.isHideInEdit === true){
            continue
          }
          let typeOfElement = "string";
          if (elementData.type === "drop" || elementData.type === "radio") {
            typeOfElement = "string";
          }
          if (elementData.group === elementGroup.groupId) {
            const resGropEn = createGroupElementObj(elementData);

            if (resGropEn) {
              if (
                elementData.fieldPlace === "bitrix" ||
                elementData.fieldPlace === ""
              ) {
                schemaObject.properties.bitrix.properties.main.properties[
                  elementData.keyTxt
                ] = resGropEn.schema;
                if (elementData.isRequired) {
                  schemaObject.properties.bitrix.properties.main.required.push(
                    elementData.keyTxt
                  );
                }

                if (elementData.reference) {
                  if (profile.userBit) {
                    dataBody.bitrix.main = {
                      ...dataBody.bitrix.main,
                      [elementData.keyTxt]: profile.userBit[
                        elementData.reference
                      ]
                        ? profile.userBit[elementData.reference]
                        : "-",
                    };
                  }
                }

                //
              } else {
                schemaObject.properties.database.properties.main.properties[
                  elementData.keyTxt
                ] = resGropEn.schema;

                if (elementData.isRequired) {
                  schemaObject.properties.database.properties.main.required.push(
                    elementData.keyTxt
                  );
                }
                if (elementData.reference) {
                  if (profile.userBit) {
                    dataBody.database.main = {
                      ...dataBody.database.main,
                      [elementData.keyTxt]: profile.userBit[
                        elementData.reference
                      ]
                        ? profile.userBit[elementData.reference]
                        : "-",
                    };
                  }
                }
              }

              // schemaObject.properties.main.properties[elementData.keyTxt] = resGropEn.schema
            }
          }
        }
        uiSchema.elements.push({
          type: "Label",
          // "text": elementGroup.groupLable
        });
        const groupBelongElemeny = schemaElement.filter(
          (e) => e.group === elementGroup.groupId
        );

        const perChunk = elementGroup.groupColtxt
          ? elementGroup.groupColtxt
          : 1;
        const resultArraySplices = groupBelongElemeny.reduce(
          (resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          },
          []
        );

        for (const splice of resultArraySplices) {
          let mainGroupSchema = {
            type: "HorizontalLayout",
            elements: [],
          };

          for (const mainEl of splice) {
            let scope = "";
            let option = {};

            let rule = {
              effect: "DISABLE",
              condition: {
                scope: "#/properties/dead",
                schema: {
                  const: true,
                },
              },
            };
            if (mainEl.fieldPlace === "bitrix" || mainEl.fieldPlace === "") {
              // scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
              scope =
                "#/properties/bitrix/properties/main/properties/" +
                mainEl.keyTxt;
            } else {
              scope =
                "#/properties/database/properties/main/properties/" +
                mainEl.keyTxt;
            }

            if (mainEl.type === "date") {
              option["format"] = "date";
              option["views"] = ["year", "month", "date"];
              option["dateFormat"] = "YYYY-MM-DD";
              option["dateSaveFormat"] = "YYYY-MM-DD";
            }

            if (mainEl.multiLine) {
              if (mainEl.multiLine == "M") {
                option["multi"] = true;
              }
            }

            if (mainEl.disableEl) {
              rule = {
                effect: "ENABLE",
                condition: {
                  scope: "#/properties/dead",
                  schema: {
                    const: true,
                  },
                },
              };
            }
            if (mainEl.isHideInEdit) {
              rule = {
                effect: "SHOW",
                condition: {
                  scope: "#/properties/dead",
                  schema: {
                    const: true,
                  },
                },
              };
            }

            mainGroupSchema.elements.push({
              type: "Control",
              scope: scope,
              options: option,
              rule: rule,
            });
          }

          uiSchema.elements.push(mainGroupSchema);
        }
      } else if (elementGroup.groupType === "category") {
        uiSchema.elements.push({
          type: "Label",
          // "text": elementGroup.groupLable
        });
        // uiSchema.elements.push({
        //     "type": "Categorization",
        //     "text": elementGroup.groupLable
        // })

        let catObj = {
          type: "Categorization",
          elements: [],
          options: {
            variant: "stepper",
            showNavButtons: true,
          },
        };
        let relatedStage = [];
        //console.log("props.normalView:", props.normalView);
        if (props.normalView) {
          //alert()
          const tempRelatedStage = group.filter(
            (e) =>
              e.selectedCategory === elementGroup.groupId &&
              e.groupType === "stage"
          );
          for (let trs of tempRelatedStage) {
            const isNext = schemaElement.find(
              (e) => e.group === trs.groupId && e.type == "move_to_next_stage"
            );
            const shareLocation = schemaElement.find(
              (e) => e.group === trs.groupId && e.type == "locationShare"
            );
            const isElement = schemaElement.find(
              (e) => e.group === trs.groupId
            );
            if (!isNext && isElement && !shareLocation) {
              relatedStage.push(trs);
            }
          }
        } else {
          relatedStage = group.filter(
            (e) =>
              (e.groupType === "stage" || e.groupType === "array") &&
              !e.hideInEdit
          );
        }
        if (elementGroup.setStepper) {
          let mainGroupSchema = {
            type: "HorizontalLayout",
            elements: [],
          };

          let scope = "";
          let option = {};

          scope =
            "#/properties/database/properties/main/properties/stepper_fake";

          let elementData = {
            keyTxt: "stepper_fake",
            lableTxt: "stepper_fake",
            type: "stepper",
            relatedStage: relatedStage,
          };
          const resGropEn = createGroupElementObj(elementData);

          if (resGropEn) {
            schemaObject.properties.database.properties.main.properties[
              "stepper_fake"
            ] = resGropEn.schema;
          }
          mainGroupSchema.elements.push({
            type: "Control",
            scope: scope,
            options: option,
          });
          uiSchema.elements.push(mainGroupSchema);
        }
        uiSchema.elements.push({
          type: "Label",
          // "text": elementGroup.groupLable
        });

        if (relatedStage.length === 0) {
          continue;
        }
        for (const elementRelatedStage of relatedStage) {
          ////console.log(elementRelatedStage,schemaElement,'eleArraObjeleArraObjeleArraObj')
          let stageObj = {
            type: "Category",
            label: elementRelatedStage.groupLable,
            elements: [],
          };
          let isMulti = false;
          if (elementRelatedStage.groupType === "stage") {
            let filteredEleimentSchemaCategory = [];
            if (props.normalView) {
              filteredEleimentSchemaCategory = schemaElement.filter(
                (e) =>
                  e.group === elementRelatedStage.groupId &&
                  e.type != "formTimeOut"
              );
            } else {
              filteredEleimentSchemaCategory = schemaElement.filter(
                (e) => e.group === elementRelatedStage.groupId
              );
            }

            let count = 0;
            for (let elementDataStage of filteredEleimentSchemaCategory) {
              ////console.log('filteredEleimentSchemaCategory',filteredEleimentSchemaCategory)

              //push answer grid

              if (elementDataStage.type == "data_grid") {
                if (elementDataStage.multiStage) {
                  isMulti = true;
                  let dynamicGridSchema = [];

                  for (const eleArraObj of elementDataStage.arrayValue) {
                    ////console.log(eleArraObj,'elementDataStageelementDataStage')
                    let lableTR = null;
                    if (eleArraObj.group_production) {
                      if (eleArraObj.group_production.value) {
                        if (Array.isArray(eleArraObj.group_production.value)) {
                          lableTR = eleArraObj.group_production.value[0];
                        }
                        //
                      }
                    }
                    //   const lableTR=eleArraObj?.group_production?.value[0]?eleArraObj.value[0]:null

                    let absalutLable = null;
                    if (lableTR) {
                      if (lableTR?.allansw?.PRODUCT_DESCRIPTION) {
                        absalutLable = lableTR.allansw.PRODUCT_DESCRIPTION;
                      }
                    }

                    // let dynGroupObj = {
                    //     "groupId": elementDataStage.group + '-' + eleArraObj.elOFGrid.group_production.selectedgroup,
                    //     "groupName": eleArraObj.elOFGrid.group_production.selectedgroup + '-' + elementDataStage.lableTxt,
                    //     "align": null,
                    //     "groupLable": eleArraObj.elOFGrid.group_production.selectedgroup + '-' + elementDataStage.lableTxt,
                    //     "groupType": "stage",
                    //     "orderNumber": 2,
                    //     "groupColtxt": null,
                    // }

                    let stageObjDyn = {
                      type: "Category",
                      label:
                        eleArraObj.group_production.selectedgroup +
                        "-" +
                        elementDataStage.lableTxt,
                      elements: [],
                    };

                    let subgroupName = eleArraObj.group_production
                      ?.selectedsubgroup
                      ? `-${eleArraObj.group_production?.selectedsubgroup}`
                      : "";

                    let objeSchema = {
                      formId: elementDataStage.formId,
                      keyTxt:
                        elementDataStage.keyTxt +
                        "-" +
                        eleArraObj.group_production.selectedgroup +
                        subgroupName,
                      lableTxt: `${elementDataStage.lableTxt}-${eleArraObj.group_production.selectedgroup} ${subgroupName}`,
                      type: "data_grid",
                      sectionTitle: `${
                        elementDataStage.sectionTitle
                          ? elementDataStage.sectionTitle
                          : ""
                      }`,
                      orderNumber: 1,
                      fieldPlace: elementDataStage.fieldPlace,
                      isMulitSelect: null,
                      multiLine: null,
                      selectedParentFormListId:
                        elementDataStage.selectedParentFormListId,
                      selectedParentFormSchemaId:
                        elementDataStage.selectedParentFormSchemaId,
                      defaultLableValue: null,
                      arrayValue: eleArraObj.group_production.value,
                      multiStage: true,
                      parentImage: elementDataStage.parentImageKey
                        ? elementDataStage.parentImageKey
                        : null,
                      eleArraObj: eleArraObj,
                    };

                    //create dynamic schema
                    const resGropEn = createGroupElementObj(objeSchema);

                    let scope = "";
                    if (resGropEn) {
                      let rule = {
                        effect: "DISABLE",
                        condition: {
                          scope: "#/properties/dead",
                          schema: {
                            const: true,
                          },
                        },
                      };
                      if (
                        objeSchema.fieldPlace === "bitrix" ||
                        objeSchema.fieldPlace === ""
                      ) {
                        schemaObject.properties.bitrix.properties.main.properties[
                          objeSchema.keyTxt
                        ] = resGropEn.schema;
                        scope =
                          "#/properties/bitrix/properties/main/properties/" +
                          objeSchema.keyTxt;
                      } else {
                        schemaObject.properties.database.properties.main.properties[
                          objeSchema.keyTxt
                        ] = resGropEn.schema;
                        scope =
                          "#/properties/database/properties/main/properties/" +
                          objeSchema.keyTxt;

                        if (objeSchema.isRequired) {
                          schemaObject.properties.database.properties.main.required.push(
                            objeSchema.keyTxt
                          );
                        }
                        if (objeSchema.reference) {
                          if (profile.userBit) {
                            dataBody.database.main = {
                              ...dataBody.database.main,
                              [objeSchema.keyTxt]: profile.userBit[
                                objeSchema.reference
                              ]
                                ? profile.userBit[objeSchema.reference]
                                : "-",
                            };
                          }
                        }
                      }

                      if (objeSchema.disableEl) {
                        rule = {
                          effect: "ENABLE",
                          condition: {
                            scope: "#/properties/dead",
                            schema: {
                              const: true,
                            },
                          },
                        };
                      }

                      stageObjDyn.elements.push({
                        type: "HorizontalLayout",
                        elements: [
                          {
                            type: "Control",
                            scope: scope,
                            rule: rule,
                          },
                        ],
                      });
                    }

                    //end of creatin dynamic
                    catObj.elements.push(stageObjDyn);

                    //dynamicGridSchema.push(dynGroupObj)
                  }

                  //for()

                  continue;
                } else {
                  let dynValueArra = [];

                  for (const parentGroupV of elementDataStage.arrayValue) {
                    for (const pushingObj of parentGroupV.group_production
                      .value) {
                      dynValueArra.push(pushingObj);
                    }
                  }
                  elementDataStage = {
                    ...elementDataStage,
                    arrayValue: dynValueArra,
                  };
                }
              }

              if (elementDataStage.type == "data_grid") {
                if (elementDataStage.multiStage) {
                  continue;
                }
              }

              if (elementDataStage.type == "answer_view") {
                elementDataStage = {
                  ...elementDataStage,
                  elementList: schemaElement,
                };
              }
              //end answer grid

              const resGropEn = createGroupElementObj(elementDataStage);
              let scope = "";
              if (resGropEn) {
                let rule = {
                  effect: "DISABLE",
                  condition: {
                    scope: "#/properties/dead",
                    schema: {
                      const: true,
                    },
                  },
                };
                if (
                  elementDataStage.fieldPlace === "bitrix" ||
                  elementDataStage.fieldPlace === ""
                ) {
                  schemaObject.properties.bitrix.properties.main.properties[
                    elementDataStage.keyTxt
                  ] = resGropEn.schema;
                  scope =
                    "#/properties/bitrix/properties/main/properties/" +
                    elementDataStage.keyTxt;
                } else {
                  schemaObject.properties.database.properties.main.properties[
                    elementDataStage.keyTxt
                  ] = resGropEn.schema;
                  scope =
                    "#/properties/database/properties/main/properties/" +
                    elementDataStage.keyTxt;

                  if (elementDataStage.isRequired) {
                    schemaObject.properties.database.properties.main.required.push(
                      elementDataStage.keyTxt
                    );
                  }
                  schemaObject.properties.database.properties.main.required =
                    schemaObject.properties.database.properties.main.required.filter(
                      function (item, pos, self) {
                        return self.indexOf(item) == pos;
                      }
                    );

                  if (elementDataStage.reference) {
                    if (profile.userBit) {
                      dataBody.database.main = {
                        ...dataBody.database.main,
                        [elementDataStage.keyTxt]: profile.userBit[
                          elementDataStage.reference
                        ]
                          ? profile.userBit[elementDataStage.reference]
                          : "-",
                      };
                    }
                  }
                }

                if (elementDataStage.disableEl) {
                  rule = {
                    effect: "ENABLE",
                    condition: {
                      scope: "#/properties/dead",
                      schema: {
                        const: true,
                      },
                    },
                  };
                }

                stageObj.elements.push({
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: scope,
                      rule: rule,
                    },
                  ],
                });
              }
              count++;
            }
          } else {
            const filterGroupEl = schemaElement.filter(
              (e) =>
                e.type != "total" &&
                e.type != "clearing" &&
                e.group === elementRelatedStage.groupId
            );
            const resGropEn = createGroupElement(
              elementRelatedStage,
              filterGroupEl
            );
            if (resGropEn) {
              let scope = "";
              if (
                resGropEn.fieldPlace === "bitrix" ||
                resGropEn.fieldPlace === ""
              ) {
                schemaObject.properties.bitrix.properties.main.properties[
                  elementRelatedStage.groupId
                ] = resGropEn.schema;
                scope =
                  "#/properties/bitrix/properties/main/properties/" +
                  elementRelatedStage.groupId;
              } else {
                schemaObject.properties.database.properties.main.properties[
                  elementRelatedStage.groupId
                ] = resGropEn.schema;
                scope =
                  "#/properties/database/properties/main/properties/" +
                  elementRelatedStage.groupId;
              }
              stageObj.elements.push({
                type: "VerticalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: scope,
                    options: {
                      showSortButtons: true,
                      detail: {
                        type: "VerticalLayout",
                        elements: resGropEn.ui ? resGropEn.ui : [],
                      },
                      elementLabelProp: "name",
                    },
                    // 'rule': rule
                  },
                ],
              });
            }
          }
          if (!isMulti) {
            catObj.elements.push(stageObj);
          }
        }
        uiSchema.elements.push(catObj);
      } else if (
        elementGroup.groupType === "array" &&
        elementGroup.selectedArrayType !== "aselement"
      ) {
        const filterGroupEl = schemaElement.filter(
          (e) =>
            e.type != "total" &&
            e.type != "clearing" &&
            e.group === elementGroup.groupId
        );
        const resGropEn = createGroupElement(elementGroup, filterGroupEl);
        if (resGropEn) {
          let scope = "";
          if (
            resGropEn.fieldPlace === "bitrix" ||
            resGropEn.fieldPlace === ""
          ) {
            schemaObject.properties.bitrix.properties.main.properties[
              elementGroup.groupId
            ] = resGropEn.schema;
            scope =
              "#/properties/bitrix/properties/main/properties/" +
              elementGroup.groupId;
          } else {
            schemaObject.properties.database.properties.main.properties[
              elementGroup.groupId
            ] = resGropEn.schema;
            scope =
              "#/properties/database/properties/main/properties/" +
              elementGroup.groupId;
          }
          //schemaObject.properties.main.properties[elementGroup.groupId] = resGropEn.schema

          let mainGroupSchema = {
            type: "VerticalLayout",
            elements: [],
          };

          mainGroupSchema.elements.push({
            type: "Control",
            scope: scope,
            options: {
              showSortButtons: true,
              detail: {
                type: "VerticalLayout",
                elements: resGropEn.ui ? resGropEn.ui : [],
              },
              elementLabelProp: "name",
            },
          });

          uiSchema.elements.push(mainGroupSchema);
        }

        const clearing = schemaElement.find((e) => e.type == "clearing");

        if (clearing) {
          const resGropEn = createGroupElementObj(clearing);

          if (resGropEn) {
            schemaObject.properties.database.properties.main.properties[
              clearing.keyTxt
            ] = resGropEn.schema;

            let mainGroupSchema = {
              type: "HorizontalLayout",
              elements: [],
            };

            let scope = "";
            let option = {};

            let rule = {
              effect: "DISABLE",
              condition: {
                scope:
                  "#/properties/bitrix/properties/main/properties/" +
                  elementGroup.groupId,
                schema: {
                  const: schemaObject.properties.database,
                },
              },
            };

            scope =
              "#/properties/database/properties/main/properties/" +
              clearing.keyTxt;

            mainGroupSchema.elements.push({
              type: "Control",
              scope: scope,
              options: option,
              rule: rule,
            });

            uiSchema.elements.push(mainGroupSchema);
          }
        }
      }
    }

    setFormSchema((prevState) => {
      return {
        ...prevState,
        schema: schemaObject,
        uischema: uiSchema,
        data: dataBody,
      };
    });

    setRenderSuccess(true);
    setLoadingFormData(false);
  };

  const handleLanguageChanged = async () => {
    setLoadingFormData(true);
    await createForm();
    setLoadingFormData(false);
  };

  const [isDynamic, setIsDynamic] = useState(false);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);

    createForm().then(() => {
      setFormDataFunction()
      
    }).catch((err) => {
        console.log(err);
      });
    
      
  }, [props.data]);

  const setFormDataFunction = () => {
    setFormDetails(props.data);
  };
  const [errors, setErrors] = useState([]);

  function targeGroupChange(data) {
    setFormSchema((prevState) => {
      return {
        ...prevState,
        data: data,
      };
    });

    //dispatch(changeDataReducer(data))

    props.changeDataTrigger(data);
    // setFormSchema((prevState) => {
    //     return ({
    //         ...prevState,
    //         data: tempData
    //     });
    // });
  }
  let tempErr;

  const setData = (data, err) => {
    //console.log('err',err)
    tempErr = err.filter((e) => {
      //console.log(e.parentSchema.title)
      return !e.parentSchema.title.includes("Store");
    });
    setErrors(tempErr ? tempErr : []);

    let tempData = data;
    let tot = 0;
    if (totalFields) {
      for (const totalFiel of totalFields) {
        if (tempData?.database?.main[totalFiel.group]) {
          if (tempData.database.main[totalFiel.group].length !== 0) {
            let tottt = 0;
            for (const subOfTot of tempData.database.main[totalFiel.group]) {
              if (subOfTot[totalFiel.targetValue]) {
                tottt =
                  parseFloat(tottt) +
                  parseFloat(subOfTot[totalFiel.targetValue]);
                // tempData.database.main.test=tempData.database.main[totalFiel.group][0]['price']
              }
            }
            tempData.database.main[totalFiel.keyTxt] = tottt;
          }
        }
      }
    }

    //  if(data?.database?.main['795c4d86-9da9-4e98-8600-507701bc6c21']){

    //     if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'].length !== 0){

    //             if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']){
    //                 data.database.main.total=data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']
    //             }

    //     }

    // }

    // setFormSchema((prevState) => {
    //   return {
    //     ...prevState,
    //     data: tempData,
    //   };
    // });
    dispatch(changeDataReducer(tempData))
      .then((res) => {
        console.log("changeDataReducer", res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });

    props.changeDataTrigger(tempData, err);
    //issue for task / auto save
  };

  const moveToFormSetting = () => {
    if (props?.formInformation?.id) {
      // history.push()
      
      window.open(
        "/manage-form/scheema?id=" + props.formInformation.id,
        "_blank"
      );
    }
  };

  return (
    <div className="jsonFormDynamic jsonFormDynamic-fixed" id="EnableLable">
      <div className="header-setting-action">
        {userRole ? (
          <>
            {userRole === "Super Admin" ? (
              <div>
                <Button
                  size="small"
                  type="primary"
                  icon={<SettingOutlined />}
                  onClick={moveToFormSetting}
                />
              </div>
            ) : null}
          </>
        ) : (
          "-"
        )}
      </div>

      <div style={{ textAlign: "left", marginTop: 10, marginBottom: 15 }}>
        {isDynamic ? null : (
          <>
            {errors.length === 0 ? (
              <>
                <AntAl message="Form Ready to submit" type="success" showIcon />
              </>
            ) : (
              <>
                <Badge
                  onClick={() => {
                    setErrorShow(!isErrorShow);
                  }}
                  count={errors.length}
                >
                  <Avatar
                    style={{
                      backgroundColor: "white",
                      verticalAlign: "middle",
                      color: "red",
                    }}
                    icon={<ExclamationCircleOutlined twoToneColor="#eb2f96" />}
                  />
                </Badge>

                {isErrorShow ? (
                  <div>
                    {errors.map((row) => (
                      <>
                        {row?.params?.missingProperty && props?.jsonSchema ? (
                          <Tag color="red">
                            {" "}
                            {props.jsonSchema.find(
                              (e) => e.keyTxt === row.params.missingProperty
                            ).lableTxt
                              ? t(
                                  props.jsonSchema.find(
                                    (e) =>
                                      e.keyTxt === row.params.missingProperty
                                  ).lableTxt
                                )
                              : "-"}{" "}
                            {row?.message ? t(row.message) : "-"}
                          </Tag>
                        ) : null}
                      </>
                    ))}
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      {/* <div className="form-date-range">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={12} sm={6} md={6}>
                                {t("Start Date")} : {formDetails.activeDateStart ? moment(formDetails.activeDateStart).format('MMMM Do, YYYY') : 'No Active Start Date'}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                End Date : {formDetails.activeDateEnd ? moment(formDetails.activeDateEnd).format('MMMM Do, YYYY') : 'No Active End Date'}
                            </Grid>
                        </Grid>
                    </Box>

                </div> */}

      {/* <div className="form-responsible-person">

                    Responsible Person : {formDetails.selectedUser ? formDetails.selectedUser : 'No Responsible User'}
                </div>
                <div className="form-header-discription">
                    {totalFields.map((row) => (
                        <>
                            {row.lableTxt} : {formSchema?.data?.database?.main[row.keyTxt] ? formSchema.data.database.main[row.keyTxt] : 0}
                        </>


                    ))}
                </div> */}

      {/* <div className="form-header-discription">
                    {formDetails.description}
                </div> */}

      {loadingFormData ? (
        <>
          <FullScreenLoader />
        </>
      ) : null}

      {loadingFormData ? (
        <></>
      ) : (
        <>
          <div
            className="JsonForms-container"
            // style= {{display: 'inline-block', height: '60vh', overflow:'auto'} } 
            >
            <JsonForms
              schema={formSchema.schema}
              uischema={formSchema.uischema}
              data={formSchema.data}
              renderers={render}
              cells={cellRender}
              onChange={({ data, errors }) => setData(data, errors)}
              // issue in here?
            />
          </div>
        </>
      )}

      {totalFields.map((row) => (
        <div style={{ textAlign: "right" }} className="form-header-discription">
          <h3>
            {row.lableTxt} :{" "}
            {formSchema?.data?.database?.main[row.keyTxt] ? (
              <>
                {`${formSchema.data.database.main[row.keyTxt]}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </>
            ) : (
              0
            )}
          </h3>
        </div>
      ))}
    </div>
  );
}
