import React, { useState, useEffect } from "react";


import {
    useDispatch
} from "react-redux";
import moment from 'moment'
import { Col } from "antd";
const style = {
    padding: '8px 0',
    marginBottom: '10px'
};
export function FullScreenLoader(props) {




    useEffect(() => {



    }, [props]);



    return (
        <div className="loading">Loading&#8230;</div>

    );
}