import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { addFormReducer } from "../../../../redux/slices/formSettings";
import { getUsers } from "../../../../redux/slices/users";

import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { notification } from "antd";

import { Button as AntButton, Modal } from "antd";
import moment from "moment";

export function ManageFormModel(props) {
  const dispatch = useDispatch();

  const [formName, setFormName] = useState("");
  const [formString, setFormString] = useState("");

  const [formDesc, setformDesc] = useState("");

  const [selectedUser, setSelectedUser] = useState("");

  const [activeDateStart, setStartDate] = React.useState(moment());
  const [activeDateEnd, setEndDate] = React.useState(null);

  const [formType, setformType] = useState("PF");

  const setActiveStartDate = (newValue) => {
    setStartDate(newValue);
  };
  const setActiveEndDate = (newValue) => {
    setEndDate(newValue);
  };

  const [responsibleUsers, setResponsibleUsers] = useState([]);

  const getResponsibleUsers = async () => {

    if (responsibleUsers.length > 0) {
      return;
    }
    const res = await dispatch(getUsers("ID,NAME,LAST_NAME,emCode"));
    console.log("res", res.payload);

    let usersArray = [];

    res.payload.forEach(user => {
      usersArray.push({ lable: user.NAME + user.LAST_NAME + ' - ' + user.emCode, key: user.ID });
    });
  
    setResponsibleUsers(usersArray);

    // [
    //   {
    //     label: "1",
    //     key: "mark",
    //   },
    // ];

    return res;
  };

  const formTypes = [
    {
      lable: "Admin Forms",
      key: "AF",
    },
    {
      lable: "Public forms",
      key: "PF",
    },
    {
      lable: "Internal - Workflow",
      key: "IW",
    },

    {
      lable: "Internal - Form",
      key: "IF",
    },
  ];

  const addForm = async () => {
    await dispatch(
      addFormReducer({
        name: formName,
        description: formDesc,
        status: "Draft",
        formType: formType,
        selectedUser: selectedUser,
        activeDateStart: activeDateStart,
        activeDateEnd: activeDateEnd,
        formString: formString,
      })
    );
    notification.success({
      message: `Form Added`,
      description: `Form submit succesfully`,
    });

    props.addFormSuccess();
  };



  useEffect(() => {
    // getResponsibleUsers();
  }, [getResponsibleUsers(),props]);

  return (
    <>
      <Modal
        title={"Add Form - " + formName}
        open={props.visible}
        onCancel={props.onCancel}
        footer={[
          <AntButton danger key="back" onClick={props.onCancel}>
            Cancel
          </AntButton>,
          <AntButton danger key="submit" type="primary" onClick={addForm}>
            Submit
          </AntButton>,
        ]}
      >
        <Box
          component="form"
          md={{
            "& > :not(style)": { m: 1, width: "35ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid style={{ padding: 16 }} item xs={12} sm={6}>
              <TextField
                className="full-width"
                value={formName}
                size="small"
                onChange={(e) => {
                  setFormName(e.target.value);
                  setFormString(e.target.value.replace(/\s+/g, "-"));
                }}
                id="outlined-basic"
                label="Form Name"
                variant="outlined"
              />
            </Grid>

            <Grid style={{ padding: 16 }} item xs={12} sm={6}>
              <Select
                className="full-width"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size='small'
                value={formType}
                onChange={(e) => {
                  setformType(e.target.value);
                }}
              >
                {formTypes.map((option) => (
                  <MenuItem value={option.key}>{option.lable}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid style={{ padding: 16 }} item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Responsible user
                </InputLabel>
                
                <Select
                  label="Responsible user"
                  className="full-width"
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedUser}
                 
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  {responsibleUsers.map((option) => (
                    <MenuItem key={option.key} value={option.key}>{option.lable}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ padding: 16 }} item xs={12} sm={12}>
              <TextField
                multiline
                className="full-width"
                rows={4}
                size="small"
                value={formDesc}
                onChange={(e) => {
                  setformDesc(e.target.value);
                }}
                id="outlined-basic"
                label="Descripton"
                variant="outlined"
              />
            </Grid>

            <Grid style={{ padding: 16 }} item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl fullWidth>
                  <MobileDatePicker
                    className="full-width"
                    label="Active Date"
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    value={activeDateStart}
                    defaultValue={moment().format("MM/dd/yyyy")}
                    onChange={setActiveStartDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid style={{ padding: 16 }} item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl fullWidth>
                  <MobileDatePicker
                    className="full-width"
                    label="End Date"
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    value={activeDateEnd}
                    defaultValue={moment().format("MM/dd/yyyy")}
                    onChange={setActiveEndDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/*  */}
    </>
  );
}
