

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import './style.css'
import { Select, Checkbox, Radio,InputNumber,DatePicker  } from 'antd';
import { init } from 'i18next';
const { Option } = Select;

const Repeater = (props) => {
    const { t, i18n } = useTranslation();

    const [enableReapeater, setEnableRepeater] = useState(false)
    const [valueOption, setValueOption] = useState({})
    const init = (data) => {
        setValueOption(data.data ? data.data : {
            enableReapeater: false,
            repeaterTerm: 'min'
        })

    }
    const enableRepater = async (e) => {

        const tempObje = {
            ...valueOption,
            enableReapeater: e
        }
        props.handleChange(props.path, tempObje)


    };

    const ignoreWeekend = async (e) => {

        const tempObje = {
            ...valueOption,
            isWeekendIgnore: e
        }
        props.handleChange(props.path, tempObje)


    };
    

    const changeRepeaterTerm = async (e) => {


        const tempObje = {
            ...valueOption,
            repeaterTerm: e
        }
        props.handleChange(props.path, tempObje)


    };

    const changeAfterSec = async (e) => {


        const tempObje = {
            ...valueOption,
            afterSec: e
        }
        props.handleChange(props.path, tempObje)


    };
    const changeAfterMin = async (e) => {


        const tempObje = {
            ...valueOption,
            afterMin: e
        }
        props.handleChange(props.path, tempObje)


    };
    const changeAfterHour = async (e) => {


        const tempObje = {
            ...valueOption,
            afterHour: e
        }
        props.handleChange(props.path, tempObje)


    };

    const changeAfterDay = async (e) => {


        const tempObje = {
            ...valueOption,
            afterDay: e
        }
        props.handleChange(props.path, tempObje)


    };
    const changeAfterWeek = async (e) => {


        const tempObje = {
            ...valueOption,
            afterWeek: e
        }
        props.handleChange(props.path, tempObje)


    };
    const changeAfterMonth = async (e) => {

        const tempObje = {
            ...valueOption,
            afterMonth: e
        }
        props.handleChange(props.path, tempObje)


    };
    const changeAfterYear = async (e) => {


        const tempObje = {
            ...valueOption,
            afterYear: e
        }
        props.handleChange(props.path, tempObje)


    };

    const changeStartDate = async (date, dateString) => {

        const tempObje = {
            ...valueOption,
            startDate: dateString
        }
        props.handleChange(props.path, tempObje)


    };



    //

    useEffect(() => {
        init(props)

        //
    }, [props]);
    return (
        <>
            <div className='repeater-check-box'>
                <Checkbox checked={valueOption?.enableReapeater} onChange={(e) => enableRepater(e.target.checked)}>Enable Repeater</Checkbox>
            </div>
            <div >
                {
                    valueOption.enableReapeater ? <>
                    <div className='repeater-check-box'>

                    Repeat Terms <br></br>
                        <Radio.Group onChange={(e) => changeRepeaterTerm(e.target.value)} defaultValue={valueOption?.repeaterTerm} buttonStyle="solid">
                  
                            <Radio.Button value="min">Minutes</Radio.Button>
                            <Radio.Button value="day">Day</Radio.Button>
                            <Radio.Button value="week">Week</Radio.Button>
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                        </Radio.Group>
                    </div>
                       

                        <div>
                            {
                                <div className="setting-container">
                                    {
                                        valueOption?.repeaterTerm === "min" ? <>
                                         after every <InputNumber min={5} defaultValue={valueOption?.afterMin?valueOption.afterMin:0} onChange={(e)=>changeAfterMin(e)} /> Min
                                         </> : null
                                    }
                                    {
                                        valueOption?.repeaterTerm === "day" ? <>
                                           after every <InputNumber defaultValue={valueOption?.afterDay?valueOption.afterDay:0} onChange={(e)=>changeAfterDay(e)} /> Day 

                                        </> : null
                                    }
                                    {
                                        valueOption?.repeaterTerm === "week" ? <>
                                        after every <InputNumber defaultValue={valueOption?.afterWeek?valueOption.afterWeek:0} onChange={(e)=>changeAfterWeek(e)} /> Week
                                        </> : null
                                    }
                                    {
                                        valueOption?.repeaterTerm === "month" ? <>
                                        after every <InputNumber defaultValue={valueOption?.afterMonth?valueOption.afterMonth:0} onChange={(e)=>changeAfterMonth(e)} /> Month
                                        </> : null
                                    }
                                    {
                                        valueOption?.repeaterTerm === "year" ? <>
                                        after every <InputNumber defaultValue={valueOption?.afterYear?valueOption.afterYear:0} onChange={(e)=>changeAfterYear(e)} /> Year
                                        </> : null
                                    }

                                    
                                </div>
                              
                            }
                             <div className='repeater-check-box'>
                              <Checkbox checked={valueOption?.isWeekendIgnore} onChange={(e) => ignoreWeekend(e.target.checked)}>Ignore Week-End</Checkbox>
                             </div>
                             <div className='repeater-check-box'>
                            Starting Date <DatePicker defaultValue={valueOption?.startDate?moment(valueOption.startDate, 'YYYY-MM-DD'):null}  onChange={changeStartDate} />
                             </div>
                              
                            {/* {(() => {
                                if (valueOption?.repeaterTerm === "sec") {
                                    return (
                                        <div className="setting-container">
                                            sec
                                        </div>
                                    )
                                } else if (valueOption?.repeaterTerm === "min") {
                                    return (
                                        <div className="setting-container">
                                            min
                                        </div>
                                    )
                                } else if (valueOption?.repeaterTerm === "hour") {
                                    return (
                                        <div className="setting-container">
                                            hour
                                        </div>
                                    )
                                }
                                else if (valueOption?.repeaterTerm === "day") {
                                    return (
                                        <div className="setting-container">
                                            day
                                        </div>
                                    )
                                }
                                else if (valueOption?.repeaterTerm === "week") {
                                    return (
                                        <div className="setting-container">
                                            week
                                        </div>
                                    )
                                }
                                else if (valueOption?.repeaterTerm === "month") {
                                    return (
                                        <div className="setting-container">
                                            month
                                        </div>
                                    )
                                }
                                else if (valueOption?.repeaterTerm === "year") {
                                    return (
                                        <div className="setting-container">
                                            year
                                        </div>
                                    )
                                }
                            }
                            )} */}
                        </div>
                    </>
                        : <></>
                }





            </div>
        </>



    );
}



export default withJsonFormsControlProps(Repeater);
