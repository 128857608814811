import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig, apiConfigForm } from "../../config";


import moment from "moment";

const initialState = {
    workFlow: [],
    workFlowFilters: [],
    viewWorkFlowDwawer: false,
    workFlowDetails: null,
    loading: false,
    error: null,
};



//


export const initialReducer = createAsyncThunk(
    "workflow/initialReducer",
    async (formId) => {
        let array = [];

        let workflowFormsEndPoint = `${apiConfig.adminApi}/work-flow/forms?pagination=${formId.pagination ? true : false}&rowPerRequest=${formId.selectedPage ? formId.selectedPage : 1}&perPage=${formId.perPage ? formId.perPage : 10}&page=${formId.page ? formId.page : 0}`;


        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const workflowFormsResponse = await axios.post(workflowFormsEndPoint, formId.filters, config);
        array = { ...workflowFormsResponse.data }
        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const workflowAnswerReducer = createAsyncThunk(
    "workflow/workflowAnswerReducer",
    async (formId) => {
        let array = [];

        let workfowAnswersEndPoint = `${apiConfig.adminApi}/work-flow/answers?pagination=${formId.pagination ? true : false}&rowPerRequest=${formId.selectedPage ? formId.selectedPage : 1}&perPage=${formId.perPage ? formId.perPage : 10}&page=${formId.page ? formId.page : 0}`;


        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const workfowAnswersResponse = await axios.post(workfowAnswersEndPoint, formId.filters, config);

        array = { ...workfowAnswersResponse.data, data: await newPayload(workfowAnswersResponse.data.data) }
        console.log("array", array)
        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

export const flowStatusChangeReducer = createAsyncThunk(
    "workflow/flowStatusChangeReducer",
    async (formId) => {
        let array = [];


        let endPoint = `${apiConfig.adminApi}/work-flow/status-change`;
        const token = localStorage.getItem('token')
        let config = {
            headers: {
                authorization: 'Bearer ' + token,
            }
        }
        const response = await axios.post(endPoint, formId, config);

        array = response.data

        if (array) {
            return array;
        } else {
            throw Error;
        }
    }
);

const newPayload = async (workflows) => await Promise.all(workflows.map(async (workflow) => {
    console.log("workflow", workflow)
    try {
        let data = await setAnswersOnWorkflow(workflow)
        return {
            ...workflow,
            answer: {
                ...workflow.answer,
                data: {
                    ...workflow.answer.data,
                    database: {
                        ...workflow.answer.data.database,
                        main: {
                            ...workflow.answer.data.database.main,
                            ...data.data
                        }
                    },
                }
            }
        }
    }
    catch (error) {
        return workflow
    }

}));


export const workFlowDrawerOpen = createAsyncThunk(
    "workflow/workFlowDrawerOpen",
    async (data) => {

        if (data) {
            return data;
        } else {
            throw Error;
        }
    }
);
export const workFlowDrawerClose = createAsyncThunk(
    "workflow/workFlowDrawerClose",
    async (data) => {

        return true
    }
);

export const filterWorkFlowReducer = createAsyncThunk(
    "workflow/filterWorkFlowReducer",
    async (filters) => {
        let array = [];


        if (filters) {
            return filters;
        } else {
            throw Error;
        }
    }
);







export const setAnswersOnWorkflow = async (workflow) => {
    const token = localStorage.getItem('token')
    let config = {
        headers: {
            authorization: 'Bearer ' + token,
        }
    }
    const response = await axios.post(`${apiConfig.adminApi}/work-flow/task-details`, {
        "formId": workflow.answer.formId,
        "answerId": workflow.answer.id,
    }, config)

    return response.data

}

export const workflowFormWithAnswers = createAsyncThunk('workflow/workflowFormWithAnswers', async (formId, { dispatch }) => {
    try {
      const [data1, data2] = await Promise.all([dispatch(initialReducer(formId)), dispatch(workflowAnswerReducer(formId))]);
       const mergedResponse = data1.payload.data.map((obj, index) => ({
            ...obj,
            ...data2.payload.data[index]
          }));
        console.log("fetchAndMergeData",mergedResponse)
        return {...data1.payload,data:mergedResponse}
    } catch (error) {
      throw error
    }
  });


export const dynamicPageSlice = createSlice({
    name: "workflow",
    initialState,
    extraReducers: {
        [workflowFormWithAnswers.pending]: (state) => {
            const st = {
                ...state,
                loading : true,
                error : null
            }
            return st
        },
        [workflowFormWithAnswers.fulfilled]: (state, action) => {
            const st = {
                ...state,
                loading : false,
                workFlow: action.payload.data,
                workFlowFilters: action.payload.data
            }
            return st
        },
        [workflowFormWithAnswers.rejected]: (state, action) => {
            const st = {
                ...state,
                loading : false,
                error : action.error.message
            }
            return st
        },
        // [initialReducer.fulfilled]: (state, action) => {

        //     const st = {
        //         ...state,
        //         workFlow: action.payload.data,
        //         workFlowFilters: action.payload.data
        //     }


        //     return st
        // },
        [workFlowDrawerOpen.fulfilled]: (state, action) => {
            const data = action.payload

            const st = {
                ...state,
                viewWorkFlowDwawer: true,
                workFlowDetails: data
            }


            return st
        },
        [workFlowDrawerClose.fulfilled]: (state, action) => {
            const data = action.payload

            const st = {
                ...state,
                viewWorkFlowDwawer: false,
                workFlowDetails: null
            }


            return st
        },
        [filterWorkFlowReducer.fulfilled]: (state, action) => {

            const filters = action.payload

            const st = {
                ...state,
                workFlow: state.workFlow,
                workFlowFilters: state.workFlow.filter(function (data) {


                    if (filters.formId && !filters.createData) {
                        if (data.form.form.id == filters.formId) {
                            return data
                        }
                    }
                    if (!filters.formId && filters.createData) {
                        const startDate = filters.createData[0] ? moment(filters.createData[0]).format('MM-DD-YYYY') : null
                        const endDate = filters.createData[1] ? moment(filters.createData[1]).format('MM-DD-YYYY') : null
                        const answerCreatedDate = data?.answer?.createdAt ? moment(data.answer.createdAt).format('MM-DD-YYYY') : null
                        if (!startDate || !endDate || !answerCreatedDate) {
                            return false;
                        }

                        if (answerCreatedDate >= startDate && answerCreatedDate <= endDate) {
                            return data
                        }

                    }

                    if (filters.formId && filters.createData) {
                        const startDate = filters.createData[0] ? moment(filters.createData[0]).format('MM-DD-YYYY') : null
                        const endDate = filters.createData[1] ? moment(filters.createData[1]).format('MM-DD-YYYY') : null
                        const answerCreatedDate = data?.answer?.createdAt ? moment(data.answer.createdAt).format('MM-DD-YYYY') : null
                        if (!startDate || !endDate || !answerCreatedDate) {
                            return false;
                        }

                        if (answerCreatedDate >= startDate && answerCreatedDate <= endDate) {
                            if (data.form.form.id == filters.formId) {
                                return data
                            }

                        }

                    }

                    return false;
                }).map(function (data) { return data })




            }

            return st

            // const st=  {
            //     ...state,
            //     workFlow:action.payload.data,
            //     workFlowFilters:action.payload.data
            // }


            // return st
        }
    },
});

export default dynamicPageSlice.reducer;


