

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col } from 'antd';
import { init } from 'i18next';

import {View,ViewCloeck,ViewStored} from './component/ViewElement'
const { Option } = Select;

const ViewData = (props) => {
    const { t, i18n } = useTranslation();
    const [val, setVal] = useState(null)
  
    const [dataList, setDataList] = useState([])
    const init = async (e) => {
         

      

        let keys=[]
        if(e?.schema?.schemaElement?.absaulteData?.showingElements && e?.schema?.schemaElement?.elementList){
            
            const elList=e.schema.schemaElement.elementList
            const elKey=e.schema.schemaElement.absaulteData.showingElements
            for (const key of elKey){
                let selectedKey=elList.find((el)=>el.keyTxt==key)
                if(selectedKey){
                    keys.push(selectedKey)
                }
            }
           
        }
        
        setDataList(keys)
         

    };

    //

    useEffect(() => {

        //
        init(props)

        //
    }, [props]);
    return (
        <div className='custom-component data-view'>

        {
            dataList.map((el)=>(
                
                <>
                    {
                        el.type==='cloacked'?<div><ViewCloeck element={el}/></div>:
                        el.type==='shops'?<div><ViewStored  element={el}/></div>:<div className='flot-div'><View  element={el}/></div>
                    }
                </>
            ))
        }
        </div>



    );
}



export default withJsonFormsControlProps(ViewData);
