import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getUserById, getLogedInUserData } from "../../../../redux/slices/users";
import { submitFormAnswer } from "../../../../redux/slices/formSettings"
import { useHistory } from "react-router-dom";
import {
    useDispatch
} from "react-redux";
import '../style.css'
import { Divider, Tag, Spin } from 'antd';
import { UserOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Avatar, Badge } from 'antd';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { useTranslation } from "react-i18next";
import { JsonForms } from '@jsonforms/react';
import moment from 'moment'
import $ from 'jquery';
import Clearing from '../custom/clearing/Clearing';
import Calculation from '../custom/calculation/Calculation';
import CalculationArray from '../custom/calculation/CalculationArray';
import { FullScreenLoader } from '../../../../components/elements/FullScreenLoader'
import {
    changeDataReducer
} from "../../../../redux/slices/ruleElement";

import GeoLocation from '../custom/geoLocation/GeoLocation';
import FileControl from '../custom/storage/FileControl';
import ShopControl from '../custom/shop/ShopControl';
import CheckCustom from '../custom/check/CheckCustom';
import CustomSorting from '../custom/sorting/CustomSorting';
import ClockedControl from '../custom/storage/ClockedControl';
import HtmlCamera from '../custom/storage/HtmlCamera';
import MultiSelect from '../custom/Select/MultiSelect';
import SingleSelect from '../custom/Select/SingleSelect';
import NumberIn from '../custom/number/NumberIn';
import ShareLocationJson from '../custom/location/ShareLocationJson';
import ViewData from '../custom/viewdata/ViewData';
import DynamicStep from '../custom/element/DynamicStep';
import AutoFill from '../custom/element/AutoFill';

import Timmer from '../custom/element/Timmer';


import DatePicker from '../custom/Select/DatePicker';
import MultiSelectTree from '../custom/Select/MultiSelectTree';

import LableTemp from '../custom/lable/LableTemp';
import TimeLine from '../custom/timeLine/TimeLine';
import Details from '../custom/details/Details';
import FormTemplate from '../custom/template/FormTemplate';
import { SettingOutlined } from '@ant-design/icons';

import { Alert as AntAl, Button, Space } from 'antd';


import DataGrid from '../custom/dataGrid/DataGrid';
import Repeater from '../custom/repeater/Repeater';

import { rankWith, scopeEndsWith } from '@jsonforms/core';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

export function FormInterfaceDynamic(props, ref) {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();


    const [userRole, setUserRole] = useState(null)
    const [render, setRender] = useState([
        ...materialRenderers
    ])

    const [cellRender, setCellRender] = useState([
        ...materialCells
    ])

    const [isErrorShow, setErrorShow] = useState(false)
    const [dataBaseData, setDataBaseData] = useState([])

    const [formDetails, setFormDetails] = useState({})

    const [totalFields, setTotalFields] = useState([])
    const [loadingFormData, setLoadingFormData] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const [renderSuccess, setRenderSuccess] = useState(true)

    const [formSchema, setFormSchema] = useState({
        schema: {
            "properties": {
                "name": {
                    "type": "string",
                    "title": "First Name"
                },
                "blabla": {
                    "type": "string",
                    "title": "First Name"
                }
            }
        }, uischema: {
            "properties": {
                "name": {
                    "type": "string",
                },
                "blabla": {
                    "type": "string",

                }
            }
        },
        data: {}

    })




    const createNonGroupElement = (mainEl) => {
        let schemaObj = {}
        let typeOfElement = 'string';
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {
            typeOfElement = 'string';
        }
        let eleObj = {}
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {


            schemaObj = {
                type: typeOfElement,
                oneOf: mainEl.enum ? mainEl.enum : [],
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'date') {
            schemaObj = {
                type: typeOfElement,
                format: 'date',
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'check') {
            schemaObj = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: mainEl.enum ? mainEl.enum : [],
                },
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'location') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: GeoLocation
            })
            setRender(renderers);

        }
        else if (mainEl.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: FileControl
            })
            schemaObj = {
                storeMethod: mainEl.fileStoreType,
                s3Bucket: mainEl.s3Bucket,
                s3Dir: mainEl.s3Dir,
                selectedFileType: mainEl.selectedFileType ? mainEl.selectedFileType : null,
                captureType: mainEl.captureType ? mainEl.captureType : null
            }
        }
        else if (mainEl.type === 'html_camera') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: HtmlCamera
            })
            schemaObj = {
                storeMethod: mainEl.fileStoreType,
                s3Bucket: mainEl.s3Bucket,
                s3Dir: mainEl.s3Dir,
                selectedFileType: mainEl.selectedFileType ? mainEl.selectedFileType : null,
                captureType: mainEl.captureType ? mainEl.captureType : null
            }
        }

        else {
            schemaObj = {
                type: typeOfElement,
                title: t(mainEl.lableTxt)
            }
        }
        return schemaObj
    }


    const createGroupElement = (elementGroup, schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: []
        }
        wrappingObj.schema['title'] = t(elementGroup.groupName)



        if (schemaElement.length !== 0) {

            let eleArra = []
            if (elementGroup.groupType === 'array' || elementGroup.groupType === 'custom_workFlow') {
                wrappingObj.schema['type'] = 'array'
                wrappingObj.schema['items'] = {}
                wrappingObj.schema.items['type'] = 'object'
                wrappingObj.schema.items['properties'] = {}
                for (const elementData of schemaElement) {

                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {
                        let uiObj = {
                            "scope": "#/properties/" + elementData.keyTxt,
                            "type": "Control"
                        }
                        wrappingObj.ui.push(uiObj)
                        let eleObj = {}
                        if (elementData.type === 'drop' || elementData.type === 'radio') {


                            let showingEnum = []
                            if (elementData?.isTranslated) {
                                if (elementData.enum) {
                                    for (const trEn of elementData.enum) {
                                        let ob = {
                                            const: trEn.const,
                                            title: t(trEn.title ? trEn.title : '-')
                                        }
                                        showingEnum.push(ob)
                                    }
                                }
                            } else {
                                showingEnum = elementData.enum ? elementData.enum : []
                            }
                            if (elementData.selectType == 'multi') {

                                if (elementData.grouped) {
                                    let renderers = render
                                    renderers.push({
                                        tester: rankWith(
                                            3, //increase rank as needed
                                            scopeEndsWith(elementData.keyTxt)
                                        ), renderer: MultiSelectTree
                                    })

                                    setRender(renderers);
                                    wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                        enumList: showingEnum,
                                        schemaElement: elementData
                                    }

                                } else {
                                    let renderers = render
                                    renderers.push({
                                        tester: rankWith(
                                            3, //increase rank as needed
                                            scopeEndsWith(elementData.keyTxt)
                                        ), renderer: MultiSelect
                                    })

                                    setRender(renderers);
                                    wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                        enumList: showingEnum,
                                        schemaElement: elementData
                                    }

                                }



                            } else {


                                let renderers = render
                                renderers.push({
                                    tester: rankWith(
                                        3, //increase rank as needed
                                        scopeEndsWith(elementData.keyTxt)
                                    ), renderer: SingleSelect
                                })
                                setRender(renderers);
                                wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                    enumList: showingEnum,
                                    schemaElement: elementData
                                }
                            }








                        }
                        else if (elementData.type === 'number') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: NumberIn
                            })

                            setRender(renderers);
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                schemaElement: elementData
                            }
                        }
                        else if (elementData.type === 'date') {


                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'dropTree') {

                            let showingEnum = []
                            if (elementData?.isTranslated) {
                                if (elementData.enum) {
                                    for (const trEn of elementData.enum) {
                                        let ob = {
                                            const: trEn.const,
                                            title: t(trEn.title ? trEn.title : '-')
                                        }
                                        showingEnum.push(ob)
                                    }
                                }
                            } else {
                                showingEnum = elementData.enum ? elementData.enum : []
                            }

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: MultiSelectTree
                            })

                            setRender(renderers);
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                enumList: showingEnum,
                                schemaElement: elementData
                            }



                        } else if (elementData.type === 'check') {
                            let arrayVal = []
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'calculation') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: Calculation
                            })

                            setRender(renderers);
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'number',
                                title: t(elementData.lableTxt),
                                info: elementData
                            }
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir
                            }
                        } else if (elementData.type === 'html_camera') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: HtmlCamera
                            })
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir
                            }
                        } else {
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            } else {
                wrappingObj.schema['properties'] = {}
                for (const elementData of schemaElement) {
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        if (elementData.type === 'drop' || elementData.type === 'radio') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                oneOf: elementData.enum ? elementData.enum : [],
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'date') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'check') {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj = {
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir,
                                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null
                            }
                        } else if (elementData.type === 'html_camera') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: HtmlCamera
                            })
                            wrappingObj = {
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir,
                                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                                mulitpleImage: schemaElement.imageTakenCount && schemaElement.imageTakenCount == 'mulitple' ? true : false
                            }

                        } else if (elementData.type === 'repeat') {

                        } else {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            }




        }
        return wrappingObj

    }


    const createGroupElementObj = (schemaElement) => {



        let wrappingObj = {
            schema: {},
            ui: {}
        }



        let eleArra = []

        wrappingObj.schema = {

        }

        let typeOfElement = 'string';
        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {
            typeOfElement = 'string';
        }

        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {



            let showingEnum = []

            if (schemaElement?.isTranslated) {

                if (schemaElement.enum) {
                    for (const trEn of schemaElement.enum) {

                        let ob = {
                            const: trEn.const,
                            title: t(trEn.title ? trEn.title : '-')
                        }
                        showingEnum.push(ob)
                    }
                }
            } else {
                showingEnum = schemaElement.enum ? schemaElement.enum : []
            }

            if (schemaElement.selectType == 'multi' || schemaElement.trigerAll) {
                let renderers = render
                renderers.push({
                    tester: rankWith(
                        3, //increase rank as needed
                        scopeEndsWith(schemaElement.keyTxt)
                    ), renderer: MultiSelect
                })
                wrappingObj.schema = {
                    enumList: showingEnum,
                    schemaElement: schemaElement
                }
            } else {


                let renderers = render
                renderers.push({
                    tester: rankWith(
                        3, //increase rank as needed
                        scopeEndsWith(schemaElement.keyTxt)
                    ), renderer: SingleSelect
                })
                wrappingObj.schema = {
                    enumList: showingEnum,
                    schemaElement: schemaElement
                }
            }




        }
        else if (schemaElement.type === 'Form') {




            if (schemaElement.formSelectType) {
                if (schemaElement.formSelectType === 'auto') {
                    var tst = '<h3>'
                    tst += `${schemaElement.lableTxt}  Will be created for this brand.</h3>`
                    if (schemaElement.cloningForm) {
                        let renderers = render
                        renderers.push({
                            tester: rankWith(
                                3, //increase rank as needed
                                scopeEndsWith(schemaElement.keyTxt)
                            ), renderer: LableTemp
                        })
                        wrappingObj.schema = {
                            type: 'string',
                            title: schemaElement.lableTxt,
                            display: tst,
                            all: schemaElement
                        }
                    } else {
                        let renderers = render
                        renderers.push({
                            tester: rankWith(
                                3, //increase rank as needed
                                scopeEndsWith(schemaElement.keyTxt)
                            ), renderer: SingleSelect
                        })
                        wrappingObj.schema = {
                            enumList: schemaElement.enum ? schemaElement.enum : [],
                            schemaElement: schemaElement
                        }
                    }
                } else {
                    let renderers = render
                    renderers.push({
                        tester: rankWith(
                            3, //increase rank as needed
                            scopeEndsWith(schemaElement.keyTxt)
                        ), renderer: SingleSelect
                    })
                    wrappingObj.schema = {
                        enumList: schemaElement.enum ? schemaElement.enum : [],
                        schemaElement: schemaElement
                    }
                }
            } else {
                let renderers = render
                renderers.push({
                    tester: rankWith(
                        3, //increase rank as needed
                        scopeEndsWith(schemaElement.keyTxt)
                    ), renderer: SingleSelect
                })
                wrappingObj.schema = {
                    enumList: schemaElement.enum ? schemaElement.enum : [],
                    schemaElement: schemaElement
                }
            }





        }
        else if (schemaElement.type === 'dynamicContent') {


            // let renderers = render
            // renderers.push({
            //     tester: rankWith(
            //         3, //increase rank as needed
            //         scopeEndsWith(schemaElement.keyTxt)
            //     ), renderer: FormTemplate
            // })
            // wrappingObj.schema = {
            //     schemaElement: schemaElement
            // }

            wrappingObj.schema = {
                type: typeOfElement,
                classNames: "customClassName",
                title: t(schemaElement.lableTxt),
                readOnly: true
            }

        }
        else if (schemaElement.type === 'answer_view') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: ViewData
            })
            wrappingObj.schema = {
                schemaElement: schemaElement
            }




        }
        else if (schemaElement.type === 'autofill') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: AutoFill
            })
            wrappingObj.schema = {
                schemaElement: schemaElement
            }




        }
        else if (schemaElement.type === 'stepper') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: DynamicStep
            })
            wrappingObj.schema = {
                schemaElement: schemaElement
            }


        }
        else if (schemaElement.type === 'formTimeOut') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: Timmer
            })
            wrappingObj.schema = {
                schemaElement: schemaElement
            }


        }

        

        else if (schemaElement.type === 'locationShare') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: ShareLocationJson
            })
            wrappingObj.schema = {
                schemaElement: schemaElement
            }




        }
        else if (schemaElement.type === 'date') {
                
            console.log('schemaElement', schemaElement)
            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: DatePicker
            })
            wrappingObj.schema = {
                schemaElement: schemaElement,
                "ui":  schemaElement.isHideInAdd? "hide" : "show",  // Add this line to hide the DatePicker element
            }




        } else if (schemaElement.type === 'check') {

            let uenuArray = []
            for (const es of schemaElement.enum) {
                uenuArray.push({
                    const: es.const,
                    title: t(es.title)




                })
            }
            wrappingObj.schema = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: uenuArray ? uenuArray : [],
                },
                title: t(schemaElement.lableTxt)
            }





        } else if (schemaElement.type === 'returnAll') {



            const ssss = props?.elementGroup ? props.elementGroup : []
            const aaaa = props?.jsonSchema ? props.jsonSchema : []

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: CheckCustom
            })
            wrappingObj.schema = {
                schemaElement: schemaElement,
                allSchema: aaaa,
                allGroup: ssss,
                addBulkDataList: targeGroupChange
            }
            wrappingObj.addBulkDataList = targeGroupChange


        }
        else if (schemaElement.type === 'sortBy') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: CustomSorting
            })
            
            wrappingObj.schema = {
                schemaElement: schemaElement,
                addBulkDataList: targeGroupChange
            }
            wrappingObj.addBulkDataList = targeGroupChange

        }
        else if (schemaElement.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: FileControl
            })
            wrappingObj.schema = {
                storeMethod: schemaElement.fileStoreType,
                s3Bucket: schemaElement.s3Bucket,
                s3Dir: schemaElement.s3Dir,
                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                captureType: schemaElement.captureType ? schemaElement.captureType : null
            }
        }
        else if (schemaElement.type === 'shops') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: ShopControl
            })
            wrappingObj.schema = {
                dataobj: schemaElement
            }
        }
        else if (schemaElement.type === 'cloacked') {

            //formSubmit
            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: ClockedControl
            })
            wrappingObj.schema = {
                schemaElement: schemaElement,
                storeMethod: schemaElement.fileStoreType,
                s3Bucket: schemaElement.s3Bucket,
                s3Dir: schemaElement.s3Dir,
                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                captureType: schemaElement.captureType ? schemaElement.captureType : null,
                mulitpleImage: schemaElement.imageTakenCount && schemaElement.imageTakenCount == 'mulitple' ? true : false,
                htmlLable: schemaElement.lableTxt,
                testFunction: testFunction,
                formSubmit: props.formSubmit ? props.formSubmit : false
            }
        }
        else if (schemaElement.type === 'html_camera') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: HtmlCamera
            })
            wrappingObj.schema = {
                storeMethod: schemaElement.fileStoreType,
                s3Bucket: schemaElement.s3Bucket,
                s3Dir: schemaElement.s3Dir,
                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                captureType: schemaElement.captureType ? schemaElement.captureType : null,
                mulitpleImage: schemaElement.imageTakenCount && schemaElement.imageTakenCount == 'mulitple' ? true : false,
                htmlLable: schemaElement.lableTxt,
                schemaEl: schemaElement
            }
        }
        else if (schemaElement.type === 'repeat') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: Repeater
            })

        }
        else if (schemaElement.type === 'clearing') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt),
                    formSchema
                ), renderer: Clearing
            })
            wrappingObj.schema = {
                info: schemaElement
            }
        }
        else if (schemaElement.type === 'data_grid') {


            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: DataGrid
            })
            wrappingObj.schema = {
                defaultArray: schemaElement.arrayValue,
                detail: schemaElement,

            }
        }
        else if (schemaElement.type === 'timeLine') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: TimeLine
            })
            wrappingObj.schema = {
                isDateEnable: schemaElement.isDateEnable,
                isLocationEnable: schemaElement.isLocationEnable,
                isResumeEnable: schemaElement.isResumeEnable,
                testFunction: testFunction,
                keyTxt: schemaElement.keyTxt

            }
        }
        else if (schemaElement.type === 'lable_draft') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: LableTemp
            })
            wrappingObj.schema = {
                type: 'string',
                title: schemaElement.lableTxt + " : " + schemaElement.arrayValue,
                label: schemaElement.arrayValue,
                display: schemaElement.defaultLableValue,
                all: schemaElement
            }
        }
        else if (schemaElement.type === 'location') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: GeoLocation
            })
            wrappingObj.schema = {
                defaultArray: schemaElement
            }
        }
        else if (schemaElement.type === 'number') {

            wrappingObj.schema = {
                type: 'integer',
                title: t(schemaElement.lableTxt)
            }
        }
        else if (schemaElement.type === 'displayData') {
            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: Details
            })
        }
        else {

            wrappingObj.schema = {
                type: typeOfElement,
                classNames: "customClassName",
                title: t(schemaElement.lableTxt),
                readOnly: true
            }


        }








        return wrappingObj

    }

    const createGroupLable = (schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: {}
        }

        wrappingObj.schema = {}




        let renderers = render
        renderers.push({
            tester: rankWith(
                3, //increase rank as needed
                scopeEndsWith(schemaElement.groupName)
            ), renderer: LableTemp
        })
        wrappingObj.schema = {
            type: 'string',
            title: schemaElement.groupName,
            label: schemaElement.groupName,
            display: schemaElement.groupName
        }






        return wrappingObj

    }


    const getUserInformationDatabase = async (id) => {
        return await dispatch(getUserById(id))
    }

    const createForm = async () => {
        setLoadingFormData(true)
        const session = localStorage.getItem('token')
        let userData = {}
        let profile = {}
        if (session) {
            userData = await dispatch(getLogedInUserData());
            if (userData.payload) {
                profile = userData.payload.profile

                if (profile?.userDB?.userRole) {
                    setUserRole(profile.userDB.userRole)
                }
            }

        }

        const group = props.elementGroup
        const schemaElement = props.jsonSchema
        setTotalFields(schemaElement.filter((e) => e.type === 'total'))
        let schemaObject = {}

        schemaObject["properties"] = {
            bitrix: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            },
            database: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            }

        }
        schemaObject['type'] = 'object'
        let uiSchema = {
            "type": "VerticalLayout",
            "elements": []
        }

        let dataBody = {
            bitrix: {
                main: {

                }
            },
            database: {
                main: {

                }
            }

        }

        // let obj_error = {
        //     'keyTxt': 'errorLine',
        //     'type': 'displayData'
        // }

        // const displayData = createGroupElementObj(obj_error)
        // //create error details
        // schemaObject.properties.database.properties.main.properties[obj_error.keyTxt] = displayData.schema

        // const uiDetailsEl = {
        //     type: 'HorizontalLayout',
        //     elements: [
        //         {
        //             scope: "#/properties/database/properties/main/properties/"+obj_error.keyTxt,
        //             type: "Control"
        //         }
        //     ]
        // }
        // uiSchema.elements.push(uiDetailsEl)

        const mainField = schemaElement.filter((e) => e.group === null && e.type != 'index' && e?.isHideInAdd !== true)

        if (mainField.length !== 0) {

            for (const mainEl of mainField) {
                const schemaObj = createNonGroupElement(mainEl)


                if (schemaObj) {
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        schemaObject.properties.bitrix.properties.main.properties[mainEl.keyTxt] = schemaObj
                    } else {
                        schemaObject.properties.database.properties.main.properties[mainEl.keyTxt] = schemaObj
                    }

                }

            }

            const chunkSize = 2;
            for (let i = 0; i < mainField.length; i += chunkSize) {
                const chunk = mainField.slice(i, i + chunkSize);
                let mainGroupSchema = {
                    "type": "HorizontalLayout",
                    "elements": []
                }

                for (const mainEl of chunk) {
                    let scope = ''
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                    } else {
                        scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope
                    })
                }
                uiSchema.elements.push(mainGroupSchema)
            }

        }


        //create error details




        for (const elementGroup of group) {
            if (elementGroup.groupType === 'object') {

                for (let elementData of schemaElement) {


                    if (elementData.type == 'index') {
                        continue
                    }
                    if (elementData?.isHideInAdd === true) {
                        continue
                    }
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        if(elementData.type=='autofill'){
                            const ref=elementData.absaulteData.parentFocusValue?elementData.absaulteData.parentFocusValue:null

                            if(ref){
                                const selectedSc=schemaElement.find((tes)=>tes.keyTxt==ref)
                                if(selectedSc?.fullObject){
                                    elementData={
                                        ...elementData,
                                        parentEnum:selectedSc.fullObject
                                    }
                                }
                                //console.log('autofill',selectedSc.fullObject)
                            }
                           
                        }
                        //console.log(elementData)
                        //add all renderers for elements from elementData
                        const resGropEn = createGroupElementObj(elementData)

                        if (resGropEn) {
                            if (elementData.fieldPlace === 'bitrix' || elementData.fieldPlace === '') {
                                schemaObject.properties.bitrix.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                                if (elementData.isRequired) {
                                    schemaObject.properties.bitrix.properties.main.required.push(elementData.keyTxt)
                                }

                                if (elementData.reference) {
                                    if (profile.userBit) {
                                        dataBody.bitrix.main = { ...dataBody.bitrix.main, [elementData.keyTxt]: profile.userBit[elementData.reference] ? profile.userBit[elementData.reference] : '-' }
                                    }

                                }



                            } else {
                                schemaObject.properties.database.properties.main.properties[elementData.keyTxt] = resGropEn.schema


                                if (elementData.isRequired) {
                                    schemaObject.properties.database.properties.main.required.push(elementData.keyTxt)
                                }
                                if (elementData.reference) {
                                    if (profile.userDB) {
                                        dataBody.database.main = { ...dataBody.database.main, [elementData.keyTxt]: profile.userDB[elementData.reference] ? profile.userDB[elementData.reference].toString() : '-' }

                                    }

                                }


                            }

                            // schemaObject.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                        }



                    }


                }
                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                },
                )
                const groupBelongElemeny = schemaElement.filter((e) => e.group === elementGroup.groupId)

                const perChunk = elementGroup.groupColtxt ? elementGroup.groupColtxt : 1;
                const resultArraySplices = groupBelongElemeny.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / perChunk)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, [])


                for (const splice of resultArraySplices) {



                    let mainGroupSchema = {
                        "type": "HorizontalLayout",
                        "elements": []
                    }




                    for (const mainEl of splice) {

                        let scope = ''
                        let option = {
                        }

                        let rule = {
                            "effect": "DISABLE",
                            "condition": {
                                "scope": "#/properties/dead",
                                "schema": {
                                    "const": true
                                }
                            }
                        }
                        if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {
                            // scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                            scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                        } else {
                            scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                        }

                        if (mainEl.type === 'date') {
                            option['format'] = 'date'
                            option['views'] = ['year', 'month', 'date']
                            option['dateFormat'] = "YYYY-MM-DD"
                            option['dateSaveFormat'] = "YYYY-MM-DD"

                        }

                        if (mainEl.multiLine) {
                            if (mainEl.multiLine == 'M') {
                                option['multi'] = true
                            }
                        }

                        // if (mainEl.reference) {

                        //     option['readonly'] = true

                        // }

                        if (mainEl.disableEl) {
                            rule = {
                                "effect": "ENABLE",
                                "condition": {
                                    "scope": "#/properties/dead",
                                    "schema": {
                                        "const": true
                                    }
                                }
                            }

                        }
                        if (props.formRuleSetElement && props.formCommonField) {
                            if (props.formRuleSetElement.length != 0) {
                                if (props.formCommonField.find((com) => com.key === mainEl.keyTxt)) {


                                } else {

                                    let selectedRuleEl = null

                                    let enumVal = ['foo']
                                    for (const ruleSet of props.formRuleSetElement) {
                                        selectedRuleEl = ruleSet
                                        if (ruleSet.visibleSet.find((rulA) => rulA === mainEl.keyTxt)) {
                                            enumVal.push(ruleSet.answer)
                                        }
                                    }
                                    rule = {
                                        "effect": "SHOW",
                                        "condition": {
                                            "scope": "#/properties/database/properties/main/properties/" + selectedRuleEl.element,

                                            "schema": { enum: enumVal }

                                        }
                                    }

                                }
                            }
                        }




                        mainGroupSchema.elements.push({
                            "type": "Control",
                            "scope": scope,
                            "options": option,
                            'rule': rule
                        })

                    }

                    uiSchema.elements.push(mainGroupSchema)
                }


            }
            else if (elementGroup.groupType === 'category') {


                // uiSchema.elements.push({
                //     "type": "Categorization",
                //     "text": elementGroup.groupLable
                // })

                let catObj = {
                    "type": "Categorization",
                    "elements": [],
                    "options": {
                        "variant": "stepper",
                        "showNavButtons": true
                    }
                }

                const relatedStage = group.filter((e) => e.selectedCategory === elementGroup.groupId && e.groupType === 'stage' && !e.hideInAdd)

                if (elementGroup.setStepper) {
                    let mainGroupSchema = {
                        "type": "HorizontalLayout",
                        "elements": []
                    }

                    let scope = ''
                    let option = {
                    }

                    scope = "#/properties/database/properties/main/properties/stepper_fake"

                    let elementData = {
                        "keyTxt": 'stepper_fake',
                        "lableTxt": 'stepper_fake',
                        "type": "stepper",
                        "relatedStage": relatedStage
                    }
                    const resGropEn = createGroupElementObj(elementData)

                    if (resGropEn) {
                        schemaObject.properties.database.properties.main.properties['stepper_fake'] = resGropEn.schema
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope,
                        "options": option,
                    })
                    uiSchema.elements.push(mainGroupSchema)
                }
                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                })

                if (relatedStage.length === 0) {
                    continue;
                }
                for (const elementRelatedStage of relatedStage) {

                    let stageObj = null

                    stageObj = {
                        "type": "Category",
                        "label": elementRelatedStage.groupLable,
                        "elements": []
                    }
                    let isMulti = false



                    if (elementRelatedStage.groupType === 'stage') {

                        const filteredEleimentSchemaCategory = schemaElement.filter((e) => e.group === elementRelatedStage.groupId)


                        let count = 0;
                        for (let elementDataStage of filteredEleimentSchemaCategory) {


                            //push answer grid

                            if (elementDataStage.type == "data_grid") {

                                if (elementDataStage.multiStage) {
                                    isMulti = true

                                    let dynamicGridSchema = []

                                    for (const eleArraObj of elementDataStage.arrayValue) {

                                        let lableTR = null
                                        if (eleArraObj.group_production) {
                                            if (eleArraObj.group_production.value) {
                                                if (Array.isArray(eleArraObj.group_production.value)) {
                                                    lableTR = eleArraObj.group_production.value[0]
                                                }
                                                //  
                                            }

                                        }
                                        //   const lableTR=eleArraObj?.group_production?.value[0]?eleArraObj.value[0]:null

                                        let absalutLable = null
                                        if (lableTR) {
                                            if (lableTR?.allansw?.PRODUCT_DESCRIPTION) {
                                                absalutLable = lableTR.allansw.PRODUCT_DESCRIPTION
                                            }
                                        }


                                        let stageObjDyn = {
                                            "type": "Category",
                                            "label": eleArraObj.group_production.selectedgroup + '-' + elementDataStage.lableTxt,
                                            "elements": []
                                        }
                                        let objeSchema = {
                                            "formId": elementDataStage.formId,
                                            "keyTxt": elementDataStage.keyTxt + '-' + eleArraObj.group_production.selectedgroup,
                                            "lableTxt": `${absalutLable ? absalutLable : eleArraObj.group_production.selectedgroup} ${elementDataStage.lableTxt}`,
                                            "type": "data_grid",
                                            "orderNumber": 1,
                                            "fieldPlace": elementDataStage.fieldPlace,
                                            "isMulitSelect": null,
                                            "multiLine": null,
                                            "selectedParentFormListId": elementDataStage.selectedParentFormListId,
                                            "selectedParentFormSchemaId": elementDataStage.selectedParentFormSchemaId,
                                            "defaultLableValue": null,
                                            "arrayValue": eleArraObj.group_production.value,
                                            "multiStage": true,
                                            "parentImage": elementDataStage.parentImageKey ? elementDataStage.parentImageKey : null,
                                            "eleArraObj": eleArraObj,
                                            "schemaElement": schemaElement ? schemaElement : []
                                        }



                                        //create dynamic schema
                                        const resGropEn = createGroupElementObj(objeSchema)
                                        let scope = ''
                                        if (resGropEn) {
                                            let rule = {
                                                "effect": "DISABLE",
                                                "condition": {
                                                    "scope": "#/properties/dead",
                                                    "schema": {
                                                        "const": true
                                                    }
                                                }
                                            }
                                            if (objeSchema.fieldPlace === 'bitrix' || objeSchema.fieldPlace === '') {
                                                schemaObject.properties.bitrix.properties.main.properties[objeSchema.keyTxt] = resGropEn.schema
                                                scope = "#/properties/bitrix/properties/main/properties/" + objeSchema.keyTxt
                                            } else {
                                                schemaObject.properties.database.properties.main.properties[objeSchema.keyTxt] = resGropEn.schema
                                                scope = "#/properties/database/properties/main/properties/" + objeSchema.keyTxt

                                                if (objeSchema.isRequired) {
                                                    schemaObject.properties.database.properties.main.required.push(objeSchema.keyTxt)
                                                }
                                                if (objeSchema.reference) {
                                                    if (profile.userBit) {
                                                        dataBody.database.main = { ...dataBody.database.main, [objeSchema.keyTxt]: profile.userBit[objeSchema.reference] ? profile.userBit[objeSchema.reference] : '-' }

                                                    }

                                                }
                                            }


                                            if (objeSchema.disableEl) {
                                                rule = {
                                                    "effect": "ENABLE",
                                                    "condition": {
                                                        "scope": "#/properties/dead",
                                                        "schema": {
                                                            "const": true
                                                        }
                                                    }
                                                }

                                            }

                                            stageObjDyn.elements.push({

                                                "type": "HorizontalLayout",
                                                "elements": [
                                                    {
                                                        "type": "Control",
                                                        "scope": scope,
                                                        'rule': rule
                                                    }

                                                ]
                                            })
                                        }

                                        //end of creatin dynamic
                                        catObj.elements.push(stageObjDyn)


                                        //dynamicGridSchema.push(dynGroupObj)
                                    }

                                    //for()

                                    continue;
                                } else {

                                    let dynValueArra = []

                                    for (const parentGroupV of elementDataStage.arrayValue) {
                                        for (const pushingObj of parentGroupV.group_production.value) {
                                            dynValueArra.push(pushingObj)
                                        }
                                    }
                                    elementDataStage = {
                                        ...elementDataStage,
                                        arrayValue: dynValueArra
                                    }
                                }


                            }


                            if (elementDataStage.type == "data_grid") {

                                if (elementDataStage.multiStage) {
                                    continue
                                }
                            }

                            if (elementDataStage.type == "answer_view") {

                                elementDataStage = {
                                    ...elementDataStage,
                                    elementList: schemaElement
                                }
                            }
                            //end answer grid

                            const resGropEn = createGroupElementObj(elementDataStage)
                            let scope = ''
                            if (resGropEn) {
                                let rule = {
                                    "effect": "DISABLE",
                                    "condition": {
                                        "scope": "#/properties/dead",
                                        "schema": {
                                            "const": true
                                        }
                                    }
                                }
                                if (elementDataStage.fieldPlace === 'bitrix' || elementDataStage.fieldPlace === '') {
                                    schemaObject.properties.bitrix.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/bitrix/properties/main/properties/" + elementDataStage.keyTxt
                                } else {
                                    schemaObject.properties.database.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/database/properties/main/properties/" + elementDataStage.keyTxt

                                    if (elementDataStage.isRequired) {
                                        schemaObject.properties.database.properties.main.required.push(elementDataStage.keyTxt)
                                    }
                                    if (elementDataStage.reference) {
                                        if (profile.userBit) {
                                            dataBody.database.main = { ...dataBody.database.main, [elementDataStage.keyTxt]: profile.userBit[elementDataStage.reference] ? profile.userBit[elementDataStage.reference] : '-' }

                                        }

                                    }
                                }


                                if (elementDataStage.disableEl) {
                                    rule = {
                                        "effect": "ENABLE",
                                        "condition": {
                                            "scope": "#/properties/dead",
                                            "schema": {
                                                "const": true
                                            }
                                        }
                                    }

                                }

                                stageObj.elements.push({

                                    "type": "HorizontalLayout",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": scope,
                                            'rule': rule
                                        }

                                    ]
                                })
                            }
                            count++
                        }

                    }
                    // if(schemaElement.filter((e) => e.group === elementRelatedStage.groupId && e.type==='data_grid')){

                    // }else{
                    if (!isMulti) {
                        catObj.elements.push(stageObj)
                    }
                    //catObj.elements.push(stageObj)
                    // }

                }
                uiSchema.elements.push(catObj)

            }

            else if (elementGroup.groupType === 'array') {

                const filterGroupEl = schemaElement.filter((e) => e.type != 'total' && e.type != 'clearing' && e.group === elementGroup.groupId)
                const resGropEn = createGroupElement(elementGroup, filterGroupEl)
                // const resGropEn = createGroupElement(elementGroup, schemaElement.filter((e) => e.type != 'total' && e.type != 'clearing'))



                if (resGropEn) {
                    let scope = ''
                    if (resGropEn.fieldPlace === 'bitrix' || resGropEn.fieldPlace === '') {
                        schemaObject.properties.bitrix.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/bitrix/properties/main/properties/" + elementGroup.groupId
                    } else {
                        schemaObject.properties.database.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/database/properties/main/properties/" + elementGroup.groupId
                    }
                    //schemaObject.properties.main.properties[elementGroup.groupId] = resGropEn.schema

                    let mainGroupSchema = {
                        "type": "VerticalLayout",
                        "elements": []
                    }


                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope,
                        "options": {
                            "showSortButtons": true,
                            "detail": {
                                'type': "VerticalLayout",
                                "elements": resGropEn.ui ? resGropEn.ui : [],

                            },
                            "elementLabelProp": 'name'
                        }
                    })

                    uiSchema.elements.push(mainGroupSchema)
                }

                const clearing = schemaElement.find((e) => e.type == 'clearing')
                if (clearing) {


                    const resGropEn = createGroupElementObj(clearing)

                    if (resGropEn) {

                        schemaObject.properties.database.properties.main.properties[clearing.keyTxt] = resGropEn.schema


                        let mainGroupSchema = {
                            "type": "HorizontalLayout",
                            "elements": []
                        }



                        let scope = ''
                        let option = {
                        }

                        let rule = {
                            "effect": "DISABLE",
                            "condition": {
                                "scope": "#/properties/bitrix/properties/main/properties/" + elementGroup.groupId,
                                "schema": {
                                    "const": schemaObject.properties.database
                                }
                            }
                        }

                        scope = "#/properties/database/properties/main/properties/" + clearing.keyTxt






                        mainGroupSchema.elements.push({
                            "type": "Control",
                            "scope": scope,
                            "options": option,
                            'rule': rule
                        })



                        uiSchema.elements.push(mainGroupSchema)

                    }






                }



            } else if (elementGroup.groupType === 'custom_workFlow') {
                let userArray = []
                for (let enumOfUser of elementGroup.enum) {

                    if (!userArray.find((e) => e == enumOfUser.const)) {
                        userArray.push(enumOfUser.const)
                    }

                }
                let approvalDomArray = [
                    {
                        type: "drop",
                        keyTxt: 'approcal_level',
                        lableTxt: 'approval lable',
                        enum: elementGroup.enum ? elementGroup.enum : [],
                        group: elementGroup.groupId
                    }
                ]

                const resGropEn = createGroupElement(elementGroup, approvalDomArray)

                // const resGropEn =
                // {
                //     "schema": {
                //         "title": "employee aproval range",
                //         "type": "array",
                //         "items": {
                //             "type": "object",
                //             "properties": {
                //                 "approcal_level": {
                //                     "type": "string",
                //                     "enum": userArray,
                //                     "title": "Approval level"
                //                 }
                //             }
                //         }
                //     },
                //     "ui":[
                //             {
                //                 "scope": "#/properties/group_production",
                //                 "type": "Control"
                //             }
                //         ]


                // }


                if (resGropEn) {
                    let scope = ''
                    if (resGropEn.fieldPlace === 'bitrix' || resGropEn.fieldPlace === '') {
                        schemaObject.properties.bitrix.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/bitrix/properties/main/properties/" + elementGroup.groupId
                    } else {
                        schemaObject.properties.database.properties.main.properties[elementGroup.groupId] = resGropEn.schema
                        scope = "#/properties/database/properties/main/properties/" + elementGroup.groupId
                    }
                    //schemaObject.properties.main.properties[elementGroup.groupId] = resGropEn.schema

                    let mainGroupSchema = {
                        "type": "VerticalLayout",
                        "elements": []
                    }


                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope,
                        "options": {
                            "detail": {
                                'type': "VerticalLayout",
                                "elements": resGropEn.ui ? resGropEn.ui : [],

                            },
                            "elementLabelProp": approvalDomArray[0].keyTxt ? approvalDomArray[0].grouped ? '-' : approvalDomArray[0].keyTxt : 'name'
                        }
                    })

                    uiSchema.elements.push(mainGroupSchema)
                }


            }


        }




        setFormSchema((prevState) => {
            return {
                ...prevState,
                schema: schemaObject,
                uischema: uiSchema,
                data: dataBody
            };
        });

        setRenderSuccess(true)
        setLoadingFormData(false)
    }

    const handleLanguageChanged = () => {
        createForm();
    }

    useEffect(() => {
        // $('.MuiButton-containedPrimary').addClass("Mui-disabled");


        i18n.on('languageChanged', handleLanguageChanged);
        createForm();
        setFormDataFunction()

    }, [props.formDetails]);


    const setFormDataFunction = () => {
        setFormDetails(props.formDetails)
    }

    const [errors, setErrors] = useState([])

    function targeGroupChange(data) {

        setFormSchema((prevState) => {
            return ({
                ...prevState,
                data: data
            });
        });


        //dispatch(changeDataReducer(data))

        props.changeDataTrigger(data)
        // setFormSchema((prevState) => {
        //     return ({
        //         ...prevState,
        //         data: tempData
        //     });
        // });

    }
    const setData = async (data, err) => {


        setErrors(err ? err : [])
        let tempData = data
        let tot = 0
        if (totalFields) {
            for (const totalFiel of totalFields) {
                if (tempData?.database?.main[totalFiel.group]) {
                    if (tempData.database.main[totalFiel.group].length !== 0) {
                        let tottt = 0
                        for (const subOfTot of tempData.database.main[totalFiel.group]) {

                            if (subOfTot[totalFiel.targetValue]) {

                                tottt = parseFloat(tottt) + parseFloat(subOfTot[totalFiel.targetValue])
                                // tempData.database.main.test=tempData.database.main[totalFiel.group][0]['price']
                            }
                        }
                        tempData.database.main[totalFiel.keyTxt] = tottt
                    }

                }
            }

        }


        //  if(data?.database?.main['795c4d86-9da9-4e98-8600-507701bc6c21']){

        //     if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'].length !== 0){

        //             if(data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']){
        //                 data.database.main.total=data.database.main['795c4d86-9da9-4e98-8600-507701bc6c21'][0]['price']
        //             }

        //     }

        // }




        // if(tempData?.database?.main){
        //     tempData.database.main['fb21d0cc-725b-4efa-81a3-2cadc4351d69'] =  [
        //         {
        //             "group_production": {
        //                 "selectedgroup": "120-171664",
        //                 "value": [
        //                     "8851512079327"
        //                 ]
        //             }
        //         }
        //     ]
        // }


        setFormSchema((prevState) => {
            return ({
                ...prevState,
                data: tempData
            });
        });


        dispatch(changeDataReducer(tempData))

        props.changeDataTrigger(tempData, err)
    }

    useEffect(()=>{
        if (props.clearState) {
            const err = null;
            props.setClearState(false)
            setFormSchema((prevState) => {
                return ({
                    ...prevState,
                    data: []
                });
            });

            dispatch(changeDataReducer([]))

            props.changeDataTrigger([], err)
        }
    }, [props])

    const testFunction = (data) => {
        props.draftFormStart(data)
    }
    const moveToFormSetting = () => {

        if (props?.formInformation?.id) {
            // history.push()
            window.open("/manage-form/scheema?id=" + props.formInformation.id, "_blank");
        }

    }


    return (

        <div className='jsonFormDynamic' id='jsonFormDynamicId'>
            {
                loadingFormData ? <><FullScreenLoader /></> : null
            }
            <div className="header-setting-action">
                {
                    userRole ? <>
                        {
                            userRole === 'Super Admin' ?
                                <div>
                                    <Button
                                        size="small"
                                        type="primary"
                                        icon={<SettingOutlined />}
                                        onClick={moveToFormSetting}
                                    />
                                </div>
                                : null
                        }
                    </> : '-'
                }
            </div>

            <div style={{ textAlign: 'left', marginTop: 10, marginBottom: 15 }}>
                {
                    errors.length === 0 ? <>

                        <AntAl message={t("Form Ready to submit")} type="success" showIcon />

                    </> : <>
                        <Badge onClick={() => {

                            setErrorShow(!isErrorShow)
                        }} count={errors.length}>
                            <Avatar
                                style={{
                                    backgroundColor: 'white',
                                    verticalAlign: 'middle',
                                    color: 'red'
                                }}
                                icon={<ExclamationCircleOutlined twoToneColor="#eb2f96" />} />
                        </Badge>

                        {
                            isErrorShow ? <div>
                                {errors.map((row) => (

                                    <>

                                        {

                                            row?.params?.missingProperty && props?.jsonSchema ?
                                                <Tag color="red"> {props.jsonSchema.find((e) => e.keyTxt === row.params.missingProperty).lableTxt ? t(props.jsonSchema.find((e) => e.keyTxt === row.params.missingProperty).lableTxt) : '-'}  {row?.message ? t(row.message) : '-'}</Tag>
                                                : null

                                        }

                                    </>
                                ))}
                            </div> : null
                        }

                    </>
                }

            </div>


            {
                loadingFormData ? <></> :
                    <>
                        <JsonForms

                            schema={formSchema.schema}
                            uischema={formSchema.uischema}
                            data={formSchema.data}
                            renderers={render}
                            cells={cellRender}
                            onChange={({ data, errors }) => setData(data, errors)}

                        />
                    </>
            }


            {/* <button onClick={() => {
                 
            }}>showData</button> */}

            <div style={{ textAlign: 'right' }} className="form-header-discription">
                {totalFields.map((row) => (
                    <h3>
                        {row.lableTxt} : {formSchema?.data?.database?.main[row.keyTxt] ? <>{`${formSchema.data.database.main[row.keyTxt]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</> : 0}
                    </h3>


                ))}
            </div>



        </div>




    );
}