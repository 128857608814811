import { fabClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConfig } from "../../config";

const initialState = {};





// export const getLanguage = createAsyncThunk(
//     "lanaguage/getLanguage",
//     async (code) => {
//         //axios request to get Users data
//         let endPoint = `${apiConfig.adminApi}/language/bundle/` + code;

//         const response = await axios.get(endPoint);
//         if (response?.data) {
//             return response.data;
//         } else {
//             throw Error;
//         }
//     }
// );

export const getLanguage = createAsyncThunk(
    "lanaguage/getLanguage",
    async (code) => {
         const endPoint = `${apiConfig.adminApi}/language/bundle/${code}` ;

        try {
            const response = await axios.get(endPoint,{
                headers: {
                  "Content-Type": "application/json", // sets request Content-Type
                  Accept: "application/json", // specifies expected response format
                },
              });
            if (response?.data) {
                return response.data;
            } else {
                throw Error;
            }
        } catch(err) {
            console.error(err);
            throw err;
        }
    }
);




export const addLanguageBulk = createAsyncThunk(
    "lanaguage/addLanguageBulk",
    async (code) => {
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/language/add-language-bulk/`;

        const response = await axios.post(endPoint,code);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);
export const getAllLanguage = createAsyncThunk(
    "lanaguage/getAllLanguage",
    async (code) => {
        //axios request to get Users data
        let endPoint = `${apiConfig.adminApi}/language/get-all`;
        // let endPoint = `http://localhost:4020/language/get-all`;


        const response = await axios.post(endPoint, code);
        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);


export const addNewLanguage = createAsyncThunk(
    "lanaguage/addNewLanguage",
    async (code) => {
        //axios request to get Users data
        let array = [];

        let endPoint = `${apiConfig.adminApi}/language/add-new-language`;

        const response = await axios.post(endPoint, code);

        array = response

        if (response?.data) {
            return response.data;
        } else {
            throw Error;
        }
    }
);


export const languageSlice = createSlice({
    name: "lanaguage",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getLanguage.pending, (state) => {
                state.usersFetchLoading = true;
            })



    },
});

export default languageSlice.reducer;


