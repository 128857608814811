/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Layout,
    Menu,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Switch,
} from "antd";
import { useDispatch } from "react-redux";
import { authenticatedWithUrl, signInWithCredintials, getUserAttribute } from "../redux/slices/users";
import { useKeycloak } from "@react-keycloak/web";

import { getAllLanguage, addLanguageBulk } from "../redux/slices/language";

import { updateSetting, getAppSetting } from "../redux/slices/configSetting";

import {
    getFormSchema,
    getFormId,
    getAllGroupRaducer,
} from "../redux/slices/formSettings";

import {
    DribbbleOutlined,
    TwitterOutlined,
    InstagramOutlined,
    GithubOutlined,
} from "@ant-design/icons";

import auth from "../auth/auth"
import { KeyLogRedirection } from "../components/layout/KeyLogRedirection";
function onChange(checked) {

}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const Auth = (props) => {
    const { keycloak, initialized } = useKeycloak();
    // const navigate = useNavigate();

    useEffect(() => {
        //console.log(keycloak)
        const isLoggedIn = keycloak.authenticated;

        console.log('emcode', isLoggedIn)
        // if(empCode){

        //     redirectWithUrl(empCode)

        // }
    }, [keycloak]);


    // const redirectWithUrl = async (myParam) => {
    //  // const hisoryUrl = props?.location?.state?.url ? props.location.state.url : null

    //   const respons = await dispatch(authenticatedWithUrl(myParam))

    //   if (respons.payload.login) {
    //     setStatus('Redirecting...')
    //     if (respons.payload.token) {

    //       auth.login(respons.payload.token, (res) => {
    //         if (res) {
    //           // alert(res)
    //           props.history.push("/dashboard")
    //         }
    //         //
    //       })
    //       //await signIn(respons.payload.token);
    //       //navigate("/dashboard/default");
    //     } else {
    //       setAuthenticating(false)
    //     }
    //   } else {
    //     setAuthenticating(false)
    //   }

    // }
    const [isLoading, setLoading] = useState(false)

    const [isAuthenticating, setAuthenticating] = useState(false)

    const [status, setStatus] = useState(null)

    const dispatch = useDispatch();

    const [footerLinks, setFooterLinks] = useState([])

    const checkAuth = async () => {
        if (auth.isAuthenticated()) {
            props.history.push("/dashboard")
        }

        const hisoryEmcode = props?.location?.state?.empCode ? props.location.state.empCode : null



        const hisoryUrl = props?.location?.state?.url ? props.location.state.url : null

        setLoading(true)
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('emp-code');
        if (myParam) {
            setLoading(false)
            setAuthenticating(true)
            setStatus('Authenticating...')
            const respons = await dispatch(authenticatedWithUrl(myParam))

            if (respons.payload.login) {
                setStatus('Redirecting...')
                if (respons.payload.token) {

                    auth.login(respons.payload.token, (res) => {
                        if (res) {
                            // alert(res)
                            props.history.push("/dashboard")
                        }
                        //
                    })
                    //await signIn(respons.payload.token);
                    //navigate("/dashboard/default");
                } else {
                    setAuthenticating(false)
                }
            } else {
                setAuthenticating(false)
            }

        } else if (hisoryEmcode) {
            setLoading(false)
            setAuthenticating(true)
            setStatus('Authenticating...')
            const respons = await dispatch(authenticatedWithUrl(hisoryEmcode))

            if (respons.payload.login) {
                setStatus('Redirecting...')
                if (respons.payload.token) {

                    auth.login(respons.payload.token, (res) => {
                        if (res) {
                            // alert(res)
                            if (hisoryUrl) {

                                props.history.push(hisoryUrl)
                            } else {
                                props.history.push("/dashboard")
                            }


                        }
                        //
                    })
                    //await signIn(respons.payload.token);
                    //navigate("/dashboard/default");
                } else {
                    setAuthenticating(false)
                }
            } else {
                setAuthenticating(false)
            }

        } else {
            setLoading(false)
        }
    }


    const onFinish = async (values) => {
        try {
            const logDet = await dispatch(signInWithCredintials({
                userKey: values.email,
                password: values.password
            }));

            if (logDet.payload) {
                if (logDet.payload !== false) {

                    auth.login(logDet.payload.token, (res) => {
                        if (res) {
                            // alert(res)
                            props.history.push("/dashboard")
                        }
                        //
                    })
                }
            }
            //navigate("/dashboard/default");
        } catch (error) {
            const message = error.message || "Something went wrong";

            // setStatus({ success: false });
            // setErrors({ submit: message });
            // setSubmitting(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        ;
    };


    const getFormData = async () => {

        const formData = await dispatch(getFormId("app_config"));
        if (formData) {
            if (formData.payload) {
                if (formData.payload.length != 0) {
                    const formSchema = await dispatch(
                        getFormSchema(formData.payload[0].id)
                    );

                    const gg = await dispatch(getAllGroupRaducer(formData.payload[0].id));
                    const getAnswer = await dispatch(getAppSetting())

                    let arrayOfFooter = []
                    let footerAnswer = {}
                    if (getAnswer?.payload?.data?.footer) {
                        footerAnswer = getAnswer.payload.data.footer

                    } else {
                        return
                    }


                    if (gg.payload) {

                    }

                    if (formSchema.payload) {
                        const footerLink = formSchema.payload
                        for (const lin of footerLink) {
                            const answer = footerAnswer[lin.keyTxt] ? footerAnswer[lin.keyTxt] : null
                            if (answer) {
                                arrayOfFooter.push({
                                    lable: lin.lableTxt,
                                    url: answer
                                })
                            }
                        }
                        setFooterLinks(arrayOfFooter)
                    }
                }
            }
        }

    };
    const [userName, setUserName] = useState(null)
    const [realM, setRealEm] = useState(null)
    const setUpPage = (Info) => {
        setUserName(Info?.location?.state?.email ? Info.location.state.email : null)
        setRealEm(Info?.location?.state?.realM ? Info.location.state.realM : null)
    };

    const logingWithKeyLock = async () => {
        keycloak.realm = realM
        await keycloak.login({ loginHint: userName })
    };


    const logingWithKeyLockGoogle = async () => {
        // let keycloakv = keycloak
        // keycloakv={
        //     ...keycloakv,
        //     realm:realM
        // }
        keycloak.realm = realM
        //keycloak.loginHint='sangeeth'
        // console.log(keycloakv)

        await keycloak.login({ idpHint: 'google', loginHint: userName })
    };



    useEffect(() => {
        setUpPage(props)

    }, []);


    return (

        <>
            <Layout className="layout-default layout-signin">
                <Header>
                    <div className="header-col header-brand">
                        <h5>Supara Intranet</h5>
                    </div>

                    <div className="header-col header-btn">

                    </div>
                </Header>

                <Content className="signin">

                    <Row justify="center">
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 18 }}
                            md={{ span: 12 }}
                            lg={{ span: 10 }}
                        >
                            <Title className="mb-15">Sign In</Title>
                            <Title className="font-regular text-muted" level={5}>
                                Enter password to sign in
                            </Title>
                            <Form
                                layout="vertical"
                                className="row-col"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    className="username"
                                    label="Email / Employee code"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your email!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email / Employee code" />
                                </Form.Item>

                                <Form.Item
                                    className="username"
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                {/*<Form.Item*/}
                                {/*  name="remember"*/}
                                {/*  className="aligin-center"*/}
                                {/*  valuePropName="checked"*/}
                                {/*>*/}
                                {/*  <Switch defaultChecked onChange={onChange} />*/}
                                {/*  Remember me*/}
                                {/*</Form.Item>*/}

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        danger
                                        htmlType="submit"
                                        style={{ width: "100%" }}
                                    >
                                        LOGIN
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>

                    </Row>
                    {!keycloak.authenticated && (
                        <>
                            <Button
                                onClick={logingWithKeyLock}
                            >
                                Login With Keycloak
                            </Button>
                            <Button
                                onClick={logingWithKeyLockGoogle}
                            >
                                Login With Google
                            </Button>
                        </>

                    )}
                    {!!keycloak.authenticated && (
                        <KeyLogRedirection log={keycloak} history={props.history} />
                    )}

                </Content>

                <Footer>
                    <Menu mode="horizontal">
                        {footerLinks.map((row) => (

                            <Menu.Item><a
                                href={row.url}
                                className="navlink-cutom"
                                style={{ color: '#1414145c' }}
                                target="_blank"
                            >
                                {row.lable}
                            </a></Menu.Item>

                        ))}

                    </Menu>


                </Footer>
            </Layout>
        </>
    )
}
export default Auth;
