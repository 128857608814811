import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../style.css";
import { useTranslation } from "react-i18next";

import { Grid, Link, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import { FormViewInterface } from "../viewInteface/FormViewInterface";

import {
  getFormAnswerList,
  deleteSelectedAnswer,
} from "../../../../redux/slices/formSettings";

import IconButton from "@mui/material/IconButton";

import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { FormInterfacedit } from "../intefaces/FormInterfacedit";

import {
  Table as AntTable,
  Pagination,
  Dropdown,
  Menu,
  notification,
  Button
} from "antd";

import {
  SettingTwoTone,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined
} from "@ant-design/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AnswerList = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [answerList, setAnswerList] = useState([]);

  const [isOpenAnswerView, openAnswerView] = useState(false);

  const [isOpenAnswerEdit, openAnswerEdit] = useState(false);

  const [groupList, setGroupList] = useState([]);
  const [schemaComponent, setScheemaComponent] = React.useState([]);

  const [selectedData, setSelectedData] = React.useState({});

  // pagination
  const [dataCount, setDataCount] = useState(0);

  const [selectedPage, setSelectedPage] = useState(1);

  const [dataPerPage, setDataPerPage] = useState(10);

  //pagination end

  //table
  const [columns, setColomn] = useState([]);
  const [answers, setAnswers] = useState([]);

  // sorting

  const [sortableKey, setSortablekey] = useState(false);

  const [sortableAsyn, setSortableAsyn] = useState(true);

  const [isDeleteViewShow, setDeletedView] = useState(false);

  const [deletedItem, setSelectedItem] = useState(null);
  //
  const [serachKey, setSearchKey] = useState(null);

  const handleMenuClick = (e) => {
   
    const action = e.key;
    const id = e.item.props.items.id;

    switch (action) {
      case "1":
        editAnswer(id);
        break;
      case "2":
        viewAnswer(id);
        break;
      case "3":
        setDeletedView(true);
        setSelectedItem(id.id);
        break;
      default:
        return;
    }
  };

  const getAnswerList = async (
    id,
    tempSort = false,
    colArray = columns,
    selectedPageParam = 1,
    rowPerRequest = dataPerPage
  ) => {
    const data = {
      formData: id,
      sorting: tempSort ? tempSort : sortableKey ? sortableKey : false,
      order: sortableKey ? !sortableAsyn : false,
      search: serachKey ? serachKey : null,
    };

    setSortablekey(tempSort);
    if (tempSort) {
      setSortableAsyn(!sortableAsyn);
    }
    const res = await dispatch(getFormAnswerList(data));

    const jsonRes = res.payload.data;
    setDataCount(jsonRes.total);

    let tempArray = [];
   
    for (let i = 0; i < jsonRes.data.length; i++) {
      let el = jsonRes.data[i];
      let row = {};

      for (let i = 0; i < colArray.length; i++) {
        let col = colArray[i];
   
        if (col.key === "createdAt") {
         
          //row[col.key]='s'
          row[col.key] = el.createdAt
            ? moment(el.createdAt).format("MMMM Do, YYYY hh:mm A")
            : "-";
        } else {
          row[col.key] = el.data.database.main[col.key];
        }
      }
      row.action = (
        <>
          <Dropdown.Button
          trigger={['click']}
            className="action-menu-button"
            overlay={
              <Menu
                onClick={handleMenuClick}
                items={[
                  {
                    label: "Edit Answers",
                    key: "1",
                    items: { id: el },
                    icon: <EditOutlined />,
                  },
                  {
                    label: "View Answers",
                    key: "2",
                    items: { id: el },
                    icon: <EyeOutlined />,
                  },
                  {
                    label: "Delete Answers",
                    key: "3",
                    items: { id: el },
                    icon: <DeleteOutlined className="delete-icon"/>,
                  },
                ]}
              />
            }
            icon={<SettingTwoTone twoToneColor="#28a745" />}
          />
        </>
      );
      tempArray.push(row);
    }


    setSelectedPage(selectedPageParam);
    setAnswers(tempArray);

    if (res.payload) {
      if (res.payload.data) {
        if (res.payload.data.data) {
          setAnswerList(res.payload.data.data);
        }
      }
    }
  };

  const searchAnswerList = async (id) => {
    const data = {
      formData: id,
      sorting: sortableKey ? sortableKey : false,
      order: sortableKey ? sortableKey : false,
      search: serachKey ? serachKey : null,
    };

    const res = await dispatch(getFormAnswerList(data));

    if (res.payload) {
      if (res.payload.data) {
        if (res.payload.data.data) {
          setAnswerList(res.payload.data.data);
        }
      }
    }
  };

  const viewAnswer = (row) => {
    setSelectedData(row);
    openAnswerView(true);
  };

  const editAnswer = (row) => {
    setSelectedData(row);
    openAnswerEdit(true);
  };

  const handleCloseUserView = () => {
    openAnswerView(false);
  };

  const handleCloseUserEdit = () => {
    openAnswerEdit(false);
    getAnswerList(props.formId);
  };

  const deleteItem = async () => {
    const val = {
      id: deletedItem,
      formId: props.formId,
    };
    const res = await dispatch(deleteSelectedAnswer(val));

    if (res.payload) {
      searchAnswerList(props.formId);
      setDeletedView(false);
    }
  };

  useEffect(() => {
    setGroupList(props.groupList);
    setScheemaComponent(props.schema);

    setColomn(props.columns);
    getAnswerList(props.formId, false, props.columns);
   
  }, [props]);

  return (
    <>
      <Grid
        className="page-action-header"
        style={{ padding: 8 }}
        container
        spacing={2}
      >
        <Grid
          style={{ padding: 8, paddingBottom: 0 }}
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
         <TextField
            label="Search"
            value={serachKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            variant="outlined"
            size="small"
          />
        </Grid>

        
      </Grid>

      <Grid
        className="page-action-header"
        style={{ padding: 8 }}
        container
        spacing={2}
      >
        <Grid style={{ padding: 8, paddingBottom: 0 }} item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={2} lg={2}>
                
              <Button
                block={true}
                danger
                onClick={() => searchAnswerList(props.formId)}
                icon={<SearchOutlined />}
              >
                Search
              </Button>

             
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>

      
      <div className="table-responsive">
        <AntTable
          columns={columns}
          dataSource={answers}
          pagination={true}
          className="ant-border-space"
        />
      </div>

      <Dialog
        fullScreen
        open={isOpenAnswerView}
        onClose={handleCloseUserView}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseUserView}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t(selectedData.id)}
            </Typography>
            <Button
              variant="contained"
              color="success"
              onClick={handleCloseUserView}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent className="full-screen-form-container-view">
          <FormViewInterface
            elementGroup={groupList}
            jsonSchema={schemaComponent}
            id={selectedData.data}
            formDetails={selectedData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={isOpenAnswerEdit}
        onClose={handleCloseUserEdit}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseUserEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t(selectedData.id)}
            </Typography>
            <Button
              variant="contained"
              color="success"
              onClick={handleCloseUserEdit}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <FormInterfacedit
          jsonSchema={schemaComponent}
          elementGroup={groupList}
          data={selectedData}
          formDetails={props.formId}
        />
      </Dialog>

      <Dialog
        open={isDeleteViewShow}
        onClose={() => {
          setDeletedView(false);
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Remove Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure! item will be remove from the list
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDeletedView(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteItem();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
