

import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select, Alert } from 'antd';
import { init } from 'i18next';

import { Button } from 'antd';
import { FormDynamicEdit } from "../../intefaces/FormDynamicEdit";
import {
    getCOnditionalEnum
} from "../../../../../redux/slices/formSettings";

import {
    getFormSchema,
    getAllGroupRaducer,
} from "../../../../../redux/slices/formSettings";

const { Option } = Select;

const FormTemplate = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [valueData, setValueData] = useState({})
    const [lableName, setLableName] = useState('')
    const [isLoading, loading] = useState(true)
    const [warning, setWarning] = useState(null)

    const [itemList, setItemList] = useState([])

    const [groupList, setGroupList] = useState([]);
    const [selectedData, setSelectedData] = React.useState({});
    const [schemaComponent, setScheemaComponent] = React.useState([]);

    const [isHideInEdit, setHideInEdit] = useState(false)

    const [formSchemaLoading, setFormSchemaLoading] = useState(false)
    const [selectedForm, setSelectedForm] = useState(null)

    const [formValue, setFormValue] = useState(null)

    const basedValue = useSelector((state) => {


        let error = null
        if (state?.ruleFormElement?.dataset) {
            const data = state.ruleFormElement.dataset
            if (props?.schema?.schemaElement?.basedOn) {
               
                    const conitionBase = props?.schema?.schemaElement?.basedOn
                    if (data?.database?.main[conitionBase]) {
                        error = data.database.main[conitionBase]
                    }
                

            }

        }

        return error

    })

    const updateData = async () => {

        // setValueData(props.data)
        let pro = valueData
        pro = {
            ...pro,
            dataset: {
                data: formValue.dataset
            }
        }
         
        props.handleChange(props.path, pro)


    };

    const changeDataTriggerEdit = async (data) => {
        setFormValue(data)
        // 
        //updateData(data)
        // setFormDataSetEdit(data)
    }

    const formDraftEditTimeLine = async (data) => {
        //return

    }

    const getFormData = async (e) => {
        // setValueData((prevState) => {
        //     return ({
        //         ...prevState,
        //         form: e
        //     });
        // });
       
        const ob = itemList.find((el) => el.title == e)
        
       if(ob){
        setSelectedForm(e)
        // 
        setFormSchemaLoading(true)
        const schemaPayload = await dispatch(getFormSchema(ob.const))
        const gruopPayload = await dispatch(getAllGroupRaducer(ob.const))
        setGroupList(gruopPayload?.payload ? gruopPayload.payload : [])
        setScheemaComponent(schemaPayload?.payload ? schemaPayload.payload : [])
      

       
        setFormSchemaLoading(false)
       }
            
       if(props.data){
        setValueData(props.data)
    }
           
        
       
    };

    const init = async (e) => {
        loading(true)
         




        if (props?.schema?.schemaElement?.warning) {
            setWarning(props.schema.schemaElement.warning)

        }

        if (props?.schema?.schemaElement?.enum) {

            setItemList(props.schema.schemaElement.enum)

        }

        if (props?.uischema?.rule?.effect) {
            if (props.uischema.rule.effect === 'SHOW') {
                setHideInEdit(true)
            } else {
                setHideInEdit(false)
            }
        } else {
            setHideInEdit(false)
        }



        loading(false)

    };

    useEffect(() => {
        getFormData(basedValue)
        // 
       

        //
    }, [basedValue]);

    useEffect(() => {

         
        setLableName(props.label ? props.label : '-')

        //
    }, [props]);
    //

    const setDDD = async () => {
        if (props.data) {
            // let obj = {
            //     form: e,
            //     obj: ob,
            //     dataset: null
            // }
            setValueData(props.data)

            // setValueData(props.data)
            setFormSchemaLoading(true)
            if (props.data.form) {
                setSelectedForm(props.data.form)
                const schemaPayload = await dispatch(getFormSchema(props.data.form))
                const gruopPayload = await dispatch(getAllGroupRaducer(props.data.form))
                setGroupList(gruopPayload?.payload ? gruopPayload.payload : [])
                setScheemaComponent(schemaPayload?.payload ? schemaPayload.payload : [])
            }
            setFormSchemaLoading(false)
             
        }
    }
    useEffect(() => {

        setDDD()
         
        //
    }, [props.data]);

    useEffect(() => {

        init()

        //
    }, [props]);
    return (
        <div className='additional-infor'>
            {
                !isLoading ? <div>

                    
                </div> : null
            }

            {
                formSchemaLoading ? 'loading' : <>

                    {
                        schemaComponent.length === 0 ? null :
                            <>
                                <FormDynamicEdit
                                    jsonSchema={schemaComponent}
                                    elementGroup={groupList}
                                    data={valueData.dataset ? valueData.dataset : null}
                                    dynamic={true}
                                    formDraftEditTimeLine={formDraftEditTimeLine}
                                    formDetails={selectedForm}
                                    changeDataTrigger={(data) => changeDataTriggerEdit(data)}
                                />
                                <Button  type="primary" size="small" onClick={updateData}>save aditional information</Button>
                            </>
                    }


                </>
            }


        </div>



    );
}



export default withJsonFormsControlProps(FormTemplate);
