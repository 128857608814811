import React, { useState, useEffect } from "react";
import { SqureLable } from '../../../components/elements/SqureLable'

import {
    useDispatch, useSelector
} from "react-redux";
import {Button, Col, Image, Result, Row} from "antd";
import {useTranslation} from "react-i18next";
import {
    closeClockOutMenu
} from "../../../redux/slices/attandance/markAttandanceSetting";

const style = {
    padding: '8px 0',
    marginBottom: '10px'
};


export function ClockOutResult(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const closeModel = () =>{
        dispatch(closeClockOutMenu())
    }

    const init = () =>{

    }
    const selectedAnswer = useSelector((state) => {
        let data = null
        const attandanceState=state.markAttandance?state.markAttandance:null
        if(attandanceState?.selectedAnswer){
            data=attandanceState.selectedAnswer
        }
        return data
    })
    const attandanceDataSql = useSelector((state) => {
        let data = null
        const attandanceState=state.markAttandance?state.markAttandance:null
        if(attandanceState?.attandanceDataSql){
            data=attandanceState.attandanceDataSql
        }
        return data
    })

    const answer = useSelector((state) => {
        let data = null
        const attandanceState=state.markAttandance?state.markAttandance:null
        if(attandanceState?.answer){
            data=attandanceState.answer
        }
        return data
    })

    useEffect(() => {

        init();

    }, [props]);



    return (
        <>
            <Result
                style={{ background: 'white' }}
                status="success"
                title="Clocked Out successfully!"
                subTitle='-'
                extra={
                    <>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} >
                                <SqureLable value={
                                    attandanceDataSql?.EmployeeName ?attandanceDataSql.EmployeeName:
                                                answer?.createdBy?.UF_USR_1642854306150 ?
                                                    answer.createdBy.UF_USR_1642854306150 : '-'

                                }  lable={t('Employee name')}/>

                            </Col>

                            <Col xs={24} sm={12} >
                                <SqureLable value={
                                    answer?.createdBy?.UF_USR_1642854306150 ?
                                        answer.createdBy.UF_USR_1642854306150 : '-'
                                }  lable={t('EmployeeCode')}/>

                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value= {
                                    answer?.data?.database?.main?.shops?.StoreID ?
                                        answer.data.database.main.shops.StoreID : '-'
                                }  lable={t('Store Id')}/>

                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value= {
                                    answer?.data?.database?.main?.shops?.StoreName ?
                                        answer.data.database.main.shops.StoreName : '-'
                                }  lable={t('Store Name')}/>
                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value=  {
                                    answer?.data?.database?.main?.Brand_ ?
                                        answer.data.database.main.Brand_ : '-'
                                }  lable={t('Brand')}/>

                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value=   {
                                    answer?.data?.database?.main?.Product_Type ?
                                        answer.data.database.main.Product_Type : '-'
                                }  lable={t('Product Type')}/>

                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value={
                                    answer?.data?.database?.main?.Sales_Type ?
                                        answer.data.database.main.Sales_Type : '-'
                                }  lable={t('Sales Type')}/>

                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value={
                                    answer?.data?.database?.main?.clocked?.clockIn?.dateTime ?
                                        answer.data.database.main.clocked.clockIn.dateTime : '-'
                                }  lable={t('Clock In Date')}/>

                            </Col>
                            <Col xs={24} sm={24} >
                                <SqureLable value={`Lat :${
                                    answer?.data?.database?.main?.clocked?.clockIn?.image?.absalute_location?.latitude ?
                                        answer.data.database.main.clocked.clockIn.image.absalute_location.latitude : '-'
                                } , Long : ${ answer?.data?.database?.main?.clocked?.clockIn?.image?.absalute_location?.longitude ?
                                    answer.data.database.main.clocked.clockIn.image.absalute_location.longitude : '-'}` }  lable={t('Clock In location')}/>

                            </Col>




                        </Row>

                        <div>
                            {
                                answer?.data?.database?.main?.clocked?.clockOut?.image?.location ? <>
                                    <Image
                                        width={200}
                                        src={answer.data.database.main.clocked.clockOut.image.location}
                                    />
                                </> : null
                            }


                        </div>

                        <Row gutter={16}>

                            <Col xs={24} sm={12} >
                                <SqureLable value= {
                                    answer?.data?.database?.main?.clocked?.clockOut?.dateTime ?
                                        answer.data.database.main.clocked.clockOut.dateTime : '-'
                                }  lable={t('Clock Out Date')}/>
                            </Col>
                            <Col xs={24} sm={12} >
                                <SqureLable value={`Lat :${
                                    answer?.data?.database?.main?.clocked?.clockOut?.image?.absalute_location?.latitude ?
                                        answer.data.database.main.clocked.clockOut.image.absalute_location.latitude : '-'
                                } , Long : ${ answer?.data?.database?.main?.clocked?.clockOut?.image?.absalute_location?.longitude ?
                                    answer.data.database.main.clocked.clockOut.image.absalute_location.longitude : '-'}` }  lable={t('Clock Out location')}/>

                            </Col>





                        </Row>
                        <div>
                            {
                                answer?.data?.database?.main?.clocked?.clockOut?.image?.location ? <>
                                    <Image
                                        width={200}
                                        src={answer.data.database.main.clocked.clockOut.image.location}
                                    />
                                </> : null
                            }


                        </div>

                        <Button style={{ marginTop: 15 }} type="primary" danger onClick={closeModel} >
                            {t('Go Attandance')}
                        </Button>
                    </>
                }
            />
        </>
    );
}