import React, { useState, useEffect } from "react";
import {
    useDispatch
} from "react-redux";
import './style.css'
// import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import {
    Card as MuiCard,
    // Typography

} from "@mui/material";
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { spacing } from "@mui/system";


import { ManageFormModel } from './compoment/ManageFormModel'

import { RuleManageMent } from './viewInteface/RuleManageMent'
import { RuleElement } from './viewInteface/RuleElement'

import { getAllFormsReducer, onChangeFormStatusRaducer ,changeFormValueReducer} from "../../../redux/slices/formSettings"
import { openRuleModel } from "../../../redux/slices/formRuleSetting"
import { openRuleElementModel } from "../../../redux/slices/ruleElement"

import {
    FileAddOutlined, SettingTwoTone,
    DeleteOutlined, EditOutlined, ForwardOutlined, CopyOutlined, UnorderedListOutlined,BorderlessTableOutlined 
} from '@ant-design/icons';




//antd

import {
    Input,
    Row,
    Col,
    Radio,
    Table,
    Button,
    Select as AntSelect,
    Pagination,
    Dropdown,
    Menu,
    notification, Checkbox
} from "antd";

import { SearchOutlined } from '@ant-design/icons';

const { Option } = AntSelect;

const ManageForm = (props) => {

    const history = useHistory();

    // const { t } = useTranslation();

    const dispatch = useDispatch();


    const Card = styled(MuiCard)(spacing);


    const statusList = [
        {
            lable: 'Draft',
            key: 'Draft'
        },
        {
            lable: 'Active',
            key: 'Active'
        },
        {
            lable: 'Deleted',
            key: 'Deleted'
        },

        {
            lable: 'Deactivated',
            key: 'Deactivated'
        },
    ];





    const [openFormManageModelm, setFormManagementModel] = useState(false)


    // pagination
    const [dataCount, setDataCount] = useState(0)

    const [selectedPage, setSelectedPage] = useState(1)

    const [dataPerPage, setDataPerPage] = useState(10)

    //pagination end
    const [searchFilter, setSearchFormName] = useState(null)
    


    const handleFormManageModel = (e) => {
        setFormManagementModel(e)
    }
    const handleCloseFormManagement = () => {
        setFormManagementModel(false)
    }


    const [showFormAnswerList, setShowFormAnswerList] = useState(false)








    const onChangeFormStatus = async (e, row) => {


        let obj = {
            "id": row.id,
            "value": {
                "name": row.name,
                "description": row.description,
                "status": e,
                'formType': row.formType,
                'selectedUser': row.selectedUser,
            }
        }
        const res = await dispatch(onChangeFormStatusRaducer(obj))


        if (res) {
            notification.success({
                message: `Success`,
                description:
                    `Form status changed to ${e}`,

            });
            getAllForms()
        }
    }
    const [formData, setFormData] = useState([])

    const handleMenuClick = (e) => {
        const action = e.key
        const id = e.item.props.items.id
        let link = ""
        switch (action) {
            case '1':
                onChangeFormStatus('Remove', id)
                break;
            case '2':

                history.push("/manage-form/scheema?id=" + id)
                break;
            case '3':
                if (e.item.props.items.type === 'PF') {

                    history.push("/public-form/submit-form?id=" + id)
                } else {
                    history.push("/internal-form/submit-form?id=" + id)

                }

                break;
            case '4':

                const prot = window.location.protocol
                const hostname = window.location.hostname
                let url = ''
                if (e.item.props.items.type === 'PF') {
                    if (window.location.port) {
                        url = ':' + window.location.port + "/public-form/submit-form?id=" + id
                    } else {
                        url = "/public-form/submit-form?id=" + id
                    }
                } else {
                    if (window.location.port) {
                        url = ':' + window.location.port + "/internal-form/submit-form?id=" + id
                    } else {
                        url = "/internal-form/submit-form?id=" + id
                    }
                }

                navigator.clipboard.writeText(prot + '//' + hostname + url)
                notification.success({
                    message: `Form Access Link`,
                    description:
                        `Form access link copied ${prot + '//' + hostname + url}`,

                });


                break;
            case '5':

                let link = "/internal-form/answer?id=" + id
                history.push(link)
                break;
            case '6':

                break;
            default:
                return
        }
    };

    const showInDashboard = async (e, el, keyWord) => {
         
         
        let obj = {
            value: e,
            key: el.id,
            keyWord: keyWord
        }
        await dispatch(changeFormValueReducer(obj))
        getAllForms()
      //  getFormSchimaToDom(myParam);
    }

    const openRuleFuntion = async (el) => {
        const obj={
            form:el
        }
         dispatch(openRuleModel(obj))
      //  getFormSchimaToDom(myParam);
    }

    const openRuleElementFuntion = async (el) => {
        const obj={
            form:el
        }
         dispatch(openRuleElementModel(obj))
      //  getFormSchimaToDom(myParam);
    }
    
    


    const getAllForms = async (
        selectedPage = 1,
        rowPerRequest = dataPerPage
    ) => {

        const payload = {
            selectedPage: selectedPage,
            rowPerRequest: rowPerRequest,
            pagination: true,
            searchFilter:searchFilter
        }

        await dispatch(getAllFormsReducer(payload))
            .then((res) => {

                const jsonRes = res.payload.respons
                handleFormManageModel(false)
                const tempArra = []
                setDataCount(jsonRes.total)
                for (let i = 0; i < jsonRes.data.length; i++) {
                    let el = jsonRes.data[i];

                    const row = {
                        key: i,
                        id: (el.id),
                        name: (el.name),


                        form_type: (
                            <> {el.formType ? <div className="form-type-chip">{el.formType}</div> : '-'}</>
                        ),
                        show_in_dashboard: (
                            <><Checkbox checked={el.show_in_dashboard} onChange={(e) => showInDashboard(e.target.checked, el, 'show_in_dashboard')} /></>
                        ),
                        status: (
                            <>{
                                <AntSelect
                                    defaultValue={el.status}
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={(e) => { onChangeFormStatus(e, el) }}
                                >
                                    {statusList.map((option) => (

                                        <Option value={option.key}>{option.lable}</Option>
                                    ))}


                                </AntSelect>


                            }
                            </>
                        ),
                        action: (
                            <>
                                <Dropdown.Button trigger={['click']} className="action-menu-button" overlay={
                                    <Menu
                                        onClick={handleMenuClick}
                                        items={[
                                            {
                                                label: 'Delete',
                                                key: '1',
                                                items: { id: el.id },
                                                icon: <DeleteOutlined className="delete-icon" />,
                                            },
                                            {
                                                label: 'Edit Schema',
                                                key: '2',
                                                items: { id: el.id },
                                                icon: <EditOutlined />
                                            },
                                            el.formType ? (el.formType !== 'AF' ?
                                                {
                                                    label: 'Move to Form',
                                                    key: '3',
                                                    items: { id: el.id, type: el.formType },
                                                    icon: <ForwardOutlined />,
                                                }
                                                : null) : null,
                                            el.formType ? (el.formType !== 'AF' ?
                                                {
                                                    label: 'Copy Form Link',
                                                    key: '4',
                                                    items: { id: el.id, type: el.formType },
                                                    icon: <CopyOutlined />,
                                                }
                                                : null) : null,
                                            el.formType ? (el.formType !== 'AF' ?
                                                {
                                                    label: 'View Answers',
                                                    key: '5',
                                                    items: { id: el.id },
                                                    icon: <UnorderedListOutlined />,
                                                }
                                                : null) : null,

                                        ]}
                                    />


                                } icon={<SettingTwoTone twoToneColor="#28a745" />} />
                                 <Button size="small" onClick={()=>openRuleFuntion(el)} type="text" shape="circle" icon={<BorderlessTableOutlined  />} />
                                 <Button size="small" onClick={()=>openRuleElementFuntion(el)} type="text" shape="circle" icon={<BorderlessTableOutlined  />} />

                            </>
                        ),
                    }

                    tempArra.push(row)
                }

                setFormData(tempArra)
            })


    }

    const addForm = (e) => {
        alert(e)
    }

    const changePagination = (pageNo, pageSize) => {

        setSelectedPage(pageNo)
        getAllForms(pageNo, pageSize)

        //
    }

    useEffect(() => {

        getAllForms()



    }, []);


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: '35%',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: '25%',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Form Type",
            key: "form_type",
            dataIndex: "form_type",
            width: '10%'
        },
        {
            title: "Show in Dashboard",
            key: "show_in_dashboard",
            dataIndex: "show_in_dashboard",
            width: '10%'
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: '20%'
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            width: '5%'
        },
    ];








    const onChange = (e) => console.log(e) ;

    return (
        <>

            <div className="page-continer">


                <div className="page-action-header">
                    <Row gutter={[50, 0]}>
                       
                        <Col xs="24"  xl={32} >
                        <Input placeholder="Form name" onChange={(e)=>setSearchFormName(e.target.value)}/>
                        </Col>
                        <Col xs="24" xl={32}>
                            <Button type="primary" danger onClick={() => { getAllForms() }}  >
                                search
                            </Button>
                        </Col>
                        <Col xs="24" xl={32}>
                            <Button block={true} danger onClick={() => { handleFormManageModel(true) }} icon={<FileAddOutlined />} >
                                New
                            </Button>
                        </Col>
                    </Row>

                </div>



                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Authors Table"
                            extra={
                                <>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ONLINE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={formData}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                                <div className="pagination-container">
                                    <Pagination onChange={(page, pageSize) => { changePagination(page, pageSize) }} defaultCurrent={selectedPage} total={dataCount} />
                                </div>

                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>



            <ManageFormModel visible={openFormManageModelm} onCancel={handleCloseFormManagement} addForm={(e) => { addForm(e) }} clossAddFormModel={() => { handleFormManageModel(false) }} addFormSuccess={() => { getAllForms() }} />

                <RuleManageMent />            
                <RuleElement />  

            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={showFormAnswerList} onClose={() => { setShowFormAnswerList(false) }}>
                <DialogTitle>Submit Answer List</DialogTitle>
                <DialogContent>
                    {/* <AnswerList formId={answerListSelectedId} /> */}

                    {/* <ManageUser jsonSchema={schemaComponent} /> */}
                </DialogContent>

            </Dialog>
        </>

    )
}

// const mapStateToProps = (state) => {
//     //changeLanguage(state)
//     return {
//         formSettings: state,
//     };
// };

export default ManageForm;
//export default connect(mapStateToProps, {})(ManageForm);
