import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {  getUserById } from "../../../../redux/slices/users";

import {
    useDispatch
} from "react-redux";
import '../style.css'
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { useTranslation } from "react-i18next";
import { JsonForms } from '@jsonforms/react';


import GeoLocation from '../custom/geoLocation/GeoLocation';
import FileControl from '../custom/storage/FileControl';

import { rankWith, scopeEndsWith } from '@jsonforms/core';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {  Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import moment from 'moment'
import LinearProgress from '@mui/material/LinearProgress';
import SingleSelect from "../custom/Select/SingleSelect";


export function GroupUserEdit(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

   

    const [render, setRender] = useState([
        ...materialRenderers
    ])

    const [dataBaseData, setDataBaseData] = useState([])

    const [renderSuccess, setRenderSuccess] = useState(true)

    const [formIsValid, setFormValidation] = useState(true)

    const [formSchema, setFormSchema] = useState({
        schema: {
            "properties": {
                "name": {
                    "type": "string",
                    "title": "First Name"
                },
                "blabla": {
                    "type": "string",
                    "title": "First Name"
                }
            }
        }, uischema: {
            "properties": {
                "name": {
                    "type": "string",
                },
                "blabla": {
                    "type": "string",

                }
            }
        },
        data: {}

    })

    const createNonGroupElement = (mainEl) => {
        let schemaObj = {}
        let typeOfElement = 'string';
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {
            typeOfElement = 'string';
        }
        let eleObj = {}
        if (mainEl.type === 'drop' || mainEl.type === 'radio') {
            schemaObj = {
                type: typeOfElement,
                oneOf: mainEl.enum ? mainEl.enum : [],
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'date') {
            schemaObj = {
                type: typeOfElement,
                format: 'date',
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'check') {
            schemaObj = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: mainEl.enum ? mainEl.enum : [],
                },
                title: t(mainEl.lableTxt)
            }
        } else if (mainEl.type === 'location') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: GeoLocation
            })
            setRender(renderers);
            return false
        }
        else if (mainEl.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(mainEl.keyTxt)
                ), renderer: FileControl
            })
            schemaObj = {
                type: 'string',
                storeMethod: mainEl.fileStoreType,
                s3Bucket: mainEl.s3Bucket,
                s3Dir: mainEl.s3Dir,
                selectedFileType: mainEl.selectedFileType ? mainEl.selectedFileType : null,
                captureType: mainEl.captureType ? mainEl.captureType : null
            }
        }
        else {
            schemaObj = {
                type: typeOfElement,
                title: t(mainEl.lableTxt)
            }
        }
        return schemaObj
    }


    const createGroupElement = (elementGroup, schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: {}
        }

        wrappingObj.schema['title'] = t(elementGroup.groupName)



        if (schemaElement.length !== 0) {
            let eleArra = []
            if (elementGroup.groupType === 'array') {
                wrappingObj.schema['type'] = 'array'
                wrappingObj.schema['items'] = {}
                wrappingObj.schema.items['type'] = 'object'
                wrappingObj.schema.items['properties'] = {}
                for (const elementData of schemaElement) {
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {
                        let eleObj = {}
                        if (elementData.type === 'drop' || elementData.type === 'radio') {


                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                oneOf: elementData.enum ? elementData.enum : [],
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'date') {


                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'check') {
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: 'string',
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir
                            }
                        } else {
                            wrappingObj.schema.items['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            } else {
                wrappingObj.schema['properties'] = {}
                for (const elementData of schemaElement) {
                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        if (elementData.type === 'drop' || elementData.type === 'radio') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                oneOf: elementData.enum ? elementData.enum : [],
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'date') {


                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                format: 'date',
                                title: t(elementData.lableTxt)
                            }



                        } else if (elementData.type === 'check') {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: 'array',
                                uniqueItems: true,
                                items: {
                                    type: 'string',
                                    oneOf: elementData.enum ? elementData.enum : [],
                                },
                                title: t(elementData.lableTxt)
                            }


                        } else if (elementData.type === 'location') {


                            let renderers = render



                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: GeoLocation
                            })




                            setRender(renderers);
                            return false
                        } else if (elementData.type === 'File') {

                            let renderers = render
                            renderers.push({
                                tester: rankWith(
                                    3, //increase rank as needed
                                    scopeEndsWith(elementData.keyTxt)
                                ), renderer: FileControl
                            })
                            wrappingObj = {
                                type: 'string',
                                storeMethod: elementData.fileStoreType,
                                s3Bucket: elementData.s3Bucket,
                                s3Dir: elementData.s3Dir,
                                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null
                            }
                        } else {
                            wrappingObj.schema['properties'][elementData.keyTxt] = {
                                type: typeOfElement,
                                title: t(elementData.lableTxt)
                            }


                        }


                        // wrappingObj.ui.push(eleObj)
                    }
                }
            }




        }
        return wrappingObj

    }


    const createGroupElementObj = (schemaElement) => {

        let wrappingObj = {
            schema: {},
            ui: {}
        }



        let eleArra = []

        wrappingObj.schema = {}

        let typeOfElement = 'string';
        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {
            typeOfElement = 'string';
        }

        if (schemaElement.type === 'drop' || schemaElement.type === 'radio') {
            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: SingleSelect
            })
            setRender(renderers);
            wrappingObj.schema = {
                enumList: schemaElement.enum ? schemaElement.enum : [],
                schemaElement: schemaElement
            }


        } else if (schemaElement.type === 'date') {
            wrappingObj.schema = {
                type: typeOfElement,
                format: 'date',
                title: t(schemaElement.lableTxt)
            }
        } else if (schemaElement.type === 'check') {
            wrappingObj.schema = {
                type: 'array',
                uniqueItems: true,
                items: {
                    type: 'string',
                    oneOf: schemaElement.enum ? schemaElement.enum : [],
                },
                title: t(schemaElement.lableTxt)
            }


        }
        else if (schemaElement.type === 'File') {

            let renderers = render
            renderers.push({
                tester: rankWith(
                    3, //increase rank as needed
                    scopeEndsWith(schemaElement.keyTxt)
                ), renderer: FileControl
            })
            wrappingObj.schema = {
                type: 'string',
                storeMethod: schemaElement.fileStoreType,
                s3Bucket: schemaElement.s3Bucket,
                s3Dir: schemaElement.s3Dir,
                selectedFileType: schemaElement.selectedFileType ? schemaElement.selectedFileType : null,
                captureType: schemaElement.captureType ? schemaElement.captureType : null
            }
        }
        else if (schemaElement.type === 'number') {

            wrappingObj.schema = {
                type: 'integer',
                title: t(schemaElement.lableTxt)
            }
        } else {
            wrappingObj.schema = {
                type: typeOfElement,
                title: t(schemaElement.lableTxt)
            }


        }








        return wrappingObj

    }


    const getUserInformationDatabase = async (id) => {
        return await dispatch(getUserById(id))
    }

    const createForm = async () => {


        setRenderSuccess(false)
        const databaseRelatedUserData = await getUserInformationDatabase(props.id.ID)

        setDataBaseData(databaseRelatedUserData.payload)


        const group = props.elementGroup
        const schemaElement = props.jsonSchema

        let schemaObject = {}

        schemaObject["properties"] = {
            bitrix: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            },
            database: {
                properties: {
                    main: {
                        title: '',
                        properties: {},
                        required: []
                    }
                }
            }

        }
        schemaObject['type'] = 'object'
        let uiSchema = {
            "type": "VerticalLayout",
            "elements": []
        }

        let dataBody = {
            bitrix: {
                main: {

                }
            },
            database: {
                main: {

                }
            }

        }


        const mainField = schemaElement.filter((e) => e.group === null)



        if (mainField.length !== 0) {



            const calCount = 2;
            for (const mainEl of mainField) {
                const schemaObj = createNonGroupElement(mainEl)

                if (schemaObj) {
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        schemaObject.properties.bitrix.properties.main.properties[mainEl.keyTxt] = schemaObj

                    } else {
                        schemaObject.properties.database.properties.main.properties[mainEl.keyTxt] = schemaObj
                        if (mainEl.isRequired) {
                            schemaObject.properties.database.properties.main.required.push(mainEl.keyTxt)
                        }
                    }

                }

            }

            const chunkSize = 2;
            for (let i = 0; i < mainField.length; i += chunkSize) {
                const chunk = mainField.slice(i, i + chunkSize);
                let mainGroupSchema = {
                    "type": "HorizontalLayout",
                    "elements": []
                }

                for (const mainEl of chunk) {
                    let scope = ''
                    if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {

                        scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                    } else {
                        scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope
                    })
                }
                uiSchema.elements.push(mainGroupSchema)
            }

        }


        for (const elementGroup of group) {

            if (elementGroup.groupType === 'object') {

                for (const elementData of schemaElement) {

                    let typeOfElement = 'string';
                    if (elementData.type === 'drop' || elementData.type === 'radio') {
                        typeOfElement = 'string';
                    }
                    if (elementData.group === elementGroup.groupId) {

                        const resGropEn = createGroupElementObj(elementData)


                        if (resGropEn) {
                            if (elementData.fieldPlace === 'bitrix' || elementData.fieldPlace === '') {
                                schemaObject.properties.bitrix.properties.main.properties[elementData.keyTxt] = resGropEn.schema

                                let keyTxtValue = props.id[elementData.keyTxt] ? props.id[elementData.keyTxt] : null;

                                if (elementData.type == 'date') {
                                    keyTxtValue = keyTxtValue ? moment(keyTxtValue).format('YYYY-MM-DD') : null

                                }
                                if (elementData.type == 'check') {
                                    keyTxtValue = keyTxtValue ? keyTxtValue : []

                                }
                                if (elementData.type == 'number') {
                                    keyTxtValue = keyTxtValue ? keyTxtValue : 0

                                }

                                if (keyTxtValue) {

                                    dataBody.bitrix.main = { ...dataBody.bitrix.main, [elementData.keyTxt]: keyTxtValue }
                                }



                                if (elementData.isRequired) {
                                    schemaObject.properties.bitrix.properties.main.required.push(elementData.keyTxt)
                                }
                            } else {
                                schemaObject.properties.database.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                                let keyTxtValue = null
                                if (databaseRelatedUserData.payload) {
                                    keyTxtValue = databaseRelatedUserData.payload[elementData.keyTxt] ? databaseRelatedUserData.payload[elementData.keyTxt] : null;
                                }


                                if (elementData.type == 'date') {
                                    keyTxtValue = keyTxtValue ? moment(keyTxtValue).format('YYYY-MM-DD') : null

                                }
                                if (elementData.type == 'check') {
                                    keyTxtValue = keyTxtValue ? keyTxtValue : []

                                }
                                if (elementData.type == 'number') {
                                    keyTxtValue = keyTxtValue ? keyTxtValue : 0

                                }

                                if (keyTxtValue) {

                                    dataBody.database.main = { ...dataBody.database.main, [elementData.keyTxt]: keyTxtValue }
                                }
                                if (elementData.isRequired) {
                                    schemaObject.properties.database.properties.main.required.push(elementData.keyTxt)
                                }
                            }

                            // schemaObject.properties.main.properties[elementData.keyTxt] = resGropEn.schema
                        }



                    }


                }
                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                },
                )
                const groupBelongElemeny = schemaElement.filter((e) => e.group === elementGroup.groupId)

                const perChunk = elementGroup.groupColtxt ? elementGroup.groupColtxt : 1;
                const resultArraySplices = groupBelongElemeny.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / perChunk)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, [])


                for (const splice of resultArraySplices) {



                    let mainGroupSchema = {
                        "type": "HorizontalLayout",
                        "elements": []
                    }




                    for (const mainEl of splice) {

                        let scope = ''
                        let option = {

                        }
                        if (mainEl.fieldPlace === 'bitrix' || mainEl.fieldPlace === '') {
                            // scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                            scope = "#/properties/bitrix/properties/main/properties/" + mainEl.keyTxt
                        } else {
                            scope = "#/properties/database/properties/main/properties/" + mainEl.keyTxt
                        }

                        if (mainEl.type === 'date') {
                            option['format'] = 'date'
                            option['views'] = ['year', 'month', 'date']
                            option['dateFormat'] = "YYYY-MM-DD"
                            option['dateSaveFormat'] = "YYYY-MM-DD"

                        }
                        mainGroupSchema.elements.push({
                            "type": "Control",
                            "scope": scope,
                            "options": option
                        })

                    }


                    uiSchema.elements.push(mainGroupSchema)
                }

                //   

            }
            else if (elementGroup.groupType === 'category') {

                uiSchema.elements.push({
                    "type": "Label",
                    "text": elementGroup.groupLable
                })
                // uiSchema.elements.push({
                //     "type": "Categorization",
                //     "text": elementGroup.groupLable
                // })

                let catObj = {
                    "type": "Categorization",
                    "elements": [],
                    "options": {
                        "variant": "stepper",
                        "showNavButtons": true
                    }
                }
                const relatedStage = group.filter((e) => e.selectedCategory === elementGroup.groupId && e.groupType === 'stage')
                if (relatedStage.length === 0) {
                    continue;
                }
                for (const elementRelatedStage of relatedStage) {

                    let stageObj = {
                        "type": "Category",
                        "label": "Basic Information",
                        "elements": []
                    }

                    if (elementRelatedStage.groupType === 'stage') {
                        const filteredEleimentSchemaCategory = schemaElement.filter((e) => e.group === elementRelatedStage.groupId)
                        for (const elementDataStage of filteredEleimentSchemaCategory) {
                            const resGropEn = createGroupElementObj(elementDataStage)
                            let scope = ''
                            if (resGropEn) {

                                if (elementDataStage.fieldPlace === 'bitrix' || elementDataStage.fieldPlace === '') {
                                    schemaObject.properties.bitrix.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/bitrix/properties/main/properties/" + elementDataStage.keyTxt
                                } else {
                                    schemaObject.properties.database.properties.main.properties[elementDataStage.keyTxt] = resGropEn.schema
                                    scope = "#/properties/database/properties/main/properties/" + elementDataStage.keyTxt
                                }
                                stageObj.elements.push({

                                    "type": "HorizontalLayout",
                                    "elements": [
                                        {
                                            "type": "Control",
                                            "scope": scope
                                        }

                                    ]
                                })
                            }
                        }

                    }
                    catObj.elements.push(stageObj)
                }
                uiSchema.elements.push(catObj)

            }

            else if (elementGroup.groupType === 'array') {

                const resGropEn = createGroupElement(elementGroup, schemaElement)

                if (resGropEn) {
                    let scope = ''
                    if (resGropEn.fieldPlace === 'bitrix' || resGropEn.fieldPlace === '') {
                        schemaObject.properties.bitrix.properties.main.properties[elementGroup.keyTxt] = resGropEn.schema
                        scope = "#/properties/bitrix/properties/main/properties/" + resGropEn.keyTxt
                    } else {
                        schemaObject.properties.database.properties.main.properties[elementGroup.keyTxt] = resGropEn.schema
                        scope = "#/properties/database/properties/main/properties/" + schemaElement.keyTxt
                    }
                    //schemaObject.properties.main.properties[elementGroup.groupId] = resGropEn.schema

                    let mainGroupSchema = {
                        "type": "VerticalLayout",
                        "elements": []
                    }
                    mainGroupSchema.elements.push({
                        "type": "Control",
                        "scope": scope
                    })

                    uiSchema.elements.push(mainGroupSchema)
                }


            }


        }




        setFormSchema((prevState) => {
            return {
                ...prevState,
                schema: schemaObject,
                uischema: uiSchema,
                data: dataBody
            };
        });

        setRenderSuccess(true)
        //

    }
    const handleLanguageChanged = () => {
        createForm();
    }

    useEffect(() => {

        i18n.on('languageChanged', handleLanguageChanged);

        createForm();


    }, []);

    const setData = (data, err) => {
        if (err.length === 0) {
            setFormValidation(true)
        } else {
            setFormValidation(false)
        }

        setFormSchema((prevState) => {
            return ({
                ...prevState,
                data: data
            });
        });
    }
    const editUserSubmit = async () => {

        if (!formSchema.data) {
            return
        }






        let obj = {
            userId: props.id.ID,
            bitrix: {

            },
            database: {

            }
        }

        obj.bitrix = formSchema.data.bitrix
        // obj.bitrix.main.id = props.id.ID
        obj.database = formSchema.data.database

        props.editGroupSubmit(obj)


        // try {
        //
        //     let res = await dispatch(updateUserService(obj));
        //
        //     if (res) {
        //         props.onSubmit(true)
        //     } else {
        //         props.onSubmit(false)
        //     }
        //
        //
        // } catch (e) {
        //
        // }
    }


    return (
        <>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {t('Edit User')}
                    </Typography>
                    <button className='button-full-screen-close' onClick={props.handleClose}>
                        CLOSE
                    </button>
                    {formIsValid ?
                        <button className='button-full-screen-submit' onClick={editUserSubmit}>
                            SAVE
                        </button> :
                        null}

                </Toolbar>
            </AppBar>
            <DialogContent className=''>

                {
                    renderSuccess ? (<>
                        <JsonForms
                            schema={formSchema.schema}
                            uischema={formSchema.uischema}
                            data={formSchema.data}
                            renderers={render}
                            cells={materialCells}
                            onChange={({ data, errors }) => setData(data, errors)}
                        />
                    </>) : (<div className='center-loading-bar'>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />

                        </Box>
                    </div>)
                }

                {/*<div className="foter-json-form">*/}
                {/*    <button className="foter-json-submit" onClick={editUserSubmit}>Submit</button>*/}
                {/*</div>*/}

            </DialogContent>


        </>
    );
}