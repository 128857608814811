import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  getFormAnswerList,
  deleteSelectedAnswer,
  submitFormAnswer,
  editFormAnswer,
  submitTaskProcessReducer,
  completeTaskProcessReducer,
  getFormAnswerTaskReducer,
} from "../../../../redux/slices/formSettings";

import { FormInterfaceDynamicEdit } from "./FormInterfaceDynamicEdit";
import { FormInterfaceDynamic } from "./FormInterfaceDynamic";
import { Button, notification } from "antd";
import { FullScreenLoader } from "../../../../components/elements/FullScreenLoader";
import { LeftOutlined } from "@ant-design/icons";
const dateFormat = "YYYY-MM-DD";

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60) % 24;
  const days = Math.floor(totalMinutes / 1440);
  const minutes = totalMinutes % 60;

  let dateFormat = "";

  if (days) {
    dateFormat += `${days}Days `;
  }
  if (hours) {
    dateFormat += `${hours}Hrs `;
  }
  if (minutes) {
    dateFormat += `${minutes}Mins `;
  }
  return dateFormat;
}

const WorkFlow = (props) => {
  const { t, i18n } = useTranslation();
  const [answerId, setAnswerId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTaskProcessOpen, setTaskProcessOpen] = useState(false);

  const [formSchemaTask, setFormScheemaTask] = useState([]);
  const [formGroupTask, setFormGroupTask] = useState([]);
  const [formTask, setFormTask] = useState(null);

  const [formDataSetTask, setFormDataSetTask] = useState(null);

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const [jsonProcessAnswer, setjsonProcessAnswer] = useState({});
  const [allAnswerRow, setAllAnswerRow] = useState([]);
  const [addButtonDisable, setaddbtnDisable] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [formInformation, setFormInformation] = useState(null);

  useEffect(() => {
    getFormAnswerTask(props.location.state.detail.formProccess
      .proccessForm.id)
      console.log("props.location",props.location)
  }, [])

  const changeDataTriggerTask = async (data, err, from) => {
    if (from) {
      console.log("from", from);
    }

    if (!selectedAnswerId) {
      console.log("no answer id", selectedAnswerId);
      return;
    }
    console.log("changeDataTriggerTask selectedAnswerId", selectedAnswerId);

    err = err.filter((e) => {
      //   console.log(e.parentSchema.title, "e.parentSchema.title");
      return !e.parentSchema.title.includes("Store");
    });
    setLoading(true);
    if (err.length != 0) {
      setaddbtnDisable(true);
    } else {
      setaddbtnDisable(false);
    }

    let newData = { ...data }
    if (newData["database"] && newData["database"]["main"]) {
      newData["database"]["main"]["active"] = true
    }

    const obj = {
      data: newData,
      proccessFormId: props.location.state.detail.formProccess.proccessForm.id,
      selectedAnswerId: selectedAnswerId,
      // proccessFormId:props.location.state.detail.formProccess.proccessForm.id
    };

    // const submitTaskProcess = await 
    dispatch(submitTaskProcessReducer(obj)).then((res) => { }).catch((err) => {
      notification.error({
        message: "Error",
        description: `TaskProcess - submitTaskProcessReducer `,
      });
    })
    setFormDataSetTask(data);
    setLoading(false);
  };


  const init = (data) => {
    setLoading(true);

    if (data?.formProccess) {
      const { proccessFormScheema, proccessFormGroups, proccessForm, proccessFormAnswer, jsonProcessAnswer } = data.formProccess;

      if (data.id) {
        setSelectedAnswerId(data.id);
      } else {
        notification.error({ message: 'Error', description: `TaskProcess - setSelectedAnswerId - No data.id` });
      }

      if (jsonProcessAnswer) {
        setjsonProcessAnswer(jsonProcessAnswer);
      }
      // else {
      //   notification.error({    message: 'Error',  description: `setSelectedAnswerId / setjsonProcessAnswer  - setFormGroupTask `  });
      //   }

      if (proccessFormScheema) {
        setFormScheemaTask(proccessFormScheema);
      } else {
        notification.error({ message: 'Error', description: `TaskProcess - setFormScheemaTask ` });
      }

      if (proccessFormGroups) {
        setFormGroupTask(proccessFormGroups);
      } else {
        notification.error({ message: 'Error', description: `TaskProcess - setFormGroupTask ` });
      }

      if (proccessForm) {
        setFormTask(proccessForm);
        setFormInformation(proccessForm);
      } else {
        notification.error({ message: 'Error', description: `setFormInformation / setFormTask  - setFormGroupTask ` });
      }


      notification.success({ message: 'Success', description: `Task Successfully loaded.` });
      setLoading(false);

    } else {
      setLoading(false);
      notification.error({ message: 'Error', description: ` not be loaded` });
      history.push("/dynamic-page/" + props.location.state.last);

    }

  };

  const getFormAnswerTask = async (id) => {
    // getFormAnswerTaskReducer
    const obj = {
      data: {},
      selectedAnswerId: props.match.params.id,
      proccessFormId: id,
    };

    console.log("props?.location?.state?.detail", props?.location?.state?.detail)
    let newData = { ...props?.location?.state?.detail }
    try {
      const taskAnswer = await dispatch(getFormAnswerTaskReducer(obj));
      // init(taskAnswer.data);
      newData.formProccess.jsonProcessAnswer = taskAnswer.payload
      init(newData);
    }
    catch (error) {
      console.log({ message: 'Error', description: error });
    }
  }


  const saveFormTaskProcess = async (id) => {
    const obj = {
      data: formDataSetTask,
      selectedAnswerId: selectedAnswerId,
      proccessFormId: id,
    };

    let newData = { ...formDataSetTask }
    newData = {
      ...newData,
      database: {
        ...newData.database,
        main: {
          ...newData?.database?.main,
          ["active"]: true
        },
      },
    }

    let taskSubmission = await dispatch(submitTaskProcessReducer(obj));

    if (taskSubmission.payload) {
      notification.success({ message: 'Success', description: `Task Successfully saved.` });

      setTaskProcessOpen(false);
      // getAnswerList()
      //  getAnswerList(props.form.id);
    } else {
      notification.error({ message: 'Error', description: `Task Could not be saved` });
    }
    history.push("/dynamic-page/" + props.location.state.last);
    //
  };

  const completeTask = async (id) => {
    localStorage.removeItem("activeTask");
    const keyOfFinis = formSchemaTask.find(
      (e) => e.type === "date" && e.finish
    );
    const keyOfFinisCal = formSchemaTask.find(
      (e) => e.type === "date_diff" && e.finish
    );

    const duration = formSchemaTask.find(
      (e) => e.type === "date_diff"
    );

    console.log("duration",duration);

    let ob = null;
    if (keyOfFinisCal) {
      let minutesDiff = moment().diff(
        moment(formDataSetTask.database.main[keyOfFinisCal.compare[0]]),
        "minutes"
      );
      //console.log('minutes:' + toHoursAndMinutes(minutesDiff));

      ob = {
        ...formDataSetTask,
        database: {
          ...formDataSetTask.database,
          main: {
            ...formDataSetTask.database.main,
            [keyOfFinisCal.keyTxt]: toHoursAndMinutes(minutesDiff),
          },
        },
      };
    } else if (keyOfFinis) {
      ob = {
        ...formDataSetTask,
        database: {
          ...formDataSetTask.database,
          main: {
            ...formDataSetTask.database.main,
            [keyOfFinis.keyTxt]: moment().toISOString(),
          },
        },
      };
    } else {
      ob = {
        ...formDataSetTask,
      };
    }
    let newData = { ...ob }
    let minutesDiff = null
    if(duration){
      minutesDiff = moment().diff(
        moment(formDataSetTask.database.main[duration.compare[0]]),
        "minutes"
      );
      minutesDiff = toHoursAndMinutes(minutesDiff)
      //console.log('minutes:' + toHoursAndMinutes(minutesDiff));
      newData = {
        ...newData,
        database: {
          ...newData.database,
          main: {
            ...newData.database.main,
            [duration.keyTxt]: minutesDiff,
          },
        },
      }
    }
    newData = {
      ...newData,
      database: {
        ...newData.database,
        main: {
          ...newData.database.main,
          ["active"]: false
        },
      },
    }

    const obj = {
      data: newData,
      selectedAnswerId: selectedAnswerId,
      isComplete: true,
      proccessFormId: id,
    };

    // delete obj?.data?.database?.main?.active;

    const completeTaskProcess = await dispatch(completeTaskProcessReducer(obj));

    if (completeTaskProcess.payload) {
      notification.success({ message: 'Success', description: `Task Successfully completed.` });
      return history.push("/dynamic-page/" + props.location.state.last);
    } else {
      notification.error({ message: 'Error', description: `Task Could not be completed` });
    }
    setTaskProcessOpen(false);
    // getAnswerList()
    //  getAnswerList(props.form.id);

    //
  };
  useEffect(() => {
    props ? console.log("props", props) : console.log(" No props", props);
    props.location.state.formInformation ? console.log("props.location.state.formInformation", props.location.state.formInformation) : console.log(" No props.location.state.formInformation", props.location.state.formInformation);
    const fetchData = async () => {
      setAnswerId(props?.match?.params?.id);
      // await init(props?.location?.state?.detail);
      // setTaskProcessOpen(true)
    };

    fetchData();
  }, []);


  return (
    <React.Fragment>
      {isLoading ? <FullScreenLoader /> : null}
      <Grid container spacing={6} className="scroll-width">
        <Grid item xs={12} className="scroll-padding">
          <div className="task-responsive-div">
            <LeftOutlined onClick={()=>history.push("/dynamic-page/" + props.location.state.last)} style={{marginLeft:"10px",flex:0.5}} />
            <h1 style={{margin: 0,fontWeight:"bold"}}>{props.location.state.detail.data.database.main.Project}</h1>
          </div>
          <div className="page-continer ">
            {isLoading ? null : (
              <>
                <div className="page-headfer">
                  <div className="page-action-process">
                    <div>
                      <Button
                        onClick={() => {
                          saveFormTaskProcess(
                            props.location.state.detail.formProccess
                              .proccessForm.id
                          );
                        }}
                      >
                        {t("SAVE")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        style={{ color: "#fff" }}
                        disabled={addButtonDisable}
                        danger
                        onClick={() => {
                          completeTask(
                            props.location.state.detail.formProccess
                              .proccessForm.id
                          );
                        }}
                      >
                        {t("COMPLETE")}
                      </Button>
                    </div>
                  </div>
                </div>
                {jsonProcessAnswer ? (
                  <FormInterfaceDynamicEdit
                    jsonSchema={formSchemaTask}
                    elementGroup={formGroupTask}
                    data={jsonProcessAnswer ? jsonProcessAnswer : null}
                    formDetails={props?.location?.state?.form?.id}
                    formInformation={formInformation}
                    changeDataTrigger={(data, err) =>
                      changeDataTriggerTask(data, err, 'FormInterfaceDynamicEdit')
                    }
                  />
                ) : (
                  <FormInterfaceDynamic
                    elementGroup={formGroupTask}
                    jsonSchema={formSchemaTask}
                    formInformation={formInformation}
                    id={formDataSetTask}
                    formDetails={formTask}
                    // handleClose={()=>{openSubmitForm(false)}}
                    // refreshPage={()=>{openSubmitForm(false)}}
                    changeDataTrigger={(data, err) =>
                      changeDataTriggerTask(data, err, 'FormInterfaceDynamic')
                    }
                  />
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default WorkFlow;
