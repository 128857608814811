

import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { Checkbox } from 'antd';
import {
    getCOnditionalEnum,
    getFormSchema,
    getAllFormsReducer
} from "../../../../../redux/slices/formSettings";
import { Select ,InputNumber  } from 'antd';
import { init } from 'i18next';
import { Button,notification,message } from 'antd';
import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Icon,
  } from "@mui/material";
  
import Input from "@mui/material/Input";


import {
    DeleteOutlined
  } from "@ant-design/icons";

  
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";



import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
const { Option } = Select;

const CustomSorting = (props) => {

    const dispatch = useDispatch();
    const ctx = useJsonForms();
    const wholeDataObject = ctx.core.data;
    const { t, i18n } = useTranslation();
    const [Urenderfunsed, setrenderfyn] = useState(false)

    const [Lable, setLable] = useState(null)
    const [ isChecked,setChecked] = useState(false)

    const [schema, setSchema] = useState([])
    const [ group,setGroup] = useState([])
    const [activeFormList, setActiveFormList] = useState([])
    const [ targetArray,setTargetArray] = useState(null)
    const [orderingValue, setOrderingValue] = useState([]);
    const [sortRefreshed, setSortRefreshed] = useState(true);
    const [selectFormEnumSet, setSelectedFormEnumSet] = useState([])
    const [orderLabel,setOrderLable] = useState('product_series')
    const dataList = useSelector((state) => {


      let error = null
      if (state?.ruleFormElement?.dataset) {
          const data = state.ruleFormElement.dataset
          
                      error = data?.database?.main?data.database.main:null
                

      }

      return error

    })
    const pushValueToOrdering = () => {
        if(!orderLabel){
          alert('Please select sort By')
          return false;
        }
        let obj = { "sort_key" : orderLabel, "sort_type": 1, "sort_by" : 'ALPHANUM' };
        setOrderLable('');
        setOrderingValue([...orderingValue, obj]);
      };
    
      const removeOrderingValus = (item) => {
        if (orderingValue) {
          setSortRefreshed(false);

          setOrderLable(item);
          setOrderingValue(prev => prev.filter(ord => ord.sort_key !== item ))
          setTimeout( () => { setSortRefreshed(true); },100)
          
          setOrderLable('');
        }
      };

      const updateOrderingValus = (key, value, index) => {
        let editedValue = orderingValue[index]
        console.log(key, value, index)
        let ordValue = orderingValue.filter(ord => ord.sort_key !== editedValue.sort_key )

        setOrderingValue([...ordValue, {...{ "sort_key" : editedValue.sort_key, "sort_type": editedValue.sort_type, "sort_by" : editedValue.sort_by, "sort_form" : editedValue?.sort_form }, [`${key}`]: value} ])
      };

    const init = async () => {
      console.log(props.schema,'props.schemaprops.schema')

        const res = await dispatch(getFormSchema(props.schema.schemaElement.selectedFormForPayload));
        setSelectedFormEnumSet(res.payload)

        const array = await dispatch(getAllFormsReducer(true))
        setActiveFormList(array.payload.respons.data)
        if(props.schema.schemaElement.sortBy){
          setOrderingValue(props.schema.schemaElement.sortBy)
        }
    }

    const updateSchemaData = useCallback(() => {
      if(dataList){
        const  retobj={
          bitrix:{main:{}},
          database:{
              main:{
                  ...dataList,
                  "sortBy":orderingValue
              }
          }
        }
      
        props.schema.addBulkDataList(retobj)
      }
    },[dataList])
    //

    useEffect(() => {
        //
        init()

        //
    }, [props]);
    
    useEffect( () => {
        updateSchemaData()
    },[orderingValue])

    return (
        <>
        
        <Grid className="field-container" item xs={12}>
                                      <h4>{t('Order Settings')}</h4>
                                    </Grid>
                                    <Grid container spacing={2}>
                                    <Grid className="field-container  ant-select-custom-container" item xs={6} md={6}>
                                      
                                        <p style={{marginBottom:'5px'}}>
                                          {t('Order By')}
                                        </p>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-ordering"
                                          size="small"
                                          label="ordering"
                                          style={{width:'100%', height: 40}}
                                          value={orderLabel}
                                          onChange={(e) => {
                                            console.log(e,'eee')
                                            setOrderLable(e)
                                          }}
                                          placeholder='Select'
                                        >
                                          {selectFormEnumSet.map((row) => (
                                            <MenuItem value={row.keyTxt}>{row.lableTxt}</MenuItem>
                                          ))}
                                        </Select>
                                    </Grid>
                                    <Button onClick={pushValueToOrdering}>{t('Add Order By')}</Button>
                                    </Grid>
                                    {sortRefreshed && <>
                                    { orderingValue.map((row,index) => (<Grid container spacing={2}>
                                      <Grid className="field-container" item xs={4} md={4}>
                                      <p style={{marginBottom:'5px'}}>{t('Key')}</p>
                                        <Input
                                          className="array-value-add-container"
                                          style={{width:'100%'}}
                                          value={row.sort_key}
                                          Readonly
                                          variant="outlined"
                                          inputProps={
                                            { readOnly: true, }
                                          }
                                        />
                                    </Grid>
                                    <Grid className="field-container" item xs={2} md={2}>
                                      
                                        <p style={{marginBottom:'5px'}}>
                                          {t('Order Type')}
                                        </p>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-ordering"
                                          label="ordering"
                                          defaultValue={row.sort_by}
                                          onChange={(e) => {
                                            //setOrderLable(e)
                                            console.log(e,'e---')
                                            //row.sort_by = e
                                            updateOrderingValus('sort_by', e, index)
                                          }}
                                          style={{width:'100%', height: 40}}
                                        >
                                          <MenuItem value={`ALPHANUM`}>Alphanumeric</MenuItem>
                                          <MenuItem value={`CUSOR`}>Custom Order</MenuItem>
                                        </Select>
                                    </Grid>
                                    {row.sort_by == 'CUSOR' && <Grid className="field-container" item xs={2} md={2}>
                               
                                    <p style={{marginBottom:'5px'}}>
                                      {t('Select Form')}
                                    </p>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    
                                    defaultValue={row.sort_form}
                                    label="group"
                                    onChange={(e) => {
                                      console.log(e,'e.target.valuee.target.value')
                                      //row.sort_form = e
                                      updateOrderingValus('sort_form', e, index)
                                    }}
                                    style={{width:'100%', height: 40}}
                                  >

                                    {activeFormList.map((row) => (
                                      <MenuItem value={row.id}>{row.name}</MenuItem>

                                    ))}
                                  </Select>
                                  
                              </Grid>}
                                    <Grid className="field-container" item xs={2} md={2}>
                                      
                                        <p style={{marginBottom:'5px'}}>
                                          {t('Data Order')}
                                        </p>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-ordering"
                                          
                                          label="ordering"
                                          defaultValue={row.sort_type}
                                          onChange={(e) => {
                                            //setEnumLable(e.target.value)
                                            //row.sort_type = e
                                            updateOrderingValus('sort_type', e, index)
                                          }}
                                          style={{width:'100%', height: 40}}
                                        >
                                          <MenuItem value={1}>ASC</MenuItem>
                                          <MenuItem value={-1}>DESC</MenuItem>
                                        </Select>
                                        </Grid>
                                      <Grid item xs={2} md={2}><Button onClick={() => removeOrderingValus(row.sort_key)}><DeleteOutlined /></Button></Grid>
                                    
                                    </Grid>))}
                                    </>}

        </>



    );
}



export default withJsonFormsControlProps(CustomSorting);
